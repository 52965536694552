import { ORGANIZATION_ROUTES } from 'routes/path';
import { APP_ASSETS } from 'utils/asset';

export const ORG_CONSTANTS = {
  qrCodeUrlWithParam: 'https://opscenter.page.link/register?org_id=',
  downloadPdf: 'Download PDF',
  uiRegisterQrCode: ' Registration QR Code',
  uiScanToRegister: 'Scan to Register for your ',
  event: 'Event',
  organisation: 'Organization',
  org: 'Org',
  cancel: 'Cancel',
  download: 'Download',
  qrCodePdfId: 'qr_code_pdf',
  qrCodePreviewId: 'qr_code_preview',
  qrPdfId: 'qr_pdf',
  qrPreviewId: 'qr_preview',
  orgNameId: 'orgName',
  bottomTitleId: 'bottomTitle',
  orgNamePdfId: 'orgNamePdf',
  bottomTitlePdfId: 'bottomTitlePdf',
  uiSe: ' SE',
  uiOrg: ' Org',
  pdfNameSuffix: '-qrCode.pdf',
  managerCapitals: 'MANAGER',
  commanderCapitals: 'COMMANDER',
};

export const ORG_NESTED_LINKS = {
  details: {
    id: 'details',
    name: 'Details',
    routeSlug: [ORGANIZATION_ROUTES.ORGANIZATION_DETAILS.path],
    routePost: '/',
    icon: {
      src: APP_ASSETS.ORG_DETAILS,
      name: 'Organization',
    },
  },
  users: {
    id: 'users',
    name: 'Users',
    routeSlug: [
      ORGANIZATION_ROUTES.ORGANIZATION_USERS.path,
      ORGANIZATION_ROUTES.ORGANIZATION_USERS_DETAILS.path,
    ],
    routePost: 'users',
    icon: {
      src: APP_ASSETS.ORG_USERS,
      name: 'Organization Users',
    },
  },
  explore: {
    id: 'explore',
    name: 'Map',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_MAP.path,
    routePost: 'maps',
    icon: {
      src: APP_ASSETS.MAP,
      name: 'Maps',
    },
  },
  qrCode: {
    id: 'qrCode',
    name: 'QR Code',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_QR_CODE.path,
    routePost: 'qr',
    icon: {
      src: APP_ASSETS.QR_CODE_ICON,
      name: 'QR Code',
    },
  },
};

export const ORG_ACTION_BUTTON = {
  addOrganization: {
    id: 'addOrganization',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_ACTION,
      name: 'Add Organization',
    },
  },
  updateOrganizationDetails: {
    id: 'addOrEditOrganizationDetails',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'Save Organization',
    },
    type: 'submit',
  },
  activateOrgDept: {
    id: 'activateOrgDept',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.INACTIVE_ICON,
      name: 'Activate Org',
    },
  },
  deActivateOrgDept: {
    id: 'deActivateOrgDept',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ACTIVE_ICON,
      name: 'Deactivate Org',
    },
  },
  addUserToOrganization: {
    id: 'addUserToOrganization',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_USER_ACTION,
      name: 'Add Users',
    },
  },
  updateUserDetails: {
    id: 'updateUserDetails',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'save User',
    },
    type: 'submit',
  },
  updateMapDetails: {
    id: 'updateMapDetails',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_MAP.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'save Org',
    },
  },
  activateOrgDeptUser: {
    id: 'activateOrgDeptUser',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.INACTIVE_ICON,
      name: 'Activate User',
    },
  },
  deActivateOrgDeptUser: {
    id: 'deActivateOrgDeptUser',
    routeSlug: ORGANIZATION_ROUTES.ORGANIZATION_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ACTIVE_ICON,
      name: 'Deactivate User',
    },
  },
};
