import PrimaryAppLogo from 'assets/primary-logo-white.png';
import SecondaryAppLogo from 'assets/secondary-logo-black.svg';
import MapIcon from 'assets/map.svg';
import QrCodeIcon from 'assets/qr-code.svg';
import MapBox from 'assets/map_box.png';
import OrganizationDetailsIcon from 'assets/house-building.svg';
import UsersIcon from 'assets/users.svg';
import PlusIcon from 'assets/plus.svg';
import FloppyDiskIcon from 'assets/floppy-disk.svg';
import AddUserIcon from 'assets/user-plus.svg';
import GeoFenceIcon from 'assets/draw-polygon.svg';
import PerimeterPointIcon from 'assets/rectangle-vertical.svg';
import CommandPostIcon from 'assets/home-user.svg';
import ActiveIcon from 'assets/lock-open.svg';
import InActiveIcon from 'assets/lock.svg';
import PoliceDeptDetailsIcon from 'assets/pd-icon.svg';
import EmsDeptDetailsIcon from 'assets/ems-org.svg';
import SuspectIcon from 'assets/user-secret.svg';
import InjuredIcon from 'assets/user-injured.svg';
import UserPlusFilledIcon from 'assets/user-plus-filled.svg';
import UsersFilledIcon from 'assets/users-filled.svg';

export const APP_ASSETS = {
  PRIMARY_APP_LOGO: PrimaryAppLogo,
  SECONDARY_APP_LOGO: SecondaryAppLogo,
  MAP_BOX: MapBox,
  ORG_DETAILS: OrganizationDetailsIcon,
  ORG_USERS: UsersIcon,
  ADD_ACTION: PlusIcon,
  SAVE_ACTION: FloppyDiskIcon,
  ADD_USER_ACTION: AddUserIcon,
  MAP: MapIcon,
  QR_CODE_ICON: QrCodeIcon,
  GEO_FENCE: GeoFenceIcon,
  COMMAND_POST_ICON: CommandPostIcon,
  PERIMETER_POINT_ICON: PerimeterPointIcon,
  ACTIVE_ICON: ActiveIcon,
  INACTIVE_ICON: InActiveIcon,
  POLICE_DEPARTMENT_DETAILS: PoliceDeptDetailsIcon,
  EMS_DEPARTMENT_DETAILS: EmsDeptDetailsIcon,
  JOIN_REQUESTS_ICON: UserPlusFilledIcon,
  INVOLVED_USERS_ICON: UsersFilledIcon,
  SUSPECT_ICON: SuspectIcon,
  INJURED_ICON: InjuredIcon,
};
