import React, { useEffect } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline, Container, Grid, Typography } from '@material-ui/core';
import Text from 'components/General/Form/Text';

import Flex from 'components/General/Flex';
import LoginButton from './LoginButton';

import { getLoginFormValue, loginActions } from 'store/login';

import { isRequired, isEmailValid, changeToLowerCase } from 'utils/helper';
import { APP_ASSETS } from 'utils/asset';
import { REDUX_FORM_CONSTANT } from 'utils/constants';
import { useLoginStyles } from './styles';
import { isAuthorised } from 'services/localStorage.service';
import { Redirect } from 'react-router';
import { appActions } from 'store';
import { eventActions } from 'store/event/event.slice';
import { isOpsCenter } from 'utils/hooks';

const LoginComponent = (props: InjectedFormProps) => {
  const classes = useLoginStyles();
  const authorised = isAuthorised();
  const dispatch = useDispatch();
  const formValues = useSelector(getLoginFormValue);
  const opsCenter = isOpsCenter();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(loginActions.authAsyncRequest(formValues));
  };

  useEffect(() => {
    if (authorised && opsCenter) {
      dispatch(eventActions.requestActiveEvent(null));
    } else if (authorised) dispatch(appActions.configAsyncRequest());
  }, []);

  if (authorised) return <Redirect to={'/'} />;

  return (
    <div className={classes.container}>
      <CssBaseline />
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.formContainer}
          >
            <div className={classes.logoContainer}>
              <img
                src={APP_ASSETS.PRIMARY_APP_LOGO}
                alt="Logo"
                height="100%"
                width="100%"
                style={{ marginLeft: '-8px' }}
              />
            </div>
            <div className={classes.titleText} id="body">
              <Typography variant="body1">
                Critical Event Response Applications
              </Typography>
            </div>
            <div className={classes.fieldContainer}>
              <Field
                name="userName"
                type="email"
                id="userName"
                component={Text}
                label="Email"
                placeHolder="Enter email"
                normalize={changeToLowerCase}
                validate={[isRequired, isEmailValid]}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Field
                name="password"
                type="password"
                id="password"
                component={Text}
                label="Password"
                placeHolder="Enter password"
                validate={[isRequired]}
              />
            </div>
            <Flex>
              <div className={classes.fieldContainer}>
                <LoginButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  id="submitBtn"
                  disabled={props.pristine || props.invalid}
                >
                  Sign In
                </LoginButton>
              </div>
            </Flex>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

export const Login = reduxForm({
  form: REDUX_FORM_CONSTANT.LOGIN,
})(LoginComponent);
