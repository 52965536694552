import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverRideEventInterface } from 'models/event/event.model';
import {
  EndEventPayload,
  EventId,
  JoinEventPayload,
  StartEvenPayload,
  CancelEventJoinPayload,
  ReportCEPayload,
  LeaveActiveEventPayload,
} from 'store/organization';
import {
  AddPersonDescriptionPayload,
  AddProfilePayload,
  GetProfilePayload,
  OrgTypeInterface,
  Profile,
  UpdateProfilePayload,
  ProfileListSuccessPayload,
  PositionInterface,
  ReportInterface,
  ReportsListSuccessPayload,
  getSortedDescendingOrderList,
  getSortedAscendingOrderList,
  FILTER_TYPES,
  EventJoinResponseInterface,
  GetEventJoinRequestsPayload,
  AcceptEventJoinRequestPayload,
  DenyEventJoinRequestPayload,
} from 'utils';

interface EventState {
  isLoading: boolean;
  onGoingEvent: OverRideEventInterface | null;
  suspectReports: ReportInterface[] | null;
  injuredReports: ReportInterface[] | null;
  reportTypes: OrgTypeInterface[] | null;
  suspectProfiles: Profile[] | null;
  injuredProfiles: Profile[] | null;
  eventJoinRequests: EventJoinResponseInterface[];
  isAcceptDenyJoinRequestInProgress: boolean;
  selectedFilter: string;
  has_joined: boolean | null;
  eventList: null | Array<OverRideEventInterface> | [];
}

const initialState: EventState = {
  isLoading: false,
  onGoingEvent: null,
  suspectReports: null,
  injuredReports: null,
  reportTypes: null,
  eventJoinRequests: [],
  isAcceptDenyJoinRequestInProgress: false,
  suspectProfiles: null,
  injuredProfiles: null,
  selectedFilter: FILTER_TYPES.ACTIVE,
  has_joined: null,
  eventList: null,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    requestActiveEvent: (
      state,
      action: PayloadAction<PositionInterface | null>,
    ) => {
      state.isLoading = true;
    },
    successActiveEvent: (
      state,
      action: PayloadAction<{
        onGoingEvent: OverRideEventInterface | null;
        eventList: null | Array<OverRideEventInterface>;
      }>,
    ) => {
      state.onGoingEvent = action.payload.onGoingEvent;
      state.isLoading = false;
      state.eventList = action.payload.eventList;
    },
    failureActiveEvent: (state) => {
      state.onGoingEvent = null;
      state.isLoading = false;
    },
    fetchReportsRequest: (state, action: PayloadAction<EventId>) => {
      state.isLoading = true;
    },
    fetchReportsSuccess: (
      state,
      action: PayloadAction<ReportsListSuccessPayload>,
    ) => {
      state.isLoading = false;
      state.suspectReports = getSortedDescendingOrderList(
        action.payload.suspectReports,
      );
      state.injuredReports = getSortedDescendingOrderList(
        action.payload.injuredReports,
      );
    },
    fetchReportsFailure: (state) => {
      state.isLoading = false;
    },
    addNewProfileRequest: (state, action: PayloadAction<AddProfilePayload>) => {
      state.isLoading = true;
    },
    addNewProfileSuccess: (
      state,
      action: PayloadAction<ProfileListSuccessPayload>,
    ) => {
      state.isLoading = false;
      state.suspectProfiles = getSortedAscendingOrderList(
        action.payload.suspectProfiles,
      );
      state.injuredProfiles = getSortedAscendingOrderList(
        action.payload.injuredProfiles,
      );
    },
    addNewProfileFailure: (state) => {
      state.isLoading = false;
    },
    fetchReportTypesRequest: (state) => {
      state.isLoading = true;
    },
    fetchReportTypesSuccess: (
      state,
      action: PayloadAction<OrgTypeInterface[]>,
    ) => {
      state.isLoading = false;
      state.reportTypes = action.payload;
    },
    fetchReportTypesFailure: (state) => {
      state.isLoading = false;
    },
    fetchEventJoinRequestsRequest: (
      state,
      action: PayloadAction<GetEventJoinRequestsPayload>,
    ) => {
      state.isLoading = true;
    },
    fetchEventJoinRequestsSuccess: (
      state,
      action: PayloadAction<EventJoinResponseInterface[]>,
    ) => {
      state.isLoading = false;
      state.eventJoinRequests = action.payload;
    },
    fetchEventJoinRequestsFailure: (state) => {
      state.isLoading = false;
    },
    acceptEventJoinRequestRequest: (
      state,
      action: PayloadAction<AcceptEventJoinRequestPayload>,
    ) => {
      state.isLoading = true;
      state.isAcceptDenyJoinRequestInProgress = true;
    },
    acceptEventJoinRequestSuccess: (
      state,
      action: PayloadAction<EventJoinResponseInterface>,
    ) => {
      state.isLoading = false;
      if (state.eventJoinRequests.length > 0) {
        const index = state.eventJoinRequests.findIndex(
          (request) => request.id === action.payload.id,
        );
        if (index !== -1) {
          state.eventJoinRequests?.splice(index, 1);
        }
      }
      state.isAcceptDenyJoinRequestInProgress = false;
    },
    acceptEventJoinRequestFailure: (state) => {
      state.isLoading = false;
      state.isAcceptDenyJoinRequestInProgress = false;
    },
    //
    denyEventJoinRequestRequest: (
      state,
      action: PayloadAction<DenyEventJoinRequestPayload>,
    ) => {
      state.isLoading = true;
      state.isAcceptDenyJoinRequestInProgress = true;
    },
    denyEventJoinRequestSuccess: (
      state,
      action: PayloadAction<EventJoinResponseInterface>,
    ) => {
      state.isLoading = false;
      if (state.eventJoinRequests.length > 0) {
        const index = state.eventJoinRequests.findIndex(
          (request) => request.id === action.payload.id,
        );
        if (index !== -1) {
          state.eventJoinRequests?.splice(index, 1);
        }
      }
      state.isAcceptDenyJoinRequestInProgress = false;
    },
    denyEventJoinRequestFailure: (state) => {
      state.isLoading = false;
      state.isAcceptDenyJoinRequestInProgress = false;
    },
    fetchProfilesRequest: (state, action: PayloadAction<GetProfilePayload>) => {
      state.isLoading = true;
    },
    fetchProfilesSuccess: (
      state,
      action: PayloadAction<ProfileListSuccessPayload>,
    ) => {
      state.isLoading = false;
      state.suspectProfiles = getSortedAscendingOrderList(
        action.payload.suspectProfiles,
      );
      state.injuredProfiles = getSortedAscendingOrderList(
        action.payload.injuredProfiles,
      );
    },
    fetchProfilesFailure: (state) => {
      state.isLoading = false;
    },
    updateProfilesRequest: (
      state,
      action: PayloadAction<UpdateProfilePayload>,
    ) => {
      state.isLoading = true;
    },
    updateProfilesSuccess: (
      state,
      action: PayloadAction<ProfileListSuccessPayload>,
    ) => {
      state.isLoading = false;
      state.suspectProfiles = getSortedAscendingOrderList(
        action.payload.suspectProfiles,
      );
      state.injuredProfiles = getSortedAscendingOrderList(
        action.payload.injuredProfiles,
      );
    },
    updateProfilesFailure: (state) => {
      state.isLoading = false;
    },
    addPersonDescriptionRequest: (
      state,
      action: PayloadAction<AddPersonDescriptionPayload>,
    ) => {
      state.isLoading = true;
    },
    addPersonDescriptionSuccess: (state) => {
      state.isLoading = false;
    },
    addPersonDescriptionFailure: (state) => {
      state.isLoading = false;
    },
    endEventRequest: (state, action: PayloadAction<EndEventPayload>) => {
      state.isLoading = true;
    },
    endEventSuccess: (state) => {
      state.isLoading = false;
    },
    endEventFailure: (state) => {
      state.isLoading = false;
    },
    leaveActiveEventRequest: (
      state,
      action: PayloadAction<LeaveActiveEventPayload>,
    ) => {
      state.isLoading = true;
    },
    leaveActiveEventSuccess: (state) => {
      //clearing event related data
      state.isLoading = false;
      state.onGoingEvent = null;
      state.suspectReports = null;
      state.injuredReports = null;
      state.reportTypes = null;
      state.eventJoinRequests = [];
      state.isAcceptDenyJoinRequestInProgress = false;
      state.suspectProfiles = null;
      state.injuredProfiles = null;
      state.selectedFilter = FILTER_TYPES.ACTIVE;
      state.has_joined = false;
    },
    leaveActiveEventFailure: (state) => {
      state.isLoading = false;
    },
    setSelectedFilter: (state, action: PayloadAction<string>) => {
      state.selectedFilter = action.payload;
    },
    startEventRequest: (state, action: PayloadAction<StartEvenPayload>) => {
      state.isLoading = true;
    },
    startEventSuccess: (
      state,
      action: PayloadAction<OverRideEventInterface>,
    ) => {
      state.isLoading = false;
      state.onGoingEvent = action.payload;
    },
    startEventFailure: (state) => {
      state.isLoading = false;
    },
    sendLocationRequest: (state) => {
      state.isLoading = true;
    },
    sendLocationSuccess: (state) => {
      state.isLoading = false;
    },
    sendLocationFailure: (state) => {
      state.isLoading = false;
    },
    setHasJoined: (state, action: PayloadAction<boolean>) => {
      state.has_joined = action.payload;
    },
    eventJoinRequest: (state, action: PayloadAction<JoinEventPayload>) => {
      state.isLoading = true;
    },
    eventJoinSuccess: (state) => {
      state.isLoading = false;
    },
    eventJoinFailure: (state) => {
      state.isLoading = false;
    },
    cancelEventJoinRequest: (
      state,
      action: PayloadAction<CancelEventJoinPayload>,
    ) => {
      state.isLoading = true;
    },
    cancelEventJoinSuccess: (state) => {
      state.isLoading = false;
    },
    cancelEventJoinFailure: (state) => {
      state.isLoading = false;
    },
    reportCERequest: (state, action: PayloadAction<ReportCEPayload>) => {
      state.isLoading = true;
    },
    reportCESuccess: (state) => {
      state.isLoading = false;
    },
    reportCEFailure: (state) => {
      state.isLoading = false;
    },
  },
});

export const eventActions = eventSlice.actions;

export default eventSlice.reducer;
