import { CategoryInterface } from 'store/app';
import { ResetPasswordPayload } from 'store/organization';
import { API_SERVICE_TYPES } from 'utils/constants';

import { apiService } from './module/api.service';

interface ResetPassword {
  token: string;
  password: string;
}
export const getUsers = async (): Promise<any> => {
  return <Promise<CategoryInterface>>apiService('/user', API_SERVICE_TYPES.GET);
};

export const getParticularUsersDetails = async (
  userId: string,
): Promise<any> => {
  return <Promise<CategoryInterface>>(
    apiService(`/user/${userId}`, API_SERVICE_TYPES.GET)
  );
};

export const resetPassword = async (body: ResetPassword): Promise<any> => {
  return <Promise<CategoryInterface>>(
    apiService(`/reset_password`, API_SERVICE_TYPES.POST, body)
  );
};
