import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router';
import { COLORS } from '../../styles/colorConstants';
import {
  getOrganizationFormValue,
  getPoliceDeptFormValue,
  getSelectedOrganization,
  isOrganizationDeptFormTouched,
  isPoliceDeptFormTouched,
} from '../../store';
import clsx from 'clsx';
import {
  NAV_LINKS,
  ORG_FORM_KEYS,
  READ_USER_SCOPES,
  REDUX_FORM_CONSTANT,
  USER_FORM_KEYS,
} from 'utils/constants';
import {
  isOrgRoute,
  isEmsRoute,
  isPoliceDeptRoute,
  isSpecialEventRoute,
  isUserRoute,
  compareFormObjects,
} from 'utils/helper';
import {
  getCurrentUser,
  getUserForms,
  haveValidScope,
  useModal,
} from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { destroy } from 'redux-form';
import { FormSaveConfirmationModal } from 'components/Form/FormModal';

const useNavStyles = makeStyles((theme: Theme) =>
  createStyles({
    navContainer: {
      // width: '75%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    navButton: {
      color: COLORS.TEAL,
      backgroundColor: 'unset',
      border: 0,
      height: '100%',
      outline: 'none',
      cursor: 'pointer',
      opacity: 0.7,
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      font: 'normal normal 600 16px/25px Poppins',
      borderBottom: '3px solid transparent',
    },
    active: {
      color: COLORS.LIGHT_GREY,
      borderBottom: `3px solid ${COLORS.WHITE}`,
    },
  }),
);

type NavObj = typeof NAV_LINKS[0];

const useActiveLink = (): NavObj => {
  const [isActive, setIsActive] = useState(NAV_LINKS[0]);
  const currentRoute = useLocation();
  useEffect(() => {
    const pathName = currentRoute.pathname;
    if (isOrgRoute(pathName)) {
      setIsActive(NAV_LINKS[0]);
    }

    if (isPoliceDeptRoute(pathName)) {
      setIsActive(NAV_LINKS[1]);
    }

    if (isEmsRoute(pathName)) {
      setIsActive(NAV_LINKS[2]);
    }

    if (isSpecialEventRoute(pathName)) {
      setIsActive(NAV_LINKS[3]);
    }

    if (isUserRoute(pathName)) {
      setIsActive(NAV_LINKS[4]);
    }
  }, [currentRoute.pathname]);

  return isActive;
};

// const authNavLinks = (authOrgName: string | undefined) => {
//   console.log(authOrgName);
//   switch (authOrgName) {
//     case APP_CATEGORY.EMS:
//       return _.filter(
//         NAV_LINKS,
//         (obj) =>
//           obj.name.toLowerCase() === APP_CATEGORY.EMS.toLowerCase() ||
//           obj.name === 'Users',
//       );
//     case APP_CATEGORY.POLICE_DEPARTMENT:
//       return _.filter(
//         NAV_LINKS,
//         (obj) =>
//           obj.name === APP_CATEGORY.POLICE_DEPARTMENT || obj.name === 'Users',
//       );
//     case APP_CATEGORY.ORGANIZATION:
//       console.log('here', APP_CATEGORY.ORGANIZATION);
//       return _.filter(
//         NAV_LINKS,
//         (obj) => obj.name === 'Organization' || obj.name === 'Users',
//       );
//     default:
//       return [];
//   }
// };

const NavSection: React.FC = () => {
  const classes = useNavStyles();
  const [open, openModal, closeModal] = useModal();
  const currentOrg = useSelector(getSelectedOrganization);
  const historyObj = useHistory();
  const activeNavObj = useActiveLink();
  const areUsersReadable = haveValidScope(READ_USER_SCOPES);
  const getOrgForm = useSelector(getOrganizationFormValue);
  const getPoliceForm = useSelector(getPoliceDeptFormValue);
  const currentUser = getCurrentUser();
  const [userForm, isUserTouched] = getUserForms();
  const isPoliceFormTouched = useSelector(isPoliceDeptFormTouched);
  const isOrgFormTouched = useSelector(isOrganizationDeptFormTouched);

  const dispatch = useDispatch();
  const [link, setLink] = useState<any>('');
  // const scope = getAuthScope();

  const destroyForms = (link: any) => {
    dispatch(destroy(REDUX_FORM_CONSTANT.ORGANIZATION));
    dispatch(destroy(REDUX_FORM_CONSTANT.POLICE_DEPT));
    historyObj.push(link.path);
  };

  const handleNavClick = (linkObj: NavObj) => {
    setLink(linkObj);
    if (
      (isPoliceFormTouched &&
        compareFormObjects(currentOrg, getPoliceForm, ORG_FORM_KEYS)) ||
      (isOrgFormTouched &&
        compareFormObjects(currentOrg, getOrgForm, ORG_FORM_KEYS)) ||
      (isUserTouched &&
        compareFormObjects(currentUser, userForm, USER_FORM_KEYS))
    ) {
      openModal();
    } else destroyForms(linkObj);
  };

  const handleStay = () => {
    closeModal();
  };

  const handleLeave = () => {
    closeModal();
    destroyForms(link);
  };
  return (
    <div data-cy="nav-tabs" className={classes.navContainer}>
      {NAV_LINKS.map(
        (link) =>
          (link.name !== 'Users' ||
            (link.name === 'Users' && areUsersReadable)) && (
            <button
              className={clsx(classes.navButton, {
                [classes.active]: activeNavObj.name === link.name,
              })}
              key={link.key}
              data-cy={link.name}
              onClick={() => handleNavClick(link)}
            >
              {link.name}
            </button>
          ),
      )}
      <FormSaveConfirmationModal
        handleLeave={handleLeave}
        handleStay={handleStay}
        open={open}
      />
    </div>
  );
};

export default NavSection;
