import { videoCallStatus } from 'config/videoCall';
import dayjs from 'dayjs';
import { InitiateVideoCallData, VideoCallData } from 'store/videoCall';
import { API_SERVICE_TYPES } from 'utils/constants';

import { apiService } from './module/api.service';

export const setStatusVideoCall = (
  id: string,
  status: videoCallStatus,
): Promise<VideoCallData> => {
  return <Promise<VideoCallData>>(
    apiService(`/video_call/${id}`, API_SERVICE_TYPES.PATCH, {
      status,
    })
  );
};

export interface InitiateVideoCallResponseInterface {
  data: InitiateVideoCallData;
}

export interface GetVideoCallResponseInterface {
  data: VideoCallData[];
}

export const initiateVideoCall = (
  data: InitiateVideoCallData,
): Promise<InitiateVideoCallResponseInterface> => {
  return <Promise<InitiateVideoCallResponseInterface>>(
    apiService(`/video_call`, API_SERVICE_TYPES.POST, {
      receiver: { id: data.receiverId },
      report: { id: data.reportId },
    })
  );
};

export const getActiveVideoCall = (): Promise<GetVideoCallResponseInterface> => {
  return <Promise<GetVideoCallResponseInterface>>(
    apiService(`/video_call`, API_SERVICE_TYPES.GET)
  );
};

export const pingVideoCall = (videoCallId: string) => {
  return <Promise<string>>(
    apiService(`/video_call/${videoCallId}/ping`, API_SERVICE_TYPES.POST, {
      ping_timestamp: dayjs().toISOString(),
    })
  );
};
