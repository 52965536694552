import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from 'styles/colorConstants';
import { horizontalScale, verticalScale } from 'utils/dimensions';

export const useResetPasswordStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      backgroundColor: COLORS.RAISIN_BLACK,
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'flex-start',
      flex: 1,
    },
    formContainer: {
      margin: 'auto',
    },
    titleText: {
      color: COLORS.WHITE,
      '& p': {
        fontSize: '20px',
        fontWeight: '600',
        marginBottom: theme.spacing(1),
      },
      textAlign: 'center',
      marginTop: verticalScale(172),
      marginBottom: verticalScale(122),
    },
    fieldContainer: {
      maxWidth: horizontalScale(329),
      alignItems: 'center',
    },
    appBar: {
      backgroundColor: COLORS.CHARCOAL,
    },
    toolbar: {
      padding: theme.spacing(0),
    },
    logoCtn: {
      height: '100%',
      width: '100%',
      marginLeft: horizontalScale(69),
    },
    logo: {
      width: horizontalScale(76),
      marginTop: theme.spacing(1.3),
    },
    btn: {
      marginTop: verticalScale(53),
    },
    resetIcon: {
      color: COLORS.MAX_BLUE_GREEN,
      width: '80px',
      height: '80px',
      marginBottom: '40px',
    },
    successCtn: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px',
    },
    successText: {
      display: 'flex',
      flexWrap: 'wrap',
      fontFamily: 'Poppins',
      textAlign: 'center',
      fontSize: '20px',
      color: COLORS.WHITE,
      maxWidth: '312px',
    },
    text: {
      fontFamily: 'Poppins',
      textAlign: 'center',
      fontSize: '12px',
      color: COLORS.RED,
    },
    btmMargin: {
      marginBottom: verticalScale(53),
      marginTop: verticalScale(53),
    },
  });
});
