import React from 'react';
import {
  styled,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Flex from 'components/General/Flex';
import IconButton from '@material-ui/core/IconButton';
import { COLORS } from 'styles/colorConstants';
import { NavIconInterface, NavButtonInterface } from 'utils/commonTypes';
import { haveValidScope } from 'utils/hooks';
import { useRouteMatch } from 'react-router';
import _ from 'lodash';
import { READ_ORG_SCOPES } from 'utils';

// import Explore from '@material-ui/icons/Explore';
// import Groups from '@material-ui/icons/Group';
// import AddIcon from '@material-ui/icons/Add';
// import HomeWork from '@material-ui/icons/HomeWorkSharp';
// import SvgIcon from '@material-ui/core/SvgIcon';

interface NavigationLinkProps {
  children?: React.ReactNode;
  isActive?: boolean;
  linkObj?: NavButtonInterface;
  onClick?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    iconButtonContainer: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      borderRadius: 'unset',
      backgroundColor: (props: any) => {
        if (props.isActive) {
          return COLORS.PERSIAN_GREEN;
        }
        return 'unset';
      },
      '&:hover': {
        backgroundColor: (props: any) => {
          if (props.isActive) {
            return fade(COLORS.PERSIAN_GREEN, 0.1);
          }
          return fade(COLORS.PERSIAN_GREEN, 0.5);
        },
      },
      '&.Mui-disabled': {
        backgroundColor: (props: any) => {
          if (props.isActive) {
            return COLORS.PERSIAN_GREEN;
          }
          return 'unset';
        },
        color: '#ff0000',
      },
    },
    icon: {
      fontSize: '2rem',
    },
    disabledIcon: {
      opacity: 0.3,
    },
  }),
);

const Icon = styled('img')({
  width: '28px',
  height: '28px',
});

export const NavigationLink: React.FC<NavigationLinkProps> = (
  props: NavigationLinkProps,
) => {
  const { isActive, linkObj, onClick } = props;
  const currentRoute = useRouteMatch();
  const orgId = _.get(currentRoute, 'params.id', '');
  const classes = useStyles({ isActive });
  const isDisabled = linkObj?.id !== 'details' && orgId === 'new';

  const renderIcon = (
    icon: NavIconInterface | null | undefined,
    id: string | null | undefined,
  ) => {
    if (icon) {
      return (
        <Icon
          data-cy={
            id === 'users' ? 'Users list' : id === 'explore' ? 'Maps' : id
          }
          src={icon.src}
          alt={icon?.name}
          className={isDisabled ? classes.disabledIcon : classes.icon}
        />
      );
    }

    return <span> no Icon </span>;
  };

  return (
    // @todo
    // @ts-ignore
    <Flex className={classes.container}>
      {(linkObj?.id !== 'org' ||
        (linkObj?.id === 'org' && haveValidScope(READ_ORG_SCOPES))) && (
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          className={classes.iconButtonContainer}
          disabled={isDisabled}
          onClick={() => onClick(linkObj)}
        >
          {renderIcon(linkObj?.icon, linkObj?.id)}
        </IconButton>
      )}
    </Flex>
  );
};

export default NavigationLink;
