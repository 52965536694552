export const BASE_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:5000';
export const PUBSUB_BASE_URL =
  process.env.REACT_APP_PUBSUB_URL || 'http://127.0.0.1:4000';
export const GRANT_TYPE = process.env.REACT_APP_GRANT_TYPE;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || '';
export const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID || '';
export const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_FIREBASE_AUTH_DOMAIN || '';
export const FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID || '';
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '';
export const FIREBASE_SENDER_ID =
  process.env.REACT_APP_FIREBASE_SENDER_ID || '';
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID || '';
export const FIREBASE_MEASUREMENT_ID_APP_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '';
export const VAPID = process.env.REACT_APP_FIREBASE_VAPID || '';
export const GOOGLE_MAP = process.env.REACT_APP_GOOGLE_MAP || '';
// export const LAUNCH_DARKLY_TEST_CLIENT =
//   process.env.REACT_APP_LAUNCH_DARKLY_TEST_CLIENT_ID || '';
