import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLORS } from 'styles/colorConstants';
import clsx from 'clsx';

interface SpinnerInterface {
  showSpinner: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    coverSpin: {
      position: 'fixed',
      width: '100%',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: COLORS.BLACK,
      opacity: 0.8,
      zIndex: 9999,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hideSpinner: {
      display: 'none',
    },
  }),
);

const CircularIndeterminate: React.FC<SpinnerInterface> = ({
  showSpinner,
}: SpinnerInterface) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.coverSpin, {
        [classes.hideSpinner]: showSpinner === false,
      })}
    >
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default CircularIndeterminate;
