import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { isNilOrEmpty, isCreateAction } from 'utils/helper';
import { ORGANIZATION_ROUTES } from 'routes/path';
import {
  getOrganizationUsers,
  getSelectedOrganization,
} from 'store/organization/organization.selectors';
import { ORGANIZATION_USER_LIST_TABLE_COLUMN } from 'utils/constants';
import {
  OrganizationUserTypeInterface,
  organizationActions,
} from 'store/organization';
import Table from 'components/General/Table';
import { replaceKeyWithValues } from './helper';

export const OrganizationUsers: React.FC = () => {
  const organizationUser = useSelector(getOrganizationUsers);
  const currentRoute = useRouteMatch();
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrganization);
  // @ts-ignore
  const orgId = currentRoute.params?.id;

  useEffect(() => {
    if (!isCreateAction(orgId) && !isNilOrEmpty(orgId)) {
      dispatch(
        organizationActions.fetchOrgSpecificData({
          // @ts-ignore
          orgId,
          showLoader: true,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(selectedOrg)) {
      dispatch(
        organizationActions.fetchParticularOrganizationDetailsRequest({
          orgId: orgId,
        }),
      );
    }
  }, [selectedOrg]);

  const handleRowClick = (rowObj: OrganizationUserTypeInterface) => {
    const organizationId = _.get(currentRoute.params, 'id', '');
    const slug = ORGANIZATION_ROUTES.ORGANIZATION_USERS_DETAILS.path;
    const replaceWith = {
      id: organizationId,
      userId: rowObj.id,
    };
    let url = replaceKeyWithValues(slug, replaceWith);
    console.log('%c url ', 'background: lime; color: black', url);
    dispatch(organizationActions.userRowClick(null));

    if (_.isArray(url)) {
      url = url[1];
    }
    dispatch(push(url));
  };
  return (
    <div>
      {' '}
      <Table
        columnData={ORGANIZATION_USER_LIST_TABLE_COLUMN}
        rowData={organizationUser.data}
        onRowClick={(rowObj) => {
          handleRowClick(rowObj);
        }}
      />{' '}
    </div>
  );
};

export default OrganizationUsers;
