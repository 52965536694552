import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthUserInterface } from 'utils';

import { AuthActionPayloadInterface } from './login.type';

interface OrganizationState {
  isLoading: boolean;
  isIpAdressLoading: boolean;
  auth: AuthUserInterface | null;
  ipAddress: string | undefined;
}

const initialState: OrganizationState = {
  isLoading: false,
  isIpAdressLoading: false,
  auth: null,
  ipAddress: undefined,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    authAsyncRequest: (
      state,
      action: PayloadAction<AuthActionPayloadInterface>,
    ) => {
      state.isLoading = true;
    },
    authAsyncSuccess: (
      state,
      action: PayloadAction<AuthUserInterface | null>,
    ) => {
      state.isLoading = false;
      state.auth = action.payload;
    },
    setAuth: (state, action: PayloadAction<AuthUserInterface | null>) => {
      state.auth = action.payload;
      state.isLoading = false;
    },
    authAsyncFailure: (state) => {
      state.isLoading = false;
    },
    isAuthenticated: (state) => {
      state.isLoading = true;
    },
    fetchIpAddressAsyncRequest: (state) => {
      state.isIpAdressLoading = true;
    },
    fetchedIpAddressAsyncSuccess: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.ipAddress = action.payload;
      state.isIpAdressLoading = false;
    },
    fetchedIpAddressAsyncFailure: (state) => {
      state.isIpAdressLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logoutUser: (state) => {},
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
