import React, { Fragment } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { COLORS } from '../../styles/colorConstants';
import LogoSection from './LogoSection';
// import NavSection from './NavSection';
import Logout from './Logout';
import NavSection from './NavSection';
import OpsNavSection from './OpsCenterNavSection';
import { CERA_ADMIN_SCOPES } from 'utils';
import { haveValidScope, isOpsCenter } from 'utils/hooks';

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: COLORS.CHARCOAL,
    },
    toolbar: {
      padding: theme.spacing(0),
    },
    container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export const Header: React.FC = () => {
  const classes = useHeaderStyles();
  const opsCenter = isOpsCenter();
  return (
    <Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.container}>
            <LogoSection />

            {haveValidScope(CERA_ADMIN_SCOPES) && <NavSection />}
            {opsCenter && <OpsNavSection />}
            <Logout />
          </div>
        </Toolbar>
      </AppBar>

      <Toolbar />
    </Fragment>
  );
};

export default Header;
