import { SPECIAL_EVENT_ROUTES } from 'routes/path';
import { APP_ASSETS } from 'utils/asset';

export const SPECIAL_EVENT_NESTED_LINKS = {
  details: {
    id: 'details',
    name: 'Details',
    routeSlug: [SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_DETAILS.path],
    routePost: '/',
    icon: {
      src: APP_ASSETS.ORG_DETAILS,
      name: 'Special Event',
    },
  },
  users: {
    id: 'users',
    name: 'Users',
    routeSlug: [
      SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_USERS.path,
      SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_USERS_DETAILS.path,
    ],
    routePost: 'users',
    icon: {
      src: APP_ASSETS.ORG_USERS,
      name: 'Special Event Users',
    },
  },
  explore: {
    id: 'explore',
    name: 'Map',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_MAP.path,
    routePost: 'maps',
    icon: {
      src: APP_ASSETS.MAP,
      name: 'Maps',
    },
  },
  qrCode: {
    id: 'qrCode',
    name: 'Special Event QR Code',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_QR_CODE.path,
    routePost: 'qr',
    icon: {
      src: APP_ASSETS.QR_CODE_ICON,
      name: 'Special Event QR Code',
    },
  },
};

export const SPECIAL_EVENT_ACTION_BUTTON = {
  addOrganization: {
    id: 'addOrganization',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_LIST.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_ACTION,
      name: 'Add Special Event Organization',
    },
  },
  startEvent: {
    id: 'startEvent',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_LIST.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_ACTION,
      name: 'Add Organization',
    },
  },
  updateOrganizationDetails: {
    id: 'addOrEditOrganizationDetails',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'Save Special Event Organization',
    },
    type: 'submit',
  },
  activateOrgDept: {
    id: 'activateOrgDept',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.INACTIVE_ICON,
      name: 'Activate Special Event Org',
    },
  },
  deActivateOrgDept: {
    id: 'deActivateOrgDept',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ACTIVE_ICON,
      name: 'Deactivate Special Event Org',
    },
  },
  addUserToOrganization: {
    id: 'addUserToOrganization',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_USER_ACTION,
      name: 'Add Users',
    },
  },
  updateUserDetails: {
    id: 'updateUserDetails',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'Save User',
    },
    type: 'submit',
  },
  updateMapDetails: {
    id: 'updateMapDetails',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_MAP.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'Save Special Event Org',
    },
  },
  activateOrgDeptUser: {
    id: 'activateOrgDeptUser',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.INACTIVE_ICON,
      name: 'Activate User',
    },
  },
  deActivateOrgDeptUser: {
    id: 'deActivateOrgDeptUser',
    routeSlug: SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ACTIVE_ICON,
      name: 'Deactivate User',
    },
  },
};

export const SPECIAL_EVENT_ACTIVE_SUB_HEADER_SECTION = {
  leftSection: true,
  middleSection: false,
  rightSection: false,
};
