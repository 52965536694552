// Firebase config
import { initializeApp } from 'firebase/app';
import { getMessaging, Messaging } from 'firebase/messaging';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

import {
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_SENDER_ID,
  FIREBASE_APP_ID,
} from './api';

const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_GOOGLE_MAP,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_SENDER_ID,
  appId: FIREBASE_APP_ID,
});

export let messaging: null | Messaging = null;
// @ts-ignore
if (firebase.messaging.isSupported()) {
  messaging = getMessaging(firebaseApp);
} else {
  console.log('Sorry! Not supported in your browser.');
}
