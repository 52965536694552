import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useMapContainerStyle = makeStyles((theme: Theme) => {
  return createStyles({
    app: {
      height: '100%',
      width: '100%',
      flex: 1,
    },
    container: {
      width: '100%',
      height: '100%',
    },
    mapContainer: {
      // display: 'flex',
      width: '100%',
      height: '100%',
      // paddingLeft: theme.spacing(8),
    },
    'app-map': {
      height: '100%',
      width: '100%',
    },
  });
});

export const useControllerStyle = makeStyles((theme: Theme) => {
  return createStyles({
    actionContainer: {
      maxWidth: '100px',
      width: '100%',
      padding: '10px',
    },
    disabled: {
      cursor: 'not-allowed',
    },
    actionMenuItem: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& .menuLabel': {
        fontSize: theme.typography.pxToRem(9),
        letterSpacing: '0.24px',
      },
    },
  });
});
