import { useState, useEffect } from 'react';
import _, { isBoolean } from 'lodash';
import { useActiveAppRouteMatch } from 'utils/hooks';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppRouteInterface } from 'routes/path';
import { getHeaderSectionVisibility } from 'routes/PoliceDepartment/utils/helper';
import {
  POLICE_DEPT_ACTIVE_SUB_HEADER_SECTION,
  POLICE_DEPT_FORM_FIELDS,
} from 'routes/PoliceDepartment/utils/constants';
import { getPoliceDeptFormValue } from 'store/policeDept';
import { getSelectedOrganization } from 'store/organization/';
import { FormField } from 'utils/types';

type VisibilityObjType = typeof POLICE_DEPT_ACTIVE_SUB_HEADER_SECTION;

export const useUpdatePoliceDeptHeaderVisibility = (
  appRoutesObj: AppRouteInterface,
  defaultVisibilityObj: VisibilityObjType,
): VisibilityObjType => {
  const activeRoute = useActiveAppRouteMatch(appRoutesObj);
  const currentRoute = useRouteMatch();
  const [activeSubHeaderSection, setSubHeaderSection] = useState(
    defaultVisibilityObj,
  );

  useEffect(() => {
    const subHeaderSectionVisibility = getHeaderSectionVisibility(activeRoute);
    setSubHeaderSection(subHeaderSectionVisibility);
    return () => {
      setSubHeaderSection(defaultVisibilityObj);
    };
  }, [currentRoute.url, activeRoute?.path]);

  return activeSubHeaderSection;
};

export const useInitializeForm = () => {
  const [formFieldsArr, setFormFieldArr] = useState<FormField[]>([]);
  const formValue = useSelector(getPoliceDeptFormValue);
  const initialValue = useSelector(getSelectedOrganization);
  const currentRoute = useRouteMatch();
  const orgId = _.get(currentRoute, 'params.id', '');

  useEffect(() => {
    const fieldArr = _.cloneDeep(POLICE_DEPT_FORM_FIELDS);
    setFormFieldArr(fieldArr);
    // if (orgId !== 'new') {
    //   fieldArr.map((obj) => {
    //     const isDisableFieldPresent = _.get(obj, 'disable', '');
    //     if (
    //       isBoolean(isDisableFieldPresent) &&
    //       obj.name === 'is_special_event_org'
    //     ) {
    //       obj.disable = true;
    //     }
    //   });
    // }
    // if (formValue && formValue.state && formValue.country) {
    // }
    // setFormFieldArr(fieldArr);
  }, []);

  console.log('%c fieldArr ', 'background: lime; color: black', {
    formFieldsArr,
  });
  return [formFieldsArr, initialValue, formValue];
};
