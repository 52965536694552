// import React from 'react';

// const EditUsers = () => {
//   return <div> Edit Users </div>;
// };

// export default EditUsers;

import React, { useEffect } from 'react';
import _ from 'lodash';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
import { useRouteMatch } from 'react-router';
import { useFetchOrgDetails } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { FormField } from 'utils/types';
import { FormContainer } from 'components/Form';
import {
  useInitializeForm,
  isFormFieldValueChange,
  useGetUserRoles,
  useGetPersonType,
  updateRolesOptions,
} from 'routes/Users/utils/hook';
import { REDUX_FORM_CONSTANT } from 'utils/constants';
import { getAllRoles, getUserRoleList, usersActions } from 'store/users';
import { allOrgPersonTypes } from 'store';
import { validateOrgForm } from 'routes/Organization/helper';

type FormWrapperProps = { fieldArr: FormField[] };

const UserDetailsFormWrapper = (
  props: FormWrapperProps & InjectedFormProps<unknown, FormWrapperProps>,
) => {
  return <FormContainer fieldArr={props.fieldArr} />;
};

const UserDetailsRFWrapper = reduxForm<unknown, FormWrapperProps>({
  form: REDUX_FORM_CONSTANT.USER_DETAILS,
  onSubmit: validateOrgForm,
})(UserDetailsFormWrapper);

export const CreateUserDetailsForm: React.FC = () => {
  const dispatch = useDispatch();
  const [fieldArr, setFormFieldArr, initialFormValue] = useInitializeForm();
  const allPersonTypes = useSelector(allOrgPersonTypes);
  const allRoles = useSelector(getUserRoleList);
  const allRolesInList = useSelector(getAllRoles);
  const currentRoute = useRouteMatch();
  const orgId = _.get(currentRoute, 'params.id', '');
  if (orgId) {
    useFetchOrgDetails(orgId);
  }
  useEffect(() => {
    dispatch(usersActions.fetchUserRolesRequest());
  }, []);
  const handleChange = (formValue: any, ...arg: any) => {
    const previousFormValue = arg.pop();
    const isValueUpdated = isFormFieldValueChange(
      formValue,
      previousFormValue,
      'organization',
    );
    if (isValueUpdated) {
      // const isSEO = _.get(formValue, 'organization.is_special_event_org', '');
      const orgTypeId = _.get(formValue, 'organization.organization_type', '');
      const newRoles = useGetUserRoles(orgTypeId, allRoles, initialFormValue);
      const newPersonTypeOptions = useGetPersonType(orgTypeId, allPersonTypes);
      updateRolesOptions(newRoles, fieldArr, setFormFieldArr, 'role');
      updateRolesOptions(
        newPersonTypeOptions,
        fieldArr,
        setFormFieldArr,
        'person_type',
      );
      if (newRoles.length === 0 && !_.isEmpty(allRoles)) {
        dispatch(change(REDUX_FORM_CONSTANT.USER_DETAILS, 'role', null));
      }
      if (newPersonTypeOptions.length === 0 && allPersonTypes?.length !== 0) {
        dispatch(change(REDUX_FORM_CONSTANT.USER_DETAILS, 'person_type', null));
      }
    }
    const isCeraAdminType =
      _.get(initialFormValue, 'organization', '') === null;
    if (isCeraAdminType) {
      const newRoles = useGetUserRoles('', allRoles, initialFormValue);
      updateRolesOptions(newRoles, fieldArr, setFormFieldArr, 'role');
    }
  };

  return (
    <UserDetailsRFWrapper
      fieldArr={fieldArr}
      initialValues={initialFormValue}
      onChange={handleChange}
    />
  );
};

export default CreateUserDetailsForm;
