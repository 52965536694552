import { all, call, takeLatest } from 'redux-saga/effects';

import { themeActions } from './slice';
import { SetTheme } from './types';

function* saveColorMode(action: SetTheme) {
  yield call((k, v) => localStorage.setItem(k, v), 'colorMode', action.payload);
}

export function* themeSaga(): Generator {
  yield all([takeLatest(themeActions.setTheme, saveColorMode)]);
}
