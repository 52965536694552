import { EventUserResponseInterface } from 'utils';
import { API_SERVICE_TYPES } from 'utils/constants';
import { apiService } from './module/api.service';

export interface GetEventUserResponse {
  data: EventUserResponseInterface[];
}
export const getAllEventUsers = (
  id: string,
  last_fetched_time?: string,
): Promise<GetEventUserResponse> => {
  let extendedQuery = '';
  if (last_fetched_time && last_fetched_time !== '') {
    extendedQuery = '?last_updated_at=$lastFetchedTime';
  }
  return <Promise<GetEventUserResponse>>(
    apiService(`/event/${id}/users${extendedQuery}`, API_SERVICE_TYPES.GET)
  );
};
