import {
  Divider,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import { NavigationBox, NavigationLink } from 'components/SubHeader';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveEvent,
  getInjuredReports,
  getSuspectProfiles,
  getReportTypes,
  getSuspectReports,
  getInjuredProfiles,
  getProfileFilter,
  getEventJoinRequests,
} from 'store/event/event.selector';
import {
  EventJoinResponseInterface,
  FILTER_TYPES,
  OrgTypeInterface,
  Profile,
  ReportInterface,
  REPORT_TYPE,
  showSuccessMsg,
} from 'utils';
import { EVENT_NESTED_LINKS, OpsCenterEventTabsConstants } from './constants';
import Reports from './Report';
import { useMapContainerStyle } from './style';
import AddIcon from '../../assets/white-plus.svg';
import FilterIcon from '../../assets/filter.svg';
import SuspectProfile from './SuspectProfile';
import { eventActions } from 'store/event/event.slice';
import InjuredProfile from './InjuredProfile';
import { isEmsCommander } from 'utils/hooks';
import { onMessage } from 'firebase/messaging';
import { messaging } from 'config/firebaseConfig';

import { RadioButtonUnchecked } from '@material-ui/icons';
import { CheckCircleOutline } from '@material-ui/icons';
import { InvolvedUser } from './InvolvedUser';
import { JoinRequest } from './JoinRequest';
import { EventUser } from 'store/eventUser/eventUser.slice';
import { getEventUsers } from 'store/eventUser/eventUser.selector';

export function Profiles() {
  const classes = useMapContainerStyle();
  const isEms = isEmsCommander();
  const suspectReportList = useSelector(getSuspectReports);
  const injuredReportList = useSelector(getInjuredReports);
  const [tabValue, setTabValue] = useState(isEms ? 1 : 0);
  const dispatch = useDispatch();
  const eventId = useSelector(getActiveEvent)?.id;
  const reportTypes: OrgTypeInterface[] | null = useSelector(getReportTypes);
  const suspectProfiles = useSelector(getSuspectProfiles);
  const injuredProfiles = useSelector(getInjuredProfiles);
  const eventJoinRequests: EventJoinResponseInterface[] | null = useSelector(
    getEventJoinRequests,
  );
  const eventUsersObject: Record<string, EventUser> = useSelector(
    getEventUsers,
  );

  const eventInvolvedUsers = Object.values(eventUsersObject);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const selectedFilter = useSelector(getProfileFilter);

  const isActiveLink = (index: number) => {
    return index === tabValue;
  };

  const handleNavLink = (index: number) => {
    setTabValue(index);
  };
  const handleAddProfile = () => {
    if (reportTypes && (tabValue === 2 || tabValue === 3)) {
      dispatch(
        eventActions.addNewProfileRequest({
          id: eventId,
          report_type: reportTypes.filter((reportTypeObj) =>
            tabValue === 2
              ? reportTypeObj.logical_name === REPORT_TYPE.SUSPECT
              : reportTypeObj.logical_name === REPORT_TYPE.INJURED,
          )[0].id,
        }),
      );
    }
  };
  if (messaging)
    onMessage(messaging, (payload) => {
      const notification = `${payload.notification?.body}`;
      showSuccessMsg(notification);
    });

  const filterProfiles = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCLick = (type: string) => {
    switch (type) {
      case FILTER_TYPES.RESOLVED:
        if (
          selectedFilter === FILTER_TYPES.RESOLVED ||
          selectedFilter === FILTER_TYPES.ACTIVE
        ) {
          dispatch(eventActions.setSelectedFilter(FILTER_TYPES.ALL));
        } else {
          dispatch(eventActions.setSelectedFilter(FILTER_TYPES.ACTIVE));
        }
        dispatch(
          eventActions.fetchProfilesRequest({
            id: eventId,
          }),
        );
        break;
      case FILTER_TYPES.ACTIVE:
        if (
          selectedFilter === FILTER_TYPES.RESOLVED ||
          selectedFilter === FILTER_TYPES.ACTIVE
        ) {
          dispatch(eventActions.setSelectedFilter(FILTER_TYPES.ALL));
        } else {
          dispatch(eventActions.setSelectedFilter(FILTER_TYPES.RESOLVED));
        }
        dispatch(
          eventActions.fetchProfilesRequest({
            id: eventId,
          }),
        );
        break;
    }
  };

  const tabsData = OpsCenterEventTabsConstants;

  const filterList = [
    {
      title: 'Resolved Profiles',
      value: FILTER_TYPES.RESOLVED,
    },
    {
      title: 'Active Profiles',
      value: FILTER_TYPES.ACTIVE,
    },
  ];

  const isChecked = (type: string): boolean => {
    return type === selectedFilter || selectedFilter === FILTER_TYPES.ALL;
  };

  const isTabFromFirstTwo: boolean = tabValue === 0 || tabValue === 1;
  const isTabFromLastTwo: boolean = tabValue === 2 || tabValue === 3;

  return (
    <div
      className={`${classes.profileContainer} ${
        tabValue === 0 || tabValue === 1
          ? classes.oneThirdWidth
          : classes.fullWidth
      }`}
    >
      <div className={classes.navCtn}>
        <div
          className={`${classes.profileCtn} ${classes.raisinBgColor}
            `}
        >
          <div className={classes.header}>
            <div
              className={`${classes.headerCtn} ${
                isTabFromFirstTwo ? classes.fullWidth : classes.halfWidth
              }`}
            >
              <Typography
                data-cy="incoming-reports"
                className={`${classes.headerText} ${classes.centerAlign} ${classes.padding}`}
              >
                {isTabFromFirstTwo
                  ? tabsData[tabValue].title
                  : 'Incoming Reports'}
              </Typography>
            </div>

            {isTabFromLastTwo && (
              <div className={`${classes.headerCtn} ${classes.halfWidth}`}>
                <IconButton
                  onClick={filterProfiles}
                  className={classes.headerIconCtn}
                  data-cy="filter"
                >
                  <img
                    className={classes.headerAddIcon}
                    src={FilterIcon}
                    alt=""
                  />
                </IconButton>
                <Menu
                  className={classes.menuCtnBg}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  data-cy="filter-menu"
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  {filterList.map((element, index) => (
                    <MenuItem
                      key={`${element.value}-${index}`}
                      onClick={() => onCLick(element.value)}
                      data-cy={element.title.toLowerCase().replace(' ', '-')}
                    >
                      <Checkbox
                        icon={
                          <RadioButtonUnchecked
                            className={classes.persianGreenColor}
                          />
                        }
                        checkedIcon={
                          <CheckCircleOutline
                            className={classes.persianGreenColor}
                          />
                        }
                        checked={isChecked(element.value)}
                      />
                      {element.title}
                    </MenuItem>
                  ))}
                </Menu>
                <Typography
                  data-cy={tabsData[tabValue].dataCy}
                  className={`${classes.headerText} ${classes.centerAlign} ${classes.padding}`}
                >
                  {tabsData[tabValue].title}
                </Typography>
                <IconButton
                  onClick={handleAddProfile}
                  className={classes.headerIconCtn}
                  data-cy="add-profiles"
                >
                  <img className={classes.headerAddIcon} src={AddIcon} alt="" />
                </IconButton>
              </div>
            )}
          </div>
          <div className={classes.navSection}>
            <div className={classes.report}>
              {tabValue === 0 &&
                eventJoinRequests &&
                eventJoinRequests.map(
                  (
                    eventJoinRequest: EventJoinResponseInterface,
                    index: number,
                    arr: EventJoinResponseInterface[],
                  ) => (
                    <JoinRequest
                      key={eventJoinRequest.id}
                      index={arr.length - index - 1}
                      joinRequest={eventJoinRequest}
                    />
                  ),
                )}
              {tabValue === 1 &&
                eventInvolvedUsers &&
                eventInvolvedUsers.map(
                  (
                    eventInvolvedUser: EventUser,
                    index: number,
                    arr: EventUser[],
                  ) => (
                    <InvolvedUser
                      key={eventInvolvedUser.id}
                      index={arr.length - index - 1}
                      user={eventInvolvedUser}
                    />
                  ),
                )}
              {tabValue === 2 &&
                suspectReportList?.map(
                  (
                    reportObj: ReportInterface,
                    index: number,
                    arr: ReportInterface[],
                  ) => (
                    <Reports
                      key={reportObj.id}
                      index={arr.length - index - 1}
                      report={reportObj}
                    />
                  ),
                )}
              {tabValue === 3 &&
                injuredReportList &&
                injuredReportList.map(
                  (
                    reportObj: ReportInterface,
                    index: number,
                    arr: ReportInterface[],
                  ) => (
                    <Reports
                      key={reportObj.id}
                      index={arr.length - index - 1}
                      report={reportObj}
                    />
                  ),
                )}
            </div>

            {isTabFromLastTwo && (
              <Divider
                style={{ backgroundColor: '#383838' }}
                orientation="vertical"
                flexItem
              />
            )}
            {isTabFromLastTwo && (
              <div className={classes.profilesContainer}>
                <div className={classes.profileBoxCtn}>
                  {tabValue === 2 &&
                    suspectProfiles?.map((profileObj: Profile) => (
                      <SuspectProfile
                        key={profileObj.id}
                        profile={profileObj}
                      />
                    ))}
                  {tabValue === 3 &&
                    injuredProfiles?.map(
                      (profileObj: Profile, index: number) => (
                        <InjuredProfile
                          key={profileObj.id}
                          index={index}
                          profile={profileObj}
                        />
                      ),
                    )}
                </div>
              </div>
            )}
          </div>
          {!isEms && (
            <div className={classes.tabCtn}>
              <NavigationBox>
                {(Object.keys(EVENT_NESTED_LINKS) as Array<
                  keyof typeof EVENT_NESTED_LINKS
                >).map((linkId, index) => {
                  const linkObj = EVENT_NESTED_LINKS[linkId];
                  // Index-0 SUSPECT
                  // Index-1 INJURED
                  return (
                    <NavigationLink
                      key={linkId}
                      isActive={isActiveLink(index)}
                      linkObj={linkObj}
                      onClick={() => handleNavLink(index)}
                    />
                  );
                })}
              </NavigationBox>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profiles;
