import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { isAuthorised } from 'services/localStorage.service';
import { ROUTES } from './path';
import { Layout } from '../container/Layout';

interface ProtectedRouteProps extends RouteProps {}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...props
}: ProtectedRouteProps) => {
  // const dispatch = useDispatch();
  // Logic to check if user is authorised to use the route can be modified here
  // useEffect(() => {
  //   dispatch(loginActions.isAuthenticated());
  // }, []);
  return isAuthorised() ? (
    <Layout>
      <Route {...props} component={Component} />
    </Layout>
  ) : (
    <Redirect to={ROUTES.LOGIN.path} />
  );
};
