import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { eventActions } from './event.slice';
import * as eventServices from 'services/event.services';
import * as localStorageService from 'services/localStorage.service';
import { PayloadAction } from '@reduxjs/toolkit';
import * as organizationService from 'services/organization.service';
import {
  AREventInterface,
  ARReportListInterface,
  PositionInterface,
  ReportInterface,
  REPORT_TYPE,
  ARReportTypesInterface,
  AddProfilePayload,
  ARAddProfileInterface,
  ARProfilesInterface,
  GetProfilePayload,
  UpdateProfilePayload,
  Profile,
  AddPersonDescriptionPayload,
  getNewProfileList,
  showSuccessMsg,
  FILTER_TYPES,
  showErrorMsg,
  ARJoinEventRequest,
  ARLeaveActiveEventInterface,
  GetEventJoinRequestsPayload,
  AREventJoinRequestsInterface,
  AcceptEventJoinRequestPayload,
  DenyEventJoinRequestPayload,
  ARAcceptEventJoinRequestInterface,
  ARDenyEventJoinRequestInterface,
  EventJoinRequestEnum,
} from 'utils';
import {
  EndEventPayload,
  EventId,
  organizationActions,
  StartEvenPayload,
  JoinEventPayload,
  CancelEventJoinPayload,
  ReportCEPayload,
  LeaveActiveEventPayload,
} from 'store/organization';
import { basicEventDetailsFromJson } from 'models/event/event.model';
import {
  getInjuredProfiles,
  getProfileFilter,
  getSuspectProfiles,
} from './event.selector';
import _, { isArray } from 'lodash';
import { loginActions } from 'store/login';
import { isEmsCommander, isSEManager } from 'utils/hooks';
import { getCurrentLocation } from 'utils/location';
import { getStartToken } from 'services/firebase.service';
import { getIPAddress } from 'services/geoCoding.service';
import { isMobile } from 'react-device-detect';
import { push, replace } from 'connected-react-router';
import { OPS_CENTER_ROUTES } from 'routes/path';
import { modalActions } from 'store/modal';

function* getActiveEvent(
  action:
    | PayloadAction<PositionInterface | null>
    | { payload: PositionInterface },
) {
  try {
    const response: AREventInterface = yield eventServices.getEventsService(
      action.payload?.lat || 0,
      action.payload?.lng || 0,
    );
    const isSEAdmin = isSEManager();

    const { data, meta, statusCode } = response;
    if (statusCode === 200) {
      yield put(eventActions.setHasJoined(meta.event.has_joined));
      if (meta.event.has_joined) {
        const transformEvent = basicEventDetailsFromJson(data);
        yield put(
          eventActions.successActiveEvent({
            onGoingEvent: transformEvent[0],
            eventList: null,
          }),
        );
        // For the SE manager, we cannot navigate to opsCenter
        if (!isSEAdmin) yield put(push(`/events/${data[0].id}`));
      } else {
        const transformEvent = basicEventDetailsFromJson(data);
        yield put(
          eventActions.successActiveEvent({
            onGoingEvent: null,
            eventList: transformEvent,
          }),
        );
      }
    } else if (statusCode === 204) {
      yield put(eventActions.setHasJoined(false));
      yield put(
        eventActions.successActiveEvent({
          onGoingEvent: null,
          eventList: [],
        }),
      );
    }
  } catch (error) {
    console.log('Error in getActiveEvent', error);
    yield put(eventActions.failureActiveEvent());
  }
}
function* getActiveEventReports(action: PayloadAction<EventId>) {
  try {
    const { id } = action.payload;
    const isEms = isEmsCommander();
    const response: ARReportListInterface = yield eventServices.getReportListService(
      id,
      isEms ? REPORT_TYPE.INJURED : '',
    );

    const { data } = response;
    if (data) {
      const suspectReports = data.filter(
        (reportObj: ReportInterface) =>
          reportObj.report_type.logical_name === REPORT_TYPE.SUSPECT,
      );
      const injuredReports = data.filter(
        (reportObj: ReportInterface) =>
          reportObj.report_type.logical_name === REPORT_TYPE.INJURED,
      );
      yield put(
        eventActions.fetchReportsSuccess({ suspectReports, injuredReports }),
      );
    } else
      yield put(
        eventActions.fetchReportsSuccess({
          suspectReports: null,
          injuredReports: null,
        }),
      );
  } catch (error) {
    console.log('Error in getActiveEventReports', error);
    yield put(eventActions.fetchReportsFailure());
  }
}

function* addNewProfile(action: PayloadAction<AddProfilePayload>) {
  try {
    const { id, report_type } = action.payload;

    const response: ARAddProfileInterface = yield eventServices.addNewProfileService(
      {
        id,
        body: {
          report_type: { id: report_type },
        },
      },
    );

    const { data } = response;
    const selectedFilter = yield select(getProfileFilter);
    const injuredProfiles = yield select(getInjuredProfiles);
    const suspectProfiles = yield select(getSuspectProfiles);
    const { injuredReports, suspectReports } = getNewProfileList(
      data,
      injuredProfiles,
      suspectProfiles,
      selectedFilter,
    );

    yield put(
      eventActions.addNewProfileSuccess({
        injuredProfiles: injuredReports,
        suspectProfiles: suspectReports,
      }),
    );
  } catch (error) {
    console.log('Error in addNewProfile', error);
    yield put(eventActions.addNewProfileFailure());
  }
}

function* fetchReportTypes() {
  try {
    const response: ARReportTypesInterface = yield eventServices.fetchReportTypeService();

    const { data } = response;
    yield put(eventActions.fetchReportTypesSuccess(data));
  } catch (error) {
    console.log('Error in fetchReportTypes', error);
    yield put(eventActions.fetchReportTypesFailure());
  }
}
function* fetchEventJoinRequests(
  action: PayloadAction<GetEventJoinRequestsPayload>,
) {
  try {
    const { id } = action.payload;
    const response: AREventJoinRequestsInterface = yield eventServices.fetchEventJoinRequestsService(
      id,
    );
    const { data } = response;
    if (data) {
      yield put(eventActions.fetchEventJoinRequestsSuccess(data));
    }
  } catch (error) {
    console.log('Error in fetchEventJoinRequests', error);
    yield put(eventActions.fetchEventJoinRequestsFailure());
  }
}
function* acceptEventJoinRequest(
  action: PayloadAction<AcceptEventJoinRequestPayload>,
) {
  try {
    const joinRequestId = action.payload.eventJoinRequest.id;
    const requestBody = { status: EventJoinRequestEnum.ACCEPTED };
    const response: ARAcceptEventJoinRequestInterface = yield eventServices.acceptEventJoinRequestService(
      { joinRequestId, requestBody },
    );
    const { data } = response;
    if (data) {
      yield put(
        eventActions.acceptEventJoinRequestSuccess(
          action.payload.eventJoinRequest,
        ),
      );
    }
  } catch (error) {
    console.log('Error in acceptEventJoinRequest', error);
    yield put(eventActions.acceptEventJoinRequestFailure());
  }
}
function* denyEventJoinRequest(
  action: PayloadAction<DenyEventJoinRequestPayload>,
) {
  try {
    const joinRequestId = action.payload.eventJoinRequest.id;
    const requestBody = { status: EventJoinRequestEnum.REJECTED };
    const response: ARDenyEventJoinRequestInterface = yield eventServices.denyEventJoinRequestService(
      { joinRequestId, requestBody },
    );
    const { data } = response;
    if (data) {
      yield put(
        eventActions.denyEventJoinRequestSuccess(
          action.payload.eventJoinRequest,
        ),
      );
    }
  } catch (error) {
    console.log('Error in denyEventJoinRequest', error);
    yield put(eventActions.denyEventJoinRequestFailure());
  }
}

function* fetchProfiles(action: PayloadAction<GetProfilePayload>) {
  try {
    const { id } = action.payload;
    const isEms = isEmsCommander();
    const selectedFilter = yield select(getProfileFilter);

    const response: ARProfilesInterface = yield eventServices.fetchProfilesService(
      id,
      isEms ? REPORT_TYPE.INJURED : '',
      selectedFilter === FILTER_TYPES.RESOLVED
        ? true
        : selectedFilter === FILTER_TYPES.ACTIVE
        ? false
        : null,
    );

    const { data } = response;
    if (data) {
      const suspectProfiles = data.filter(
        (profileObj: Profile) =>
          profileObj.report_type.logical_name === REPORT_TYPE.SUSPECT,
      );
      const injuredProfiles = data.filter(
        (profileObj: Profile) =>
          profileObj.report_type.logical_name === REPORT_TYPE.INJURED,
      );
      yield put(
        eventActions.fetchProfilesSuccess({ suspectProfiles, injuredProfiles }),
      );
    } else
      yield put(
        eventActions.fetchProfilesSuccess({
          suspectProfiles: null,
          injuredProfiles: null,
        }),
      );
  } catch (error) {
    console.log('Error in fetchReportTypes', error);
    yield put(eventActions.fetchProfilesFailure());
  }
}

function* updateProfile(action: PayloadAction<UpdateProfilePayload>) {
  try {
    const { id, body } = action.payload;
    const bodyFormData = new FormData();

    bodyFormData.append('data', body['data']);

    if (body['files'] && isArray(body['files'])) {
      for (const file of body['files']) {
        bodyFormData.append('files', file);
      }
    }

    const response: ARAddProfileInterface = yield eventServices.updateProfilesService(
      id,
      bodyFormData,
    );
    const { data } = response;

    const selectedFilter = yield select(getProfileFilter);

    const injuredProfiles = yield select(getInjuredProfiles);
    const suspectProfiles = yield select(getSuspectProfiles);

    const { injuredReports, suspectReports } = getNewProfileList(
      data,
      injuredProfiles,
      suspectProfiles,
      selectedFilter,
    );

    yield put(
      eventActions.updateProfilesSuccess({
        injuredProfiles: injuredReports,
        suspectProfiles: suspectReports,
      }),
    );
  } catch (error) {
    console.log('Error in fetchReportTypes', error);
    yield put(eventActions.updateProfilesFailure());
  }
}

function* addPersonDescription(
  action: PayloadAction<AddPersonDescriptionPayload>,
) {
  try {
    const { profileId, personDescriptionId } = action.payload;
    const response = yield eventServices.addPersonDescriptionService(
      profileId,
      {
        id: personDescriptionId,
      },
    );

    yield put(eventActions.addPersonDescriptionSuccess());
  } catch (error) {
    console.log('Error in fetchReportTypes', error);
    yield put(eventActions.addPersonDescriptionFailure());
  }
}

function* endEvent(action: PayloadAction<EndEventPayload>) {
  try {
    const { id, reason, comment } = action.payload;
    const body: any = {
      end_meta: {},
      end_reason: reason,
    };
    if (comment) {
      body['end_comment'] = comment;
    }
    yield eventServices.endEventService(id, body);
    yield put(eventActions.endEventSuccess());
    showSuccessMsg('Event has been ended successfully.');
    localStorageService.logout();
    yield put(loginActions.logoutUser());
  } catch (error) {
    console.log('Error in endEvent', error);
    yield put(eventActions.endEventFailure());
  }
}

function* leaveActiveEventAfterProcess() {
  showSuccessMsg('Left active event successfully.');
  //close the menu (side drawer) in UI
  yield put(modalActions.closeModal());
  // navigate to event list
  yield put(replace(OPS_CENTER_ROUTES.EVENT_LIST.path));
  const payload: PositionInterface = {
    lat: 0,
    lng: 0,
  };
  yield call(getActiveEvent, { payload });
}

function* leaveActiveEvent(action: PayloadAction<LeaveActiveEventPayload>) {
  try {
    const { eventId } = action.payload;
    const response: ARLeaveActiveEventInterface = yield eventServices.leaveActiveEventService(
      eventId,
    );
    if (response.isSuccess) {
      yield put(eventActions.leaveActiveEventSuccess());
    } else {
      console.log('leaveActiveEvent response is not success', response);
      yield put(eventActions.leaveActiveEventFailure());
    }
  } catch (error) {
    console.log('Error in leaveActiveEvent', error);
    showErrorMsg(error);
    yield put(eventActions.leaveActiveEventFailure());
  }
}

function* startEvent(action: PayloadAction<StartEvenPayload>) {
  try {
    const { orgId } = action.payload;
    const response = yield organizationService.createEventService({
      id: orgId,
    });
    yield put(eventActions.startEventSuccess(response.data));

    yield put(
      organizationActions.fetchParticularOrganizationDetailsRequest({
        orgId,
      }),
    );
  } catch (error) {
    showErrorMsg(error);
    yield put(eventActions.startEventFailure());
  }
}

function* sendUserLocation() {
  try {
    const coords: PositionInterface = yield getCurrentLocation();
    const body = {
      location: [
        {
          coords: {
            latitude: coords.lat,
            longitude: coords.lng,
          },
        },
      ],
    };
    yield eventServices.sendUserLocationService(body);
    yield put(eventActions.sendLocationSuccess());
  } catch (error) {
    yield put(eventActions.sendLocationFailure());
  }
}

function* joinEventRequest(action: PayloadAction<JoinEventPayload>) {
  try {
    const { eventId } = action.payload;
    const response: ARJoinEventRequest = yield eventServices.sendEventJoinRequest(
      eventId,
    );
    if (response.isSuccess) {
      yield put(eventActions.requestActiveEvent(null));
      yield put(eventActions.eventJoinSuccess());
      showSuccessMsg('Joined Event Successfully');
    }
  } catch (error) {
    showErrorMsg(error);
    yield put(eventActions.eventJoinFailure());
  }
}
function* cancelEventJoinRequest(
  action: PayloadAction<CancelEventJoinPayload>,
) {
  try {
    const { eventJoinRequestId } = action.payload;
    const response: ARJoinEventRequest = yield eventServices.deleteEventJoinRequest(
      eventJoinRequestId,
    );
    if (response.isSuccess) {
      yield put(eventActions.requestActiveEvent(null));
      yield put(eventActions.cancelEventJoinSuccess());
      showSuccessMsg('Cancelled join request Successfully');
    }
  } catch (error) {
    showErrorMsg(error);
    yield put(eventActions.cancelEventJoinFailure());
  }
}

function* reportCE(action: PayloadAction<ReportCEPayload>) {
  try {
    const { name } = action.payload;
    const coords: PositionInterface = yield getCurrentLocation();
    const token = yield getStartToken();
    const ipAddress = yield getIPAddress();
    const body = {
      location: {
        type: 'Point',
        coordinates: [coords.lng, coords.lat],
      },
      device_id: token ? token : '',
      device_name: `${name}'s ${isMobile ? 'Mobile' : 'Desktop'}`,
      ip_address: ipAddress,
    };

    const response: ARJoinEventRequest = yield eventServices.reportCriticalEvent(
      body,
    );
    if (response.isSuccess) {
      yield put(eventActions.requestActiveEvent(null));
      yield put(eventActions.reportCESuccess());
      showSuccessMsg('Reported critical event successfully');
    }
  } catch (error) {
    showErrorMsg(error);
    yield put(eventActions.reportCEFailure());
  }
}

export function* eventSaga(): Generator {
  yield all([
    takeLatest(eventActions.requestActiveEvent, getActiveEvent),
    takeLatest(eventActions.fetchReportsRequest, getActiveEventReports),
    takeLatest(eventActions.addNewProfileRequest, addNewProfile),
    takeLatest(eventActions.fetchReportTypesRequest, fetchReportTypes),
    takeLatest(
      eventActions.fetchEventJoinRequestsRequest,
      fetchEventJoinRequests,
    ),
    takeLatest(
      eventActions.acceptEventJoinRequestRequest,
      acceptEventJoinRequest,
    ),
    takeLatest(eventActions.denyEventJoinRequestRequest, denyEventJoinRequest),
    takeLatest(eventActions.fetchProfilesRequest, fetchProfiles),
    takeLatest(eventActions.updateProfilesRequest, updateProfile),
    takeLatest(eventActions.addPersonDescriptionRequest, addPersonDescription),
    takeLatest(eventActions.endEventRequest, endEvent),
    takeLatest(eventActions.leaveActiveEventRequest, leaveActiveEvent),
    takeLatest(
      eventActions.leaveActiveEventSuccess,
      leaveActiveEventAfterProcess,
    ),
    takeLatest(eventActions.startEventRequest, startEvent),
    takeLatest(eventActions.sendLocationRequest, sendUserLocation),
    takeLatest(eventActions.eventJoinRequest, joinEventRequest),
    takeLatest(eventActions.cancelEventJoinRequest, cancelEventJoinRequest),
    takeLatest(eventActions.reportCERequest, reportCE),
  ]);
}
