import { PositionInterface } from 'utils/commonTypes';
export const DEFAULT_COORDINATES = {
  lat: 77.55,
  lng: 12.99,
};
export const GEO_FENCE_OFFSET = [
  [0.007447722358776332, 0.0020178966463699766],
  [0.0035126219045338303, 0.0012792001058699043],
  [0.006167764307036805, 0.00008002871533641098],
  [0.007447722358776332, 0.0020178966463699766],
];
export const PERIMETER_POINT_OFFSET = [
  [0.007447722358776332, 0.0020178966463699766],
  [0.0035126219045338303, 0.0012792001058699043],
  [0.006167764307036805, 0.00008002871533641098],
  [0.007447722358776332, 0.0020178966463699766],
];
export const COMMAND_POST_OFFSET = [
  0.004891064766553654,
  0.0003308933161711991,
];

const op = (value: any, initialValue: any) => {
  if (initialValue < 0) {
    return initialValue - value;
  }
  if (initialValue >= 0) {
    return initialValue + value;
  }
};

export const computeCoordinateForPPANDCP = (
  coordinate: any,
  offset: any,
  backendFormat = false,
) => {
  return offset.reduce((acc: any, obj: any) => {
    const lat = op(obj[0], coordinate.lat);
    const lng = op(obj[1], coordinate.lng);
    if (backendFormat) {
      acc.push([lat, lng]);
    }
    acc.push({ position: { lat, lng } });
    return acc;
  }, []);
};

export const computeCoordinate = (
  coordinate: any,
  offset: any,
  backendFormat = false,
) => {
  return offset.reduce((acc: any, obj: any) => {
    const lat = op(obj[0], coordinate.lat);
    const lng = op(obj[1], coordinate.lng);
    if (backendFormat) {
      acc.push([lat, lng]);
    }
    acc.push({ lat, lng });
    return acc;
  }, []);
};

const computePP = (coordsArray: Array<PositionInterface>) => {
  return coordsArray.map((obj: PositionInterface) => {
    return { position: obj };
  });
};
export const DEFAULT_PERIMETER_POINT = (
  coordinates = DEFAULT_COORDINATES,
  offset = PERIMETER_POINT_OFFSET,
  backendFormat = false,
) => {
  return {
    type: 'MultiPoint',
    // coordinates: computeCoordinateForPPANDCP(
    //   coordinates,
    //   offset,
    //   backendFormat,
    // ),
    coordinates: computePP(computeCoordinatesUsingSphericalCo(coordinates)),
  };
};

export const computeCoordinatesUsingSphericalCo = (
  currentOrgCords: PositionInterface,
) => {
  const circleRadius = 28.28; // 40/√2

  // @ts-ignore
  const northEast = new window.google.maps.geometry.spherical.computeOffset(
    // @ts-ignore
    new window.google.maps.LatLng(currentOrgCords),
    circleRadius,
    45,
  );
  // @ts-ignore
  const northWest = new window.google.maps.geometry.spherical.computeOffset(
    // @ts-ignore
    new window.google.maps.LatLng(currentOrgCords),
    circleRadius,
    315,
  );
  // @ts-ignore
  const southEast = new window.google.maps.geometry.spherical.computeOffset(
    // @ts-ignore
    new window.google.maps.LatLng(currentOrgCords),
    circleRadius,
    135,
  );
  // @ts-ignore
  const southWest = new window.google.maps.geometry.spherical.computeOffset(
    // @ts-ignore
    new window.google.maps.LatLng(currentOrgCords),
    circleRadius,
    225,
  );
  return [
    { lat: northEast.lat(), lng: northEast.lng() },
    { lat: southEast.lat(), lng: southEast.lng() },
    { lat: southWest.lat(), lng: southWest.lng() },
    { lat: northWest.lat(), lng: northWest.lng() },
  ];
};

export const DEFAULT_GEO_FENCE = (
  coordinates = DEFAULT_COORDINATES,
  offset = GEO_FENCE_OFFSET,
  backendFormat = false,
) => {
  return {
    type: 'Polygon',
    coordinates: computeCoordinatesUsingSphericalCo(coordinates),
    // coordinates: computeCoordinate(coordinates, offset, backendFormat),
  };
};

const computeCP = (coordinate: PositionInterface) => {
  // @ts-ignore
  const west = new window.google.maps.geometry.spherical.computeOffset(
    // @ts-ignore
    new window.google.maps.LatLng(coordinate),
    7.07, //10/√2
    90,
  );

  return [
    {
      position: {
        lat: west.lat(),
        lng: west.lng(),
      },
    },
  ];
};

export const DEFAULT_COMMAND_POINT = (
  coordinates = DEFAULT_COORDINATES,
  offset = [COMMAND_POST_OFFSET],
  backend = false,
) => {
  const cpCoords = computeCoordinatesUsingSphericalCo(coordinates)[0];
  const defaultCoordinates = computeCP(cpCoords);
  // const defaultCoordinates = computeCoordinateForPPANDCP(
  //   coordinates,
  //   offset,
  //   backend,
  // );
  return {
    type: 'Point',
    coordinates: defaultCoordinates,
  };
};

export const DEFAULT_ORG_PERIMETER_POINT = (
  coordinates = DEFAULT_COORDINATES,
  offset = PERIMETER_POINT_OFFSET,
  backendFormat = false,
) => {
  return computePP(computeCoordinatesUsingSphericalCo(coordinates));
};

// export const GEO_JSON_TYPE = {
//   GEO_FENCE: {
//     type: 'Polygon',
//     coordinates: [
//       [
//         [77.55744772235877, 12.99201789664637],
//         [77.55351262190453, 12.99127920010587],
//         [77.55616776430703, 12.990080028715337],
//         [77.55744772235877, 12.99201789664637],
//       ],
//     ],
//   },
//   PERIMETER_POINTS: {
//     type: 'MultiPoint',
//     coordinates: [
//       [77.55744772235877, 12.99201789664637],
//       [77.55351262190453, 12.99127920010587],
//       [77.55616776430703, 12.990080028715337],
//       [77.55744772235877, 12.99201789664637],
//     ],
//   },
//   COMMAND_POST: {
//     type: 'Point',
//     coordinates: [[77.55489106476655, 12.990330893316171]],
//   },
//   DEFAULT_COORDINATES: {
//     lat: 77.55,
//     lng: 12.99,
//   },
// };
