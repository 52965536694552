import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { CustomButton } from 'components/General/CustomButton';
import { COLORS } from 'styles/colorConstants';
import { eventActions } from 'store/event/event.slice';
import { LeaveActiveEventPayload } from 'store/organization';

const title = 'Are you certain you want to leave this event?';

const useLeaveActiveThreatStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // width: '5%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    username: {
      fontFamily: 'Poppins',
      color: COLORS.WHITE_GREY,
      fontWeight: 600,
      marginLeft: '8px',
    },
    title: {
      fontSize: '20px',
    },
    color: {
      color: COLORS.RAISIN_BLACK,
    },
    margin: {
      margin: '16px',
    },
    padding: {
      padding: '16px',
    },
    lightColor: {
      color: COLORS.DATE_COLOR,
    },
    ctn: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkbox: {
      color: COLORS.OPS_CENTER_GREY,
      '&.Mui-checked': {
        color: COLORS.OPS_CENTER_GREY,
      },
      '&.Mui-unchecked': {
        color: COLORS.OPS_CENTER_GREY,
      },
    },
    actionBtnCtn: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '16px',
    },
    comment: {
      marginTop: '8px',
    },
    drawerStyles: {
      background: COLORS.GREY,
    },
    list: {
      width: 250,
    },
    redText: {
      color: COLORS.RED,
    },
    reportCEDialogCtn: {
      textAlign: 'center',
      color: COLORS.BLACK,
      textTransform: 'uppercase',
    },
    // logo: {
    //   width: ' 70px',
    // },
  }),
);

export const LeaceActiveThreatDialogComponent = (props: {
  open: boolean;
  onClose: any;
  id: string;
}) => {
  const { open, onClose, id } = props;
  const classes = useLeaveActiveThreatStyles();
  const dispatch = useDispatch();

  const handleLeaveActiveThreat = () => {
    const payload: LeaveActiveEventPayload = { eventId: id };
    dispatch(eventActions.leaveActiveEventRequest(payload));
    onClose();
  };

  return (
    <Dialog
      data-cy="leave-event"
      open={open}
      onClose={onClose}
      keepMounted={false}
    >
      <DialogContent className={`${classes.padding} ${classes.ctn}`}>
        <DialogTitle
          className={`${classes.title} ${classes.username} ${classes.color}`}
        >
          {title.toUpperCase()}
        </DialogTitle>
      </DialogContent>

      <DialogActions className={`${classes.actionBtnCtn}`}>
        <CustomButton onClick={onClose} error={false} label="Stay" />
        <CustomButton
          onClick={handleLeaveActiveThreat}
          error={true}
          label="Leave"
        />
      </DialogActions>
    </Dialog>
  );
};
