import _ from 'lodash';
import { RoutesInterface } from './../../routes/path';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OrganizationTypeInterface,
  OrganizationDataActionPayload,
  AddEditOrganizationActionPayload,
  AddEditOrganizationSuccessActionPayload,
  OrganizationRolesInterface,
  OrganizationSpecificActionPayload,
  OrganizationUserSuccessActionPayload,
  OrganizationUserTypeInterface,
  AddEditOrgUserActionPayload,
  AddEditOrgUserSuccessActionPayload,
  CreateNewOrgUserButtonClickActionPayload,
  ParticularUserDetailsActionPayload,
} from './policeDept.type';
import { PaginationInterface } from 'utils/commonTypes';
import { DEFAULT_PAGINATION_OBJECT } from 'utils/constants';
import { isCreateAction, findAndUpdate } from 'utils';

interface OrganizationState {
  list: {
    data: OrganizationTypeInterface[] | [];
    pagination: PaginationInterface;
  };
  isLoading: boolean;

  // currentAppRoute: RoutesInterface | null;
  // currentOrganization: OrganizationTypeInterface | null;
  // currentOrganizationRoles: OrganizationRolesInterface[] | [];
  // users: OrganizationUserSuccessActionPayload;
  // selectedUser: OrganizationUserTypeInterface | null;
}

const initialState: OrganizationState = {
  list: {
    data: [],
    pagination: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
  },
  isLoading: false,
  // currentOrganization: null,
  // currentAppRoute: null,
  // currentOrganizationRoles: [],
  // users: {
  //   data: [],
  //   meta: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
  // },
  // selectedUser: null,
};

export const policeDeptSlice = createSlice({
  name: 'policeDept',
  initialState,
  reducers: {
    //  list all the police dept organization
    fetchPoliceDeptDataSuccess: (
      state,
      action: PayloadAction<OrganizationDataActionPayload>,
    ) => {
      state.isLoading = false;
      state.list.data = action.payload.data;
      state.list.pagination = action.payload.meta;
    },
    fetchPoliceDeptDataFailure: (state) => {
      state.isLoading = false;
    },
    fetchPoliceDeptDataRequest: (state) => {
      state.isLoading = true;
    },

    // //  fetch particular Organization details
    // fetchParticularOrganizationDetailsSuccess: (
    //   state,
    //   action: PayloadAction<OrganizationTypeInterface>,
    // ) => {
    //   state.isLoading = false;
    //   state.currentOrganization = action.payload;
    // },
    // fetchParticularOrganizationDetailsFailure: (state) => {
    //   state.isLoading = false;
    // },
    // fetchParticularOrganizationDetailsRequest: (
    //   state,
    //   action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
    // ) => {
    //   state.isLoading = true;
    // },

    // // create or update organization
    // addOrEditOrganizationsAsyncRequest: (
    //   state,
    //   action: PayloadAction<AddEditOrganizationActionPayload>,
    // ) => {
    //   state.isLoading = true;
    // },
    // addOrEditOrganizationsAsyncSuccess: (
    //   state,
    //   action: PayloadAction<AddEditOrganizationSuccessActionPayload>,
    // ) => {
    //   state.isLoading = false;
    //   state.currentOrganization = action.payload.updatedValue;

    //   if (action.payload.actionType === 'new') {
    //     // @ts-ignore
    //     state.organizationList.data.push(action.payload.updatedValue);
    //   } else {
    //     findAndUpdate<OrganizationTypeInterface>(
    //       state.organizationList.data,
    //       action.payload.updatedValue,
    //     );
    //     const objIndex = state.organizationList.data.findIndex((obj) => {
    //       return obj.id === _.get(action.payload.updatedValue, 'id', '');
    //     });

    //     if (objIndex !== -1) {
    //       state.organizationList.data[objIndex] = action.payload.updatedValue;
    //     }
    //   }
    // },
    // addOrEditOrganizationsAsyncFailure: (state) => {
    //   state.isLoading = false;
    //   state.currentOrganization = null;
    // },

    // // save the organization specific roles
    // organizationRolesAsyncRequest: (
    //   state,
    //   action: PayloadAction<OrganizationSpecificActionPayload>,
    // ) => {
    //   state.isLoading = action.payload.showLoader
    //     ? action.payload.showLoader
    //     : false;
    // },
    // organizationRolesAsyncSuccess: (
    //   state,
    //   action: PayloadAction<OrganizationRolesInterface[]>,
    // ) => {
    //   state.isLoading = false;
    //   state.currentOrganizationRoles = action.payload;
    // },
    // organizationRolesAsyncFailure: (state) => {
    //   state.isLoading = false;
    //   state.currentOrganizationRoles = [];
    // },

    // // save the organization specific users
    // organizationUsersAsyncRequest: (
    //   state,
    //   action: PayloadAction<OrganizationSpecificActionPayload>,
    // ) => {
    //   state.isLoading = action.payload.showLoader
    //     ? action.payload.showLoader
    //     : false;
    // },
    // organizationUsersAsyncSuccess: (
    //   state,
    //   action: PayloadAction<OrganizationUserSuccessActionPayload>,
    // ) => {
    //   state.isLoading = false;
    //   state.users = action.payload;
    // },
    // organizationUsersAsyncFailure: (state) => {
    //   state.isLoading = false;
    //   state.users = {
    //     data: [],
    //     meta: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
    //   };
    // },

    // // save the user specific details
    // fetchUserDetailsAsyncRequest: (
    //   state,
    //   action: PayloadAction<ParticularUserDetailsActionPayload>,
    // ) => {
    //   state.isLoading = true;
    // },
    // fetchUserDetailsAsyncSuccess: (
    //   state,
    //   action: PayloadAction<OrganizationUserTypeInterface>,
    // ) => {
    //   state.isLoading = false;
    //   state.selectedUser = action.payload;
    // },
    // fetchUserDetailsAsyncFailure: (state) => {
    //   state.isLoading = false;
    //   state.selectedUser = null;
    // },

    // addOrEditOrgUserAsyncRequest: (
    //   state,
    //   action: PayloadAction<AddEditOrgUserActionPayload>,
    // ) => {
    //   state.isLoading = true;
    // },

    // addOrEditOrgUserAsyncSuccess: (
    //   state,
    //   action: PayloadAction<AddEditOrgUserSuccessActionPayload>,
    // ) => {
    //   state.isLoading = false;
    //   const { actionType, updatedValue } = action.payload;
    //   if (isCreateAction(actionType || '')) {
    //     state.selectedUser = updatedValue;
    //     // @ts-ignore
    //     state.users.data.push(updatedValue);
    //   } else {
    //     findAndUpdate<OrganizationUserTypeInterface>(
    //       state.users.data,
    //       updatedValue,
    //     );
    //   }
    // },

    // addOrEditOrgUserAsyncFailure: (state) => {
    //   state.isLoading = false;
    // },

    // // sync
    // organizationRowClick: (
    //   state,
    //   action: PayloadAction<OrganizationTypeInterface>,
    // ) => {
    //   state.currentOrganization = action.payload;
    // },
    // userRowClick: (
    //   state,
    //   action: PayloadAction<OrganizationUserTypeInterface>,
    // ) => {
    //   state.selectedUser = action.payload;
    // },

    // createNewOrganizationButtonClick: (state) => {
    //   state.currentOrganization = null;
    // },
    // createNewOrgUserButtonClick: (
    //   state,
    //   action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
    // ) => {
    //   state.selectedUser = null;
    // },

    // fetchOrgSpecificData: (
    //   state,
    //   action: PayloadAction<OrganizationSpecificActionPayload>,
    // ) => {
    //   // state.currentAppRoute = action.payload;
    //   console.log('%c action ', 'background: lime; color: black', action);
    //   state.users = {
    //     data: [],
    //     meta: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
    //   };
    //   state.currentOrganizationRoles = [];
    // },
    // setCurrentAppRoute: (
    //   state,
    //   action: PayloadAction<RoutesInterface | null>,
    // ) => {
    //   state.currentAppRoute = action.payload;
    // },
  },
});

export const policeDeptActions = policeDeptSlice.actions;

export default policeDeptSlice.reducer;
