import NavigationSection from 'components/SubHeader/NavigationSection';
import PageBody from 'container/PageBody';
import React, { Fragment, useState, useEffect } from 'react';
import { useBreadCrumb, useActiveAppRouteMatch } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import _, { isNull } from 'lodash';
import OrganizationTable from 'components/OrganizationTable';
import {
  getSelectedOrganization,
  getSelectedUsers,
  organizationActions,
} from 'store';
import { getAppRoute } from 'routes/Organization/helper';
import { SPECIAL_EVENT_ROUTES } from 'routes/path';
import {
  getPoliceDeptFormErrors,
  getPoliceDeptFormValue,
} from 'store/policeDept';
import {
  getCommandPostPoints,
  getGeoFencePoints,
  getPerimeterPoints,
  getSpecialEventUserFormErrors,
  getSpecialEventUserFormValue,
} from 'store/organization';
import {
  ActionButtonInterface,
  AppCategory,
  CombinedActionButtonInterface,
  getUpdatedFields,
  isRouteMatch,
  REDUX_FORM_CONSTANT,
  STATUS,
} from 'utils';
import {
  SPECIAL_EVENT_ACTION_BUTTON,
  SPECIAL_EVENT_ACTIVE_SUB_HEADER_SECTION,
  SPECIAL_EVENT_NESTED_LINKS,
} from './utils/constants';
import { destroy, submit } from 'redux-form';
import { useRouteMatch } from 'react-router';

import UserForm from 'routes/Organization/UserForm';
import { RenderMap } from 'routes/Organization/Map';
import { updateActionButtonVisibility } from './utils/helper';
import { useUpdateSpecialEventHeaderVisibility } from './utils/hooks';
import { toast } from 'react-toastify';
import { CreatePoliceDeptForm } from 'routes/PoliceDepartment/PoliceDeptForm';
import SpecialEventUsers from './SpecialEventUsers';
import { eventActions } from 'store/event/event.slice';
import { QrCodePreview } from 'routes/Organization/QrCodePreview';
// import { useFlags } from 'launchdarkly-react-client-sdk';
// import { LAUNCH_DARKLY_KEYS } from 'utils/constants/ldKeys';

export const SpecialEvent = () => {
  const appActiveRoute = useActiveAppRouteMatch(SPECIAL_EVENT_ROUTES);

  const selectedUser = useSelector(getSelectedUsers);
  const selectedOrganization = useSelector(getSelectedOrganization);
  const isOrgArchieved = !isNull(selectedOrganization?.archived_at);
  const [, appRouteObj] = getAppRoute(SPECIAL_EVENT_ROUTES);
  const activeSubHeaderSection = useUpdateSpecialEventHeaderVisibility(
    SPECIAL_EVENT_ROUTES,
    SPECIAL_EVENT_ACTIVE_SUB_HEADER_SECTION,
  );
  const [
    actionButton,
    setActionButton,
  ] = useState<CombinedActionButtonInterface>(SPECIAL_EVENT_ACTION_BUTTON);

  // const flags = useFlags();
  // const qrCodeFeatureFlag: boolean =
  //   flags[LAUNCH_DARKLY_KEYS.REGISTER_USING_QR_CODE];
  const nestedLinks: any = { ...SPECIAL_EVENT_NESTED_LINKS };
  // if (!qrCodeFeatureFlag || isOrgArchieved) {
  if (isOrgArchieved) {
    delete nestedLinks['qrCode'];
  }

  const breadcrumb = useBreadCrumb(selectedOrganization, selectedUser);
  const dispatch = useDispatch();
  const currentRoute = useRouteMatch();
  const addOrEditPoliceDeptFormErrors = useSelector(getPoliceDeptFormErrors);
  const addOrEditPoliceDeptFormValue = useSelector(getPoliceDeptFormValue);
  const addOrEditUserFormErrors = useSelector(getSpecialEventUserFormErrors);
  const addOrEditUserFormValue = useSelector(getSpecialEventUserFormValue);

  const commandPosts = useSelector(getCommandPostPoints);
  const geoFence = useSelector(getGeoFencePoints);
  const perimeterPoints = useSelector(getPerimeterPoints);

  const handleActionButtonClick = (actionButtonObj: ActionButtonInterface) => {
    console.log('%c actionButtonObj ', 'background: lime; color: black', {
      actionButtonObj,
    });

    if (actionButtonObj.id === SPECIAL_EVENT_ACTION_BUTTON.addOrganization.id) {
      dispatch(organizationActions.createNewOrganizationButtonClick());
      dispatch(destroy(REDUX_FORM_CONSTANT.POLICE_DEPT));
    }

    if (
      actionButtonObj.id ===
      SPECIAL_EVENT_ACTION_BUTTON.updateOrganizationDetails.id
    ) {
      dispatch(submit(REDUX_FORM_CONSTANT.POLICE_DEPT));
      if (!_.isEmpty(addOrEditPoliceDeptFormErrors)) {
        toast.error('Please fill the required fields.');
      } else {
        const updatedFieldValue = getUpdatedFields(
          addOrEditPoliceDeptFormValue,
          selectedOrganization,
        );
        dispatch(
          organizationActions.addOrEditOrganizationsAsyncRequest({
            formValue: addOrEditPoliceDeptFormValue,
            dirtyFields: updatedFieldValue,
            // @ts-ignore
            id: currentRoute.params.id,
          }),
        );
      }
    }

    if (actionButtonObj.id === SPECIAL_EVENT_ACTION_BUTTON.activateOrgDept.id) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'organization',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.ACTIVATE,
        }),
      );
    }

    if (
      actionButtonObj.id === SPECIAL_EVENT_ACTION_BUTTON.deActivateOrgDept.id
    ) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'organization',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.DEACTIVATE,
        }),
      );
    }

    if (
      actionButtonObj.id === SPECIAL_EVENT_ACTION_BUTTON.activateOrgDeptUser.id
    ) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.userId,
          status: STATUS.UNBLOCK_USER,
        }),
      );
    }

    if (
      actionButtonObj.id ===
      SPECIAL_EVENT_ACTION_BUTTON.deActivateOrgDeptUser.id
    ) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.userId,
          status: STATUS.BLOCK_USER,
        }),
      );
    }

    if (
      actionButtonObj.id ===
      SPECIAL_EVENT_ACTION_BUTTON.addUserToOrganization.id
    ) {
      dispatch(
        organizationActions.createNewOrgUserButtonClick({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    if (
      actionButtonObj.id === SPECIAL_EVENT_ACTION_BUTTON.updateUserDetails.id
    ) {
      dispatch(submit(REDUX_FORM_CONSTANT.POLICE_USER));
      if (!_.isEmpty(addOrEditUserFormErrors)) {
        toast.error('Please fill the required fields.');
      } else
        dispatch(
          organizationActions.addOrEditOrgUserAsyncRequest({
            formValue: addOrEditUserFormValue,
            // @ts-ignore
            orgId: currentRoute.params.id,
            // @ts-ignore
            userId: currentRoute.params.userId,
          }),
        );
    }

    if (actionButtonObj.id === SPECIAL_EVENT_ACTION_BUTTON.startEvent.id) {
      dispatch(
        eventActions.startEventRequest({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    if (
      actionButtonObj.id === SPECIAL_EVENT_ACTION_BUTTON.updateMapDetails.id
    ) {
      if (!commandPosts.length || !perimeterPoints.length || !geoFence.length) {
        toast.error(
          'Command Points, Perimeter points and Geofence cannot be deleted. Please add a new one.',
        );
      } else {
        const updatedFieldValue = getUpdatedFields(
          addOrEditPoliceDeptFormValue,
          selectedOrganization,
        );
        dispatch(
          organizationActions.addOrEditOrganizationsAsyncRequest({
            formValue: selectedOrganization,
            dirtyFields: updatedFieldValue,
            // @ts-ignore
            id: currentRoute.params.id,
            isGeoJSONRequest: true,
          }),
        );
      }
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (_.isNull(selectedOrganization) && currentRoute.params?.id) {
      dispatch(
        organizationActions.fetchParticularOrganizationDetailsRequest({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    const updatedActionButton = updateActionButtonVisibility(
      actionButton,
      appActiveRoute,
      selectedUser,
      selectedOrganization,
    );
    setActionButton(updatedActionButton);
  }, [appActiveRoute?.path, selectedUser, selectedOrganization]);

  return (
    <Fragment>
      <NavigationSection
        breadcrumbData={breadcrumb}
        activeRouteSlug={appRouteObj.path}
        subNavigation={nestedLinks}
        sectionVisibility={activeSubHeaderSection}
        actionButtons={actionButton}
        actionButtonClick={handleActionButtonClick}
      />
      <PageBody>
        {isRouteMatch(
          SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_LIST,
          currentRoute.url,
        ) && <OrganizationTable categoryType={AppCategory.SPECIAL_EVENT} />}

        {isRouteMatch(
          SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_DETAILS,
          currentRoute.url,
        ) && <CreatePoliceDeptForm />}

        {isRouteMatch(
          SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_USERS,
          currentRoute.url,
        ) && <SpecialEventUsers />}

        {isRouteMatch(
          SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_USERS_DETAILS,
          currentRoute.url,
        ) && <UserForm id={REDUX_FORM_CONSTANT.SPECIAL_EVENT_USER} />}

        {isRouteMatch(
          SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_MAP,
          currentRoute.url,
        ) && <RenderMap />}

        {isRouteMatch(
          SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_QR_CODE,
          currentRoute.url,
        ) &&
          !isOrgArchieved && (
            // qrCodeFeatureFlag &&
            <QrCodePreview />
          )}
      </PageBody>
    </Fragment>
  );
};
