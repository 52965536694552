import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { FormField } from 'utils/types';
import { FormFieldContainer } from 'components/Form/FormFieldContainer';

type FormContainerTypes = {
  fieldArr: FormField[];
};

export const FormContainer = (props: FormContainerTypes) => {
  return (
    <div>
      <form>
        <RenderFormFields fieldArr={props.fieldArr} />
      </form>
    </div>
  );
};

export const RenderFormFields = (props: FormContainerTypes) => {
  return (
    <Box p={6} display="flex">
      <Grid container spacing={10}>
        <FormFieldContainer fieldArr={props.fieldArr} />
      </Grid>
    </Box>
  );
};

export default FormContainer;
