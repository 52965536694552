import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import Spinner from 'components/General/Spinner';
import { merge } from 'lodash';
import { appTheme } from './styles/themes';
import { Routes } from './routes';
import { getTheme } from './store';
import { getSpinnerStatus } from 'store/app';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
// import { withLDProvider } from 'launchdarkly-react-client-sdk';
// import { LAUNCH_DARKLY_TEST_CLIENT } from 'config';

const AppComponent: React.FC = () => {
  const colorMode = useSelector(getTheme);
  const spinnerStatus = useSelector(getSpinnerStatus);

  const theme = useMemo(() => {
    return createMuiTheme(
      merge(appTheme, {
        palette: {
          type: colorMode,
          mode: colorMode,
        },
      }),
    );
  }, [colorMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Spinner showSpinner={spinnerStatus} />
      <Routes />

      <ToastContainer
        theme="colored"
        icon={false}
        autoClose={3000}
        position="top-right"
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        pauseOnHover={false}
        data-cy="toast-message"
      />
    </ThemeProvider>
  );
};

// export const App = withLDProvider({
//   clientSideID: LAUNCH_DARKLY_TEST_CLIENT,
// })(AppComponent);
export const App = AppComponent;
