import { EMS_ROUTES } from 'routes/path';
import { APP_ASSETS } from 'utils/asset';
import { COUNTRY_DATA } from 'utils/constants';
import {
  formatVal,
  isRequired,
  isValidIdentifier,
  isValidPhoneNumber,
  isValidPostalCode,
  isDomainPresent,
  isValidDomain,
} from 'utils/helper';
import Text from 'components/General/Form/Text';
import ComboBox from 'components/General/Form/ComboBox';
import InputChip from 'components/General/Form/InputChip';

export const EMS_NESTED_LINKS = {
  details: {
    id: 'details',
    name: 'EMS',
    routeSlug: [EMS_ROUTES.EMS_ORG_DETAILS.path],
    // routePost: '/',
    icon: {
      src: APP_ASSETS.EMS_DEPARTMENT_DETAILS,
      name: 'EMS',
    },
  },
  users: {
    id: 'users',
    name: 'Ems Users',
    routeSlug: [
      EMS_ROUTES.EMS_ORG_USERS.path,
      EMS_ROUTES.EMS_ORG_USERS_DETAILS.path,
    ],
    routePost: 'users',
    icon: {
      src: APP_ASSETS.ORG_USERS,
      name: 'Ems Users',
    },
  },
  org: {
    id: 'org',
    name: 'Ems Org',
    routeSlug: EMS_ROUTES.EMS_ORG_ASSOCIATION.path,
    routePost: 'org',
    icon: {
      src: APP_ASSETS.ORG_DETAILS,
      name: 'Ems Org',
    },
  },
  qrCode: {
    id: 'qrCode',
    name: 'Ems QR Code',
    routeSlug: EMS_ROUTES.EMS_ORG_QR_CODE.path,
    routePost: 'qr',
    icon: {
      src: APP_ASSETS.QR_CODE_ICON,
      name: 'Ems QR Code',
    },
  },
};

export const EMS_ACTION_BUTTON = {
  emsAddOrg: {
    id: 'emsAddOrg',
    routeSlug: EMS_ROUTES.EMS_ORG_LIST.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_ACTION,
      name: 'Add Ems',
    },
  },
  emsAddOrEditOrg: {
    id: 'emsAddOrEditOrg',
    routeSlug: EMS_ROUTES.EMS_ORG_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'Save Ems',
    },
    type: 'submit',
  },
  activateEmsDept: {
    id: 'activateOrgDept',
    routeSlug: EMS_ROUTES.EMS_ORG_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.INACTIVE_ICON,
      name: 'Activate Org',
    },
  },
  deActivateEmsDept: {
    id: 'deActivateOrgDept',
    routeSlug: EMS_ROUTES.EMS_ORG_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ACTIVE_ICON,
      name: 'Deactivate Org',
    },
  },
  emsAddUserToOrg: {
    id: 'emsAddUserToOrg',
    routeSlug: EMS_ROUTES.EMS_ORG_USERS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_USER_ACTION,
      name: 'Add Users',
    },
  },
  emsUpdateUserDetails: {
    id: 'updateUserDetails',
    routeSlug: EMS_ROUTES.EMS_ORG_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'save User',
    },
    type: 'submit',
  },
  addEditSupervisee: {
    id: 'addEditSupervisee',
    routeSlug: EMS_ROUTES.EMS_ORG_ASSOCIATION.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_ACTION,
      name: 'Add Organization',
    },
  },
  emsActivateUser: {
    id: 'emsActivateUser',
    routeSlug: EMS_ROUTES.EMS_ORG_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.INACTIVE_ICON,
      name: 'Activate User',
    },
  },
  emsDeActivateUser: {
    id: 'emsDeActivateUser',
    routeSlug: EMS_ROUTES.EMS_ORG_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ACTIVE_ICON,
      name: 'Deactivate User',
    },
  },
};

export const EMS_ACTIVE_SUB_HEADER_SECTION = {
  leftSection: true,
  middleSection: false,
  rightSection: false,
};

export const EMS_FORM_FIELDS = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    component: Text,
    placeHolder: 'Enter Organization Name',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'country',
    label: 'Country',
    type: 'text',
    id: 'country',
    component: ComboBox,
    placeHolder: 'Select Country',
    validate: [isRequired],
    required: true,
    options: COUNTRY_DATA,
    displayValuePath: 'name',
  },
  {
    name: 'phone',
    type: 'tel',
    component: Text,
    label: 'Phone',
    placeHolder: 'Enter Phone',
    validate: [isValidPhoneNumber],
    required: true,
  },
  {
    name: 'street_1',
    type: 'text',
    component: Text,
    label: 'Street',
    placeHolder: 'Enter Street',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'street_2',
    type: 'text',
    component: Text,
    label: 'Street 2',
    placeHolder: 'Enter Street',
    validate: [],
  },
  {
    name: 'city',
    type: 'text',
    component: Text,
    label: 'City',
    placeHolder: 'Enter City',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'state',
    type: 'text',
    component: ComboBox,
    id: 'state',
    label: 'State',
    placeHolder: 'Enter State',
    displayValuePath: 'name',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'postal_code',
    type: 'text',
    component: Text,
    label: 'Postal Code',
    placeHolder: 'Enter Postal Code',
    validate: [isValidPostalCode],
    format: formatVal('postal_code'),
    required: true,
  },
  {
    name: 'identifier',
    type: 'text',
    label: 'Service Identifier',
    component: Text,
    placeHolder: 'Enter Service Identifier',
    validate: [isValidIdentifier],
    required: true,
  },
  {
    name: 'domains',
    type: 'text',
    component: InputChip,
    label: 'domain',
    placeHolder: 'Enter domain',
    validate: [isDomainPresent, isValidDomain],
    required: true,
    disable: false,
  },
];
