import { store } from './../index';
import { isCreateAction } from './../../utils/helper';
import { ORGANIZATION_ROUTES, POLICE_DEPT_ROUTES } from './../../routes/path';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';

import * as organizationService from 'services/organization.service';
import { showErrorMsg, showSuccessMsg, COMMON_ACTION_TYPES } from 'utils';

import { policeDeptActions } from './policeDept.slice';
import {
  AddEditOrganizationActionPayload,
  OrganizationTypeInterface,
  OrganizationSpecificActionPayload,
  AddEditOrgUserActionPayload,
  CreateNewOrgUserButtonClickActionPayload,
  ParticularUserDetailsActionPayload,
} from './policeDept.type';
import { getPoliceDeptCategory } from 'store/app/app.selector';
import { CategoryInterface } from 'store/app/app.types';

import { getOrganizationCategory } from 'store/app';

function* getPoliceDeptData() {
  try {
    // fake api call
    // const response = _.cloneDeep(organizationFakeData) as OrganizationType[];

    const policeDeptCategory: CategoryInterface = yield select(
      getPoliceDeptCategory,
    );
    // @ts-ignore
    const response = yield organizationService.getOrganizationList(
      policeDeptCategory.id,
    );
    yield put(
      policeDeptActions.fetchPoliceDeptDataSuccess({
        data: response?.data?.data,
        meta: response?.data?.meta?.pagination,
      }),
    );
  } catch (err) {
    showErrorMsg(err);
    yield put(policeDeptActions.fetchPoliceDeptDataFailure());
  }
}

// function* handleCreatePoliceDeptButtonClick() {
//   try {
//     const url = `${POLICE_DEPT_ROUTES.POLICE_DEPT.path}/new`;
//     yield put(push(url));
//   } catch (err) {
//     showErrorMsg(err);
//   }
// }

// function* getParticularOrganizationDetails(
//   action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
// ) {
//   try {
//     // fake api call
//     // const response = _.cloneDeep(organizationFakeData) as OrganizationType[];

//     const { orgId } = action.payload;

//     // @ts-ignore
//     const response = yield organizationService.getOrganizationDetails(orgId);

//     yield put(
//       organizationActions.fetchParticularOrganizationDetailsSuccess(
//         response.data,
//       ),
//     );
//   } catch (err) {
//     showErrorMsg(err);
//     yield put(organizationActions.fetchParticularOrganizationDetailsFailure());
//   }
// }

// function* addOrEditOrganizationsData(
//   action: PayloadAction<AddEditOrganizationActionPayload>,
// ) {
//   try {
//     const { formValue, id } = action.payload;

//     // @ts-ignore
//     const organizationCategoryObj = yield select(getOrganizationCategory);

//     const requestPayload = _.cloneDeep(formValue);

//     // @ts-ignore
//     requestPayload['organization_type'] = organizationCategoryObj?.id;
//     let req = null;
//     let actionType = '';
//     let msg = '';
//     if (id === 'new') {
//       actionType = 'new';
//       req = organizationService.createOrganization({ body: requestPayload });
//       msg = 'Organization Created Successfully';
//     } else {
//       actionType = 'update';
//       req = organizationService.updateOrganization({
//         body: requestPayload,
//         id,
//       });
//       msg = 'Organization Updated Successfully';
//     }

//     // @ts-ignore
//     const response = yield req;
//     yield put(
//       organizationActions.addOrEditOrganizationsAsyncSuccess({
//         updatedValue: response.data,
//         actionType,
//       }),
//     );
//     const organizationId = _.get(response.data, 'id', '');

//     if (organizationId) {
//       yield put(
//         push(`${ORGANIZATION_ROUTES.ORGANIZATION.path}/${organizationId}`),
//       );
//     }
//     showSuccessMsg(msg);
//   } catch (err) {
//     showErrorMsg(err);
//     yield put(organizationActions.addOrEditOrganizationsAsyncFailure());
//   }
// }
// function* handleOrganizationRowClick(
//   action: PayloadAction<OrganizationTypeInterface>,
// ) {
//   try {
//     const url = `${ORGANIZATION_ROUTES.ORGANIZATION.path}/${action.payload.id}`;
//     yield put(push(url));

//     yield fetchOrganizationSpecificData({
//       payload: {
//         orgId: action.payload.id,
//         showLoader: false,
//       },
//     });
//   } catch (err) {
//     showErrorMsg(err);
//   }
// }

// function* fetchOrganizationSpecificData(
//   action:
//     | PayloadAction<OrganizationSpecificActionPayload>
//     | { payload: OrganizationSpecificActionPayload },
// ) {
//   try {
//     // fetching the details of the organization roles and users
//     yield put(
//       organizationActions.organizationRolesAsyncRequest({
//         orgId: action.payload.orgId,
//         showLoader: false,
//       }),
//     );
//     yield put(
//       organizationActions.organizationUsersAsyncRequest({
//         orgId: action.payload.orgId,
//         showLoader: false,
//       }),
//     );
//   } catch (err) {
//     showErrorMsg(err);
//   }
// }

// function* getOrganizationRolesList(
//   action: PayloadAction<OrganizationSpecificActionPayload>,
// ) {
//   try {
//     // @ts-ignore
//     const response = yield organizationService.getOrganizationRoles({
//       id: action.payload.orgId,
//     });
//     yield put(organizationActions.organizationRolesAsyncSuccess(response.data));
//   } catch (err) {
//     showErrorMsg(err);
//     yield put(organizationActions.organizationRolesAsyncFailure());
//   }
// }
// function* getOrganizationUserList(
//   action: PayloadAction<OrganizationSpecificActionPayload>,
// ) {
//   try {
//     // @ts-ignore
//     const response = yield organizationService.getOrganizationUsers({
//       id: action.payload.orgId,
//     });
//     yield put(organizationActions.organizationUsersAsyncSuccess(response.data));
//   } catch (err) {
//     showErrorMsg(err);
//     yield put(organizationActions.organizationUsersAsyncFailure());
//   }
// }
// function* getParticularUserDetails(
//   action: PayloadAction<ParticularUserDetailsActionPayload>,
// ) {
//   try {
//     // @ts-ignore
//     const response = yield organizationService.getOrgUserDetails({
//       userId: action.payload.userId,
//     });
//     yield put(organizationActions.fetchUserDetailsAsyncSuccess(response.data));
//   } catch (err) {
//     showErrorMsg(err);
//     yield put(organizationActions.fetchUserDetailsAsyncFailure());
//   }
// }

// function* handleCreateOrgUserButtonClick(
//   action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
// ) {
//   try {
//     const { orgId } = action.payload;
//     const url = `${ORGANIZATION_ROUTES.ORGANIZATION.path}/${orgId}/users/new`;
//     yield put(push(url));
//   } catch (err) {
//     showErrorMsg(err);
//   }
// }

// function* addOrEditOrganizationUser(
//   action: PayloadAction<AddEditOrgUserActionPayload>,
// ) {
//   try {
//     const { formValue, orgId, userId, actionType } = action.payload;
//     let req = null;
//     let msg = '';
//     const requestPayload = _.cloneDeep(formValue);

//     // @ts-ignore
//     requestPayload[`password`] = 'secret';
//     // @ts-ignore
//     if (isCreateAction(userId || '')) {
//       req = organizationService.createOrgUsers({
//         body: requestPayload,
//         orgId: orgId,
//       });
//       msg = 'User Created Successfully';
//     } else {
//       req = organizationService.updateOrgUsers({
//         body: requestPayload,
//         orgId: orgId,
//         userId,
//       });
//       msg = 'User Updated Successfully';
//     }

//     // @ts-ignore
//     const response = yield req;

//     const updatedUserId = _.get(response.data, 'id', '');

//     yield put(
//       organizationActions.addOrEditOrgUserAsyncSuccess({
//         updatedValue: response.data,
//         actionType,
//       }),
//     );

//     if (updatedUserId) {
//       yield put(
//         push(
//           `${ORGANIZATION_ROUTES.ORGANIZATION.path}/${orgId}/users/${updatedUserId}`,
//         ),
//       );
//     }
//     showSuccessMsg(msg);
//   } catch (err) {
//     showErrorMsg(err);
//     yield put(organizationActions.addOrEditOrgUserAsyncFailure());
//   }
// }

export function* policeDeptSaga(): Generator {
  yield all([
    takeLatest(policeDeptActions.fetchPoliceDeptDataRequest, getPoliceDeptData),
    // takeLatest(
    //   fetchParticularOrganizationDetailsRequest,
    //   getParticularOrganizationDetails,
    // ),
    // takeLatest(
    //   organizationActions.addOrEditOrganizationsAsyncRequest,
    //   addOrEditOrganizationsData,
    // ),
    // takeLatest(
    //   organizationActions.organizationRowClick,
    //   handleOrganizationRowClick,
    // ),
    // takeLatest(
    //   organizationActions.organizationRolesAsyncRequest,
    //   getOrganizationRolesList,
    // ),
    // takeLatest(
    //   organizationActions.organizationUsersAsyncRequest,
    //   getOrganizationUserList,
    // ),
    // takeLatest(
    //   organizationActions.fetchUserDetailsAsyncRequest,
    //   getParticularUserDetails,
    // ),
    // takeLatest(
    //   organizationActions.createNewOrgUserButtonClick.type,
    //   handleCreateOrgUserButtonClick,
    // ),
    // takeLatest(
    //   organizationActions.addOrEditOrgUserAsyncRequest,
    //   addOrEditOrganizationUser,
    // ),
    // takeLatest(
    //   organizationActions.fetchOrgSpecificData,
    //   fetchOrganizationSpecificData,
    // ),
  ]);
}
