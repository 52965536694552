import { Button, DialogActions, Typography } from '@material-ui/core';
import { FiberManualRecordRounded } from '@material-ui/icons';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAcceptDenyJoinRequestInProgress } from 'store';
import { eventActions } from 'store/event/event.slice';
import {
  AcceptEventJoinRequestPayload,
  DenyEventJoinRequestPayload,
  EventJoinResponseInterface,
  getTimeFormatted,
} from 'utils';
import { useMapContainerStyle } from './style';

export function JoinRequest(props: {
  index: number;
  joinRequest: EventJoinResponseInterface;
}) {
  const { index, joinRequest } = props;
  const user = joinRequest.user;
  const classes = useMapContainerStyle();
  const isAcceptDenyJoinRequestInProgress = useSelector(
    getAcceptDenyJoinRequestInProgress,
  );
  const dispatch = useDispatch();
  const reportCreatedAt = dayjs(joinRequest.created_at);
  const now = dayjs();
  const [isCreatedRecently, setIsCreatedRecently] = useState(
    now.diff(reportCreatedAt, 'minute') < 1,
  );

  const onAccept = () => {
    if (!isAcceptDenyJoinRequestInProgress) {
      const payload: AcceptEventJoinRequestPayload = {
        requestIndex: index,
        eventId: joinRequest.event.id,
        eventJoinRequest: joinRequest,
      };
      dispatch(eventActions.acceptEventJoinRequestRequest(payload));
    }
  };
  const onDeny = () => {
    if (!isAcceptDenyJoinRequestInProgress) {
      const payload: DenyEventJoinRequestPayload = {
        requestIndex: index,
        eventId: joinRequest.event.id,
        eventJoinRequest: joinRequest,
      };
      dispatch(eventActions.denyEventJoinRequestRequest(payload));
    }
  };

  const [denyLabel, acceptLabel] = ['Deny', 'Accept'];

  return (
    <div className={`${classes.raisinBgColor} ${classes.involvedUserCtn}`}>
      {isCreatedRecently ? (
        <Typography className={classes.justNow}>
          <FiberManualRecordRounded fontSize="inherit" />
          <span className={classes.justNowText}>Just Now</span>
        </Typography>
      ) : (
        <Typography className={classes.date}>
          {getTimeFormatted(joinRequest.created_at)}
        </Typography>
      )}

      <Typography
        className={classes.reportType}
      >{`${user.first_name} ${user.last_name} - ${user.role.name}`}</Typography>
      <Typography className={classes.reportUser}>
        {user.organization.name}
      </Typography>
      <DialogActions className={`${classes.joinBtnsCtn}`}>
        <div className="row"></div>
        <Button
          data-cy={denyLabel.toLowerCase().replace(' ', '-')}
          onClick={onDeny}
          type="submit"
          className={`${classes.btnSize} ${classes.redBtnColor}`}
        >
          {denyLabel}
        </Button>
        <Button
          data-cy={acceptLabel.toLowerCase().replace(' ', '-')}
          onClick={onAccept}
          type="submit"
          className={`${classes.btnSize} ${classes.greenBtnColor}`}
        >
          {acceptLabel}
        </Button>
      </DialogActions>
    </div>
  );
}
