import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useRouteMatch } from 'react-router';
import {
  AppRouteInterface,
  EMS_ROUTES,
  POLICE_DEPT_ROUTES,
  RoutesInterface,
  ORGANIZATION_ROUTES,
  SPECIAL_EVENT_ROUTES,
} from 'routes/path';
import {
  findActiveRoute,
  isEmsRoute,
  isOrgRoute,
  isPoliceDeptRoute,
  isUserRoute,
} from 'utils/helper';
import { BreadcrumbDataProps } from 'components/CustomBreadcrumbs';
import { OrgTypeInterface } from 'utils/types';
import {
  APP_CATEGORY,
  CERA_ADMIN_SCOPES,
  EMS_COMMANDER_UNIQUE_SCOPES,
  ORG_ADMIN_SCOPES,
  POLICE_COMMANDER_UNIQUE_SCOPES,
  SCOPES,
} from 'utils/constants';
import { getAuthScope } from 'services/localStorage.service';
import { useSelector } from 'react-redux';
import {
  getCurrentLocation,
  getEMSUserFormValue,
  getOrgUserFormValue,
  getPoliceUserFormValue,
  getSelectedUser,
  getSelectedUsers,
  getUserDetailsFormValue,
  isEMSUserFormTouched,
  isOrgUserFormTouched,
  isPoliceUserFormTouched,
  isUserDetailsFormTouched,
} from 'store';

export const useActiveAppRouteMatch = (appRoutesObj: AppRouteInterface) => {
  const currentRoute = useRouteMatch();
  const routeObj = findActiveRoute(appRoutesObj, currentRoute.url);
  const [activeRoute, setActiveRoute] = useState<RoutesInterface | null>(
    routeObj,
  );
  useEffect(() => {
    const routeObj = findActiveRoute(appRoutesObj, currentRoute.url);

    setActiveRoute(routeObj);
    return () => {
      setActiveRoute(null);
    };
  }, [currentRoute.url]);

  return activeRoute;
};

type OrgType = { id: string; name: string } | null;
type UserType = {
  id: string;
  first_name: string;
  organization: OrgType;
} | null;

const useCurrentUrl = (
  org: OrgType,
  user: UserType,
  currentRoute: any,
  cb: any,
): any => {
  const orgValue =
    _.get(org, 'name', null) ||
    _.get(user, 'organization.name', '') ||
    _.get(org, 'first_name', null);
  const userName = _.get(user, 'first_name', '');
  const currentUrl = currentRoute.url;

  const filteredUrlArr = currentUrl.split('/').filter((path: string) => path);
  console.log('%c filteredUrlArr ', 'background: lime; color: black', {
    filteredUrlArr,
  });
  let routeToNavigate = '';

  return filteredUrlArr.map((path: string, index: number) => {
    let label = path;
    routeToNavigate = `${routeToNavigate}/${path}`;

    // replace :orgId with name
    // Eg: org/:id
    if (index === 1 && path && path !== 'new') {
      label = orgValue;
    }

    // replace :userId with name
    // Eg: org/:id/users/:userId
    if (index === 3 && path && path !== 'new') {
      label = userName;
    }

    return {
      label,
      value: routeToNavigate,
      onClick: cb,
    };
  });

  // let newRoute = '';
  // const updatedRouteArr = currentUrl
  //   .split('/')
  //   .forEach((path: string, index: number) => {
  //     console.log('%c path ', 'background: black; color: yellow', {
  //       path,
  //       newRoute,
  //     });
  //     if (index === 0 && path) {
  //       return '';
  //     }
  //     let val = path;
  //     // replace :orgId with name
  //     // Eg: org/:id
  //     if (index === 2 && path && path !== 'new') {
  //       val = orgValue;
  //     }

  //     // replace :userId with name
  //     // Eg: org/:id/users/:userId
  //     if (index === 4 && path && path !== 'new') {
  //       val = userName;
  //     }
  //     newRoute = newRoute ? `${newRoute}/${val}` : val;
  //     return path;
  //   });

  // return newRoute;
};

export const useBreadCrumb = (
  org: OrgType | null,
  user: UserType | null,
): BreadcrumbDataProps[] | [] => {
  const currentRoute = useRouteMatch();
  const [breadcrumb, setBreadCrumb] = useState<BreadcrumbDataProps[] | []>([]);
  const callback = (value: string) => {
    if (
      haveValidScope(CERA_ADMIN_SCOPES) ||
      (value !== POLICE_DEPT_ROUTES.POLICE_DEPT.path &&
        value !== ORGANIZATION_ROUTES.ORGANIZATION.path &&
        value !== EMS_ROUTES.EMS_ORG_LIST.path)
    ) {
    }
  };
  // const newBreadCrumb = useCurrentUrl(org, user, currentRoute, callback);

  useEffect(() => {
    const newBreadCrumb = useCurrentUrl(org, user, currentRoute, callback);
    setBreadCrumb(newBreadCrumb);
    return () => {
      setBreadCrumb([]);
    };
  }, [org, currentRoute.url, user]);

  return breadcrumb;
};

export const getAuthenticatedUserOrgName = (
  authOrg: string,
  allCategories: OrgTypeInterface[],
): string | undefined => {
  // const allCategories = useSelector(getAllCategory);
  const currentOrgType = _.find(
    allCategories,
    (obj: OrgTypeInterface) => obj.id === authOrg,
  );
  return currentOrgType?.name;
};

export const getDefaultPath = (
  authOrgName: string | undefined,
): RoutesInterface => {
  switch (authOrgName) {
    case APP_CATEGORY.POLICE_DEPARTMENT:
      return POLICE_DEPT_ROUTES.POLICE_DEPT_DETAILS;
    case APP_CATEGORY.EMS:
      return EMS_ROUTES.EMS_ORG_DETAILS;
    case APP_CATEGORY.SPECIAL_EVENT:
      return SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_DETAILS;
    default:
      return ORGANIZATION_ROUTES.ORGANIZATION_DETAILS;
  }
};

export const haveValidScope = (scope: string): boolean => {
  const receivedScopes = getAuthScope();
  const receivedScopesList = receivedScopes?.split(' ');
  const scopesList = scope.split(' ');
  if (_.intersection(receivedScopesList, scopesList).length === 0) {
    return false;
  }
  return true;
};

export const haveAllScopes = (scope: string): boolean => {
  const receivedScopes = getAuthScope();
  const receivedScopesList = receivedScopes?.split(' ');
  const scopesList = scope.split(' ');

  return (
    _.intersection(receivedScopesList, scopesList).length === scopesList?.length
  );
};

export const useModal = () => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  return [open, openModal, closeModal] as const;
};
// export const isUserAllowed = (
//   departmentRoute:
//     | typeof ORGANIZATION_ROUTES
//     | typeof POLICE_DEPT_ROUTES
//     | typeof EMS_ROUTES
//     | typeof USERS_ROUTES,
//   userPermission: Permission | null,
// ) => {
//   console.log(departmentRoute);
//   (Object.keys(departmentRoute) as Array<keyof typeof departmentRoute>).map(
//     (routeKey) => {
//       // TODO
//       // @ts-ignore
//       return _.indexOf(
//         departmentRoute[routeKey].permissions,
//         userPermission,
//       ) !== -1
//         ? departmentRoute[routeKey].path
//         : '/';
//     },
//   );
// };

export const getUserForms = () => {
  const orgUserForm = useSelector(getOrgUserFormValue);
  const policeUserForm = useSelector(getPoliceUserFormValue);
  const emsUserForm = useSelector(getEMSUserFormValue);
  const userForm = useSelector(getUserDetailsFormValue);
  const isOrgUserTouched = useSelector(isOrgUserFormTouched);
  const isPoliceUserTouched = useSelector(isPoliceUserFormTouched);
  const isEMSUserTouched = useSelector(isEMSUserFormTouched);
  const isUserDetailsTouched = useSelector(isUserDetailsFormTouched);
  const currentRoute = useSelector(getCurrentLocation);

  if (isUserRoute(currentRoute.pathname)) {
    return [userForm, isUserDetailsTouched];
  } else {
    if (isOrgRoute(currentRoute.pathname)) {
      return [orgUserForm, isOrgUserTouched];
    } else if (isPoliceDeptRoute(currentRoute.pathname)) {
      return [policeUserForm, isPoliceUserTouched];
    } else if (isEmsRoute(currentRoute.pathname)) {
      return [emsUserForm, isEMSUserTouched];
    } else return [{}, false];
  }
};

export const getCurrentUser = () => {
  const selectedOrgUser = useSelector(getSelectedUsers);
  const currentUser = useSelector(getSelectedUser);
  const currentRoute = useSelector(getCurrentLocation);
  if (isUserRoute(currentRoute.pathname)) {
    return currentUser;
  } else return selectedOrgUser;
};

export const isOpsCenter = (): boolean => {
  return haveValidScope(
    `${EMS_COMMANDER_UNIQUE_SCOPES} ${POLICE_COMMANDER_UNIQUE_SCOPES}`,
  );
};

export const isEmsCommander = (): boolean => {
  return haveValidScope(EMS_COMMANDER_UNIQUE_SCOPES);
};

export const isSEManager = (): boolean => {
  return haveAllScopes(`${SCOPES.EVENT_READ} ${ORG_ADMIN_SCOPES}`);
};
