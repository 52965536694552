import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import httpStatus from 'http-status';
import camelCase from 'camelcase';
import { ApiResponseInterface } from 'utils';

export const camelCaseForObject = (data: any) => {
  const obj = {};
  _.mapKeys(data, (v, k) => {
    const camelCaseKey = camelCase(k);
    // todo
    // @ts-ignore
    obj[`${camelCaseKey}`] = convertToCamelCase(data[k]);
  });
  return obj;
};

const convertToCamelCase = (data: any) => {
  if (_.isString(data)) {
    return data;
  }

  if (_.isArray(data)) {
    return camelCaseForArray(data);
  }

  if (_.isObject(data)) {
    return camelCaseForObject(data);
  }

  return data;
};

const camelCaseForArray = (data: any) => {
  const arrayInstance: any[] = [];
  _.forEach(data, (value) => {
    arrayInstance.push(convertToCamelCase(value));
  });
  return arrayInstance;
};

export const callAPIService = (req: Promise<AxiosResponse<any>>) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await req;
      const parsedData = response?.data || response;
      const successResponse = <ApiResponseInterface>{
        ...parsedData,
      };

      // successResponse.data = convertToCamelCase(parsedData);
      // successResponse.data = parsedData;
      successResponse.isError = false;
      successResponse.statusCode = response?.status;
      successResponse.statusName = <string>(
        httpStatus[`${response?.status}_NAME`]
      );

      resolve(successResponse);
    } catch (err) {
      let errResponse = <ApiResponseInterface>{};

      const aerr = err as AxiosError;

      if (aerr?.response) {
        const parsedData = aerr?.response?.data || aerr.response;
        // errResponse.data = convertToCamelCase(err.response.data);
        errResponse = { ...convertToCamelCase(parsedData) };
        errResponse.statusCode = aerr.response.status;
        errResponse.statusName = <string>(
          httpStatus[`${aerr.response.status}_NAME`]
        );
        errResponse.isError = true;
      } else if (aerr?.request && aerr.request.status) {
        errResponse.data = aerr.request.statusText;
        errResponse.statusCode = aerr.request.status;
        errResponse.statusName = <string>(
          httpStatus[`${errResponse.statusCode}_NAME`]
        );
        errResponse.isError = true;
      } else {
        errResponse.data = aerr.message;
        errResponse.statusCode = 500;
        errResponse.statusName = <string>httpStatus[`500_NAME`];
        errResponse.isError = true;
      }

      reject(errResponse);
    }
  });
};
