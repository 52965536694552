import React, { useState } from 'react';
import _ from 'lodash';
import { Marker } from '@react-google-maps/api';
import { MarkerObj } from 'utils/commonTypes';
import { DeleteButton } from 'routes/Organization/Map/DeleteButton';
import { CERA_ADMIN_SCOPES } from 'utils';
import { haveValidScope } from 'utils/hooks';
import { COLORS } from 'styles/colorConstants';

interface MarkerPropsIF {
  points: MarkerObj[] | MarkerObj;
  onEdit: any;
  onDelete: any;
  setPPDelBtn: any;
  setOverlayVisibility: any;
  setCpDelBtn: any;
  setEmsCpDelBtn: any;
  ppDelBtn: boolean;
  cpDelBtn: boolean;
  emsCpDelBtn: boolean;
  type: string;
  onClickDeleteAll: any;
}

export const CustomMarker = (props: any) => {
  const { point, onRightClick, onEdit, type } = props;
  const isAdmin = haveValidScope(CERA_ADMIN_SCOPES);
  return (
    <Marker
      position={point.position}
      icon={
        type === 'pp'
          ? {
              url: point.icon,
              anchor: new window.google.maps.Point(17.5, 18.5),
            }
          : point.icon
      }
      label={
        type === 'pp'
          ? {
              text: point.label ? point.label : '-',
              color: COLORS.PP_COLOR,
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              fontSize: '20px',
            }
          : point.label
      }
      onRightClick={onRightClick}
      draggable={isAdmin}
      // onMouseUp={onEdit}
      // onMouseDown={onEdit}
      onDragEnd={onEdit}
    />
  );
};

export const RenderMarker = (props: MarkerPropsIF): any => {
  const {
    points,
    onEdit,
    onDelete,
    ppDelBtn,
    setPPDelBtn,
    setOverlayVisibility,
    setCpDelBtn,
    cpDelBtn,
    type,
    onClickDeleteAll,
    emsCpDelBtn,
    setEmsCpDelBtn,
  } = props;

  const [activeMarker, setActiveMarker] = useState<MarkerObj | null>(null);
  const [activeMarkerIndex, setActiveMarkerIndex] = useState<
    number | string | null
  >(null);
  const isAdmin = haveValidScope(CERA_ADMIN_SCOPES);
  const handleOnEdit = (point: MarkerObj, event: any, index?: number) => {
    console.log('%c handle the update ', 'background: black; color: yellow', {
      point,
      event,
      index,
    });
    const latLng = event.latLng;
    const newLocation = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    onEdit(newLocation, point.id ? point.id : index);
  };

  const handleRightClick = (pt: MarkerObj, index: number | string) => {
    setActiveMarker(pt);
    if (isAdmin) {
      setOverlayVisibility(false);
      if (type === 'cp') {
        setCpDelBtn(true);
        setPPDelBtn(false);
        setEmsCpDelBtn(false);
      } else if (type === 'pp') {
        setCpDelBtn(false);
        setPPDelBtn(true);
        setEmsCpDelBtn(false);
      } else {
        setCpDelBtn(false);
        setPPDelBtn(false);
        setEmsCpDelBtn(true);
      }
    }
    setActiveMarkerIndex(index);
  };

  const handleDeleteBtnClick = () => {
    setPPDelBtn(false);
    onDelete(activeMarkerIndex);
  };

  return (
    <>
      {_.isArray(points) &&
        points.length &&
        points.map((pt, index) => {
          return (
            <CustomMarker
              point={pt}
              key={pt.id || index}
              type={type}
              onRightClick={() => handleRightClick(pt, pt.id ? pt.id : index)}
              onEdit={(e: any) => handleOnEdit(pt, e, index)}
            />
          );
        })}

      {/* {_.isObject(points) && (
        <CustomMarker
          point={points}
          type={type}
          onRightClick={() => handleRightClick(points as MarkerObj, 0)}
          onEdit={(e: any) => handleOnEdit(points as MarkerObj, e)}
        />
      )} */}

      {((type === 'pp' && ppDelBtn) ||
        (type === 'cp' && cpDelBtn) ||
        (type === 'emsCp' && emsCpDelBtn)) &&
        activeMarker?.position && (
          <DeleteButton
            position={activeMarker?.position}
            onClick={handleDeleteBtnClick}
            onClickDeleteAll={onClickDeleteAll}
          />
        )}
    </>
  );
};

export default RenderMarker;
