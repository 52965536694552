import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useVideoCallContainerStyle } from './styles';
import { ReactComponent as VideoCallEndIcon } from 'assets/video-call-end.svg';
import { ReactComponent as VideoCallMuteIcon } from 'assets/video-call-mute.svg';
import { OngoingVideoCallPropType, IAgoraUser } from './hooks/useVideoCall';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { useDrag, useDragDropManager } from 'react-dnd';
import Draggable from 'react-draggable';

export const OngoingVideoCall = (props: OngoingVideoCallPropType) => {
  const classes = useVideoCallContainerStyle();

  return (
    <>
      {props.isVideoCallOngoing && (
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div id="videos" className={classes.videoContainer}>
            <div className={classes.videoClose}>
              {/* <VideoCallEndIcon
                fontSize="small"
                onClick={() => props.setPnPMode(true)}
              /> */}

              <CloseOutlined
                fontSize="large"
                onClick={() => {
                  console.log('setting pnp');
                  props.setPnPMode(true);
                }}
              />
            </div>
            <div className={classes.videoControls}>
              <div style={{ marginRight: 20 }}>
                <VideoCallMuteIcon />
              </div>
              <div>
                <VideoCallEndIcon onClick={() => props.leaveChannel(true)} />
              </div>
            </div>
            {props.users.length == 0 ? (
              <div className={classes.userConnectingContainer}>
                <h2>{props.otherUserName}</h2>
                <small>Connecting...</small>
              </div>
            ) : (
              props.users.map((user) => <Video key={user.uid} user={user} />)
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const PictureInPictureVideoCall = (props: OngoingVideoCallPropType) => {
  const classes = useVideoCallContainerStyle();

  // const [x, setX] = useState(100);
  // const [y, setY] = useState(100);

  // const dragDropManager = useDragDropManager();
  // const monitor = dragDropManager.getMonitor();

  // useEffect(() => {
  //   monitor.subscribeToOffsetChange(() => {
  //     console.log('Dragging', monitor.getItemType(), monitor.getClientOffset());
  //     if (monitor.getItemType() === 'video-call') {
  //       console.log('', monitor.getClientOffset());
  //       const coords = monitor.getClientOffset();

  //       if (coords) {
  //         setX(coords.x);
  //         setY(coords.y);
  //       }
  //     }
  //   });
  // }, [monitor]);

  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: 'video-call',
  //   canDrag: true,
  //   collect: (monitor) => ({
  //     isDragging: !!monitor.isDragging(),
  //   }),
  // }));

  return (
    <>
      {props.isVideoCallOngoing && (
        <Draggable>
          <div
            id="videos"
            className={classes.pictureInPictureContainer}
            // style={{ top: y, left: x }}
            // ref={drag}
          >
            <div className={classes.videoClose}>
              <CloseOutlined
                fontSize="small"
                onClick={() => {
                  console.log('setting pnp');
                  props.setPnPMode(false);
                }}
              />
            </div>
            {props.users.length == 0 ? (
              <div className={classes.userConnectingContainer}>
                <h2>{props.otherUserName}</h2>
                <small>Connecting...</small>
              </div>
            ) : (
              props.users.map((user) => <Video key={user.uid} user={user} />)
            )}
          </div>
        </Draggable>
      )}
    </>
  );
};

interface IVideoProps {
  user: IAgoraUser;
}

const Video = (props: IVideoProps) => {
  const user = props.user;
  const vidDiv = useRef<HTMLDivElement>(null);

  const playVideo = () => {
    console.log('vidDiv.current', vidDiv.current);
    // @ts-ignore
    user.videoTrack?.play(vidDiv.current);
  };
  const stopVideo = () => {
    user.videoTrack?.stop();
  };

  useEffect(() => {
    console.log('Playing for ', user.uid);
    playVideo();
    return () => {
      stopVideo();
    };
  }, []);

  const classes = useVideoCallContainerStyle();

  return (
    <>
      <div ref={vidDiv} className={classes.userVideoContainer}></div>
    </>
  );
};
