import { OverRideInnerPPInterface } from 'models/event/event.model';
import { MarkerObj, PositionInterface } from './commonTypes';

export function getCurrentLocation(): Promise<PositionInterface> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(
          'Current lat,lng: ',
          position.coords.latitude,
          position.coords.longitude,
        );
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        console.log('Could not fetch current location', err);
        reject(err.message);
      },
    );
  });
}

function squaredPolar(point: PositionInterface, centre: PositionInterface) {
  return [
    Math.atan2(point.lat - centre.lat, point.lng - centre.lng),
    (point.lng - centre.lng) ** 2 + (point.lat - centre.lat) ** 2, // Square of distance
  ];
}

interface PolarMarkerObj extends MarkerObj {
  polarCentre?: PositionInterface;
}

interface PolarMarker {
  position: PositionInterface;
  polarCenter?: PositionInterface;
}

// Main algorithm:
export function polySort(points: PolarMarker[]) {
  // Get "centre of mass"
  const centre = {
    lng: points.reduce((sum, p) => sum + p.position.lng, 0) / points.length,
    lat: points.reduce((sum, p) => sum + p.position.lat, 0) / points.length,
  };

  // Sort by polar angle and distance, centered at this centre of mass.
  for (const point of points) {
    const [lng, lat] = squaredPolar(point.position, centre);
    point.polarCenter = { lng, lat };
  }

  points.sort((a, b) => {
    if (a.polarCenter && b.polarCenter) {
      return (
        a.polarCenter.lng - b.polarCenter.lng ||
        a.polarCenter.lat - b.polarCenter.lat
      );
    } else {
      return 0;
    }
  });

  return points;
}

export function markerObjPolySort(points: MarkerObj[]): MarkerObj[] {
  const markerMap = {} as Record<string, MarkerObj>;

  for (const point of points) {
    markerMap[`${point.position.lng}:${point.position.lat}`] = point;
  }

  const polarPoints: PolarMarker[] = points.map((point) => {
    return {
      position: {
        lat: point.position.lat,
        lng: point.position.lng,
      },
    };
  });

  const sortedPolarPoints = polySort(polarPoints);

  const sortedMarkerObjs: MarkerObj[] = [];

  for (const point of sortedPolarPoints) {
    sortedMarkerObjs.push(
      markerMap[`${point.position.lng}:${point.position.lat}`],
    );
  }

  return sortedMarkerObjs;
}

export function overrideInnerPPPolySort(
  points: OverRideInnerPPInterface[],
): OverRideInnerPPInterface[] {
  const markerMap = {} as Record<string, OverRideInnerPPInterface>;

  for (const point of points) {
    markerMap[
      `${point.point.coordinates.lng}:${point.point.coordinates.lat}`
    ] = point;
  }

  const polarPoints: PolarMarker[] = points.map((point) => {
    return {
      position: {
        lat: point.point.coordinates.lat,
        lng: point.point.coordinates.lng,
      },
    };
  });

  const sortedPolarPoints = polySort(polarPoints);

  const sortedMarkerObjs: OverRideInnerPPInterface[] = [];

  for (const point of sortedPolarPoints) {
    sortedMarkerObjs.push(
      markerMap[`${point.position.lng}:${point.position.lat}`],
    );
  }

  return sortedMarkerObjs;
}
