import _ from 'lodash';
import { normalize } from 'normalizr';
import { isNilOrEmpty } from 'utils/helper';
import { UserRolesType, ASROrgInterface } from 'utils/types';
import { OrganizationRolesInterface } from 'store/organization';
import {
  OrganizationTypeInterface,
  basicOrgDetailsFromJson,
} from 'models/organization';
import { normalizeUserRoleList } from 'utils/schema';

export function userOrgModelFromJSON(
  apiResponse: ASROrgInterface[],
): OrganizationTypeInterface[] {
  if (isNilOrEmpty(apiResponse)) {
    return [];
  }
  if (!_.isArray(apiResponse)) {
    return [];
  }

  const transformData = (
    combinedOrgList: OrganizationTypeInterface[],
    orgListObj: ASROrgInterface,
  ) => {
    const response = basicOrgDetailsFromJson(orgListObj.data);
    return [...combinedOrgList, ...response];
  };

  const mappedData = _.reduce(apiResponse, transformData, []);
  return mappedData;

  //   const normalizedData = normalizeUserOrgList(mappedData);
}

export function userRolesModelFromJSON(
  apiResponse: OrganizationRolesInterface[],
): UserRolesType {
  if (isNilOrEmpty(apiResponse)) {
    return {
      entities: {},
      result: [],
    };
  }
  if (!_.isArray(apiResponse)) {
    return {
      entities: {},
      result: [],
    };
  }

  const mappedData = normalizeUserRoleList(apiResponse);
  console.log('%c mappedData ', 'background: lime; color: black', {
    mappedData,
  });

  return {
    entities: _.get(mappedData, 'entities', {}),
    result: _.get(mappedData, 'result', []),
  };
}
