import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { isAuthorised } from 'services/localStorage.service';
import { ROUTES, DEFAULT_LANDING_ROUTE } from './path';
import { isCurrentRoute } from 'utils/helper';
import {
  getAuthenticatedUserOrgName,
  getDefaultUrl,
  isOpsCenter,
} from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveEvent,
  getAllCategory,
  getAuthenticatedUserId,
  getAuthenticatedUserOrgId,
  getAuthenticatedUserOrgType,
  getHasActiveEvent,
} from 'store';
import CircularIndeterminate from 'components/General/Spinner';
import { eventActions } from 'store/event/event.slice';
import { isBoolean } from 'lodash';

export const UnKnowRouteResolver = () => {
  const dispatch = useDispatch();
  const isRootRoute = isCurrentRoute({ path: '/' });
  const orgId = useSelector(getAuthenticatedUserOrgId);
  const orgType = useSelector(getAuthenticatedUserOrgType);
  const allCategories = useSelector(getAllCategory);
  const userId = useSelector(getAuthenticatedUserId);
  const orgName = getAuthenticatedUserOrgName(orgType, allCategories);
  const opsCenter = isOpsCenter();
  const event = useSelector(getActiveEvent);
  const eventId = event?.id;
  const hasActiveEvent = useSelector(getHasActiveEvent);

  useEffect(() => {
    if (isAuthorised()) dispatch(eventActions.requestActiveEvent(null));
  }, []);

  if (!isAuthorised()) {
    return <Redirect to={ROUTES.LOGIN.path} />;
  }

  if (isRootRoute) {
    if (!opsCenter) {
      if (isAuthorised() && userId !== '' && allCategories.length !== 0) {
        return (
          <Redirect
            to={
              orgName && orgId
                ? getDefaultUrl(orgName, orgId)
                : DEFAULT_LANDING_ROUTE.path
            }
          />
        );
      } else return <CircularIndeterminate showSpinner />;
    } else {
      if (userId && isBoolean(hasActiveEvent) && !hasActiveEvent) {
        return <Redirect to={'events'} />;
      } else if (userId && hasActiveEvent) {
        return <Redirect to={`events/${eventId}`} />;
      } else return <CircularIndeterminate showSpinner />;
    }
  } else return <Redirect to={'/404'} />;
};

export default UnKnowRouteResolver;
