import _, { isNull } from 'lodash';
import {
  CREATE_ORG_SCOPES,
  CREATE_USER_SCOPES,
  DEACTIVATE_ORG_SCOPES,
  DEACTIVATE_USER_SCOPES,
  EDIT_ORG_SCOPES,
  EDIT_USER_SCOPES,
  SCOPES,
  STATE_DATA,
} from 'utils/constants';
import { isNilOrEmpty } from 'utils/helper';
import { FormField } from 'utils/types';
import { RoutesInterface } from 'routes/path';
import { EMS_ROUTES } from 'routes/path';
import { CombinedActionButtonInterface } from 'utils/commonTypes';
import { haveAllScopes, haveValidScope } from 'utils/hooks';
import { OrganizationUserTypeInterface } from 'store';
import { OrganizationTypeInterface } from 'models/organization';

export const getHeaderSectionVisibility = (
  currentAppRoute: RoutesInterface | null,
) => {
  if (isNilOrEmpty(currentAppRoute)) {
    return {
      leftSection: true,
      middleSection: true,
      rightSection: true,
    };
  }

  //  when on police dept data list page
  if (currentAppRoute && currentAppRoute.id === EMS_ROUTES.EMS_ORG_LIST.id) {
    return {
      leftSection: true,
      middleSection: false,
      rightSection: true,
    };
  }

  return {
    leftSection: true,
    middleSection: true,
    rightSection: true,
  };
};

export const checkAndUpdateProvinceOptions = (
  newFormValue: any,
  oldFormValue: any,
  fieldArr: FormField[],
) => {
  const updateCountryName = _.get(newFormValue, 'country.name', '');
  const oldCountryName = _.get(oldFormValue, 'country.name', null);
  console.log('%c updateCountryName ', 'background: lime; color: black', {
    updateCountryName,
    oldCountryName,
  });
  if (updateCountryName !== oldCountryName) {
    updateProvinceOptions(fieldArr, updateCountryName);
    return true;
  }
  return false;
};

export const updateProvinceOptions = (
  fieldArr: FormField[],
  countryCode: string,
): FormField[] => {
  const provinceIndex = _.findIndex(
    fieldArr,
    (obj: FormField) => obj.name === 'state',
  );
  const provincesObj = STATE_DATA[`${countryCode}`];
  if (provincesObj) {
    if (_.isNull(_.get(provincesObj, 'provinces', ''))) {
      fieldArr[provinceIndex].options = [{ name: 'NA' }];
    } else {
      // @ts-ignore
      fieldArr[provinceIndex].options = _.cloneDeep(provincesObj.provinces);
    }
  } else fieldArr[provinceIndex].options = [];

  return fieldArr;
};

export const updateActionButtonVisibility = (
  actionButtonObj: CombinedActionButtonInterface,
  currentAppRoute: RoutesInterface | null,
  selectedUser: OrganizationUserTypeInterface,
  selectedOrganization: OrganizationTypeInterface,
) => {
  if (!currentAppRoute) {
    return actionButtonObj;
  }

  const isOrgEditable = haveValidScope(EDIT_ORG_SCOPES);
  const areUsersEditable = haveValidScope(EDIT_USER_SCOPES);
  const canUsersBeActivated = haveValidScope(DEACTIVATE_USER_SCOPES);
  const canCreateUsers = haveValidScope(CREATE_USER_SCOPES);
  const canAddOrg = haveValidScope(CREATE_ORG_SCOPES);
  const isUserNotBlocked = isNull(_.get(selectedUser, 'blocked_at', ''));
  const isUserNotArchived = isNull(_.get(selectedUser, 'archived_at', ''));
  const isOrgActive = isNull(_.get(selectedOrganization, 'archived_at', ''));
  const canOrgBeActivated = haveValidScope(DEACTIVATE_ORG_SCOPES);
  const canAddEditSupervise = haveAllScopes(SCOPES.EDIT_ORG);

  console.log('%c actionButtonObj ^^^ ', 'background: black; color: yellow', {
    actionButtonObj,
    currentAppRoute,
  });

  if (currentAppRoute.path === EMS_ROUTES.EMS_ORG_LIST.path) {
    _.set(actionButtonObj, 'emsAddOrg.isVisible', canAddOrg);
    _.set(actionButtonObj, 'emsAddOrEditOrg.isVisible', false);
    _.set(actionButtonObj, 'activateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'emsAddUserToOrg.isVisible', false);
    _.set(actionButtonObj, 'emsUpdateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'emsActivateUser.isVisible', false);
    _.set(actionButtonObj, 'emsDeActivateUser.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }

  if (currentAppRoute.path === EMS_ROUTES.EMS_ORG_DETAILS.path) {
    _.set(actionButtonObj, 'emsAddOrg.isVisible', false);
    _.set(
      actionButtonObj,
      'emsAddOrEditOrg.isVisible',
      isNull(selectedOrganization) || (isOrgActive && isOrgEditable),
    );
    _.set(
      actionButtonObj,
      'activateEmsDept.isVisible',
      !isNull(selectedOrganization) && !isOrgActive && canOrgBeActivated,
    );
    _.set(
      actionButtonObj,
      'deActivateEmsDept.isVisible',
      !isNull(selectedOrganization) && isOrgActive && canOrgBeActivated,
    );
    _.set(actionButtonObj, 'emsAddUserToOrg.isVisible', false);
    _.set(actionButtonObj, 'emsUpdateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'emsActivateUser.isVisible', false);
    _.set(actionButtonObj, 'emsDeActivateUser.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }

  if (currentAppRoute.path === EMS_ROUTES.EMS_ORG_USERS.path) {
    _.set(actionButtonObj, 'emsAddOrg.isVisible', false);
    _.set(actionButtonObj, 'emsAddOrEditOrg.isVisible', false);
    _.set(actionButtonObj, 'activateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'emsAddUserToOrg.isVisible', canCreateUsers);
    _.set(actionButtonObj, 'emsUpdateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'emsActivateUser.isVisible', false);
    _.set(actionButtonObj, 'emsDeActivateUser.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }

  if (currentAppRoute.path === EMS_ROUTES.EMS_ORG_USERS_DETAILS.path) {
    _.set(actionButtonObj, 'emsAddOrg.isVisible', false);
    _.set(actionButtonObj, 'emsAddOrEditOrg.isVisible', false);
    _.set(actionButtonObj, 'activateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'emsAddUserToOrg.isVisible', false);
    _.set(
      actionButtonObj,
      'emsUpdateUserDetails.isVisible',
      isNull(selectedUser) ||
        (isUserNotBlocked && isUserNotArchived && areUsersEditable),
    );
    _.set(
      actionButtonObj,
      'emsActivateUser.isVisible',
      !isNull(selectedUser) && !isUserNotBlocked && canUsersBeActivated,
    );
    _.set(
      actionButtonObj,
      'emsDeActivateUser.isVisible',
      !isNull(selectedUser) && isUserNotBlocked && canUsersBeActivated,
    );
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }
  if (currentAppRoute.path === EMS_ROUTES.EMS_ORG_ASSOCIATION.path) {
    _.set(actionButtonObj, 'emsAddOrg.isVisible', false);
    _.set(actionButtonObj, 'emsAddOrEditOrg.isVisible', false);
    _.set(actionButtonObj, 'activateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'emsAddUserToOrg.isVisible', false);
    _.set(actionButtonObj, 'emsUpdateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'emsActivateUser.isVisible', false);
    _.set(actionButtonObj, 'emsDeActivateUser.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', canAddEditSupervise);
    //todo add scope cond here
  }

  if (currentAppRoute.path === EMS_ROUTES.EMS_ORG_QR_CODE.path) {
    _.set(actionButtonObj, 'emsAddOrg.isVisible', false);
    _.set(
      actionButtonObj,
      'emsAddOrEditOrg.isVisible',
      isNull(selectedOrganization) || (isOrgActive && isOrgEditable),
    );
    _.set(actionButtonObj, 'activateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateEmsDept.isVisible', false);
    _.set(actionButtonObj, 'emsAddUserToOrg.isVisible', false);
    _.set(actionButtonObj, 'emsUpdateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'emsActivateUser.isVisible', false);
    _.set(actionButtonObj, 'emsDeActivateUser.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }
  return actionButtonObj;
};
