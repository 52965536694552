import { Button } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from 'styles/colorConstants';

const LoginButton = withStyles((theme: Theme) => ({
  root: {
    color: COLORS.WHITE,
    backgroundColor: theme.palette.primary.main,
    minWidth: '120px',
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
    },
  },
}))(Button);

export default LoginButton;
