import { all, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import { userOrgModelFromJSON, userRolesModelFromJSON } from 'models/users';
import { showErrorMsg, showSuccessMsg } from 'utils/';

import * as UsersService from 'services/users.service';

import * as organizationService from 'services/organization.service';
import {
  UserDetailsAP,
  fetchUserOrgAP,
  OrgTypeInterface,
  ASROrgInterface,
  ASROrgRolesInterface,
} from 'utils/types';
import { usersActions } from './users.slice';
import {
  getBaseUrl,
  addOrEditOrgUserData,
  AddEditOrgUserActionPayload,
  UpdateActiveStatusPayload,
  ResetPasswordPayload,
} from 'store/organization';

function* getUsersData() {
  try {
    console.log('%c dada ', 'background: lime; color: black');
    // @ts-ignore
    const response = yield UsersService.getUsers();
    yield put(
      usersActions.fetchUserDataSuccess({
        data: response?.data,
        meta: response?.meta?.pagination,
      }),
    );
  } catch (err) {
    showErrorMsg(err);
    yield put(usersActions.fetchUserDataFailure());
  }
}

function* getUserDetailsData(action: PayloadAction<UserDetailsAP>) {
  try {
    const { userId } = action.payload;
    // @ts-ignore
    const response = yield UsersService.getParticularUsersDetails(userId);

    console.log('%c response ', 'background: black; color: yellow', {
      response,
    });

    yield put(
      usersActions.fetchUserDetailsSuccess({
        data: response.data,
      }),
    );
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', { err });
    showErrorMsg(err);
    yield put(usersActions.fetchUserDetailsFailure());
  }
}
// function* editUserRequest() {
//   try {
//   } catch (err) {
//     console.log('%c err ', 'background: salmon; color: black', { err });
//     showErrorMsg(err);
//     yield put(usersActions.fetchUserDetailsFailure());
//   }
// }

function* editUserRequest(action: PayloadAction<AddEditOrgUserActionPayload>) {
  try {
    const baseUrl: string = yield getBaseUrl();
    const { userId } = action.payload;

    // @ts-ignore
    const { response, msg } = yield addOrEditOrgUserData({
      payload: action.payload,
    });
    const updatedUserId = _.get(response.data, 'id', '');

    yield put(
      usersActions.addOrEditUserSuccess({
        updatedValue: response.data,
        actionType: userId,
      }),
    );

    if (updatedUserId) {
      yield put(push(`${baseUrl}/${updatedUserId}`));
    }
    showSuccessMsg(msg);
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', { err });
    showErrorMsg(err);
    yield put(usersActions.addOrEditUserFailure());
  }
}

function* getUserOrgData(action: PayloadAction<fetchUserOrgAP>) {
  try {
    const { orgTypes } = action.payload;
    if (!(orgTypes && orgTypes.length)) {
      return null;
    }

    console.log('%c orgTypes ', 'background: black; color: yellow', orgTypes);
    const apiReq = organizationService.getOrganizationList();

    const response = yield apiReq;
    const transformedData = userOrgModelFromJSON([response]);

    console.log(
      '%c transformedData -> org User Org Data ',
      'background: black; color: yellow',
      { transformedData },
    );

    yield put(
      usersActions.fetchUserOrgSuccess({
        data: transformedData,
      }),
    );
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', { err });
    showErrorMsg(err);
    yield put(usersActions.fetchUserOrgFailure());
  }
}
function* getOrgRolesData() {
  try {
    const response: ASROrgRolesInterface = yield organizationService.getAppRoles();

    const mappedData = userRolesModelFromJSON(response.data);
    yield put(usersActions.fetchAllRolesSuccess(response.data));
    yield put(
      usersActions.fetchUserRolesSuccess({
        data: mappedData,
      }),
    );
  } catch (err) {
    console.log('%c err getOrgRolesData', 'background: salmon; color: black', {
      err,
    });
    showErrorMsg(err);
    yield put(usersActions.fetchUserRolesFailure());
  }
}
function* resetActiveUser() {
  try {
    yield put(
      usersActions.updateActiveUser({
        data: null,
      }),
    );
  } catch (err) {
    console.log('%c err getOrgRolesData', 'background: salmon; color: black', {
      err,
    });
    showErrorMsg(err);
    yield put(usersActions.fetchUserRolesFailure());
  }
}
function* updateUserActiveStatus(
  action: PayloadAction<UpdateActiveStatusPayload>,
) {
  try {
    const { type, id, status } = action.payload;
    const response = yield organizationService.updateStatus({
      type,
      id,
      status,
    });
    yield put(usersActions.updateUserActiveStatusSuccess(response.data));
    showSuccessMsg(`User status updated successfully.`);
  } catch (err) {
    console.log('%c eerorradankk777 ', 'background: salmon; color: black', err);
    showErrorMsg(err);
    yield put(usersActions.updateUserActiveStatusFailure());
  }
}

function* resetPassword(action: PayloadAction<ResetPasswordPayload>) {
  try {
    const { token, password } = action.payload;
    yield UsersService.resetPassword({ token, password });
    yield put(usersActions.resetPasswordSuccess());
    showSuccessMsg('Password has been changed successfully.');
  } catch (e) {
    console.log('%c eerorradankk777 ', 'background: salmon; color: black', e);
    yield put(usersActions.resetPasswordFailure());
    showErrorMsg(e);
  }
}

export function* usersSaga(): Generator {
  yield all([
    takeLatest(usersActions.fetchUserDataRequest, getUsersData),
    takeLatest(usersActions.fetchUserDetailsDataRequest, getUserDetailsData),
    takeLatest(usersActions.fetchUserOrgRequest, getUserOrgData),
    takeLatest(usersActions.fetchUserRolesRequest.type, getOrgRolesData),
    takeLatest(usersActions.addOrEditUserRequest, editUserRequest),
    takeLatest(
      'organization/createNewOrganizationButtonClick',
      resetActiveUser,
    ),
    takeLatest(
      usersActions.updateUserActiveStatusRequest,
      updateUserActiveStatus,
    ),
    takeLatest(usersActions.resetPasswordRequest, resetPassword),
  ]);
}
