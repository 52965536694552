import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { webSocketActions } from './ws.slice';
import { showErrorMsg } from 'utils/helper';
import { SocketTokenPayload, getSocketToken } from 'services/websocket.service';
import { isAuth } from 'store/login';

function* fetchSocketUrl(action: any) {
  for (let i = 0; i < 5; i++) {
    try {
      yield call(isAuth);

      const response: SocketTokenPayload = yield getSocketToken();
      yield put(webSocketActions.setSocketUrl(response.data));

      return;
    } catch (err) {
      const meta = {
        path: '',
      };
      if (_.get(err, 'isError', true)) {
        meta.path = 'data.errorDescription';
      }

      console.log('err', err);

      yield delay(2000);
      // showErrorMsg(err, meta);
    }
  }
}

export function* websocketSaga(): Generator {
  yield all([takeLatest(webSocketActions.startConnecting, fetchSocketUrl)]);
}
