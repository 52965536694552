export enum AppCategoryEnum {
  ORGANIZATION = 'School',
  POLICE_DEPARTMENT = 'Police Department',
  EMS = 'EMS',
  SPECIAL_EVENT = 'Special Event',
}

export enum AppRolesEnum {
  org_manager = 'ORG_MANAGER',
  org_default = 'ORG_DEFAULT',
  police_manager = 'POLICE_MANAGER',
  police_default = 'POLICE_DEFAULT',
  police_commander = 'POLICE_COMMANDER',
  ems_manager = 'EMS_MANAGER',
  ems_default = 'EMS_DEFAULT',
  ems_commander = 'EMS_COMMANDER',
  cera_admin = 'CERA_ADMIN',
  police_officer_se = 'POLICE_OFFICER_SE',
  ems_se = 'EMS_SE',
  security_guard_se = 'SECURITY_GUARD_SE',
  police_commander_se = 'POLICE_COMMANDER_SE',
  ems_commander_se = 'EMS_COMMANDER_SE',
  se_manager = 'SE_MANAGER',
}

export enum AppPersonTypesEnum {
  officer = 'OFFICER',
  k9 = 'K9',
  swat = 'SWAT',
  commander = 'POLICE_COMMANDER',
  teacher = 'TEACHER',
  student = 'STUDENT',
  staff = 'STAFF',
  emt = 'EMT',
  ems_commander = 'EMS_COMMANDER',
  officerSe = 'OFFICER_SE',
  k9Se = 'K9_SE',
  swatSe = 'SWAT_SE',
  commanderSe = 'POLICE_COMMANDER_SE',
}

export interface ApiResponseInterface {
  data: any;
  headers?: any;
  isError: boolean;
  statusCode: number;
  statusName: string;
}

export interface ActionButtonInterface {
  id: string;
  routeSlug: string;
  isVisible: boolean;
  icon: NavIconInterface;
  type?: string;
}

export interface CombinedActionButtonInterface {
  [index: string]: ActionButtonInterface;
}

export type GeneralObject = {
  [key: string]: any;
};

export interface PaginationInterface {
  pages: number;
  next: number | null;
  previous: number | null;
  current: number | null;
  count: number;
}

export interface TableColumnInterface {
  name: string;
  key: string;
  label: string;
}

export interface NavIconInterface {
  src: any;
  name: string;
}

export interface NavButtonInterface {
  id: string;
  name: string;
  routeSlug: string | string[];
  routePost: string;
  icon: NavIconInterface;
}

export interface NestedNavButtonInterface {
  [key: string]: NavButtonInterface;
}

export interface PositionInterface {
  lat: number;
  lng: number;
}
export interface MarkerObj {
  position: PositionInterface;
  label?: string;
  icon: string;
  id?: string;
}

export const permissionTypes = [
  'CERA_ADMIN',
  'EMS_ADMIN',
  'ORGANIZATION_ADMIN',
  'POLICE_ADMIN',
  'POLICE_COMMANDER',
  'EMS_COMMANDER',
] as const;
export type Permission = typeof permissionTypes[number];

export enum AppCategory {
  ORGANIZATION = 'School',
  POLICE_DEPARTMENT = 'Police Department',
  EMS = 'Ems',
  SPECIAL_EVENT = 'Special Event',
}

export enum EventJoinRequestEnum {
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
}
