import { useEffect } from 'react';
import { isCreateAction } from 'utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { organizationActions } from 'store/organization';
import { usersActions } from 'store/users';
import { useRouteMatch } from 'react-router';
import {
  DEFAULT_LANDING_ROUTE,
  EMS_ROUTES,
  ORGANIZATION_ROUTES,
  POLICE_DEPT_ROUTES,
  SPECIAL_EVENT_ROUTES,
  USERS_ROUTES,
} from 'routes/path';
import { APP_CATEGORY } from 'utils/constants';
import { getActiveCategory } from 'store';

export const useFetchOrgDetails = (orgId: string): void => {
  const dispatch = useDispatch();
  const currentRoute = useRouteMatch();
  const activeCategory = useSelector(getActiveCategory);

  console.log(
    '%c currentRoute ',
    'background: lime; color: black',
    currentRoute,
  );
  const isUserRoute = currentRoute.path === USERS_ROUTES.USERS_DETAILS.path;
  const isMapRoute =
    currentRoute.path === ORGANIZATION_ROUTES.ORGANIZATION_MAP.path;

  useEffect(() => {
    if (!isCreateAction(orgId)) {
      if (isUserRoute) {
        dispatch(
          usersActions.fetchUserDetailsDataRequest({
            userId: orgId,
          }),
        );
      } else {
        dispatch(
          organizationActions.fetchParticularOrganizationDetailsRequest({
            orgId: orgId,
          }),
        );
      }
      if (isMapRoute && activeCategory) {
        dispatch(
          organizationActions.fetchOrganizationDataRequest({
            categoryType: activeCategory.name,
          }),
        );
      }
    }
  }, [orgId, activeCategory]);
};

export const getDefaultUrl = (categoryType: string, id: string): string => {
  let url = '';
  switch (categoryType) {
    case APP_CATEGORY.ORGANIZATION: {
      url = `${ORGANIZATION_ROUTES.ORGANIZATION.path}/${id}`;
      break;
    }
    case APP_CATEGORY.POLICE_DEPARTMENT: {
      url = `${POLICE_DEPT_ROUTES.POLICE_DEPT.path}/${id}`;
      break;
    }

    case APP_CATEGORY.EMS: {
      url = `${EMS_ROUTES.EMS_ORG_LIST.path}/${id}`;
      break;
    }

    case APP_CATEGORY.SPECIAL_EVENT: {
      url = `${SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_LIST.path}/${id}`;
      break;
    }

    default:
      url = DEFAULT_LANDING_ROUTE.path;
  }
  return url;
};
