import { Typography } from '@material-ui/core';
import React from 'react';
import { EventUser } from 'store/eventUser/eventUser.slice';
import { useMapContainerStyle } from './style';

export function InvolvedUser(props: { index: number; user: EventUser }) {
  const { user, index } = props;
  const userData = user.user;
  const badgeNum = userData.batchNumber;
  const phoneNum = userData.phone;
  const classes = useMapContainerStyle();

  return (
    <div className={`${classes.raisinBgColor} ${classes.involvedUserCtn}`}>
      <Typography
        className={classes.reportType}
      >{`${userData.firstName} ${userData.lastName} - ${userData.role.name}`}</Typography>
      <Typography className={classes.reportUser}>
        {userData.organization.name}
      </Typography>
      {badgeNum && (
        <Typography
          className={classes.reportUser}
        >{`Badge - ${badgeNum}`}</Typography>
      )}
      {phoneNum && (
        <Typography className={classes.reportUser}>{phoneNum}</Typography>
      )}
    </div>
  );
}
