import React from 'react';
import IncomingVideoCall from 'routes/VideoCall/incomingCallToast';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter, get, isNil } from 'lodash';
import { toast } from 'react-toastify';
import { videoCallStatus } from 'config/videoCall';

import AgoraRTC, {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteVideoTrack,
  UID,
} from 'agora-rtc-sdk-ng';

interface VideoCallState {
  // Indicates if a video call is going on. If true, data would be non-null.
  // If false, data would be null
  isVideoCallOngoing: boolean;
  data: VideoCallData | null;
  agora: AgoraData | null;

  // Stores all incoming video call through video_call_initiate pubsub message.
  // If one of the incoming calls is accepted, it is poped from here and added to
  // data
  incomingCalls: VideoCallData[];

  // Indicates the user has initiated a new call
  outgoingCall: VideoCallData | null;
}

export interface InitiateVideoCallData {
  receiverId: string;
  reportId: string;
}

export interface IAgoraRtc {
  localAudioTrack: IMicrophoneAudioTrack | null;
  localVideoTrack: ICameraVideoTrack | null;
  client: IAgoraRTCClient | null;
}

export interface IAgoraUser {
  uid: UID;
  audio: boolean;
  video: boolean;
  client: boolean;
  videoTrack: IRemoteVideoTrack | ICameraVideoTrack | undefined | null;
}

interface AgoraData {
  localRtc: IAgoraRtc;
  users: IAgoraUser[];
}

export interface VideoCallData {
  id: string;
  callerId: string;
  callerName: string;
  callUserId: number;
  channelId: string | null;
  endedAt: string | null;
  receiverId: string;
  receiverName: string;
  reportId: string;
  status: string;
  token: string | null;
}

const initialState: VideoCallState = {
  isVideoCallOngoing: false,
  incomingCalls: [],
  outgoingCall: null,
  data: null,
  agora: null,
};

export const videoCallSlice = createSlice({
  name: 'videoCall',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchActiveVideoCall: (state) => {},
    /* eslint-disable */
    startOutgoingCall: (
      state,
      action: PayloadAction<InitiateVideoCallData>,
    ) => {},
    unsetOutgoingCall: (state, action: PayloadAction<VideoCallData>) => {
      if (
        state.outgoingCall?.id === action.payload.id &&
        action.payload.status === videoCallStatus.reject
      ) {
        state.outgoingCall = null;
      }
    },
    /* eslint-enable */
    setOutgoingCall: (state, action: PayloadAction<VideoCallData | null>) => {
      state.outgoingCall = action.payload;
    },
    setIncomingVideoCall: (state, action: PayloadAction<VideoCallData>) => {
      state.incomingCalls.push({
        id: action.payload.id,
        callerId: action.payload.callerId,
        callerName: action.payload.callerName,
        callUserId: action.payload.callUserId,
        endedAt: action.payload.endedAt,
        receiverId: action.payload.receiverId,
        receiverName: action.payload.receiverName,
        reportId: action.payload.reportId,
        status: action.payload.status,
        channelId: null,
        token: null,
      });
    },
    addAgoraUser: (state, action: PayloadAction<IAgoraUser>) => {
      if (state.agora) {
        state.agora.users = [
          ...state.agora.users,
          {
            uid: action.payload.uid,
            audio: action.payload.audio,
            video: action.payload.video,
            client: false,
            videoTrack: action.payload.videoTrack,
          },
        ];
      }
    },
    updateAgoraUser: (state, action: PayloadAction<IAgoraUser>) => {
      if (state.agora) {
        const user = state.agora.users.find(
          (user) => user.uid === action.payload.uid,
        );

        if (user) {
          user.uid = action.payload.uid;
          user.audio = action.payload.audio;
          user.video = action.payload.video;
          user.client = false;
          user.videoTrack = action.payload.videoTrack;
        }
      }
    },
    deleteAgoraUser: (state, action: PayloadAction<string>) => {
      if (state.agora) {
        state.agora.users = state.agora.users.filter(
          (user) => user.uid === action.payload,
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    pingVideoCall: (state, action: PayloadAction<string>) => {},
    setOngoingVideoCall: (state, action: PayloadAction<VideoCallData>) => {
      if (isNil(action.payload.endedAt)) {
        state.incomingCalls = filter(
          state.incomingCalls,
          (call) => call.id != get(action, 'payload.id'),
        );

        state.outgoingCall = null;
        state.isVideoCallOngoing = true;
        state.data = {
          id: action.payload.id,
          callerId: action.payload.callerId,
          callerName: action.payload.callerName,
          callUserId: action.payload.callUserId,
          endedAt: action.payload.endedAt,
          receiverId: action.payload.receiverId,
          receiverName: action.payload.receiverName,
          reportId: action.payload.reportId,
          status: action.payload.status,
          channelId: action.payload.channelId,
          token: action.payload.token,
        };
      } else {
        state.isVideoCallOngoing = false;
        state.data = null;

        if (state.outgoingCall?.id === action.payload.id) {
          state.outgoingCall = null;
        }
      }
    },
    setEndVideoCall: (state) => {
      state.isVideoCallOngoing = false;
      state.data = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    acceptVideoCall: (state, action: PayloadAction<string>) => {},
    rejectVideoCall: (state, action: PayloadAction<string>) => {
      state.incomingCalls = filter(
        state.incomingCalls,
        (call) => call.id != get(action, 'payload'),
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    endVideoCall: (state) => {},
  },
});

export const videoCallActions = videoCallSlice.actions;

export default videoCallSlice.reducer;
