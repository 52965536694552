import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from 'styles/colorConstants';

const CONTAINER_MAX_WIDTH = '300px';

export const useLoginStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      backgroundColor: COLORS.THEME_COLOR,
      minHeight: '100vh',
    },
    formContainer: {
      maxWidth: CONTAINER_MAX_WIDTH,
      margin: 'auto',
    },
    logoContainer: {
      maxWidth: CONTAINER_MAX_WIDTH,
      marginTop: theme.spacing(10),
    },
    fieldContainer: {
      margin: `${theme.spacing(1)}px 0`,
      width: '100%',
    },
    titleText: {
      color: COLORS.WHITE,
      marginBottom: theme.spacing(2),
      '& p': {
        fontSize: '1.5rem',
        marginBottom: theme.spacing(1),
      },
    },
  });
});
