import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import appLogo from '../../assets/primary-logo-white.png';
import opsCenterLogo from '../../assets/customized/opscenter-logo.svg';
import {
  getAuthenticatedUserOrgName,
  getCurrentUser,
  getDefaultUrl,
  getUserForms,
  haveValidScope,
  isOpsCenter,
  useModal,
} from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCategory,
  getAuthenticatedUserOrgId,
  getAuthenticatedUserOrgType,
  getOrganizationFormValue,
  getPoliceDeptFormValue,
  getSelectedOrganization,
  isOrganizationDeptFormTouched,
  isPoliceDeptFormTouched,
} from 'store';

import { destroy, initialize } from 'redux-form';
import {
  APP_CATEGORY,
  CERA_ADMIN_SCOPES,
  compareFormObjects,
  ORG_ADMIN_SCOPES,
  ORG_FORM_KEYS,
  POLICE_COMMANDER_UNIQUE_SCOPES,
  REDUX_FORM_CONSTANT,
  USER_FORM_KEYS,
} from 'utils';
import { FormSaveConfirmationModal } from 'components/Form/FormModal';
const useLogoStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      // width: '25%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      cursor: 'pointer',
      flexDirection: 'row',
    },
    logo: {
      width: ' 70px',
    },
    opsCenterLogo: {
      marginLeft: '4px',
    },
  }),
);

const LogoSection: React.FC = () => {
  const classes = useLogoStyles();
  const historyObj = useHistory();
  const allCategories = useSelector(getAllCategory);
  const orgId = useSelector(getAuthenticatedUserOrgId);
  const orgType = useSelector(getAuthenticatedUserOrgType);
  const getOrgForm = useSelector(getOrganizationFormValue);
  const getPoliceForm = useSelector(getPoliceDeptFormValue);
  const dispatch = useDispatch();
  const currentOrg = useSelector(getSelectedOrganization);
  const [link, setLink] = useState<any>('');
  const [open, openModal, closeModal] = useModal();
  const currentUser = getCurrentUser();
  const [userForm, isUserTouched] = getUserForms();
  const isPoliceFormTouched = useSelector(isPoliceDeptFormTouched);
  const isOrgFormTouched = useSelector(isOrganizationDeptFormTouched);

  const handleStay = () => {
    closeModal();
  };

  const destroyForms = (url: any) => {
    dispatch(destroy(REDUX_FORM_CONSTANT.ORGANIZATION));
    dispatch(destroy(REDUX_FORM_CONSTANT.POLICE_DEPT));
    resetForms(url);
    // historyObj.push(url);
  };

  const resetForms = (url: string) => {
    const orgName = getAuthenticatedUserOrgName(orgType, allCategories);
    if (
      haveValidScope(ORG_ADMIN_SCOPES) &&
      orgName === APP_CATEGORY.ORGANIZATION
    )
      dispatch(initialize(REDUX_FORM_CONSTANT.ORGANIZATION, currentOrg));
    else if (
      haveValidScope(ORG_ADMIN_SCOPES) &&
      (orgName === APP_CATEGORY.EMS ||
        orgName === APP_CATEGORY.POLICE_DEPARTMENT ||
        orgName === APP_CATEGORY.SPECIAL_EVENT)
    )
      dispatch(initialize(REDUX_FORM_CONSTANT.POLICE_DEPT, currentOrg));
    historyObj.push(url);
  };

  const handleLeave = () => {
    closeModal();
    destroyForms(link);
  };

  const handleNavLink = () => {
    const orgName = getAuthenticatedUserOrgName(orgType, allCategories);
    const opsCenter = isOpsCenter();

    const url = getDefaultUrl(orgName ? orgName : '', orgId);
    setLink(url);
    if (!opsCenter)
      if (
        (isPoliceFormTouched &&
          compareFormObjects(currentOrg, getPoliceForm, ORG_FORM_KEYS)) ||
        (isOrgFormTouched &&
          compareFormObjects(currentOrg, getOrgForm, ORG_FORM_KEYS)) ||
        (isUserTouched &&
          compareFormObjects(currentUser, userForm, USER_FORM_KEYS))
      ) {
        openModal();
      } else if (haveValidScope(CERA_ADMIN_SCOPES)) resetForms(url);
      else destroyForms(url);
  };

  return (
    <>
      <div
        className={classes.logoContainer}
        aria-hidden="true"
        onClick={handleNavLink}
      >
        <img src={appLogo} alt="Cera Logo" className={classes.logo} />
        {haveValidScope(POLICE_COMMANDER_UNIQUE_SCOPES) && (
          <img
            src={opsCenterLogo}
            alt="Opscenter Logo"
            className={classes.opsCenterLogo}
          />
        )}
      </div>
      <FormSaveConfirmationModal
        handleLeave={handleLeave}
        handleStay={handleStay}
        open={open}
      />
    </>
  );
};

export default LogoSection;
