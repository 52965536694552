import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GoogleMap, Polygon, Marker } from '@react-google-maps/api';
import _, { isNull } from 'lodash';
import Flex from 'components/General/Flex';
import { Controllers } from 'routes/Organization/Map/Controllers';
import { useDefaultGeoCodes } from 'routes/Organization/Map/hook';
import { RenderMarker } from 'routes/Organization/Map/Marker';
import { DeleteButton } from 'routes/Organization/Map/DeleteButton';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PerimeterPointIcon from 'assets/customized/perimeter-point.svg';
import CPIcon from 'assets/customized/command-post.svg';
import GreyPerimeterPointIcon from 'assets/customized/grey-pp.svg';
import GreyCPIcon from 'assets/customized/command_post_grey.svg';
import EMSCPIcon from 'assets/customized/house-medical.svg';
import { gnAlphabeticalSeq } from 'utils/helper';

import { MarkerObj, PositionInterface } from 'utils/commonTypes';
import { useMapContainerStyle } from './style';

import {
  getGeoFencePoints,
  getPerimeterPoints,
  getCommandPostPoints,
  organizationActions,
  getSelectedOrganization,
  getCurrentOrgCoordinates,
  getOrganizationFormValue,
  getEMSCommandPostPoints,
  getOrganizationData,
  getOrgPerimeterPoints,
} from 'store/organization';
import { CERA_ADMIN_SCOPES } from 'utils';
import { getFullAddress } from '../utils/helper';
import { computeCoordinatesUsingSphericalCo } from '../../../utils/constants/geoJson';
import { COLORS } from 'styles/colorConstants';
import { markerObjPolySort } from 'utils/location';
import { haveValidScope } from 'utils/hooks';
import { useLocation, useRouteMatch } from 'react-router';

const CONTAINER_MAX_WIDTH = '300px';

const COORDINATES = {
  p1: {
    lat: 0.0000268716592909527,
    lng: 0.031585693359375,
  },
  p2: {
    lat: 0.0029268716592909527,
    lng: 0.020965087890625,
  },
};

// [
//   { lat: 52.52549080781086, lng: 13.398118538856465 },
//   { lat: 52.48578559055679, lng: 13.36653284549709 },
//   { lat: 52.48871246221608, lng: 13.44618372440334 },
// ]

export function MapContainer() {
  const dispatch = useDispatch();
  const classes = useMapContainerStyle();
  const currentOrgObj = useSelector(getSelectedOrganization);

  const [isOverlayVisible, setOverlayVisibility] = useState(false);
  const [selectedVertex, setVertex] = useState(null);
  const [defaultGeoCodes] = useDefaultGeoCodes();
  const isAdmin = haveValidScope(CERA_ADMIN_SCOPES);
  const orgFormValue = useSelector(getOrganizationFormValue);
  const updatedAddress = getFullAddress(orgFormValue);
  const ogAddress = getFullAddress(currentOrgObj);
  const [ppDelBtn, setPPDelBtn] = useState(false);
  const [showCpDelBtn, setCpDelBtn] = useState(false);
  const [showEmsCpDelBtn, setEmsCpDelBtn] = useState(false);
  const [geoFenceDelBtn, setGFDelBtn] = useState(false);
  const [
    gfDelMarkerPosition,
    setGfDelMarkerPosition,
  ] = useState<PositionInterface | null>(null);

  const currentRoute = useRouteMatch();
  // @ts-ignore
  const { id } = currentRoute.params;
  const currentOrgCords = useSelector(getCurrentOrgCoordinates);

  // Define refs for Polygon instance and listeners
  const googleMapRef: any = useRef(null);
  const polygonRef: any = useRef(null);
  const listenersRef = useRef([]);

  const gfPoints = useSelector(getGeoFencePoints);
  const ppPoints = useSelector(getPerimeterPoints);
  const cpPoints = useSelector(getCommandPostPoints);
  const emsCpPoints = useSelector(getEMSCommandPostPoints);
  const orgPPs = useSelector(getOrgPerimeterPoints);
  const orgList = useSelector(getOrganizationData);

  const getMapCenterPoints = () => {
    // @ts-ignore
    if (!(defaultGeoCodes.lat === 0 || defaultGeoCodes.lng === 0)) {
      return defaultGeoCodes;
    }

    const pos = googleMapRef?.current?.getCenter();

    if (pos) {
      return { lat: pos.lat(), lng: pos.lng() };
    }

    return { lat: 0, lng: 0 };
  };
  // // Store Polygon path in state
  const [path, setPath] = useState(gfPoints);

  useEffect(() => {
    if (updatedAddress && !_.isEqual(updatedAddress, ogAddress)) {
      dispatch(
        organizationActions.getCurrentOrgCoordinatesRequest(orgFormValue),
      );
    } else if (currentOrgObj) {
      dispatch(
        organizationActions.getCurrentOrgCoordinatesRequest(currentOrgObj),
      );
    }
  }, [updatedAddress, currentOrgObj]);

  useEffect(() => {
    setPath(gfPoints);
  }, [gfPoints]);

  // Call setPath with new edited path
  const onEdit = useCallback(
    (args: any) => {
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng: any) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
          });

        console.log('%c nextPath path', 'background: black; color: yellow', {
          nextPath,
        });
        setPath(nextPath);
        handleGeoFenceUpdate(nextPath);
      }
    },
    [gfPoints],
  );

  // Call setPath with new edited path
  const onRemove = useCallback(
    (args: any) => {
      console.log('%c args remove', 'background: lime; color: black', args);
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng: any) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
          });
        // dispatch(organizationActions.updateGeoFence(nextPath));
        setPath(nextPath);
        console.log(
          '%c on remove -> nextPath ',
          'background: lime; color: black',
          nextPath,
        );
        handleGeoFenceUpdate(nextPath);
      }
    },
    [gfPoints],
  );

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;

      polygonRef.current.addListener('contextmenu', (event: any) => {
        if (event.vertex === undefined || event.vertex === null) {
          return;
        }
        setPPDelBtn(false);
        setCpDelBtn(false);
        setOverlayVisibility(true);
        setGFDelBtn(false);
        setVertex(event.vertex);
      });

      const path = polygon.getPath();
      listenersRef.current.push(
        // @ts-ignore
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onRemove),
      );
    },
    [onEdit],
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
    polygonRef.current = null;
  }, []);

  const handleMapLoad = (googleMapInstance: any) => {
    googleMapRef.current = googleMapInstance;
  };

  const getPosition = () => {
    if (selectedVertex === null || selectedVertex === undefined) {
      return { lat: 0, lng: 0 };
    }
    const paths = polygonRef.current?.getPath();
    // @ts-ignore
    const position = paths.getAt(selectedVertex);

    if (position) {
      return { lat: position.lat(), lng: position.lng() };
    }
  };

  const handleRemoveVertex = () => {
    if (selectedVertex === null || selectedVertex === undefined) {
      return { lat: 0, lng: 0 };
    }
    const paths = polygonRef.current?.getPath();
    paths?.removeAt(selectedVertex);
    const nextPath = paths.getArray().map((latLng: any) => {
      return { lat: latLng.lat(), lng: latLng.lng() };
    });

    setOverlayVisibility(false);
    setVertex(null);

    setPath(nextPath);
    handleGeoFenceUpdate(nextPath);
  };

  const deleteAll = () => {
    dispatch(
      organizationActions.updateGeoJSONData({
        geoFence: [],
        ppPoints: [],
        cpPoints: [],
        orgPps: [],
      }),
    );
    setOverlayVisibility(false);
    setGFDelBtn(false);
  };

  const handleGeoFenceUpdate = (nextPath: any) => {
    const updatedValue = nextPath || path;
    dispatch(organizationActions.updateGeoFence({ points: updatedValue }));
  };

  const addGeoFence = () => {
    if (gfPoints.length) {
      toast.error('Only one Geo Fence can be added');
      return;
    }

    const basePolygon = computeCoordinatesUsingSphericalCo(currentOrgCords);
    // @ts-ignore
    // const { lat, lng } = getMapCenterPoints();

    // const p1 = {
    //   lat: lat - COORDINATES.p1.lng,
    //   lng: lng + COORDINATES.p1.lng,
    // };
    // const p2 = {
    //   lat: lat,
    //   lng: lng,
    // };
    // const p3 = {
    //   lat: lat + COORDINATES.p2.lng,
    //   lng: lng + COORDINATES.p2.lng,
    // };

    // const basePolygon = [p1, p2, p3];
    setPath(basePolygon);
    dispatch(organizationActions.updateGeoFence({ points: basePolygon }));
  };

  const handlePerimeterUpdate = (
    newPosition: PositionInterface,
    index?: string | number,
  ) => {
    if (index) {
      const arr = ppPoints.slice();
      const newPoints = {
        ...arr[index],
        position: newPosition,
      };
      dispatch(
        organizationActions.updatePerimeterPoints({
          point: newPoints,
          index,
          type: 'update',
        }),
      );
    }
  };
  const handlePerimeterDelete = (index: number) => {
    dispatch(
      organizationActions.updatePerimeterPoints({
        index,
        type: 'delete',
      }),
    );
  };

  const addPerimeterPoint = () => {
    const centerPoints = getMapCenterPoints();
    const newPPPoints = {
      position: _.cloneDeep(centerPoints),
      icon: PerimeterPointIcon,
      label: gnAlphabeticalSeq(orgPPs.length),
    };
    // @ts-ignore
    dispatch(organizationActions.addPerimeterPoints({ points: newPPPoints }));
  };

  const handleCPUpdate = (newPosition: PositionInterface, index?: number) => {
    if (_.isNumber(index)) {
      const arr = cpPoints.slice();
      const newPoints = {
        ...arr[index],
        position: newPosition,
      };
      dispatch(
        organizationActions.updateCommandPost({
          point: newPoints,
          index,
          type: 'update',
        }),
      );
    }
  };

  const handleCPDelete = (index: number) => {
    if (_.isNumber(index)) {
      dispatch(
        organizationActions.updateCommandPost({
          index,
          type: 'delete',
        }),
      );
    }
  };

  const addCP = () => {
    if (cpPoints.length) {
      toast.error('Command Post can only be one');
      return;
    }
    const centerPoints = getMapCenterPoints();
    const newCPPoints = {
      position: _.cloneDeep(centerPoints),
      label: '',
      icon: CPIcon,
    };

    // @ts-ignore
    dispatch(organizationActions.addCommandPost({ points: newCPPoints }));
  };

  const addEmsCp = () => {
    if (emsCpPoints?.length) {
      toast.error('EMS Command Post can only be one');
      return;
    }
    const centerPoints = getMapCenterPoints();
    const newEMSCPPoints = {
      position: _.cloneDeep(centerPoints),
      label: '',
      icon: EMSCPIcon,
    };

    // @ts-ignore
    dispatch(organizationActions.addEmsCP({ points: newEMSCPPoints }));
  };

  const updateEmsCP = (newPosition: PositionInterface, index?: number) => {
    if (_.isNumber(index)) {
      const arr = emsCpPoints.slice();
      const newPoints = {
        ...arr[index],
        position: newPosition,
      };
      dispatch(
        organizationActions.updateEmsCP({
          point: newPoints,
          index,
          type: 'update',
        }),
      );
    }
  };

  const deleteEmsCp = (index: number) => {
    if (_.isNumber(index)) {
      dispatch(
        organizationActions.updateEmsCP({
          index,
          type: 'delete',
        }),
      );
    }
  };

  const getAllMarkers = () => {
    const tempArray = [];
    tempArray.push(currentOrgCords);
    ppPoints
      .map((obj: MarkerObj) => obj.position)
      .map((obj: any) => {
        tempArray.push(obj);
      });
    cpPoints
      .map((obj: MarkerObj) => obj.position)
      .map((obj: any) => {
        tempArray.push(obj);
      });
    gfPoints.map((obj: any) => {
      tempArray.push(obj);
    });
    return tempArray;
  };

  const setBounds = () => {
    const markers = getAllMarkers();
    // @ts-ignore
    const bounds = new window.google.maps.LatLngBounds();
    markers.map((item, key) => {
      bounds.extend(item);
      return key;
    });
    googleMapRef.current.fitBounds(bounds);
    // googleMapRef.current.setOptions({ minZoom: 5 });
    if (googleMapRef.current.getZoom() > 18) {
      googleMapRef.current.setZoom(googleMapRef.current.getZoom() - 1);
    } else if (googleMapRef.current.getZoom() < 5) {
      googleMapRef.current.setZoom(googleMapRef.current.getZoom() + 1);
    } else googleMapRef.current.setZoom(googleMapRef.current.getZoom());
    const center = bounds.getCenter();
    googleMapRef.current.setCenter(center);
  };
  useEffect(() => {
    if (!isNull(currentOrgCords) && googleMapRef.current) {
      setBounds();
    }
  }, [currentOrgCords, googleMapRef.current]);

  const deleteGf = () => {
    dispatch(organizationActions.updateGeoFence({ points: [] }));
    setGFDelBtn(false);
  };

  const polygonRightClick = (event: any) => {
    const clickedCoord = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    const isVertexSelected = _.isNumber(event?.vertex);

    if (isVertexSelected) {
      setOverlayVisibility(true);
      setGFDelBtn(false);
    } else {
      setGFDelBtn(true);
      setGfDelMarkerPosition(clickedCoord);
      setOverlayVisibility(false);
    }
    setPPDelBtn(false);
    setCpDelBtn(false);
  };

  console.log('%c path 0000', 'background: salmon; color: black', {
    path,
    currentOrgCords,
  });
  const pp: MarkerObj[] = [];
  orgList.data
    .filter((org) => org.id !== id)
    .map((org) => org.orgPps)
    .map((perimeterPoints) => {
      perimeterPoints.map((points) => {
        return pp.push(points);
      });
    });

  return (
    <div className={classes.app}>
      <Flex className={classes.container}>
        <div className={classes.mapContainer}>
          <GoogleMap
            mapContainerClassName={classes['app-map']}
            // center={getMapCenterCoordinates()}
            // zoom={12}
            onClick={() => {
              setOverlayVisibility(false);
              setPPDelBtn(false);
              setCpDelBtn(false);
              setGFDelBtn(false);
            }}
            onLoad={handleMapLoad}
          >
            {currentOrgCords && <Marker position={currentOrgCords} />}
            {path && path.length && (
              <Polygon
                // Make the Polygon editable / draggable
                editable={isAdmin}
                draggable={isAdmin}
                path={path}
                // Event used when manipulating and adding points
                onMouseUp={onEdit}
                // Event used when dragging the whole Polygon
                onDragEnd={onEdit}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onRightClick={polygonRightClick}
              />
            )}
            {orgList.data
              .filter((org) => org.id !== id)
              .map((org) => org.geoFence)
              .map((geofence, index) => {
                return (
                  <Polygon
                    key={index}
                    editable={false}
                    draggable={false}
                    path={geofence.coordinates}
                    options={{
                      strokeColor: COLORS.DISABLE_COLOR,
                      fillOpacity: 0,
                    }}
                  />
                );
              })}

            {pp.map((points, index) => {
              return (
                <Marker
                  key={index}
                  draggable={false}
                  position={points.position}
                  icon={GreyPerimeterPointIcon}
                  label={points.label}
                />
              );
            })}
            {orgList.data
              .filter((org) => org.id !== id)
              .map((org) => org.commandPost)
              .map((commandPost, index) => {
                return (
                  <Marker
                    key={index}
                    draggable={false}
                    position={commandPost.coordinates[0].position}
                    icon={GreyCPIcon}
                  />
                );
              })}

            {orgPPs.length && (
              <RenderMarker
                points={orgPPs}
                onEdit={handlePerimeterUpdate}
                onDelete={handlePerimeterDelete}
                setCpDelBtn={setCpDelBtn}
                setPPDelBtn={setPPDelBtn}
                setEmsCpDelBtn={setEmsCpDelBtn}
                setOverlayVisibility={(val: boolean) => {
                  setGFDelBtn(val);
                  setOverlayVisibility(val);
                }}
                ppDelBtn={ppDelBtn}
                cpDelBtn={showCpDelBtn}
                emsCpDelBtn={showEmsCpDelBtn}
                type="pp"
                onClickDeleteAll={deleteAll}
              />
            )}
            {orgPPs.length && (
              <Polygon
                options={{
                  strokeColor: COLORS.PP_COLOR,
                  fillOpacity: 0,
                }}
                path={markerObjPolySort(orgPPs).map(
                  (ppObj: MarkerObj) => ppObj.position,
                )}
                draggable={false}
                editable={false}
              />
            )}
            {cpPoints.length && (
              <RenderMarker
                points={cpPoints}
                onEdit={handleCPUpdate}
                onDelete={handleCPDelete}
                setCpDelBtn={setCpDelBtn}
                setPPDelBtn={setPPDelBtn}
                setEmsCpDelBtn={setEmsCpDelBtn}
                setOverlayVisibility={(val: boolean) => {
                  setGFDelBtn(val);
                  setOverlayVisibility(val);
                }}
                ppDelBtn={ppDelBtn}
                cpDelBtn={showCpDelBtn}
                emsCpDelBtn={showEmsCpDelBtn}
                type="cp"
                onClickDeleteAll={deleteAll}
              />
            )}

            {emsCpPoints?.length && (
              <RenderMarker
                points={emsCpPoints}
                onEdit={updateEmsCP}
                onDelete={deleteEmsCp}
                setCpDelBtn={setCpDelBtn}
                setPPDelBtn={setPPDelBtn}
                setEmsCpDelBtn={setEmsCpDelBtn}
                setOverlayVisibility={(val: boolean) => {
                  setGFDelBtn(val);
                  setOverlayVisibility(val);
                }}
                ppDelBtn={ppDelBtn}
                cpDelBtn={showCpDelBtn}
                emsCpDelBtn={showEmsCpDelBtn}
                type="emsCp"
                onClickDeleteAll={deleteAll}
              />
            )}

            {(isOverlayVisible || geoFenceDelBtn) && (
              <DeleteButton
                position={
                  isOverlayVisible ? getPosition() : gfDelMarkerPosition
                }
                onClick={isOverlayVisible ? handleRemoveVertex : deleteGf}
                onClickDeleteAll={deleteAll}
              />
            )}
          </GoogleMap>
        </div>
        <Controllers
          ppBtnClick={addPerimeterPoint}
          cpBtnClick={addCP}
          emsCpBtnClick={addEmsCp}
          gfBtnClick={addGeoFence}
        />
      </Flex>
    </div>
  );
}

export default MapContainer;
