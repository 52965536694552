import {
  ORGANIZATION_ROUTES,
  POLICE_DEPT_ROUTES,
  EMS_ROUTES,
  USERS_ROUTES,
  SPECIAL_EVENT_ROUTES,
} from 'routes/path';
import _, { isNull } from 'lodash';
import { RoutesInterface, AppRouteInterface } from 'routes/path';
import { useRouteMatch, matchPath } from 'react-router';
import { toast } from 'react-toastify';
import { ERROR_MSG, FILTER_TYPES, REPORT_TYPE } from './constants/';
import { EventJoinResponseInterface, Profile, ReportInterface } from './types';

export const showSuccessMsg = (msg: string) => {
  toast.success(msg);
};

export const showErrorMsg = (
  err: any,
  metaData: any = { path: 'data.errorMessage' },
): void => {
  try {
    let errorMsg = '';
    if (err && err.data && _.isObject(err.data)) {
      errorMsg =
        metaData && metaData.path
          ? _.get(err, metaData.path, ERROR_MSG.DEFAULT)
          : ERROR_MSG.DEFAULT;
    } else if (_.isError(err)) {
      errorMsg = err.message;
    } else if (_.isString(err)) {
      errorMsg = err;
    } else if (err?.statusName) {
      errorMsg = err?.statusName;
    } else {
      errorMsg = JSON.stringify(err);
    }

    toast.error(errorMsg);
  } catch (e) {
    console.log(
      '%c catch block of showErrorMsg ',
      'background: salmon; color: black',
      e,
    );
    toast.error(ERROR_MSG.DEFAULT);
  }
};

export const getErrorStatusCode = (
  err: any,
  metaData: any = { path: 'data.errorMessage' },
): string | null => {
  if (err && err.data && _.isObject(err.data)) {
    return metaData && metaData.path
      ? _.get(err, metaData.path, ERROR_MSG.DEFAULT)
      : ERROR_MSG.DEFAULT;
  } else {
    return null;
  }
};

export const changeToLowerCase = (value: string) =>
  value && value.toLowerCase();

export const isNilOrEmpty = (value: any) =>
  _.isNil(value) || _.isEmpty(value) || _.isNull(value) || _.isNaN(value);

export const isRequired = (value: string): undefined | string =>
  value || typeof value === 'number' ? undefined : 'Required';

export const isValidIdentifier = (value: string): undefined | string =>
  value && value.length === 2 && /^[A-Za-z]+$/.test(value)
    ? undefined
    : 'Must be a 2 alpha characters';

export const isValidBadgeId = (value: number): undefined | string =>
  value && value > 0 && value < 1000000
    ? undefined
    : 'Must be a 0-6 digits number';

export const checkMinLength = (min: number) => (value: string) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const isEmailValid = (value: string): undefined | string =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const isValidPhoneNumber = (number: string): string | undefined =>
  number && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i.test(number)
    ? 'Invalid Phone Number'
    : !number
    ? 'Required'
    : undefined;

export const isValidPostalCode = (value: string) =>
  value && (value.length < 5 || value.length > 10)
    ? 'Invalid Postal code'
    : !value
    ? 'Required'
    : undefined;

export const isCurrentRoute = (linkObj: any) => {
  const match = useRouteMatch(linkObj.path);
  return _.get(match, 'isExact', false);
};

export const formatVal = (name: string) => (val: string): string => {
  return val !== undefined ? val.toString().replace(' ', '') : '';
};

export const isCreateAction = (val: string): boolean => {
  if (val === 'new') {
    return true;
  }
  return false;
};

export const isUpdateAction = (val: string): boolean => {
  if (val === 'update') {
    return true;
  }
  return false;
};

export const findAndUpdate = <T extends { id: string }>(
  data: T[],
  element: T,
): T[] => {
  const objIndex = data.findIndex((obj) => {
    return obj.id === _.get(element, 'id', '');
  });

  if (objIndex !== -1) {
    data[objIndex] = element;
  }
  return data;
};

export const isRouteMatch = (
  appRouteObj: RoutesInterface,
  currentURL: string,
): boolean => {
  const res = matchPath(currentURL, appRouteObj.path);
  return _.get(res, 'isExact', false) === true;
};

export const generateBreadCrumb = (currentUrl: string, history: any) => {
  const breadCrumbData = currentUrl.split('/').filter((path: string) => path);
  let routeToNavigate = '';

  return breadCrumbData.map((path: string) => {
    const label = path;
    routeToNavigate = `${routeToNavigate}/${path}`;
    return {
      label,
      value: routeToNavigate,
      onClick: (value: string) => history.push(value),
    };
  });
};

export const findActiveRoute = (
  appRoutes: AppRouteInterface,
  currentUrl: string,
): RoutesInterface | null => {
  if (isNilOrEmpty(appRoutes)) {
    return null;
  }
  const screenKeys = Object.keys(appRoutes);
  const foundScreenKey = _.find(screenKeys, (screenKey) => {
    const obj = appRoutes[screenKey];

    return isRouteMatch(obj, currentUrl);
  });

  if (foundScreenKey) {
    return appRoutes[foundScreenKey];
  }
  return null;
};

export const getBasePath = (pathName: string): string => {
  if (pathName && pathName.includes('/')) {
    const splitPath = pathName.split('/');
    // console.log('%c splitPath ', 'background: lime; color: black', {
    //   splitPath,
    // });
    return `/${splitPath[1]}`;
  }
  return pathName;
};

export const isOrgRoute = (pathName: string): boolean => {
  return _.startsWith(
    ORGANIZATION_ROUTES.ORGANIZATION.path,
    getBasePath(pathName),
  );
};
export const isPoliceDeptRoute = (pathName: string): boolean => {
  return _.startsWith(
    POLICE_DEPT_ROUTES.POLICE_DEPT.path,
    getBasePath(pathName),
  );
};
export const isEmsRoute = (pathName: string): boolean => {
  return _.startsWith(EMS_ROUTES.EMS_ORG_LIST.path, getBasePath(pathName));
};

export const isSpecialEventRoute = (pathName: string): boolean => {
  return _.startsWith(
    SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_LIST.path,
    getBasePath(pathName),
  );
};

export const isUserRoute = (pathName: string): boolean => {
  return _.startsWith(USERS_ROUTES.USERS.path, getBasePath(pathName));
};

export const gnAlphabeticalSeq = (seqNo: number) => {
  return String.fromCharCode('A'.charCodeAt(0) + (seqNo % 26));
};

export const reverseCoordinates = (coordinateObj: any) => {
  return {
    lat: coordinateObj.lng,
    lng: coordinateObj.lat,
  };
};

export const compareFormObjects = (first: any, second: any, keys: any) => {
  return !_.isEmpty(second) && !_.isMatch(first, _.pick(second, keys));
};

const checkIdAndGetData = (payload: any, reportList: any) => {
  const doesPayloadIdExist = reportList.filter(
    (obj: any) => obj.id === payload.id,
  ).length;
  if (doesPayloadIdExist) {
    return reportList.map((reportObj: any) => {
      return reportObj.id === payload.id ? payload : reportObj;
    });
  } else {
    return [...reportList, payload];
  }
};

const getFilteredList = (list: Profile[] | null, selectedFilter: string) => {
  if (selectedFilter === FILTER_TYPES.RESOLVED) {
    return list?.filter((ele) => !_.isNull(ele.resolved_at));
  } else if (selectedFilter === FILTER_TYPES.ACTIVE)
    return list?.filter((ele) => isNull(ele.resolved_at));
  else return list;
};

export const getNewProfileList = (
  payload: any,
  injuredReports: Profile[] | null,
  suspectReports: Profile[] | null,
  selectedFilter: string,
): any => {
  const reportType = _.get(payload, 'report_type.logical_name', '');

  switch (reportType) {
    case REPORT_TYPE.SUSPECT:
      return {
        injuredReports: getFilteredList(injuredReports, selectedFilter),
        suspectReports: suspectReports
          ? getFilteredList(
              checkIdAndGetData(payload, suspectReports),
              selectedFilter,
            )
          : getFilteredList([payload], selectedFilter),
      };
    case REPORT_TYPE.INJURED:
      return {
        suspectReports: getFilteredList(suspectReports, selectedFilter),
        injuredReports: injuredReports
          ? getFilteredList(
              checkIdAndGetData(payload, injuredReports),
              selectedFilter,
            )
          : getFilteredList([payload], selectedFilter),
      };

    default:
      return {
        suspectReports: [],
        injuredReports: [],
      };
  }
};

export const getNewReportList = (
  payload: any,
  injuredReports: ReportInterface[] | null,
  suspectReports: ReportInterface[] | null,
): any => {
  const reportType = _.get(payload, 'report_type.logical_name', '');

  switch (reportType) {
    case REPORT_TYPE.SUSPECT:
      return {
        injuredReports: injuredReports,
        suspectReports: suspectReports
          ? checkIdAndGetData(payload, suspectReports)
          : [payload],
      };
    case REPORT_TYPE.INJURED:
      return {
        suspectReports: suspectReports,
        injuredReports: injuredReports
          ? checkIdAndGetData(payload, injuredReports)
          : [payload],
      };
    default:
      return {
        suspectReports: [],
        injuredReports: [],
      };
  }
};

export const getNewEventJoinRequestsList = (
  payload: any,
  eventJoinRequests: EventJoinResponseInterface[],
): any => {
  return eventJoinRequests
    ? checkIdAndGetData(payload, eventJoinRequests)
    : [payload];
};

export const getTimeFormatted = (date: string) => {
  const createdDate = new Date(date);
  return createdDate.toLocaleTimeString();
};

export const getSortedDescendingOrderList = (list: any) => {
  return list && list.length
    ? list.slice().sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      })
    : [];
};

export const getSortedAscendingOrderList = (list: any) => {
  return list && list.length
    ? list.slice().sort((a: any, b: any) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      })
    : [];
};

// https://stackoverflow.com/a/12043228/6291892
export const isDark = (colorHex: string): boolean => {
  const c = colorHex.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  if (luma < 50) {
    return true;
  } else {
    return false;
  }
};

export const getUpdatedFields = (formValue: any, initialValue: any) => {
  const updatedFields = Object.keys(formValue).filter(
    (value) =>
      !_.isEqual(
        _.get(formValue, [value], ''),
        _.get(initialValue, [value], ''),
      ),
  );
  const requestedPayload: { [key: string]: string } = {};
  updatedFields.forEach((updatedKey) => {
    requestedPayload[updatedKey] = formValue[updatedKey];
  });
  return requestedPayload;
};

export const isDomainPresent = (value: Array<string>): undefined | string =>
  value?.length !== 0 ? undefined : 'Required';

export const isValidDomain = (value: Array<string>): undefined | string =>
  value?.length &&
  value.every((pattern) =>
    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(pattern),
  )
    ? undefined
    : 'Please enter valid domain name. Eg- gmail.com';
