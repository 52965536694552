import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Flex from 'components/General/Flex';
import { useDispatch, useSelector } from 'react-redux';
import { useMapContainerStyle } from './style';
import openDetailArrowIcon from '../../assets/angles-right.svg';
import closeDetailArrowIcon from '../../assets/angles-left.svg';
import { getActiveEvent, getReportTypes } from 'store/event/event.selector';
import { EventInterface } from 'utils';
import MapContainer from './MapContainer';
import Profiles from './Profiles';

import { eventActions } from 'store/event/event.slice';
import { Route, useRouteMatch } from 'react-router';
import {
  getOutgoingVideoCall,
  getIsOngoingVideoCall,
} from 'store/videoCall/videoCall.selector';
import { eventUserActions } from 'store/eventUser/eventUser.slice';
import useVideoCall, {
  OngoingVideoCall,
  OutgoingVideoCall,
  PictureInPictureVideoCall,
} from 'routes/VideoCall';

export function EventDetailScreen() {
  const classes = useMapContainerStyle();
  const activeEvent = useSelector(getActiveEvent);
  const [isCollapsed, setCollapse] = useState(false);
  const reportTypes = useSelector(getReportTypes);
  const outgoingVideoCall = useSelector(getOutgoingVideoCall);
  let callOrMapComp: JSX.Element;
  const videoCall = useVideoCall();
  const isVideoCallOngoing = useSelector(getIsOngoingVideoCall);

  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    if (activeEvent?.id) {
      dispatch(
        eventActions.fetchEventJoinRequestsRequest({ id: activeEvent?.id }),
      );
      dispatch(eventActions.fetchReportsRequest({ id: activeEvent?.id }));
      dispatch(
        eventActions.fetchProfilesRequest({
          id: activeEvent?.id,
        }),
      );
      dispatch(eventUserActions.fetchEventUsers(activeEvent.id));
      if (navigator.geolocation) {
        dispatch(eventActions.sendLocationRequest());
      } else {
        console.log('Location is not available.');
      }
    }
  }, [activeEvent?.id]);

  useEffect(() => {
    if (!reportTypes) {
      dispatch(eventActions.fetchReportTypesRequest());
    }
  }, [reportTypes]);

  if (isVideoCallOngoing) {
    if (videoCall.isPnPMode) {
      callOrMapComp = (
        <>
          <PictureInPictureVideoCall
            setPnPMode={videoCall.setPnPMode}
            users={videoCall.users}
            isVideoCallOngoing={videoCall.isVideoCallOngoing}
            setAudioEnabled={videoCall.setAudioEnabled}
            audioEnabled={videoCall.audioEnabled}
            leaveChannel={videoCall.leaveChannel}
            otherUserName={videoCall.otherUserName}
          />
          <MapContainer />
        </>
      );
    } else {
      callOrMapComp = (
        <OngoingVideoCall
          setPnPMode={videoCall.setPnPMode}
          users={videoCall.users}
          isVideoCallOngoing={videoCall.isVideoCallOngoing}
          setAudioEnabled={videoCall.setAudioEnabled}
          audioEnabled={videoCall.audioEnabled}
          leaveChannel={videoCall.leaveChannel}
          otherUserName={videoCall.otherUserName}
        />
      );
    }
  } else if (outgoingVideoCall) {
    callOrMapComp = (
      <OutgoingVideoCall
        onClick={() => {
          videoCall.leaveChannel(true);
        }}
      />
    );
  } else {
    callOrMapComp = <MapContainer />;
  }

  return (
    <div className={classes.app}>
      <Flex className={classes.container}>
        <div className={classes.mapContainer}>{callOrMapComp}</div>
        <div
          aria-hidden="true"
          className={classes.iconCtn}
          onClick={() => setCollapse(!isCollapsed)}
        >
          <img
            src={isCollapsed ? openDetailArrowIcon : closeDetailArrowIcon}
            alt="Open details"
            className={classes.arrowIcon}
          />
        </div>

        {!isCollapsed && <Profiles />}
        {/* */}
      </Flex>
    </div>
  );
}

export default EventDetailScreen;
