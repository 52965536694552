import React, { Fragment, useState, useEffect } from 'react';
import _, { isNull } from 'lodash';
import { useRouteMatch } from 'react-router';
import { RoutesInterface } from 'routes/path';
import { useDispatch, useSelector } from 'react-redux';

import { haveValidScope, useBreadCrumb } from 'utils/hooks';
import {
  getUserDetailsFormValue,
  getSelectedUser,
  getUserDetailsFormErrors,
  isUserDetailsFormTouched,
} from 'store/users';
import NavigationSection from 'components/SubHeader/NavigationSection';
import { USERS_ROUTES } from 'routes/path';
import {
  USER_DETAILS_ACTIVE_SUB_HEADER_SECTION,
  USER_DETAILS_ACTION_BUTTON,
} from './utils/constants';
import { PageBody } from 'container/PageBody';
import {
  isRouteMatch,
  findActiveRoute,
  compareFormObjects,
} from 'utils/helper';

import {
  ActionButtonInterface,
  CombinedActionButtonInterface,
} from 'utils/commonTypes';

import { organizationActions } from 'store/organization';
import { usersActions } from 'store/users';

import UsersList from './UsersList';
import EditUsers from './Edit';
import {
  useUpdateUserDetailsHeaderVisibility,
  useFetchInitialDataForUser,
} from 'routes/Users/utils/hook';
import diff from 'object-diff';
import { toast } from 'react-toastify';
import {
  DEACTIVATE_USER_SCOPES,
  EDIT_USER_SCOPES,
  REDUX_FORM_CONSTANT,
  STATUS,
  USER_FORM_KEYS,
  WARNING_MESSAGE,
} from 'utils';
import { OrganizationUserTypeInterface } from 'store/policeDept/policeDept.type';
import { submit } from 'redux-form';

const updateActionButtonVisibility = (
  actionButtonObj: CombinedActionButtonInterface,
  currentAppRoute: RoutesInterface | null,
  selectedUser: OrganizationUserTypeInterface,
) => {
  const isUserNotBlocked = isNull(_.get(selectedUser, 'blocked_at', ''));
  const isUserNotArchived = isNull(_.get(selectedUser, 'archived_at', ''));

  const canUsersBeActivated = haveValidScope(DEACTIVATE_USER_SCOPES);
  const areUsersEditable = haveValidScope(EDIT_USER_SCOPES);

  if (!currentAppRoute) {
    return actionButtonObj;
  }
  if (currentAppRoute.path === USERS_ROUTES.USERS.path) {
    _.set(actionButtonObj, 'addUser.isVisible', true);
    _.set(actionButtonObj, 'addOrEditUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activateUser.isVisible', false);
    _.set(actionButtonObj, 'deActivateUser.isVisible', false);
  }

  if (currentAppRoute.path === USERS_ROUTES.USERS_DETAILS.path) {
    _.set(actionButtonObj, 'addUser.isVisible', false);
    _.set(
      actionButtonObj,
      'addOrEditUserDetails.isVisible',
      isNull(selectedUser) ||
        (isUserNotBlocked && isUserNotArchived && areUsersEditable),
    );
    _.set(
      actionButtonObj,
      'activateUser.isVisible',
      !isNull(selectedUser) && !isUserNotBlocked && canUsersBeActivated,
    );
    _.set(
      actionButtonObj,
      'deActivateUser.isVisible',
      !isNull(selectedUser) && isUserNotBlocked && canUsersBeActivated,
    );
  }

  return actionButtonObj;
};

export const Users = () => {
  const currentRoute = useRouteMatch();
  const isUserDetailsTouched = useSelector(isUserDetailsFormTouched);
  const dispatch = useDispatch();
  useFetchInitialDataForUser();

  const selectedUser = useSelector(getSelectedUser);
  const addOrEditUserFormValue = useSelector(getUserDetailsFormValue);
  const addOrEditUserFormErrors = useSelector(getUserDetailsFormErrors);
  const currentRouteBreadCrumb = useBreadCrumb(selectedUser, null);
  const activeSubHeaderSection = useUpdateUserDetailsHeaderVisibility(
    USERS_ROUTES,
    USER_DETAILS_ACTIVE_SUB_HEADER_SECTION,
  );

  const [
    actionButton,
    setActionButton,
  ] = useState<CombinedActionButtonInterface>(
    _.cloneDeep(USER_DETAILS_ACTION_BUTTON),
  );

  const handleActionButtonClick = (actionButtonObj: ActionButtonInterface) => {
    console.log('%c  ', 'background: lime; color: black');

    if (actionButtonObj.id === USER_DETAILS_ACTION_BUTTON.addUser.id) {
      dispatch(organizationActions.createNewOrganizationButtonClick());
    }
    if (
      actionButtonObj.id === USER_DETAILS_ACTION_BUTTON.addOrEditUserDetails.id
    ) {
      dispatch(submit(REDUX_FORM_CONSTANT.USER_DETAILS));
      const org = _.get(addOrEditUserFormValue, 'organization', null);
      const userId = _.get(addOrEditUserFormValue, 'id', '');
      if (!_.isEmpty(addOrEditUserFormErrors)) {
        toast.error('Please fill the required fields.');
      } else
        dispatch(
          usersActions.addOrEditUserRequest({
            formValue: userId
              ? diff(selectedUser, addOrEditUserFormValue)
              : addOrEditUserFormValue,
            // @ts-ignore
            orgId: org?.id,
            // @ts-ignore
            userId: currentRoute.params.id,
          }),
        );
      // dispatch(organizationActions.createNewOrganizationButtonClick());
    }

    if (actionButtonObj.id === USER_DETAILS_ACTION_BUTTON.activateUser.id) {
      dispatch(
        usersActions.updateUserActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.UNBLOCK_USER,
        }),
      );
    }

    if (actionButtonObj.id === USER_DETAILS_ACTION_BUTTON.deActivateUser.id) {
      dispatch(
        usersActions.updateUserActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.BLOCK_USER,
        }),
      );
    }
  };

  useEffect(() => {
    const appActiveRoute = findActiveRoute(USERS_ROUTES, currentRoute.url);
    const updatedActionButton = updateActionButtonVisibility(
      actionButton,
      appActiveRoute,
      selectedUser,
    );
    setActionButton(updatedActionButton);
  }, [currentRoute?.path, currentRoute?.url, selectedUser]);

  window.onbeforeunload = function (event: any) {
    const e = event || window.event;
    const msg = WARNING_MESSAGE;

    if (
      isUserDetailsTouched &&
      compareFormObjects(selectedUser, addOrEditUserFormValue, USER_FORM_KEYS)
    ) {
      e.preventDefault();
      if (e) {
        return (e.returnValue = msg);
      }
      return msg;
    }
  };

  return (
    <Fragment>
      <NavigationSection
        breadcrumbData={currentRouteBreadCrumb}
        // TODO
        // merge section and action button visibility together
        sectionVisibility={activeSubHeaderSection}
        actionButtons={actionButton}
        actionButtonClick={handleActionButtonClick}
      />

      <PageBody>
        {isRouteMatch(USERS_ROUTES.USERS, currentRoute.url) && <UsersList />}

        {isRouteMatch(USERS_ROUTES.USERS_DETAILS, currentRoute.url) && (
          <EditUsers />
        )}
      </PageBody>
    </Fragment>
  );
};

export default Users;
