import { normalize, schema } from 'normalizr';
import _ from 'lodash';
import {
  GenericObject,
  GenericNormalizedObject,
} from 'utils/types/common.types';
import {
  orgModelFromJSON,
  OrganizationTypeInterface,
} from 'models/organization';

import { OrganizationRolesInterface } from 'store/organization';

type Organization = OrganizationTypeInterface[];
type Roles = OrganizationRolesInterface[];

// export const normalizeUserOrgList = (
//   data: Organization[],
// ): GenericNormalizedObject<Organization> => {
//   const normalizedData = <
//     { entities: GenericObject<OrganizationTypeInterface[]>; result: string[] }
//   >{
//     entities: {},
//     result: [],
//   };

//   _.reduce(
//     data,
//     // @ts-ignore
//     (transformedData: typeof normalizedData, orgList: Organization) => {
//       const orgType = orgList[0]?.organization_type;

//       if (transformedData.entities[orgType]) {
//         transformedData.entities[orgType] = [
//           ...transformedData.entities[orgType],
//           ...orgList,
//         ];
//       } else {
//         transformedData.entities[orgType] = orgList;
//       }
//       transformedData.result.push(orgType);

//       return transformedData;
//     },
//     normalizedData,
//   );

//   return normalizedData;
// };

// const userRoles = new schema.Entity('userRoles', [], {
//   idAttribute: 'organization_type',
// });

// export const userRolesListSchema = new schema.Array(userRoles);
// new schema.Array(userRoles);

export const normalizeUserRoleList = (
  data: Roles,
): GenericNormalizedObject<Roles> => {
  const normalizedData = <{ entities: GenericObject<Roles>; result: string[] }>{
    entities: {},
    result: [],
  };

  _.reduce(
    data,
    // @ts-ignore
    (
      transformedData: typeof normalizedData,
      orgList: OrganizationRolesInterface,
    ) => {
      const orgType = orgList.organization_type;

      if (transformedData.entities[orgType]) {
        transformedData.entities[orgType] = [
          ...transformedData.entities[orgType],
          orgList,
        ];
      } else {
        transformedData.entities[orgType] = [orgList];
      }
      transformedData.result.push(orgType);

      return transformedData;
    },
    normalizedData,
  );
  // @ts-ignore
  return normalizedData;
};
