import React from 'react';
import _, { isUndefined } from 'lodash';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { COLORS } from 'styles/colorConstants';

import { TableColumnInterface } from 'utils/commonTypes';

import { isNilOrEmpty } from 'utils';

interface TableProps {
  columnData?: TableColumnInterface[];
  rowData?: any[];
  onRowClick?: (rowObj: any) => void;
}

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: COLORS.SHARK,
      },
      '&:nth-of-type(even)': {
        backgroundColor: COLORS.TUNA,
      },
      cursor: 'pointer',
    },
  }),
)(TableRow);

const useBodyStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'inherit',
      border: `1px solid ${COLORS.ABBEY}`,
      borderRadius: theme.shape.borderRadius,
    },
    tableHeader: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

const computeAddress = (dataObj: any) => {
  let addressStr = '';
  if (!isNilOrEmpty(dataObj.street_1)) {
    addressStr = dataObj.street_1;
  }

  if (!isNilOrEmpty(dataObj.state)) {
    addressStr = addressStr
      ? `${addressStr}, ${dataObj.state?.name}`
      : dataObj.state;
  }

  if (!isNilOrEmpty(dataObj.country)) {
    addressStr = addressStr
      ? `${addressStr}, ${dataObj.country.name}`
      : dataObj.country;
  }

  if (!isNilOrEmpty(dataObj.postal_code)) {
    addressStr = addressStr
      ? `${addressStr}, ${dataObj.postal_code}`
      : dataObj.postal_code;
  }

  return isNilOrEmpty(addressStr) ? '-' : addressStr;
};

const computeStatus = (
  statusValue: string | null,
  userStatus: string | null | undefined,
) => {
  if (!isUndefined(userStatus)) {
    if (statusValue === null && userStatus === null) {
      return 'Active';
    } else if (statusValue || userStatus) {
      return 'Inactive';
    } else return '-';
  } else {
    if (statusValue === null) {
      return 'Active';
    } else if (statusValue) {
      return 'Inactive';
    } else return '-';
  }
};

const getRowValue = (rowObj: any, colObj: any) => {
  if (colObj?.name === 'address') {
    return computeAddress(rowObj);
  }
  if (colObj?.name === 'status') {
    return computeStatus(rowObj[colObj.key], rowObj[colObj?.key2]);
  }
  return isNilOrEmpty(_.get(rowObj, colObj.key, '-'))
    ? '-'
    : _.get(rowObj, colObj.key, '-');
};

const CustomTable: React.FC<TableProps> = (props: TableProps) => {
  const classes = useBodyStyles();
  const columnData = _.get(props, 'columnData', []);
  const rowData = _.get(props, 'rowData', []);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table aria-label="custom pagination table">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {_.map(columnData, (col) => {
              return (
                <TableCell align="left" key={col.key}>
                  {col.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {rowData &&
            rowData.length > 0 &&
            _.map(rowData, (row, rowIndex) => (
              <StyledTableRow
                data-testid={rowIndex}
                key={`${row.id}-${rowIndex}`}
                onClick={() => props.onRowClick?.(row)}
              >
                {_.map(columnData, (colObj, colIndex) => {
                  return (
                    <TableCell
                      align="left"
                      data-cy={colObj.label}
                      key={`${row.id}-${rowIndex}-${colIndex}`}
                    >
                      {getRowValue(row, colObj)}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            ))}

          {rowData && rowData.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={columnData.length}
                style={{ textAlign: 'center' }}
              >
                {' '}
                No Data Available{' '}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
