import { all, takeLatest, put, select } from 'redux-saga/effects';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import * as organizationService from 'services/organization.service';
import * as orgTypeModel from 'models/organization';
import { showErrorMsg } from 'utils/';
import { ASROrgTypeInterface } from 'utils/types';
import { appActions } from './app.slice';
import { AppCategoryEnum } from 'utils/commonTypes';
import {
  isOrgRoute,
  isPoliceDeptRoute,
  isEmsRoute,
  isSpecialEventRoute,
} from 'utils/helper';
import { Config } from 'store/organization';
import { loginActions } from 'store/login';
import { isAuthorised } from 'services/localStorage.service';
import { getAllCategory } from './app.selector';

const getCategoryTypeFromRoute = (pathName: string) => {
  let type = null;
  if (isOrgRoute(pathName)) {
    type = AppCategoryEnum.ORGANIZATION;
  }
  if (isPoliceDeptRoute(pathName)) {
    type = AppCategoryEnum.POLICE_DEPARTMENT;
  }
  if (isEmsRoute(pathName)) {
    type = AppCategoryEnum.EMS;
  }
  if (isSpecialEventRoute(pathName)) {
    type = AppCategoryEnum.SPECIAL_EVENT;
  }
  return type;
};

const getPersonTypeFromResponse = (response: any): Config => {
  return response['person_types'];
};

export function* getAppCategory() {
  try {
    const response: ASROrgTypeInterface = yield organizationService.getAppCategory();
    const parsedResponse = orgTypeModel.orgTypeFromJSON(response);
    const categories = parsedResponse?.data;

    yield put(appActions.categoryAsyncSuccess(categories));
  } catch (err) {
    showErrorMsg(err);
    yield put(appActions.categoryAsyncFailure());
  }
}
function* updateActiveAppCategory(action: LocationChangeAction) {
  try {
    yield put(loginActions.isAuthenticated());
    const getAllCategories = yield select(getAllCategory);
    if (isAuthorised() && getAllCategories.length === 0) {
      yield getAppCategory();
    }
    const { location } = action.payload;
    const pathName = location.pathname;
    const type = getCategoryTypeFromRoute(pathName);
    yield put(appActions.setActiveCategoryType(type));
  } catch (err) {
    showErrorMsg(err);
  }
}
function* getConfigDetails() {
  try {
    const response = yield organizationService.getConfig();
    const personTypes = getPersonTypeFromResponse(response.data);
    yield put(appActions.configAsyncSuccess(personTypes));
  } catch (err) {
    showErrorMsg(err);
  }
}

export function* appSaga(): Generator {
  yield all([
    takeLatest(appActions.categoryAsyncRequest, getAppCategory),
    takeLatest(LOCATION_CHANGE, updateActiveAppCategory),
    takeLatest(appActions.configAsyncRequest, getConfigDetails),
  ]);
}
