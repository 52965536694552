import { OPS_CENTER_ROUTES } from 'routes/path';
import { APP_ASSETS } from 'utils';

export const EVENT_NESTED_LINKS = {
  joinRequests: {
    id: 'joinRequests',
    name: 'Join Requests',
    routeSlug: [OPS_CENTER_ROUTES.OPS_CENTER.path],
    routePost: '/',
    icon: {
      src: APP_ASSETS.JOIN_REQUESTS_ICON,
      name: 'Join Requests',
    },
  },
  involvedUsers: {
    id: 'involvedUsers',
    name: 'Involved Users',
    routeSlug: [OPS_CENTER_ROUTES.OPS_CENTER.path],
    routePost: 'users',
    icon: {
      src: APP_ASSETS.INVOLVED_USERS_ICON,
      name: 'Involved Users',
    },
  },
  suspect: {
    id: 'suspect',
    name: 'Suspect',
    routeSlug: [OPS_CENTER_ROUTES.OPS_CENTER.path],
    routePost: 'suspects',
    icon: {
      src: APP_ASSETS.SUSPECT_ICON,
      name: 'Suspect',
    },
  },
  injured: {
    id: 'injured',
    name: 'Injured',
    routeSlug: [OPS_CENTER_ROUTES.OPS_CENTER.path],
    routePost: 'injured',
    icon: {
      src: APP_ASSETS.INJURED_ICON,
      name: 'Injured',
    },
  },
};

interface TabData {
  index: number;
  dataCy: string;
  title: string;
}

export const OpsCenterEventTabsConstants: TabData[] = [
  {
    index: 0,
    dataCy: 'join-requests',
    title: 'Join Requests',
  },
  {
    index: 1,
    dataCy: 'involved-users',
    title: 'Involved Users',
  },
  {
    index: 2,
    dataCy: 'suspects',
    title: 'Threats',
  },
  {
    index: 3,
    dataCy: 'injured-persons',
    title: 'Injured Persons',
  },
];
