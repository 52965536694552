import React, { useEffect } from 'react';
import { ConnectedRouter as Router } from 'connected-react-router';
import { Switch, Route } from 'react-router';

import { history } from 'utils';

import { ProtectedRoute } from './ProtectedRoute';
// import { Counter } from './Counter';
import { Login } from './Login';
import { ResetPassword } from './ResetPassword';
import { Registration } from './Registration';
import { Organization } from './Organization';
import { PoliceDepartment } from './PoliceDepartment';
import { Ems } from './Ems/';
import { Users } from './Users';
import { NotFound } from './NotFound';
import { ErrorPage } from './ErrorPage';
import _ from 'lodash';
import {
  ORGANIZATION_ROUTES,
  POLICE_DEPT_ROUTES,
  EMS_ROUTES,
  USERS_ROUTES,
  OPS_CENTER_ROUTES,
  VIDEO_CALL_ROUTES,
  SPECIAL_EVENT_ROUTES,
} from './path';
import UnKnowRouteResolver from './UnKnowRouteResolver';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuthenticatedUserEmail,
  getAuthenticatedUserId,
  getAuthenticatedUserLastName,
  getAuthenticatedUserName,
  getCurrentLocation,
  getSystemIpAddress,
  loginActions,
} from 'store';
import { haveValidScope } from 'utils/hooks';
import VideoCall from './VideoCall';
import { Event } from './Event';
import { webSocketActions } from 'store/websocket';
import { SpecialEvent } from './SpecialEvent';
import { NoActiveEvent } from './Event/NoActiveEvent';
// import { useLDClient } from 'launchdarkly-react-client-sdk';

// export const ORGANIZATION_ROUTE = '/organization';
// export const ORGANIZATION_DETAILS_ROUTE = '/organization/:id/details';
// export const ORGANIZATION_USERS_ROUTE = '/organization/:id/users';
// export const ORGANIZATION_MAP_ROUTE = '/organization/:id/map';

export const Routes: React.FC = () => {
  const currentLocation = useSelector(getCurrentLocation);
  const authenticatedUserId = useSelector(getAuthenticatedUserId);
  const authenticatedUserEmail = useSelector(getAuthenticatedUserEmail);
  const authenticatedUserName = useSelector(getAuthenticatedUserName);
  const authenticatedUserLastName = useSelector(getAuthenticatedUserLastName);
  const ipAddress = useSelector(getSystemIpAddress);
  // const ldClient = useLDClient();
  console.log('Current location-', currentLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authenticatedUserId) {
      dispatch(webSocketActions.startConnecting());
    }

    return () => {
      dispatch(webSocketActions.closeConnection());
    };
  }, [authenticatedUserId]);

  // useEffect(() => {
  //   if (!ipAddress) {
  //     //fetch ip address
  //     dispatch(loginActions.fetchIpAddressAsyncRequest);
  //   }
  //   if (authenticatedUserEmail && ldClient && ipAddress) {
  //     const ldUser = {
  //       key: authenticatedUserEmail,
  //       email: authenticatedUserEmail,
  //       firstName: authenticatedUserName,
  //       lastName: authenticatedUserLastName,
  //       ip: ipAddress,
  //     };

  //     ldClient.identify(ldUser, undefined, () => {
  //       console.log("New user's flags available. User-", ldUser);
  //     });
  //   }
  // }, [authenticatedUserEmail, ldClient, ipAddress]);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/register" component={Registration} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/error" component={ErrorPage} />
        <Route path="/404" component={NotFound} />
        <Route path="/event" component={NoActiveEvent} />
        <Route path="/">
          <Switch>
            {/* <ProtectedRoute exact path="/counter" render={() => <Counter />} /> */}

            <ProtectedRoute
              exact
              path={(Object.keys(ORGANIZATION_ROUTES) as Array<
                keyof typeof ORGANIZATION_ROUTES
              >)
                .map((routes) => ORGANIZATION_ROUTES[routes])
                .filter((obj) => haveValidScope(obj.scopes))
                .map((obj) => obj.path)}
              render={() => <Organization />}
            />

            {/* Police Department */}
            <ProtectedRoute
              exact
              path={
                (Object.keys(POLICE_DEPT_ROUTES) as Array<
                  keyof typeof POLICE_DEPT_ROUTES
                >)
                  .map((routes) => POLICE_DEPT_ROUTES[routes])
                  .filter((obj) => haveValidScope(obj.scopes))
                  .map((obj) => obj.path)
                //   // TODO
                //   // @ts-ignore
              }
              render={() => <PoliceDepartment />}
            />
            {/* EMS */}
            <ProtectedRoute
              exact
              path={(Object.keys(EMS_ROUTES) as Array<keyof typeof EMS_ROUTES>)
                .map((routes) => EMS_ROUTES[routes])
                .filter((obj) => haveValidScope(obj.scopes))
                .map((obj) => obj.path)}
              render={() => <Ems />}
            />
            {/* SPECIAL EVENT */}
            <ProtectedRoute
              exact
              path={(Object.keys(SPECIAL_EVENT_ROUTES) as Array<
                keyof typeof SPECIAL_EVENT_ROUTES
              >)
                .map((routes) => SPECIAL_EVENT_ROUTES[routes])
                .filter((obj) => haveValidScope(obj.scopes))
                .map((obj) => obj.path)}
              render={() => <SpecialEvent />}
            />
            {/* Users */}
            <ProtectedRoute
              exact
              path={(Object.keys(USERS_ROUTES) as Array<
                keyof typeof USERS_ROUTES
              >)
                .map((routes) => USERS_ROUTES[routes])
                .filter((obj) => haveValidScope(obj.scopes))
                .map((obj) => obj.path)}
              render={() => <Users />}
            />
            {/* OpsCenter */}
            <ProtectedRoute
              // exact
              path={(Object.keys(OPS_CENTER_ROUTES) as Array<
                keyof typeof OPS_CENTER_ROUTES
              >)
                .map((routes) => OPS_CENTER_ROUTES[routes])
                .filter((obj) => haveValidScope(obj.scopes))
                .map((obj) => obj.path)}
              render={() => <Event />}
            />

            {/* <ProtectedRoute
              exact
              path={(Object.keys(VIDEO_CALL_ROUTES) as Array<
                keyof typeof VIDEO_CALL_ROUTES
              >)
                .map((routes) => VIDEO_CALL_ROUTES[routes])
                .filter((obj) => haveValidScope(obj.scopes))
                .map((obj) => obj.path)}
              render={() => <VideoCall />}
            /> */}

            {/* Catch-all route */}
            <Route
              path="/"
              render={(props: any) => <UnKnowRouteResolver {...props} />}
            />
          </Switch>
        </Route>
      </Switch>
    </Router>
  );
};

// [
//   ROUTES.ORGANIZATION.path,
//   ROUTES.ORGANIZATION_DETAILS.path,
//   ROUTES.ORGANIZATION_MAP.path,
//   ROUTES.ORGANIZATION_USERS.path,
//   ROUTES.ORGANIZATION_USERS_DETAILS.path,
// ]
