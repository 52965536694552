import { useState, useEffect } from 'react';
import _ from 'lodash';
import { AppRouteInterface, USERS_ROUTES } from 'routes/path';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserDetailsFormValue,
  getSelectedUser,
  getUserOrgList,
  getUserRoleList,
  getAllRoles,
} from 'store/users';
import { allOrgPersonTypes, getAllCategory } from 'store/app';

import {
  USER_DETAILS_FORM_FIELDS,
  USER_DETAILS_ACTIVE_SUB_HEADER_SECTION,
} from 'routes/Users/utils/constants';
import { usersActions } from 'store/users';
import {
  AppCategoryEnum,
  CREATE_USER_SCOPES,
  EDIT_USER_SCOPES,
  FormField,
  isNilOrEmpty,
  UserRolesType,
} from 'utils';
import { haveValidScope } from 'utils/hooks';
import { OrganizationTypeInterface } from 'models/organization';
import { OrganizationRolesInterface } from 'store';

type VisibilityObjType = typeof USER_DETAILS_ACTIVE_SUB_HEADER_SECTION;

export const showBadgeFieldUser = (
  allCategories: any,
  formValue: any,
): boolean => {
  const orgTypeId = _.get(formValue, 'organization.organization_type', '');
  const orgCategoryId = _.filter(
    allCategories,
    (obj) => obj.name === AppCategoryEnum.ORGANIZATION,
  )[0]?.id;

  if (
    orgTypeId !== orgCategoryId &&
    !isNilOrEmpty(orgTypeId) &&
    !isNilOrEmpty(orgCategoryId)
  ) {
    return true;
  }
  return false;
};
export const showBadgeFieldInOrganisation = (
  activeCategoryId: any,
  allCategories: any,
): boolean => {
  const orgCategoryId = _.filter(
    allCategories,
    (obj) => obj.name === AppCategoryEnum.ORGANIZATION,
  )[0]?.id;

  if (
    activeCategoryId !== orgCategoryId &&
    !isNilOrEmpty(activeCategoryId) &&
    !isNilOrEmpty(orgCategoryId)
  ) {
    return true;
  }
  return false;
};

export const useInitializeForm = () => {
  const selectedUser = useSelector(getSelectedUser);
  const [formFieldsArr, setFormFieldArr] = useState<FormField[]>([]);
  const [initialValue, setInitalValue] = useState(selectedUser);
  const formValue = useSelector(getUserDetailsFormValue);
  const allPersonTypes = useSelector(allOrgPersonTypes);
  const allRoles = useSelector(getUserRoleList);
  const allRolesInList = useSelector(getAllRoles);
  const orgList = useSelector(getUserOrgList);

  useEffect(() => {
    const fieldArr = _.cloneDeep(USER_DETAILS_FORM_FIELDS);

    const orgIndex = _.findIndex(
      fieldArr,
      (field) => field.name === 'organization',
    );
    if (orgIndex !== -1 && fieldArr && fieldArr.length) {
      fieldArr[orgIndex].options = orgList;
    }
    if (selectedUser && !selectedUser.organization) {
      fieldArr.splice(orgIndex, 1);
    }
    if (selectedUser && !selectedUser.person_type) {
      const personTypeIndex = _.findIndex(
        fieldArr,
        (field) => field.name === 'person_type',
      );
      fieldArr.splice(personTypeIndex, 1);
    }

    if (!_.isEmpty(allRoles)) {
      const orgTypeId = _.get(
        selectedUser,
        'organization.organization_type',
        '',
      );
      // const isSEO = _.get(
      //   selectedUser,
      //   'organization.is_special_event_org',
      //   '',
      // );
      const newRoles = useGetUserRoles(orgTypeId, allRoles, selectedUser);
      const newPersonTypeOptions = useGetPersonType(orgTypeId, allPersonTypes);
      updateRolesOptions(newRoles, fieldArr, setFormFieldArr, 'role');
      updateRolesOptions(
        newPersonTypeOptions,
        fieldArr,
        setFormFieldArr,
        'person_type',
      );
    }

    if (selectedUser) {
      setFormFieldArr(fieldArr);
    }
  }, [orgList, selectedUser]);

  useEffect(() => {
    setInitalValue(selectedUser);
  }, [selectedUser]);

  return [formFieldsArr, setFormFieldArr, initialValue, formValue];
};

export const useUpdateUserDetailsHeaderVisibility = (
  appRoutesObj: AppRouteInterface,
  defaultVisibilityObj: VisibilityObjType,
): VisibilityObjType => {
  const currentRoute = useRouteMatch();
  const [activeSubHeaderSection, setSubHeaderSection] = useState(
    defaultVisibilityObj,
  );
  const canAddUsers = haveValidScope(CREATE_USER_SCOPES);
  const isUserEditable = haveValidScope(EDIT_USER_SCOPES);

  useEffect(() => {
    const currentPath = _.get(currentRoute, 'path', '');
    const newObj = _.cloneDeep(activeSubHeaderSection);

    if (currentPath === USERS_ROUTES.USERS.path) {
      newObj.leftSection = true;
      newObj.middleSection = false;
      newObj.rightSection = canAddUsers;
    } else {
      newObj.leftSection = true;
      newObj.middleSection = false;
      newObj.rightSection = isUserEditable;
    }

    setSubHeaderSection(newObj);
    return () => {
      setSubHeaderSection(defaultVisibilityObj);
    };
  }, [currentRoute.url]);

  return activeSubHeaderSection;
};

export const useFetchInitialDataForUser = () => {
  const allCategories = useSelector(getAllCategory);
  const dispatch = useDispatch();

  useEffect(() => {
    if (allCategories && allCategories.length > 0) {
      // dispatch action
      dispatch(usersActions.fetchUserRolesRequest());
      dispatch(usersActions.fetchUserOrgRequest({ orgTypes: allCategories }));
    }
  }, [allCategories]);
};

export const isFormFieldValueChange = (
  newFormValue: any,
  oldFormValue: any,
  path: string,
) => {
  const updateValue = _.get(newFormValue, path, '');
  const oldValue = _.get(oldFormValue, path, null);

  if (updateValue !== oldValue) {
    return true;
  }
  return false;
};

export const useGetUserRoles = (
  // isSEO: boolean,
  orgTypeId: string,
  allRoles: any,
  selectedUser: any,
) => {
  const isCeraAdmin = _.get(selectedUser, 'organization', '') === null;

  const roleArr = allRoles[orgTypeId ? orgTypeId : isCeraAdmin ? 'null' : ''];
  if (roleArr && roleArr.length) {
    return _.cloneDeep(roleArr);
  }
  return [];
};

export const useGetPersonType = (orgTypeId: string, allPersonTypes: any) => {
  if (orgTypeId) {
    return _.filter(
      allPersonTypes,
      (obj) => obj.organization_type === orgTypeId,
    );
  }
  return [];
};

export const updateRolesOptions = (
  roleOptions: any,
  fieldArr: any,
  setFieldArr: any,
  fieldName: any,
): void => {
  const roleFieldIndex = _.findIndex(fieldArr, (field: any) => {
    return field.name === fieldName;
  });
  if (roleFieldIndex !== -1) {
    fieldArr[roleFieldIndex].options = roleOptions;
    setFieldArr(fieldArr);
  }
};

// export const updateRoleOptions = (
//   fieldArr: FormField[],
//   orgTypeId: string,
// ): FormField[] => {
//   const provinceIndex = _.findIndex(
//     fieldArr,
//     (obj: FormField) => obj.name === 'state',
//   );
//   const provincesObj = STATE_DATA[`${countryCode}`];
//   if (provincesObj) {
//     // @ts-ignore
//     fieldArr[provinceIndex].options = _.cloneDeep(provincesObj.provinces);
//   } else {
//     fieldArr[provinceIndex].options = [];
//   }

//   return fieldArr;
// };
