import _ from 'lodash';
import { RootState } from '../index';
import { REDUX_FORM_CONSTANT } from 'utils/constants';

export const getUsersData = (state: RootState) => {
  return _.get(state, 'users.list.data', []);
};

export const getSelectedUser = (state: RootState) => {
  return _.get(state, 'users.activeUser', null);
};

export const getUserOrgList = (state: RootState) => {
  return _.get(state, 'users.combinedOrgList', null);
};

export const getUserRoleList = (state: RootState) => {
  return _.get(state, 'users.combinedUserRoles.entities', {});
};

export const getAllRoles = (state: RootState) => {
  return _.get(state, 'users.allRoles', []);
};

export const getUserDetailsFormValue = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.USER_DETAILS}.values`, {});

export const isUserDetailsFormTouched = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.USER_DETAILS}.anyTouched`, false);

export const getUserDetailsFormErrors = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.USER_DETAILS}.syncErrors`, {});

export const getIsPasswordResetSuccess = (state: RootState) =>
  _.get(state, `users.isPasswordResetSuccess`, false);
