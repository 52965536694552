import _, { isNull } from 'lodash';
import {
  CREATE_ORG_SCOPES,
  CREATE_USER_SCOPES,
  DEACTIVATE_ORG_SCOPES,
  DEACTIVATE_USER_SCOPES,
  EDIT_ORG_SCOPES,
  EDIT_USER_SCOPES,
  SCOPES,
  STATE_DATA,
} from 'utils/constants';
import { isNilOrEmpty } from 'utils/helper';
import { FormField } from 'utils/types';
import { RoutesInterface } from 'routes/path';
import { POLICE_DEPT_ROUTES } from 'routes/path';
import { CombinedActionButtonInterface } from 'utils/commonTypes';
import { haveAllScopes, haveValidScope } from 'utils/hooks';
import { OrganizationUserTypeInterface } from 'store';
import { OrganizationTypeInterface } from 'models/organization';

export const getHeaderSectionVisibility = (
  currentAppRoute: RoutesInterface | null,
) => {
  if (isNilOrEmpty(currentAppRoute)) {
    return {
      leftSection: true,
      middleSection: true,
      rightSection: true,
    };
  }

  //  when on police dept data list page
  if (
    currentAppRoute &&
    currentAppRoute.id === POLICE_DEPT_ROUTES.POLICE_DEPT.id
  ) {
    return {
      leftSection: true,
      middleSection: false,
      rightSection: true,
    };
  }

  //   //  when on map page
  //   if (currentAppRoute && currentAppRoute.id ===  POLICE_DEPT_ROUTES. .id) {
  //     return {
  //       leftSection: true,
  //       middleSection: true,
  //       rightSection: false,
  //     };
  //   }

  return {
    leftSection: true,
    middleSection: true,
    rightSection: true,
  };
};

export const checkAndUpdateProvinceOptions = (
  newFormValue: any,
  oldFormValue: any,
  fieldArr: FormField[],
) => {
  const updateCountryName = _.get(newFormValue, 'country.name', '');
  const oldCountryName = _.get(oldFormValue, 'country.name', null);
  console.log('%c updateCountryName ', 'background: lime; color: black', {
    updateCountryName,
    oldCountryName,
  });
  if (updateCountryName !== oldCountryName) {
    updateProvinceOptions(fieldArr, updateCountryName);
    return true;
  }
  return false;
};

export const updateProvinceOptions = (
  fieldArr: FormField[],
  countryCode: string,
): FormField[] => {
  const provinceIndex = _.findIndex(
    fieldArr,
    (obj: FormField) => obj.name === 'state',
  );
  const provincesObj = STATE_DATA[`${countryCode}`];
  if (provincesObj) {
    if (_.isNull(_.get(provincesObj, 'provinces', ''))) {
      fieldArr[provinceIndex].options = [{ name: 'NA' }];
    } else {
      // @ts-ignore
      fieldArr[provinceIndex].options = _.cloneDeep(provincesObj.provinces);
    }
  } else {
    fieldArr[provinceIndex].options = [];
  }

  return fieldArr;
};

export const updateActionButtonVisibility = (
  actionButtonObj: CombinedActionButtonInterface,
  currentAppRoute: RoutesInterface | null,
  selectedUser: OrganizationUserTypeInterface | null,
  selectedOrganization: OrganizationTypeInterface | null,
) => {
  if (!currentAppRoute) {
    return actionButtonObj;
  }
  const isOrgEditable = haveValidScope(EDIT_ORG_SCOPES);
  const areUsersEditable = haveValidScope(EDIT_USER_SCOPES);
  const canCreateUsers = haveValidScope(CREATE_USER_SCOPES);
  const canAddOrg = haveValidScope(CREATE_ORG_SCOPES);
  const isUserNotBlocked = isNull(_.get(selectedUser, 'blocked_at', ''));
  const isUserNotArchived = isNull(_.get(selectedUser, 'archived_at', ''));
  const isOrgActive = isNull(_.get(selectedOrganization, 'archived_at', ''));
  const canUsersBeActivated = haveValidScope(DEACTIVATE_USER_SCOPES);
  const canOrgBeActivated = haveValidScope(DEACTIVATE_ORG_SCOPES);
  const canAddEditSupervise = haveAllScopes(SCOPES.EDIT_ORG);
  // const canStartEvent =
  //   _.get(selectedOrganization, 'is_special_event_org', '') === true;

  console.log('%c actionButtonObj ^^^ ', 'background: black; color: yellow', {
    actionButtonObj,
    currentAppRoute,
  });

  if (currentAppRoute.path === POLICE_DEPT_ROUTES.POLICE_DEPT.path) {
    _.set(actionButtonObj, 'addPoliceDept.isVisible', canAddOrg);
    // _.set(actionButtonObj, 'startEvent.isVisible', false);
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
    _.set(actionButtonObj, 'addOrEditPoliceDept.isVisible', false);
    _.set(actionButtonObj, 'activatePoliceDept.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceOrg.isVisible', false);
    _.set(actionButtonObj, 'addUserToPoliceDept.isVisible', false);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }

  if (currentAppRoute.path === POLICE_DEPT_ROUTES.POLICE_DEPT_DETAILS.path) {
    _.set(actionButtonObj, 'addPoliceDept.isVisible', false);
    // _.set(
    //   actionButtonObj,
    //   'startEvent.isVisible',
    //   isCeraAdmin && canStartEvent && hasStartEventScope,
    // );
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
    _.set(
      actionButtonObj,
      'addOrEditPoliceDept.isVisible',
      isNull(selectedOrganization) || (isOrgActive && isOrgEditable),
    );
    _.set(
      actionButtonObj,
      'activatePoliceDept.isVisible',
      !isNull(selectedOrganization) && !isOrgActive && canOrgBeActivated,
    );
    _.set(
      actionButtonObj,
      'deActivatePoliceOrg.isVisible',
      !isNull(selectedOrganization) && isOrgActive && canOrgBeActivated,
    );
    _.set(actionButtonObj, 'addUserToPoliceDept.isVisible', false);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }

  if (currentAppRoute.path === POLICE_DEPT_ROUTES.POLICE_DEPT_USERS.path) {
    _.set(actionButtonObj, 'addPoliceDept.isVisible', false);
    // _.set(actionButtonObj, 'startEvent.isVisible', false);
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
    _.set(actionButtonObj, 'addOrEditPoliceDept.isVisible', false);
    _.set(actionButtonObj, 'activatePoliceDept.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceOrg.isVisible', false);
    _.set(actionButtonObj, 'addUserToPoliceDept.isVisible', canCreateUsers);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }

  if (
    currentAppRoute.path === POLICE_DEPT_ROUTES.POLICE_DEPT_USERS_DETAILS.path
  ) {
    _.set(actionButtonObj, 'addPoliceDept.isVisible', false);
    // _.set(actionButtonObj, 'startEvent.isVisible', false);
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
    _.set(actionButtonObj, 'addOrEditPoliceDept.isVisible', false);
    _.set(actionButtonObj, 'activatePoliceDept.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceOrg.isVisible', false);
    _.set(actionButtonObj, 'addUserToPoliceDept.isVisible', false);
    _.set(
      actionButtonObj,
      'updateUserDetails.isVisible',
      isNull(selectedUser) ||
        (isUserNotBlocked && isUserNotArchived && areUsersEditable),
    );
    _.set(
      actionButtonObj,
      'activatePoliceDeptUsers.isVisible',
      !isNull(selectedUser) && !isUserNotBlocked && canUsersBeActivated,
    );
    _.set(
      actionButtonObj,
      'deActivatePoliceDeptUsers.isVisible',
      !isNull(selectedUser) && isUserNotBlocked && canUsersBeActivated,
    );
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }
  if (currentAppRoute.path === POLICE_DEPT_ROUTES.POLICE_DEPT_ORG.path) {
    _.set(actionButtonObj, 'addPoliceDept.isVisible', false);
    // _.set(actionButtonObj, 'startEvent.isVisible', false);
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
    _.set(actionButtonObj, 'addOrEditPoliceDept.isVisible', false);
    _.set(actionButtonObj, 'activatePoliceDept.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceOrg.isVisible', false);
    _.set(actionButtonObj, 'addUserToPoliceDept.isVisible', false);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', canAddEditSupervise);
    //todo add scope cond here
  }
  // if (currentAppRoute.path === POLICE_DEPT_ROUTES.POLICE_DEPT_MAP.path) {
  //   _.set(actionButtonObj, 'addPoliceDept.isVisible', false);
  //   _.set(actionButtonObj, 'startEvent.isVisible', false);
  //   _.set(actionButtonObj, 'updateMapDetails.isVisible', true);
  //   _.set(actionButtonObj, 'addOrEditPoliceDept.isVisible', false);
  //   _.set(actionButtonObj, 'activatePoliceDept.isVisible', false);
  //   _.set(actionButtonObj, 'deActivatePoliceOrg.isVisible', false);
  //   _.set(actionButtonObj, 'addUserToPoliceDept.isVisible', false);
  //   _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
  //   _.set(actionButtonObj, 'activatePoliceDeptUsers.isVisible', false);
  //   _.set(actionButtonObj, 'deActivatePoliceDeptUsers.isVisible', false);
  //   _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  // }

  if (currentAppRoute.path === POLICE_DEPT_ROUTES.POLICE_DEPT_QR_CODE.path) {
    _.set(actionButtonObj, 'addPoliceDept.isVisible', false);
    // _.set(actionButtonObj, 'startEvent.isVisible', false);
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
    _.set(
      actionButtonObj,
      'addOrEditPoliceDept.isVisible',
      isNull(selectedOrganization) || (isOrgActive && isOrgEditable),
    );
    _.set(actionButtonObj, 'activatePoliceDept.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceOrg.isVisible', false);
    _.set(actionButtonObj, 'addUserToPoliceDept.isVisible', false);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'deActivatePoliceDeptUsers.isVisible', false);
    _.set(actionButtonObj, 'addEditSupervisee.isVisible', false);
  }
  return actionButtonObj;
};
