import _ from 'lodash';
import { OverRideEventInterface } from 'models/event/event.model';
import { OrganizationTypeInterface } from 'models/organization';
import {
  EventJoinResponseInterface,
  FILTER_TYPES,
  OrgTypeInterface,
  Profile,
} from 'utils';
import { RootState } from '../index';

export const getActiveEvent = (state: RootState): OverRideEventInterface =>
  _.get(state, 'event.onGoingEvent', null);

export const getActiveEventOrg = (
  state: RootState,
): OrganizationTypeInterface[] =>
  _.get(state, 'event.onGoingEvent.organizations', null);

export const getSuspectReports = (state: RootState) =>
  _.get(state, 'event.suspectReports', null);

export const getInjuredReports = (state: RootState) =>
  _.get(state, 'event.injuredReports', null);

export const getReportTypes = (state: RootState): OrgTypeInterface[] | null =>
  _.get(state, 'event.reportTypes', null);

export const getSuspectProfiles = (state: RootState): Profile[] | null =>
  _.get(state, 'event.suspectProfiles', null);

export const getInjuredProfiles = (state: RootState): Profile[] | null =>
  _.get(state, 'event.injuredProfiles', null);

export const getEventJoinRequests = (
  state: RootState,
): EventJoinResponseInterface[] | null =>
  _.get(state, 'event.eventJoinRequests', null);

export const getAcceptDenyJoinRequestInProgress = (state: RootState): boolean =>
  _.get(state, 'event.isAcceptDenyJoinRequestInProgress', false);

export const getEventLoader = (state: RootState): boolean =>
  _.get(state, 'event.isLoading', false);

export const getProfileFilter = (state: RootState): string =>
  _.get(state, 'event.selectedFilter', FILTER_TYPES.ACTIVE);

export const getHasActiveEvent = (state: RootState): boolean | null =>
  _.get(state, 'event.has_joined', null);

export const getEventList = (
  state: RootState,
): Array<OverRideEventInterface> | null =>
  _.get(state, 'event.eventList', null);

export const getActiveEventOrgs = (
  state: RootState,
): OrganizationTypeInterface[] =>
  _.get(state, 'event.onGoingEvent.organizations', null);
