import _ from 'lodash';
import { AppCategoryEnum } from 'utils/commonTypes';
import { CategoryInterface } from 'store/app/app.types';
import { RootState } from '../index';
import { PersonTypeInterface } from 'store/organization';
import { OrgTypeInterface } from 'utils';

export const getSpinnerStatus = (state: RootState) => {
  const status = {
    app: _.get(state, 'app.isLoading', false),
    organization: _.get(state, 'organization.isLoading', false),
    users: _.get(state, 'users.isLoading', false),
    event: _.get(state, 'event.isLoading', false),
  };
  let isLoading = false;

  _.mapKeys(status, (v, k: string) => {
    // @ts-ignore
    if (!isLoading && status[k]) {
      isLoading = true;
    }
  });

  return isLoading;
};

export const getAllCategory = (state: RootState): Array<OrgTypeInterface> => {
  return _.get(state, 'app.allCategories', []);
};

export const getOrganizationCategory = (state: RootState) => {
  return _.get(state, 'app.organizationCategory', false);
};
export const getPoliceDeptCategory = (state: RootState) => {
  return _.get(state, 'app.policeCategory', null);
};
export const getSeOrgCategory = (state: RootState) => {
  return _.get(state, 'app.specialEventCategory', null);
};
export const getActiveCategoryType = (state: RootState) => {
  return _.get(state, 'app.activeCategoryType', null);
};
export const checkCategoryFetchStatus = (state: RootState) => {
  return _.get(state, 'app.isCategoriesFetched', false);
};

export const getActiveCategory = (
  state: RootState,
): null | CategoryInterface => {
  const activeCategory = getActiveCategoryType(state);

  console.log(
    '%c activeCategory ',
    'background: lime; color: black',
    activeCategory,
  );

  switch (activeCategory) {
    case AppCategoryEnum.ORGANIZATION: {
      const orgCategory = _.get(state, 'app.organizationCategory', null);
      if (_.isArray(orgCategory)) {
        return orgCategory[0];
      }
      return orgCategory;
    }
    case AppCategoryEnum.POLICE_DEPARTMENT: {
      return _.get(state, 'app.policeCategory', null);
    }
    case AppCategoryEnum.EMS: {
      return _.get(state, 'app.emsCategory', null);
    }
    case AppCategoryEnum.SPECIAL_EVENT: {
      return _.get(state, 'app.specialEventCategory', null);
    }
  }
  return null;
};

export const currentOrgPersonTypes = (
  state: RootState,
): undefined | Array<PersonTypeInterface> => {
  const activeCategoryId = getActiveCategory(state);
  const allConfigs: Array<PersonTypeInterface> = _.get(
    state,
    'app.allConfigDetails',
  );

  return _.filter(
    allConfigs,
    (obj) => obj.organization_type === activeCategoryId?.id,
  );
};

export const allOrgPersonTypes = (
  state: RootState,
): undefined | Array<PersonTypeInterface> => {
  return _.get(state, 'app.allConfigDetails');
};

export const getSeOrgTypeId = (state: RootState): string => {
  return _.get(state, 'app.specialEventCategory.id', '');
};
