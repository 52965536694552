import _ from 'lodash';

const ls = localStorage || {};

export const get = (key: string): any => {
  const value = ls.getItem(key);
  if (_.isNil(value)) {
    return value;
  }
  return JSON.parse(value);
};

export const set = (key: string, value: any): boolean | Error => {
  if (_.isNil(value)) {
    throw Error('Value is either Null or Undefined');
  }
  try {
    const stringValue = JSON.stringify(value);
    ls.setItem(key, stringValue);
    return true;
  } catch (e) {
    throw e;
  }
};

export const remove = (key: string): void => {
  ls.removeItem(key);
};

export const clear = (): void => {
  ls.clear();
};
