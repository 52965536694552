import {
  EventInterface,
  GeoJSONType,
  InnerPerimeterPoint,
  isNilOrEmpty,
  PositionInterface,
} from 'utils';
import _ from 'lodash';

export interface PointsGeoJSONType {
  type: 'Point';
  coordinates: PositionInterface;
}

export interface MultiPointGeoJSONType {
  type: 'MultiPoint';
  coordinates: PositionInterface[];
}

export const getMapsCoords = (coords: GeoJSONType): PositionInterface => {
  return {
    lat: coords[1],
    lng: coords[0],
  };
};

export type OverRideInnerPPInterface = Omit<InnerPerimeterPoint, 'point'> & {
  point: PointsGeoJSONType;
};

export type OverRideEventInterface = Omit<
  EventInterface,
  'ems_command_post' | 'police_command_post'
> & {
  police_command_post: MultiPointGeoJSONType;
  ems_command_post: MultiPointGeoJSONType | null;
  inner_pps: OverRideInnerPPInterface[] | null;
};

export const basicEventDetailsFromJson = (apiResponse: EventInterface[]) => {
  if (isNilOrEmpty(apiResponse)) {
    return [];
  }
  if (!_.isArray(apiResponse)) {
    return [];
  }

  return apiResponse.map((res) => {
    return eventModel(res);
  });
};

function eventModel(dataObj: EventInterface) {
  const eventObj = {} as OverRideEventInterface;

  eventObj.id = dataObj.id;
  eventObj.created_at = dataObj.created_at;
  eventObj.updated_at = dataObj.updated_at;
  eventObj.device_id = dataObj.device_id;
  eventObj.device_name = dataObj.device_name;
  eventObj.ip_address = dataObj.ip_address;
  eventObj.location = dataObj.location;
  eventObj.ended_at = dataObj.ended_at;
  eventObj.end_meta = dataObj.end_meta;
  eventObj.end_reason = dataObj.end_reason;
  eventObj.end_comment = dataObj.end_comment;
  eventObj.user = dataObj.user;
  eventObj.ended_by_user = dataObj.ended_by_user;
  eventObj.organizations = dataObj.organizations;
  eventObj.supervising_police_organization =
    dataObj.supervising_police_organization;
  eventObj.supervising_ems_organization = dataObj.supervising_ems_organization;
  eventObj.event_join_request = dataObj.event_join_request;
  eventObj.is_special_event = dataObj.is_special_event;

  const policeCpCoords = _.get(dataObj, 'police_command_post.coordinates', []);
  eventObj.police_command_post = {
    type: _.get(dataObj, 'police_command_post.type', 'MultiPoint'),
    coordinates: policeCpCoords.map((coords: GeoJSONType) =>
      getMapsCoords(coords),
    ),
  };

  const isEmsCpPresent = _.get(dataObj, 'ems_command_post', null);
  let emsCoords = null;
  if (isEmsCpPresent) {
    emsCoords = _.get(dataObj, 'ems_command_post.coordinates', null);
  }

  eventObj.ems_command_post = !isEmsCpPresent
    ? null
    : {
        type: _.get(dataObj, 'ems_command_post.type', 'MultiPoint'),
        // @ts-ignore
        coordinates: emsCoords.map((coords) => getMapsCoords(coords)),
      };

  const inner_pps = _.get(dataObj, 'inner_pps', null);
  const val = inner_pps?.map((ppObj) => {
    return {
      ...ppObj,
      point: {
        type: _.get(dataObj, 'ems_command_post.type', 'Point'),
        coordinates: getMapsCoords(ppObj.point.coordinates),
      },
    };
  });
  // @ts-ignore
  eventObj.inner_pps = val ? val : null;

  return eventObj;
}
