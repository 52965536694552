import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Marker } from '@react-google-maps/api';
import Flex from 'components/General/Flex';
import { useDispatch, useSelector } from 'react-redux';
import { useMapContainerStyle } from './style';
import { getActiveEvent, getEventList } from 'store/event/event.selector';
import { EventJoinRequestEnum, getTimeFormatted } from 'utils';

import { eventActions } from 'store/event/event.slice';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { GoogleMap } from '@react-google-maps/api';
import { OverRideEventInterface } from 'models/event/event.model';
import { CustomButton } from 'components/General/CustomButton';
import { getAuthenticatedUserOrgId } from 'store';
import EventMarker from 'assets/customized/active-threat.svg';

export function EventListScreen(): JSX.Element {
  const classes = useMapContainerStyle();
  const eventList = useSelector(getEventList);
  const googleMapRef: any = useRef(null);
  const dispatch = useDispatch();
  const [joinEvent, setJoinEvent] = useState(false);
  const authOrganizationId = useSelector(getAuthenticatedUserOrgId);
  const joinedEvent = useSelector(getActiveEvent);
  const history = useHistory();
  const [
    selectedEvent,
    setSelectedEvent,
  ] = useState<null | OverRideEventInterface>(null);

  const handleMapLoad = (googleMapInstance: any) => {
    googleMapRef.current = googleMapInstance;
  };

  const setBounds = () => {
    // @ts-ignore
    const bounds = new window.google.maps.LatLngBounds();
    eventList?.map((event, key) => {
      bounds.extend({
        lat: event.location.coordinates[1],
        lng: event.location.coordinates[0],
      });
      return key;
    });
    googleMapRef.current.fitBounds(bounds);

    const center = bounds.getCenter();
    googleMapRef.current.setCenter(center);
  };
  useEffect(() => {
    if (eventList && googleMapRef.current) {
      setBounds();
    }
  }, [googleMapRef.current, eventList]);

  const handleJoinEvent = (event: OverRideEventInterface) => {
    setJoinEvent(true);
    setSelectedEvent(event);
  };
  const handleCancel = () => {
    setJoinEvent(false);
    setSelectedEvent(null);
  };
  const confirmJoinEvent = () => {
    if (selectedEvent) {
      if (
        selectedEvent?.event_join_request?.status ===
        EventJoinRequestEnum.PENDING
      ) {
        dispatch(
          eventActions.cancelEventJoinRequest({
            eventJoinRequestId: selectedEvent?.event_join_request?.id,
          }),
        );
      } else {
        dispatch(eventActions.eventJoinRequest({ eventId: selectedEvent.id }));
      }

      setJoinEvent(false);
    }
  };

  useEffect(() => {
    if (joinedEvent?.id) {
      history.push(`events/${joinedEvent.id}`);
    }
  }, [joinedEvent]);

  return (
    <div className={classes.app}>
      <Flex className={classes.container}>
        <GoogleMap
          mapContainerClassName={classes['app-map']}
          onLoad={handleMapLoad}
          zoom={12}
        >
          {eventList?.map((event) => (
            <Marker
              key={event.id}
              position={{
                lat: event.location.coordinates[1],
                lng: event.location.coordinates[0],
              }}
              icon={EventMarker}
              draggable={false}
            />
          ))}
        </GoogleMap>
        <div className={`${classes.eventListCtn} ${classes.raisinBgColor}`}>
          <div className={classes.header}>
            <Typography
              data-cy="active-threats"
              className={`${classes.centerAlign} ${classes.padding}`}
            >
              Active Threats
            </Typography>
          </div>
          <div className={classes.navSection}>
            <div className={classes.report}>
              {eventList?.map((event) => {
                const actionedBy = event?.event_join_request?.actioned_by;
                const requestStatus = event?.event_join_request?.status;
                return (
                  <div
                    aria-hidden="true"
                    className={classes.eventComp}
                    key={event.id}
                    onClick={() => handleJoinEvent(event)}
                  >
                    <Typography
                      className={`${classes.reportType} ${classes.eventName}`}
                    >
                      {_.join(
                        event.organizations.map((org) => org.name),
                        ', ',
                      )}
                    </Typography>

                    <Typography
                      className={`${classes.eventName} ${classes.eventDate}`}
                    >
                      Started: {getTimeFormatted(event.created_at)}
                    </Typography>
                    <Typography
                      className={`${classes.eventName} ${classes.eventDate}`}
                    >
                      {_.join(
                        event.supervising_police_organization?.map(
                          (org) => org.name,
                        ),
                        ', ',
                      )}
                    </Typography>
                    <Typography
                      className={`${classes.eventName} ${classes.eventDate} ${
                        requestStatus === EventJoinRequestEnum.PENDING
                          ? classes.pendingColor
                          : requestStatus === EventJoinRequestEnum.REJECTED
                          ? classes.rejectColor
                          : classes.eventName
                      }`}
                    >
                      {requestStatus === EventJoinRequestEnum.PENDING ||
                      requestStatus === EventJoinRequestEnum.REJECTED
                        ? `Request ${requestStatus}`
                        : ''}
                      {actionedBy
                        ? `by ${actionedBy.first_name} ${actionedBy.last_name}`
                        : ''}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Dialog open={joinEvent}>
          <div className={`${classes.raisinBgColor} ${classes.padding}`}>
            <DialogTitle className={classes.eventName}>
              {selectedEvent?.event_join_request?.status ===
              EventJoinRequestEnum.PENDING
                ? 'Cancel Join request'
                : selectedEvent?.supervising_police_organization.filter(
                    (org) => org.id === authOrganizationId,
                  ).length ||
                  selectedEvent?.supervising_ems_organization.filter(
                    (org) => org.id === authOrganizationId,
                  ).length
                ? 'Join Active Threat'
                : 'Permission required to Join'}
            </DialogTitle>

            <DialogContent>
              <Typography
                className={`${classes.reportType} ${classes.eventName}`}
              >
                {selectedEvent?.event_join_request?.status !==
                  EventJoinRequestEnum.PENDING &&
                  _.join(
                    selectedEvent?.organizations.map((org) => org.name),
                    ', ',
                  )}
              </Typography>
              <Typography
                className={`${classes.eventName} ${classes.eventDate}`}
              >
                {selectedEvent?.event_join_request?.status !==
                  EventJoinRequestEnum.PENDING &&
                  _.join(
                    selectedEvent?.supervising_police_organization?.map(
                      (org) => org.name,
                    ),
                    ', ',
                  )}
              </Typography>
            </DialogContent>
            <DialogActions className={classes.marginBtm}>
              <CustomButton
                onClick={handleCancel}
                error={true}
                label="Cancel"
              />
              <CustomButton
                onClick={confirmJoinEvent}
                error={false}
                label={
                  selectedEvent?.event_join_request?.status ===
                  EventJoinRequestEnum.PENDING
                    ? 'Confirm'
                    : 'Join'
                }
              />
            </DialogActions>
          </div>
        </Dialog>
      </Flex>
    </div>
  );
}

export default EventListScreen;
