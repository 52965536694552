import React from 'react';
import _ from 'lodash';
import { useRouteMatch } from 'react-router';
import { useFetchOrgDetails } from 'utils/hooks';
import { MapContainer } from './MapContainer';

export const RenderMap = () => {
  const currentRoute = useRouteMatch();
  const orgId = _.get(currentRoute, 'params.id', '');

  if (orgId) {
    useFetchOrgDetails(orgId);
  }

  return <MapContainer />;
};
