import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reduce } from 'lodash';
import { EventId } from 'store/organization';
import { PointsGeoJSONType } from 'models/event/event.model';
import { AppPersonTypesEnum, AppRolesEnum } from 'utils';

export interface EventUserOrgData {
  id: string;
  identifier: string;
  name: string;
}

interface EventUserPersonTypeData {
  id: string;
  logicalName: AppPersonTypesEnum;
  name: string;
}

interface EventUserRoleData {
  id: string;
  // isSpecialEventRole: boolean;
  logicalName: AppRolesEnum;
  name: string;
}

interface EventUserData {
  id: string;
  batchNumber: string | null;
  firstName: string;
  lastName: string;
  organization: EventUserOrgData;
  personType: EventUserPersonTypeData;
  role: EventUserRoleData;
  username: string;
  phone: string;
  lastKnownLocation: PointsGeoJSONType | null;
}

export interface EventUser {
  id: string;
  archivedAt: string | null;
  event: EventId;
  user: EventUserData;
}

interface EventUserLocationUser {
  id: string;
  lastKnownLocation: PointsGeoJSONType | null;
}

export interface EventUserLocationPayload {
  id: string;
  event: EventId;
  user: EventUserLocationUser;
}

interface EventUserState {
  users: Record<string, EventUser>;
}

const initialState: EventUserState = {
  users: {},
};

export const eventUserSlice = createSlice({
  name: 'eventUser',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchEventUsers: (state, action: PayloadAction<string>) => {},
    setAllEventUsers: (state, action: PayloadAction<EventUser[]>) => {
      state.users = reduce(
        action.payload,
        (prev, eventUser) => {
          prev[eventUser.user.id] = eventUser;
          return prev;
        },
        {} as Record<string, EventUser>,
      );
    },
    addEventUser: (state, action: PayloadAction<EventUser>) => {
      state.users[action.payload.user.id] = action.payload;
    },
    removeEventUser: (state, action: PayloadAction<EventUser>) => {
      if (state.users[action.payload.user.id]) {
        delete state.users[action.payload.user.id];
      }
    },
    updateEventUserLocation: (
      state,
      action: PayloadAction<EventUserLocationPayload>,
    ) => {
      if (state.users[action.payload.user.id]) {
        state.users[action.payload.user.id].user.lastKnownLocation =
          action.payload.user.lastKnownLocation;
      }
    },
    bulkUpdateEventUserLocation: (
      state,
      action: PayloadAction<Record<string, EventUserLocationPayload>>,
    ) => {
      for (const userId in action.payload) {
        if (state.users[userId]) {
          state.users[userId].user.lastKnownLocation =
            action.payload[userId].user.lastKnownLocation;
        }
      }
    },
  },
});

export const eventUserActions = eventUserSlice.actions;

export default eventUserSlice.reducer;
