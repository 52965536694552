const DESIGN_HEIGHT = 831;
const DESIGN_WIDTH = 1440;

function getDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

const horizontalScale = (size: number): number =>
  (getDimensions().width / DESIGN_WIDTH) * size;
const verticalScale = (size: number): number =>
  (getDimensions().height / DESIGN_HEIGHT) * size;

export { horizontalScale, verticalScale };
