import { ROUTES } from 'routes/path';
import { Permission } from 'utils/commonTypes';

export const NAV_LINKS = [
  {
    key: 'organization',
    path: ROUTES.ORGANIZATION.path,
    name: 'Organization',
  },
  {
    key: 'police_department',
    path: ROUTES.POLICE_DEPT.path,
    name: 'Police Department',
  },
  {
    key: 'ems',
    path: ROUTES.EMS_ORG_LIST.path,
    name: 'EMS',
  },
  {
    key: 'special_event',
    path: ROUTES.SPECIAL_EVENT_LIST.path,
    name: 'Special Event',
  },
  {
    key: 'users',
    path: ROUTES.USERS.path,
    name: 'Users',
  },
];

export const REDUX_FORM_CONSTANT = {
  LOGIN: 'loginForm',
  ORGANIZATION: 'organizationForm',
  POLICE_USER: 'policeUserForm',
  EMS_USER: 'emsUserForm',
  ORG_USER: 'orgUserForm',
  SPECIAL_EVENT_USER: 'specialEventUser',
  POLICE_DEPT: 'policeDeptForm',
  ASSOCIATED_ORG: 'associatedOrg',
  USER_DETAILS: 'userDetailsForm',
};

export const ERROR_MSG = {
  DEFAULT: 'Something Went Wrong',
};

export const API_SERVICE_TYPES = {
  GET: 'get',
  PUT: 'put',
  POST: 'post',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const APP_CATEGORY = {
  ORGANIZATION: 'School',
  POLICE_DEPARTMENT: 'Police Department',
  EMS: 'Ems',
  SPECIAL_EVENT: 'Special Event',
};

export const DEFAULT_PAGINATION_OBJECT = {
  pages: 1,
  next: null,
  previous: null,
  current: 1,
  count: 0,
};

export const ORGANIZATION_LIST_TABLE_COLUMN = [
  {
    name: 'name',
    label: 'Name',
    key: 'name',
  },
  {
    name: 'address',
    label: 'Address',
    key: 'street_1.state.postal_code',
  },
  {
    name: 'city',
    label: 'City',
    key: 'city',
  },
  {
    name: 'phone',
    label: 'Phone',
    key: 'phone',
  },
  {
    name: 'status',
    label: 'Status',
    key: 'archived_at',
  },
];

export const USERS_TABLE_COLUMN = [
  {
    name: 'firstName',
    label: 'First Name',
    key: 'first_name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    key: 'last_name',
  },
  {
    name: 'associated',
    label: 'Associated',
    key: 'organization.name',
  },
  {
    name: 'role',
    label: 'Role',
    key: 'role.name',
  },
  {
    name: 'phone',
    label: 'Phone',
    key: 'phone',
  },
  {
    name: 'status',
    label: 'Status',
    key: 'archived_at',
    key2: 'blocked_at',
  },
];

export const ORGANIZATION_USER_LIST_TABLE_COLUMN = [
  {
    name: 'first_name',
    label: 'First Name',
    key: 'first_name',
  },
  {
    name: 'last_name',
    label: 'Last Name',
    key: 'last_name',
  },
  {
    name: 'role',
    label: 'Role',
    key: 'role.name',
  },
  {
    name: 'email',
    label: 'Email',
    key: 'username',
  },
  {
    name: 'phone',
    label: 'Phone',
    key: 'phone',
  },
  {
    name: 'status',
    label: 'Status',
    key: 'archived_at',
    key2: 'blocked_at',
  },
];

export const COMMON_ACTION_TYPES = {
  FETCH_ORGANIZATION_SPECIFIC_DETAILS: 'FETCH_ORGANIZATION_SPECIFIC_DETAILS',
};

export const DEFAULT_ACTIVE_SUB_HEADER_SECTION = {
  ORG: {
    leftSection: true,
    middleSection: false,
    rightSection: false,
  },
  POLICE_DEPT: {
    leftSection: true,
    middleSection: false,
    rightSection: false,
  },
};

export const GEO_JSON_TYPE = {
  GEO_FENCE: {
    type: 'Polygon',
    coordinates: [
      [
        [77.55744772235877, 12.99201789664637],
        [77.55351262190453, 12.99127920010587],
        [77.55616776430703, 12.990080028715337],
        [77.55744772235877, 12.99201789664637],
      ],
    ],
  },
  PERIMETER_POINTS: {
    type: 'MultiPoint',
    coordinates: [
      [77.55744772235877, 12.99201789664637],
      [77.55351262190453, 12.99127920010587],
      [77.55616776430703, 12.990080028715337],
      [77.55744772235877, 12.99201789664637],
    ],
  },
  COMMAND_POST: {
    type: 'Point',
    coordinates: [77.55489106476655, 12.990330893316171],
  },
  DEFAULT_COORDINATES: {
    lat: 77.55,
    lng: 12.99,
  },
};

export const SCOPES = {
  READ_ALL_ORG: 'org:read',
  READ_OWN_ORG_ONLY: 'org:read:own_org_only',
  CREATE_ORG: 'org:create',
  EDIT_ORG: 'org:edit',
  EDIT_OWN_ORG_ONLY: 'org:edit:own_org_only',
  DEACTIVATE_ORG: 'org:deactivate',

  READ_ALL_USERS: 'user:read',
  READ_OWN_USERS_ONLY: 'user:read:own_org_only',
  CREATE_USERS: 'user:create',
  CREATE_OWN_USERS_ONLY: 'user:create:own_org_only',
  EDIT_USER: 'user:edit',
  EDIT_OWN_USER_ONLY: 'user:edit:own_org_only',
  DEACTIVATE_USER: 'user:deactivate',
  DEACTIVATE_OWN_ONLY: 'user:deactivate:own_org_only',

  START_SE: 'event:create_se',
  EVENT_READ: 'event:read',
  EVENT_EDIT: 'event:edit',
  EVENT_EDIT_CP: 'event:edit:cp',
  EVENT_EDIT_PP: 'event:edit:pp',
  EVENT_EDIT_EMS_CP: 'event:edit:ems_cp',
  EVENT_END: 'event:end',

  REPORT_SUSPECT_READ: 'report:suspect:read',
  REPORT_INJURED_READ: 'report:injured:read',
  REPORT_SUSPECT_EDIT: 'report:suspect:edit',
  REPORT_INJURED_EDIT: 'report:injured:edit',

  PROFILE_SUSPECT_CREATE: 'profile:suspect:create',
  PROFILE_INJURED_CREATE: 'profile:injured:create',
  PROFILE_SUSPECT_READ: 'profile:suspect:read',
  PROFILE_INJURED_READ: 'profile:injured:read',
  PROFILE_SUSPECT_EDIT: 'profile:suspect:edit',
  PROFILE_INJURED_EDIT: 'profile:injured:edit',
};
// org
export const AUTH_SCOPES = `${SCOPES.READ_ALL_ORG} ${SCOPES.READ_OWN_ORG_ONLY} ${SCOPES.EVENT_READ}`;
export const READ_ORG_SCOPES = `${SCOPES.READ_ALL_ORG} ${SCOPES.READ_OWN_ORG_ONLY}`;
export const EDIT_ORG_SCOPES = `${SCOPES.EDIT_ORG} ${SCOPES.EDIT_OWN_ORG_ONLY}`;
export const CREATE_ORG_SCOPES = `${SCOPES.CREATE_ORG}`;
export const DEACTIVATE_ORG_SCOPES = `${SCOPES.DEACTIVATE_ORG}`;
//users
export const READ_USER_SCOPES = `${SCOPES.READ_ALL_USERS} ${SCOPES.READ_OWN_USERS_ONLY}`;
export const EDIT_USER_SCOPES = `${SCOPES.EDIT_USER} ${SCOPES.EDIT_OWN_USER_ONLY}`;
export const CREATE_USER_SCOPES = `${SCOPES.CREATE_USERS} ${SCOPES.CREATE_OWN_USERS_ONLY}`;
export const DEACTIVATE_USER_SCOPES = `${SCOPES.DEACTIVATE_USER} ${SCOPES.DEACTIVATE_OWN_ONLY}`;
//event
export const START_SPECIAL_EVENT_SCOPES = `${SCOPES.START_SE}`;
export const READ_EVENT_SCOPES = `${SCOPES.EVENT_READ}`;
// commanders
export const POLICE_COMMANDER_UNIQUE_SCOPES = `${SCOPES.PROFILE_SUSPECT_EDIT}`;
// ems
export const EMS_COMMANDER_UNIQUE_SCOPES = `${SCOPES.EVENT_EDIT_EMS_CP}`;

// CERA ADMIN
export const CERA_ADMIN_SCOPES = `${SCOPES.READ_ALL_ORG} ${SCOPES.EDIT_ORG} ${SCOPES.CREATE_ORG} ${SCOPES.DEACTIVATE_ORG} ${SCOPES.READ_ALL_USERS} ${SCOPES.EDIT_USER} ${SCOPES.CREATE_USERS} ${SCOPES.DEACTIVATE_USER}`;
// ORG ADMIN; POLICE_ADMIN; EMS_ADMIN; SE_ADMIN
export const ORG_ADMIN_SCOPES = `${SCOPES.READ_OWN_ORG_ONLY} ${SCOPES.EDIT_OWN_ORG_ONLY} ${SCOPES.READ_OWN_USERS_ONLY} ${SCOPES.EDIT_OWN_USER_ONLY} ${SCOPES.CREATE_OWN_USERS_ONLY} ${SCOPES.DEACTIVATE_OWN_ONLY}`;

interface PermissionType {
  CERA_ADMIN: Permission;
  EMS_ADMIN: Permission;
  ORGANIZATION_ADMIN: Permission;
  POLICE_ADMIN: Permission;
  POLICE_COMMANDER: Permission;
  EMS_COMMANDER: Permission;
}

export const STATUS = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  BLOCK_USER: 'block',
  UNBLOCK_USER: 'unblock',
};

export const ORG_FORM_KEYS = [
  'name',
  'country',
  'phone',
  'street_1',
  'street_2',
  'city',
  'state',
  'postal_code',
  'badge_number',
  'id',
];

export const USER_FORM_KEYS = [
  'first_name',
  'last_name',
  'phone',
  'batch_number',
  'id',
  'organization[id]',
  'person_type[id]',
  'person_type[logical_name]',
  'role[id]',
  'role[logical_name]',
  'username',
];

export const WARNING_MESSAGE =
  'You will lose all unsaved information if you leave this page.';

export const REPORT_TYPE = {
  SUSPECT: 'SUSPECT',
  INJURED: 'INJURED',
};

export const SUSPECT_PROERTIES_KEY = {
  gender: ['MALE', 'FEMALE'],
  height: ['SHORT', 'AVERAGE', 'TALL'],
  build: ['THIN', 'MEDIUM', 'HEAVY'],
  weapon: ['RIFLE', 'PISTOL', 'KNIFE'],
  shirt: ['SHIRT'],
  skin: ['SKIN'],
  hair: ['HAIR'],
};

export const SUSPECT_PROFILE_PROPERTY_KEY: Record<string, string[]> = {
  gender: ['MALE', 'FEMALE'],
  height: ['SHORT', 'AVERAGE', 'TALL'],
  build: ['THIN', 'MEDIUM', 'HEAVY'],
  weapon: ['RIFLE', 'PISTOL', 'KNIFE'],
};

export const SUSPECT_PROERTIES_VALUES = [
  'MALE',
  'FEMALE',
  'SHORT',
  'AVERAGE',
  'TALL',
  'THIN',
  'MEDIUM',
  'HEAVY',
  'RIFLE',
  'PISTOL',
  'KNIFE',
];
export const INJURED_PROFILE_PROPERTIES = {
  RED: 'Priority 1',
  YELLOW: 'Priority 2',
  GREEN: 'Priority 3',
};

export const FILTER_TYPES = {
  ACTIVE: 'active',
  RESOLVED: 'resolved',
  ALL: 'all',
};

export const ORGANIZATION_DOMAIN_USER_LIST_TABLE_COLUMN = [
  {
    name: 'email',
    label: 'Email',
    key: 'username',
  },
  {
    name: 'role',
    label: 'Role',
    key: 'role.name',
  },
  {
    name: 'status',
    label: 'Status',
    key: 'archived_at',
    key2: 'blocked_at',
  },
];
