type provinces = {
  name: string;
};

type State = {
  countryName: string;
  provinces: provinces[] | null;
};

type StateObj = {
  [key: string]: State;
};

export const STATE_DATA: StateObj = {
  Andorra: {
    countryName: 'Andorra',
    provinces: [
      {
        name: 'Sant Julià de Loria',
      },
      {
        name: 'Ordino',
      },
      {
        name: 'La Massana',
      },
      {
        name: 'Encamp',
      },
      {
        name: 'Canillo',
      },
      {
        name: 'Andorra la Vella',
      },
      {
        name: 'Escaldes-Engordany',
      },
    ],
  },
  'United Arab Emirates': {
    countryName: 'United Arab Emirates',
    provinces: [
      {
        name: 'Imārat Umm al Qaywayn',
      },
      {
        name: 'Raʼs al Khaymah',
      },
      {
        name: 'Dubai',
      },
      {
        name: 'Sharjah',
      },
      {
        name: 'Fujairah',
      },
      {
        name: 'Ajman',
      },
      {
        name: 'Abu Dhabi',
      },
    ],
  },
  Afghanistan: {
    countryName: 'Afghanistan',
    provinces: [
      {
        name: 'Zabul',
      },
      {
        name: 'Vardak',
      },
      {
        name: 'Takhar',
      },
      {
        name: 'Sar-e Pol',
      },
      {
        name: 'Samangan',
      },
      {
        name: 'Parwan',
      },
      {
        name: 'Paktika',
      },
      {
        name: 'Paktia',
      },
      {
        name: 'Oruzgan',
      },
      {
        name: 'Nimroz',
      },
      {
        name: 'Nangarhar',
      },
      {
        name: 'Logar',
      },
      {
        name: 'Laghman',
      },
      {
        name: 'Kunduz',
      },
      {
        name: 'Kunar',
      },
      {
        name: 'Kapisa',
      },
      {
        name: 'Kandahar',
      },
      {
        name: 'Kabul',
      },
      {
        name: 'Jowzjan',
      },
      {
        name: 'Herat',
      },
      {
        name: 'Helmand',
      },
      {
        name: 'Ghowr',
      },
      {
        name: 'Ghazni',
      },
      {
        name: 'Faryab',
      },
      {
        name: 'Farah',
      },
      {
        name: 'Bamyan',
      },
      {
        name: 'Balkh',
      },
      {
        name: 'Baghlan',
      },
      {
        name: 'Badghis',
      },
      {
        name: 'Badakhshan',
      },
      {
        name: 'Khowst',
      },
      {
        name: 'Nuristan',
      },
      {
        name: 'Daykundi',
      },
      {
        name: 'Panjshir',
      },
    ],
  },
  'Antigua and Barbuda': {
    countryName: 'Antigua and Barbuda',
    provinces: [
      {
        name: 'Saint Philip',
      },
      {
        name: 'Saint Peter',
      },
      {
        name: 'Saint Paul',
      },
      {
        name: 'Saint Mary',
      },
      {
        name: 'Saint John',
      },
      {
        name: 'Saint George',
      },
      {
        name: 'Redonda',
      },
      {
        name: 'Barbuda',
      },
    ],
  },
  Anguilla: {
    countryName: 'Anguilla',
    provinces: [
      {
        name: 'Blowing Point',
      },
      {
        name: 'Sandy Ground',
      },
      {
        name: 'Sandy Hill',
      },
      {
        name: 'The Valley',
      },
      {
        name: 'East End',
      },
      {
        name: 'North Hill',
      },
      {
        name: 'West End',
      },
      {
        name: 'South Hill',
      },
      {
        name: 'The Quarter',
      },
      {
        name: 'North Side',
      },
      {
        name: 'Island Harbour',
      },
      {
        name: 'George Hill',
      },
      {
        name: 'Stoney Ground',
      },
      {
        name: 'The Farrington',
      },
    ],
  },
  Albania: {
    countryName: 'Albania',
    provinces: [
      {
        name: 'Berat',
      },
      {
        name: 'Dibër',
      },
      {
        name: 'Elbasan',
      },
      {
        name: 'Gjirokastër',
      },
      {
        name: 'Korçë',
      },
      {
        name: 'Kukës',
      },
      {
        name: 'Durrës',
      },
      {
        name: 'Fier',
      },
      {
        name: 'Lezhë',
      },
      {
        name: 'Shkodër',
      },
      {
        name: 'Tirana',
      },
      {
        name: 'Vlorë',
      },
    ],
  },
  Armenia: {
    countryName: 'Armenia',
    provinces: [
      {
        name: 'Ararat',
      },
      {
        name: 'Syunik',
      },
      {
        name: 'Vayots Dzor',
      },
      {
        name: 'Yerevan',
      },
      {
        name: 'Aragatsotn',
      },
      {
        name: 'Armavir',
      },
      {
        name: 'Gegharkunik',
      },
      {
        name: 'Kotayk',
      },
      {
        name: 'Lori',
      },
      {
        name: 'Shirak',
      },
      {
        name: 'Tavush',
      },
    ],
  },
  Angola: {
    countryName: 'Angola',
    provinces: [
      {
        name: 'Lunda Sul',
      },
      {
        name: 'Luanda Norte',
      },
      {
        name: 'Moxico',
      },
      {
        name: 'Cuando Cobango',
      },
      {
        name: 'Zaire',
      },
      {
        name: 'Uíge',
      },
      {
        name: 'Malanje',
      },
      {
        name: 'Luanda',
      },
      {
        name: 'Cuanza Norte',
      },
      {
        name: 'Cabinda',
      },
      {
        name: 'Bengo',
      },
      {
        name: 'Namibe',
      },
      {
        name: 'Huíla',
      },
      {
        name: 'Huambo',
      },
      {
        name: 'Cunene',
      },
      {
        name: 'Kwanza Sul',
      },
      {
        name: 'Bíe',
      },
      {
        name: 'Benguela',
      },
    ],
  },
  Antarctica: {
    countryName: 'Antarctica',
    provinces: null,
  },
  Argentina: {
    countryName: 'Argentina',
    provinces: [
      {
        name: 'Misiones',
      },
      {
        name: 'Formosa',
      },
      {
        name: 'Buenos Aires F.D.',
      },
      {
        name: 'Entre Rios',
      },
      {
        name: 'Corrientes',
      },
      {
        name: 'Buenos Aires',
      },
      {
        name: 'Tucuman',
      },
      {
        name: 'Tierra del Fuego',
      },
      {
        name: 'Santiago del Estero',
      },
      {
        name: 'Santa Fe',
      },
      {
        name: 'Santa Cruz',
      },
      {
        name: 'San Luis',
      },
      {
        name: 'San Juan',
      },
      {
        name: 'Salta',
      },
      {
        name: 'Rio Negro',
      },
      {
        name: 'Neuquen',
      },
      {
        name: 'Mendoza',
      },
      {
        name: 'La Rioja',
      },
      {
        name: 'La Pampa',
      },
      {
        name: 'Jujuy',
      },
      {
        name: 'Cordoba',
      },
      {
        name: 'Chubut',
      },
      {
        name: 'Chaco',
      },
      {
        name: 'Catamarca',
      },
    ],
  },
  'American Samoa': {
    countryName: 'American Samoa',
    provinces: [
      {
        name: 'Western District',
      },
      {
        name: 'Swains Island',
      },
      {
        name: 'Eastern District',
      },
      {
        name: "Manu'a",
      },
      {
        name: 'Rose Island',
      },
    ],
  },
  Austria: {
    countryName: 'Austria',
    provinces: [
      {
        name: 'Vienna',
      },
      {
        name: 'Vorarlberg',
      },
      {
        name: 'Tyrol',
      },
      {
        name: 'Styria',
      },
      {
        name: 'Salzburg',
      },
      {
        name: 'Upper Austria',
      },
      {
        name: 'Lower Austria',
      },
      {
        name: 'Carinthia',
      },
      {
        name: 'Burgenland',
      },
    ],
  },
  Australia: {
    countryName: 'Australia',
    provinces: [
      {
        name: 'Western Australia',
      },
      {
        name: 'South Australia',
      },
      {
        name: 'Northern Territory',
      },
      {
        name: 'Victoria',
      },
      {
        name: 'Tasmania',
      },
      {
        name: 'Queensland',
      },
      {
        name: 'New South Wales',
      },
      {
        name: 'Australian Capital Territory',
      },
    ],
  },
  Aruba: {
    countryName: 'Aruba',
    provinces: null,
  },
  'Aland Islands': {
    countryName: 'Aland Islands',
    provinces: [
      {
        name: 'Mariehamns stad',
      },
      {
        name: 'Ålands landsbygd',
      },
      {
        name: 'Ålands skärgård',
      },
    ],
  },
  Azerbaijan: {
    countryName: 'Azerbaijan',
    provinces: [
      {
        name: 'Beyləqan',
      },
      {
        name: 'Zǝngilan',
      },
      {
        name: 'Yardımlı',
      },
      {
        name: 'Shusha',
      },
      {
        name: 'Salyan',
      },
      {
        name: 'Sabirabad',
      },
      {
        name: 'Saatlı',
      },
      {
        name: 'Bilǝsuvar',
      },
      {
        name: 'Neftçala',
      },
      {
        name: 'Nakhichevan',
      },
      {
        name: 'Masally',
      },
      {
        name: 'Lerik',
      },
      {
        name: 'Lənkəran',
      },
      {
        name: 'Laçın',
      },
      {
        name: 'Qubadlı',
      },
      {
        name: 'İmişli',
      },
      {
        name: 'Füzuli',
      },
      {
        name: 'Jabrayil',
      },
      {
        name: 'Jalilabad',
      },
      {
        name: 'Astara',
      },
      {
        name: 'Xocalı',
      },
      {
        name: 'Ağcabǝdi',
      },
      {
        name: 'Ağdam',
      },
      {
        name: 'Shirvan',
      },
      {
        name: 'Lankaran Sahari',
      },
      {
        name: 'Shusha City',
      },
      {
        name: 'Tǝrtǝr',
      },
      {
        name: 'Xankǝndi',
      },
      {
        name: 'Khojavend',
      },
      {
        name: 'Zərdab',
      },
      {
        name: 'Zaqatala',
      },
      {
        name: 'Yevlax',
      },
      {
        name: 'Oğuz',
      },
      {
        name: 'Ucar',
      },
      {
        name: 'Tovuz',
      },
      {
        name: 'Şamaxı',
      },
      {
        name: 'Shaki',
      },
      {
        name: 'Şǝmkir',
      },
      {
        name: 'Kürdǝmir',
      },
      {
        name: 'Qǝbǝlǝ',
      },
      {
        name: 'Qusar',
      },
      {
        name: 'Quba',
      },
      {
        name: 'Goygol Rayon',
      },
      {
        name: 'Xaçmaz',
      },
      {
        name: 'Kalbajar',
      },
      {
        name: 'Qazax',
      },
      {
        name: 'Goranboy',
      },
      {
        name: 'Qax',
      },
      {
        name: 'İsmayıllı',
      },
      {
        name: 'Göyçay',
      },
      {
        name: 'Shabran',
      },
      {
        name: 'Daşkǝsǝn',
      },
      {
        name: 'Balakǝn',
      },
      {
        name: 'Barda',
      },
      {
        name: 'Baki',
      },
      {
        name: 'Abşeron',
      },
      {
        name: 'Ağsu',
      },
      {
        name: 'Ağdaş',
      },
      {
        name: 'Gǝdǝbǝy',
      },
      {
        name: 'Ağstafa',
      },
      {
        name: 'Gǝncǝ',
      },
      {
        name: 'Mingǝcevir',
      },
      {
        name: 'Naftalan',
      },
      {
        name: 'Qobustan',
      },
      {
        name: 'Samux',
      },
      {
        name: 'Shaki City',
      },
      {
        name: 'Siyǝzǝn',
      },
      {
        name: 'Sumqayit',
      },
      {
        name: 'Xızı',
      },
      {
        name: 'Yevlax City',
      },
      {
        name: 'Hacıqabul',
      },
    ],
  },
  'Bosnia and Herzegovina': {
    countryName: 'Bosnia and Herzegovina',
    provinces: [
      {
        name: 'Federation of B&H',
      },
      {
        name: 'Srpska',
      },
      {
        name: 'Brčko',
      },
    ],
  },
  Barbados: {
    countryName: 'Barbados',
    provinces: [
      {
        name: 'Saint Thomas',
      },
      {
        name: 'Saint Philip',
      },
      {
        name: 'Saint Peter',
      },
      {
        name: 'Saint Michael',
      },
      {
        name: 'Saint Lucy',
      },
      {
        name: 'Saint Joseph',
      },
      {
        name: 'Saint John',
      },
      {
        name: 'Saint James',
      },
      {
        name: 'Saint George',
      },
      {
        name: 'Saint Andrew',
      },
      {
        name: 'Christ Church',
      },
    ],
  },
  Bangladesh: {
    countryName: 'Bangladesh',
    provinces: [
      {
        name: 'Rajshahi Division',
      },
      {
        name: 'Dhaka',
      },
      {
        name: 'Chittagong',
      },
      {
        name: 'Khulna',
      },
      {
        name: 'Barisāl',
      },
      {
        name: 'Sylhet',
      },
      {
        name: 'Rangpur Division',
      },
      {
        name: 'Mymensingh Division',
      },
    ],
  },
  Belgium: {
    countryName: 'Belgium',
    provinces: [
      {
        name: 'Brussels Capital',
      },
      {
        name: 'Wallonia',
      },
      {
        name: 'Flanders',
      },
    ],
  },
  'Burkina Faso': {
    countryName: 'Burkina Faso',
    provinces: [
      {
        name: 'Boucle du Mouhoun',
      },
      {
        name: 'Cascades',
      },
      {
        name: 'Centre',
      },
      {
        name: 'Centre-Est',
      },
      {
        name: 'Centre-Nord',
      },
      {
        name: 'Centre-Ouest',
      },
      {
        name: 'Centre-Sud',
      },
      {
        name: 'Est',
      },
      {
        name: 'Hauts-Bassins',
      },
      {
        name: 'Nord',
      },
      {
        name: 'Plateau-Central',
      },
      {
        name: 'Sahel',
      },
      {
        name: 'Sud-Ouest',
      },
    ],
  },
  Bulgaria: {
    countryName: 'Bulgaria',
    provinces: [
      {
        name: 'Razgrad',
      },
      {
        name: 'Montana',
      },
      {
        name: 'Vratsa',
      },
      {
        name: 'Varna',
      },
      {
        name: 'Dobrich',
      },
      {
        name: 'Sofia',
      },
      {
        name: 'Ruse',
      },
      {
        name: 'Plovdiv',
      },
      {
        name: 'Pleven',
      },
      {
        name: 'Pernik',
      },
      {
        name: 'Pazardzhik',
      },
      {
        name: 'Lovech',
      },
      {
        name: 'Haskovo',
      },
      {
        name: 'Sofia-Capital',
      },
      {
        name: 'Burgas',
      },
      {
        name: 'Blagoevgrad',
      },
      {
        name: 'Gabrovo',
      },
      {
        name: 'Kardzhali',
      },
      {
        name: 'Kyustendil',
      },
      {
        name: 'Shumen',
      },
      {
        name: 'Silistra',
      },
      {
        name: 'Sliven',
      },
      {
        name: 'Smolyan',
      },
      {
        name: 'Stara Zagora',
      },
      {
        name: 'Targovishte',
      },
      {
        name: 'Veliko Tarnovo',
      },
      {
        name: 'Vidin',
      },
      {
        name: 'Yambol',
      },
    ],
  },
  Bahrain: {
    countryName: 'Bahrain',
    provinces: [
      {
        name: 'Muharraq',
      },
      {
        name: 'Manama',
      },
      {
        name: 'Southern Governorate',
      },
      {
        name: 'Northern',
      },
    ],
  },
  Burundi: {
    countryName: 'Burundi',
    provinces: [
      {
        name: 'Makamba',
      },
      {
        name: 'Bururi',
      },
      {
        name: 'Muramvya',
      },
      {
        name: 'Gitega',
      },
      {
        name: 'Ruyigi',
      },
      {
        name: 'Cankuzo',
      },
      {
        name: 'Karuzi',
      },
      {
        name: 'Bubanza',
      },
      {
        name: 'Cibitoke',
      },
      {
        name: 'Ngozi',
      },
      {
        name: 'Kayanza',
      },
      {
        name: 'Muyinga',
      },
      {
        name: 'Kirundo',
      },
      {
        name: 'Rutana',
      },
      {
        name: 'Mwaro',
      },
      {
        name: 'Bujumbura Mairie',
      },
      {
        name: 'Bujumbura Rural',
      },
      {
        name: 'Rumonge',
      },
    ],
  },
  Benin: {
    countryName: 'Benin',
    provinces: [
      {
        name: 'Zou',
      },
      {
        name: 'Ouémé',
      },
      {
        name: 'Mono',
      },
      {
        name: 'Borgou',
      },
      {
        name: 'Atlantique',
      },
      {
        name: 'Atakora',
      },
      {
        name: 'Alibori',
      },
      {
        name: 'Collines',
      },
      {
        name: 'Kouffo',
      },
      {
        name: 'Donga',
      },
      {
        name: 'Littoral',
      },
      {
        name: 'Plateau',
      },
    ],
  },
  'Saint Barthelemy': {
    countryName: 'Saint Barthelemy',
    provinces: null,
  },
  Bermuda: {
    countryName: 'Bermuda',
    provinces: [
      {
        name: 'Warwick',
      },
      {
        name: 'Southampton',
      },
      {
        name: 'Smithʼs',
      },
      {
        name: 'Sandys',
      },
      {
        name: 'Saint Georgeʼs',
      },
      {
        name: 'Saint George',
      },
      {
        name: 'Pembroke',
      },
      {
        name: 'Paget',
      },
      {
        name: 'Hamilton',
      },
      {
        name: 'Hamilton city',
      },
      {
        name: 'Devonshire',
      },
    ],
  },
  Brunei: {
    countryName: 'Brunei',
    provinces: [
      {
        name: 'Tutong',
      },
      {
        name: 'Temburong',
      },
      {
        name: 'Brunei-Muara District',
      },
      {
        name: 'Belait',
      },
    ],
  },
  Bolivia: {
    countryName: 'Bolivia',
    provinces: [
      {
        name: 'Tarija',
      },
      {
        name: 'Santa Cruz',
      },
      {
        name: 'Potosí',
      },
      {
        name: 'Pando',
      },
      {
        name: 'Oruro',
      },
      {
        name: 'La Paz',
      },
      {
        name: 'Cochabamba',
      },
      {
        name: 'Chuquisaca',
      },
      {
        name: 'El Beni',
      },
    ],
  },
  'Bonaire, Saint Eustatius and Saba': {
    countryName: 'Bonaire, Saint Eustatius and Saba',
    provinces: [
      {
        name: 'Bonaire',
      },
      {
        name: 'Saba',
      },
      {
        name: 'Sint Eustatius',
      },
    ],
  },
  Brazil: {
    countryName: 'Brazil',
    provinces: [
      {
        name: 'Rio Grande do Norte',
      },
      {
        name: 'Piauí',
      },
      {
        name: 'Pernambuco',
      },
      {
        name: 'Paraíba',
      },
      {
        name: 'Pará',
      },
      {
        name: 'Maranhão',
      },
      {
        name: 'Ceará',
      },
      {
        name: 'Amapá',
      },
      {
        name: 'Alagoas',
      },
      {
        name: 'Sergipe',
      },
      {
        name: 'São Paulo',
      },
      {
        name: 'Santa Catarina',
      },
      {
        name: 'Rio Grande do Sul',
      },
      {
        name: 'Rio de Janeiro',
      },
      {
        name: 'Paraná',
      },
      {
        name: 'Minas Gerais',
      },
      {
        name: 'Mato Grosso do Sul',
      },
      {
        name: 'Mato Grosso',
      },
      {
        name: 'Goiás',
      },
      {
        name: 'Federal District',
      },
      {
        name: 'Espírito Santo',
      },
      {
        name: 'Bahia',
      },
      {
        name: 'Tocantins',
      },
      {
        name: 'Roraima',
      },
      {
        name: 'Amazonas',
      },
      {
        name: 'Acre',
      },
      {
        name: 'Rondônia',
      },
    ],
  },
  Bahamas: {
    countryName: 'Bahamas',
    provinces: [
      {
        name: 'San Salvador',
      },
      {
        name: 'Ragged Island',
      },
      {
        name: 'Berry Islands',
      },
      {
        name: 'New Providence',
      },
      {
        name: 'Mayaguana',
      },
      {
        name: 'Long Island',
      },
      {
        name: 'Inagua',
      },
      {
        name: 'Harbour Island',
      },
      {
        name: 'Freeport',
      },
      {
        name: 'Exuma',
      },
      {
        name: 'Cat Island',
      },
      {
        name: 'Bimini',
      },
      {
        name: 'Acklins',
      },
      {
        name: 'Black Point',
      },
      {
        name: 'Central Abaco',
      },
      {
        name: 'Central Andros',
      },
      {
        name: 'Central Eleuthera',
      },
      {
        name: 'Crooked Island and Long Cay',
      },
      {
        name: 'East Grand Bahama',
      },
      {
        name: 'Grand Cay',
      },
      {
        name: 'Hope Town',
      },
      {
        name: 'Mangrove Cay',
      },
      {
        name: 'Moore’s Island',
      },
      {
        name: 'North Abaco',
      },
      {
        name: 'North Andros',
      },
      {
        name: 'North Eleuthera',
      },
      {
        name: 'Rum Cay',
      },
      {
        name: 'South Abaco',
      },
      {
        name: 'South Andros',
      },
      {
        name: 'South Eleuthera',
      },
      {
        name: 'Spanish Wells',
      },
      {
        name: 'West Grand Bahama',
      },
    ],
  },
  Bhutan: {
    countryName: 'Bhutan',
    provinces: [
      {
        name: 'Bumthang',
      },
      {
        name: 'Chukha',
      },
      {
        name: 'Dagana',
      },
      {
        name: 'Chirang',
      },
      {
        name: 'Geylegphug',
      },
      {
        name: 'Haa',
      },
      {
        name: 'Lhuntse',
      },
      {
        name: 'Mongar',
      },
      {
        name: 'Paro',
      },
      {
        name: 'Pemagatshel',
      },
      {
        name: 'Punakha',
      },
      {
        name: 'Samchi',
      },
      {
        name: 'Samdrup Jongkhar',
      },
      {
        name: 'Shemgang',
      },
      {
        name: 'Tashigang',
      },
      {
        name: 'Thimphu',
      },
      {
        name: 'Tongsa',
      },
      {
        name: 'Wangdi Phodrang',
      },
      {
        name: 'Gasa',
      },
      {
        name: 'Trashi Yangste',
      },
    ],
  },
  'Bouvet Island': {
    countryName: 'Bouvet Island',
    provinces: null,
  },
  Botswana: {
    countryName: 'Botswana',
    provinces: [
      {
        name: 'Ngwaketsi',
      },
      {
        name: 'South-East',
      },
      {
        name: 'North-East',
      },
      {
        name: 'North-West',
      },
      {
        name: 'Kweneng',
      },
      {
        name: 'Kgatleng',
      },
      {
        name: 'Kgalagadi',
      },
      {
        name: 'Ghanzi',
      },
      {
        name: 'Chobe',
      },
      {
        name: 'Central',
      },
      {
        name: 'City of Francistown',
      },
      {
        name: 'Gaborone',
      },
      {
        name: 'Jwaneng',
      },
      {
        name: 'Lobatse',
      },
      {
        name: 'Selibe Phikwe',
      },
      {
        name: 'Sowa Town',
      },
    ],
  },
  Belarus: {
    countryName: 'Belarus',
    provinces: [
      {
        name: 'Vitebsk',
      },
      {
        name: 'Mogilev',
      },
      {
        name: 'Minsk',
      },
      {
        name: 'Minsk City',
      },
      {
        name: 'Grodnenskaya',
      },
      {
        name: 'Gomel Oblast',
      },
      {
        name: 'Brest',
      },
    ],
  },
  Belize: {
    countryName: 'Belize',
    provinces: [
      {
        name: 'Toledo',
      },
      {
        name: 'Stann Creek',
      },
      {
        name: 'Orange Walk',
      },
      {
        name: 'Corozal',
      },
      {
        name: 'Cayo',
      },
      {
        name: 'Belize',
      },
    ],
  },
  Canada: {
    countryName: 'Canada',
    provinces: [
      {
        name: 'Alberta',
      },
      {
        name: 'British Columbia',
      },
      {
        name: 'Manitoba',
      },
      {
        name: 'New Brunswick',
      },
      {
        name: 'Northwest Territories',
      },
      {
        name: 'Nova Scotia',
      },
      {
        name: 'Nunavut',
      },
      {
        name: 'Ontario',
      },
      {
        name: 'Prince Edward Island',
      },
      {
        name: 'Quebec',
      },
      {
        name: 'Saskatchewan',
      },
      {
        name: 'Yukon',
      },
      {
        name: 'Newfoundland and Labrador',
      },
    ],
  },
  'Cocos Islands': {
    countryName: 'Cocos Islands',
    provinces: null,
  },
  'Democratic Republic of the Congo': {
    countryName: 'Democratic Republic of the Congo',
    provinces: [
      {
        name: 'Tshuapa',
      },
      {
        name: 'Tshopo',
      },
      {
        name: 'Tanganyika',
      },
      {
        name: 'South Kivu',
      },
      {
        name: 'Sankuru',
      },
      {
        name: 'Nord Kivu',
      },
      {
        name: 'Mongala',
      },
      {
        name: 'Maniema',
      },
      {
        name: 'Kasai-Central',
      },
      {
        name: 'Kasaï-Oriental',
      },
      {
        name: 'Kasai',
      },
      {
        name: 'Ituri',
      },
      {
        name: 'Haut-Uele',
      },
      {
        name: 'Haut-Lomami',
      },
      {
        name: 'Équateur',
      },
      {
        name: 'Bas-Uele',
      },
      {
        name: 'Lualaba',
      },
      {
        name: 'Mai-Ndombe',
      },
      {
        name: 'Kwilu',
      },
      {
        name: 'Kwango',
      },
      {
        name: 'Kinshasa',
      },
      {
        name: 'Bas-Congo',
      },
      {
        name: 'Haut-Katanga',
      },
      {
        name: 'Lomami',
      },
      {
        name: 'Nord-Ubangi',
      },
      {
        name: 'Sud-Ubangi',
      },
    ],
  },
  'Central African Republic': {
    countryName: 'Central African Republic',
    provinces: [
      {
        name: 'Vakaga',
      },
      {
        name: 'Ouaka',
      },
      {
        name: 'Mbomou',
      },
      {
        name: 'Haut-Mbomou',
      },
      {
        name: 'Haute-Kotto',
      },
      {
        name: 'Basse-Kotto',
      },
      {
        name: 'Bamingui-Bangoran',
      },
      {
        name: 'Sangha-Mbaéré',
      },
      {
        name: 'Ouham-Pendé',
      },
      {
        name: 'Ouham',
      },
      {
        name: "Ombella-M'Poko",
      },
      {
        name: 'Nana-Mambéré',
      },
      {
        name: 'Lobaye',
      },
      {
        name: 'Kémo',
      },
      {
        name: 'Mambéré-Kadéï',
      },
      {
        name: 'Nana-Grébizi',
      },
      {
        name: 'Bangui',
      },
    ],
  },
  'Republic of the Congo': {
    countryName: 'Republic of the Congo',
    provinces: [
      {
        name: 'Sangha',
      },
      {
        name: 'Pool',
      },
      {
        name: 'Plateaux',
      },
      {
        name: 'Niari',
      },
      {
        name: 'Likouala',
      },
      {
        name: 'Lékoumou',
      },
      {
        name: 'Kouilou',
      },
      {
        name: 'Cuvette',
      },
      {
        name: 'Bouenza',
      },
      {
        name: 'Brazzaville',
      },
      {
        name: 'Cuvette-Ouest',
      },
      {
        name: 'Pointe-Noire',
      },
    ],
  },
  Switzerland: {
    countryName: 'Switzerland',
    provinces: [
      {
        name: 'Zurich',
      },
      {
        name: 'Zug',
      },
      {
        name: 'Vaud',
      },
      {
        name: 'Valais',
      },
      {
        name: 'Uri',
      },
      {
        name: 'Ticino',
      },
      {
        name: 'Thurgau',
      },
      {
        name: 'Solothurn',
      },
      {
        name: 'Schwyz',
      },
      {
        name: 'Schaffhausen',
      },
      {
        name: 'Saint Gallen',
      },
      {
        name: 'Obwalden',
      },
      {
        name: 'Nidwalden',
      },
      {
        name: 'Neuchâtel',
      },
      {
        name: 'Lucerne',
      },
      {
        name: 'Jura',
      },
      {
        name: 'Grisons',
      },
      {
        name: 'Glarus',
      },
      {
        name: 'Geneva',
      },
      {
        name: 'Fribourg',
      },
      {
        name: 'Bern',
      },
      {
        name: 'Basel-City',
      },
      {
        name: 'Basel-Landschaft',
      },
      {
        name: 'Appenzell Ausserrhoden',
      },
      {
        name: 'Appenzell Innerrhoden',
      },
      {
        name: 'Aargau',
      },
    ],
  },
  'Ivory Coast': {
    countryName: 'Ivory Coast',
    provinces: [
      {
        name: 'Yamoussoukro',
      },
      {
        name: 'Bas-Sassandra',
      },
      {
        name: 'Comoé',
      },
      {
        name: 'Denguélé',
      },
      {
        name: 'Gôh-Djiboua',
      },
      {
        name: 'Lacs',
      },
      {
        name: 'Lagunes',
      },
      {
        name: 'Montagnes',
      },
      {
        name: 'Sassandra-Marahoué',
      },
      {
        name: 'Savanes',
      },
      {
        name: 'Vallée du Bandama',
      },
      {
        name: 'Woroba',
      },
      {
        name: 'Zanzan',
      },
      {
        name: 'Abidjan',
      },
    ],
  },
  'Cook Islands': {
    countryName: 'Cook Islands',
    provinces: [
      {
        name: 'Aitutaki',
      },
      {
        name: 'Atiu',
      },
      {
        name: 'Mangaia',
      },
      {
        name: 'Manihiki',
      },
      {
        name: "Ma'uke",
      },
      {
        name: 'Mitiaro',
      },
      {
        name: 'Palmerston',
      },
      {
        name: 'Penrhyn',
      },
      {
        name: 'Pukapuka',
      },
      {
        name: 'Rakahanga',
      },
      {
        name: 'Rarotonga',
      },
    ],
  },
  Chile: {
    countryName: 'Chile',
    provinces: [
      {
        name: 'Valparaíso',
      },
      {
        name: 'Tarapacá',
      },
      {
        name: 'Santiago Metropolitan',
      },
      {
        name: 'Maule Region',
      },
      {
        name: 'Los Lagos Region',
      },
      {
        name: "O'Higgins Region",
      },
      {
        name: 'Coquimbo Region',
      },
      {
        name: 'Biobío',
      },
      {
        name: 'Atacama',
      },
      {
        name: 'Araucanía',
      },
      {
        name: 'Antofagasta',
      },
      {
        name: 'Aysén',
      },
      {
        name: 'Region of Magallanes',
      },
      {
        name: 'Arica y Parinacota',
      },
      {
        name: 'Los Ríos Region',
      },
      {
        name: 'Ñuble',
      },
    ],
  },
  Cameroon: {
    countryName: 'Cameroon',
    provinces: [
      {
        name: 'South-West',
      },
      {
        name: 'South',
      },
      {
        name: 'West',
      },
      {
        name: 'North-West',
      },
      {
        name: 'North',
      },
      {
        name: 'Littoral',
      },
      {
        name: 'Far North',
      },
      {
        name: 'East',
      },
      {
        name: 'Centre',
      },
      {
        name: 'Adamaoua',
      },
    ],
  },
  China: {
    countryName: 'China',
    provinces: [
      {
        name: 'Tibet',
      },
      {
        name: 'Qinghai',
      },
      {
        name: 'Xinjiang',
      },
      {
        name: 'Zhejiang',
      },
      {
        name: 'Yunnan',
      },
      {
        name: 'Tianjin',
      },
      {
        name: 'Sichuan',
      },
      {
        name: 'Shanxi',
      },
      {
        name: 'Shanghai',
      },
      {
        name: 'Shandong',
      },
      {
        name: 'Shaanxi',
      },
      {
        name: 'Ningxia Hui Autonomous Region',
      },
      {
        name: 'Jiangxi',
      },
      {
        name: 'Jiangsu',
      },
      {
        name: 'Hunan',
      },
      {
        name: 'Hubei',
      },
      {
        name: 'Henan',
      },
      {
        name: 'Hebei',
      },
      {
        name: 'Hainan',
      },
      {
        name: 'Guizhou',
      },
      {
        name: 'Guangxi',
      },
      {
        name: 'Guangdong',
      },
      {
        name: 'Gansu',
      },
      {
        name: 'Fujian',
      },
      {
        name: 'Chongqing',
      },
      {
        name: 'Anhui',
      },
      {
        name: 'Inner Mongolia',
      },
      {
        name: 'Liaoning',
      },
      {
        name: 'Jilin',
      },
      {
        name: 'Heilongjiang',
      },
      {
        name: 'Beijing',
      },
    ],
  },
  Colombia: {
    countryName: 'Colombia',
    provinces: [
      {
        name: 'Vichada',
      },
      {
        name: 'Vaupés',
      },
      {
        name: 'Valle del Cauca',
      },
      {
        name: 'Tolima',
      },
      {
        name: 'Sucre',
      },
      {
        name: 'Santander',
      },
      {
        name: 'San Andres y Providencia',
      },
      {
        name: 'Risaralda',
      },
      {
        name: 'Quindío',
      },
      {
        name: 'Putumayo',
      },
      {
        name: 'Norte de Santander',
      },
      {
        name: 'Nariño',
      },
      {
        name: 'Meta',
      },
      {
        name: 'Magdalena',
      },
      {
        name: 'La Guajira',
      },
      {
        name: 'Huila',
      },
      {
        name: 'Guaviare',
      },
      {
        name: 'Guainía',
      },
      {
        name: 'Cundinamarca',
      },
      {
        name: 'Córdoba',
      },
      {
        name: 'Chocó',
      },
      {
        name: 'Cesar',
      },
      {
        name: 'Cauca',
      },
      {
        name: 'Casanare',
      },
      {
        name: 'Caquetá',
      },
      {
        name: 'Caldas',
      },
      {
        name: 'Boyacá',
      },
      {
        name: 'Bolívar',
      },
      {
        name: 'Bogota D.C.',
      },
      {
        name: 'Atlántico',
      },
      {
        name: 'Arauca',
      },
      {
        name: 'Antioquia',
      },
      {
        name: 'Amazonas',
      },
    ],
  },
  'Costa Rica': {
    countryName: 'Costa Rica',
    provinces: [
      {
        name: 'San José',
      },
      {
        name: 'Puntarenas',
      },
      {
        name: 'Limón',
      },
      {
        name: 'Heredia',
      },
      {
        name: 'Guanacaste',
      },
      {
        name: 'Cartago',
      },
      {
        name: 'Alajuela',
      },
    ],
  },
  Cuba: {
    countryName: 'Cuba',
    provinces: [
      {
        name: 'Villa Clara',
      },
      {
        name: 'Santiago de Cuba',
      },
      {
        name: 'Sancti Spíritus',
      },
      {
        name: 'Pinar del Río',
      },
      {
        name: 'Matanzas',
      },
      {
        name: 'Las Tunas',
      },
      {
        name: 'Isla de la Juventud',
      },
      {
        name: 'Holguín',
      },
      {
        name: 'Guantánamo',
      },
      {
        name: 'Granma',
      },
      {
        name: 'Havana',
      },
      {
        name: 'Cienfuegos',
      },
      {
        name: 'Ciego de Ávila',
      },
      {
        name: 'Camagüey',
      },
      {
        name: 'Artemisa',
      },
      {
        name: 'Mayabeque',
      },
    ],
  },
  'Cabo Verde': {
    countryName: 'Cabo Verde',
    provinces: [
      {
        name: 'Tarrafal',
      },
      {
        name: 'São Vicente',
      },
      {
        name: 'Santa Catarina',
      },
      {
        name: 'Sal',
      },
      {
        name: 'Ribeira Grande',
      },
      {
        name: 'Praia',
      },
      {
        name: 'Paul',
      },
      {
        name: 'Maio',
      },
      {
        name: 'Brava',
      },
      {
        name: 'Boa Vista',
      },
      {
        name: 'Mosteiros',
      },
      {
        name: 'Santa Cruz',
      },
      {
        name: 'São Domingos',
      },
      {
        name: 'São Filipe',
      },
      {
        name: 'São Miguel',
      },
      {
        name: 'Porto Novo',
      },
      {
        name: 'Ribeira Brava',
      },
      {
        name: 'Santa Catarina do Fogo',
      },
      {
        name: 'São Salvador do Mundo',
      },
      {
        name: 'Tarrafal de São Nicolau',
      },
      {
        name: 'São Lourenço dos Órgãos',
      },
      {
        name: 'Ribeira Grande de Santiago',
      },
    ],
  },
  Curacao: {
    countryName: 'Curacao',
    provinces: null,
  },
  'Christmas Island': {
    countryName: 'Christmas Island',
    provinces: null,
  },
  Cyprus: {
    countryName: 'Cyprus',
    provinces: [
      {
        name: 'Pafos',
      },
      {
        name: 'Nicosia',
      },
      {
        name: 'Limassol',
      },
      {
        name: 'Larnaka',
      },
      {
        name: 'Keryneia',
      },
      {
        name: 'Ammochostos',
      },
    ],
  },
  Czechia: {
    countryName: 'Czechia',
    provinces: [
      {
        name: 'Hlavní město Praha',
      },
      {
        name: 'South Moravian',
      },
      {
        name: 'Jihočeský kraj',
      },
      {
        name: 'Vysočina',
      },
      {
        name: 'Karlovarský kraj',
      },
      {
        name: 'Královéhradecký kraj',
      },
      {
        name: 'Liberecký kraj',
      },
      {
        name: 'Olomoucký',
      },
      {
        name: 'Moravskoslezský',
      },
      {
        name: 'Pardubický',
      },
      {
        name: 'Plzeňský kraj',
      },
      {
        name: 'Central Bohemia',
      },
      {
        name: 'Ústecký kraj',
      },
      {
        name: 'Zlín',
      },
    ],
  },
  Germany: {
    countryName: 'Germany',
    provinces: [
      {
        name: 'Thuringia',
      },
      {
        name: 'Schleswig-Holstein',
      },
      {
        name: 'Saxony-Anhalt',
      },
      {
        name: 'Saxony',
      },
      {
        name: 'Saarland',
      },
      {
        name: 'Rheinland-Pfalz',
      },
      {
        name: 'North Rhine-Westphalia',
      },
      {
        name: 'Lower Saxony',
      },
      {
        name: 'Mecklenburg-Vorpommern',
      },
      {
        name: 'Hesse',
      },
      {
        name: 'Hamburg',
      },
      {
        name: 'Bremen',
      },
      {
        name: 'Brandenburg',
      },
      {
        name: 'Berlin',
      },
      {
        name: 'Bavaria',
      },
      {
        name: 'Baden-Württemberg',
      },
    ],
  },
  Djibouti: {
    countryName: 'Djibouti',
    provinces: [
      {
        name: 'Tadjourah',
      },
      {
        name: 'Obock',
      },
      {
        name: 'Djibouti',
      },
      {
        name: 'Dikhil',
      },
      {
        name: 'Ali Sabieh',
      },
      {
        name: 'Arta',
      },
    ],
  },
  Denmark: {
    countryName: 'Denmark',
    provinces: [
      {
        name: 'Capital Region',
      },
      {
        name: 'Central Jutland',
      },
      {
        name: 'North Denmark',
      },
      {
        name: 'Zealand',
      },
      {
        name: 'South Denmark',
      },
    ],
  },
  Dominica: {
    countryName: 'Dominica',
    provinces: [
      {
        name: 'Saint Peter',
      },
      {
        name: 'Saint Paul',
      },
      {
        name: 'Saint Patrick',
      },
      {
        name: 'Saint Mark',
      },
      {
        name: 'Saint Luke',
      },
      {
        name: 'Saint Joseph',
      },
      {
        name: 'Saint John',
      },
      {
        name: 'Saint George',
      },
      {
        name: 'Saint David',
      },
      {
        name: 'Saint Andrew',
      },
    ],
  },
  'Dominican Republic': {
    countryName: 'Dominican Republic',
    provinces: [
      {
        name: 'Valverde',
      },
      {
        name: 'Santiago Rodríguez',
      },
      {
        name: 'Santiago',
      },
      {
        name: 'San Pedro de Macorís',
      },
      {
        name: 'San Juan',
      },
      {
        name: 'San Cristóbal',
      },
      {
        name: 'Sánchez Ramírez',
      },
      {
        name: 'Samaná',
      },
      {
        name: 'Hermanas Mirabal',
      },
      {
        name: 'Puerto Plata',
      },
      {
        name: 'Peravia',
      },
      {
        name: 'Pedernales',
      },
      {
        name: 'Nacional',
      },
      {
        name: 'Monte Plata',
      },
      {
        name: 'Monte Cristi',
      },
      {
        name: 'Monseñor Nouel',
      },
      {
        name: 'María Trinidad Sánchez',
      },
      {
        name: 'La Vega',
      },
      {
        name: 'La Romana',
      },
      {
        name: 'La Altagracia',
      },
      {
        name: 'Independencia',
      },
      {
        name: 'Hato Mayor',
      },
      {
        name: 'Espaillat',
      },
      {
        name: 'El Seíbo',
      },
      {
        name: 'Elías Piña',
      },
      {
        name: 'Duarte',
      },
      {
        name: 'Dajabón',
      },
      {
        name: 'Barahona',
      },
      {
        name: 'Baoruco',
      },
      {
        name: 'Azua',
      },
      {
        name: 'San José de Ocoa',
      },
      {
        name: 'Santo Domingo',
      },
    ],
  },
  Algeria: {
    countryName: 'Algeria',
    provinces: [
      {
        name: 'Tlemcen',
      },
      {
        name: 'Tizi Ouzou',
      },
      {
        name: 'Tissemsilt',
      },
      {
        name: 'Tipaza',
      },
      {
        name: 'Tindouf',
      },
      {
        name: 'Tiaret',
      },
      {
        name: 'Tébessa',
      },
      {
        name: 'Tamanrasset',
      },
      {
        name: 'Souk Ahras',
      },
      {
        name: 'Skikda',
      },
      {
        name: 'Sidi Bel Abbès',
      },
      {
        name: 'Sétif',
      },
      {
        name: 'Saida',
      },
      {
        name: 'Relizane',
      },
      {
        name: 'Oum el Bouaghi',
      },
      {
        name: 'Ouargla',
      },
      {
        name: 'Oran',
      },
      {
        name: 'Naama',
      },
      {
        name: "M'Sila",
      },
      {
        name: 'Mostaganem',
      },
      {
        name: 'Mila',
      },
      {
        name: 'Medea',
      },
      {
        name: 'Mascara',
      },
      {
        name: 'Laghouat',
      },
      {
        name: 'Khenchela',
      },
      {
        name: 'Jijel',
      },
      {
        name: 'Illizi',
      },
      {
        name: 'Guelma',
      },
      {
        name: 'Ghardaia',
      },
      {
        name: 'El Tarf',
      },
      {
        name: 'El Oued',
      },
      {
        name: 'El Bayadh',
      },
      {
        name: 'Djelfa',
      },
      {
        name: 'Constantine',
      },
      {
        name: 'Chlef',
      },
      {
        name: 'Boumerdes',
      },
      {
        name: 'Bouira',
      },
      {
        name: 'Bordj Bou Arréridj',
      },
      {
        name: 'Blida',
      },
      {
        name: 'Biskra',
      },
      {
        name: 'Béjaïa',
      },
      {
        name: 'Béchar',
      },
      {
        name: 'Batna',
      },
      {
        name: 'Annaba',
      },
      {
        name: 'Algiers',
      },
      {
        name: 'Aïn Témouchent',
      },
      {
        name: 'Aïn Defla',
      },
      {
        name: 'Adrar',
      },
    ],
  },
  Ecuador: {
    countryName: 'Ecuador',
    provinces: [
      {
        name: 'Zamora-Chinchipe',
      },
      {
        name: 'Tungurahua',
      },
      {
        name: 'Pichincha',
      },
      {
        name: 'Pastaza',
      },
      {
        name: 'Napo',
      },
      {
        name: 'Morona-Santiago',
      },
      {
        name: 'Manabí',
      },
      {
        name: 'Los Ríos',
      },
      {
        name: 'Loja',
      },
      {
        name: 'Imbabura',
      },
      {
        name: 'Guayas',
      },
      {
        name: 'Galápagos',
      },
      {
        name: 'Esmeraldas',
      },
      {
        name: 'El Oro',
      },
      {
        name: 'Cotopaxi',
      },
      {
        name: 'Chimborazo',
      },
      {
        name: 'Carchi',
      },
      {
        name: 'Cañar',
      },
      {
        name: 'Bolívar',
      },
      {
        name: 'Azuay',
      },
      {
        name: 'Sucumbios',
      },
      {
        name: 'Orellana',
      },
      {
        name: 'Santo Domingo de los Tsáchilas',
      },
      {
        name: 'Santa Elena',
      },
    ],
  },
  Estonia: {
    countryName: 'Estonia',
    provinces: [
      {
        name: 'Võrumaa',
      },
      {
        name: 'Viljandimaa',
      },
      {
        name: 'Valgamaa',
      },
      {
        name: 'Tartu',
      },
      {
        name: 'Saare',
      },
      {
        name: 'Raplamaa',
      },
      {
        name: 'Põlvamaa',
      },
      {
        name: 'Pärnumaa',
      },
      {
        name: 'Lääne-Virumaa',
      },
      {
        name: 'Lääne',
      },
      {
        name: 'Jõgevamaa',
      },
      {
        name: 'Järvamaa',
      },
      {
        name: 'Ida-Virumaa',
      },
      {
        name: 'Hiiumaa',
      },
      {
        name: 'Harjumaa',
      },
    ],
  },
  Egypt: {
    countryName: 'Egypt',
    provinces: [
      {
        name: 'Sohag',
      },
      {
        name: 'North Sinai',
      },
      {
        name: 'Qena',
      },
      {
        name: 'Matruh',
      },
      {
        name: 'Kafr el-Sheikh',
      },
      {
        name: 'South Sinai',
      },
      {
        name: 'Damietta',
      },
      {
        name: 'Port Said',
      },
      {
        name: 'Beni Suweif',
      },
      {
        name: 'Asyut',
      },
      {
        name: 'Aswan',
      },
      {
        name: 'Suez',
      },
      {
        name: 'Sharqia',
      },
      {
        name: 'New Valley',
      },
      {
        name: 'Qalyubia',
      },
      {
        name: 'Cairo',
      },
      {
        name: 'Minya',
      },
      {
        name: 'Monufia',
      },
      {
        name: 'Giza',
      },
      {
        name: 'Ismailia',
      },
      {
        name: 'Alexandria',
      },
      {
        name: 'Gharbia',
      },
      {
        name: 'Faiyum',
      },
      {
        name: 'Beheira',
      },
      {
        name: 'Red Sea',
      },
      {
        name: 'Dakahlia',
      },
      {
        name: 'Luxor',
      },
    ],
  },
  'Western Sahara': {
    countryName: 'Western Sahara',
    provinces: null,
  },
  Eritrea: {
    countryName: 'Eritrea',
    provinces: [
      {
        name: 'Anseba',
      },
      {
        name: 'Debub',
      },
      {
        name: 'Southern Red Sea',
      },
      {
        name: 'Gash-Barka',
      },
      {
        name: 'Maekel',
      },
      {
        name: 'Northern Red Sea',
      },
    ],
  },
  Spain: {
    countryName: 'Spain',
    provinces: [
      {
        name: 'Murcia',
      },
      {
        name: 'Ceuta',
      },
      {
        name: 'Balearic Islands',
      },
      {
        name: 'Andalusia',
      },
      {
        name: 'Canary Islands',
      },
      {
        name: 'Castille-La Mancha',
      },
      {
        name: 'Extremadura',
      },
      {
        name: 'Valencia',
      },
      {
        name: 'Asturias',
      },
      {
        name: 'Navarre',
      },
      {
        name: 'Madrid',
      },
      {
        name: 'La Rioja',
      },
      {
        name: 'Cantabria',
      },
      {
        name: 'Aragon',
      },
      {
        name: 'Castille and León',
      },
      {
        name: 'Catalonia',
      },
      {
        name: 'Galicia',
      },
      {
        name: 'Basque Country',
      },
      {
        name: 'Melilla',
      },
    ],
  },
  Ethiopia: {
    countryName: 'Ethiopia',
    provinces: [
      {
        name: 'Addis Ababa',
      },
      {
        name: 'Āfar',
      },
      {
        name: 'Amhara',
      },
      {
        name: 'Bīnshangul Gumuz',
      },
      {
        name: 'Dire Dawa',
      },
      {
        name: 'Gambela',
      },
      {
        name: 'Harari',
      },
      {
        name: 'Oromiya',
      },
      {
        name: 'Somali',
      },
      {
        name: 'Tigray',
      },
      {
        name: 'SNNPR',
      },
    ],
  },
  Finland: {
    countryName: 'Finland',
    provinces: [
      {
        name: 'Lapland',
      },
      {
        name: 'Kainuu',
      },
      {
        name: 'North Ostrobothnia',
      },
      {
        name: 'Central Ostrobothnia',
      },
      {
        name: 'Ostrobothnia',
      },
      {
        name: 'South Ostrobothnia',
      },
      {
        name: 'Central Finland',
      },
      {
        name: 'North Karelia',
      },
      {
        name: 'North Savo',
      },
      {
        name: 'South Savo',
      },
      {
        name: 'South Karelia',
      },
      {
        name: 'Kymenlaakso',
      },
      {
        name: 'Pirkanmaa',
      },
      {
        name: 'Kanta-Häme',
      },
      {
        name: 'Southwest Finland',
      },
      {
        name: 'Uusimaa',
      },
      {
        name: 'Paijat-Hame',
      },
      {
        name: 'Satakunta',
      },
    ],
  },
  Fiji: {
    countryName: 'Fiji',
    provinces: [
      {
        name: 'Western',
      },
      {
        name: 'Northern',
      },
      {
        name: 'Central',
      },
      {
        name: 'Eastern',
      },
      {
        name: 'Rotuma',
      },
    ],
  },
  'Falkland Islands': {
    countryName: 'Falkland Islands',
    provinces: null,
  },
  Micronesia: {
    countryName: 'Micronesia',
    provinces: [
      {
        name: 'Yap',
      },
      {
        name: 'Pohnpei',
      },
      {
        name: 'Kosrae',
      },
      {
        name: 'Chuuk',
      },
    ],
  },
  'Faroe Islands': {
    countryName: 'Faroe Islands',
    provinces: [
      {
        name: 'Vágar',
      },
      {
        name: 'Suðuroy',
      },
      {
        name: 'Streymoy',
      },
      {
        name: 'Sandoy',
      },
      {
        name: 'Norðoyar',
      },
      {
        name: 'Eysturoy',
      },
    ],
  },
  France: {
    countryName: 'France',
    provinces: [
      {
        name: "Provence-Alpes-Côte d'Azur",
      },
      {
        name: 'Pays de la Loire',
      },
      {
        name: 'Île-de-France',
      },
      {
        name: 'Corsica',
      },
      {
        name: 'Centre',
      },
      {
        name: 'Brittany',
      },
      {
        name: 'Bourgogne-Franche-Comté',
      },
      {
        name: 'Nouvelle-Aquitaine',
      },
      {
        name: 'Normandy',
      },
      {
        name: 'Grand Est',
      },
      {
        name: 'Occitanie',
      },
      {
        name: 'Hauts-de-France',
      },
      {
        name: 'Auvergne-Rhône-Alpes',
      },
    ],
  },
  Gabon: {
    countryName: 'Gabon',
    provinces: [
      {
        name: 'Woleu-Ntem',
      },
      {
        name: 'Ogooué-Maritime',
      },
      {
        name: 'Ogooué-Lolo',
      },
      {
        name: 'Ogooué-Ivindo',
      },
      {
        name: 'Nyanga',
      },
      {
        name: 'Ngouni',
      },
      {
        name: 'Moyen-Ogooué',
      },
      {
        name: 'Haut-Ogooué',
      },
      {
        name: 'Estuaire',
      },
    ],
  },
  'United Kingdom': {
    countryName: 'United Kingdom',
    provinces: [
      {
        name: 'Wales',
      },
      {
        name: 'Scotland',
      },
      {
        name: 'Northern Ireland',
      },
      {
        name: 'England',
      },
    ],
  },
  Grenada: {
    countryName: 'Grenada',
    provinces: [
      {
        name: 'Saint Patrick',
      },
      {
        name: 'Saint Mark',
      },
      {
        name: 'Saint John',
      },
      {
        name: 'Saint George',
      },
      {
        name: 'Saint David',
      },
      {
        name: 'Saint Andrew',
      },
      {
        name: 'Carriacou and Petite Martinique',
      },
    ],
  },
  Georgia: {
    countryName: 'Georgia',
    provinces: [
      {
        name: "T'bilisi",
      },
      {
        name: 'Ajaria',
      },
      {
        name: 'Kvemo Kartli',
      },
      {
        name: 'Kakheti',
      },
      {
        name: 'Guria',
      },
      {
        name: 'Imereti',
      },
      {
        name: 'Shida Kartli',
      },
      {
        name: 'Mtskheta-Mtianeti',
      },
      {
        name: 'Racha-Lechkhumi and Kvemo Svaneti',
      },
      {
        name: 'Samegrelo and Zemo Svaneti',
      },
      {
        name: 'Samtskhe-Javakheti',
      },
      {
        name: 'Abkhazia',
      },
    ],
  },
  'French Guiana': {
    countryName: 'French Guiana',
    provinces: [
      {
        name: 'Guyane',
      },
    ],
  },
  Guernsey: {
    countryName: 'Guernsey',
    provinces: [
      {
        name: 'St Pierre du Bois',
      },
      {
        name: 'Torteval',
      },
      {
        name: 'Saint Saviour',
      },
      {
        name: 'Forest',
      },
      {
        name: 'St Martin',
      },
      {
        name: 'Saint Andrew',
      },
      {
        name: 'St Peter Port',
      },
      {
        name: 'Castel',
      },
      {
        name: 'Vale',
      },
      {
        name: 'St Sampson',
      },
      {
        name: 'Alderney',
      },
    ],
  },
  Ghana: {
    countryName: 'Ghana',
    provinces: [
      {
        name: 'Western',
      },
      {
        name: 'Volta',
      },
      {
        name: 'Upper West',
      },
      {
        name: 'Upper East',
      },
      {
        name: 'Northern',
      },
      {
        name: 'Greater Accra',
      },
      {
        name: 'Eastern',
      },
      {
        name: 'Central',
      },
      {
        name: 'Ashanti',
      },
      {
        name: 'Bono',
      },
      {
        name: 'Oti',
      },
      {
        name: 'North East',
      },
      {
        name: 'Ahafo',
      },
      {
        name: 'Bono East',
      },
      {
        name: 'Savannah',
      },
      {
        name: 'Western North',
      },
    ],
  },
  Gibraltar: {
    countryName: 'Gibraltar',
    provinces: null,
  },
  Greenland: {
    countryName: 'Greenland',
    provinces: [
      {
        name: 'Kujalleq',
      },
      {
        name: 'Qeqqata',
      },
      {
        name: 'Sermersooq',
      },
      {
        name: 'Qeqertalik',
      },
      {
        name: 'Avannaata',
      },
    ],
  },
  Gambia: {
    countryName: 'Gambia',
    provinces: [
      {
        name: 'Western',
      },
      {
        name: 'Upper River',
      },
      {
        name: 'North Bank',
      },
      {
        name: 'Central River',
      },
      {
        name: 'Lower River',
      },
      {
        name: 'Banjul',
      },
    ],
  },
  Guinea: {
    countryName: 'Guinea',
    provinces: [
      {
        name: 'Conakry',
      },
      {
        name: 'Boke',
      },
      {
        name: 'Faranah',
      },
      {
        name: 'Kankan',
      },
      {
        name: 'Kindia',
      },
      {
        name: 'Labe',
      },
      {
        name: 'Mamou',
      },
      {
        name: 'Nzerekore',
      },
    ],
  },
  Guadeloupe: {
    countryName: 'Guadeloupe',
    provinces: [
      {
        name: 'Guadeloupe',
      },
    ],
  },
  'Equatorial Guinea': {
    countryName: 'Equatorial Guinea',
    provinces: [
      {
        name: 'Annobon',
      },
      {
        name: 'Bioko Norte',
      },
      {
        name: 'Bioko Sur',
      },
      {
        name: 'Centro Sur',
      },
      {
        name: 'Kié-Ntem',
      },
      {
        name: 'Litoral',
      },
      {
        name: 'Wele-Nzas',
      },
    ],
  },
  Greece: {
    countryName: 'Greece',
    provinces: [
      {
        name: 'Mount Athos',
      },
      {
        name: 'Attica',
      },
      {
        name: 'Central Greece',
      },
      {
        name: 'Central Macedonia',
      },
      {
        name: 'Crete',
      },
      {
        name: 'East Macedonia and Thrace',
      },
      {
        name: 'Epirus',
      },
      {
        name: 'Ionian Islands',
      },
      {
        name: 'North Aegean',
      },
      {
        name: 'Peloponnese',
      },
      {
        name: 'South Aegean',
      },
      {
        name: 'Thessaly',
      },
      {
        name: 'West Greece',
      },
      {
        name: 'West Macedonia',
      },
    ],
  },
  'South Georgia and the South Sandwich Islands': {
    countryName: 'South Georgia and the South Sandwich Islands',
    provinces: null,
  },
  Guatemala: {
    countryName: 'Guatemala',
    provinces: [
      {
        name: 'Zacapa',
      },
      {
        name: 'Totonicapán',
      },
      {
        name: 'Suchitepeque',
      },
      {
        name: 'Sololá',
      },
      {
        name: 'Santa Rosa',
      },
      {
        name: 'San Marcos',
      },
      {
        name: 'Sacatepéquez',
      },
      {
        name: 'Retalhuleu',
      },
      {
        name: 'Quiché',
      },
      {
        name: 'Quetzaltenango',
      },
      {
        name: 'Petén',
      },
      {
        name: 'Jutiapa',
      },
      {
        name: 'Jalapa',
      },
      {
        name: 'Izabal',
      },
      {
        name: 'Huehuetenango',
      },
      {
        name: 'Guatemala',
      },
      {
        name: 'Escuintla',
      },
      {
        name: 'El Progreso',
      },
      {
        name: 'Chiquimula',
      },
      {
        name: 'Chimaltenango',
      },
      {
        name: 'Baja Verapaz',
      },
      {
        name: 'Alta Verapaz',
      },
    ],
  },
  Guam: {
    countryName: 'Guam',
    provinces: [
      {
        name: 'Piti',
      },
      {
        name: 'Santa Rita',
      },
      {
        name: 'Sinajana',
      },
      {
        name: 'Talofofo',
      },
      {
        name: 'Tamuning',
      },
      {
        name: 'Umatac',
      },
      {
        name: 'Yigo',
      },
      {
        name: 'Yona',
      },
      {
        name: 'Merizo',
      },
      {
        name: 'Mangilao',
      },
      {
        name: 'Agana Heights',
      },
      {
        name: 'Chalan Pago-Ordot',
      },
      {
        name: 'Asan',
      },
      {
        name: 'Agat',
      },
      {
        name: 'Dededo',
      },
      {
        name: 'Barrigada',
      },
      {
        name: 'Hagatna',
      },
      {
        name: 'Inarajan',
      },
      {
        name: 'Mongmong-Toto-Maite',
      },
    ],
  },
  'Guinea-Bissau': {
    countryName: 'Guinea-Bissau',
    provinces: [
      {
        name: 'Tombali',
      },
      {
        name: 'Quinara',
      },
      {
        name: 'Oio',
      },
      {
        name: 'Gabú',
      },
      {
        name: 'Cacheu',
      },
      {
        name: 'Bolama',
      },
      {
        name: 'Bissau',
      },
      {
        name: 'Biombo',
      },
      {
        name: 'Bafatá',
      },
    ],
  },
  Guyana: {
    countryName: 'Guyana',
    provinces: [
      {
        name: 'Upper Takutu-Upper Essequibo',
      },
      {
        name: 'Upper Demerara-Berbice',
      },
      {
        name: 'Potaro-Siparuni',
      },
      {
        name: 'Pomeroon-Supenaam',
      },
      {
        name: 'Mahaica-Berbice',
      },
      {
        name: 'Essequibo Islands-West Demerara',
      },
      {
        name: 'East Berbice-Corentyne',
      },
      {
        name: 'Demerara-Mahaica',
      },
      {
        name: 'Cuyuni-Mazaruni',
      },
      {
        name: 'Barima-Waini',
      },
    ],
  },
  'Hong Kong': {
    countryName: 'Hong Kong',
    provinces: [
      {
        name: 'Yuen Long',
      },
      {
        name: 'Tsuen Wan',
      },
      {
        name: 'Tai Po',
      },
      {
        name: 'Sai Kung',
      },
      {
        name: 'Islands',
      },
      {
        name: 'Central and Western',
      },
      {
        name: 'Wan Chai',
      },
      {
        name: 'Eastern',
      },
      {
        name: 'Southern',
      },
      {
        name: 'Yau Tsim Mong',
      },
      {
        name: 'Sham Shui Po',
      },
      {
        name: 'Kowloon City',
      },
      {
        name: 'Wong Tai Sin',
      },
      {
        name: 'Kwun Tong',
      },
      {
        name: 'Kwai Tsing',
      },
      {
        name: 'Tuen Mun',
      },
      {
        name: 'North',
      },
      {
        name: 'Sha Tin',
      },
    ],
  },
  'Heard Island and McDonald Islands': {
    countryName: 'Heard Island and McDonald Islands',
    provinces: null,
  },
  Honduras: {
    countryName: 'Honduras',
    provinces: [
      {
        name: 'Yoro',
      },
      {
        name: 'Valle',
      },
      {
        name: 'Santa Bárbara',
      },
      {
        name: 'Olancho',
      },
      {
        name: 'Ocotepeque',
      },
      {
        name: 'Lempira',
      },
      {
        name: 'La Paz',
      },
      {
        name: 'Bay Islands',
      },
      {
        name: 'Intibucá',
      },
      {
        name: 'Gracias a Dios',
      },
      {
        name: 'Francisco Morazán',
      },
      {
        name: 'El Paraíso',
      },
      {
        name: 'Cortés',
      },
      {
        name: 'Copán',
      },
      {
        name: 'Comayagua',
      },
      {
        name: 'Colón',
      },
      {
        name: 'Choluteca',
      },
      {
        name: 'Atlántida',
      },
    ],
  },
  Croatia: {
    countryName: 'Croatia',
    provinces: [
      {
        name: 'Bjelovarsko-Bilogorska',
      },
      {
        name: 'Slavonski Brod-Posavina',
      },
      {
        name: 'Dubrovačko-Neretvanska',
      },
      {
        name: 'Istria',
      },
      {
        name: 'Karlovačka',
      },
      {
        name: 'Koprivničko-Križevačka',
      },
      {
        name: 'Krapinsko-Zagorska',
      },
      {
        name: 'Ličko-Senjska',
      },
      {
        name: 'Međimurska',
      },
      {
        name: 'Osječko-Baranjska',
      },
      {
        name: 'Požeško-Slavonska',
      },
      {
        name: 'Primorsko-Goranska',
      },
      {
        name: 'Šibensko-Kniniska',
      },
      {
        name: 'Sisačko-Moslavačka',
      },
      {
        name: 'Split-Dalmatia',
      },
      {
        name: 'Varaždinska',
      },
      {
        name: 'Vukovar-Sirmium',
      },
      {
        name: 'Zadarska',
      },
      {
        name: 'Zagrebačka',
      },
      {
        name: 'City of Zagreb',
      },
      {
        name: 'Virovitičk-Podravska',
      },
    ],
  },
  Haiti: {
    countryName: 'Haiti',
    provinces: [
      {
        name: 'Sud-Est',
      },
      {
        name: 'Sud',
      },
      {
        name: 'Ouest',
      },
      {
        name: 'Nord-Ouest',
      },
      {
        name: 'Nord-Est',
      },
      {
        name: 'Nord',
      },
      {
        name: 'GrandʼAnse',
      },
      {
        name: 'Centre',
      },
      {
        name: 'Artibonite',
      },
      {
        name: 'Nippes',
      },
    ],
  },
  Hungary: {
    countryName: 'Hungary',
    provinces: [
      {
        name: 'Szabolcs-Szatmár-Bereg',
      },
      {
        name: 'Jász-Nagykun-Szolnok',
      },
      {
        name: 'Heves',
      },
      {
        name: 'Hajdú-Bihar',
      },
      {
        name: 'Csongrád',
      },
      {
        name: 'Borsod-Abaúj-Zemplén',
      },
      {
        name: 'Bekes County',
      },
      {
        name: 'Zala',
      },
      {
        name: 'Veszprém',
      },
      {
        name: 'Vas',
      },
      {
        name: 'Tolna',
      },
      {
        name: 'Somogy',
      },
      {
        name: 'Pest',
      },
      {
        name: 'Nógrád',
      },
      {
        name: 'Komárom-Esztergom',
      },
      {
        name: 'Győr-Moson-Sopron',
      },
      {
        name: 'Fejér',
      },
      {
        name: 'Budapest',
      },
      {
        name: 'Baranya',
      },
      {
        name: 'Bács-Kiskun',
      },
    ],
  },
  Indonesia: {
    countryName: 'Indonesia',
    provinces: [
      {
        name: 'North Sumatra',
      },
      {
        name: 'Aceh',
      },
      {
        name: 'Yogyakarta',
      },
      {
        name: 'South Sumatra',
      },
      {
        name: 'West Sumatra',
      },
      {
        name: 'North Sulawesi',
      },
      {
        name: 'Southeast Sulawesi',
      },
      {
        name: 'Central Sulawesi',
      },
      {
        name: 'South Sulawesi',
      },
      {
        name: 'Riau',
      },
      {
        name: 'East Nusa Tenggara',
      },
      {
        name: 'West Nusa Tenggara',
      },
      {
        name: 'Maluku',
      },
      {
        name: 'Lampung',
      },
      {
        name: 'East Kalimantan',
      },
      {
        name: 'Central Kalimantan',
      },
      {
        name: 'South Kalimantan',
      },
      {
        name: 'West Kalimantan',
      },
      {
        name: 'East Java',
      },
      {
        name: 'Central Java',
      },
      {
        name: 'West Java',
      },
      {
        name: 'Jambi',
      },
      {
        name: 'Jakarta',
      },
      {
        name: 'Papua',
      },
      {
        name: 'Bengkulu',
      },
      {
        name: 'Bali',
      },
      {
        name: 'Banten',
      },
      {
        name: 'Gorontalo',
      },
      {
        name: 'Bangka–Belitung Islands',
      },
      {
        name: 'North Maluku',
      },
      {
        name: 'West Papua',
      },
      {
        name: 'West Sulawesi',
      },
      {
        name: 'Riau Islands',
      },
      {
        name: 'North Kalimantan',
      },
    ],
  },
  Ireland: {
    countryName: 'Ireland',
    provinces: [
      {
        name: 'Connaught',
      },
      {
        name: 'Leinster',
      },
      {
        name: 'Munster',
      },
      {
        name: 'Ulster',
      },
    ],
  },
  Israel: {
    countryName: 'Israel',
    provinces: [
      {
        name: 'Jerusalem',
      },
      {
        name: 'Tel Aviv',
      },
      {
        name: 'Haifa',
      },
      {
        name: 'Northern District',
      },
      {
        name: 'Central District',
      },
      {
        name: 'Southern District',
      },
      {
        name: 'Judea and Samaria Area',
      },
    ],
  },
  'Isle of Man': {
    countryName: 'Isle of Man',
    provinces: [
      {
        name: 'Andreas',
      },
      {
        name: 'Arbory',
      },
      {
        name: 'Ballaugh',
      },
      {
        name: 'Braddan',
      },
      {
        name: 'Bride',
      },
      {
        name: 'Castletown',
      },
      {
        name: 'Douglas',
      },
      {
        name: 'German',
      },
      {
        name: 'Jurby',
      },
      {
        name: 'Laxey',
      },
      {
        name: 'Lezayre',
      },
      {
        name: 'Lonan',
      },
      {
        name: 'Malew',
      },
      {
        name: 'Marown',
      },
      {
        name: 'Maughold',
      },
      {
        name: 'Michael',
      },
      {
        name: 'Onchan',
      },
      {
        name: 'Patrick',
      },
      {
        name: 'Peel',
      },
      {
        name: 'Port Erin',
      },
      {
        name: 'Port St Mary',
      },
      {
        name: 'Ramsey',
      },
      {
        name: 'Rushen',
      },
      {
        name: 'Santon',
      },
    ],
  },
  India: {
    countryName: 'India',
    provinces: [
      {
        name: 'West Bengal',
      },
      {
        name: 'Uttar Pradesh',
      },
      {
        name: 'Tripura',
      },
      {
        name: 'Telangana',
      },
      {
        name: 'Tamil Nadu',
      },
      {
        name: 'Sikkim',
      },
      {
        name: 'Rajasthan',
      },
      {
        name: 'Punjab',
      },
      {
        name: 'Puducherry',
      },
      {
        name: 'Odisha',
      },
      {
        name: 'Nagaland',
      },
      {
        name: 'Mizoram',
      },
      {
        name: 'Meghalaya',
      },
      {
        name: 'Manipur',
      },
      {
        name: 'Maharashtra',
      },
      {
        name: 'Madhya Pradesh',
      },
      {
        name: 'Laccadives',
      },
      {
        name: 'Kerala',
      },
      {
        name: 'Karnataka',
      },
      {
        name: 'Jammu and Kashmir',
      },
      {
        name: 'Himachal Pradesh',
      },
      {
        name: 'Haryana',
      },
      {
        name: 'Gujarat',
      },
      {
        name: 'Goa',
      },
      {
        name: 'Delhi',
      },
      {
        name: 'Chandigarh',
      },
      {
        name: 'Bihar',
      },
      {
        name: 'Assam',
      },
      {
        name: 'Arunachal Pradesh',
      },
      {
        name: 'Andhra Pradesh',
      },
      {
        name: 'Andaman and Nicobar',
      },
      {
        name: 'Chhattisgarh',
      },
      {
        name: 'Jharkhand',
      },
      {
        name: 'Uttarakhand',
      },
      {
        name: 'Ladakh',
      },
      {
        name: 'Dadra and Nagar Haveli and Daman and Diu',
      },
    ],
  },
  'British Indian Ocean Territory': {
    countryName: 'British Indian Ocean Territory',
    provinces: null,
  },
  Iraq: {
    countryName: 'Iraq',
    provinces: [
      {
        name: 'Basra',
      },
      {
        name: 'Wāsiţ',
      },
      {
        name: 'Salah ad Din',
      },
      {
        name: 'Nineveh',
      },
      {
        name: 'Maysan',
      },
      {
        name: 'Karbalāʼ',
      },
      {
        name: 'Arbīl',
      },
      {
        name: 'Diyālá',
      },
      {
        name: 'Dhi Qar',
      },
      {
        name: 'Duhok',
      },
      {
        name: 'Baghdad',
      },
      {
        name: 'Bābil',
      },
      {
        name: 'Kirkuk',
      },
      {
        name: 'Sulaymaniyah',
      },
      {
        name: 'An Najaf',
      },
      {
        name: 'Al Qādisīyah',
      },
      {
        name: 'Al Muthanná',
      },
      {
        name: 'Al Anbar',
      },
    ],
  },
  Iran: {
    countryName: 'Iran',
    provinces: [
      {
        name: 'Tehran',
      },
      {
        name: 'Zanjan',
      },
      {
        name: 'Yazd',
      },
      {
        name: 'Semnan',
      },
      {
        name: 'Māzandarān',
      },
      {
        name: 'Markazi',
      },
      {
        name: 'Lorestan Province',
      },
      {
        name: 'Kordestān',
      },
      {
        name: 'Kohgiluyeh and Boyer-Ahmad',
      },
      {
        name: 'Khuzestan',
      },
      {
        name: 'Kermānshāh',
      },
      {
        name: 'Kerman',
      },
      {
        name: 'Ilam Province',
      },
      {
        name: 'Hormozgan',
      },
      {
        name: 'Hamadān',
      },
      {
        name: 'Gīlān',
      },
      {
        name: 'Fars',
      },
      {
        name: 'Chaharmahal and Bakhtiari',
      },
      {
        name: 'Bushehr',
      },
      {
        name: 'East Azerbaijan',
      },
      {
        name: 'West Azerbaijan',
      },
      {
        name: 'Ardabīl',
      },
      {
        name: 'Isfahan',
      },
      {
        name: 'Golestan',
      },
      {
        name: 'Qazvīn',
      },
      {
        name: 'Qom',
      },
      {
        name: 'Sistan and Baluchestan',
      },
      {
        name: 'South Khorasan Province',
      },
      {
        name: 'Razavi Khorasan',
      },
      {
        name: 'North Khorasan',
      },
      {
        name: 'Alborz Province',
      },
    ],
  },
  Iceland: {
    countryName: 'Iceland',
    provinces: [
      {
        name: 'Northwest',
      },
      {
        name: 'Northeast',
      },
      {
        name: 'East',
      },
      {
        name: 'South',
      },
      {
        name: 'Capital Region',
      },
      {
        name: 'Southern Peninsula',
      },
      {
        name: 'West',
      },
      {
        name: 'Westfjords',
      },
    ],
  },
  Italy: {
    countryName: 'Italy',
    provinces: [
      {
        name: 'Sicily',
      },
      {
        name: 'Sardinia',
      },
      {
        name: 'Calabria',
      },
      {
        name: 'Veneto',
      },
      {
        name: 'Aosta Valley',
      },
      {
        name: 'Umbria',
      },
      {
        name: 'Trentino-Alto Adige',
      },
      {
        name: 'Tuscany',
      },
      {
        name: 'Apulia',
      },
      {
        name: 'Piedmont',
      },
      {
        name: 'Molise',
      },
      {
        name: 'The Marches',
      },
      {
        name: 'Lombardy',
      },
      {
        name: 'Liguria',
      },
      {
        name: 'Latium',
      },
      {
        name: 'Friuli Venezia Giulia',
      },
      {
        name: 'Emilia-Romagna',
      },
      {
        name: 'Campania',
      },
      {
        name: 'Basilicate',
      },
      {
        name: 'Abruzzo',
      },
    ],
  },
  Jersey: {
    countryName: 'Jersey',
    provinces: [
      {
        name: 'St Clement',
      },
      {
        name: 'St Saviour',
      },
      {
        name: 'St. Brelade',
      },
      {
        name: 'Grouville',
      },
      {
        name: 'St Mary',
      },
      {
        name: 'St Lawrence',
      },
      {
        name: 'St Peter',
      },
      {
        name: 'St Ouen',
      },
      {
        name: 'St John',
      },
      {
        name: 'Trinity',
      },
      {
        name: 'St Martîn',
      },
      {
        name: 'St Helier',
      },
    ],
  },
  Jamaica: {
    countryName: 'Jamaica',
    provinces: [
      {
        name: 'Westmoreland',
      },
      {
        name: 'Trelawny',
      },
      {
        name: 'St. Thomas',
      },
      {
        name: 'St. Mary',
      },
      {
        name: 'St. James',
      },
      {
        name: 'St. Elizabeth',
      },
      {
        name: 'Saint Catherine',
      },
      {
        name: 'St Ann',
      },
      {
        name: 'St. Andrew',
      },
      {
        name: 'Portland',
      },
      {
        name: 'Manchester',
      },
      {
        name: 'Kingston',
      },
      {
        name: 'Hanover',
      },
      {
        name: 'Clarendon',
      },
    ],
  },
  Jordan: {
    countryName: 'Jordan',
    provinces: [
      {
        name: 'Ma’an',
      },
      {
        name: 'Irbid',
      },
      {
        name: 'Zarqa',
      },
      {
        name: 'Tafielah',
      },
      {
        name: 'Amman',
      },
      {
        name: 'Mafraq',
      },
      {
        name: 'Karak',
      },
      {
        name: 'Balqa',
      },
      {
        name: 'Ajlun',
      },
      {
        name: 'Jerash',
      },
      {
        name: 'Aqaba',
      },
      {
        name: 'Madaba',
      },
    ],
  },
  Japan: {
    countryName: 'Japan',
    provinces: [
      {
        name: 'Yamanashi',
      },
      {
        name: 'Yamaguchi',
      },
      {
        name: 'Wakayama',
      },
      {
        name: 'Toyama',
      },
      {
        name: 'Tottori',
      },
      {
        name: 'Tokyo',
      },
      {
        name: 'Tokushima',
      },
      {
        name: 'Tochigi',
      },
      {
        name: 'Shizuoka',
      },
      {
        name: 'Shimane',
      },
      {
        name: 'Shiga',
      },
      {
        name: 'Saitama',
      },
      {
        name: 'Saga',
      },
      {
        name: 'Ōsaka',
      },
      {
        name: 'Okinawa',
      },
      {
        name: 'Okayama',
      },
      {
        name: 'Oita',
      },
      {
        name: 'Niigata',
      },
      {
        name: 'Nara',
      },
      {
        name: 'Nagasaki',
      },
      {
        name: 'Nagano',
      },
      {
        name: 'Miyazaki',
      },
      {
        name: 'Mie',
      },
      {
        name: 'Kyoto',
      },
      {
        name: 'Kumamoto',
      },
      {
        name: 'Kochi',
      },
      {
        name: 'Kanagawa',
      },
      {
        name: 'Kagoshima',
      },
      {
        name: 'Kagawa',
      },
      {
        name: 'Ishikawa',
      },
      {
        name: 'Hyōgo',
      },
      {
        name: 'Hiroshima',
      },
      {
        name: 'Gunma',
      },
      {
        name: 'Gifu',
      },
      {
        name: 'Fukuoka',
      },
      {
        name: 'Fukui',
      },
      {
        name: 'Ehime',
      },
      {
        name: 'Aichi',
      },
      {
        name: 'Yamagata',
      },
      {
        name: 'Miyagi',
      },
      {
        name: 'Iwate',
      },
      {
        name: 'Ibaraki',
      },
      {
        name: 'Fukushima',
      },
      {
        name: 'Chiba',
      },
      {
        name: 'Akita',
      },
      {
        name: 'Hokkaido',
      },
      {
        name: 'Aomori',
      },
    ],
  },
  Kenya: {
    countryName: 'Kenya',
    provinces: [
      {
        name: 'West Pokot',
      },
      {
        name: 'Wajir',
      },
      {
        name: 'Uasin Gishu',
      },
      {
        name: 'Turkana',
      },
      {
        name: 'Trans Nzoia',
      },
      {
        name: 'Tharaka - Nithi',
      },
      {
        name: 'Tana River',
      },
      {
        name: 'Siaya',
      },
      {
        name: 'Samburu',
      },
      {
        name: 'Nairobi Area',
      },
      {
        name: "Murang'A",
      },
      {
        name: 'Mombasa',
      },
      {
        name: 'Meru',
      },
      {
        name: 'Marsabit',
      },
      {
        name: 'Mandera',
      },
      {
        name: 'Laikipia',
      },
      {
        name: 'Kwale',
      },
      {
        name: 'Kitui',
      },
      {
        name: 'Kisumu',
      },
      {
        name: 'Kisii',
      },
      {
        name: 'Kirinyaga',
      },
      {
        name: 'Kilifi',
      },
      {
        name: 'Kiambu',
      },
      {
        name: 'Kericho',
      },
      {
        name: 'Kakamega',
      },
      {
        name: 'Isiolo',
      },
      {
        name: 'Garissa',
      },
      {
        name: 'Embu',
      },
      {
        name: 'Busia',
      },
      {
        name: 'Bungoma',
      },
      {
        name: 'Baringo',
      },
      {
        name: 'Nyandarua',
      },
      {
        name: 'Vihiga',
      },
      {
        name: 'Lamu',
      },
      {
        name: 'Machakos',
      },
      {
        name: 'Makueni',
      },
      {
        name: 'Elegeyo-Marakwet',
      },
      {
        name: 'Taita Taveta',
      },
      {
        name: 'Kajiado',
      },
      {
        name: 'Nyeri',
      },
      {
        name: 'Homa Bay',
      },
      {
        name: 'Bomet',
      },
      {
        name: 'Migori',
      },
      {
        name: 'Nakuru',
      },
      {
        name: 'Narok',
      },
      {
        name: 'Nyamira',
      },
      {
        name: 'Nandi',
      },
    ],
  },
  Kyrgyzstan: {
    countryName: 'Kyrgyzstan',
    provinces: [
      {
        name: 'Osh',
      },
      {
        name: 'Batken',
      },
      {
        name: 'Talas',
      },
      {
        name: 'Naryn',
      },
      {
        name: 'Issyk-Kul',
      },
      {
        name: 'Bishkek',
      },
      {
        name: 'Jalal-Abad',
      },
      {
        name: 'Chüy',
      },
      {
        name: 'Osh City',
      },
    ],
  },
  Cambodia: {
    countryName: 'Cambodia',
    provinces: [
      {
        name: 'Pursat',
      },
      {
        name: 'Battambang',
      },
      {
        name: 'Takeo',
      },
      {
        name: 'Svay Rieng',
      },
      {
        name: 'Stung Treng',
      },
      {
        name: 'Ŏtâr Méanchey',
      },
      {
        name: 'Siem Reap',
      },
      {
        name: 'Ratanakiri',
      },
      {
        name: 'Prey Veng',
      },
      {
        name: 'Preah Vihear',
      },
      {
        name: 'Phnom Penh',
      },
      {
        name: 'Pailin',
      },
      {
        name: 'Mondolkiri',
      },
      {
        name: 'Kratie',
      },
      {
        name: 'Kep',
      },
      {
        name: 'Koh Kong',
      },
      {
        name: 'Kandal',
      },
      {
        name: 'Kampot',
      },
      {
        name: 'Kampong Thom',
      },
      {
        name: 'Kampong Speu',
      },
      {
        name: 'Kampong Chhnang',
      },
      {
        name: 'Kampong Cham',
      },
      {
        name: 'Preah Sihanouk',
      },
      {
        name: 'Banteay Meanchey',
      },
      {
        name: 'Tboung Khmum',
      },
    ],
  },
  Kiribati: {
    countryName: 'Kiribati',
    provinces: [
      {
        name: 'Gilbert Islands',
      },
      {
        name: 'Line Islands',
      },
      {
        name: 'Phoenix Islands',
      },
    ],
  },
  Comoros: {
    countryName: 'Comoros',
    provinces: [
      {
        name: 'Mohéli',
      },
      {
        name: 'Grande Comore',
      },
      {
        name: 'Anjouan',
      },
    ],
  },
  'Saint Kitts and Nevis': {
    countryName: 'Saint Kitts and Nevis',
    provinces: [
      {
        name: 'Trinity Palmetto Point',
      },
      {
        name: 'Middle Island',
      },
      {
        name: 'Saint Thomas Lowland',
      },
      {
        name: 'Saint Peter Basseterre',
      },
      {
        name: 'Saint Paul Charlestown',
      },
      {
        name: 'Saint Paul Capesterre',
      },
      {
        name: 'Saint Mary Cayon',
      },
      {
        name: 'Saint John Figtree',
      },
      {
        name: 'Saint John Capesterre',
      },
      {
        name: 'Saint James Windwa',
      },
      {
        name: 'Saint George Gingerland',
      },
      {
        name: 'Saint George Basseterre',
      },
      {
        name: 'Saint Anne Sandy Point',
      },
      {
        name: 'Christ Church Nichola Town',
      },
    ],
  },
  'North Korea': {
    countryName: 'North Korea',
    provinces: [
      {
        name: 'Pyongyang',
      },
      {
        name: 'South Pyongan',
      },
      {
        name: "P'yŏngan-bukto",
      },
      {
        name: 'Kangwŏn-do',
      },
      {
        name: 'Hwanghae-namdo',
      },
      {
        name: 'Hwanghae-bukto',
      },
      {
        name: 'Hamgyŏng-namdo',
      },
      {
        name: 'Yanggang-do',
      },
      {
        name: 'Hamgyŏng-bukto',
      },
      {
        name: 'Chagang-do',
      },
      {
        name: 'Rason',
      },
    ],
  },
  'South Korea': {
    countryName: 'South Korea',
    provinces: [
      {
        name: 'Ulsan',
      },
      {
        name: 'Daejeon',
      },
      {
        name: 'Daegu',
      },
      {
        name: 'Seoul',
      },
      {
        name: 'Busan',
      },
      {
        name: 'Gyeongsangbuk-do',
      },
      {
        name: 'Gyeonggi-do',
      },
      {
        name: 'Gwangju',
      },
      {
        name: 'Gangwon-do',
      },
      {
        name: 'Incheon',
      },
      {
        name: 'Chungcheongnam-do',
      },
      {
        name: 'North Chungcheong',
      },
      {
        name: 'Jeollanam-do',
      },
      {
        name: 'Jeollabuk-do',
      },
      {
        name: 'Jeju-do',
      },
      {
        name: 'Gyeongsangnam-do',
      },
      {
        name: 'Sejong-si',
      },
    ],
  },
  Kosovo: {
    countryName: 'Kosovo',
    provinces: [
      {
        name: 'Ferizaj',
      },
      {
        name: 'Gjakova',
      },
      {
        name: 'Gjilan',
      },
      {
        name: 'Mitrovica',
      },
      {
        name: 'Pec',
      },
      {
        name: 'Pristina',
      },
      {
        name: 'Prizren',
      },
    ],
  },
  Kuwait: {
    countryName: 'Kuwait',
    provinces: [
      {
        name: 'Hawalli',
      },
      {
        name: 'Al Asimah',
      },
      {
        name: 'Al Jahrāʼ',
      },
      {
        name: 'Al Farwaniyah',
      },
      {
        name: 'Al Aḩmadī',
      },
      {
        name: 'Mubārak al Kabīr',
      },
    ],
  },
  'Cayman Islands': {
    countryName: 'Cayman Islands',
    provinces: [
      {
        name: 'George Town',
      },
      {
        name: 'West Bay',
      },
      {
        name: 'Bodden Town',
      },
      {
        name: 'North Side',
      },
      {
        name: 'East End',
      },
      {
        name: 'Sister Island',
      },
    ],
  },
  Kazakhstan: {
    countryName: 'Kazakhstan',
    provinces: [
      {
        name: 'Batys Qazaqstan',
      },
      {
        name: 'Mangghystaū',
      },
      {
        name: 'Atyraū',
      },
      {
        name: 'Aqtöbe',
      },
      {
        name: 'East Kazakhstan',
      },
      {
        name: 'Aqmola',
      },
      {
        name: 'North Kazakhstan',
      },
      {
        name: 'Pavlodar Region',
      },
      {
        name: 'Qyzylorda',
      },
      {
        name: 'Qostanay',
      },
      {
        name: 'Karaganda',
      },
      {
        name: 'Zhambyl',
      },
      {
        name: 'South Kazakhstan',
      },
      {
        name: 'Almaty',
      },
      {
        name: 'Almaty Oblysy',
      },
      {
        name: 'Shymkent',
      },
      {
        name: 'Baikonur',
      },
      {
        name: 'Nur-Sultan',
      },
    ],
  },
  Laos: {
    countryName: 'Laos',
    provinces: [
      {
        name: 'Xiangkhoang',
      },
      {
        name: 'Xiagnabouli',
      },
      {
        name: 'Vientiane',
      },
      {
        name: 'Savannahkhét',
      },
      {
        name: 'Salavan',
      },
      {
        name: 'Phôngsali',
      },
      {
        name: 'Oudômxai',
      },
      {
        name: 'Louangphabang',
      },
      {
        name: 'Loungnamtha',
      },
      {
        name: 'Khammouan',
      },
      {
        name: 'Houaphan',
      },
      {
        name: 'Champasak',
      },
      {
        name: 'Attapu',
      },
      {
        name: 'Xékong',
      },
      {
        name: 'Bokeo',
      },
      {
        name: 'Bolikhamsai',
      },
      {
        name: 'Vientiane Prefecture',
      },
      {
        name: 'Xaisomboun',
      },
    ],
  },
  Lebanon: {
    countryName: 'Lebanon',
    provinces: [
      {
        name: 'Mont-Liban',
      },
      {
        name: 'Beyrouth',
      },
      {
        name: 'Liban-Nord',
      },
      {
        name: 'South Governorate',
      },
      {
        name: 'Béqaa',
      },
      {
        name: 'Nabatîyé',
      },
      {
        name: 'Aakkâr',
      },
      {
        name: 'Baalbek-Hermel',
      },
    ],
  },
  'Saint Lucia': {
    countryName: 'Saint Lucia',
    provinces: [
      {
        name: 'Vieux-Fort',
      },
      {
        name: 'Soufrière',
      },
      {
        name: 'Micoud',
      },
      {
        name: 'Laborie',
      },
      {
        name: 'Gros-Islet',
      },
      {
        name: 'Dennery',
      },
      {
        name: 'Choiseul',
      },
      {
        name: 'Castries',
      },
      {
        name: 'Anse-la-Raye',
      },
      {
        name: 'Canaries',
      },
    ],
  },
  Liechtenstein: {
    countryName: 'Liechtenstein',
    provinces: [
      {
        name: 'Vaduz',
      },
      {
        name: 'Triesenberg',
      },
      {
        name: 'Triesen',
      },
      {
        name: 'Schellenberg',
      },
      {
        name: 'Schaan',
      },
      {
        name: 'Ruggell',
      },
      {
        name: 'Planken',
      },
      {
        name: 'Mauren',
      },
      {
        name: 'Gamprin',
      },
      {
        name: 'Eschen',
      },
      {
        name: 'Balzers',
      },
    ],
  },
  'Sri Lanka': {
    countryName: 'Sri Lanka',
    provinces: [
      {
        name: 'Western',
      },
      {
        name: 'Uva',
      },
      {
        name: 'Southern',
      },
      {
        name: 'Sabaragamuwa',
      },
      {
        name: 'North Western',
      },
      {
        name: 'North Central',
      },
      {
        name: 'Central',
      },
      {
        name: 'Northern Province',
      },
      {
        name: 'Eastern Province',
      },
    ],
  },
  Liberia: {
    countryName: 'Liberia',
    provinces: [
      {
        name: 'Sinoe',
      },
      {
        name: 'Nimba',
      },
      {
        name: 'Montserrado',
      },
      {
        name: 'Maryland',
      },
      {
        name: 'Lofa',
      },
      {
        name: 'Grand Gedeh',
      },
      {
        name: 'Grand Cape Mount',
      },
      {
        name: 'Grand Bassa',
      },
      {
        name: 'Bong',
      },
      {
        name: 'Bomi',
      },
      {
        name: 'Grand Kru',
      },
      {
        name: 'Margibi',
      },
      {
        name: 'River Cess',
      },
      {
        name: 'Gbarpolu',
      },
      {
        name: 'River Gee',
      },
    ],
  },
  Lesotho: {
    countryName: 'Lesotho',
    provinces: [
      {
        name: 'Thaba-Tseka',
      },
      {
        name: 'Quthing',
      },
      {
        name: 'Qachaʼs Nek',
      },
      {
        name: 'Mokhotlong',
      },
      {
        name: 'Mohaleʼs Hoek',
      },
      {
        name: 'Maseru',
      },
      {
        name: 'Mafeteng',
      },
      {
        name: 'Leribe',
      },
      {
        name: 'Butha-Buthe',
      },
      {
        name: 'Berea',
      },
    ],
  },
  Lithuania: {
    countryName: 'Lithuania',
    provinces: [
      {
        name: 'Alytus',
      },
      {
        name: 'Kaunas',
      },
      {
        name: 'Klaipėda County',
      },
      {
        name: 'Marijampolė County',
      },
      {
        name: 'Panevėžys',
      },
      {
        name: 'Siauliai',
      },
      {
        name: 'Tauragė County',
      },
      {
        name: 'Telsiai',
      },
      {
        name: 'Utena',
      },
      {
        name: 'Vilnius',
      },
    ],
  },
  Luxembourg: {
    countryName: 'Luxembourg',
    provinces: [
      {
        name: 'Wiltz',
      },
      {
        name: 'Vianden',
      },
      {
        name: 'Remich',
      },
      {
        name: 'Redange',
      },
      {
        name: 'Mersch',
      },
      {
        name: 'Luxembourg',
      },
      {
        name: 'Grevenmacher',
      },
      {
        name: 'Esch-sur-Alzette',
      },
      {
        name: 'Echternach',
      },
      {
        name: 'Diekirch',
      },
      {
        name: 'Clervaux',
      },
      {
        name: 'Capellen',
      },
    ],
  },
  Latvia: {
    countryName: 'Latvia',
    provinces: [
      {
        name: 'Ventspils Rajons',
      },
      {
        name: 'Ventspils',
      },
      {
        name: 'Valmiera',
      },
      {
        name: 'Valka',
      },
      {
        name: 'Tukuma novads',
      },
      {
        name: 'Talsi Municipality',
      },
      {
        name: 'Saldus Rajons',
      },
      {
        name: 'Riga',
      },
      {
        name: 'Rēzeknes Novads',
      },
      {
        name: 'Rēzekne',
      },
      {
        name: 'Preiļu novads',
      },
      {
        name: 'Ogre',
      },
      {
        name: 'Madona Municipality',
      },
      {
        name: 'Ludzas novads',
      },
      {
        name: 'Limbažu novads',
      },
      {
        name: 'Liepāja',
      },
      {
        name: 'Kuldīgas novads',
      },
      {
        name: 'Krāslavas novads',
      },
      {
        name: 'Jūrmala',
      },
      {
        name: 'Jelgavas novads',
      },
      {
        name: 'Jelgava',
      },
      {
        name: 'Jēkabpils Municipality',
      },
      {
        name: 'Gulbenes novads',
      },
      {
        name: 'Dobeles novads',
      },
      {
        name: 'Daugavpils municipality',
      },
      {
        name: 'Daugavpils',
      },
      {
        name: 'Cēsu Rajons',
      },
      {
        name: 'Bauskas Rajons',
      },
      {
        name: 'Balvu Novads',
      },
      {
        name: 'Alūksnes Novads',
      },
      {
        name: 'Aizkraukles novads',
      },
      {
        name: 'Dundaga',
      },
      {
        name: 'Alsunga',
      },
      {
        name: 'Pāvilostas',
      },
      {
        name: 'Nīca',
      },
      {
        name: 'Rucavas',
      },
      {
        name: 'Grobiņa',
      },
      {
        name: 'Durbe',
      },
      {
        name: 'Aizpute',
      },
      {
        name: 'Priekule',
      },
      {
        name: 'Vaiņode',
      },
      {
        name: 'Skrunda',
      },
      {
        name: 'Brocēni',
      },
      {
        name: 'Rojas',
      },
      {
        name: 'Kandava',
      },
      {
        name: 'Auces',
      },
      {
        name: 'Jaunpils',
      },
      {
        name: 'Engure',
      },
      {
        name: 'Tērvete',
      },
      {
        name: 'Ozolnieku',
      },
      {
        name: 'Rundāles',
      },
      {
        name: 'Babīte',
      },
      {
        name: 'Mārupe',
      },
      {
        name: 'Olaine',
      },
      {
        name: 'Lecava',
      },
      {
        name: 'Ķekava',
      },
      {
        name: 'Salaspils',
      },
      {
        name: 'Baldone',
      },
      {
        name: 'Stopiņi',
      },
      {
        name: 'Carnikava',
      },
      {
        name: 'Ādaži',
      },
      {
        name: 'Garkalne',
      },
      {
        name: 'Vecumnieki',
      },
      {
        name: 'Ķegums',
      },
      {
        name: 'Lielvārde',
      },
      {
        name: 'Skrīveri',
      },
      {
        name: 'Jaunjelgava',
      },
      {
        name: 'Nereta',
      },
      {
        name: 'Viesīte',
      },
      {
        name: 'Salas',
      },
      {
        name: 'Jēkabpils',
      },
      {
        name: 'Aknīste',
      },
      {
        name: 'Ilūkste',
      },
      {
        name: 'Vārkava',
      },
      {
        name: 'Līvāni',
      },
      {
        name: 'Varakļāni',
      },
      {
        name: 'Vilanu',
      },
      {
        name: 'Riebiņu',
      },
      {
        name: 'Aglona',
      },
      {
        name: 'Cibla',
      },
      {
        name: 'Zilupes',
      },
      {
        name: 'Viļaka',
      },
      {
        name: 'Baltinava',
      },
      {
        name: 'Dagda',
      },
      {
        name: 'Karsava',
      },
      {
        name: 'Rugāju',
      },
      {
        name: 'Cesvaine',
      },
      {
        name: 'Lubāna',
      },
      {
        name: 'Krustpils',
      },
      {
        name: 'Pļaviņu',
      },
      {
        name: 'Koknese',
      },
      {
        name: 'Ikšķile',
      },
      {
        name: 'Ropažu',
      },
      {
        name: 'Inčukalns',
      },
      {
        name: 'Krimulda',
      },
      {
        name: 'Sigulda',
      },
      {
        name: 'Līgatne',
      },
      {
        name: 'Mālpils',
      },
      {
        name: 'Sēja',
      },
      {
        name: 'Saulkrastu',
      },
      {
        name: 'Salacgrīvas',
      },
      {
        name: 'Aloja',
      },
      {
        name: 'Naukšēni',
      },
      {
        name: 'Rūjienas',
      },
      {
        name: 'Mazsalaca',
      },
      {
        name: 'Burtnieki',
      },
      {
        name: 'Pārgaujas',
      },
      {
        name: 'Kocēni',
      },
      {
        name: 'Amatas',
      },
      {
        name: 'Priekuļi',
      },
      {
        name: 'Raunas',
      },
      {
        name: 'Strenči',
      },
      {
        name: 'Beverīna',
      },
      {
        name: 'Smiltene',
      },
      {
        name: 'Jaunpiebalga',
      },
      {
        name: 'Ērgļi',
      },
      {
        name: 'Vecpiebalga',
      },
      {
        name: 'Apes',
      },
      {
        name: 'Mesraga',
      },
    ],
  },
  Libya: {
    countryName: 'Libya',
    provinces: [
      {
        name: 'Darnah',
      },
      {
        name: 'Banghāzī',
      },
      {
        name: 'Al Marj',
      },
      {
        name: 'Al Kufrah',
      },
      {
        name: 'Al Jabal al Akhḑar',
      },
      {
        name: 'Tripoli',
      },
      {
        name: 'Surt',
      },
      {
        name: 'Sabhā',
      },
      {
        name: 'Nālūt',
      },
      {
        name: 'Murzuq',
      },
      {
        name: 'Mişrātah',
      },
      {
        name: 'Ghāt',
      },
      {
        name: 'Az Zāwiyah',
      },
      {
        name: 'Ash Shāţiʼ',
      },
      {
        name: 'Al Jufrah',
      },
      {
        name: 'An Nuqāţ al Khams',
      },
      {
        name: 'Al Buţnān',
      },
      {
        name: 'Jabal al Gharbi',
      },
      {
        name: 'Al Jafārah',
      },
      {
        name: 'Al Marqab',
      },
      {
        name: 'Al Wāḩāt',
      },
      {
        name: 'Wādī al Ḩayāt',
      },
    ],
  },
  Morocco: {
    countryName: 'Morocco',
    provinces: [
      {
        name: 'Tanger-Tetouan-Al Hoceima',
      },
      {
        name: 'Oriental',
      },
      {
        name: 'Fès-Meknès',
      },
      {
        name: 'Rabat-Salé-Kénitra',
      },
      {
        name: 'Béni Mellal-Khénifra',
      },
      {
        name: 'Casablanca-Settat',
      },
      {
        name: 'Marrakesh-Safi',
      },
      {
        name: 'Drâa-Tafilalet',
      },
      {
        name: 'Souss-Massa',
      },
      {
        name: 'Guelmim-Oued Noun',
      },
      {
        name: 'Laâyoune-Sakia El Hamra',
      },
      {
        name: 'Dakhla-Oued Ed-Dahab',
      },
    ],
  },
  Monaco: {
    countryName: 'Monaco',
    provinces: [
      {
        name: 'Municipality of Monaco',
      },
    ],
  },
  Moldova: {
    countryName: 'Moldova',
    provinces: [
      {
        name: 'Raionul Edineţ',
      },
      {
        name: 'Ungheni',
      },
      {
        name: 'Teleneşti',
      },
      {
        name: 'Taraclia',
      },
      {
        name: 'Ştefan-Vodă',
      },
      {
        name: 'Strășeni',
      },
      {
        name: 'Raionul Soroca',
      },
      {
        name: 'Rîşcani',
      },
      {
        name: 'Rezina',
      },
      {
        name: 'Orhei',
      },
      {
        name: 'Raionul Ocniţa',
      },
      {
        name: 'Anenii Noi',
      },
      {
        name: 'Nisporeni',
      },
      {
        name: 'Leova',
      },
      {
        name: 'Sîngerei',
      },
      {
        name: 'Criuleni',
      },
      {
        name: 'Ialoveni',
      },
      {
        name: 'Chișinău Municipality',
      },
      {
        name: 'Căuşeni',
      },
      {
        name: 'Cantemir',
      },
      {
        name: 'Călăraşi',
      },
      {
        name: 'Cahul',
      },
      {
        name: 'Glodeni',
      },
      {
        name: 'Floreşti',
      },
      {
        name: 'Fălești',
      },
      {
        name: 'Dubăsari',
      },
      {
        name: 'Drochia',
      },
      {
        name: 'Donduşeni',
      },
      {
        name: 'Cimişlia',
      },
      {
        name: 'Briceni',
      },
      {
        name: 'Basarabeasca',
      },
      {
        name: 'Hînceşti',
      },
      {
        name: 'Şoldăneşti',
      },
      {
        name: 'Transnistria',
      },
      {
        name: 'Găgăuzia',
      },
      {
        name: 'Bender Municipality',
      },
      {
        name: 'Bălţi',
      },
    ],
  },
  Montenegro: {
    countryName: 'Montenegro',
    provinces: [
      {
        name: 'Opština Rožaje',
      },
      {
        name: 'Opština Žabljak',
      },
      {
        name: 'Ulcinj',
      },
      {
        name: 'Tivat',
      },
      {
        name: 'Podgorica',
      },
      {
        name: 'Opština Šavnik',
      },
      {
        name: 'Opština Plužine',
      },
      {
        name: 'Pljevlja',
      },
      {
        name: 'Opština Plav',
      },
      {
        name: 'Opština Nikšić',
      },
      {
        name: 'Mojkovac',
      },
      {
        name: 'Kotor',
      },
      {
        name: 'Opština Kolašin',
      },
      {
        name: 'Berane',
      },
      {
        name: 'Herceg Novi',
      },
      {
        name: 'Danilovgrad',
      },
      {
        name: 'Cetinje',
      },
      {
        name: 'Budva',
      },
      {
        name: 'Bijelo Polje',
      },
      {
        name: 'Bar',
      },
      {
        name: 'Andrijevica',
      },
      {
        name: 'Gusinje',
      },
      {
        name: 'Petnjica',
      },
      {
        name: 'Tuzi',
      },
    ],
  },
  'Saint Martin': {
    countryName: 'Saint Martin',
    provinces: null,
  },
  Madagascar: {
    countryName: 'Madagascar',
    provinces: [
      {
        name: 'Diana',
      },
      {
        name: 'Sava',
      },
      {
        name: 'Sofia',
      },
      {
        name: 'Analanjirofo',
      },
      {
        name: 'Boeny',
      },
      {
        name: 'Betsiboka',
      },
      {
        name: 'Alaotra Mangoro',
      },
      {
        name: 'Melaky',
      },
      {
        name: 'Bongolava',
      },
      {
        name: 'Vakinankaratra',
      },
      {
        name: 'Itasy',
      },
      {
        name: 'Analamanga',
      },
      {
        name: 'Atsinanana',
      },
      {
        name: 'Menabe',
      },
      {
        name: "Amoron'i Mania",
      },
      {
        name: 'Upper Matsiatra',
      },
      {
        name: 'Vatovavy Fitovinany',
      },
      {
        name: 'Ihorombe',
      },
      {
        name: 'Atsimo-Atsinanana',
      },
      {
        name: 'Anosy',
      },
      {
        name: 'Androy',
      },
      {
        name: 'Atsimo-Andrefana',
      },
    ],
  },
  'Marshall Islands': {
    countryName: 'Marshall Islands',
    provinces: [
      {
        name: 'Ailinginae Atoll',
      },
      {
        name: 'Ailinglaplap Atoll',
      },
      {
        name: 'Ailuk Atoll',
      },
      {
        name: 'Arno Atoll',
      },
      {
        name: 'Aur Atoll',
      },
      {
        name: 'Bikar Atoll',
      },
      {
        name: 'Bikini Atoll',
      },
      {
        name: 'Ebon Atoll',
      },
      {
        name: 'Enewetak Atoll',
      },
      {
        name: 'Erikub Atoll',
      },
      {
        name: 'Jaluit Atoll',
      },
      {
        name: 'Kwajalein Atoll',
      },
      {
        name: 'Lae Atoll',
      },
      {
        name: 'Likiep Atoll',
      },
      {
        name: 'Majuro Atoll',
      },
      {
        name: 'Maloelap Atoll',
      },
      {
        name: 'Mili Atoll',
      },
      {
        name: 'Namdrik Atoll',
      },
      {
        name: 'Namu Atoll',
      },
      {
        name: 'Rongelap Atoll',
      },
      {
        name: 'Rongrik Atoll',
      },
      {
        name: 'Taka Atoll',
      },
      {
        name: 'Bokak Atoll',
      },
      {
        name: 'Ujae Atoll',
      },
      {
        name: 'Ujelang',
      },
      {
        name: 'Utrik Atoll',
      },
      {
        name: 'Wotho Atoll',
      },
      {
        name: 'Wotje Atoll',
      },
      {
        name: 'Jabat Island',
      },
      {
        name: 'Jemo Island',
      },
      {
        name: 'Kili Island',
      },
      {
        name: 'Lib Island',
      },
      {
        name: 'Mejit Island',
      },
    ],
  },
  'North Macedonia': {
    countryName: 'North Macedonia',
    provinces: [
      {
        name: 'Valandovo',
      },
      {
        name: 'Resen',
      },
      {
        name: 'Kratovo',
      },
      {
        name: 'Pehchevo',
      },
      {
        name: 'Novo Selo',
      },
      {
        name: 'Bosilovo',
      },
      {
        name: 'Vasilevo',
      },
      {
        name: 'Dojran',
      },
      {
        name: 'Bogdanci',
      },
      {
        name: 'Konche',
      },
      {
        name: 'Makedonska Kamenica',
      },
      {
        name: 'Zrnovci',
      },
      {
        name: 'Karbinci',
      },
      {
        name: 'Demir Kapija',
      },
      {
        name: 'Rosoman',
      },
      {
        name: 'Gradsko',
      },
      {
        name: 'Lozovo',
      },
      {
        name: 'Češinovo-Obleševo',
      },
      {
        name: 'Novaci',
      },
      {
        name: 'Berovo',
      },
      {
        name: 'Bitola',
      },
      {
        name: 'Mogila',
      },
      {
        name: 'Arachinovo',
      },
      {
        name: 'Bogovinje',
      },
      {
        name: 'Brvenica',
      },
      {
        name: 'Chashka',
      },
      {
        name: 'Centar Zhupa',
      },
      {
        name: 'Chucher Sandevo',
      },
      {
        name: 'Debar',
      },
      {
        name: 'Delchevo',
      },
      {
        name: 'Demir Hisar',
      },
      {
        name: 'Dolneni',
      },
      {
        name: 'Gevgelija',
      },
      {
        name: 'Gostivar',
      },
      {
        name: 'Ilinden',
      },
      {
        name: 'Jegunovce',
      },
      {
        name: 'Kavadarci',
      },
      {
        name: 'Kichevo',
      },
      {
        name: 'Kochani',
      },
      {
        name: 'Kriva Palanka',
      },
      {
        name: 'Krivogashtani',
      },
      {
        name: 'Krushevo',
      },
      {
        name: 'Kumanovo',
      },
      {
        name: 'Lipkovo',
      },
      {
        name: 'Makedonski Brod',
      },
      {
        name: 'Negotino',
      },
      {
        name: 'Ohrid',
      },
      {
        name: 'Petrovec',
      },
      {
        name: 'Plasnica',
      },
      {
        name: 'Prilep',
      },
      {
        name: 'Probishtip',
      },
      {
        name: 'Radovish',
      },
      {
        name: 'Rankovce',
      },
      {
        name: 'Mavrovo and Rostuša',
      },
      {
        name: 'Sopište',
      },
      {
        name: 'Staro Nagorichane',
      },
      {
        name: 'Shtip',
      },
      {
        name: 'Struga',
      },
      {
        name: 'Strumica',
      },
      {
        name: 'Studenichani',
      },
      {
        name: 'Sveti Nikole',
      },
      {
        name: 'Tearce',
      },
      {
        name: 'Tetovo',
      },
      {
        name: 'Veles',
      },
      {
        name: 'Vevchani',
      },
      {
        name: 'Vinica',
      },
      {
        name: 'Vrapchishte',
      },
      {
        name: 'Zelenikovo',
      },
      {
        name: 'Zhelino',
      },
      {
        name: 'Debarca',
      },
      {
        name: 'Grad Skopje',
      },
    ],
  },
  Mali: {
    countryName: 'Mali',
    provinces: [
      {
        name: 'Tombouctou',
      },
      {
        name: 'Sikasso',
      },
      {
        name: 'Ségou',
      },
      {
        name: 'Mopti',
      },
      {
        name: 'Koulikoro',
      },
      {
        name: 'Kayes',
      },
      {
        name: 'Gao',
      },
      {
        name: 'Bamako',
      },
      {
        name: 'Kidal',
      },
      {
        name: 'Taoudénit',
      },
      {
        name: 'Ménaka',
      },
    ],
  },
  Myanmar: {
    countryName: 'Myanmar',
    provinces: [
      {
        name: 'Tanintharyi',
      },
      {
        name: 'Shan',
      },
      {
        name: 'Sagain',
      },
      {
        name: 'Rangoon',
      },
      {
        name: 'Rakhine',
      },
      {
        name: 'Bago',
      },
      {
        name: 'Mon',
      },
      {
        name: 'Mandalay',
      },
      {
        name: 'Magway',
      },
      {
        name: 'Kayah',
      },
      {
        name: 'Kayin',
      },
      {
        name: 'Kachin',
      },
      {
        name: 'Ayeyarwady',
      },
      {
        name: 'Chin',
      },
      {
        name: 'Nay Pyi Taw',
      },
    ],
  },
  Mongolia: {
    countryName: 'Mongolia',
    provinces: [
      {
        name: 'Uvs',
      },
      {
        name: 'Hovd',
      },
      {
        name: 'Govĭ-Altay',
      },
      {
        name: 'Dzabkhan',
      },
      {
        name: 'Bayan-Ölgiy',
      },
      {
        name: 'Bayanhongor',
      },
      {
        name: 'Ulaanbaatar',
      },
      {
        name: 'Central Aimak',
      },
      {
        name: 'Sühbaatar',
      },
      {
        name: 'Selenge',
      },
      {
        name: 'Övörhangay',
      },
      {
        name: 'Ömnögovĭ',
      },
      {
        name: 'Hövsgöl',
      },
      {
        name: 'Hentiy',
      },
      {
        name: 'Middle Govĭ',
      },
      {
        name: 'East Gobi Aymag',
      },
      {
        name: 'East Aimak',
      },
      {
        name: 'Bulgan',
      },
      {
        name: 'Arhangay',
      },
      {
        name: 'Darhan Uul',
      },
      {
        name: 'Govĭ-Sumber',
      },
      {
        name: 'Orhon',
      },
    ],
  },
  Macao: {
    countryName: 'Macao',
    provinces: [
      {
        name: 'Nossa Senhora de Fátima',
      },
      {
        name: 'Santo António',
      },
      {
        name: 'São Lázaro',
      },
      {
        name: 'Sé',
      },
      {
        name: 'São Lourenço',
      },
      {
        name: 'Nossa Senhora do Carmo',
      },
      {
        name: 'Cotai',
      },
      {
        name: 'São Francisco Xavier',
      },
    ],
  },
  'Northern Mariana Islands': {
    countryName: 'Northern Mariana Islands',
    provinces: [
      {
        name: 'Rota',
      },
      {
        name: 'Saipan',
      },
      {
        name: 'Tinian',
      },
      {
        name: 'Northern Islands',
      },
    ],
  },
  Martinique: {
    countryName: 'Martinique',
    provinces: [
      {
        name: 'Martinique',
      },
    ],
  },
  Mauritania: {
    countryName: 'Mauritania',
    provinces: [
      {
        name: 'Trarza',
      },
      {
        name: 'Tiris Zemmour',
      },
      {
        name: 'Tagant',
      },
      {
        name: 'Inchiri',
      },
      {
        name: 'Hodh El Gharbi',
      },
      {
        name: 'Hodh Ech Chargi',
      },
      {
        name: 'Guidimaka',
      },
      {
        name: 'Gorgol',
      },
      {
        name: 'Dakhlet Nouadhibou',
      },
      {
        name: 'Brakna',
      },
      {
        name: 'Assaba',
      },
      {
        name: 'Adrar',
      },
      {
        name: 'Nouakchott Ouest',
      },
      {
        name: 'Nouakchott Nord',
      },
      {
        name: 'Nouakchott Sud',
      },
    ],
  },
  Montserrat: {
    countryName: 'Montserrat',
    provinces: [
      {
        name: 'Saint Peter',
      },
      {
        name: 'Saint Georges',
      },
      {
        name: 'Saint Anthony',
      },
    ],
  },
  Malta: {
    countryName: 'Malta',
    provinces: [
      {
        name: 'Attard',
      },
      {
        name: 'Balzan',
      },
      {
        name: 'Il-Birgu',
      },
      {
        name: 'Birkirkara',
      },
      {
        name: 'Birżebbuġa',
      },
      {
        name: 'Bormla',
      },
      {
        name: 'Dingli',
      },
      {
        name: 'Il-Fgura',
      },
      {
        name: 'Il-Furjana',
      },
      {
        name: 'Il-Fontana',
      },
      {
        name: 'Għajnsielem',
      },
      {
        name: 'L-Għarb',
      },
      {
        name: 'Ħal Għargħur',
      },
      {
        name: 'L-Għasri',
      },
      {
        name: 'Ħal Għaxaq',
      },
      {
        name: 'Il-Gudja',
      },
      {
        name: 'Il-Gżira',
      },
      {
        name: 'Il-Ħamrun',
      },
      {
        name: 'L-Iklin',
      },
      {
        name: 'L-Imdina',
      },
      {
        name: 'L-Imġarr',
      },
      {
        name: 'L-Imqabba',
      },
      {
        name: 'L-Imsida',
      },
      {
        name: 'Mtarfa',
      },
      {
        name: 'Senglea',
      },
      {
        name: 'Il-Kalkara',
      },
      {
        name: 'Ta’ Kerċem',
      },
      {
        name: 'Kirkop',
      },
      {
        name: 'Lija',
      },
      {
        name: 'Luqa',
      },
      {
        name: 'Il-Marsa',
      },
      {
        name: 'Marsaskala',
      },
      {
        name: 'Marsaxlokk',
      },
      {
        name: 'Il-Mellieħa',
      },
      {
        name: 'Il-Mosta',
      },
      {
        name: 'Il-Munxar',
      },
      {
        name: 'In-Nadur',
      },
      {
        name: 'In-Naxxar',
      },
      {
        name: 'Paola',
      },
      {
        name: 'Pembroke',
      },
      {
        name: 'Tal-Pietà',
      },
      {
        name: 'Il-Qala',
      },
      {
        name: 'Qormi',
      },
      {
        name: 'Il-Qrendi',
      },
      {
        name: 'Ir-Rabat',
      },
      {
        name: 'Victoria',
      },
      {
        name: 'Safi',
      },
      {
        name: 'Saint John',
      },
      {
        name: 'Saint Julian',
      },
      {
        name: 'Saint Lawrence',
      },
      {
        name: 'Saint Lucia',
      },
      {
        name: 'Saint Paul’s Bay',
      },
      {
        name: 'Saint Venera',
      },
      {
        name: 'Sannat',
      },
      {
        name: 'Is-Siġġiewi',
      },
      {
        name: 'Tas-Sliema',
      },
      {
        name: 'Is-Swieqi',
      },
      {
        name: 'Tarxien',
      },
      {
        name: 'Ta’ Xbiex',
      },
      {
        name: 'Ix-Xagħra',
      },
      {
        name: 'Ix-Xewkija',
      },
      {
        name: 'Ix-Xgħajra',
      },
      {
        name: 'Ħaż-Żabbar',
      },
      {
        name: 'Ħaż-Żebbuġ',
      },
      {
        name: 'Iż-Żebbuġ',
      },
      {
        name: 'Iż-Żejtun',
      },
      {
        name: 'Iż-Żurrieq',
      },
      {
        name: 'Valletta',
      },
    ],
  },
  Mauritius: {
    countryName: 'Mauritius',
    provinces: [
      {
        name: 'Agalega Islands',
      },
      {
        name: 'Savanne',
      },
      {
        name: 'Rivière du Rempart',
      },
      {
        name: 'Port Louis',
      },
      {
        name: 'Plaines Wilhems',
      },
      {
        name: 'Pamplemousses',
      },
      {
        name: 'Moka',
      },
      {
        name: 'Grand Port',
      },
      {
        name: 'Flacq',
      },
      {
        name: 'Black River',
      },
      {
        name: 'Cargados Carajos',
      },
      {
        name: 'Rodrigues',
      },
    ],
  },
  Maldives: {
    countryName: 'Maldives',
    provinces: [
      {
        name: 'Vaavu Atholhu',
      },
      {
        name: 'Thaa Atholhu',
      },
      {
        name: 'Shaviyani Atholhu',
      },
      {
        name: 'Seenu',
      },
      {
        name: 'Raa Atoll',
      },
      {
        name: 'Noonu Atoll',
      },
      {
        name: 'Gnyaviyani Atoll',
      },
      {
        name: 'Meemu Atholhu',
      },
      {
        name: 'Lhaviyani Atholhu',
      },
      {
        name: 'Laamu',
      },
      {
        name: 'Kaafu Atoll',
      },
      {
        name: 'Haa Dhaalu Atholhu',
      },
      {
        name: 'Haa Alifu Atholhu',
      },
      {
        name: 'Gaafu Dhaalu Atoll',
      },
      {
        name: 'Gaafu Alif Atoll',
      },
      {
        name: 'Faafu Atholhu',
      },
      {
        name: 'Dhaalu Atholhu',
      },
      {
        name: 'Baa Atholhu',
      },
      {
        name: 'Northern Ari Atoll',
      },
      {
        name: 'Male',
      },
      {
        name: 'Southern Ari Atoll',
      },
    ],
  },
  Malawi: {
    countryName: 'Malawi',
    provinces: [
      {
        name: 'Southern Region',
      },
      {
        name: 'Northern Region',
      },
      {
        name: 'Central Region',
      },
    ],
  },
  Mexico: {
    countryName: 'Mexico',
    provinces: [
      {
        name: 'Yucatán',
      },
      {
        name: 'Veracruz',
      },
      {
        name: 'Tlaxcala',
      },
      {
        name: 'Tamaulipas',
      },
      {
        name: 'Tabasco',
      },
      {
        name: 'Quintana Roo',
      },
      {
        name: 'Querétaro',
      },
      {
        name: 'Puebla',
      },
      {
        name: 'Oaxaca',
      },
      {
        name: 'Nuevo León',
      },
      {
        name: 'Morelos',
      },
      {
        name: 'México',
      },
      {
        name: 'Hidalgo',
      },
      {
        name: 'Guerrero',
      },
      {
        name: 'Mexico City',
      },
      {
        name: 'Chiapas',
      },
      {
        name: 'Campeche',
      },
      {
        name: 'Zacatecas',
      },
      {
        name: 'Sonora',
      },
      {
        name: 'Sinaloa',
      },
      {
        name: 'San Luis Potosí',
      },
      {
        name: 'Nayarit',
      },
      {
        name: 'Michoacán',
      },
      {
        name: 'Jalisco',
      },
      {
        name: 'Guanajuato',
      },
      {
        name: 'Durango',
      },
      {
        name: 'Colima',
      },
      {
        name: 'Coahuila',
      },
      {
        name: 'Chihuahua',
      },
      {
        name: 'Baja California Sur',
      },
      {
        name: 'Baja California',
      },
      {
        name: 'Aguascalientes',
      },
    ],
  },
  Malaysia: {
    countryName: 'Malaysia',
    provinces: [
      {
        name: 'Melaka',
      },
      {
        name: 'Terengganu',
      },
      {
        name: 'Selangor',
      },
      {
        name: 'Sarawak',
      },
      {
        name: 'Sabah',
      },
      {
        name: 'Perlis',
      },
      {
        name: 'Perak',
      },
      {
        name: 'Pahang',
      },
      {
        name: 'Negeri Sembilan',
      },
      {
        name: 'Kelantan',
      },
      {
        name: 'Kuala Lumpur',
      },
      {
        name: 'Penang',
      },
      {
        name: 'Kedah',
      },
      {
        name: 'Johor',
      },
      {
        name: 'Labuan',
      },
      {
        name: 'Putrajaya',
      },
    ],
  },
  Mozambique: {
    countryName: 'Mozambique',
    provinces: [
      {
        name: 'Zambézia',
      },
      {
        name: 'Tete',
      },
      {
        name: 'Sofala',
      },
      {
        name: 'Niassa',
      },
      {
        name: 'Nampula',
      },
      {
        name: 'Maputo Province',
      },
      {
        name: 'Manica',
      },
      {
        name: 'Inhambane',
      },
      {
        name: 'Gaza',
      },
      {
        name: 'Cabo Delgado',
      },
      {
        name: 'Maputo City',
      },
    ],
  },
  Namibia: {
    countryName: 'Namibia',
    provinces: [
      {
        name: 'Zambezi',
      },
      {
        name: 'Khomas',
      },
      {
        name: 'Erongo',
      },
      {
        name: 'Hardap',
      },
      {
        name: 'Karas',
      },
      {
        name: 'Kunene',
      },
      {
        name: 'Ohangwena',
      },
      {
        name: 'Omaheke',
      },
      {
        name: 'Omusati',
      },
      {
        name: 'Oshana',
      },
      {
        name: 'Oshikoto',
      },
      {
        name: 'Otjozondjupa',
      },
      {
        name: 'Kavango East',
      },
      {
        name: 'Kavango West',
      },
    ],
  },
  'New Caledonia': {
    countryName: 'New Caledonia',
    provinces: [
      {
        name: 'South Province',
      },
      {
        name: 'North Province',
      },
      {
        name: 'Loyalty Islands',
      },
    ],
  },
  Niger: {
    countryName: 'Niger',
    provinces: [
      {
        name: 'Zinder',
      },
      {
        name: 'Tahoua',
      },
      {
        name: 'Maradi',
      },
      {
        name: 'Dosso',
      },
      {
        name: 'Diffa',
      },
      {
        name: 'Agadez',
      },
      {
        name: 'Tillabéri',
      },
      {
        name: 'Niamey',
      },
    ],
  },
  'Norfolk Island': {
    countryName: 'Norfolk Island',
    provinces: null,
  },
  Nigeria: {
    countryName: 'Nigeria',
    provinces: [
      {
        name: 'Sokoto',
      },
      {
        name: 'Rivers',
      },
      {
        name: 'Plateau',
      },
      {
        name: 'Oyo',
      },
      {
        name: 'Ondo',
      },
      {
        name: 'Ogun',
      },
      {
        name: 'Niger',
      },
      {
        name: 'Lagos',
      },
      {
        name: 'Kwara',
      },
      {
        name: 'Katsina',
      },
      {
        name: 'Kano',
      },
      {
        name: 'Kaduna',
      },
      {
        name: 'Imo',
      },
      {
        name: 'Cross River',
      },
      {
        name: 'Borno',
      },
      {
        name: 'Benue',
      },
      {
        name: 'Bauchi',
      },
      {
        name: 'Anambra',
      },
      {
        name: 'Akwa Ibom',
      },
      {
        name: 'FCT',
      },
      {
        name: 'Abia',
      },
      {
        name: 'Delta',
      },
      {
        name: 'Adamawa',
      },
      {
        name: 'Edo',
      },
      {
        name: 'Enugu',
      },
      {
        name: 'Jigawa',
      },
      {
        name: 'Bayelsa',
      },
      {
        name: 'Ebonyi',
      },
      {
        name: 'Ekiti',
      },
      {
        name: 'Gombe',
      },
      {
        name: 'Nassarawa',
      },
      {
        name: 'Zamfara',
      },
      {
        name: 'Kebbi',
      },
      {
        name: 'Kogi',
      },
      {
        name: 'Osun',
      },
      {
        name: 'Taraba',
      },
      {
        name: 'Yobe',
      },
    ],
  },
  Nicaragua: {
    countryName: 'Nicaragua',
    provinces: [
      {
        name: 'Rivas',
      },
      {
        name: 'Río San Juan',
      },
      {
        name: 'Nueva Segovia',
      },
      {
        name: 'Matagalpa',
      },
      {
        name: 'Masaya',
      },
      {
        name: 'Managua',
      },
      {
        name: 'Madriz',
      },
      {
        name: 'León',
      },
      {
        name: 'Jinotega',
      },
      {
        name: 'Granada',
      },
      {
        name: 'Estelí',
      },
      {
        name: 'Chontales',
      },
      {
        name: 'Chinandega',
      },
      {
        name: 'Carazo',
      },
      {
        name: 'Boaco',
      },
      {
        name: 'North Caribbean Coast',
      },
      {
        name: 'South Caribbean Coast',
      },
    ],
  },
  Netherlands: {
    countryName: 'Netherlands',
    provinces: [
      {
        name: 'South Holland',
      },
      {
        name: 'Zeeland',
      },
      {
        name: 'Utrecht',
      },
      {
        name: 'Overijssel',
      },
      {
        name: 'North Holland',
      },
      {
        name: 'North Brabant',
      },
      {
        name: 'Limburg',
      },
      {
        name: 'Groningen',
      },
      {
        name: 'Gelderland',
      },
      {
        name: 'Friesland',
      },
      {
        name: 'Drenthe',
      },
      {
        name: 'Flevoland',
      },
    ],
  },
  Norway: {
    countryName: 'Norway',
    provinces: [
      {
        name: 'Rogaland',
      },
      {
        name: 'Oslo',
      },
      {
        name: 'Nordland',
      },
      {
        name: 'Møre og Romsdal',
      },
      {
        name: 'Trøndelag',
      },
      {
        name: 'Viken',
      },
      {
        name: 'Agder',
      },
      {
        name: 'Troms og Finnmark',
      },
      {
        name: 'Innlandet',
      },
      {
        name: 'Vestfold og Telemark',
      },
      {
        name: 'Vestland',
      },
    ],
  },
  Nepal: {
    countryName: 'Nepal',
    provinces: [
      {
        name: 'Province 1',
      },
      {
        name: 'Province 2',
      },
      {
        name: 'Bagmati Province',
      },
      {
        name: 'Province 4',
      },
      {
        name: 'Lumbini Province',
      },
      {
        name: 'Karnali Pradesh',
      },
      {
        name: 'Sudurpashchim Pradesh',
      },
    ],
  },
  Nauru: {
    countryName: 'Nauru',
    provinces: [
      {
        name: 'Yaren',
      },
      {
        name: 'Uaboe',
      },
      {
        name: 'Nibok',
      },
      {
        name: 'Meneng',
      },
      {
        name: 'Ijuw',
      },
      {
        name: 'Ewa',
      },
      {
        name: 'Denigomodu',
      },
      {
        name: 'Buada',
      },
      {
        name: 'Boe',
      },
      {
        name: 'Baiti',
      },
      {
        name: 'Anibare',
      },
      {
        name: 'Anetan',
      },
      {
        name: 'Anabar',
      },
      {
        name: 'Aiwo',
      },
    ],
  },
  Niue: {
    countryName: 'Niue',
    provinces: null,
  },
  'New Zealand': {
    countryName: 'New Zealand',
    provinces: [
      {
        name: 'Wellington',
      },
      {
        name: 'Manawatu-Wanganui',
      },
      {
        name: 'Waikato',
      },
      {
        name: 'Tasman',
      },
      {
        name: 'Taranaki',
      },
      {
        name: 'Southland',
      },
      {
        name: 'Bay of Plenty',
      },
      {
        name: 'Northland',
      },
      {
        name: 'Marlborough',
      },
      {
        name: "Hawke's Bay",
      },
      {
        name: 'Gisborne',
      },
      {
        name: 'Canterbury',
      },
      {
        name: 'Auckland',
      },
      {
        name: 'Chatham Islands',
      },
      {
        name: 'Nelson',
      },
      {
        name: 'Otago',
      },
      {
        name: 'West Coast',
      },
    ],
  },
  Oman: {
    countryName: 'Oman',
    provinces: [
      {
        name: 'Ad Dakhiliyah',
      },
      {
        name: 'Al Batinah South',
      },
      {
        name: 'Al Wusţá',
      },
      {
        name: 'Southeastern Governorate',
      },
      {
        name: 'Az̧ Z̧āhirah',
      },
      {
        name: 'Muscat',
      },
      {
        name: 'Musandam',
      },
      {
        name: 'Dhofar',
      },
      {
        name: 'Al Buraimi',
      },
      {
        name: 'Northeastern Governorate',
      },
      {
        name: 'Al Batinah North',
      },
    ],
  },
  Panama: {
    countryName: 'Panama',
    provinces: [
      {
        name: 'Veraguas',
      },
      {
        name: 'Guna Yala',
      },
      {
        name: 'Panamá',
      },
      {
        name: 'Los Santos',
      },
      {
        name: 'Herrera',
      },
      {
        name: 'Darién',
      },
      {
        name: 'Colón',
      },
      {
        name: 'Coclé',
      },
      {
        name: 'Chiriquí',
      },
      {
        name: 'Bocas del Toro',
      },
      {
        name: 'Emberá',
      },
      {
        name: 'Ngöbe-Buglé',
      },
      {
        name: 'Panamá Oeste',
      },
    ],
  },
  Peru: {
    countryName: 'Peru',
    provinces: [
      {
        name: 'Ucayali',
      },
      {
        name: 'Tumbes',
      },
      {
        name: 'San Martín',
      },
      {
        name: 'Piura',
      },
      {
        name: 'Loreto',
      },
      {
        name: 'Lambayeque',
      },
      {
        name: 'La Libertad',
      },
      {
        name: 'Huanuco',
      },
      {
        name: 'Cajamarca',
      },
      {
        name: 'Ancash',
      },
      {
        name: 'Amazonas',
      },
      {
        name: 'Tacna',
      },
      {
        name: 'Puno',
      },
      {
        name: 'Pasco',
      },
      {
        name: 'Moquegua',
      },
      {
        name: 'Madre de Dios',
      },
      {
        name: 'Lima',
      },
      {
        name: 'Lima region',
      },
      {
        name: 'Junin',
      },
      {
        name: 'Ica',
      },
      {
        name: 'Huancavelica',
      },
      {
        name: 'Cusco',
      },
      {
        name: 'Callao',
      },
      {
        name: 'Ayacucho',
      },
      {
        name: 'Arequipa',
      },
      {
        name: 'Apurímac',
      },
    ],
  },
  'French Polynesia': {
    countryName: 'French Polynesia',
    provinces: [
      {
        name: 'Îles Marquises',
      },
      {
        name: 'Îles Tuamotu-Gambier',
      },
      {
        name: 'Leeward Islands',
      },
      {
        name: 'Îles du Vent',
      },
      {
        name: 'Îles Australes',
      },
    ],
  },
  'Papua New Guinea': {
    countryName: 'Papua New Guinea',
    provinces: [
      {
        name: 'West New Britain',
      },
      {
        name: 'Western Province',
      },
      {
        name: 'Western Highlands',
      },
      {
        name: 'Southern Highlands',
      },
      {
        name: 'Sandaun',
      },
      {
        name: 'Bougainville',
      },
      {
        name: 'Northern Province',
      },
      {
        name: 'New Ireland',
      },
      {
        name: 'National Capital',
      },
      {
        name: 'Morobe',
      },
      {
        name: 'Manus',
      },
      {
        name: 'Madang',
      },
      {
        name: 'Gulf',
      },
      {
        name: 'Enga',
      },
      {
        name: 'East Sepik',
      },
      {
        name: 'East New Britain',
      },
      {
        name: 'Eastern Highlands',
      },
      {
        name: 'Chimbu',
      },
      {
        name: 'Milne Bay',
      },
      {
        name: 'Central Province',
      },
      {
        name: 'Hela',
      },
      {
        name: 'Jiwaka',
      },
    ],
  },
  Philippines: {
    countryName: 'Philippines',
    provinces: [
      {
        name: 'Autonomous Region in Muslim Mindanao',
      },
      {
        name: 'Northern Mindanao',
      },
      {
        name: 'Mimaropa',
      },
      {
        name: 'Cagayan Valley',
      },
      {
        name: 'Soccsksargen',
      },
      {
        name: 'Caraga',
      },
      {
        name: 'Cordillera',
      },
      {
        name: 'Ilocos',
      },
      {
        name: 'Calabarzon',
      },
      {
        name: 'Western Visayas',
      },
      {
        name: 'Central Luzon',
      },
      {
        name: 'Central Visayas',
      },
      {
        name: 'Eastern Visayas',
      },
      {
        name: 'Zamboanga Peninsula',
      },
      {
        name: 'Davao',
      },
      {
        name: 'Bicol',
      },
      {
        name: 'Metro Manila',
      },
    ],
  },
  Pakistan: {
    countryName: 'Pakistan',
    provinces: [
      {
        name: 'Islamabad',
      },
      {
        name: 'Sindh',
      },
      {
        name: 'Punjab',
      },
      {
        name: 'Khyber Pakhtunkhwa',
      },
      {
        name: 'Gilgit-Baltistan',
      },
      {
        name: 'Balochistan',
      },
      {
        name: 'Azad Kashmir',
      },
    ],
  },
  Poland: {
    countryName: 'Poland',
    provinces: [
      {
        name: 'Lublin',
      },
      {
        name: 'Lesser Poland',
      },
      {
        name: 'Mazovia',
      },
      {
        name: 'Subcarpathia',
      },
      {
        name: 'Podlasie',
      },
      {
        name: 'Świętokrzyskie',
      },
      {
        name: 'Warmia-Masuria',
      },
      {
        name: 'Lower Silesia',
      },
      {
        name: 'Łódź Voivodeship',
      },
      {
        name: 'Lubusz',
      },
      {
        name: 'Opole Voivodeship',
      },
      {
        name: 'Pomerania',
      },
      {
        name: 'Silesia',
      },
      {
        name: 'Greater Poland',
      },
      {
        name: 'West Pomerania',
      },
      {
        name: 'Kujawsko-Pomorskie',
      },
    ],
  },
  'Saint Pierre and Miquelon': {
    countryName: 'Saint Pierre and Miquelon',
    provinces: [
      {
        name: 'Saint-Pierre',
      },
      {
        name: 'Miquelon-Langlade',
      },
    ],
  },
  Pitcairn: {
    countryName: 'Pitcairn',
    provinces: null,
  },
  'Puerto Rico': {
    countryName: 'Puerto Rico',
    provinces: [
      {
        name: 'Adjuntas',
      },
      {
        name: 'Aguada',
      },
      {
        name: 'Aguadilla',
      },
      {
        name: 'Aguas Buenas',
      },
      {
        name: 'Aibonito',
      },
      {
        name: 'Añasco',
      },
      {
        name: 'Arecibo',
      },
      {
        name: 'Arroyo',
      },
      {
        name: 'Barceloneta',
      },
      {
        name: 'Barranquitas',
      },
      {
        name: 'Bayamón',
      },
      {
        name: 'Cabo Rojo',
      },
      {
        name: 'Caguas',
      },
      {
        name: 'Camuy',
      },
      {
        name: 'Canóvanas',
      },
      {
        name: 'Carolina',
      },
      {
        name: 'Cataño',
      },
      {
        name: 'Cayey',
      },
      {
        name: 'Ceiba',
      },
      {
        name: 'Ciales',
      },
      {
        name: 'Cidra',
      },
      {
        name: 'Coamo',
      },
      {
        name: 'Comerío',
      },
      {
        name: 'Corozal',
      },
      {
        name: 'Culebra',
      },
      {
        name: 'Dorado',
      },
      {
        name: 'Fajardo',
      },
      {
        name: 'Florida',
      },
      {
        name: 'Guánica',
      },
      {
        name: 'Guayama',
      },
      {
        name: 'Guayanilla',
      },
      {
        name: 'Guaynabo',
      },
      {
        name: 'Gurabo',
      },
      {
        name: 'Hatillo',
      },
      {
        name: 'Hormigueros',
      },
      {
        name: 'Humacao',
      },
      {
        name: 'Isabela',
      },
      {
        name: 'Jayuya',
      },
      {
        name: 'Juana Díaz',
      },
      {
        name: 'Juncos',
      },
      {
        name: 'Lajas',
      },
      {
        name: 'Lares',
      },
      {
        name: 'Las Marías',
      },
      {
        name: 'Las Piedras',
      },
      {
        name: 'Loíza',
      },
      {
        name: 'Luquillo',
      },
      {
        name: 'Manatí',
      },
      {
        name: 'Maricao',
      },
      {
        name: 'Maunabo',
      },
      {
        name: 'Mayagüez',
      },
      {
        name: 'Moca',
      },
      {
        name: 'Morovis',
      },
      {
        name: 'Naguabo',
      },
      {
        name: 'Naranjito',
      },
      {
        name: 'Orocovis',
      },
      {
        name: 'Patillas',
      },
      {
        name: 'Peñuelas',
      },
      {
        name: 'Ponce',
      },
      {
        name: 'Rincón',
      },
      {
        name: 'Quebradillas',
      },
      {
        name: 'Río Grande',
      },
      {
        name: 'Sabana Grande',
      },
      {
        name: 'Salinas',
      },
      {
        name: 'San Germán',
      },
      {
        name: 'San Juan',
      },
      {
        name: 'San Lorenzo',
      },
      {
        name: 'San Sebastián',
      },
      {
        name: 'Santa Isabel',
      },
      {
        name: 'Toa Alta',
      },
      {
        name: 'Toa Baja',
      },
      {
        name: 'Trujillo Alto',
      },
      {
        name: 'Utuado',
      },
      {
        name: 'Vega Alta',
      },
      {
        name: 'Vega Baja',
      },
      {
        name: 'Villalba',
      },
      {
        name: 'Yabucoa',
      },
      {
        name: 'Yauco',
      },
      {
        name: 'Vieques',
      },
    ],
  },
  'Palestinian Territory': {
    countryName: 'Palestinian Territory',
    provinces: [
      {
        name: 'Gaza Strip',
      },
      {
        name: 'West Bank',
      },
    ],
  },
  Portugal: {
    countryName: 'Portugal',
    provinces: [
      {
        name: 'District of Setúbal',
      },
      {
        name: 'Santarém',
      },
      {
        name: 'Portalegre',
      },
      {
        name: 'Lisbon',
      },
      {
        name: 'Leiria',
      },
      {
        name: 'Faro',
      },
      {
        name: 'Évora',
      },
      {
        name: 'Castelo Branco',
      },
      {
        name: 'Beja',
      },
      {
        name: 'Madeira',
      },
      {
        name: 'Viseu',
      },
      {
        name: 'Vila Real',
      },
      {
        name: 'Viana do Castelo',
      },
      {
        name: 'Porto',
      },
      {
        name: 'Guarda',
      },
      {
        name: 'Coimbra',
      },
      {
        name: 'Bragança',
      },
      {
        name: 'Braga',
      },
      {
        name: 'Aveiro',
      },
      {
        name: 'Azores',
      },
    ],
  },
  Palau: {
    countryName: 'Palau',
    provinces: [
      {
        name: 'Ngatpang',
      },
      {
        name: 'Sonsorol',
      },
      {
        name: 'Kayangel',
      },
      {
        name: 'Hatohobei',
      },
      {
        name: 'Aimeliik',
      },
      {
        name: 'Airai',
      },
      {
        name: 'Angaur',
      },
      {
        name: 'Koror',
      },
      {
        name: 'Melekeok',
      },
      {
        name: 'Ngaraard',
      },
      {
        name: 'Ngchesar',
      },
      {
        name: 'Ngarchelong',
      },
      {
        name: 'Ngardmau',
      },
      {
        name: 'Ngaremlengui',
      },
      {
        name: 'Ngiwal',
      },
      {
        name: 'Peleliu',
      },
    ],
  },
  Paraguay: {
    countryName: 'Paraguay',
    provinces: [
      {
        name: 'San Pedro',
      },
      {
        name: 'Presidente Hayes',
      },
      {
        name: 'Paraguarí',
      },
      {
        name: 'Ñeembucú',
      },
      {
        name: 'Misiones',
      },
      {
        name: 'Itapúa',
      },
      {
        name: 'Guairá',
      },
      {
        name: 'Cordillera',
      },
      {
        name: 'Concepción',
      },
      {
        name: 'Central',
      },
      {
        name: 'Canindeyú',
      },
      {
        name: 'Caazapá',
      },
      {
        name: 'Caaguazú',
      },
      {
        name: 'Amambay',
      },
      {
        name: 'Alto Paraná',
      },
      {
        name: 'Alto Paraguay',
      },
      {
        name: 'Asunción',
      },
      {
        name: 'Boquerón',
      },
    ],
  },
  Qatar: {
    countryName: 'Qatar',
    provinces: [
      {
        name: 'Madīnat ash Shamāl',
      },
      {
        name: 'Al Khor',
      },
      {
        name: 'Baladīyat Umm Şalāl',
      },
      {
        name: 'Baladīyat ar Rayyān',
      },
      {
        name: 'Baladīyat ad Dawḩah',
      },
      {
        name: 'Al Wakrah',
      },
      {
        name: 'Baladīyat az̧ Z̧a‘āyin',
      },
      {
        name: 'Al-Shahaniya',
      },
    ],
  },
  Reunion: {
    countryName: 'Reunion',
    provinces: [
      {
        name: 'Réunion',
      },
    ],
  },
  Romania: {
    countryName: 'Romania',
    provinces: [
      {
        name: 'Vrancea',
      },
      {
        name: 'Vâlcea',
      },
      {
        name: 'Vaslui',
      },
      {
        name: 'Tulcea',
      },
      {
        name: 'Timiş',
      },
      {
        name: 'Teleorman',
      },
      {
        name: 'Suceava',
      },
      {
        name: 'Sibiu',
      },
      {
        name: 'Satu Mare',
      },
      {
        name: 'Sălaj',
      },
      {
        name: 'Prahova',
      },
      {
        name: 'Olt',
      },
      {
        name: 'Neamţ',
      },
      {
        name: 'Mureş',
      },
      {
        name: 'Mehedinţi',
      },
      {
        name: 'Maramureş',
      },
      {
        name: 'Iaşi',
      },
      {
        name: 'Ialomiţa',
      },
      {
        name: 'Hunedoara',
      },
      {
        name: 'Harghita',
      },
      {
        name: 'Gorj',
      },
      {
        name: 'Giurgiu',
      },
      {
        name: 'Galaţi',
      },
      {
        name: 'Dolj',
      },
      {
        name: 'Dâmboviţa',
      },
      {
        name: 'Covasna',
      },
      {
        name: 'Constanța',
      },
      {
        name: 'Cluj',
      },
      {
        name: 'Caraş-Severin',
      },
      {
        name: 'Călăraşi',
      },
      {
        name: 'Buzău',
      },
      {
        name: 'Bucureşti',
      },
      {
        name: 'Braşov',
      },
      {
        name: 'Brăila',
      },
      {
        name: 'Botoşani',
      },
      {
        name: 'Bistriţa-Năsăud',
      },
      {
        name: 'Bihor',
      },
      {
        name: 'Bacău',
      },
      {
        name: 'Arges',
      },
      {
        name: 'Arad',
      },
      {
        name: 'Alba',
      },
      {
        name: 'Ilfov',
      },
    ],
  },
  Serbia: {
    countryName: 'Serbia',
    provinces: [
      {
        name: 'Vojvodina',
      },
      {
        name: 'Central Serbia',
      },
    ],
  },
  Russia: {
    countryName: 'Russia',
    provinces: [
      {
        name: 'Jaroslavl',
      },
      {
        name: 'Voronezj',
      },
      {
        name: 'Vologda',
      },
      {
        name: 'Volgograd Oblast',
      },
      {
        name: 'Ulyanovsk',
      },
      {
        name: 'Udmurtiya Republic',
      },
      {
        name: 'Tver’ Oblast',
      },
      {
        name: 'Tula',
      },
      {
        name: 'Tatarstan Republic',
      },
      {
        name: 'Tambov',
      },
      {
        name: 'Stavropol’ Kray',
      },
      {
        name: 'Smolensk',
      },
      {
        name: 'Saratovskaya Oblast',
      },
      {
        name: 'Samara Oblast',
      },
      {
        name: 'Ryazan Oblast',
      },
      {
        name: 'Rostov',
      },
      {
        name: 'Pskov Oblast',
      },
      {
        name: 'Perm',
      },
      {
        name: 'Penza',
      },
      {
        name: 'Orel Oblast',
      },
      {
        name: 'Orenburg Oblast',
      },
      {
        name: 'Novgorod Oblast',
      },
      {
        name: 'North Ossetia',
      },
      {
        name: 'Nenets',
      },
      {
        name: 'Murmansk',
      },
      {
        name: 'Moscow',
      },
      {
        name: 'Moscow Oblast',
      },
      {
        name: 'Mordoviya Republic',
      },
      {
        name: 'Mariy-El Republic',
      },
      {
        name: 'Lipetsk Oblast',
      },
      {
        name: "Leningradskaya Oblast'",
      },
      {
        name: 'St.-Petersburg',
      },
      {
        name: 'Kursk',
      },
      {
        name: 'Krasnodarskiy',
      },
      {
        name: 'Kostroma Oblast',
      },
      {
        name: 'Komi',
      },
      {
        name: 'Kirov',
      },
      {
        name: 'Karelia',
      },
      {
        name: 'Karachayevo-Cherkesiya Republic',
      },
      {
        name: 'Kaluga',
      },
      {
        name: 'Kalmykiya Republic',
      },
      {
        name: 'Kaliningrad',
      },
      {
        name: 'Kabardino-Balkariya Republic',
      },
      {
        name: 'Ivanovo',
      },
      {
        name: 'Ingushetiya Republic',
      },
      {
        name: 'Nizhny Novgorod Oblast',
      },
      {
        name: 'Dagestan',
      },
      {
        name: 'Chuvashia',
      },
      {
        name: 'Chechnya',
      },
      {
        name: 'Bryansk Oblast',
      },
      {
        name: 'Belgorod Oblast',
      },
      {
        name: 'Bashkortostan Republic',
      },
      {
        name: 'Astrakhan',
      },
      {
        name: 'Arkhangelskaya',
      },
      {
        name: 'Adygeya Republic',
      },
      {
        name: 'Vladimir',
      },
      {
        name: 'Yamalo-Nenets',
      },
      {
        name: 'Tyumen’ Oblast',
      },
      {
        name: 'Republic of Tyva',
      },
      {
        name: 'Tomsk Oblast',
      },
      {
        name: 'Sverdlovsk',
      },
      {
        name: 'Omsk',
      },
      {
        name: 'Novosibirsk Oblast',
      },
      {
        name: 'Kurgan Oblast',
      },
      {
        name: 'Krasnoyarskiy',
      },
      {
        name: 'Khanty-Mansia',
      },
      {
        name: 'Khakasiya Republic',
      },
      {
        name: 'Kemerovo Oblast',
      },
      {
        name: 'Altai',
      },
      {
        name: 'Chelyabinsk',
      },
      {
        name: 'Altai Krai',
      },
      {
        name: 'Sakha',
      },
      {
        name: 'Primorskiy (Maritime) Kray',
      },
      {
        name: 'Khabarovsk',
      },
      {
        name: 'Irkutsk Oblast',
      },
      {
        name: 'Jewish Autonomous Oblast',
      },
      {
        name: 'Amur Oblast',
      },
      {
        name: 'Buryatiya Republic',
      },
      {
        name: 'Sakhalin Oblast',
      },
      {
        name: 'Magadan Oblast',
      },
      {
        name: 'Kamchatka',
      },
      {
        name: 'Chukotka',
      },
      {
        name: 'Transbaikal Territory',
      },
    ],
  },
  Rwanda: {
    countryName: 'Rwanda',
    provinces: [
      {
        name: 'Eastern Province',
      },
      {
        name: 'Kigali',
      },
      {
        name: 'Northern Province',
      },
      {
        name: 'Western Province',
      },
      {
        name: 'Southern Province',
      },
    ],
  },
  'Saudi Arabia': {
    countryName: 'Saudi Arabia',
    provinces: [
      {
        name: 'Tabuk Region',
      },
      {
        name: 'Najran Region',
      },
      {
        name: 'Mecca Region',
      },
      {
        name: 'Jazan Region',
      },
      {
        name: "Ha'il Region",
      },
      {
        name: "'Asir Region",
      },
      {
        name: 'Eastern Province',
      },
      {
        name: 'Riyadh Region',
      },
      {
        name: 'Al-Qassim Region',
      },
      {
        name: 'Medina Region',
      },
      {
        name: 'Al Jawf Region',
      },
      {
        name: 'Northern Borders Region',
      },
      {
        name: 'Al Bahah Region',
      },
    ],
  },
  'Solomon Islands': {
    countryName: 'Solomon Islands',
    provinces: [
      {
        name: 'Western Province',
      },
      {
        name: 'Malaita',
      },
      {
        name: 'Isabel',
      },
      {
        name: 'Guadalcanal',
      },
      {
        name: 'Central Province',
      },
      {
        name: 'Temotu',
      },
      {
        name: 'Makira',
      },
      {
        name: 'Choiseul',
      },
      {
        name: 'Rennell and Bellona',
      },
      {
        name: 'Honiara',
      },
    ],
  },
  Seychelles: {
    countryName: 'Seychelles',
    provinces: [
      {
        name: 'Takamaka',
      },
      {
        name: 'Saint Louis',
      },
      {
        name: 'Port Glaud',
      },
      {
        name: 'Pointe Larue',
      },
      {
        name: 'Plaisance',
      },
      {
        name: 'Mont Fleuri',
      },
      {
        name: 'Mont Buxton',
      },
      {
        name: 'English River',
      },
      {
        name: 'La Digue',
      },
      {
        name: 'Grand Anse Mahe',
      },
      {
        name: 'Grand Anse Praslin',
      },
      {
        name: 'Glacis',
      },
      {
        name: 'Cascade',
      },
      {
        name: 'Bel Ombre',
      },
      {
        name: 'Bel Air',
      },
      {
        name: 'Beau Vallon',
      },
      {
        name: 'Baie Sainte Anne',
      },
      {
        name: 'Baie Lazare',
      },
      {
        name: 'Anse Royale',
      },
      {
        name: 'Anse Etoile',
      },
      {
        name: 'Anse Boileau',
      },
      {
        name: 'Anse-aux-Pins',
      },
      {
        name: 'Les Mamelles',
      },
      {
        name: 'Roche Caiman',
      },
      {
        name: 'Au Cap',
      },
      {
        name: 'Outer Islands',
      },
      {
        name: 'Ile Perseverance I',
      },
      {
        name: 'Ile Perseverance II',
      },
    ],
  },
  Sudan: {
    countryName: 'Sudan',
    provinces: [
      {
        name: 'Northern State',
      },
      {
        name: 'Khartoum',
      },
      {
        name: 'Red Sea',
      },
      {
        name: 'Al Jazīrah',
      },
      {
        name: 'Al Qaḑārif',
      },
      {
        name: 'White Nile',
      },
      {
        name: 'Blue Nile',
      },
      {
        name: 'Western Darfur',
      },
      {
        name: 'West Kordofan State',
      },
      {
        name: 'Southern Darfur',
      },
      {
        name: 'Southern Kordofan',
      },
      {
        name: 'Kassala',
      },
      {
        name: 'River Nile',
      },
      {
        name: 'Northern Darfur',
      },
      {
        name: 'North Kordofan',
      },
      {
        name: 'Sinnār',
      },
      {
        name: 'Eastern Darfur',
      },
      {
        name: 'Central Darfur',
      },
    ],
  },
  'South Sudan': {
    countryName: 'South Sudan',
    provinces: [
      {
        name: 'Upper Nile',
      },
      {
        name: 'Lakes',
      },
      {
        name: 'Unity',
      },
      {
        name: 'Central Equatoria',
      },
      {
        name: 'Western Equatoria',
      },
      {
        name: 'Western Bahr al Ghazal',
      },
      {
        name: 'Jonglei',
      },
      {
        name: 'Northern Bahr al Ghazal',
      },
      {
        name: 'Eastern Equatoria',
      },
      {
        name: 'Warrap',
      },
    ],
  },
  Sweden: {
    countryName: 'Sweden',
    provinces: [
      {
        name: 'Norrbotten',
      },
      {
        name: 'Västmanland',
      },
      {
        name: 'Västernorrland',
      },
      {
        name: 'Västerbotten',
      },
      {
        name: 'Värmland',
      },
      {
        name: 'Uppsala',
      },
      {
        name: 'Stockholm',
      },
      {
        name: 'Södermanland',
      },
      {
        name: 'Östergötland',
      },
      {
        name: 'Örebro',
      },
      {
        name: 'Kronoberg',
      },
      {
        name: 'Dalarna',
      },
      {
        name: 'Kalmar',
      },
      {
        name: 'Jönköping',
      },
      {
        name: 'Jämtland',
      },
      {
        name: 'Halland',
      },
      {
        name: 'Gotland',
      },
      {
        name: 'Gävleborg',
      },
      {
        name: 'Blekinge',
      },
      {
        name: 'Skåne',
      },
      {
        name: 'Västra Götaland',
      },
    ],
  },
  Singapore: {
    countryName: 'Singapore',
    provinces: null,
  },
  'Saint Helena': {
    countryName: 'Saint Helena',
    provinces: [
      {
        name: 'Ascension',
      },
      {
        name: 'Tristan da Cunha',
      },
      {
        name: 'Saint Helena',
      },
    ],
  },
  Slovenia: {
    countryName: 'Slovenia',
    provinces: [
      {
        name: 'Žalec',
      },
      {
        name: 'Zagorje ob Savi',
      },
      {
        name: 'Vrhnika',
      },
      {
        name: 'Tržič',
      },
      {
        name: 'Trebnje',
      },
      {
        name: 'Trbovlje',
      },
      {
        name: 'Tolmin',
      },
      {
        name: 'Velenje',
      },
      {
        name: 'Šmarje pri Jelšah',
      },
      {
        name: 'Slovenska Konjice',
      },
      {
        name: 'Slovenska Bistrica',
      },
      {
        name: 'Slovenj Gradec',
      },
      {
        name: 'Škofja Loka',
      },
      {
        name: 'Sežana',
      },
      {
        name: 'Sevnica',
      },
      {
        name: 'Sentjur',
      },
      {
        name: 'Ribnica',
      },
      {
        name: 'Radovljica',
      },
      {
        name: 'Radlje ob Dravi',
      },
      {
        name: 'Ptuj',
      },
      {
        name: 'Postojna',
      },
      {
        name: 'Piran-Pirano',
      },
      {
        name: 'Ormož',
      },
      {
        name: 'Novo Mesto',
      },
      {
        name: 'Nova Gorica',
      },
      {
        name: 'Murska Sobota',
      },
      {
        name: 'Mozirje',
      },
      {
        name: 'Metlika',
      },
      {
        name: 'Maribor',
      },
      {
        name: 'Logatec',
      },
      {
        name: 'Ljutomer',
      },
      {
        name: 'Litija',
      },
      {
        name: 'Lenart',
      },
      {
        name: 'Laško',
      },
      {
        name: 'Krško',
      },
      {
        name: 'Kranj',
      },
      {
        name: 'Koper-Capodistria',
      },
      {
        name: 'Kočevje',
      },
      {
        name: 'Kamnik',
      },
      {
        name: 'Jesenice',
      },
      {
        name: 'Izola-Isola',
      },
      {
        name: 'Ilirska Bistrica',
      },
      {
        name: 'Idrija',
      },
      {
        name: 'Hrastnik',
      },
      {
        name: 'Grosuplje',
      },
      {
        name: 'Gornja Radgona',
      },
      {
        name: 'Dravograd',
      },
      {
        name: 'Domžale',
      },
      {
        name: 'Črnomelj',
      },
      {
        name: 'Cerknica',
      },
      {
        name: 'Celje',
      },
      {
        name: 'Brežice',
      },
      {
        name: 'Ajdovščina',
      },
      {
        name: 'Hrpelje-Kozina',
      },
      {
        name: 'Divača',
      },
      {
        name: 'Pivka',
      },
      {
        name: 'Loška Dolina',
      },
      {
        name: 'Loški Potok',
      },
      {
        name: 'Osilnica',
      },
      {
        name: 'Velike Lašče',
      },
      {
        name: 'Škofljica',
      },
      {
        name: 'Ig',
      },
      {
        name: 'Brezovica',
      },
      {
        name: 'Borovnica',
      },
      {
        name: 'Vipava',
      },
      {
        name: 'Komen',
      },
      {
        name: 'Miren-Kostanjevica',
      },
      {
        name: 'Brda',
      },
      {
        name: 'Kanal',
      },
      {
        name: 'Žiri',
      },
      {
        name: 'Cerkno',
      },
      {
        name: 'Železniki',
      },
      {
        name: 'Gorenja Vas-Poljane',
      },
      {
        name: 'Dobrova-Horjul-Polhov Gradec',
      },
      {
        name: 'Kobarid',
      },
      {
        name: 'Bovec',
      },
      {
        name: 'Bohinj',
      },
      {
        name: 'Bled',
      },
      {
        name: 'Naklo',
      },
      {
        name: 'Kranjska Gora',
      },
      {
        name: 'Preddvor',
      },
      {
        name: 'Cerklje na Gorenjskem',
      },
      {
        name: 'Šenčur',
      },
      {
        name: 'Vodice',
      },
      {
        name: 'Medvode',
      },
      {
        name: 'Mengeš',
      },
      {
        name: 'Dol pri Ljubljani',
      },
      {
        name: 'Moravče',
      },
      {
        name: 'Gornji Grad',
      },
      {
        name: 'Luče',
      },
      {
        name: 'Ravne na Koroškem',
      },
      {
        name: 'Mežica',
      },
      {
        name: 'Muta',
      },
      {
        name: 'Vuzenica',
      },
      {
        name: 'Črna na Koroškem',
      },
      {
        name: 'Ljubno',
      },
      {
        name: 'Šoštanj',
      },
      {
        name: 'Šmartno ob Paki',
      },
      {
        name: 'Lukovica',
      },
      {
        name: 'Radeče',
      },
      {
        name: 'Ivančna Gorica',
      },
      {
        name: 'Dobrepolje',
      },
      {
        name: 'Semič',
      },
      {
        name: 'Šentjernej',
      },
      {
        name: 'Škocjan',
      },
      {
        name: 'Štore',
      },
      {
        name: 'Vojnik',
      },
      {
        name: 'Vitanje',
      },
      {
        name: 'Zreče',
      },
      {
        name: 'Mislinja',
      },
      {
        name: 'Ruše',
      },
      {
        name: 'Kungota',
      },
      {
        name: 'Šentilj',
      },
      {
        name: 'Pesnica',
      },
      {
        name: 'Duplek',
      },
      {
        name: 'Rače-Fram',
      },
      {
        name: 'Starše',
      },
      {
        name: 'Kidričevo',
      },
      {
        name: 'Majšperk',
      },
      {
        name: 'Videm',
      },
      {
        name: 'Rogaška Slatina',
      },
      {
        name: 'Rogatec',
      },
      {
        name: 'Podčetrtek',
      },
      {
        name: 'Kozje',
      },
      {
        name: 'Gorišnica',
      },
      {
        name: 'Zavrč',
      },
      {
        name: 'Dornava',
      },
      {
        name: 'Juršinci',
      },
      {
        name: 'Sveti Jurij',
      },
      {
        name: 'Radenci',
      },
      {
        name: 'Puconci',
      },
      {
        name: 'Rogašovci',
      },
      {
        name: 'Kuzma',
      },
      {
        name: 'Gornji Petrovci',
      },
      {
        name: 'Moravske Toplice',
      },
      {
        name: 'Kobilje',
      },
      {
        name: 'Beltinci',
      },
      {
        name: 'Turnišče',
      },
      {
        name: 'Odranci',
      },
      {
        name: 'Črenšovci',
      },
      {
        name: 'Nazarje',
      },
      {
        name: 'Ljubljana',
      },
      {
        name: 'Žirovnica',
      },
      {
        name: 'Jezersko',
      },
      {
        name: 'Solčava',
      },
      {
        name: 'Komenda',
      },
      {
        name: 'Horjul',
      },
      {
        name: 'Šempeter-Vrtojba',
      },
      {
        name: 'Bloke',
      },
      {
        name: 'Sodražica',
      },
      {
        name: 'Trzin',
      },
      {
        name: 'Prevalje',
      },
      {
        name: 'Vransko',
      },
      {
        name: 'Tabor',
      },
      {
        name: 'Braslovče',
      },
      {
        name: 'Polzela',
      },
      {
        name: 'Prebold',
      },
      {
        name: 'Kostel',
      },
      {
        name: 'Žužemberk',
      },
      {
        name: 'Dolenjske Toplice',
      },
      {
        name: 'Mirna Peč',
      },
      {
        name: 'Bistrica ob Sotli',
      },
      {
        name: 'Dobje',
      },
      {
        name: 'Dobrna',
      },
      {
        name: 'Oplotnica',
      },
      {
        name: 'Podvelka',
      },
      {
        name: 'Ribnica na Pohorju',
      },
      {
        name: 'Lovrenc na Pohorju',
      },
      {
        name: 'Selnica ob Dravi',
      },
      {
        name: 'Hoče-Slivnica',
      },
      {
        name: 'Miklavž na Dravskem Polju',
      },
      {
        name: 'Hajdina',
      },
      {
        name: 'Žetale',
      },
      {
        name: 'Podlehnik',
      },
      {
        name: 'Markovci',
      },
      {
        name: 'Destrnik',
      },
      {
        name: 'Trnovska Vas',
      },
      {
        name: 'Sveti Andraž v Slovenskih Goricah',
      },
      {
        name: 'Cerkvenjak',
      },
      {
        name: 'Benedikt',
      },
      {
        name: 'Sveta Ana',
      },
      {
        name: 'Križevci',
      },
      {
        name: 'Veržej',
      },
      {
        name: 'Velika Polana',
      },
      {
        name: 'Lendava-Lendva',
      },
      {
        name: 'Dobrovnik-Dobronak',
      },
      {
        name: 'Tišina',
      },
      {
        name: 'Cankova',
      },
      {
        name: 'Grad',
      },
      {
        name: 'Hodoš-Hodos',
      },
      {
        name: 'Razkrižje',
      },
      {
        name: 'Šmartno pri Litiji',
      },
      {
        name: 'Šalovci',
      },
      {
        name: 'Apače',
      },
      {
        name: 'Cirkulane',
      },
      {
        name: 'Kostanjevica na Krki',
      },
      {
        name: 'Log–Dragomer',
      },
      {
        name: 'Makole',
      },
      {
        name: 'Mokronog-Trebelno',
      },
      {
        name: 'Poljčane',
      },
      {
        name: 'Rečica ob Savinji',
      },
      {
        name: 'Renče-Vogrsko',
      },
      {
        name: 'Središče ob Dravi',
      },
      {
        name: 'Straža',
      },
      {
        name: 'Sv. Trojica v Slov. Goricah',
      },
      {
        name: 'Sveti Tomaž',
      },
      {
        name: 'Šentrupert',
      },
      {
        name: 'Šmarješke Toplice',
      },
      {
        name: 'Sveti Jurij v Slovenskih Goricah',
      },
      {
        name: 'Gorje',
      },
      {
        name: 'Ankaran',
      },
      {
        name: 'Mirna',
      },
    ],
  },
  'Svalbard and Jan Mayen': {
    countryName: 'Svalbard and Jan Mayen',
    provinces: [
      {
        name: 'Jan Mayen',
      },
      {
        name: 'Svalbard',
      },
    ],
  },
  Slovakia: {
    countryName: 'Slovakia',
    provinces: [
      {
        name: 'Košický kraj',
      },
      {
        name: 'Prešovský kraj',
      },
      {
        name: 'Žilinský kraj',
      },
      {
        name: 'Banskobystrický kraj',
      },
      {
        name: 'Bratislava',
      },
      {
        name: 'Nitriansky kraj',
      },
      {
        name: 'Trenčiansky kraj',
      },
      {
        name: 'Trnavský kraj',
      },
    ],
  },
  'Sierra Leone': {
    countryName: 'Sierra Leone',
    provinces: [
      {
        name: 'Western Area',
      },
      {
        name: 'Southern Province',
      },
      {
        name: 'Northern Province',
      },
      {
        name: 'Eastern Province',
      },
      {
        name: 'North West',
      },
    ],
  },
  'San Marino': {
    countryName: 'San Marino',
    provinces: [
      {
        name: 'Serravalle',
      },
      {
        name: 'Chiesanuova',
      },
      {
        name: 'San Marino',
      },
      {
        name: 'Acquaviva',
      },
      {
        name: 'Borgo Maggiore',
      },
      {
        name: 'Domagnano',
      },
      {
        name: 'Faetano',
      },
      {
        name: 'Fiorentino',
      },
      {
        name: 'Montegiardino',
      },
    ],
  },
  Senegal: {
    countryName: 'Senegal',
    provinces: [
      {
        name: 'Ziguinchor',
      },
      {
        name: 'Thiès',
      },
      {
        name: 'Tambacounda',
      },
      {
        name: 'Saint-Louis',
      },
      {
        name: 'Matam',
      },
      {
        name: 'Louga',
      },
      {
        name: 'Kolda',
      },
      {
        name: 'Kaolack',
      },
      {
        name: 'Fatick',
      },
      {
        name: 'Diourbel',
      },
      {
        name: 'Dakar',
      },
      {
        name: 'Kaffrine',
      },
      {
        name: 'Kédougou',
      },
      {
        name: 'Sédhiou',
      },
    ],
  },
  Somalia: {
    countryName: 'Somalia',
    provinces: [
      {
        name: 'Woqooyi Galbeed',
      },
      {
        name: 'Togdheer',
      },
      {
        name: 'Lower Shabeelle',
      },
      {
        name: 'Middle Shabele',
      },
      {
        name: 'Sanaag',
      },
      {
        name: 'Nugaal',
      },
      {
        name: 'Mudug',
      },
      {
        name: 'Lower Juba',
      },
      {
        name: 'Middle Juba',
      },
      {
        name: 'Hiiraan',
      },
      {
        name: 'Gedo',
      },
      {
        name: 'Galguduud',
      },
      {
        name: 'Bay',
      },
      {
        name: 'Bari',
      },
      {
        name: 'Banaadir',
      },
      {
        name: 'Bakool',
      },
      {
        name: 'Awdal',
      },
      {
        name: 'Sool',
      },
    ],
  },
  Suriname: {
    countryName: 'Suriname',
    provinces: [
      {
        name: 'Wanica',
      },
      {
        name: 'Sipaliwini',
      },
      {
        name: 'Saramacca',
      },
      {
        name: 'Paramaribo',
      },
      {
        name: 'Para',
      },
      {
        name: 'Nickerie',
      },
      {
        name: 'Marowijne',
      },
      {
        name: 'Coronie',
      },
      {
        name: 'Commewijne',
      },
      {
        name: 'Brokopondo',
      },
    ],
  },
  'Sao Tome and Principe': {
    countryName: 'Sao Tome and Principe',
    provinces: [
      {
        name: 'São Tomé Island',
      },
      {
        name: 'Príncipe',
      },
    ],
  },
  'El Salvador': {
    countryName: 'El Salvador',
    provinces: [
      {
        name: 'Usulután',
      },
      {
        name: 'Sonsonate',
      },
      {
        name: 'San Vicente',
      },
      {
        name: 'Santa Ana',
      },
      {
        name: 'San Salvador',
      },
      {
        name: 'San Miguel',
      },
      {
        name: 'Morazán',
      },
      {
        name: 'La Unión',
      },
      {
        name: 'La Paz',
      },
      {
        name: 'La Libertad',
      },
      {
        name: 'Cuscatlán',
      },
      {
        name: 'Chalatenango',
      },
      {
        name: 'Cabañas',
      },
      {
        name: 'Ahuachapán',
      },
    ],
  },
  'Sint Maarten': {
    countryName: 'Sint Maarten',
    provinces: null,
  },
  Syria: {
    countryName: 'Syria',
    provinces: [
      {
        name: 'Tartus',
      },
      {
        name: 'Dimashq',
      },
      {
        name: 'Idlib',
      },
      {
        name: 'Homs',
      },
      {
        name: 'Hama',
      },
      {
        name: 'Aleppo',
      },
      {
        name: 'Rif-dimashq',
      },
      {
        name: 'Deir ez-Zor',
      },
      {
        name: 'Daraa',
      },
      {
        name: 'As-Suwayda',
      },
      {
        name: 'Ar-Raqqah',
      },
      {
        name: 'Quneitra',
      },
      {
        name: 'Latakia',
      },
      {
        name: 'Al-Hasakah',
      },
    ],
  },
  Eswatini: {
    countryName: 'Eswatini',
    provinces: [
      {
        name: 'Shiselweni',
      },
      {
        name: 'Manzini',
      },
      {
        name: 'Lubombo',
      },
      {
        name: 'Hhohho',
      },
    ],
  },
  'Turks and Caicos Islands': {
    countryName: 'Turks and Caicos Islands',
    provinces: null,
  },
  Chad: {
    countryName: 'Chad',
    provinces: [
      {
        name: 'Salamat',
      },
      {
        name: 'Ouadaï',
      },
      {
        name: 'Wadi Fira',
      },
      {
        name: 'Tandjilé',
      },
      {
        name: 'Moyen-Chari',
      },
      {
        name: 'Mayo-Kebbi Est',
      },
      {
        name: 'Logone Oriental',
      },
      {
        name: 'Logone Occidental',
      },
      {
        name: 'Lac',
      },
      {
        name: 'Kanem',
      },
      {
        name: 'Guéra',
      },
      {
        name: 'Chari-Baguirmi',
      },
      {
        name: 'Batha',
      },
      {
        name: 'Borkou',
      },
      {
        name: 'Hadjer-Lamis',
      },
      {
        name: 'Mandoul',
      },
      {
        name: 'Mayo-Kebbi Ouest',
      },
      {
        name: 'N’Djaména',
      },
      {
        name: 'Barh el Gazel',
      },
      {
        name: 'Sila',
      },
      {
        name: 'Tibesti',
      },
      {
        name: 'Ennedi-Ouest',
      },
      {
        name: 'Ennedi-Est',
      },
    ],
  },
  'French Southern Territories': {
    countryName: 'French Southern Territories',
    provinces: [
      {
        name: 'Crozet',
      },
      {
        name: 'Kerguelen',
      },
      {
        name: 'Saint-Paul-et-Amsterdam',
      },
      {
        name: 'Îles Éparses',
      },
      {
        name: 'Terre-Adélie',
      },
    ],
  },
  Togo: {
    countryName: 'Togo',
    provinces: [
      {
        name: 'Savanes',
      },
      {
        name: 'Plateaux',
      },
      {
        name: 'Maritime',
      },
      {
        name: 'Centrale',
      },
      {
        name: 'Kara',
      },
    ],
  },
  Thailand: {
    countryName: 'Thailand',
    provinces: [
      {
        name: 'Uthai Thani',
      },
      {
        name: 'Trang',
      },
      {
        name: 'Tak',
      },
      {
        name: 'Surat Thani',
      },
      {
        name: 'Sukhothai',
      },
      {
        name: 'Ratchaburi',
      },
      {
        name: 'Ranong',
      },
      {
        name: 'Prachuap Khiri Khan',
      },
      {
        name: 'Phuket',
      },
      {
        name: 'Phetchaburi',
      },
      {
        name: 'Phang Nga',
      },
      {
        name: 'Mae Hong Son',
      },
      {
        name: 'Lamphun',
      },
      {
        name: 'Lampang',
      },
      {
        name: 'Krabi',
      },
      {
        name: 'Kanchanaburi',
      },
      {
        name: 'Kamphaeng Phet',
      },
      {
        name: 'Chumphon',
      },
      {
        name: 'Chiang Rai',
      },
      {
        name: 'Chiang Mai',
      },
      {
        name: 'Yasothon',
      },
      {
        name: 'Yala',
      },
      {
        name: 'Uttaradit',
      },
      {
        name: 'Trat',
      },
      {
        name: 'Surin',
      },
      {
        name: 'Suphanburi',
      },
      {
        name: 'Songkhla',
      },
      {
        name: 'Si Sa Ket',
      },
      {
        name: 'Sing Buri',
      },
      {
        name: 'Satun',
      },
      {
        name: 'Saraburi',
      },
      {
        name: 'Samut Songkhram',
      },
      {
        name: 'Samut Sakhon',
      },
      {
        name: 'Samut Prakan',
      },
      {
        name: 'Sakon Nakhon',
      },
      {
        name: 'Roi Et',
      },
      {
        name: 'Rayong',
      },
      {
        name: 'Phra Nakhon Si Ayutthaya',
      },
      {
        name: 'Phrae',
      },
      {
        name: 'Phitsanulok',
      },
      {
        name: 'Phichit',
      },
      {
        name: 'Phetchabun',
      },
      {
        name: 'Phayao',
      },
      {
        name: 'Phatthalung',
      },
      {
        name: 'Pattani',
      },
      {
        name: 'Pathum Thani',
      },
      {
        name: 'Nonthaburi',
      },
      {
        name: 'Nong Khai',
      },
      {
        name: 'Narathiwat',
      },
      {
        name: 'Nan',
      },
      {
        name: 'Nakhon Si Thammarat',
      },
      {
        name: 'Nakhon Sawan',
      },
      {
        name: 'Nakhon Ratchasima',
      },
      {
        name: 'Nakhon Phanom',
      },
      {
        name: 'Nakhon Pathom',
      },
      {
        name: 'Nakhon Nayok',
      },
      {
        name: 'Mukdahan',
      },
      {
        name: 'Maha Sarakham',
      },
      {
        name: 'Lopburi',
      },
      {
        name: 'Loei',
      },
      {
        name: 'Bangkok',
      },
      {
        name: 'Khon Kaen',
      },
      {
        name: 'Kalasin',
      },
      {
        name: 'Chon Buri',
      },
      {
        name: 'Chanthaburi',
      },
      {
        name: 'Chaiyaphum',
      },
      {
        name: 'Chai Nat',
      },
      {
        name: 'Chachoengsao',
      },
      {
        name: 'Buriram',
      },
      {
        name: 'Ang Thong',
      },
      {
        name: 'Udon Thani',
      },
      {
        name: 'Prachin Buri',
      },
      {
        name: 'Ubon Ratchathani',
      },
      {
        name: 'Amnat Charoen',
      },
      {
        name: 'Nong Bua Lam Phu',
      },
      {
        name: 'Sa Kaeo',
      },
      {
        name: 'Bueng Kan',
      },
    ],
  },
  Tajikistan: {
    countryName: 'Tajikistan',
    provinces: [
      {
        name: 'Sughd',
      },
      {
        name: 'Gorno-Badakhshan',
      },
      {
        name: 'Khatlon',
      },
      {
        name: 'Republican Subordination',
      },
      {
        name: 'Dushanbe',
      },
    ],
  },
  Tokelau: {
    countryName: 'Tokelau',
    provinces: [
      {
        name: 'Nukunonu',
      },
      {
        name: 'Fakaofo',
      },
      {
        name: 'Atafu',
      },
    ],
  },
  'Timor Leste': {
    countryName: 'Timor Leste',
    provinces: [
      {
        name: 'Viqueque',
      },
      {
        name: 'Manufahi',
      },
      {
        name: 'Manatuto',
      },
      {
        name: 'Liquiçá',
      },
      {
        name: 'Lautém',
      },
      {
        name: 'Cova Lima',
      },
      {
        name: 'Ermera',
      },
      {
        name: 'Díli',
      },
      {
        name: 'Bobonaro',
      },
      {
        name: 'Baucau',
      },
      {
        name: 'Oecusse',
      },
      {
        name: 'Ainaro',
      },
      {
        name: 'Aileu',
      },
    ],
  },
  Turkmenistan: {
    countryName: 'Turkmenistan',
    provinces: [
      {
        name: 'Balkan',
      },
      {
        name: 'Ahal',
      },
      {
        name: 'Ashgabat',
      },
      {
        name: 'Daşoguz',
      },
      {
        name: 'Mary',
      },
      {
        name: 'Lebap',
      },
    ],
  },
  Tunisia: {
    countryName: 'Tunisia',
    provinces: [
      {
        name: 'Zaghwān',
      },
      {
        name: 'Tūnis',
      },
      {
        name: 'Tawzar',
      },
      {
        name: 'Tataouine',
      },
      {
        name: 'Sūsah',
      },
      {
        name: 'Silyānah',
      },
      {
        name: 'Sīdī Bū Zayd',
      },
      {
        name: 'Şafāqis',
      },
      {
        name: 'Qibilī',
      },
      {
        name: 'Gafsa',
      },
      {
        name: 'Qābis',
      },
      {
        name: 'Nābul',
      },
      {
        name: 'Madanīn',
      },
      {
        name: 'Jundūbah',
      },
      {
        name: 'Bin ‘Arūs',
      },
      {
        name: 'Banzart',
      },
      {
        name: 'Bājah',
      },
      {
        name: 'Ariana',
      },
      {
        name: 'Kairouan',
      },
      {
        name: 'Al Qaşrayn',
      },
      {
        name: 'Al Munastīr',
      },
      {
        name: 'Al Mahdīyah',
      },
      {
        name: 'Kef',
      },
      {
        name: 'Manouba',
      },
    ],
  },
  Tonga: {
    countryName: 'Tonga',
    provinces: [
      {
        name: 'Vava‘u',
      },
      {
        name: 'Tongatapu',
      },
      {
        name: 'Ha‘apai',
      },
      {
        name: 'ʻEua',
      },
      {
        name: 'Niuas',
      },
    ],
  },
  Turkey: {
    countryName: 'Turkey',
    provinces: [
      {
        name: 'Yozgat',
      },
      {
        name: 'Van',
      },
      {
        name: 'Uşak',
      },
      {
        name: 'Şanlıurfa',
      },
      {
        name: 'Tunceli',
      },
      {
        name: 'Sivas',
      },
      {
        name: 'Siirt',
      },
      {
        name: 'Niğde',
      },
      {
        name: 'Nevşehir',
      },
      {
        name: 'Muş',
      },
      {
        name: 'Muğla',
      },
      {
        name: 'Mardin',
      },
      {
        name: 'Manisa',
      },
      {
        name: 'Malatya',
      },
      {
        name: 'Kütahya',
      },
      {
        name: 'Konya',
      },
      {
        name: 'Kırşehir',
      },
      {
        name: 'Kayseri',
      },
      {
        name: 'Kahramanmaraş',
      },
      {
        name: 'İzmir',
      },
      {
        name: 'Isparta',
      },
      {
        name: 'Mersin',
      },
      {
        name: 'Hatay',
      },
      {
        name: 'Hakkâri',
      },
      {
        name: 'Gaziantep',
      },
      {
        name: 'Eskişehir',
      },
      {
        name: 'Erzurum',
      },
      {
        name: 'Erzincan',
      },
      {
        name: 'Elazığ',
      },
      {
        name: 'Diyarbakır',
      },
      {
        name: 'Denizli',
      },
      {
        name: 'Burdur',
      },
      {
        name: 'Bitlis',
      },
      {
        name: 'Bingöl',
      },
      {
        name: 'Bilecik',
      },
      {
        name: 'Balıkesir',
      },
      {
        name: 'Aydın',
      },
      {
        name: 'Antalya',
      },
      {
        name: 'Ankara',
      },
      {
        name: 'Ağrı',
      },
      {
        name: 'Afyonkarahisar',
      },
      {
        name: 'Adıyaman',
      },
      {
        name: 'Adana',
      },
      {
        name: 'Osmaniye',
      },
      {
        name: 'Iğdır',
      },
      {
        name: 'Aksaray',
      },
      {
        name: 'Batman',
      },
      {
        name: 'Karaman',
      },
      {
        name: 'Kırıkkale',
      },
      {
        name: 'Şırnak',
      },
      {
        name: 'Kilis',
      },
      {
        name: 'Zonguldak',
      },
      {
        name: 'Trabzon',
      },
      {
        name: 'Tokat',
      },
      {
        name: 'Tekirdağ',
      },
      {
        name: 'Sinop',
      },
      {
        name: 'Samsun',
      },
      {
        name: 'Sakarya',
      },
      {
        name: 'Rize',
      },
      {
        name: 'Ordu',
      },
      {
        name: 'Kocaeli',
      },
      {
        name: 'Kırklareli',
      },
      {
        name: 'Kastamonu',
      },
      {
        name: 'Kars',
      },
      {
        name: 'Istanbul',
      },
      {
        name: 'Gümüşhane',
      },
      {
        name: 'Giresun',
      },
      {
        name: 'Edirne',
      },
      {
        name: 'Çorum',
      },
      {
        name: 'Çankırı',
      },
      {
        name: 'Canakkale',
      },
      {
        name: 'Bursa',
      },
      {
        name: 'Bolu',
      },
      {
        name: 'Artvin',
      },
      {
        name: 'Amasya',
      },
      {
        name: 'Bartın',
      },
      {
        name: 'Karabük',
      },
      {
        name: 'Yalova',
      },
      {
        name: 'Ardahan',
      },
      {
        name: 'Bayburt',
      },
      {
        name: 'Düzce',
      },
    ],
  },
  'Trinidad and Tobago': {
    countryName: 'Trinidad and Tobago',
    provinces: [
      {
        name: 'Tobago',
      },
      {
        name: 'San Fernando',
      },
      {
        name: 'Port of Spain',
      },
      {
        name: 'Mayaro',
      },
      {
        name: 'Borough of Arima',
      },
      {
        name: 'Chaguanas',
      },
      {
        name: 'Couva-Tabaquite-Talparo',
      },
      {
        name: 'Diego Martin',
      },
      {
        name: 'Penal/Debe',
      },
      {
        name: 'Princes Town',
      },
      {
        name: 'Point Fortin',
      },
      {
        name: 'Sangre Grande',
      },
      {
        name: 'Siparia',
      },
      {
        name: 'San Juan/Laventille',
      },
      {
        name: 'Tunapuna/Piarco',
      },
    ],
  },
  Tuvalu: {
    countryName: 'Tuvalu',
    provinces: [
      {
        name: 'Nui',
      },
      {
        name: 'Nanumea',
      },
      {
        name: 'Funafuti',
      },
      {
        name: 'Niutao',
      },
      {
        name: 'Nanumanga',
      },
      {
        name: 'Vaitupu',
      },
      {
        name: 'Nukufetau',
      },
      {
        name: 'Nukulaelae',
      },
    ],
  },
  Taiwan: {
    countryName: 'Taiwan',
    provinces: [
      {
        name: 'Fukien',
      },
      {
        name: 'Takao',
      },
      {
        name: 'Taipei',
      },
      {
        name: 'Taiwan',
      },
    ],
  },
  Tanzania: {
    countryName: 'Tanzania',
    provinces: [
      {
        name: 'Kagera',
      },
      {
        name: 'Zanzibar Urban/West',
      },
      {
        name: 'Zanzibar North',
      },
      {
        name: 'Zanzibar Central/South',
      },
      {
        name: 'Tanga',
      },
      {
        name: 'Tabora',
      },
      {
        name: 'Singida',
      },
      {
        name: 'Shinyanga',
      },
      {
        name: 'Rukwa',
      },
      {
        name: 'Pwani',
      },
      {
        name: 'Pemba South',
      },
      {
        name: 'Pemba North',
      },
      {
        name: 'Mwanza',
      },
      {
        name: 'Morogoro',
      },
      {
        name: 'Mbeya',
      },
      {
        name: 'Mara',
      },
      {
        name: 'Lindi',
      },
      {
        name: 'Kilimanjaro',
      },
      {
        name: 'Kigoma',
      },
      {
        name: 'Iringa',
      },
      {
        name: 'Dodoma',
      },
      {
        name: 'Dar es Salaam',
      },
      {
        name: 'Arusha',
      },
      {
        name: 'Manyara',
      },
      {
        name: 'Ruvuma',
      },
      {
        name: 'Mtwara',
      },
      {
        name: 'Simiyu',
      },
      {
        name: 'Geita',
      },
      {
        name: 'Katavi',
      },
      {
        name: 'Njombe',
      },
      {
        name: 'Songwe',
      },
    ],
  },
  Ukraine: {
    countryName: 'Ukraine',
    provinces: [
      {
        name: 'Zhytomyr',
      },
      {
        name: 'Zaporizhzhya Oblast',
      },
      {
        name: 'Transcarpathia',
      },
      {
        name: 'Volyn',
      },
      {
        name: 'Vinnytsya Oblast',
      },
      {
        name: 'Ternopil Oblast',
      },
      {
        name: 'Sumy',
      },
      {
        name: 'Sevastopol City',
      },
      {
        name: 'Rivne',
      },
      {
        name: 'Poltava Oblast',
      },
      {
        name: 'Odessa',
      },
      {
        name: 'Mykolayiv Oblast',
      },
      {
        name: 'Lviv',
      },
      {
        name: 'Luhansk',
      },
      {
        name: 'Kyiv',
      },
      {
        name: 'Kyiv City',
      },
      {
        name: 'Crimea',
      },
      {
        name: 'Kirovohrad Oblast',
      },
      {
        name: 'Khmelnytskyy Oblast',
      },
      {
        name: 'Kherson Oblast',
      },
      {
        name: 'Kharkiv',
      },
      {
        name: 'Ivano-Frankivsk Oblast',
      },
      {
        name: 'Donetsk',
      },
      {
        name: 'Dnipropetrovsk Oblast',
      },
      {
        name: 'Chernivtsi Oblast',
      },
      {
        name: 'Chernihiv',
      },
      {
        name: 'Cherkasy Oblast',
      },
    ],
  },
  Uganda: {
    countryName: 'Uganda',
    provinces: [
      {
        name: 'Central Region',
      },
      {
        name: 'Eastern Region',
      },
      {
        name: 'Northern Region',
      },
      {
        name: 'Western Region',
      },
    ],
  },
  'United States Minor Outlying Islands': {
    countryName: 'United States Minor Outlying Islands',
    provinces: [
      {
        name: 'Wake Island',
      },
      {
        name: 'Navassa Island',
      },
      {
        name: 'Baker Island',
      },
      {
        name: 'Howland Island',
      },
      {
        name: 'Jarvis Island',
      },
      {
        name: 'Johnston Atoll',
      },
      {
        name: 'Kingman Reef',
      },
      {
        name: 'Midway Islands',
      },
      {
        name: 'Palmyra Atoll',
      },
    ],
  },
  'United States': {
    countryName: 'United States',
    provinces: [
      {
        name: 'Arkansas',
      },
      {
        name: 'Washington, D.C.',
      },
      {
        name: 'Delaware',
      },
      {
        name: 'Florida',
      },
      {
        name: 'Georgia',
      },
      {
        name: 'Kansas',
      },
      {
        name: 'Louisiana',
      },
      {
        name: 'Maryland',
      },
      {
        name: 'Missouri',
      },
      {
        name: 'Mississippi',
      },
      {
        name: 'North Carolina',
      },
      {
        name: 'Oklahoma',
      },
      {
        name: 'South Carolina',
      },
      {
        name: 'Tennessee',
      },
      {
        name: 'Texas',
      },
      {
        name: 'West Virginia',
      },
      {
        name: 'Alabama',
      },
      {
        name: 'Connecticut',
      },
      {
        name: 'Iowa',
      },
      {
        name: 'Illinois',
      },
      {
        name: 'Indiana',
      },
      {
        name: 'Maine',
      },
      {
        name: 'Michigan',
      },
      {
        name: 'Minnesota',
      },
      {
        name: 'Nebraska',
      },
      {
        name: 'New Hampshire',
      },
      {
        name: 'New Jersey',
      },
      {
        name: 'New York',
      },
      {
        name: 'Ohio',
      },
      {
        name: 'Rhode Island',
      },
      {
        name: 'Vermont',
      },
      {
        name: 'Wisconsin',
      },
      {
        name: 'California',
      },
      {
        name: 'Colorado',
      },
      {
        name: 'New Mexico',
      },
      {
        name: 'Nevada',
      },
      {
        name: 'Utah',
      },
      {
        name: 'Arizona',
      },
      {
        name: 'Idaho',
      },
      {
        name: 'Montana',
      },
      {
        name: 'North Dakota',
      },
      {
        name: 'Oregon',
      },
      {
        name: 'South Dakota',
      },
      {
        name: 'Washington',
      },
      {
        name: 'Wyoming',
      },
      {
        name: 'Hawaii',
      },
      {
        name: 'Alaska',
      },
      {
        name: 'Kentucky',
      },
      {
        name: 'Massachusetts',
      },
      {
        name: 'Pennsylvania',
      },
      {
        name: 'Virginia',
      },
    ],
  },
  Uruguay: {
    countryName: 'Uruguay',
    provinces: [
      {
        name: 'Treinta y Tres',
      },
      {
        name: 'Tacuarembó',
      },
      {
        name: 'Soriano',
      },
      {
        name: 'San José',
      },
      {
        name: 'Salto',
      },
      {
        name: 'Rocha',
      },
      {
        name: 'Rivera',
      },
      {
        name: 'Río Negro',
      },
      {
        name: 'Paysandú',
      },
      {
        name: 'Montevideo',
      },
      {
        name: 'Maldonado',
      },
      {
        name: 'Lavalleja',
      },
      {
        name: 'Florida',
      },
      {
        name: 'Flores',
      },
      {
        name: 'Durazno',
      },
      {
        name: 'Colonia',
      },
      {
        name: 'Cerro Largo',
      },
      {
        name: 'Canelones',
      },
      {
        name: 'Artigas',
      },
    ],
  },
  Uzbekistan: {
    countryName: 'Uzbekistan',
    provinces: [
      {
        name: 'Karakalpakstan',
      },
      {
        name: 'Surxondaryo',
      },
      {
        name: 'Samarqand',
      },
      {
        name: 'Qashqadaryo',
      },
      {
        name: 'Bukhara',
      },
      {
        name: 'Toshkent',
      },
      {
        name: 'Tashkent',
      },
      {
        name: 'Sirdaryo Region',
      },
      {
        name: 'Navoiy',
      },
      {
        name: 'Namangan',
      },
      {
        name: 'Xorazm',
      },
      {
        name: 'Jizzax',
      },
      {
        name: 'Fergana',
      },
      {
        name: 'Andijon',
      },
    ],
  },
  Vatican: {
    countryName: 'Vatican',
    provinces: null,
  },
  'Saint Vincent and the Grenadines': {
    countryName: 'Saint Vincent and the Grenadines',
    provinces: [
      {
        name: 'Saint Patrick',
      },
      {
        name: 'Saint George',
      },
      {
        name: 'Saint David',
      },
      {
        name: 'Saint Andrew',
      },
      {
        name: 'Grenadines',
      },
      {
        name: 'Charlotte',
      },
    ],
  },
  Venezuela: {
    countryName: 'Venezuela',
    provinces: [
      {
        name: 'Zulia',
      },
      {
        name: 'Yaracuy',
      },
      {
        name: 'Trujillo',
      },
      {
        name: 'Táchira',
      },
      {
        name: 'Sucre',
      },
      {
        name: 'Portuguesa',
      },
      {
        name: 'Nueva Esparta',
      },
      {
        name: 'Monagas',
      },
      {
        name: 'Miranda',
      },
      {
        name: 'Mérida',
      },
      {
        name: 'Lara',
      },
      {
        name: 'Guárico',
      },
      {
        name: 'Dependencias Federales',
      },
      {
        name: 'Distrito Federal',
      },
      {
        name: 'Falcón',
      },
      {
        name: 'Delta Amacuro',
      },
      {
        name: 'Cojedes',
      },
      {
        name: 'Carabobo',
      },
      {
        name: 'Bolívar',
      },
      {
        name: 'Barinas',
      },
      {
        name: 'Aragua',
      },
      {
        name: 'Apure',
      },
      {
        name: 'Anzoátegui',
      },
      {
        name: 'Amazonas',
      },
      {
        name: 'Vargas',
      },
    ],
  },
  'British Virgin Islands': {
    countryName: 'British Virgin Islands',
    provinces: null,
  },
  'U.S. Virgin Islands': {
    countryName: 'U.S. Virgin Islands',
    provinces: [
      {
        name: 'Saint Croix Island',
      },
      {
        name: 'Saint John Island',
      },
      {
        name: 'Saint Thomas Island',
      },
    ],
  },
  Vietnam: {
    countryName: 'Vietnam',
    provinces: [
      {
        name: 'Nghệ An',
      },
      {
        name: 'Ninh Bình',
      },
      {
        name: 'Ninh Thuận',
      },
      {
        name: 'Sóc Trăng',
      },
      {
        name: 'Trà Vinh',
      },
      {
        name: 'Tuyên Quang',
      },
      {
        name: 'Vĩnh Long',
      },
      {
        name: 'Yên Bái',
      },
      {
        name: 'Lào Cai',
      },
      {
        name: 'Tiền Giang',
      },
      {
        name: 'Thừa Thiên-Huế',
      },
      {
        name: 'Kon Tum',
      },
      {
        name: 'Thanh Hóa',
      },
      {
        name: 'Thái Bình',
      },
      {
        name: 'Tây Ninh Province',
      },
      {
        name: 'Sơn La',
      },
      {
        name: 'Quảng Trị',
      },
      {
        name: 'Quảng Ninh',
      },
      {
        name: 'Quảng Ngãi Province',
      },
      {
        name: 'Quảng Bình',
      },
      {
        name: 'Phú Yên',
      },
      {
        name: 'Hòa Bình',
      },
      {
        name: 'Long An',
      },
      {
        name: 'Lạng Sơn',
      },
      {
        name: 'Lâm Đồng',
      },
      {
        name: 'Lai Châu',
      },
      {
        name: 'Kiến Giang',
      },
      {
        name: 'Khánh Hòa',
      },
      {
        name: 'Ho Chi Minh',
      },
      {
        name: 'Hà Tĩnh',
      },
      {
        name: 'Hà Giang',
      },
      {
        name: 'Gia Lai',
      },
      {
        name: 'Hanoi',
      },
      {
        name: 'Can Tho',
      },
      {
        name: 'Haiphong',
      },
      {
        name: 'Bình Thuận',
      },
      {
        name: 'Đồng Tháp',
      },
      {
        name: 'Đồng Nai',
      },
      {
        name: 'Đắk Lắk',
      },
      {
        name: 'Bà Rịa-Vũng Tàu',
      },
      {
        name: 'Cao Bằng',
      },
      {
        name: 'Bình Định',
      },
      {
        name: 'Bến Tre',
      },
      {
        name: 'An Giang',
      },
      {
        name: 'Ðắk Nông',
      },
      {
        name: 'Ðiện Biên',
      },
      {
        name: 'Bắc Ninh',
      },
      {
        name: 'Bắc Giang',
      },
      {
        name: 'Da Nang',
      },
      {
        name: 'Bình Dương',
      },
      {
        name: 'Bình Phước',
      },
      {
        name: 'Thái Nguyên',
      },
      {
        name: 'Quảng Nam',
      },
      {
        name: 'Phú Thọ',
      },
      {
        name: 'Nam Định',
      },
      {
        name: 'Hà Nam',
      },
      {
        name: 'Bắc Kạn',
      },
      {
        name: 'Bạc Liêu',
      },
      {
        name: 'Cà Mau',
      },
      {
        name: 'Hải Dương',
      },
      {
        name: 'Hưng Yên',
      },
      {
        name: 'Vĩnh Phúc',
      },
      {
        name: 'Hậu Giang',
      },
    ],
  },
  Vanuatu: {
    countryName: 'Vanuatu',
    provinces: [
      {
        name: 'Tafea',
      },
      {
        name: 'Sanma',
      },
      {
        name: 'Torba',
      },
      {
        name: 'Malampa',
      },
      {
        name: 'Penama',
      },
      {
        name: 'Shefa',
      },
    ],
  },
  'Wallis and Futuna': {
    countryName: 'Wallis and Futuna',
    provinces: [
      {
        name: 'Uvea',
      },
      {
        name: 'Sigave',
      },
      {
        name: 'Alo',
      },
    ],
  },
  Samoa: {
    countryName: 'Samoa',
    provinces: [
      {
        name: 'Vaisigano',
      },
      {
        name: 'Va‘a-o-Fonoti',
      },
      {
        name: 'Tuamasaga',
      },
      {
        name: 'Satupa‘itea',
      },
      {
        name: 'Palauli',
      },
      {
        name: 'Gagaifomauga',
      },
      {
        name: 'Gaga‘emauga',
      },
      {
        name: 'Fa‘asaleleaga',
      },
      {
        name: 'Atua',
      },
      {
        name: 'Aiga-i-le-Tai',
      },
      {
        name: "A'ana",
      },
    ],
  },
  Yemen: {
    countryName: 'Yemen',
    provinces: [
      {
        name: 'Ta‘izz',
      },
      {
        name: 'Shabwah',
      },
      {
        name: 'Sanaa',
      },
      {
        name: 'Şa‘dah',
      },
      {
        name: 'Raymah',
      },
      {
        name: 'Ma’rib',
      },
      {
        name: 'Al Maḩwīt',
      },
      {
        name: 'Al Jawf',
      },
      {
        name: 'Muhafazat Hadramaout',
      },
      {
        name: 'Dhamār',
      },
      {
        name: 'Al Mahrah',
      },
      {
        name: 'Al Hudaydah',
      },
      {
        name: 'Al Bayda',
      },
      {
        name: 'Aden',
      },
      {
        name: 'Abyan',
      },
      {
        name: 'Aḑ Ḑāli‘',
      },
      {
        name: 'Omran',
      },
      {
        name: 'Ḩajjah',
      },
      {
        name: 'Ibb',
      },
      {
        name: 'Laḩij',
      },
      {
        name: 'Amanat Alasimah',
      },
      {
        name: 'Soqatra',
      },
    ],
  },
  Mayotte: {
    countryName: 'Mayotte',
    provinces: [
      {
        name: 'Acoua',
      },
      {
        name: 'Bandraboua',
      },
      {
        name: 'Bandrele',
      },
      {
        name: 'Bouéni',
      },
      {
        name: 'Chiconi',
      },
      {
        name: 'Chirongui',
      },
      {
        name: 'Dembeni',
      },
      {
        name: 'Dzaoudzi',
      },
      {
        name: 'Kani-Kéli',
      },
      {
        name: 'Koungou',
      },
      {
        name: 'Mamoudzou',
      },
      {
        name: 'Mtsamboro',
      },
      {
        name: "M'Tsangamouji",
      },
      {
        name: 'Ouangani',
      },
      {
        name: 'Pamandzi',
      },
      {
        name: 'Sada',
      },
      {
        name: 'Tsingoni',
      },
    ],
  },
  'South Africa': {
    countryName: 'South Africa',
    provinces: [
      {
        name: 'Orange Free State',
      },
      {
        name: 'KwaZulu-Natal',
      },
      {
        name: 'Eastern Cape',
      },
      {
        name: 'Gauteng',
      },
      {
        name: 'Mpumalanga',
      },
      {
        name: 'Northern Cape',
      },
      {
        name: 'Limpopo',
      },
      {
        name: 'North-West',
      },
      {
        name: 'Western Cape',
      },
    ],
  },
  Zambia: {
    countryName: 'Zambia',
    provinces: [
      {
        name: 'Western',
      },
      {
        name: 'Southern',
      },
      {
        name: 'North-Western',
      },
      {
        name: 'Northern',
      },
      {
        name: 'Lusaka',
      },
      {
        name: 'Luapula',
      },
      {
        name: 'Eastern',
      },
      {
        name: 'Copperbelt',
      },
      {
        name: 'Central',
      },
      {
        name: 'Muchinga',
      },
    ],
  },
  Zimbabwe: {
    countryName: 'Zimbabwe',
    provinces: [
      {
        name: 'Midlands',
      },
      {
        name: 'Matabeleland South',
      },
      {
        name: 'Matabeleland North',
      },
      {
        name: 'Masvingo',
      },
      {
        name: 'Mashonaland West',
      },
      {
        name: 'Mashonaland East',
      },
      {
        name: 'Mashonaland Central',
      },
      {
        name: 'Manicaland',
      },
      {
        name: 'Bulawayo',
      },
      {
        name: 'Harare',
      },
    ],
  },
  'Serbia and Montenegro': {
    countryName: 'Serbia and Montenegro',
    provinces: null,
  },
  'Netherlands Antilles': {
    countryName: 'Netherlands Antilles',
    provinces: null,
  },
};
