export interface RoutesInterface {
  path: string;
  id: string;
  scopes: string;
}

export interface AppRouteInterface {
  [index: string]: RoutesInterface;
}

export const ORGANIZATION_ROUTES = {
  ORGANIZATION: {
    path: '/organization',
    id: 'ORGANIZATION',
    scopes: 'org:read org:read:own_org_only',
  },
  ORGANIZATION_DETAILS: {
    path: '/organization/:id',
    id: 'ORGANIZATION_DETAILS',
    scopes: 'org:read org:read:own_org_only',
  },
  ORGANIZATION_USERS: {
    path: '/organization/:id/users',
    id: 'ORGANIZATION_USERS',
    scopes: 'user:read user:read:own_org_only',
  },
  ORGANIZATION_USERS_DETAILS: {
    path: '/organization/:id/users/:userId',
    id: 'ORGANIZATION_USERS_DETAILS',
    scopes: 'user:read user:read:own_org_only',
  },
  ORGANIZATION_MAP: {
    path: '/organization/:id/map',
    id: 'ORGANIZATION_MAP',
    scopes: 'org:read org:read:own_org_only',
  },
  ORGANIZATION_QR_CODE: {
    path: '/organization/:id/qr-code',
    id: 'ORGANIZATION_QR_CODE',
    scopes: 'org:read org:read:own_org_only',
  },
};

export const POLICE_DEPT_ROUTES = {
  POLICE_DEPT: {
    path: '/police-department',
    id: 'POLICE_DEPT',
    scopes: 'org:read org:read:own_org_only',
  },
  POLICE_DEPT_DETAILS: {
    path: '/police-department/:id',
    id: 'POLICE_DEPT_DETAILS',
    scopes: 'org:read org:read:own_org_only',
  },
  POLICE_DEPT_USERS: {
    path: '/police-department/:id/users',
    id: 'POLICE_DEPT_USERS',
    scopes: 'user:read user:read:own_org_only',
  },
  POLICE_DEPT_USERS_DETAILS: {
    path: '/police-department/:id/users/:userId',
    id: 'POLICE_DEPT_USERS_DETAILS',
    scopes: 'user:read user:read:own_org_only',
  },
  POLICE_DEPT_ORG: {
    path: '/police-department/:id/org',
    id: 'POLICE_DEPT_ORG',
    scopes: 'org:read org:read:own_org_only',
    // todo add valid scope for supervisor
  },
  POLICE_DEPT_QR_CODE: {
    path: '/police-department/:id/qr-code',
    id: 'POLICE_DEPT_ORG',
    scopes: 'org:read org:read:own_org_only',
  },
};
export const EMS_ROUTES = {
  EMS_ORG_LIST: {
    path: '/ems',
    id: 'EMS_ORG_LIST',
    scopes: 'org:read org:read:own_org_only',
  },
  EMS_ORG_DETAILS: {
    path: '/ems/:id',
    id: 'EMS_ORG_DETAILS',
    scopes: 'org:read org:read:own_org_only',
  },
  EMS_ORG_USERS: {
    path: '/ems/:id/users',
    id: 'EMS_ORG_USERS',
    scopes: 'user:read user:read:own_org_only',
  },
  EMS_ORG_USERS_DETAILS: {
    path: '/ems/:id/users/:userId',
    id: 'EMS_ORG_USERS_DETAILS',
    scopes: 'user:read user:read:own_org_only',
  },
  EMS_ORG_ASSOCIATION: {
    path: '/ems/:id/org',
    id: 'EMS_ORG_ASSOCIATION',
    scopes: 'org:read org:read:own_org_only',
    // todo add valid scope for supervisor
  },
  EMS_ORG_QR_CODE: {
    path: '/ems/:id/qr-code',
    id: 'EMS_ORG_QR_CODE',
    scopes: 'org:read org:read:own_org_only',
  },
};

export const SPECIAL_EVENT_ROUTES = {
  SPECIAL_EVENT_LIST: {
    path: '/special-event',
    id: 'SPECIAL_EVENT_LIST',
    scopes: 'org:read', //change
  },
  SPECIAL_EVENT_DETAILS: {
    path: '/special-event/:id',
    id: 'SPECIAL_EVENT_DETAILS',
    scopes: 'org:read org:read:own_org_only',
  },
  SPECIAL_EVENT_USERS: {
    path: '/special-event/:id/users',
    id: 'SPECIAL_EVENT_USERS',
    scopes: 'user:read user:read:own_org_only',
  },
  SPECIAL_EVENT_USERS_DETAILS: {
    path: '/special-event/:id/users/:userId',
    id: 'SPECIAL_EVENT_USERS_DETAILS',
    scopes: 'user:read user:read:own_org_only',
  },
  SPECIAL_EVENT_MAP: {
    path: '/special-event/:id/map',
    id: 'SPECIAL_EVENT_MAP',
    scopes: 'org:read org:read:own_org_only',
  },
  SPECIAL_EVENT_QR_CODE: {
    path: '/special-event/:id/qr-code',
    id: 'SPECIAL_EVENT_QR_CODE',
    scopes: 'org:read org:read:own_org_only',
  },
};

export const USERS_ROUTES = {
  USERS: {
    path: '/users',
    id: 'USERS',
    scopes: 'user:read user:read:own_org_only',
  },
  USERS_DETAILS: {
    path: '/users/:id',
    id: 'USERS_DETAILS',
    scopes: 'user:read user:read:own_org_only',
  },
};

export const OPS_CENTER_ROUTES = {
  OPS_CENTER: {
    path: '/events/:id',
    id: 'OPS_CENTER',
    scopes: 'event:read',
  },
  EVENT_LIST: {
    path: '/events',
    id: 'EVENT_LIST',
    scopes: 'event:read',
  },
};

export const VIDEO_CALL_ROUTES = {
  VIDEO_CALL: {
    path: '/video_call',
    id: 'VIDEO_CALL',
    scopes: 'event:read',
  },
};

export const ROUTES = {
  // ORGANIZATION ROUTES
  ...ORGANIZATION_ROUTES,

  // POLICE DEPARTMENT ROUTES
  ...POLICE_DEPT_ROUTES,

  // EMS ROUTES
  ...EMS_ROUTES,

  // SPECIAL EVENT ROUTES
  ...SPECIAL_EVENT_ROUTES,

  // USERS ROUTES
  ...USERS_ROUTES,

  //   common
  REGISTER: {
    path: '/register',
  },
  LOGIN: {
    path: '/login',
  },
  ERROR: {
    path: '/error',
  },
};

export const DEFAULT_LANDING_ROUTE = ORGANIZATION_ROUTES.ORGANIZATION;
