import { Middleware } from 'redux';
import { io, Socket } from 'socket.io-client';
import { toast } from 'react-toastify';
import { webSocketActions } from './ws.slice';
import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs';
import { PUBSUB_BASE_URL } from 'config/api';
import {
  EventJoinRequestEnum,
  EventJoinResponseInterface,
  getNewEventJoinRequestsList,
  getNewProfileList,
  getNewReportList,
} from 'utils';
import { videoCallActions, VideoCallData } from 'store/videoCall';
import IncomingVideoCallClose, {
  IncomingVideoCallName,
} from 'routes/VideoCall/incomingCallToast';
import React from 'react';
import {
  EventInterface,
  EventUserLocationResponseInterface,
  EventUserResponseInterface,
  ReportInterface,
  ReportsListSuccessPayload,
  REPORT_TYPE,
} from 'utils';
import { WS_PAYLOAD_TYPE } from './ws.constants';
import { eventActions } from 'store/event/event.slice';
import { push } from 'connected-react-router';
import _, { debounce, throttle } from 'lodash';
import { camelCaseForObject } from 'services/module/helper';
import {
  eventUserActions,
  EventUserLocationPayload,
} from 'store/eventUser/eventUser.slice';
import {
  basicEventUserDetailsFromJson,
  eventUserLocationModel,
  eventUserModel,
} from 'models/event/eventUser.model';

import { loginActions } from 'store/login';
import * as localStorageService from 'services/localStorage.service';
import { messaging } from 'config/firebaseConfig';
import { deleteToken } from 'firebase/messaging';
import { basicEventDetailsFromJson } from 'models/event/event.model';

let fp: Agent;

export async function getBrowserId(): Promise<string> {
  if (!fp) {
    fp = await FingerprintJS.load();
  }
  const result = await fp.get();

  return result.visitorId;
}

interface MessageInteface {
  payload: any;
  type: string;
}

const webSocketMiddleware: Middleware = (store) => {
  let socket: Socket;
  let eventUserLocationData: Record<string, EventUserLocationPayload> = {};

  const throttledUpdateUserLocation = throttle(() => {
    store.dispatch(
      eventUserActions.bulkUpdateEventUserLocation(eventUserLocationData),
    );
    eventUserLocationData = {};
  }, 1000);

  return (next) => (action) => {
    if (webSocketActions.setSocketUrl.match(action)) {
      getBrowserId().then((visitorId) => {
        if (socket?.connected !== true) {
          toast('Connecting to live updates!', {
            toastId: 'socket_connection_broken',
            type: 'warning',
            autoClose: false,
          });

          socket = io(PUBSUB_BASE_URL, {
            reconnectionDelay: 1000,
            reconnectionAttempts: 5,
            extraHeaders: {
              auth: action.payload.token,
              deviceid: visitorId,
            },
          });

          socket.on('connect', () => {
            console.log(
              '%c webSocketMiddleware ',
              'background: lime; color: black',
              'Connected with socket id:',
              socket.id,
            );

            toast.update('socket_connection_broken', {
              render: 'Socket connection established',
              type: 'success',
              autoClose: 1000,
            });
          });

          socket.on('disconnect', (msg) => {
            console.log(
              '%c webSocketMiddleware ',
              'background: lime; color: black',
              'Disconnect from socket id',
            );

            toast.update('socket_connection_broken', {
              render: 'Socket connection broken',
              type: toast.TYPE.ERROR,
              autoClose: false,
            });
          });

          socket.on('message', (msg: MessageInteface) => {
            const { payload, type } = msg;
            console.log(
              '%c webSocketMiddleware ',
              'background: lime; color: black',
              'Got the message',
              msg,
            );

            switch (msg.type) {
              default:
            }

            switch (type) {
              case WS_PAYLOAD_TYPE.EVENT_JOIN_REQUEST:
                const eventJoinRequestPayload = payload as EventJoinResponseInterface;
                const eventJoinRequests: EventJoinResponseInterface[] = store.getState()
                  .event.eventJoinRequests;
                const isJoinRequestAlreadyExist = eventJoinRequests.find(
                  (request) => request.id === eventJoinRequestPayload.id,
                );
                if (
                  !isJoinRequestAlreadyExist &&
                  eventJoinRequestPayload.status ===
                    EventJoinRequestEnum.PENDING
                ) {
                  store.dispatch(
                    eventActions.fetchEventJoinRequestsSuccess(
                      getNewEventJoinRequestsList(
                        eventJoinRequestPayload,
                        eventJoinRequests,
                      ),
                    ),
                  );
                } else if (
                  isJoinRequestAlreadyExist &&
                  eventJoinRequestPayload.status ===
                    EventJoinRequestEnum.ACCEPTED
                ) {
                  store.dispatch(
                    eventActions.acceptEventJoinRequestSuccess(
                      eventJoinRequestPayload,
                    ),
                  );
                } else if (
                  isJoinRequestAlreadyExist &&
                  eventJoinRequestPayload.status ===
                    EventJoinRequestEnum.REJECTED
                ) {
                  store.dispatch(
                    eventActions.denyEventJoinRequestSuccess(
                      eventJoinRequestPayload,
                    ),
                  );
                }
                break;

              case WS_PAYLOAD_TYPE.REPORT:
                const suspectReports = store.getState().event.suspectReports;
                const injuredReports = store.getState().event.injuredReports;

                store.dispatch(
                  eventActions.fetchReportsSuccess(
                    getNewReportList(payload, injuredReports, suspectReports),
                  ),
                );
                break;
              case WS_PAYLOAD_TYPE.PROFILE:
                const suspectProfiles = store.getState().event.suspectProfiles;
                const injuredProfiles = store.getState().event.injuredProfiles;
                const selectedFilter = store.getState().event.selectedFilter;

                const reports = getNewProfileList(
                  payload,
                  injuredProfiles,
                  suspectProfiles,
                  selectedFilter,
                );
                store.dispatch(
                  eventActions.updateProfilesSuccess({
                    injuredProfiles: reports.injuredReports,
                    suspectProfiles: reports.suspectReports,
                  }),
                );
                break;
              case WS_PAYLOAD_TYPE.VIDEO_CALL_END:
              case WS_PAYLOAD_TYPE.VIDEO_CALL_JOIN:
                store.dispatch(
                  videoCallActions.setOngoingVideoCall(
                    camelCaseForObject(msg.payload) as VideoCallData,
                  ),
                );

                toast.update('incoming_video_call', { onClose: () => true });
                toast.dismiss('incoming_video_call');
                // store.dispatch(push('/video_call'));
                break;
              case WS_PAYLOAD_TYPE.VIDEO_CALL_INITIATE:
                store.dispatch(
                  videoCallActions.setIncomingVideoCall(
                    camelCaseForObject(msg.payload) as VideoCallData,
                  ),
                );
                const videoCallData = camelCaseForObject(
                  msg.payload,
                ) as VideoCallData;

                const videoCallId = (camelCaseForObject(
                  msg.payload,
                ) as VideoCallData).id;
                let wasRejected = false;

                toast.warn(
                  React.createElement(IncomingVideoCallName, {
                    callerName: videoCallData.callerName,
                  }),
                  {
                    closeButton: React.createElement(IncomingVideoCallClose, {
                      videoCallId,
                      closeButtonCallback: () => (wasRejected = true),
                    }),
                    theme: 'dark',
                    autoClose: 8000,
                    hideProgressBar: false,
                    // onClose is not needed since backend is handling closing of video call
                    // onClose: () => {
                    //   if (!wasRejected) {
                    //     store.dispatch(
                    //       videoCallActions.rejectVideoCall(videoCallData.id),
                    //     );
                    //   }
                    // },
                    toastId: 'incoming_video_call',
                  },
                );

                break;
              case WS_PAYLOAD_TYPE.VIDEO_CALL_RESPONSE_STATUS:
                // Received when call is rejected
                store.dispatch(
                  videoCallActions.unsetOutgoingCall(
                    camelCaseForObject(msg.payload) as VideoCallData,
                  ),
                );
                const event = store.getState().event.onGoingEvent;
                if (!_.isNil(event)) {
                  store.dispatch(push(`/events/${event.id}`));
                }
                break;
              case WS_PAYLOAD_TYPE.EVENT_USER_LOCATION:
                const data = eventUserLocationModel(
                  msg.payload as EventUserLocationResponseInterface,
                );

                eventUserLocationData[data.user.id] = data;

                throttledUpdateUserLocation();
                break;
              case WS_PAYLOAD_TYPE.EVENT_USER:
                const eventUser = eventUserModel(
                  msg.payload as EventUserResponseInterface,
                );
                const authUserId: string = store.getState().login.auth.id;
                if (eventUser.archivedAt === null) {
                  store.dispatch(eventUserActions.addEventUser(eventUser));
                } else {
                  //remove user
                  store.dispatch(eventUserActions.removeEventUser(eventUser));
                  if (eventUser.user.id === authUserId) {
                    // for commander leaving the event from other device
                    store.dispatch(eventActions.leaveActiveEventSuccess());
                  }
                }
                break;
              case WS_PAYLOAD_TYPE.EVENT:
                const pathname = window.location.pathname;
                if (pathname.includes(payload.id) && payload.ended_at) {
                  localStorageService.logout();
                  if (messaging) {
                    deleteToken(messaging);
                  }
                  store.dispatch(loginActions.logoutUser());
                } else {
                  if (payload.has_joined) {
                    const transformEventData = basicEventDetailsFromJson([
                      payload,
                    ]);
                    store.dispatch(
                      eventActions.successActiveEvent({
                        onGoingEvent: transformEventData[0],
                        eventList: null,
                      }),
                    );
                  } else {
                    const transformEventData = basicEventDetailsFromJson([
                      payload,
                    ]);
                    store.dispatch(
                      eventActions.successActiveEvent({
                        onGoingEvent: null,
                        eventList: transformEventData,
                      }),
                    );
                  }
                }
                break;
            }
          });

          socket.on('connect_error', () => {
            console.log(
              '%c webSocketMiddleware ',
              'background: lime; color: black',
              'Connect to pubsub failed',
            );
          });

          socket.io.on('reconnect_error', () => {
            console.log(
              '%c webSocketMiddleware ',
              'background: lime; color: black',
              'Reconnection failed!',
            );

            toast.update('socket_connection_broken', {
              render: 'Reconnection failed',
              type: toast.TYPE.ERROR,
              autoClose: false,
            });
          });

          socket.io.on('reconnect_attempt', () => {
            console.log(
              '%c webSocketMiddleware ',
              'background: lime; color: black',
              'Reconnecting...',
            );

            toast.update('socket_connection_broken', {
              render: 'Reconnecting...',
              type: toast.TYPE.ERROR,
              autoClose: false,
            });
          });

          socket.io.on('reconnect_failed', () => {
            console.log(
              '%c webSocketMiddleware ',
              'background: lime; color: black',
              'Live updates stopped! Please refresh after sometime',
            );

            toast.update('socket_connection_broken', {
              render: 'Live updates stopped! Please refresh after sometime',
              type: toast.TYPE.ERROR,
              autoClose: false,
            });

            store.dispatch(webSocketActions.startConnecting());
          });
        }
      });
    }

    if (webSocketActions.closeConnection.match(action)) {
      if (socket) {
        console.log(
          '%c webSocketMiddleware ',
          'background: lime; color: black',
          'Staring disconnect for socket id',
          socket.id,
        );
        socket.disconnect();
      }
    }

    next(action);
  };
};

export default webSocketMiddleware;
