import React, { useEffect, useState, useRef } from 'react';
import {
  Collapse,
  TextareaAutosize,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  PersonDescriptionInterface,
  Profile,
  FilesInterface,
  SUSPECT_PROERTIES_KEY,
  SUSPECT_PROFILE_PROPERTY_KEY,
} from 'utils';
import EmptyImageSvg from '../../assets/empty-image.svg';
import { useMapContainerStyle } from './style';
import _ from 'lodash';
import {
  CarouselComponent,
  ClickableSuspectCharsComp,
} from 'components/General/SuspectProperties';
import { CustomButton } from 'components/General/CustomButton';
import { useDispatch } from 'react-redux';
import { eventActions } from 'store/event/event.slice';
import { useDrop } from 'react-dnd';
import MenuIcon from '../../assets/ellipsis.svg';
import {
  CheckCircleOutline,
  RadioButtonUnchecked,
  Image,
} from '@material-ui/icons';

export function SuspectProfile(props: { profile: Profile }) {
  const { profile } = props;
  const classes = useMapContainerStyle();
  const dispatch = useDispatch();
  const [properties, setProperties] = useState<any>({});
  const [isExpanded, setExpand] = useState(false);
  const files = profile.files;
  const hiddenFileInput: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null,
  );

  const [previewImage, setPreviewImage] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [comment, setComment] = useState(
    profile.comment ? profile.comment : '',
  );

  useEffect(() => {
    const obj: { [key: string]: string } = {};
    (Object.keys(SUSPECT_PROERTIES_KEY) as Array<
      keyof typeof SUSPECT_PROERTIES_KEY
    >).forEach((label) => {
      const labelValue = profile[label];
      if (labelValue) {
        obj[label] = labelValue;
      }
    });
    setProperties(obj);
  }, [profile]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'report',
    drop: () => ({ name: profile.id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handlePropertySelected = (keyProp: string, label: string) => {
    if (properties[keyProp] && properties[keyProp] === label) {
      setProperties((prev: any) => {
        const newObj = _.omit(prev, keyProp);
        return newObj;
      });
    } else
      setProperties((prev: any) => {
        return { ...prev, [keyProp]: label };
      });
  };

  const handleSave = () => {
    const body: { [key: string]: string | File[] } = { ...properties };

    const jsonData: { [key: string]: string | File[] } = {};

    jsonData['data'] = JSON.stringify(body);

    if (image) {
      jsonData['files'] = files.length ? [...files, image] : [image];
    }

    // To do add file in format
    dispatch(
      eventActions.updateProfilesRequest({
        id: profile.id,
        body: jsonData,
      }),
    );
    setExpand(false);
  };

  const computeReportProperties = () => {
    const personDescList = profile.person_descriptions;
    const tempObj: any = {};
    if (personDescList.length) {
      (Object.keys(SUSPECT_PROERTIES_KEY) as Array<
        keyof typeof SUSPECT_PROERTIES_KEY
      >).forEach((label) => {
        // @ts-ignore
        const labelExistsInReports = personDescList.map(
          (profileObj: PersonDescriptionInterface) => profileObj[label],
        );
        tempObj[label] = labelExistsInReports.filter((ele: any) => ele);
      });
      return tempObj;
    } else return null;
  };

  const handleComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
    setProperties((prev: any) => {
      return { ...prev, comment: event.target.value };
    });
  };

  const getImageList = () => {
    const arrayTemp: FilesInterface[] | [] = [];
    // @ts-ignore
    profile?.person_descriptions.map((obj: PersonDescriptionInterface) => {
      if (obj.files.length) {
        // @ts-ignore
        obj.files?.map((filesObj: FilesInterface) => {
          if (filesObj) {
            // @ts-ignore
            arrayTemp.push(filesObj);
          }
        });
      }
    });
    // @ts-ignore
    profile.files?.map((filesObj: FilesInterface) => {
      if (filesObj) {
        // @ts-ignore
        arrayTemp.push(filesObj);
      }
    });
    return arrayTemp;
  };

  const imageList = getImageList();

  const handleAddImage = (event: any) => {
    event.target.value = '';
    hiddenFileInput?.current?.click();
  };

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResolve = () => {
    const body = { resolved_at: profile.resolved_at ? null : new Date() };
    const jsonData: { [key: string]: string | null } = {};
    jsonData['data'] = JSON.stringify(body);
    dispatch(
      eventActions.updateProfilesRequest({
        id: profile.id,
        body: jsonData,
      }),
    );
    handleClose();
  };

  return (
    <div
      ref={drop}
      className={`${classes.tunaBgColor} ${classes.suspectProfileCtn}`}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          onClick={() => setExpand((prev) => !prev)}
          data-cy="suspect-profile-text"
          className={`${classes.headerText} ${classes.padding}`}
        >
          Threat #{profile.index}
        </Typography>
        <IconButton data-cy="suspect-profile-menu" onClick={handleClick}>
          <img src={MenuIcon} alt="logo" className={classes.headerAddIcon} />
        </IconButton>
        <Menu
          className={classes.menuCtnBg}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuItem data-cy="change-status" onClick={handleResolve}>
            {profile.resolved_at ? (
              <CheckCircleOutline
                style={{ marginRight: 8 }}
                className={classes.persianGreenColor}
              />
            ) : (
              <RadioButtonUnchecked
                style={{ marginRight: 8 }}
                className={classes.persianGreenColor}
              />
            )}
            {profile.resolved_at ? 'Activate' : 'Resolve'}
          </MenuItem>
          <MenuItem onClick={handleAddImage}>
            <Image
              style={{ marginRight: 8 }}
              className={classes.persianGreenColor}
            />
            Add Image{' '}
            <input
              type="file"
              name="Upload Suspect Image"
              onChange={handleUploadFile}
              style={{ display: 'none' }}
              ref={hiddenFileInput}
            />
          </MenuItem>
        </Menu>
      </div>
      {imageList.length > 0 ? (
        <CarouselComponent imgList={imageList} />
      ) : (
        <img
          src={previewImage ? previewImage : EmptyImageSvg}
          alt=""
          className={classes.susImage}
        />
      )}

      <div className={classes.suspectProfilePropertiesCtn}>
        {Object.keys(SUSPECT_PROFILE_PROPERTY_KEY).map((property) => {
          const labels: string[] = SUSPECT_PROFILE_PROPERTY_KEY[property];

          const compArr: React.ReactElement[] = [];

          for (const index in labels) {
            const label = labels[index];

            const reportProps = computeReportProperties();

            compArr.push(
              <ClickableSuspectCharsComp
                key={`${label}-${index}`}
                label={label}
                badge={
                  reportProps &&
                  property &&
                  reportProps[property].filter(
                    (element: string) => element === label,
                  ).length
                }
                onClick={handlePropertySelected}
                isActive={_.includes(Object.values(properties), label)}
                properties={properties}
                propertyKey={property}
                isSelectedByCom={_.includes(Object.values(properties), label)}
                isPresentInPD={Boolean(
                  reportProps &&
                    property &&
                    reportProps[property].filter(
                      (element: string) => element === label,
                    ).length,
                )}
                disable={false}
              />,
            );
          }

          return (
            <div
              style={{ display: 'flex', flexDirection: 'row' }}
              key={property}
            >
              {compArr}
            </div>
          );
        })}
      </div>

      <Collapse in={true}>
        <Typography className={classes.headerText}>Comments</Typography>
        <TextareaAutosize
          className={`${classes.tunaBgColor} ${classes.comment}`}
          rowsMin={5}
          onChange={handleComment}
          value={comment}
        />
        <div className={classes.btnCtn}>
          <CustomButton onClick={handleSave} error={false} label="Save" />
        </div>
      </Collapse>
    </div>
  );
}

export default SuspectProfile;
