import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'styles/colorConstants';

export const useMapContainerStyle = makeStyles((theme: Theme) => {
  return createStyles({
    app: {
      height: '100%',
      width: '100%',
      flex: 1,
      // marginLeft: theme.spacing(-8),
      // marginRight: theme.spacing(-8),
    },
    container: {
      width: '100%',
      height: '100%',
    },
    mapContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      // paddingLeft: theme.spacing(8),
    },
    profileContainer: {
      // width: '100%',
      height: '100%',
    },
    'app-map': {
      height: '100%',
      width: '100%',
    },
    arrowIcon: {
      width: '30px',
      height: '21px',
      marginTop: '4px',
    },
    hide: {
      display: 'none',
    },
    iconCtn: {
      backgroundColor: COLORS.OPS_CENTER_GREY,
      height: '100%',
    },
    header: {
      backgroundColor: COLORS.OPS_CENTER_GREY,
      display: 'flex',
      width: '100%',
      // justifyContent: 'space-around',
      alignItems: 'center',
    },
    navSection: {
      display: 'flex',
      // alignItems: 'start',
      // justifyContent: 'flex-end',
      width: '100%',
      height: '90%',
    },
    profilesContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
    },
    navCtn: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    raisinBgColor: {
      backgroundColor: COLORS.RAISIN_BLACK,
    },
    tunaBgColor: {
      backgroundColor: COLORS.TUNA,
    },
    disabledReportBgColor: {
      backgroundColor: COLORS.DISABLED_REPORT,
    },
    profileCtn: {
      // marginLeft: '8px',
      // width: '50%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: '100%',
    },
    halfFlex: {
      flex: 0.5,
    },
    fullFlex: {
      flex: 1,
    },
    headerText: {
      font: 'Montserrat',
      fontWeight: 600,
    },
    padding: {
      padding: '8px',
    },
    centerAlign: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    headerAddIcon: {
      height: '22px',
      width: '22px',
    },
    headerIconCtn: {
      alignSelf: 'end',
    },
    headerCtn: {
      display: 'flex',
      alignItems: 'center',
    },
    halfWidth: {
      width: '50%',
    },
    fullWidth: {
      width: '100%',
    },
    oneThirdWidth: {
      width: '33%',
    },
    report: {
      width: '50%',
      // maxHeight: '90%',
      flex: 1,
      overflow: 'auto',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      scrollbarColor: '#121C21 #121C21',
      // marginBottom: theme.spacing(8),
      // overflow: 'scroll',
    },
    profileBoxCtn: {
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      // maxHeight: '90%',
      // height: '90%',
      flex: 1,
      overflow: 'auto',
      scrollbarColor: '#121C21 #121C21',
      scrollbarWidth: 'thin',

      // overflow: 'scroll',
    },
    tabCtn: {
      // marginTop: 'auto',
      marginTop: '10px',
      display: 'flex',
      alignSelf: 'center',
      paddingTop: '8px',
      marginBottom: '20px',
    },
    reportCtn: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '8px',
      width: '90%',
      border: '1px solid #707070;',
      padding: '8px',
      marginTop: '16px',
    },
    involvedUserCtn: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '8px',
      width: '90%',
      border: '1px solid #707070;',
      padding: '8px',
      marginTop: '16px',
    },
    joinBtnsCtn: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '6px',
    },
    greenBtnColor: {
      backgroundColor: COLORS.GREEN,
      boxShadow: `0px 4px 0px ${COLORS.GREEN_ACCENT}`,
      '&:hover': {
        backgroundColor: COLORS.GREEN,
        color: COLORS.BLACK,
      },
      marginLeft: '18px',
    },
    redBtnColor: {
      backgroundColor: COLORS.RED,
      boxShadow: `0px 4px 0px ${COLORS.RED_SHADOW}`,
      '&:hover': {
        backgroundColor: COLORS.RED,
        color: COLORS.BLACK,
      },
      marginRight: '18px',
    },
    btnSize: {
      color: COLORS.BLACK,
      borderRadius: '4px',
      fontStyle: 'Noto Sans',
      fontSize: '14px',
      width: '8em',
    },
    date: {
      color: COLORS.DATE_COLOR,
      fontSize: '14px',
      fontFamily: 'Montserrat',
    },
    justNow: {
      display: 'flex',
      alignItems: 'center',
      color: COLORS.GREEN,
      fontSize: '14px',
    },
    justNowText: {
      marginLeft: '5px',
    },
    reportType: {
      color: COLORS.WHITE_GREY,
      // fontWeight: 600,
      fontFamily: 'Montserrat',
      fontSize: '20px',
    },
    reportUser: {
      color: COLORS.DATE_COLOR,
      fontSize: '16px',
      fontFamily: 'Montserrat',
    },
    vc: {
      height: '14px',
      width: '30px',
      verticalAlign: 'middle',
      marginLeft: 'auto',
    },
    vcText: {
      color: COLORS.GREEN,
      fontSize: '14px',
      fontFamily: 'Montserrat',
    },
    vcCtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    // Suspect Profile
    suspectProfileCtn: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      padding: '8px',
      marginTop: '16px',
      borderRadius: '8px',
    },
    susImage: {
      width: '100%',
      // height: '250px',
      maxHeight: '200px',
    },
    personDescription: {
      marginTop: '8px',
      borderRadius: '20px',
      width: '40px',
      backgroundColor: COLORS.LIGHT_GREY,
      display: 'flex',
      justifyContent: 'center',
      marginRight: '8px',
      padding: '2px',
    },
    personDescText: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      color: COLORS.RAISIN_BLACK,
      fontSize: '9px',
    },
    // Suspect report details
    suspectReportCtn: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '8px',
      border: '1px solid #707070;',
      padding: '8px',
      margin: '4px',
    },
    suspectPropertiesCtn: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      marginBottom: '8px',
    },
    suspectProfilePropertiesCtn: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      marginBottom: '8px',
    },
    comment: {
      border: '1px solid #707070',
      borderRadius: '4px',
      marginLeft: '8px',
      marginRight: '8px',
      marginBottom: '8px',
      color: COLORS.WHITE,
      width: '90%',
    },
    // Injured profile
    injuredProfileCtn: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      marginTop: '16px',
      borderRadius: '8px',
    },
    injuredHeader: {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    severityText: {
      fontSize: '12px',
      font: 'Poppins',
      fontWeight: 400,
    },
    btnCtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '8px',
      marginBottom: '8px',
    },
    createdCtn: {
      border: 'solid',
      borderColor: '#AC6322',
      borderRadius: '2px',
    },
    createdText: {
      fontSize: '9px',
      fontFamily: 'Montserrat',
      color: '#707070',
      fontWeight: 600,
      padding: '1px',
    },
    progresbarCtn: {
      display: 'flex',
      height: '200px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    outlineNone: {
      outline: 'none',
    },
    menuCtnBg: {
      '& .MuiPaper-root': {
        backgroundColor: COLORS.TUNA,
      },
      color: COLORS.WHITE,
    },
    persianGreenColor: {
      color: COLORS.PERSIAN_GREEN,
    },
    profileSubCtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    eventListCtn: {
      width: '25%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    eventComp: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '4px',
      width: '90%',
      border: `1px solid ${COLORS.RED};`,
      padding: '8px',
      marginTop: '16px',
      backgroundColor: COLORS.OPS_CENTER_GREY,
    },
    eventName: {
      textAlign: 'center',
    },
    eventDate: {
      color: COLORS.WHITE_GREY,
      fontSize: '12px',
      fontFamily: 'Montserrat',
    },
    marginBtm: {
      marginBottom: '8px',
    },
    pendingColor: {
      color: COLORS.PRIORITY_YELLOW,
    },
    rejectColor: {
      color: COLORS.RED,
    },
  });
});
