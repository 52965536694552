import React, { Fragment, useState, useEffect } from 'react';
import { PageBody } from 'container/PageBody';
import NavigationSection from 'components/SubHeader/NavigationSection';
import _, { isNull } from 'lodash';
import {
  POLICE_ORG_NESTED_LINKS,
  POLICE_DEPT_ACTION_BUTTON,
  POLICE_DEPT_ACTIVE_SUB_HEADER_SECTION,
} from 'routes/PoliceDepartment/utils/constants';
import { useRouteMatch } from 'react-router';
import { POLICE_DEPT_ROUTES } from 'routes/path';
import {
  getUserForms,
  useActiveAppRouteMatch,
  useBreadCrumb,
} from 'utils/hooks';
import OrganizationTable from 'components/OrganizationTable';
import {
  ActionButtonInterface,
  AppCategory,
  CombinedActionButtonInterface,
} from 'utils/commonTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdatePoliceDeptHeaderVisibility } from 'routes/PoliceDepartment/utils/hook';
import {
  compareFormObjects,
  isRouteMatch,
  getUpdatedFields,
} from 'utils/helper';
import { CreatePoliceDeptForm } from './PoliceDeptForm';
import { OrganizationUsers } from 'routes/PoliceDepartment/OrganizationUsers';
import { UserForm } from 'routes/Organization/UserForm';
import { updateActionButtonVisibility } from 'routes/PoliceDepartment/utils/helper';
import {
  organizationActions,
  getPoliceUserFormValue,
  getPoliceUserFormErrors,
  getOrganizationFormValue,
  isOrganizationDeptFormTouched,
  getCommandPostPoints,
  getGeoFencePoints,
  getPerimeterPoints,
} from 'store/organization';
import {
  getPoliceDeptFormErrors,
  getPoliceDeptFormValue,
  isPoliceDeptFormTouched,
} from 'store/policeDept';
import { SuperviseeList } from 'routes/PoliceDepartment/SuperviseeList';
import { modalActions } from 'store/modal';
import { getSelectedOrganization, getSelectedUsers } from 'store/organization/';
import {
  ORG_FORM_KEYS,
  REDUX_FORM_CONSTANT,
  STATUS,
  USER_FORM_KEYS,
  WARNING_MESSAGE,
} from 'utils';
import { toast } from 'react-toastify';
import { destroy, submit } from 'redux-form';
import { eventActions } from 'store/event/event.slice';
import { QrCodePreview } from 'routes/Organization/QrCodePreview';
// import { useFlags } from 'launchdarkly-react-client-sdk';
// import { LAUNCH_DARKLY_KEYS } from 'utils/constants/ldKeys';

export const PoliceDepartment = () => {
  const currentRoute = useRouteMatch();
  const dispatch = useDispatch();

  const selectedUser = useSelector(getSelectedUsers);
  const selectedOrganization = useSelector(getSelectedOrganization);
  const isOrgArchieved = !isNull(selectedOrganization?.archived_at);
  const currentRouteBreadCrumb = useBreadCrumb(
    selectedOrganization,
    selectedUser,
  );

  // const flags = useFlags();
  // const qrCodeFeatureFlag: boolean =
  //   flags[LAUNCH_DARKLY_KEYS.REGISTER_USING_QR_CODE];
  const nestedLinks: any = { ...POLICE_ORG_NESTED_LINKS };
  // if (!qrCodeFeatureFlag || isOrgArchieved) {
  if (isOrgArchieved) {
    delete nestedLinks['qrCode'];
  }

  const appActiveRoute = useActiveAppRouteMatch(POLICE_DEPT_ROUTES);
  const activeSubHeaderSection = useUpdatePoliceDeptHeaderVisibility(
    POLICE_DEPT_ROUTES,
    POLICE_DEPT_ACTIVE_SUB_HEADER_SECTION,
  );
  const addOrEditPoliceDeptFormValue = useSelector(getPoliceDeptFormValue);
  const addOrEditUserFormValue = useSelector(getPoliceUserFormValue);
  const addOrEditPoliceDeptFormErrors = useSelector(getPoliceDeptFormErrors);
  const addOrEditPoliceUserFormErrors = useSelector(getPoliceUserFormErrors);
  const isPoliceFormTouched = useSelector(isPoliceDeptFormTouched);
  const isOrgFormTouched = useSelector(isOrganizationDeptFormTouched);
  const commandPosts = useSelector(getCommandPostPoints);
  const geoFence = useSelector(getGeoFencePoints);
  const perimeterPoints = useSelector(getPerimeterPoints);
  const [
    actionButton,
    setActionButton,
  ] = useState<CombinedActionButtonInterface>(POLICE_DEPT_ACTION_BUTTON);

  useEffect(() => {
    // @ts-ignore
    if (_.isNull(selectedOrganization) && currentRoute.params?.id) {
      dispatch(
        organizationActions.fetchParticularOrganizationDetailsRequest({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    const updatedActionButton = updateActionButtonVisibility(
      actionButton,
      appActiveRoute,
      selectedUser,
      selectedOrganization,
    );
    setActionButton(updatedActionButton);
  }, [appActiveRoute?.path, selectedUser, selectedOrganization]);

  const handleActionButtonClick = (actionButtonObj: ActionButtonInterface) => {
    console.log('%c actionButtonObj ', 'background: lime; color: black', {
      actionButtonObj,
    });

    if (actionButtonObj.id === POLICE_DEPT_ACTION_BUTTON.addPoliceDept.id) {
      dispatch(organizationActions.createNewOrganizationButtonClick());
      dispatch(destroy(REDUX_FORM_CONSTANT.POLICE_DEPT));
    }

    if (
      actionButtonObj.id === POLICE_DEPT_ACTION_BUTTON.addOrEditPoliceDept.id
    ) {
      dispatch(submit(REDUX_FORM_CONSTANT.POLICE_DEPT));
      if (!_.isEmpty(addOrEditPoliceDeptFormErrors)) {
        toast.error('Please fill the required fields.');
      } else {
        const updatedFieldValue = getUpdatedFields(
          addOrEditPoliceDeptFormValue,
          selectedOrganization,
        );
        dispatch(
          organizationActions.addOrEditOrganizationsAsyncRequest({
            formValue: addOrEditPoliceDeptFormValue,
            dirtyFields: updatedFieldValue,
            // @ts-ignore
            id: currentRoute.params.id,
          }),
        );
      }
    }

    if (
      actionButtonObj.id === POLICE_DEPT_ACTION_BUTTON.activatePoliceDept.id
    ) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'organization',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.ACTIVATE,
        }),
      );
    }

    if (
      actionButtonObj.id === POLICE_DEPT_ACTION_BUTTON.deActivatePoliceOrg.id
    ) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'organization',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.DEACTIVATE,
        }),
      );
    }

    if (
      actionButtonObj.id ===
      POLICE_DEPT_ACTION_BUTTON.activatePoliceDeptUsers.id
    ) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.userId,
          status: STATUS.UNBLOCK_USER,
        }),
      );
    }

    if (
      actionButtonObj.id ===
      POLICE_DEPT_ACTION_BUTTON.deActivatePoliceDeptUsers.id
    ) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.userId,
          status: STATUS.BLOCK_USER,
        }),
      );
    }

    if (
      actionButtonObj.id === POLICE_DEPT_ACTION_BUTTON.addUserToPoliceDept.id
    ) {
      dispatch(
        organizationActions.createNewOrgUserButtonClick({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    if (actionButtonObj.id === POLICE_DEPT_ACTION_BUTTON.updateUserDetails.id) {
      dispatch(submit(REDUX_FORM_CONSTANT.POLICE_USER));
      if (!_.isEmpty(addOrEditPoliceUserFormErrors)) {
        toast.error('Please fill the required fields.');
      } else
        dispatch(
          organizationActions.addOrEditOrgUserAsyncRequest({
            formValue: addOrEditUserFormValue,
            // @ts-ignore
            orgId: currentRoute.params.id,
            // @ts-ignore
            userId: currentRoute.params.userId,
          }),
        );
    }
    if (actionButtonObj.id === POLICE_DEPT_ACTION_BUTTON.addEditSupervisee.id) {
      dispatch(modalActions.openModal());
    }
    if (actionButtonObj.id === POLICE_DEPT_ACTION_BUTTON.startEvent.id) {
      dispatch(
        eventActions.startEventRequest({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    // if (actionButtonObj.id === POLICE_DEPT_ACTION_BUTTON.updateMapDetails.id) {
    //   if (!commandPosts.length || !perimeterPoints.length || !geoFence.length) {
    //     toast.error(
    //       'Command Points, Perimeter points and Geofence cannot be deleted. Please add a new one.',
    //     );
    //   } else
    //     dispatch(
    //       organizationActions.addOrEditOrganizationsAsyncRequest({
    //         formValue: selectedOrganization,
    //         // @ts-ignore
    //         id: currentRoute.params.id,
    //         isGeoJSONRequest: true,
    //       }),
    //     );
    // }
  };
  const getOrgForm = useSelector(getOrganizationFormValue);
  const [userForm, isUserTouched] = getUserForms();
  window.onbeforeunload = function (event: any) {
    const e = event || window.event;
    const msg = WARNING_MESSAGE;

    if (
      (isPoliceFormTouched &&
        compareFormObjects(
          selectedOrganization,
          addOrEditPoliceDeptFormValue,
          ORG_FORM_KEYS,
        )) ||
      (isOrgFormTouched &&
        compareFormObjects(selectedOrganization, getOrgForm, ORG_FORM_KEYS)) ||
      (isUserTouched &&
        compareFormObjects(selectedUser, userForm, USER_FORM_KEYS))
    ) {
      e.preventDefault();
      if (e) {
        return (e.returnValue = msg);
      }
      return msg;
    }
  };

  return (
    <Fragment>
      <NavigationSection
        breadcrumbData={currentRouteBreadCrumb}
        activeRouteSlug={appActiveRoute?.path}
        subNavigation={nestedLinks}
        // TODO
        // merge section and action button visibility together
        sectionVisibility={activeSubHeaderSection}
        actionButtons={actionButton}
        actionButtonClick={handleActionButtonClick}
      />

      <PageBody>
        {isRouteMatch(POLICE_DEPT_ROUTES.POLICE_DEPT, currentRoute.url) && (
          <OrganizationTable categoryType={AppCategory.POLICE_DEPARTMENT} />
        )}

        {isRouteMatch(
          POLICE_DEPT_ROUTES.POLICE_DEPT_DETAILS,
          currentRoute.url,
        ) && <CreatePoliceDeptForm />}

        {isRouteMatch(
          POLICE_DEPT_ROUTES.POLICE_DEPT_USERS,
          currentRoute.url,
        ) && <OrganizationUsers />}

        {isRouteMatch(
          POLICE_DEPT_ROUTES.POLICE_DEPT_USERS_DETAILS,
          currentRoute.url,
        ) && <UserForm id={REDUX_FORM_CONSTANT.POLICE_USER} />}

        {isRouteMatch(POLICE_DEPT_ROUTES.POLICE_DEPT_ORG, currentRoute.url) && (
          <SuperviseeList />
        )}

        {/* {selectedOrganization?.is_special_event_org &&
          isRouteMatch(
            POLICE_DEPT_ROUTES.POLICE_DEPT_MAP,
            currentRoute.url,
          ) && <RenderMap />} */}

        {/* {isRouteMatch(
          ORGANIZATION_ROUTES.ORGANIZATION_MAP,
          currentRoute.url,
        ) && <MapContainer />} */}

        {isRouteMatch(
          POLICE_DEPT_ROUTES.POLICE_DEPT_QR_CODE,
          currentRoute.url,
        ) &&
          !isOrgArchieved && (
            // qrCodeFeatureFlag &&
            <QrCodePreview />
          )}
      </PageBody>
    </Fragment>
  );
};

export default PoliceDepartment;
