import * as storageService from './module/storage.service';
// TODO: remove
import { AuthInterface } from 'store/login/login.type';
import { AuthTokenInterface } from 'utils/types';

export const saveAuthObject = (authObj: AuthTokenInterface): void => {
  storageService.set('auth', authObj);
};

export const getAuthObject = (): AuthInterface | null => {
  return storageService.get('auth');
};

export const isAuthenticated = (): boolean => {
  const authObj = getAuthObject();

  if (authObj && authObj.token_type) {
    return true;
  }
  return false;
};

export const isAuthorised = (): boolean => {
  const authObj = getAuthObject();

  if (authObj && authObj.token_type) {
    return true;
  }
  return false;
};

export const getAuthToken = (): string | undefined => {
  if (isAuthenticated()) {
    return getAuthObject()?.access_token;
  }
  return undefined;
};

export const getRefreshToken = (): string | undefined => {
  if (isAuthenticated()) {
    return getAuthObject()?.refresh_token;
  }
  return undefined;
};

export const logout = (): void => {
  storageService.clear();
};

export const getAuthScope = (): string | undefined => {
  if (isAuthenticated()) {
    return getAuthObject()?.scope;
  }
  return undefined;
};
