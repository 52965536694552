import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useActiveAppRouteMatch } from 'utils/hooks';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppRouteInterface } from 'routes/path';
import { getHeaderSectionVisibility } from 'routes/Ems/utils/helper';
import {
  EMS_ACTIVE_SUB_HEADER_SECTION,
  EMS_FORM_FIELDS,
} from 'routes/Ems/utils/constants';
import { getPoliceDeptFormValue } from 'store/policeDept';
import { getSelectedOrganization } from 'store/organization/';
import { FormField } from 'utils/types';

type VisibilityObjType = typeof EMS_ACTIVE_SUB_HEADER_SECTION;

export const useUpdatePoliceDeptHeaderVisibility = (
  appRoutesObj: AppRouteInterface,
  defaultVisibilityObj: VisibilityObjType,
): VisibilityObjType => {
  const activeRoute = useActiveAppRouteMatch(appRoutesObj);
  const currentRoute = useRouteMatch();
  const [activeSubHeaderSection, setSubHeaderSection] = useState(
    defaultVisibilityObj,
  );

  useEffect(() => {
    const subHeaderSectionVisibility = getHeaderSectionVisibility(activeRoute);
    setSubHeaderSection(subHeaderSectionVisibility);
    return () => {
      setSubHeaderSection(defaultVisibilityObj);
    };
  }, [currentRoute.url, activeRoute?.path]);

  return activeSubHeaderSection;
};

export const useInitializeForm = () => {
  const [formFieldsArr, setFormFieldArr] = useState<FormField[]>([]);
  const formValue = useSelector(getPoliceDeptFormValue);
  const initialValue = useSelector(getSelectedOrganization);
  useEffect(() => {
    const fieldArr = _.cloneDeep(EMS_FORM_FIELDS);
    setFormFieldArr(fieldArr);
    console.log('%c fieldArr 9999', 'background: lime; color: black', {
      fieldArr,
    });
    if (formValue && formValue.state && formValue.country) {
    }
  }, []);

  console.log('%c fieldArr ', 'background: lime; color: black', {
    formFieldsArr,
  });
  return [formFieldsArr, initialValue, formValue];
};
