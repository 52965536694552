import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import { Header } from '../components/Header';
import { appActions } from 'store/app';
import { LoadScript } from '@react-google-maps/api';
import { isOpsCenter, isSEManager } from 'utils/hooks';
import { eventActions } from 'store/event/event.slice';
import { videoCallActions } from 'store/videoCall';
import { isAuthorised } from 'services/localStorage.service';
import { GOOGLE_MAP } from 'config';

interface LayoutProps {
  children: React.ReactNode;
}

const Container = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100vh',
  // backgroundColor: 'black',
  flex: 1,
  flexDirection: 'column',
});

export const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const dispatch = useDispatch();
  const opsCenter = isOpsCenter();
  const seManager = isSEManager();

  useEffect(() => {
    if (opsCenter || seManager) {
      // Todo:  Since SE MANAGER gets organization dashboard instead
      //        event, we might need to create a separate if block for
      //        seManager
      dispatch(eventActions.requestActiveEvent(null));

      // Fetching config for SE MANAGER too since they need it to
      // edit user's person type.
      dispatch(appActions.configAsyncRequest());

      // dispatch(videoCallActions.fetchActiveVideoCall());
    } else if (isAuthorised()) dispatch(appActions.configAsyncRequest());
  }, []);

  return (
    <LoadScript
      id="script-loader"
      // googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP || ''}
      googleMapsApiKey={GOOGLE_MAP}
      libraries={['geometry']}
      language="en"
    >
      <Container>
        <Header />
        {children}
      </Container>
    </LoadScript>
  );
};
