import React from 'react';
import { useDispatch } from 'react-redux';
import { videoCallActions, VideoCallData } from 'store/videoCall';
import { CallRounded, CallEndRounded } from '@material-ui/icons';
import { useVideoCallContainerStyle } from './styles';

interface IncomingVideoCallProps {
  videoCallId: string;
  closeToast?: () => void;
  closeButtonCallback: () => void;
}

const IncomingVideoCallClose = (props: IncomingVideoCallProps) => {
  const dispatch = useDispatch();

  const classes = useVideoCallContainerStyle();

  return (
    <div className={classes.incomingVideoCallCloseContainer}>
      <div style={{ marginRight: '20px' }}>
        <CallRounded
          color="primary"
          fontSize="large"
          onClick={() => {
            dispatch(videoCallActions.acceptVideoCall(props.videoCallId));
            props.closeButtonCallback();
            if (props.closeToast) props.closeToast();
          }}
        />
      </div>
      <div>
        <CallEndRounded
          color="error"
          fontSize="large"
          onClick={() => {
            dispatch(videoCallActions.rejectVideoCall(props.videoCallId));
            props.closeButtonCallback();
            if (props.closeToast) props.closeToast();
          }}
        />
      </div>
    </div>
  );
};

interface IncomingVideoCallNameProps {
  callerName: string;
}

export const IncomingVideoCallName = (props: IncomingVideoCallNameProps) => {
  const classes = useVideoCallContainerStyle();

  return (
    <div
      className={classes.incomingVideoCallNameContainer}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10px',
        marginRight: '20px',
      }}
    >
      <div>{props.callerName}</div>
      <small>Calling...</small>
    </div>
  );
};

export default IncomingVideoCallClose;
