import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedOrganization } from 'store/organization';
import * as GoogleMapApis from 'services/geoCoding.service';
import { OrgInterface } from 'utils/types/org';
import { getFullAddress } from 'routes/Organization/utils/helper';
import _ from 'lodash';

type MetaDataType = {
  mapInstance: any;
};

export const useDefaultGeoCodes = (defaultValue = { lat: 0, lng: 0 }) => {
  const [defaultGeoCodes, setDefaultGeoCodes] = useState(defaultValue);
  const currentOrg = useSelector(getSelectedOrganization);
  const address = getFullAddress(currentOrg);

  useEffect(() => {
    try {
      if (address) {
        GoogleMapApis.getGeoCoordinates(address)
          .then((geoCodes) => {
            console.log('%c geoCodes ', 'background: lime; color: black', {
              geoCodes,
            });
            setDefaultGeoCodes(geoCodes);
          })
          .catch((err) => {
            console.error(
              '%c useDefaultGeoCodes: error inside the geo codes ',
              'background: salmon; color: black',
              { err },
            );
          });
      }
    } catch (err) {
      console.error(
        '%c useDefaultGeoCodes: error inside the geo codes ',
        'background: salmon; color: black',
        { err },
      );
    }
  }, [address]);

  return [defaultGeoCodes, setDefaultGeoCodes];
};
