import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from 'styles/colorConstants';

export const CONTAINER_MAX_WIDTH = '600px';
export const CONTAINER_MAX_HEIGHT = '850px';

export const useOrganisationStyles = makeStyles((theme: Theme) => {
  return createStyles({
    pdfContainer: {
      // maxWidth: CONTAINER_MAX_WIDTH,
      // maxHeight: CONTAINER_MAX_HEIGHT,
      margin: 'auto',
      backgroundColor: COLORS.WHITE,
    },
    previewContainer: {
      maxWidth: CONTAINER_MAX_WIDTH,
      margin: 'auto',
    },
    logo: {
      marginLeft: '8px',
    },
    fieldContainer: {
      margin: `${theme.spacing(1)}px 0`,
      width: '100%',
    },
    orgTextCtn: {
      color: COLORS.TEXT_BLACK,
      textAlign: 'center',
      width: '100%',
      marginBottom: '5px',
    },
    orgPreviewHeaderCtn: {
      paddingTop: '15px',
      paddingBottom: '30px',
      color: COLORS.LIGHT_GREY,
      textAlign: 'center',
      width: '100%',
    },
    bottomTextCtn: {
      color: COLORS.TEXT_BLACK,
      textAlign: 'center',
      width: '100%',
      marginTop: '15px',
      marginBottom: '5px',
    },
    downloadBtnCtn: {
      textAlign: 'center',
      width: '100%',
      marginBottom: '10px',
      marginTop: '40px',
    },
    downloadBtnSize: {
      color: COLORS.WHITE,
      padding: '15px 50px',
      borderRadius: '4px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
    },
    downloadBtnColor: {
      textTransform: 'none',
      backgroundColor: COLORS.PERSIAN_GREEN,
      boxShadow: `0px 4px 0px ${COLORS.PERSIAN_GREEN_SHADOW}`,
      '&:hover': {
        backgroundColor: COLORS.PERSIAN_GREEN,
        color: COLORS.WHITE,
      },
    },
    orgText: {
      fontSize: '24px',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '39px',
    },
    orgPreviewHeaderText: {
      fontSize: '24px',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '35px',
    },
    bottomText: {
      color: COLORS.TEXT_BLACK,
      textAlign: 'center',
      width: '100%',
      fontSize: '17px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
    },
    logoText: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 'lighter',
      color: COLORS.TEXT_BLACK,
    },
    logoTextCtn: {
      width: '100px',
      color: COLORS.WHITE,
      paddingLeft: '4px',
    },
    qrContainerPdf: {
      width: '100%',
      alignItems: 'center',
      display: 'flex',
    },
    qrContainer: {
      width: '100%',
      alignItems: 'center',
      display: 'flex',
    },
    logoContainer: {
      marginLeft: '2px',
      marginTop: '5px',
      marginBottom: '15px',
      height: '13%',
      width: '35%',
    },
    bodyContainer: {
      width: '100%',
      height: '100%',
    },
    buttonCtn: {
      textAlign: 'center',
    },
    actionText: {
      color: COLORS.BLACK,
      fontSize: '12px',
      fontFamily: 'Poppins',
    },
    actionBtns: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginTop: '40px',
      marginBottom: '8px',
    },
    bgColor: {
      backgroundColor: COLORS.WHITE,
    },
  });
});
