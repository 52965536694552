import { VAPID } from 'config';
import { messaging } from 'config/firebaseConfig';
import { getToken } from 'firebase/messaging';

export const getStartToken = () => {
  return messaging
    ? getToken(messaging, { vapidKey: VAPID })
        .then((currentToken) => {
          if (currentToken) {
            console.log('Current token for client: ', currentToken);
            return currentToken;
          } else {
            Notification.requestPermission();
            return;
          }
        })
        .catch((err) => {
          console.log('Permission status is', Notification.permission);
          console.log('An error occurred while retrieving token. ', err);
          return;
        })
    : null;
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });
