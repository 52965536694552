import React, { useState, useEffect } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { organizationActions } from 'store/organization';
import Text from 'components/General/Form/Text';
import ComboBox from 'components/General/Form/ComboBox';
// import ComboBox from 'components/General/Form/ComboBox';
import _ from 'lodash';

import {
  getSelectedUsers,
  getOrganizationRoles,
} from 'store/organization/organization.selectors';

import {
  changeToLowerCase,
  isCreateAction,
  isEmailValid,
  isRequired,
  isValidBadgeId,
  isValidPhoneNumber,
} from 'utils/helper';
import { FormContainer } from '../../components/Form';
import { REDUX_FORM_CONSTANT } from 'utils/constants';
import { currentOrgPersonTypes } from 'store';
import { validateOrgForm } from './helper';

const FORM_FIELDS_ARRAY = [
  {
    name: 'first_name',
    type: 'text',
    component: Text,
    label: 'First Name',
    placeHolder: 'Enter First Name',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'last_name',
    type: 'text',
    component: Text,
    label: 'Last Name',
    placeHolder: 'Enter Last Name',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'role',
    type: 'text',
    component: ComboBox,
    id: 'role',
    label: 'Role',
    displayValuePath: 'name',
    placeHolder: 'Enter Role',
    validate: [isRequired],
    required: true,
    options: [],
  },
  {
    name: 'username',
    type: 'text',
    component: Text,
    label: 'Email',
    placeHolder: 'Enter Email',
    validate: [isRequired, isEmailValid],
    normalize: changeToLowerCase,
    required: true,
  },
  {
    name: 'phone',
    type: 'tel',
    component: Text,
    label: 'Phone',
    placeHolder: 'Enter Phone',
    required: true,
    validate: [isValidPhoneNumber],
  },
  {
    name: 'person_type',
    type: 'text',
    component: ComboBox,
    id: 'person-type',
    label: 'Person Type',
    displayValuePath: 'name',
    placeHolder: 'Enter Person Type',
    validate: [isRequired],
    required: true,
    disable: false,
    options: [],
  },
  {
    name: 'batch_number',
    type: 'number',
    component: Text,
    label: 'Badge Number',
    placeHolder: 'Enter Badge Id',
    validate: [isValidBadgeId],
    required: true,
  },
];

const UserFormController = (props: InjectedFormProps) => {
  const roles = useSelector(getOrganizationRoles);
  const personTypes: any = useSelector(currentOrgPersonTypes);
  const [formFields, setFormFields] = useState(_.cloneDeep(FORM_FIELDS_ARRAY));

  useEffect(() => {
    const roleObjIndex = _.findIndex(
      formFields,
      (obj: typeof FORM_FIELDS_ARRAY[0]) => obj.name === 'role',
    );
    if (roleObjIndex !== -1) {
      formFields[roleObjIndex].options = _.cloneDeep(roles);
    } else {
      formFields[roleObjIndex].options = [];
    }
    if (props.form === REDUX_FORM_CONSTANT.ORG_USER) {
      const badgeIdIndex = _.findIndex(
        formFields,
        (obj: typeof FORM_FIELDS_ARRAY[0]) => obj.name === 'batch_number',
      );
      if (badgeIdIndex !== -1) {
        formFields.splice(badgeIdIndex, 1);
      }
    }

    const personTypeIndex = _.findIndex(
      formFields,
      (obj: typeof FORM_FIELDS_ARRAY[0]) => obj.name === 'person_type',
    );
    if (personTypeIndex !== -1) {
      formFields[personTypeIndex].options = _.cloneDeep(personTypes);
    } else {
      formFields[personTypeIndex].options = [];
    }

    setFormFields(formFields);
  }, [roles, personTypes]);

  return <FormContainer fieldArr={formFields} />;
};

const UserRFWrapper = reduxForm({
  form: REDUX_FORM_CONSTANT.ORG_USER,
  onSubmit: validateOrgForm,
})(UserFormController);

interface UserFormProps {
  id: string;
}

export const UserForm: React.FC<UserFormProps> = ({ id }: UserFormProps) => {
  const selectedUser = useSelector(getSelectedUsers);
  const currentRoute = useRouteMatch();
  const currentRoles = useSelector(getOrganizationRoles);
  const dispatch = useDispatch();

  useEffect(() => {
    // @ts-ignore
    const { id, userId } = currentRoute.params;
    if (!isCreateAction(userId)) {
      dispatch(
        organizationActions.fetchUserDetailsAsyncRequest({
          userId: userId,
        }),
      );
    }

    if (currentRoles.length === 0) {
      dispatch(
        organizationActions.organizationRolesAsyncRequest({ orgId: id }),
      );
    }
  }, []);

  return (
    <>
      <UserRFWrapper form={id} initialValues={selectedUser} />
      {/* <FormSaveConfirmationModal
        handleLeave={handleLeave}
        handleStay={handleStay}
        open={openModal}
      /> */}
    </>
  );
};

export default UserForm;
