import React, { useEffect } from 'react';
import _, { isNil } from 'lodash';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
import { useRouteMatch } from 'react-router';
import { useFetchOrgDetails } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { FormField } from 'utils/types';
import { FormContainer } from 'components/Form';
import {
  checkAndUpdateProvinceOptions,
  updateProvinceOptions,
} from 'routes/Ems/utils/helper';
import { useInitializeForm } from 'routes/Ems/utils/hook';
import { REDUX_FORM_CONSTANT } from 'utils/constants';
import { validateOrgForm } from 'routes/Organization/helper';
import { getPoliceDeptFormValue } from 'store';

type FormWrapperProps = { fieldArr: FormField[] };

const PoliceDeptFormWrapper = (
  props: FormWrapperProps & InjectedFormProps<unknown, FormWrapperProps>,
) => {
  return <FormContainer fieldArr={props.fieldArr} />;
};

const PoliceDeptRFWrapper = reduxForm<unknown, FormWrapperProps>({
  form: REDUX_FORM_CONSTANT.POLICE_DEPT,
  onSubmit: validateOrgForm,
  destroyOnUnmount: false,
})(PoliceDeptFormWrapper);

export const CreatePoliceDeptForm: React.FC = () => {
  const [fieldArr, initialFormValue] = useInitializeForm();
  const dispatch = useDispatch();
  const formValue = useSelector(getPoliceDeptFormValue);
  const currentRoute = useRouteMatch();
  const orgId = _.get(currentRoute, 'params.id', '');

  if (orgId) {
    useFetchOrgDetails(orgId);
  }

  useEffect(() => {
    const countryCode = _.get(formValue, 'country.name', null);
    if (!isNil(countryCode) && fieldArr.length !== 0) {
      updateProvinceOptions(fieldArr, countryCode);
    }
  }, [fieldArr]);

  const handleChange = (formValue: any, ...arg: any) => {
    const previousFormValue = arg.pop();
    const isValueUpdated = checkAndUpdateProvinceOptions(
      formValue,
      previousFormValue,
      fieldArr,
    );

    const stateVal = _.get(formValue, 'state.name', '');

    const stateOptions = fieldArr.filter((obj: any) => obj.name === 'state')[0]
      .options;

    const options = stateOptions?.map((obj: any) => obj.name) || [];

    if (isValueUpdated && _.indexOf(options, stateVal) === -1) {
      dispatch(change(REDUX_FORM_CONSTANT.POLICE_DEPT, 'state', null));
    }

    console.log(
      '%c fieldArr  after',
      'background: black; color: yellow',
      fieldArr,
    );
  };

  return (
    <PoliceDeptRFWrapper
      fieldArr={fieldArr}
      initialValues={initialFormValue}
      onChange={handleChange}
    />
  );
};
