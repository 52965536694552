import {
  Container,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useOrganisationStyles } from './styles';
import { APP_ASSETS } from 'utils/asset';
import { COLORS } from 'styles/colorConstants';
import { QRCodeSVG } from 'qrcode.react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _, { isNull } from 'lodash';
import html2canvas from 'html2canvas';
import {
  getSelectedOrganization,
  getOrganizationRoles,
  OrganizationRolesInterface,
  organizationActions,
} from 'store/organization';
import { OrganizationTypeInterface } from 'models/organization';
import { isSpecialEventRoute } from 'utils/helper';
import { jsPDF } from 'jspdf';
import { CustomButton } from 'components/General/CustomButton';
import { ORG_CONSTANTS } from './utils/constants';

// QR Code value data interface
interface QrCodeDataInterface {
  org_id: string;
  url: string;
  roles: OrganizationRolesInterface[];
}

const filterRoles = (
  roles: OrganizationRolesInterface[],
): OrganizationRolesInterface[] => {
  //filtering out Admin & Commander roles from QR code value
  const filteredRoles: OrganizationRolesInterface[] = roles.filter(
    (value) =>
      !value.logical_name.includes(ORG_CONSTANTS.managerCapitals) &&
      !value.logical_name.includes(ORG_CONSTANTS.commanderCapitals),
  );
  return filteredRoles;
};

export const QrCodePreview: React.FC = () => {
  const classes = useOrganisationStyles();
  const currentRoute = useRouteMatch();
  const dispatch = useDispatch();

  const selectedOrganization:
    | OrganizationTypeInterface
    | null
    | undefined = useSelector(getSelectedOrganization);

  const orgId = _.get(currentRoute, 'params.id', '');
  const orgName = selectedOrganization?.name;
  const isOrgArchieved = !isNull(selectedOrganization?.archived_at);
  const orgRoles: OrganizationRolesInterface[] = useSelector(
    getOrganizationRoles,
  );
  const filteredRoles = filterRoles(orgRoles);

  const isSpecialEvent = isSpecialEventRoute(currentRoute.path);
  const exportRef: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null,
  );
  const [openDownloadDialog, setDownloadDialog] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (currentRoute.params?.id && _.isEmpty(orgRoles)) {
      dispatch(
        organizationActions.fetchParticularOrganizationDetailsRequest({
          // @ts-ignore
          orgId: currentRoute.params?.id,
        }),
      );
      dispatch(
        organizationActions.organizationRolesAsyncRequest({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
  }, [orgId, orgRoles]);

  const canShowQrCode =
    !_.isEmpty(orgId) && !isOrgArchieved && filteredRoles.length > 0 && orgName;
  let qrCodeDataObject = {};
  if (canShowQrCode) {
    qrCodeDataObject = {
      org_id: orgId,
      url: ORG_CONSTANTS.qrCodeUrlWithParam + orgId,
      roles: filteredRoles,
    } as QrCodeDataInterface;
  }

  // download QR code
  const downloadQRCode = async (element: HTMLInputElement | null) => {
    // process : (html -> canvas -> png image -> pdf)
    if (element) {
      const canvas = await html2canvas(element);
      const qrCodeURL = canvas
        .toDataURL('image/png', 1.0)
        .replace('image/png', 'image/octet-stream');
      const pdf = new jsPDF();
      // pdf.addImage(qrCodeURL, 'PNG', 50, 50, 0, 0);
      const imageProps = pdf.getImageProperties(qrCodeURL);
      pdf.addImage(
        qrCodeURL,
        50,
        50,
        0.35 * imageProps.width,
        0.35 * imageProps.height,
      );
      pdf.save(
        `${orgName?.replaceAll(' ', '_')}${ORG_CONSTANTS.pdfNameSuffix}`,
      );
    }
  };

  const DownloadPdfHtmlContent = () => {
    //QR Code PDF UI used in dialog and then for download as pdf
    return (
      <Container maxWidth="sm">
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          alignContent="flex-start"
          className={classes.pdfContainer}
          id={ORG_CONSTANTS.qrPdfId}
          ref={exportRef}
        >
          <div className={classes.logoContainer}>
            <img
              src={APP_ASSETS.SECONDARY_APP_LOGO}
              alt="Logo"
              style={{
                backgroundColor: COLORS.WHITE,
              }}
            />
          </div>
          <div className={classes.orgTextCtn} id={ORG_CONSTANTS.orgNamePdfId}>
            <Typography className={classes.orgText}>{orgName!}</Typography>
          </div>
          <QRCodeSVG
            id={ORG_CONSTANTS.qrCodePdfId}
            className={classes.qrContainerPdf}
            level="L"
            size={250}
            includeMargin={true}
            value={JSON.stringify(qrCodeDataObject)}
          />
          <div
            className={classes.bottomTextCtn}
            id={ORG_CONSTANTS.bottomTitlePdfId}
          >
            <Typography className={classes.bottomText}>
              {ORG_CONSTANTS.uiScanToRegister +
                (isSpecialEvent
                  ? ORG_CONSTANTS.event
                  : // : ORG_CONSTANTS.organisation
                    ORG_CONSTANTS.org)}
            </Typography>
          </div>
        </Grid>
      </Container>
    );
  };

  // return !_.isEmpty(orgId) && filteredRoles.length > 0 && orgName ? (
  return canShowQrCode ? (
    <Container>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        className={classes.previewContainer}
        id={ORG_CONSTANTS.qrPreviewId}
      >
        <div
          className={classes.orgPreviewHeaderCtn}
          id={ORG_CONSTANTS.orgNameId}
        >
          <Typography className={classes.orgPreviewHeaderText}>
            {orgName! + ORG_CONSTANTS.uiRegisterQrCode}
          </Typography>
        </div>
        <QRCodeSVG
          id={ORG_CONSTANTS.qrCodePreviewId}
          className={classes.qrContainer}
          level="L"
          size={300}
          includeMargin={true}
          value={JSON.stringify(qrCodeDataObject)}
        />
        <div
          className={classes.downloadBtnCtn}
          id={ORG_CONSTANTS.bottomTitleId}
        >
          <Button
            onClick={() => {
              setDownloadDialog(true);
            }}
            type="submit"
            className={`${classes.downloadBtnSize} ${classes.downloadBtnColor}`}
          >
            {ORG_CONSTANTS.downloadPdf}
          </Button>
        </div>
      </Grid>
      <Dialog
        PaperProps={{
          className: classes.bgColor,
        }}
        open={openDownloadDialog}
      >
        <DialogContent className={classes.actionText}>
          <DownloadPdfHtmlContent />
        </DialogContent>
        <DialogActions className={classes.actionBtns}>
          <CustomButton
            onClick={() => {
              setDownloadDialog(false);
            }}
            error={true}
            label={ORG_CONSTANTS.cancel}
          />
          <CustomButton
            onClick={() => {
              downloadQRCode(exportRef.current).then(() => {
                setDownloadDialog(false);
              });
            }}
            error={false}
            label={ORG_CONSTANTS.download}
          />
        </DialogActions>
      </Dialog>
    </Container>
  ) : (
    <div></div>
  );
};
