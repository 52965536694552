import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { isNilOrEmpty, isCreateAction } from 'utils/helper';
import { POLICE_DEPT_ROUTES } from 'routes/path';
import {
  getOrganizationUsers,
  getSelectedOrganization,
} from 'store/organization/organization.selectors';
import { ORGANIZATION_USER_LIST_TABLE_COLUMN } from 'utils/constants';
import {
  OrganizationUserTypeInterface,
  organizationActions,
} from 'store/organization';
import Table from 'components/General/Table';
import { replaceKeyWithValues } from 'routes/Organization/helper';

type UserListProps = {
  columnData: typeof ORGANIZATION_USER_LIST_TABLE_COLUMN[0][];
  rowData: any[] | undefined;
  onClick: (obj: any) => void;
};

export const UserList: React.FC<UserListProps> = (props: UserListProps) => {
  const currentRoute = useRouteMatch();
  const dispatch = useDispatch();
  // @ts-ignore
  const orgId = currentRoute.params?.id;
  const { columnData, rowData, onClick } = props;
  useEffect(() => {
    if (!isCreateAction(orgId) && !isNilOrEmpty(orgId)) {
      dispatch(
        organizationActions.fetchOrgSpecificData({
          orgId,
          showLoader: true,
        }),
      );
    }
  }, []);

  return (
    <div>
      {' '}
      <Table
        columnData={columnData}
        rowData={rowData}
        onRowClick={(rowObj) => {
          onClick(rowObj);
        }}
      />{' '}
    </div>
  );
};

export const OrganizationUsers = () => {
  const organizationUser = useSelector(getOrganizationUsers);
  const selectedOrg = useSelector(getSelectedOrganization);
  const currentRoute = useRouteMatch();
  const dispatch = useDispatch();
  // @ts-ignore
  const orgId = currentRoute.params?.id;

  useEffect(() => {
    if (_.isEmpty(selectedOrg)) {
      dispatch(
        organizationActions.fetchParticularOrganizationDetailsRequest({
          orgId: orgId,
        }),
      );
    }
  }, [selectedOrg]);
  const handleRowClick = (rowObj: OrganizationUserTypeInterface) => {
    const organizationId = _.get(currentRoute.params, 'id', '');
    const slug = POLICE_DEPT_ROUTES.POLICE_DEPT_USERS_DETAILS.path;
    const replaceWith = {
      id: organizationId,
      userId: rowObj.id,
    };
    let url = replaceKeyWithValues(slug, replaceWith);
    console.log('%c url ', 'background: lime; color: black', url);
    dispatch(organizationActions.userRowClick(null));

    if (_.isArray(url)) {
      url = url[1];
    }
    dispatch(push(url));
  };
  return (
    <UserList
      columnData={ORGANIZATION_USER_LIST_TABLE_COLUMN}
      rowData={organizationUser.data}
      onClick={handleRowClick}
    />
  );
};

export default OrganizationUsers;
