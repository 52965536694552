import React, { useEffect, useState } from 'react';
import Table from 'components/General/Table';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganizationData,
  getOrgSupervisee,
  getSelectedOrganization,
  getPoliceDeptFormValue,
  isPoliceDeptFormTouched,
} from '../../store';

import {
  ORGANIZATION_LIST_TABLE_COLUMN,
  ORG_FORM_KEYS,
  REDUX_FORM_CONSTANT,
  SCOPES,
} from 'utils/constants';
import { organizationActions } from 'store/organization';
import { OrganizationTypeInterface } from 'models/organization';
import { checkCategoryFetchStatus } from 'store/app';
import { AppCategory } from 'utils/commonTypes';
import { destroy } from 'redux-form';

import { FormSaveConfirmationModal } from 'components/Form/FormModal';
import { compareFormObjects } from 'utils';
import { haveValidScope, useModal } from 'utils/hooks';

interface OrganizationTableProps {
  categoryType: AppCategory;
  isSupervisee?: boolean;
}

export const OrganizationList: React.FC<OrganizationTableProps> = (
  props: OrganizationTableProps,
) => {
  const organizationList = useSelector(getOrganizationData);
  const superviseeList = useSelector(getOrgSupervisee);
  const isCategoryFetched = useSelector(checkCategoryFetchStatus);
  const dispatch = useDispatch();
  const [link, setLink] = useState<any>('');
  const { categoryType, isSupervisee } = props;
  const getPoliceForm = useSelector(getPoliceDeptFormValue);
  const isPoliceFormTouched = useSelector(isPoliceDeptFormTouched);

  const currentOrg = useSelector(getSelectedOrganization);
  const [open, openModal, closeModal] = useModal();

  // used to differ between cera_admin & ems/police manager here
  const canReadAllOrgs = haveValidScope(SCOPES.READ_ALL_ORG);

  const destroyForm = (val: any) => {
    dispatch(destroy(REDUX_FORM_CONSTANT.ORGANIZATION));
    dispatch(destroy(REDUX_FORM_CONSTANT.POLICE_DEPT));
    dispatch(
      organizationActions.OrganizationTableClick({
        data: val,
        categoryType,
      }),
    );
  };

  const handleOrganizationRowClick = (rowObj: OrganizationTypeInterface) => {
    setLink(rowObj);
    if (
      isSupervisee &&
      isPoliceFormTouched &&
      compareFormObjects(currentOrg, getPoliceForm, ORG_FORM_KEYS)
    ) {
      openModal();
    } else {
      dispatch(organizationActions.setCurrentOrgCoordinates(null));
      dispatch(
        organizationActions.updateGeoJSONData({
          cpPoints: [],
          ppPoints: [],
          geoFence: [],
          orgPps: [],
        }),
      );
      destroyForm(rowObj);
    }
  };

  const handleStay = () => {
    closeModal();
  };
  const handleLeave = () => {
    closeModal();
    destroyForm(link);
  };

  useEffect(() => {
    if (isCategoryFetched) {
      if (isSupervisee) {
        dispatch(
          organizationActions.fetchOrgSuperviseeDataRequest({ categoryType }),
        );
      } else {
        dispatch(
          organizationActions.fetchOrganizationDataRequest({ categoryType }),
        );
      }
    }
  }, [categoryType, isCategoryFetched]);

  return (
    <>
      <Table
        columnData={ORGANIZATION_LIST_TABLE_COLUMN}
        rowData={isSupervisee ? superviseeList?.data : organizationList.data}
        onRowClick={canReadAllOrgs ? handleOrganizationRowClick : undefined}
      />
      <FormSaveConfirmationModal
        handleLeave={handleLeave}
        handleStay={handleStay}
        open={open}
      />
    </>
  );
};

export default OrganizationList;
