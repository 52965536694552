import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  PayloadAction,
} from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { history } from 'utils';
import storage from 'redux-persist/lib/storage';
import { middleware, runSagas } from './middleware';
// import counter from './counter';
import { getPersistConfig } from 'redux-deep-persist';
import theme from './theme';
import organization from './organization';
import login from './login';
import app from './app';
import policeDept from './policeDept';
import modal from './modal';
import users from './users';
import event from './event';
import webSocket from './websocket';
import videoCall from './videoCall';
import eventUser from './eventUser';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from 'redux-persist';

export * from './theme';
// export * from './counter';
export * from './login';
export * from './organization';
export * from './app';
export * from './policeDept';
export * from './route';
export * from './modal';
export * from './users/';
export * from './event';

const combineReducer = combineReducers({
  router: connectRouter(history),
  theme,
  // counter,
  organization,
  form: formReducer,
  login,
  app,
  policeDept,
  modal,
  users,
  event,
  webSocket,
  videoCall,
  eventUser,
});

const persistConfig = getPersistConfig({
  key: 'root',
  storage,
  whitelist: ['event.selectedFilter'],
  rootReducer: combineReducer,
});

const persistedReducer = persistReducer(persistConfig, combineReducer);

const rootReducer = (state: any, action: PayloadAction) => {
  if (action.type === 'login/logoutUser') {
    state = undefined;
  }
  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(middleware),
});

export const persistor = persistStore(store);

runSagas();

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
