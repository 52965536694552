import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoBox, Marker, Polygon } from '@react-google-maps/api';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMapContainerStyle } from './style';

import {
  getActiveEvent,
  getInjuredProfiles,
  getSuspectProfiles,
} from 'store/event/event.selector';
import {
  GeoJSONType,
  getTimeFormatted,
  gnAlphabeticalSeq,
  InnerPerimeterPoint,
  MarkerObj,
  PersonDescriptionInterface,
  PositionInterface,
  Profile,
} from 'utils';
import OccupiedPp from 'assets/customized/occupied-pp.svg';
import CPIcon from 'assets/customized/command-post.svg';
import EMSCPIcon from 'assets/customized/house-medical.svg';
import GreyInjuryIcon from 'assets/grey-injury-marker.svg';
import SuspectIcon from 'assets/orange-injury-marker.svg';
import RedInjuryIcon from 'assets/red-injury-marker.svg';
import GreenInjuryIcon from 'assets/green-injury-marker.svg';
import YellowInjuryIcon from 'assets/yellow-injury-marker.svg';
import PerimeterPointIcon from 'assets/customized/perimeter-point.svg';
import {
  OverRideEventInterface,
  OverRideInnerPPInterface,
} from 'models/event/event.model';
import { COLORS } from 'styles/colorConstants';
import { EventUser } from 'store/eventUser/eventUser.slice';
import { getEventUsers } from 'store/eventUser/eventUser.selector';
import PersonTypeMapIcon from 'components/PersonTypeMapIcon';
import { overrideInnerPPPolySort } from 'utils/location';
import { isEmsCommander } from 'utils/hooks';
import { getEventMapCenter, getEventMapZoom, organizationActions } from 'store';

const CreatedAtComponent = (props: {
  personDescObj: PersonDescriptionInterface;
  index: number;
  type: 'I' | 'T';
  severity: 'RED' | 'YELLOW' | 'GREEN' | null;
  size: number;
}) => {
  const classes = useMapContainerStyle();
  const [isOpen, setOpen] = useState(false);
  const { personDescObj, index, type, severity, size } = props;
  const coords = personDescObj.report['location'].coordinates;
  const getIcon = (severity: string | null) => {
    switch (severity) {
      case 'RED':
        return RedInjuryIcon;
      case 'YELLOW':
        return YellowInjuryIcon;
      case 'GREEN':
        return GreenInjuryIcon;
      default:
        if (type === 'I') return GreyInjuryIcon;
        else return SuspectIcon;
    }
  };

  return (
    <Marker
      position={{
        lat: coords[1],
        lng: coords[0],
      }}
      key={personDescObj.id}
      icon={{
        url: getIcon(severity),
        scaledSize: new google.maps.Size(size, size),
      }}
      onClick={() => {
        setOpen((prev) => !prev);
      }}
      draggable={false}
      label={{
        text: `${type}${index + 1}`,
        fontWeight: '700',
        fontFamily: 'Poppins',
        color: COLORS.WHITE,
      }}
    >
      {isOpen && (
        <InfoBox
          position={{
            lat: coords[1],
            lng: coords[0],
          }}
          options={{
            boxStyle: {
              backgroundColor: COLORS.WHITE,
            },
            closeBoxURL: '',
          }}
        >
          <div className={classes.createdCtn}>
            <span className={classes.createdText}>
              {getTimeFormatted(personDescObj.created_at)}
            </span>
          </div>
        </InfoBox>
      )}
    </Marker>
  );
};

export function MapContainer() {
  const isEms = isEmsCommander();
  const classes = useMapContainerStyle();
  const dispatch = useDispatch();
  const activeEvent = useSelector(getActiveEvent);
  const eventUserObj: Record<string, EventUser> = useSelector(getEventUsers);
  const suspectProfileList = useSelector(getSuspectProfiles);
  const injuredProfileList = useSelector(getInjuredProfiles);
  const [markerSize, setMarkerSize] = useState(30);
  const eventMapZoom: number | null = useSelector(getEventMapZoom);
  const eventMapCenter: PositionInterface | null = useSelector(
    getEventMapCenter,
  );

  const googleMapRef: any = useRef(null);
  const eventUsers = Object.values(eventUserObj);

  const handleMapLoad = (googleMapInstance: google.maps.Map) => {
    googleMapRef.current = googleMapInstance;
  };

  const handleCenterChange = () => {
    if (googleMapRef.current) {
      const latNum: number = googleMapRef.current.getCenter().lat();
      const lngNum: number = googleMapRef.current.getCenter().lng();
      const center = googleMapRef.current.getCenter().toJSON();
      //to store the map center values in redux
      //store only when map center value changes
      if (
        center &&
        (eventMapCenter?.lat != latNum || eventMapCenter?.lng != lngNum)
      ) {
        dispatch(organizationActions.updateEventMapCenter(center));
      }
    }
  };

  const handleZoomChange = () => {
    if (googleMapRef.current) {
      const pixelSizeAtZoom0 = 20; //the size of the icon at zoom level 0
      const maxPixelSize = 40; //restricts the maximum size of the icon, otherwise the browser will choke at higher zoom levels trying to scale an image to millions of pixels
      const minPixelSize = 20;
      const zoom = googleMapRef.current.getZoom();
      let relativePixelSize = Math.round(
        pixelSizeAtZoom0 * Math.pow(2, Math.abs(zoom - 22)),
      ); // use 2 to the power of current zoom to calculate relative pixel size.  Base of exponent is 2 because relative size should double every time you zoom in
      if (relativePixelSize > maxPixelSize)
        //restrict the maximum size of the icon
        relativePixelSize = maxPixelSize;

      if (relativePixelSize < minPixelSize) relativePixelSize = minPixelSize;

      // console.log('relativePixelSize', relativePixelSize);
      setMarkerSize(relativePixelSize);
      let zoomNum: number = googleMapRef.current.getZoom();
      if (zoomNum > 18) {
        zoomNum -= 1;
      } else if (zoomNum < 5) {
        zoomNum += 1;
      }
      //to store the map center values in redux
      //stores only when map zoom value changes
      if (eventMapZoom != zoomNum) {
        dispatch(organizationActions.updateEventMapZoom(zoomNum));
      }
    }
  };

  const getAllMarkers = () => {
    const tempArray: any[] = [];
    const { inner_pps, ems_command_post, police_command_post } = activeEvent;

    police_command_post.coordinates.map((coords) => tempArray.push(coords));
    if (inner_pps)
      inner_pps
        ?.map((obj: InnerPerimeterPoint) => obj.point.coordinates)
        .map((obj: any) => {
          tempArray.push(obj);
        });

    if (ems_command_post?.coordinates) {
      ems_command_post.coordinates.map((coords) => tempArray.push(coords));
    }

    if (!isEms && suspectProfileList && suspectProfileList.length) {
      suspectProfileList
        .map((obj: Profile) => {
          if (obj.person_descriptions.length) {
            return obj.person_descriptions[0].report.location.coordinates;
          }
        })
        .map((coordsArray) => {
          if (coordsArray) {
            tempArray.push({
              lat: coordsArray[1],
              lng: coordsArray[0],
            });
          }
        });
    }
    if (eventUsers) {
      eventUsers
        .map((obj: EventUser) => {
          if (obj.user.lastKnownLocation?.coordinates) {
            return obj.user.lastKnownLocation?.coordinates;
          }
        })
        .map((coordsArray) => {
          if (coordsArray) {
            tempArray.push({
              lat: coordsArray.lat,
              lng: coordsArray.lng,
            });
          }
        });
    }
    // for (const eventUser of eventUsers) {
    //   if (eventUser.user.lastKnownLocation)
    //     tempArray.push(eventUser.user.lastKnownLocation.coordinates);
    // }

    return tempArray;
  };

  const setBounds = () => {
    const markers = getAllMarkers();
    // @ts-ignore
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((item, key) => {
      bounds.extend(item);
    });
    //setting fit bounds only for the first time
    if (eventMapZoom === null && eventMapCenter === null) {
      googleMapRef.current.fitBounds(bounds);
    }
    // googleMapRef.current.setOptions({ minZoom: 5 });

    //reseting values of zoom & center of map when navigates back and on refresh
    if (eventMapZoom !== null) {
      googleMapRef.current.setZoom(eventMapZoom);
    } else {
      const zoomNum: number = googleMapRef.current.getZoom();
      if (zoomNum > 18) {
        googleMapRef.current.setZoom(zoomNum - 1);
      } else if (zoomNum < 5) {
        googleMapRef.current.setZoom(zoomNum + 1);
      } else googleMapRef.current.setZoom(zoomNum);
    }
    if (eventMapCenter) {
      googleMapRef.current.setCenter(eventMapCenter);
    } else {
      const center = bounds.getCenter();
      googleMapRef.current.setCenter(center);
    }
  };

  useEffect(() => {
    if (activeEvent && googleMapRef.current) {
      setBounds();
    }
  }, [activeEvent, googleMapRef.current]);

  const handleCPsDrag = (
    e: any,
    index: number,
    type: 'police_command_post' | 'ems_command_post',
  ) => {
    const cps =
      type === 'police_command_post'
        ? activeEvent?.police_command_post
        : activeEvent?.ems_command_post;
    if (cps) {
      const new_cps: GeoJSONType[] = cps.coordinates.map((pp, seq) =>
        index === seq ? [e.latLng.lng(), e.latLng.lat()] : [pp.lng, pp.lat],
      );
      dispatch(
        organizationActions.updateCpsRequest({
          [type]: {
            type: 'MultiPoint',
            coordinates: new_cps,
          },
        }),
      );
    }
  };
  return (
    <div className={classes.container} style={{ flex: 3 }}>
      <GoogleMap
        mapContainerClassName={classes['app-map']}
        onLoad={handleMapLoad}
        center={eventMapCenter ? eventMapCenter : undefined}
        zoom={eventMapZoom ? eventMapZoom : 12}
        onZoomChanged={handleZoomChange}
        onCenterChanged={handleCenterChange}
      >
        {activeEvent?.police_command_post &&
          activeEvent?.police_command_post.coordinates.map(
            (position, index) => (
              <Marker
                key={index}
                position={position}
                icon={CPIcon}
                // draggable={false}
                draggable={!isEms}
                onDragEnd={(e: any) =>
                  handleCPsDrag(e, index, 'police_command_post')
                }
              />
            ),
          )}

        {activeEvent?.ems_command_post &&
          activeEvent?.ems_command_post.coordinates.map((position, index) => (
            <Marker
              key={index}
              position={position}
              icon={EMSCPIcon}
              draggable={true}
              onDragEnd={(e: any) =>
                handleCPsDrag(e, index, 'ems_command_post')
              }
            />
          ))}

        {activeEvent?.inner_pps &&
          activeEvent?.inner_pps.map(
            (ppObj: OverRideInnerPPInterface, index: number) => (
              <Marker
                key={ppObj.id}
                position={ppObj.point.coordinates}
                icon={{
                  url: ppObj.occupied ? OccupiedPp : PerimeterPointIcon,
                  anchor: new window.google.maps.Point(17.5, 18.5),
                }}
                draggable={false}
                label={{
                  text: ppObj.label.toUpperCase(),
                  color: ppObj.occupied ? COLORS.WHITE : COLORS.PP_COLOR,
                  fontWeight: 'bold',
                  fontFamily: 'Poppins',
                  fontSize: '20px',
                }}
              />
            ),
          )}

        {activeEvent?.inner_pps && (
          <Polygon
            options={{
              strokeColor: COLORS.PP_COLOR,
              fillOpacity: 0,
            }}
            path={overrideInnerPPPolySort(activeEvent.inner_pps).map(
              (ppObj: OverRideInnerPPInterface) => ppObj.point.coordinates,
            )}
            draggable={false}
            editable={false}
          />
        )}

        {eventUsers.map((eventUser) => {
          return (
            <PersonTypeMapIcon
              size={markerSize}
              key={eventUser.id}
              user={eventUser}
            />
          );
        })}
        {!isEms &&
          suspectProfileList?.map((profile, index: number) => {
            const personDescList = profile.person_descriptions;
            if (personDescList.length) {
              return (
                <CreatedAtComponent
                  size={markerSize}
                  index={index}
                  personDescObj={personDescList[0]}
                  type="T"
                  severity={profile.severity}
                />
              );
            }
          })}

        {injuredProfileList?.map((profile, index: number) => {
          const personDescList = profile.person_descriptions;
          if (personDescList.length) {
            return (
              <CreatedAtComponent
                size={markerSize}
                index={index}
                personDescObj={personDescList[0]}
                type="I"
                severity={profile.severity}
              />
            );
          }
        })}
      </GoogleMap>
    </div>
  );
}

export default MapContainer;
