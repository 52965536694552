import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { CustomButton } from 'components/General/CustomButton';
import React from 'react';
import { COLORS } from 'styles/colorConstants';
import { WARNING_MESSAGE } from 'utils';

interface ModalProps {
  open: boolean;
  handleLeave?: any;
  handleStay?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: COLORS.BLACK,
    fontSize: '12px',
    fontFamily: 'Poppins',
  },
  actionBtns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: '8px',
  },
}));

export const FormSaveConfirmationModal = (props: ModalProps) => {
  const { open, handleLeave, handleStay } = props;
  //   const dispatch = useDispatch();
  const classes = useStyles();
  //   const history = useHistory();
  //   const isModalOpen = useSelector(getFormModalStatus);
  //   const url = useSelector(getFormModalUrl);

  //   const handleLeave = () => {
  //     dispatch(destroy(REDUX_FORM_CONSTANT.ORGANIZATION));
  //     dispatch(destroy(REDUX_FORM_CONSTANT.POLICE_DEPT));
  //     dispatch(organizationActions.setFormModalState({ status: false }));
  //     history.push(url);
  //   };

  //   const handleStay = () => {
  //     dispatch(organizationActions.setFormModalState({ status: false }));
  //   };
  return (
    <Dialog open={open}>
      <DialogTitle className={classes.text}>Unsaved Information</DialogTitle>
      <DialogContent className={classes.text}>{WARNING_MESSAGE}</DialogContent>
      <DialogActions className={classes.actionBtns}>
        <CustomButton onClick={handleStay} error={false} label="Stay" />
        <CustomButton onClick={handleLeave} error={true} label="Leave" />
      </DialogActions>
    </Dialog>
  );
};
