import { ASROrgTypeInterface, AROrgTypeInterface } from 'utils/types';

// export const orgTypeFromJSON = (
//   responsePayload: ASROrgTypeInterface,
// ): AROrgTypeInterface => {
//   return {
//     ...responsePayload.data,
//     ...responsePayload.data?.data,
//   };
// };

export const orgTypeFromJSON = (responsePayload: any): any => {
  return {
    ...responsePayload,
  };
};
