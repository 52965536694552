import {
  ASROrgTypeInterface,
  ASROrgRolesInterface,
  PointsGeoJSONType,
} from 'utils/types';
import {
  OrganizationDataActionPayload,
  OrganizationRolesInterface,
  OrganizationUserSuccessActionPayload,
  OrganizationUserTypeInterface,
} from 'store/organization';
import { OrganizationTypeInterface } from 'models/organization';
import { API_SERVICE_TYPES } from 'utils/constants';

import { apiService } from './module/api.service';

export const getAppCategory = async (): Promise<ASROrgTypeInterface> => {
  return <Promise<ASROrgTypeInterface>>(
    apiService('/organization_type', API_SERVICE_TYPES.GET)
  );
};

export const getAppRoles = async (): Promise<ASROrgRolesInterface> => {
  return <Promise<ASROrgRolesInterface>>(
    apiService('/roles', API_SERVICE_TYPES.GET)
  );
};

export const getOrganizationList = async (
  orgType?: string,
): Promise<OrganizationDataActionPayload> => {
  const url = orgType
    ? `/organization?page_size=1000&organization_type=${orgType}`
    : '/organization?page_size=1000';

  return <Promise<OrganizationDataActionPayload>>(
    apiService(url, API_SERVICE_TYPES.GET)
  );
};
export const getOrganizationDetails = async (
  id: string,
): Promise<OrganizationDataActionPayload> => {
  return <Promise<OrganizationDataActionPayload>>(
    apiService(`/organization/${id}`, API_SERVICE_TYPES.GET)
  );
};

export const createOrganization = async (
  payload: any,
): Promise<OrganizationTypeInterface> => {
  return <Promise<OrganizationTypeInterface>>(
    apiService('/organization', API_SERVICE_TYPES.POST, payload.body)
  );
};
export const updateOrganization = async (
  payload: any,
): Promise<OrganizationTypeInterface> => {
  const { id, body } = payload;
  return <Promise<OrganizationTypeInterface>>(
    apiService(`/organization/${id}`, API_SERVICE_TYPES.PATCH, body)
  );
};

export const getOrganizationUsers = async (
  payload: any,
): Promise<OrganizationUserSuccessActionPayload> => {
  const { id, domain } = payload;
  return <Promise<OrganizationUserSuccessActionPayload>>(
    apiService(
      `${
        domain
          ? `/organization/${id}/user?domain=${domain}&page_size=1000`
          : `/organization/${id}/user?page_size=1000`
      }`,
      API_SERVICE_TYPES.GET,
    )
  );
};
export const getOrgUserDetails = async (
  payload: any,
): Promise<OrganizationUserTypeInterface> => {
  const { userId } = payload;
  return <Promise<OrganizationUserTypeInterface>>(
    apiService(`/user/${userId}`, API_SERVICE_TYPES.GET)
  );
};

export const getOrganizationRoles = async (
  payload: any,
): Promise<OrganizationRolesInterface[]> => {
  const { id } = payload;
  return <Promise<OrganizationRolesInterface[]>>(
    apiService(`/organization/${id}/role`, API_SERVICE_TYPES.GET)
  );
};

export const createOrgUsers = async (
  payload: any,
): Promise<OrganizationUserTypeInterface> => {
  const { orgId, body } = payload;
  return <Promise<OrganizationUserTypeInterface>>(
    apiService(`/organization/${orgId}/user`, API_SERVICE_TYPES.POST, body)
  );
};

export const updateOrgUsers = async (
  payload: any,
): Promise<OrganizationUserTypeInterface> => {
  const { userId, body } = payload;
  return <Promise<OrganizationUserTypeInterface>>(
    apiService(`/user/${userId}`, API_SERVICE_TYPES.PATCH, body)
  );
};

export const getOrgSuperviseeList = async (payload: {
  orgId: string;
}): Promise<OrganizationUserTypeInterface> => {
  const { orgId } = payload;
  return <Promise<OrganizationUserTypeInterface>>(
    apiService(`/organization/${orgId}/supervisee`, API_SERVICE_TYPES.GET)
  );
};
export const createOrgSupervisee = async (payload: {
  orgId: string;
  body: { supervisee: { id: string } };
}): Promise<OrganizationUserTypeInterface> => {
  const { orgId, body } = payload;
  return <Promise<OrganizationUserTypeInterface>>(
    apiService(
      `/organization/${orgId}/supervisee`,
      API_SERVICE_TYPES.POST,
      body,
    )
  );
};

export const getConfig = async () => {
  return apiService(`/config`, API_SERVICE_TYPES.GET);
};

export const updateStatus = async (payload: {
  type: string; //user or organization
  id: string;
  status: string; //activate or deactivate
}): Promise<OrganizationUserTypeInterface> => {
  const { type, id, status } = payload;
  return <Promise<OrganizationUserTypeInterface>>(
    apiService(`/${type}/${id}/${status}`, API_SERVICE_TYPES.POST, {})
  );
};

export const createEventService = async (payload: { id: string }) => {
  const { id } = payload;
  return <Promise<OrganizationUserTypeInterface>>(
    apiService(`/organization/${id}/event`, API_SERVICE_TYPES.POST, {})
  );
};

export const updateEventCps = async (
  payload: any,
): Promise<OrganizationTypeInterface> => {
  const { id, body } = payload;
  return <Promise<OrganizationTypeInterface>>(
    apiService(`/event/${id}`, API_SERVICE_TYPES.PATCH, body)
  );
};

export const deletePP = async (payload: {
  id: string;
}): Promise<OrganizationTypeInterface> => {
  const { id } = payload;

  return <Promise<OrganizationTypeInterface>>(
    apiService(`/org_perimeter_point/${id}/resolve`, API_SERVICE_TYPES.POST)
  );
};

export const addPP = async (payload: {
  id: string;
  body: {
    point: PointsGeoJSONType;
  };
}): Promise<OrganizationTypeInterface> => {
  const { id, body } = payload;

  return <Promise<OrganizationTypeInterface>>(
    apiService(
      `/organization/${id}/perimeter_point`,
      API_SERVICE_TYPES.POST,
      body,
    )
  );
};

export const editPP = async (payload: {
  id: string;
  body: {
    point: PointsGeoJSONType;
  };
}): Promise<OrganizationTypeInterface> => {
  const { id, body } = payload;

  return <Promise<OrganizationTypeInterface>>(
    apiService(`/org_perimeter_point/${id}`, API_SERVICE_TYPES.PATCH, body)
  );
};
