import React from 'react';
import { styled } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Logout from 'components/Header/Logout';
import { AppBar } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { COLORS } from 'styles/colorConstants';

const Container = styled('div')({
  color: '#000000',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: COLORS.CHARCOAL,
    },
    toolbar: {
      padding: theme.spacing(0),
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

export const NoActiveEvent: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Logout />
        </Toolbar>
      </AppBar>

      <Container data-testid="text">
        There is no active event for you.
      </Container>
    </div>
  );
};
