import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { COLORS } from '../../styles/colorConstants';
import { getActiveEventOrg } from 'store/event/event.selector';
import { useDispatch, useSelector } from 'react-redux';
import { eventActions } from 'store/event/event.slice';
import { Typography } from '@material-ui/core';
import { getCurrentLocation } from 'utils/location';

const useNavStyles = makeStyles((theme: Theme) =>
  createStyles({
    navContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    orgName: {
      justifyContent: 'center',
      color: COLORS.WHITE_GREY,
    },
  }),
);

const OpsNavSection: React.FC = () => {
  const classes = useNavStyles();
  const eventOrg = useSelector(getActiveEventOrg);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!eventOrg) {
      dispatch(eventActions.requestActiveEvent(null));
    }
  }, [eventOrg]);

  return (
    <div data-cy="org-name" className={classes.navContainer}>
      <Typography className={classes.orgName}>
        {eventOrg?.map(({ name }) => name).join(', ')}
      </Typography>
    </div>
  );
};

export default OpsNavSection;
