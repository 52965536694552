import {
  CLIENT_ID,
  GRANT_TYPE,
  CLIENT_SECRET,
  BASE_URL,
} from './../config/api';
import axios from 'axios';
import { callAPIService } from 'services/module/helper';
import { AuthActionPayloadInterface } from 'store/login/login.type';
import { GeneralObject } from 'utils/commonTypes';
import { getRefreshToken } from './localStorage.service';
// TODO
// add the response type

export const authenticateUser = async (
  userObj: AuthActionPayloadInterface | null,
  grantType: string,
): Promise<any> => {
  const requestPayload: GeneralObject = {
    grant_type: grantType,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  if (grantType === 'refresh_token') {
    requestPayload['refresh_token'] = getRefreshToken();
  } else {
    requestPayload['username'] = userObj?.userName;
    requestPayload['password'] = userObj?.password;
  }

  const params = new URLSearchParams();

  Object.keys(requestPayload).forEach((key: string) => {
    params.append(key, requestPayload[key]);
  });

  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };
  const url = `${BASE_URL}o/token`;

  return callAPIService(axios.post(url, params, config));
};
