import { MarkerObj } from './../../utils/commonTypes';
import _ from 'lodash';
import { COUNTRY_DATA } from 'utils/constants';
import * as GEO_JSON_CONSTANTS from 'utils/constants/geoJson';
import { isNilOrEmpty, gnAlphabeticalSeq } from 'utils/helper';
import { PositionInterface } from 'utils/commonTypes';
import PerimeterPointIcon from 'assets/customized/perimeter-point.svg';
import CPIcon from 'assets/customized/command-post.svg';
import EmsCpIcon from 'assets/customized/house-medical.svg';
import {
  OverRideOrgInterface,
  PolygonPositionInterfecae,
  MultipleGeoJSONType,
  MultiPointMarkerObj,
  PointMarkerObj,
  PointsGeoJSONType,
} from 'utils/types';

type Country = typeof COUNTRY_DATA[0];

interface DomainAPIPayload {
  name: string;
}
interface OrgType {
  id: string;
  created_at: string;
  updated_at: string;
  archived_at: string | null;
  name: string;
  street_1: string;
  street_2: string;
  city: string;
  postal_code: string;
  phone?: string;
  is_archived: boolean;
  organization_type: string;
  identifier?: string;
  has_active_event?: boolean;
  domains?: Array<string>;
}

interface GeoJSON {
  type: string;
  coordinates: [];
}
export interface OrgPerimeterPoints {
  id?: string;
  point: PointsGeoJSONType;
  label?: string;
}

export interface OrganizationTypeInterface extends OrgType {
  country?: Country | null;
  geoFence: PolygonPositionInterfecae;
  perimeterPoints: MultiPointMarkerObj;
  commandPost: PointMarkerObj;
  emsCommandPoint: PointMarkerObj | null;
  orgPps: PPMarkerInterface[];
}
export interface ApiResponseOrgI extends OrgType {
  country: string | undefined | null;
  geofence: GeoJSON;
  perimeter_points: GeoJSON;
  command_post: GeoJSON;
}

export type PPMarkerInterface = Omit<OrgPerimeterPoints, 'point'> & {
  position: PositionInterface;
  icon: string;
};

const mapCountry = (countryName: string | null | undefined) => {
  if (!countryName) {
    return null;
  }

  const countryIndex = _.findIndex(COUNTRY_DATA, (country: Country) => {
    return _.isEqual(country.name, countryName);
  });

  if (countryIndex !== -1) {
    return _.cloneDeep(COUNTRY_DATA[countryIndex]);
  }
  return null;
};
const mapState = (stateName: string | null | undefined) => {
  if (!stateName) {
    return null;
  }

  return { name: stateName };
};

export function orgModelFromJSON(
  apiResponse: OverRideOrgInterface[],
): OrganizationTypeInterface[] | [] {
  if (isNilOrEmpty(apiResponse)) {
    return [];
  }
  if (!_.isArray(apiResponse)) {
    return [];
  }

  return apiResponse.map((res) => {
    return orgModel(res);
  });
}
export function orgModelToJSON(orgObj: any, metaData?: any): any {
  if (isNilOrEmpty(orgObj)) {
    return null;
  }

  return generateApiPayload(orgObj, metaData);
}

export const getDomainAPIPayload = (
  domains: Array<string>,
): Array<DomainAPIPayload> => {
  return domains.map((domain) => {
    return {
      name: domain,
    };
  });
};

const getGeoFenceAPIPayload = (
  geoFence: any,
  defaultCoordinates = GEO_JSON_CONSTANTS.DEFAULT_COORDINATES,
) => {
  const defaultValue = GEO_JSON_CONSTANTS.DEFAULT_GEO_FENCE(defaultCoordinates);
  const type = _.get(geoFence, 'type', defaultValue.type);

  let userSelectedCoordinate = _.get(geoFence, 'coordinates', []);

  if (!(userSelectedCoordinate && userSelectedCoordinate.length)) {
    userSelectedCoordinate = defaultValue.coordinates;
  }

  console.log('%c userSelectedCoordinate', 'background: black; color: yellow', {
    userSelectedCoordinate,
  });

  userSelectedCoordinate = _.cloneDeep(userSelectedCoordinate);

  const coordinates = [covertToGeoJson(userSelectedCoordinate)];

  return { type, coordinates };
};
const getPPAPIPayload = (
  ppObj: any,
  defaultCoordinates = GEO_JSON_CONSTANTS.DEFAULT_COORDINATES,
) => {
  const defaultValue = GEO_JSON_CONSTANTS.DEFAULT_PERIMETER_POINT(
    defaultCoordinates,
  );
  const type = _.get(ppObj, 'type', defaultValue.type);
  let userSelectedCoordinate = _.get(ppObj, 'coordinates', []);
  if (!(userSelectedCoordinate && userSelectedCoordinate.length)) {
    userSelectedCoordinate = defaultValue.coordinates;
  }

  const coordinates = covertMarkersToGeoJSON(userSelectedCoordinate);

  return { type, coordinates };
};
const getCPAPIPayload = (
  geoFence: any,
  defaultCoordinates = GEO_JSON_CONSTANTS.DEFAULT_COORDINATES,
) => {
  const defaultValue = GEO_JSON_CONSTANTS.DEFAULT_COMMAND_POINT(
    defaultCoordinates,
  );

  const type = _.get(geoFence, 'type', defaultValue.type);

  let userSelectedCoordinate = _.get(geoFence, 'coordinates', []);
  if (!(userSelectedCoordinate && userSelectedCoordinate.length)) {
    userSelectedCoordinate = _.cloneDeep(defaultValue.coordinates);
  }
  // debugger;
  const coordinates = covertMarkersToGeoJSON(userSelectedCoordinate).pop();

  return { type, coordinates };
};

const getEMSCPAPIPayload = (
  emsObj: any,
  defaultCoordinates = GEO_JSON_CONSTANTS.DEFAULT_COORDINATES,
) => {
  const defaultValue = GEO_JSON_CONSTANTS.DEFAULT_COMMAND_POINT(
    defaultCoordinates,
  );

  const type = _.get(emsObj, 'type', defaultValue.type);
  const userSelectedCoordinate = _.get(emsObj, 'coordinates', []);
  const coordinates = covertMarkersToGeoJSON(userSelectedCoordinate).pop();
  if (_.isNil(coordinates)) {
    return null;
  } else return { type, coordinates: coordinates };
};

// Used only for new org
const getOrgPPAPIPayload = (
  orgPps: any,
  defaultCoordinates = GEO_JSON_CONSTANTS.DEFAULT_COORDINATES,
) => {
  const defaultValue = GEO_JSON_CONSTANTS.DEFAULT_ORG_PERIMETER_POINT(
    defaultCoordinates,
  );

  return defaultValue.map((coords) => {
    return {
      point: {
        type: 'Point',
        coordinates: [coords.position.lng, coords.position.lat],
      },
    };
  });
};

function generateApiPayload(orgObj: any, metaData?: any): any {
  const payload: any = {};
  if (!orgObj) {
    return payload;
  }

  payload.id = orgObj.id;
  payload.created_at = orgObj.created_at;
  payload.updated_at = orgObj.updated_at;
  payload.name = orgObj.name;
  payload.street_1 = orgObj.street_1;
  payload.street_2 = orgObj.street_2;
  payload.city = orgObj.city;
  payload.country = _.get(orgObj.country, 'name', '');
  payload.state = _.get(orgObj.state, 'name', '');
  payload.postal_code = orgObj.postal_code;
  payload.phone = orgObj.phone;
  payload.is_archived = orgObj.is_archived;
  payload.organization_type = orgObj.organization_type;
  payload.identifier = orgObj.identifier;
  if (orgObj.domains) {
    payload.domains = getDomainAPIPayload(orgObj.domains);
  }

  // add the geo json payload
  payload.geofence = getGeoFenceAPIPayload(
    orgObj.geoFence,
    metaData?.defaultCoordinates,
  );
  payload.perimeter_points = getPPAPIPayload(
    orgObj.perimeterPoints,
    metaData?.defaultCoordinates,
  );
  payload.command_post = getCPAPIPayload(
    orgObj.commandPost,
    metaData?.defaultCoordinates,
  );

  payload.ems_command_post = getEMSCPAPIPayload(
    orgObj.emsCommandPoint,
    metaData?.defaultCoordinates,
  );
  if (!orgObj.orgPps) {
    payload.org_perimeter_point = getOrgPPAPIPayload(
      orgObj.orgPps,
      metaData?.defaultCoordinates,
    );
  }
  return payload;
}

export const convertToStringArray = (
  domains: Array<{ name: string }>,
): Array<string> => {
  return domains?.map((domain) => domain.name);
};

export const covertToPoints = (geoJsonObj: GeoJSON) => {
  let pointsArr: PositionInterface[] = [];
  // @ts-ignore
  const coordinates = geoJsonObj.coordinates[0] || [];
  if (coordinates && coordinates.length) {
    pointsArr = coordinates.map((geoArr) => {
      return {
        lat: geoArr[1],
        lng: geoArr[0],
      };
    });
  }

  // remove the last co-ordinate
  const firstPoint = pointsArr[0];
  const lastPoint = pointsArr[pointsArr.length - 1];

  if (_.isEqual(firstPoint, lastPoint)) {
    pointsArr = _.dropRight(pointsArr, 1);
  }

  return pointsArr;
};

export const covertToGeoJson = (points: PositionInterface[]) => {
  let coordinates: any = [];

  if (!(points && points.length)) {
    return ['dsadas'];
  }

  if (points && points.length) {
    coordinates = points.map((ptObj) => {
      return [ptObj.lng, ptObj.lat];
    });
  }
  // match the first and last co-ordinates
  coordinates[coordinates.length] = coordinates[0];

  return coordinates;
};

// geoFence: PolygonGeoJSONType;
// perimeterPoints: PointsGeoJSONType;
// commandPost: MultipleGeoJSONType;

export const covertGeoJSONToMarker = (
  geoJsonObj: MultipleGeoJSONType,
  icon: string,
) => {
  let coordinates: any = [];

  if (geoJsonObj && geoJsonObj?.coordinates.length) {
    coordinates = geoJsonObj.coordinates.map((geoJsonObj, index) => {
      return {
        position: {
          lat: geoJsonObj[1],
          lng: geoJsonObj[0],
        },
        icon,
        label: gnAlphabeticalSeq(index),
      };
    });
  }

  return coordinates;
};

export const convertPPJSONToPPMarker = (
  geoJsonObj: OrgPerimeterPoints[],
): PPMarkerInterface[] => {
  let coordinates: any = [];

  if (geoJsonObj && geoJsonObj?.length) {
    coordinates = geoJsonObj.map((geoJsonObj) => {
      return {
        position: {
          lat: geoJsonObj.point.coordinates[1],
          lng: geoJsonObj.point.coordinates[0],
        },
        icon: PerimeterPointIcon,
        label: geoJsonObj.label?.toUpperCase(),
        id: geoJsonObj.id,
      };
    });
  }

  return coordinates;
};

export const covertMarkersToGeoJSON = (markers: MarkerObj[]) => {
  let coordinates: any = [];

  if (markers && markers.length) {
    coordinates = markers.map((marker) => {
      return [marker.position.lng, marker.position.lat];
    });
  }

  return coordinates;
};

function orgModel(dataObj: OverRideOrgInterface) {
  const orgObj = {} as OrganizationTypeInterface;

  orgObj.id = dataObj.id;
  orgObj.created_at = dataObj.created_at;
  orgObj.updated_at = dataObj.updated_at;
  orgObj.name = dataObj.name;
  orgObj.street_1 = dataObj.street_1;
  orgObj.street_2 = dataObj.street_2;
  orgObj.city = dataObj.city;
  orgObj.country = mapCountry(dataObj.country);
  // @ts-ignore
  orgObj.state = mapState(dataObj.state);
  orgObj.postal_code = dataObj.postal_code;
  orgObj.phone = dataObj.phone;
  orgObj.is_archived = dataObj.is_archived;
  orgObj.organization_type = dataObj.organization_type;
  orgObj.identifier = dataObj.identifier;
  orgObj.archived_at = dataObj.archived_at;
  if (dataObj.domains) {
    orgObj.domains = convertToStringArray(dataObj.domains);
  }

  orgObj.has_active_event = dataObj.has_active_event;

  const defaultGeoFence = GEO_JSON_CONSTANTS.DEFAULT_GEO_FENCE(
    undefined,
    undefined,
    true,
  );
  const defaultPerimeterPoint = GEO_JSON_CONSTANTS.DEFAULT_PERIMETER_POINT(
    undefined,
    undefined,
    true,
  );
  const defaultCommandPoints = GEO_JSON_CONSTANTS.DEFAULT_COMMAND_POINT(
    undefined,
    undefined,
    true,
  );

  // geo fence
  orgObj.geoFence = {
    type: _.get(dataObj, 'geofence.type', defaultGeoFence.type),
    // @ts-ignore
    coordinates: covertToPoints(dataObj.geofence),
  };
  orgObj.perimeterPoints = {
    type: _.get(dataObj, 'perimeter_points.type', defaultPerimeterPoint.type),
    coordinates: covertGeoJSONToMarker(
      dataObj.perimeter_points,
      PerimeterPointIcon,
    ),
  };
  orgObj.orgPps = convertPPJSONToPPMarker(dataObj.org_perimeter_point);

  const isEmsCpPresent = _.get(dataObj, 'ems_command_post', null);
  if (_.isNull(isEmsCpPresent)) {
    orgObj.emsCommandPoint = null;
  } else {
    // @ts-ignore
    dataObj.ems_command_post.coordinates = [
      // @ts-ignore
      dataObj.ems_command_post.coordinates,
    ];
    orgObj.emsCommandPoint = {
      type: _.get(dataObj, 'ems_command_post.type', defaultCommandPoints.type),
      // @ts-ignore
      coordinates: covertGeoJSONToMarker(dataObj.ems_command_post, EmsCpIcon),
    };
  }
  // @ts-ignore
  dataObj.command_post.coordinates = [dataObj.command_post.coordinates];

  orgObj.commandPost = {
    type: _.get(dataObj, 'command_post.type', defaultCommandPoints.type),
    coordinates: covertGeoJSONToMarker(dataObj.command_post, CPIcon),
  };

  return orgObj;
}

export const basicOrgDetailsFromJson = (
  apiResponse: OverRideOrgInterface[],
): OrganizationTypeInterface[] | [] => {
  if (isNilOrEmpty(apiResponse)) {
    return [];
  }
  if (!_.isArray(apiResponse)) {
    return [];
  }

  return apiResponse.map((res) => {
    return listOrgModel(res);
  });
};

function listOrgModel(dataObj: OverRideOrgInterface) {
  const orgObj = {} as OrganizationTypeInterface;

  orgObj.id = dataObj.id;
  orgObj.name = dataObj.name;
  orgObj.street_1 = dataObj.street_1;
  orgObj.street_2 = dataObj.street_2;
  orgObj.city = dataObj.city;
  orgObj.country = mapCountry(dataObj.country);
  // @ts-ignore
  orgObj.state = mapState(dataObj.state);
  orgObj.postal_code = dataObj.postal_code;
  orgObj.phone = dataObj.phone;
  orgObj.organization_type = dataObj.organization_type;
  orgObj.archived_at = dataObj.archived_at;

  return orgObj;
}
