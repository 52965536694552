import _ from 'lodash';
import { OrganizationTypeInterface } from 'store/policeDept/policeDept.type';
import { Permission } from 'utils';
import { REDUX_FORM_CONSTANT } from 'utils/constants';
import { RootState } from '../index';

export const getLoginFormValue = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.LOGIN}.values`, {});

export const getAuthenticatedUserOrgType = (state: RootState): string =>
  _.get(state, `login.auth.organization.organization_type`, '');

export const getAuthenticatedUserOrg = (
  state: RootState,
): OrganizationTypeInterface => _.get(state, `login.auth.organization`, {});

// export const getUserPermissions = (state: RootState): Permission | null =>
//   _.get(state, `login.permissions`, {});
export const getAuthenticatedUserId = (state: RootState): string =>
  _.get(state, `login.auth.id`, '');

export const getAuthenticatedUserOrgId = (state: RootState): string =>
  _.get(state, `login.auth.organization.id`, '');

export const getLoginLoaderStatus = (state: RootState): boolean =>
  _.get(state, `login.isLoading`, false);

export const getLoginUser = (state: RootState): string =>
  `${_.get(state, `login.auth.first_name`, '')} ${_.get(
    state,
    `login.auth.last_name`,
    '',
  )}`;
export const getAuthenticatedUserEmail = (state: RootState): string =>
  _.get(state, `login.auth.username`, '');

export const getAuthenticatedUserName = (state: RootState): string =>
  _.get(state, `login.auth.first_name`, '');

export const getAuthenticatedUserLastName = (state: RootState): string =>
  _.get(state, `login.auth.last_name`, '');

export const getSystemIpAddress = (state: RootState): string | undefined =>
  _.get(state, `login.ipAddress`, undefined);
