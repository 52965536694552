export const WS_PAYLOAD_TYPE = {
  REPORT: 'report',
  PROFILE: 'profile',
  EVENT: 'event',
  EVENT_USER: 'event_user',
  USER: 'user',
  EVENT_USER_LOCATION: 'event_user_location',
  EVENT_JOIN_REQUEST: 'event_join_request',
  VIDEO_CALL_INITIATE: 'video_call_initiate',
  VIDEO_CALL_JOIN: 'video_call_join',
  VIDEO_CALL_RESPONSE_STATUS: 'video_call_response_status',
  VIDEO_CALL_END: 'video_call_end',
};
