import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { COLORS } from 'styles/colorConstants';

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      outline: 'none',
    },
    container: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    bodyContainer: {
      display: 'flex',
      flex: 1,
      //   justifyContent: 'center',
      //   alignItems: 'center',
      position: 'relative',
      background: COLORS.RAISIN_BLACK,
      width: '100%',
      maxWidth: '500px',
      minHeight: '250px',
      borderRadius: '8px',
      boxShadow: '0px 3px 6px #00000029',
      opacity: 1,
      overflow: 'auto',
      padding: theme.spacing(4),
    },
  }),
);

type AppModalProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: (event: any, reason: 'backdropClick' | 'escapeKeyDown') => void;
};

export const AppModal = (props: AppModalProps) => {
  const classes = useHeaderStyles();

  const { open, onClose, children } = props;

  return (
    <div className={classes.container}>
      <Modal open={open} onClose={onClose} className={classes.modalContainer}>
        <div className={classes.bodyContainer}> {children}</div>
      </Modal>
    </div>
  );
};
