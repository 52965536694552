import { getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleWare from 'redux-saga';
import { all } from 'redux-saga/effects';
import logger from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import webSocketMiddleware from './websocket/ws.middleware';
import { history } from 'utils';

// import { counterSaga } from './counter';
import { themeSaga } from './theme';
import { organizationSaga } from './organization';
import { loginSaga } from './login';
import { appSaga } from './app';
import { policeDeptSaga } from './policeDept';
import { usersSaga } from './users';
import { eventSaga } from './event';
import { websocketSaga } from './websocket/ws.saga';
import { videoCallSaga } from 'store/videoCall/videoCall.saga';
import { eventUserSaga } from 'store/eventUser/eventUser.saga';

function* rootSaga() {
  yield all([
    themeSaga(),
    // counterSaga(),
    organizationSaga(),
    loginSaga(),
    appSaga(),
    policeDeptSaga(),
    usersSaga(),
    eventSaga(),
    websocketSaga(),
    videoCallSaga(),
    eventUserSaga(),
  ]);
}

const sagaMiddleWare = createSagaMiddleWare();

// Add Redux middleware here
const middleware = [
  sagaMiddleWare,
  routerMiddleware(history),
  webSocketMiddleware,
];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

function runSagas(): void {
  sagaMiddleWare.run(rootSaga);
}

export { middleware, runSagas };
