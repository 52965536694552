import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { WrappedFieldProps } from 'redux-form';
import { COLORS } from 'styles/colorConstants';
import { useSelector } from 'react-redux';
import { allOrgPersonTypes, getUserRoleList } from 'store';
import { Box, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: COLORS.ABBEY,
  },
  popupIndicator: {
    color: theme.palette.primary.main,
  },
  clearIndicator: {
    color: theme.palette.primary.main,
  },
  popupIcon: {
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: COLORS.WHITE,
  },
  root: {
    width: '100%',
    '& .MuiInputBase-root.Mui-disabled': {
      color: COLORS.DISABLE_COLOR,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: COLORS.DISABLE_COLOR,
    },
  },
}));

type ComboBoxProps = {
  options: any;
  displayValuePath: string;
  label: string;
  id: string;
  disable?: boolean;
  required?: boolean;
};

export const ComboBox: React.FC<WrappedFieldProps & ComboBoxProps> = (
  props: WrappedFieldProps & ComboBoxProps,
) => {
  const formInputChange = props.input.onChange;
  const formInputBlur = props.input.onBlur;
  const [val, setValue] = useState();
  const classes = useStyles();
  const allRoles = useSelector(getUserRoleList);
  const allPersonTypes = useSelector(allOrgPersonTypes);

  const handleChange = (event: any, value: any) => {
    setValue(value);
    formInputChange(value);
  };
  const handleBlur = () => {
    formInputBlur(val);
  };
  useEffect(() => {
    if (!_.isEmpty(allRoles) && allPersonTypes?.length !== 0) {
      const valIndexInOptions = _.findIndex(
        props.options,
        (obj: any) => obj.id === props.input.value?.id,
      );
      if (
        valIndexInOptions === -1 &&
        props.options?.length !== 0 &&
        (props.input.name === 'person_type' || props.input.name === 'role')
      ) {
        formInputChange({});
      }
    }
  }, [props.options]);

  const getCountryList = (list: Array<{ name: string; code: string }>) => {
    const sortedList = _.sortBy(list, (obj) => {
      return obj.name;
    });
    const getUsaIndex = sortedList.findIndex(
      (obj: { name: string; code: string }) => obj.code === 'US',
    );
    const getUsaObj = sortedList.splice(getUsaIndex, 1)[0];

    sortedList.unshift(getUsaObj);
    return sortedList || [];
  };

  return (
    <Autocomplete
      disabled={props.disable}
      id={props.id || ''}
      classes={classes}
      options={
        props.input?.name === 'organization' ||
        props.input?.name === 'associatedOrg'
          ? props.options
          : props.input?.name === 'country'
          ? getCountryList(props.options)
          : _.sortBy(props.options, (obj) => {
              return obj.name;
            }) || []
      }
      getOptionLabel={(option: any) => {
        return _.get(option, props.displayValuePath, '');
      }}
      {...props.input}
      onChange={handleChange}
      onBlur={handleBlur}
      renderInput={(params) => (
        <Box>
          <TextField
            required={props.required}
            {...params}
            label={props.label}
          />
          {props.meta.touched && (
            <FormHelperText data-cy={`error-${props.label}`} error={true}>
              {props.meta.error}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default ComboBox;
