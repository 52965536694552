import { getFullAddress } from 'routes/Organization/utils/helper';
import { MarkerObj, PositionInterface } from 'utils/commonTypes';
import * as localStorageService from 'services/localStorage.service';
import {
  isCreateAction,
  isPoliceDeptRoute,
  isOrgRoute,
  isEmsRoute,
  isUserRoute,
  isSpecialEventRoute,
} from 'utils/helper';
import {
  EMS_ROUTES,
  ORGANIZATION_ROUTES,
  POLICE_DEPT_ROUTES,
  SPECIAL_EVENT_ROUTES,
  USERS_ROUTES,
} from './../../routes/path';
import { all, put, select, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getActiveCategory,
  getOrganizationCategory,
  getSeOrgCategory,
} from 'store/app/app.selector';
import {
  getGeoFencePoints,
  getPerimeterPoints,
  getCommandPostPoints,
  getEMSCommandPostPoints,
} from 'store/organization/organization.selectors';
import { CategoryInterface } from 'store/app/app.types';
import * as organizationService from 'services/organization.service';
import {
  showErrorMsg,
  showSuccessMsg,
  STATUS,
  REDUX_FORM_CONSTANT,
} from 'utils/';
import { organizationActions } from './organization.slice';
import {
  AddEditOrganizationActionPayload,
  OrganizationSpecificActionPayload,
  AddEditOrgUserActionPayload,
  CreateNewOrgUserButtonClickActionPayload,
  ParticularUserDetailsActionPayload,
  OrganizationTableClickAP,
  FetchOrganizationAP,
  AddOrgSuperviseeAP,
  UpdateActiveStatusPayload,
  StartEvenPayload,
  UpdateCPsPayload,
  DomainUsersPayload,
} from './organization.type';
import {
  orgModelFromJSON,
  orgModelToJSON,
  basicOrgDetailsFromJson,
  getDomainAPIPayload,
  PPMarkerInterface,
  OrganizationTypeInterface,
  covertMarkersToGeoJSON,
} from 'models/organization';
import { ASROrgInterface, EventInterface } from 'utils/types';
import { getCurrentLocation } from 'store/route';
import { modalActions } from 'store/modal';
import * as GoogleMapApis from 'services/geoCoding.service';
import { getDefaultUrl } from 'utils/hooks';

import { getAuthenticatedUserId, loginActions } from 'store/login';
import { getActiveEvent } from 'store/event';
import { change } from 'redux-form';
import { OverRideEventInterface } from 'models/event/event.model';
import { getOrgPerimeterPoints, getSelectedOrganization } from '.';
import diff from 'object-diff';

function* getOrganizationsData() {
  try {
    // fake api call
    const categoryObj: CategoryInterface = yield select(getActiveCategory);
    if (!(categoryObj && categoryObj.id)) {
      return;
    }
    const response: ASROrgInterface = yield organizationService.getOrganizationList(
      categoryObj?.id,
    );
    const transformedData = orgModelFromJSON(response?.data);

    yield put(
      organizationActions.fetchOrganizationDataSuccess({
        data: transformedData,
        meta: response?.meta?.pagination,
      }),
    );

    // // update the geo json details
    // yield updateOrgGeoJSONData(transformedData);
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', err);
    showErrorMsg(err);
    yield put(organizationActions.fetchOrganizationDataFailure());
  }
}

function* updateOrgGeoJSONData(transformedOrgData: any) {
  if (transformedOrgData && _.isObject(transformedOrgData)) {
    const emsCp = _.get(transformedOrgData, 'emsCommandPoint', null);
    const geoJSONData = {
      geoFence: _.get(transformedOrgData, 'geoFence.coordinates', []),
      ppPoints: _.get(transformedOrgData, 'perimeterPoints.coordinates', []),
      cpPoints: _.get(transformedOrgData, 'commandPost.coordinates', []),
      emsCp: _.isNull(emsCp) ? null : _.get(emsCp, 'coordinates', []),
      orgPps: _.get(transformedOrgData, 'orgPps', []),
    };

    yield put(organizationActions.updateGeoJSONData(geoJSONData));
  }
}

function* getParticularOrganizationDetails(
  action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
) {
  try {
    // fake api call
    // const response = _.cloneDeep(organizationFakeData) as OrganizationType[];

    const { orgId } = action.payload;

    // @ts-ignore
    const response = yield organizationService.getOrganizationDetails(orgId);
    const transformedData = orgModelFromJSON([response.data]).pop();
    yield put(
      organizationActions.fetchParticularOrganizationDetailsSuccess(
        transformedData,
      ),
    );

    // update the geo json details
    yield updateOrgGeoJSONData(transformedData);
  } catch (err) {
    showErrorMsg(err);
    yield put(organizationActions.fetchParticularOrganizationDetailsFailure());
  }
}

// expecting the org object
function* mapGeoJSONData(orgObj: any) {
  const geoFenceData: PositionInterface[] = yield select(getGeoFencePoints);

  console.log('%c geoFenceData ', 'background: lime; color: black', {
    geoFenceData,
    orgObj,
  });
  // debugger;
  orgObj.geoFence.coordinates = geoFenceData;

  const ppPoints: MarkerObj[] = yield select(getPerimeterPoints);
  orgObj.perimeterPoints.coordinates = ppPoints;

  const cpData: MarkerObj[] = yield select(getCommandPostPoints);
  orgObj.commandPost.coordinates = cpData;

  const orgPPs: PPMarkerInterface[] = yield select(getOrgPerimeterPoints);
  orgObj.orgPps = orgPPs;

  const emsCPs: MarkerObj[] = yield select(getEMSCommandPostPoints);
  if (!_.isNull(emsCPs) && emsCPs.length && _.isNull(orgObj.emsCommandPoint)) {
    orgObj.emsCommandPoint = {
      coordinates: emsCPs,
    };
  } else if (
    !_.isNull(emsCPs) &&
    emsCPs.length &&
    !_.isNull(orgObj.emsCommandPoint)
  ) {
    orgObj.emsCommandPoint.coordinates = emsCPs;
  }

  console.log('%c after <<- ', 'background: black; color: yellow', orgObj);
  return orgObj;
}

function* addOrEditOrganizationsData(
  action: PayloadAction<AddEditOrganizationActionPayload>,
) {
  try {
    const { formValue, id, isGeoJSONRequest, dirtyFields } = action.payload;
    const url: string = yield getBaseUrl();
    // @ts-ignore
    const organizationCategoryObj = yield select(getActiveCategory);
    const currentOrg: OrganizationTypeInterface = yield select(
      getSelectedOrganization,
    );

    let orgObj = _.cloneDeep(formValue);

    if (isGeoJSONRequest) {
      orgObj = yield mapGeoJSONData(orgObj);

      const orgPPs: PPMarkerInterface[] = yield select(getOrgPerimeterPoints);
      const arePPsChanged = _.isEqual(orgPPs, currentOrg.orgPps);

      if (!arePPsChanged) {
        const toBeDeleted = _.differenceBy(currentOrg.orgPps, orgPPs, 'id');

        const toBeDAdded = orgPPs.filter((pp) => !_.isString(pp.id));

        const toBeUpdated = _.differenceBy(
          orgPPs.filter((pp) => _.isString(pp.id)),
          currentOrg.orgPps,
          'position',
        );

        // @ts-ignore
        const resp = yield all(
          toBeDeleted.map((ppObj) => {
            if (ppObj.id)
              return call(organizationService.deletePP, { id: ppObj.id });
          }),
        );

        yield* toBeDAdded.map((ppObj) => {
          if (id)
            return call(organizationService.addPP, {
              id: id,
              body: {
                point: {
                  type: 'Point',
                  coordinates: [ppObj.position.lng, ppObj.position.lat],
                },
              },
            });
        });
        yield* toBeUpdated.map((ppObj) => {
          if (ppObj.id)
            return call(organizationService.editPP, {
              id: ppObj.id,
              body: {
                point: {
                  type: 'Point',
                  coordinates: [ppObj.position.lng, ppObj.position.lat],
                },
              },
            });
        });
      }
    }

    console.log(
      '%c formValue ***************',
      'background: black; color: yellow',
      {
        orgObj,
      },
    );

    // @ts-ignore
    const fullAddress = getFullAddress(orgObj);
    // @ts-ignore
    const defaultCoordinates = yield GoogleMapApis.getGeoCoordinates(
      fullAddress,
    );

    let requestPayload = orgModelToJSON(orgObj, {
      defaultCoordinates: defaultCoordinates,
    });
    if (_.isNull(requestPayload.ems_command_post)) {
      requestPayload = _.omit(requestPayload, 'ems_command_post');
    }
    // @ts-ignore
    requestPayload['organization_type'] = organizationCategoryObj?.id;
    let req = null;
    let actionType = '';
    let msg = '';
    if (id === 'new') {
      actionType = 'new';
      req = organizationService.createOrganization({ body: requestPayload });
      msg = 'Organization Created Successfully';
    } else {
      actionType = 'update';
      const domains = _.get(dirtyFields, 'domains', '');
      if (domains) {
        dirtyFields['domains'] = getDomainAPIPayload(domains);
      }
      if (
        organizationCategoryObj?.logical_name === 'SPECIAL_EVENT' ||
        !requestPayload.domains?.length
      ) {
        requestPayload = _.omit(requestPayload, 'domains');
      }
      req = organizationService.updateOrganization({
        body: isGeoJSONRequest ? requestPayload : dirtyFields,
        id,
      });
      msg = 'Organization Updated Successfully';
    }

    // @ts-ignore
    const response = yield req;
    const transformedData = orgModelFromJSON([response.data]).pop();
    yield put(
      organizationActions.addOrEditOrganizationsAsyncSuccess({
        // @ts-ignore
        updatedValue: transformedData,
        actionType,
      }),
    );
    // update the geo json details
    yield updateOrgGeoJSONData(transformedData);
    const orgId = _.get(response.data, 'id', '');

    if (orgId) {
      let redirectUrl = `${url}/${orgId}`;
      if (isGeoJSONRequest) {
        redirectUrl = `${redirectUrl}/map`;
      }
      yield put(push(redirectUrl));
      yield put(
        organizationActions.fetchOrgSpecificData({
          orgId,
        }),
      );
    }

    showSuccessMsg(msg);
  } catch (err) {
    console.log('%c eerorradankk777 ', 'background: salmon; color: black', err);
    showErrorMsg(err);
    yield put(organizationActions.addOrEditOrganizationsAsyncFailure());
  }
}

function* updateActiveStatus(action: PayloadAction<UpdateActiveStatusPayload>) {
  try {
    const { type, id, status } = action.payload;
    // @ts-ignore
    const response = yield organizationService.updateStatus({
      type,
      id,
      status,
    });
    if (type === 'user') {
      yield put(
        organizationActions.fetchUserDetailsAsyncSuccess(response.data),
      );
      // @ts-ignore
      const loggedInUserId = yield select(getAuthenticatedUserId);
      if (status === STATUS.BLOCK_USER && id === loggedInUserId) {
        localStorageService.logout();
        yield put(loginActions.logoutUser());
        showErrorMsg(`Access to CERA has been removed.`);
      } else {
        showSuccessMsg(
          `${
            type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
          } status updated successfully.`,
        );
      }
    } else {
      yield put(
        organizationActions.fetchParticularOrganizationDetailsSuccess(
          response.data,
        ),
      );
      yield put(organizationActions.updateActiveStatusSuccess());
      showSuccessMsg(
        `${
          type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
        } status updated successfully.`,
      );
    }
  } catch (err) {
    console.log('%c eerorradankk777 ', 'background: salmon; color: black', err);
    showErrorMsg(err);
    yield put(organizationActions.updateActiveStatusFailure());
  }
}

export function* handleOrganizationRowClick(
  action:
    | PayloadAction<OrganizationTableClickAP>
    | { payload: OrganizationTableClickAP },
) {
  try {
    const { categoryType, data } = action.payload;
    let url = '';
    url = getDefaultUrl(categoryType, data.id);
    if (url) {
      yield put(push(url));
    }
    yield fetchOrganizationSpecificData({
      payload: {
        orgId: action.payload.data.id,
        showLoader: false,
      },
    });
  } catch (err) {
    showErrorMsg(err);
  }
}

function* fetchOrganizationSpecificData(
  action:
    | PayloadAction<OrganizationSpecificActionPayload>
    | { payload: OrganizationSpecificActionPayload },
) {
  try {
    // fetching the details of the organization roles and users
    const { showLoader } = action.payload;
    yield put(
      organizationActions.organizationRolesAsyncRequest({
        orgId: action.payload.orgId,
        showLoader: showLoader,
      }),
    );
    yield put(
      organizationActions.organizationUsersAsyncRequest({
        orgId: action.payload.orgId,
        showLoader: showLoader,
      }),
    );
  } catch (err) {
    showErrorMsg(err);
  }
}

export function* getBaseUrl() {
  // @ts-ignore
  const currentLocation: any = yield select(getCurrentLocation);
  let url = '';

  if (isOrgRoute(currentLocation.pathname)) {
    url = `${ORGANIZATION_ROUTES.ORGANIZATION.path}`;
  }

  if (isPoliceDeptRoute(currentLocation.pathname)) {
    url = `${POLICE_DEPT_ROUTES.POLICE_DEPT.path}`;
  }
  if (isEmsRoute(currentLocation.pathname)) {
    url = `${EMS_ROUTES.EMS_ORG_LIST.path}`;
  }
  if (isSpecialEventRoute(currentLocation.pathname)) {
    url = `${SPECIAL_EVENT_ROUTES.SPECIAL_EVENT_LIST.path}`;
  }
  if (isUserRoute(currentLocation.pathname)) {
    url = `${USERS_ROUTES.USERS.path}`;
  }
  console.log('%c url ', 'background: black; color: yellow', {
    url,
    currentLocation,
  });
  return url;
}

function* handleCreateOrganizationButtonClick() {
  try {
    let url: string = yield getBaseUrl();

    url = `${url}/new`;

    yield put(push(url));
  } catch (err) {
    showErrorMsg(err);
  }
}

function* getOrganizationRolesList(
  action: PayloadAction<OrganizationSpecificActionPayload>,
) {
  try {
    // @ts-ignore
    const response = yield organizationService.getOrganizationRoles({
      id: action.payload.orgId,
    });
    yield put(organizationActions.organizationRolesAsyncSuccess(response.data));
  } catch (err) {
    showErrorMsg(err);
    yield put(organizationActions.organizationRolesAsyncFailure());
  }
}
function* getOrganizationUserList(
  action: PayloadAction<OrganizationSpecificActionPayload>,
) {
  try {
    // @ts-ignore
    const response = yield organizationService.getOrganizationUsers({
      id: action.payload.orgId,
    });
    yield put(organizationActions.organizationUsersAsyncSuccess(response));
  } catch (err) {
    showErrorMsg(err);
    yield put(organizationActions.organizationUsersAsyncFailure());
  }
}
function* getParticularUserDetails(
  action: PayloadAction<ParticularUserDetailsActionPayload>,
) {
  try {
    // @ts-ignore
    const response = yield organizationService.getOrgUserDetails({
      userId: action.payload.userId,
    });
    yield put(organizationActions.fetchUserDetailsAsyncSuccess(response.data));
  } catch (err) {
    showErrorMsg(err);
    yield put(organizationActions.fetchUserDetailsAsyncFailure());
  }
}

function* handleCreateOrgUserButtonClick(
  action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
) {
  try {
    const { orgId } = action.payload;
    const baseUrl: string = yield getBaseUrl();
    const url = `${baseUrl}/${orgId}/users/new`;
    yield put(push(url));
  } catch (err) {
    showErrorMsg(err);
  }
}

export function* addOrEditOrgUserData(action: {
  payload: AddEditOrgUserActionPayload;
}) {
  const { formValue, orgId, userId } = action.payload;
  let req = null;
  let msg = '';
  const requestPayload = _.cloneDeep(formValue);
  // @ts-ignore

  if (isCreateAction(userId || '')) {
    // @ts-ignore
    req = organizationService.createOrgUsers({
      body: requestPayload,
      orgId: orgId,
    });
    msg = 'User Created Successfully';
  } else {
    req = organizationService.updateOrgUsers({
      body: requestPayload,
      orgId: orgId,
      userId,
    });
    msg = 'User Updated Successfully';
  }

  // @ts-ignore
  const response = yield req;

  return { response, msg };
}

function* addOrEditOrganizationUser(
  action: PayloadAction<AddEditOrgUserActionPayload>,
) {
  try {
    const baseUrl: string = yield getBaseUrl();
    const { orgId, userId } = action.payload;

    // @ts-ignore
    const { response, msg } = yield addOrEditOrgUserData({
      payload: action.payload,
    });
    const updatedUserId = _.get(response.data, 'id', '');
    yield put(
      organizationActions.addOrEditOrgUserAsyncSuccess({
        updatedValue: response.data,
        actionType: userId,
      }),
    );

    if (updatedUserId) {
      yield put(push(`${baseUrl}/${orgId}/users/${updatedUserId}`));
    }
    showSuccessMsg(msg);
  } catch (err) {
    showErrorMsg(err);
    yield put(organizationActions.addOrEditOrgUserAsyncFailure());
  }
}

const filterAssociatedOrg = (superviseeList: any, orgList: any) => {
  // debugger;

  const removeSuperviseeOrg = (org: any) => {
    const findIndex = _.findIndex(superviseeList.data, (superviseeObj: any) => {
      if (superviseeObj.supervisee?.id === org.id) {
        return true;
      }
      return false;
    });

    if (findIndex === -1) {
      return true;
    }
    return false;
  };

  const filteredOrgList = _.filter(orgList, removeSuperviseeOrg);

  orgList = _.filter(filteredOrgList, (org) => _.isNull(org.archived_at));

  return orgList;
};

function* fetchOrgSuperviseData() {
  try {
    // fake api call
    const orgCategory: CategoryInterface = yield select(
      getOrganizationCategory,
    );
    const seOrgCategory: CategoryInterface = yield select(getSeOrgCategory);
    // @ts-ignore
    const currentLocation: any = yield select(getCurrentLocation);
    const pathname = _.get(currentLocation, 'pathname', null);
    let orgId = '';
    if (pathname) {
      orgId = pathname.split('/')[2];
    }

    if (isCreateAction(orgId)) {
      yield put(organizationActions.fetchOrgSuperviseeDataFailure());
      return;
    }

    // @ts-ignore
    const response = yield all([
      organizationService.getOrgSuperviseeList({
        orgId: orgId,
      }),
      organizationService.getOrganizationList(orgCategory.id),
      organizationService.getOrganizationList(seOrgCategory.id),
    ]);

    const superviseeList = response[0];
    const orgArr = [...response[1].data, ...response[2].data];
    const associatedOrg = filterAssociatedOrg(superviseeList, orgArr);
    // @ts-ignore
    if (superviseeList?.data.length) {
      const orgList = superviseeList?.data?.map((obj: any) => obj.supervisee);

      const transformedData = basicOrgDetailsFromJson(orgList);

      yield put(
        organizationActions.fetchOrgSuperviseeDataSuccess({
          // @ts-ignore
          data: transformedData,
          // @ts-ignore
          associatedOrg: associatedOrg,
          meta: superviseeList?.data?.meta?.pagination,
        }),
      );
      // update the geo json details
      yield updateOrgGeoJSONData(transformedData);
    } else if (associatedOrg?.length) {
      yield put(
        organizationActions.fetchOrgSuperviseeDataSuccess({
          // @ts-ignore
          data: [],
          // @ts-ignore
          associatedOrg: associatedOrg,
          meta: superviseeList?.data?.meta?.pagination,
        }),
      );
    }
  } catch (err) {
    showErrorMsg(err);
    console.log(
      '%c err fetchOrgSuperviseData',
      'background: salmon; color: black',
      { err },
    );
    yield put(organizationActions.fetchOrgSuperviseeDataFailure());
  }
}

function* addOrgSupervisee(action: PayloadAction<AddOrgSuperviseeAP>) {
  try {
    const { asOrgObj, categoryType } = action.payload;
    // fake api call
    // @ts-ignore
    const currentLocation: any = yield select(getCurrentLocation);
    const pathname = _.get(currentLocation, 'pathname', null);
    let orgId = '';
    if (pathname) {
      orgId = pathname.split('/')[2];
    }

    const body = {
      supervisee: { id: asOrgObj?.id },
    };

    // @ts-ignore
    yield organizationService.createOrgSupervisee({
      orgId,
      body,
    });

    yield put(
      organizationActions.fetchOrgSuperviseeDataRequest({
        categoryType,
      }),
    );

    yield put(modalActions.closeModal());
    showSuccessMsg('Organization Added Successfully');
    yield put(organizationActions.addOrgSuperviseeDataSuccess());
  } catch (err) {
    showErrorMsg(err);
    yield put(organizationActions.addOrgSuperviseeDataFailure());
  }
}

function* getCurrentOrgCoordinates(action: any) {
  try {
    const orgObj = action.payload;
    const fullAddress = getFullAddress(orgObj);
    // @ts-ignore
    const currentOrgCoordinates = yield GoogleMapApis.getGeoCoordinates(
      fullAddress,
    );
    yield put(
      organizationActions.getCurrentOrgCoordinatesSuccess(
        currentOrgCoordinates,
      ),
    );
  } catch (error) {
    showErrorMsg(error);
    yield put(organizationActions.getCurrentOrgCoordinatesFailure());
  }
}

function* updateCps(action: PayloadAction<UpdateCPsPayload>) {
  try {
    const activeEvent: OverRideEventInterface = yield select(getActiveEvent);
    const eventId = activeEvent.id;
    organizationService.updateEventCps({
      body: action.payload,
      id: eventId,
    });
    yield put(organizationActions.updateCpsSuccess());
  } catch (error) {
    showErrorMsg(error);
    yield put(organizationActions.updateCpsFailure());
  }
}

function* getDomainUsers(action: PayloadAction<DomainUsersPayload>) {
  try {
    const { id, domain } = action.payload;
    // @ts-ignore
    const response = yield organizationService.getOrganizationUsers({
      id: id,
      domain: domain,
    });
    yield put(organizationActions.getDomainUsersSuccess(response.data));
  } catch (error) {
    showErrorMsg(error);
    yield put(organizationActions.getDomainUsersFailure());
  }
}

function* updateFormDomain(
  action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
) {
  try {
    // @ts-ignore
    const currentLocation: any = yield select(getCurrentLocation);

    if (!isUserRoute(currentLocation.pathname)) {
      const { orgId } = action.payload;
      let form = '';

      if (isOrgRoute(currentLocation.pathname)) {
        form = REDUX_FORM_CONSTANT.ORGANIZATION;
      } else {
        form = REDUX_FORM_CONSTANT.POLICE_DEPT;
      }
      // @ts-ignore
      const response = yield organizationService.getOrganizationDetails(orgId);
      const transformedData = orgModelFromJSON([response.data]).pop();
      yield put(
        organizationActions.fetchParticularOrganizationDetailsSuccess(
          transformedData,
        ),
      );
      yield put(change(form, 'domains', transformedData?.domains));

      // update the geo json details
      yield updateOrgGeoJSONData(transformedData);
    }
  } catch (error) {
    showErrorMsg(error);
    yield put(organizationActions.getDomainUsersFailure());
  }
}

export function* organizationSaga(): Generator {
  yield all([
    takeLatest(
      organizationActions.fetchOrganizationDataRequest,
      getOrganizationsData,
    ),
    takeLatest(
      organizationActions.fetchParticularOrganizationDetailsRequest,
      getParticularOrganizationDetails,
    ),
    takeLatest(
      organizationActions.addOrEditOrganizationsAsyncRequest,
      addOrEditOrganizationsData,
    ),
    takeLatest(
      organizationActions.OrganizationTableClick,
      handleOrganizationRowClick,
    ),
    takeLatest(
      organizationActions.createNewOrganizationButtonClick.type,
      handleCreateOrganizationButtonClick,
    ),
    takeLatest(
      organizationActions.organizationRolesAsyncRequest,
      getOrganizationRolesList,
    ),
    takeLatest(
      organizationActions.organizationUsersAsyncRequest,
      getOrganizationUserList,
    ),
    takeLatest(
      organizationActions.fetchUserDetailsAsyncRequest,
      getParticularUserDetails,
    ),
    takeLatest(
      organizationActions.createNewOrgUserButtonClick.type,
      handleCreateOrgUserButtonClick,
    ),
    takeLatest(
      organizationActions.addOrEditOrgUserAsyncRequest,
      addOrEditOrganizationUser,
    ),
    takeLatest(
      organizationActions.fetchOrgSpecificData,
      fetchOrganizationSpecificData,
    ),
    takeLatest(
      organizationActions.fetchOrgSuperviseeDataRequest,
      fetchOrgSuperviseData,
    ),
    takeLatest(
      organizationActions.addOrgSuperviseeDataRequest,
      addOrgSupervisee,
    ),
    takeLatest(
      organizationActions.updateActiveStatusRequest,
      updateActiveStatus,
    ),
    takeLatest(
      organizationActions.getCurrentOrgCoordinatesRequest,
      getCurrentOrgCoordinates,
    ),
    takeLatest(organizationActions.updateCpsRequest, updateCps),
    takeLatest(organizationActions.getDomainUsersRequest, getDomainUsers),
    takeLatest(organizationActions.updateFormDomainRequest, updateFormDomain),
  ]);
}
