import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ColorMode = 'light' | 'dark';

function getInitialColorMode(): ColorMode {
  const preferDarkQuery = '(prefers-color-scheme: dark)';
  const mediaQuery = window.matchMedia(preferDarkQuery);

  const isDarkModeSystem = mediaQuery.matches;

  return (
    (localStorage.getItem('colorMode') as ColorMode) ||
    (isDarkModeSystem ? 'dark' : 'light')
  );
}

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    colorMode: getInitialColorMode(),
  },
  reducers: {
    setTheme: (state, action: PayloadAction<ColorMode>) => {
      state.colorMode = action.payload;
    },
  },
});

export const themeActions = themeSlice.actions;

export default themeSlice.reducer;
