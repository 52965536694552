import {
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { INJURED_PROFILE_PROPERTIES, Profile } from 'utils';
import { useMapContainerStyle } from './style';
import { COLORS } from 'styles/colorConstants';
import _ from 'lodash';
import { ClickableSuspectCharsComp } from 'components/General/SuspectProperties';
import { CustomButton } from 'components/General/CustomButton';
import { useDispatch } from 'react-redux';
import { eventActions } from 'store/event/event.slice';
import { useDrop } from 'react-dnd';
import MenuIcon from '../../assets/ellipsis.svg';
import {
  CheckCircleOutline,
  RadioButtonUnchecked,
  Image,
} from '@material-ui/icons';

export function InjuredProfile(props: { profile: Profile; index: number }) {
  const { profile, index } = props;
  const classes = useMapContainerStyle();
  const dispatch = useDispatch();
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'report',
    drop: () => ({ name: profile.id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [comment, setComment] = useState(
    profile.comment ? profile.comment : '',
  );
  // const [isExpanded, setExpand] = useState(false);
  const [severity, setSeverity] = useState<'RED' | 'GREEN' | 'YELLOW' | null>(
    profile.severity,
  );

  const handleSelectSeverity = (
    key: 'RED' | 'GREEN' | 'YELLOW',
    event: string,
  ) => {
    setSeverity(key);
  };

  const handleSave = () => {
    const body: { [key: string]: string | null } = { severity };
    if (comment) {
      body['comment'] = comment;
    }

    const jsonData: { [key: string]: string | null } = {};

    jsonData['data'] = JSON.stringify(body);

    dispatch(
      eventActions.updateProfilesRequest({
        id: profile.id,
        body: jsonData,
      }),
    );
    // setExpand(false);
  };

  const getAppropriateColor = (color: string) => {
    switch (color) {
      case 'RED':
        return COLORS.PRIORITY_RED;
      case 'YELLOW':
        return COLORS.PRIORITY_YELLOW;
      default:
        return COLORS.GREEN_ACCENT;
    }
  };
  const handleComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };
  // const handleExpand = () => {
  //   setExpand((prev) => !prev);
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResolve = () => {
    const body = { resolved_at: profile.resolved_at ? null : new Date() };
    const jsonData: { [key: string]: string | null } = {};
    jsonData['data'] = JSON.stringify(body);
    dispatch(
      eventActions.updateProfilesRequest({
        id: profile.id,
        body: jsonData,
      }),
    );
    handleClose();
  };

  return (
    <div
      ref={drop}
      className={`${classes.tunaBgColor} ${classes.injuredProfileCtn}`}
    >
      <div
        // onClick={handleExpand}
        // role="button"
        // tabIndex={0}
        // onKeyDown={handleExpand}
        style={{
          backgroundColor: severity
            ? getAppropriateColor(severity)
            : COLORS.DISABLED_REPORT,
        }}
        className={`${classes.injuredHeader} ${classes.padding} ${classes.outlineNone}`}
      >
        <div className={classes.profileSubCtn}>
          <Typography
            style={{
              fontSize: '14px',
              color: severity === 'RED' ? COLORS.WHITE : COLORS.RAISIN_BLACK,
            }}
            className={`${classes.headerText} `}
            data-cy={`injured-profile-text`}
          >
            Injured Person #{profile.index}
          </Typography>
          <IconButton
            data-cy={`injured-menu`}
            style={{ padding: 0 }}
            onClick={handleClick}
          >
            <img
              src={MenuIcon}
              alt="logo"
              style={{
                width: '22px',
              }}
            />
          </IconButton>
          <Menu
            className={classes.menuCtnBg}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuItem onClick={handleResolve}>
              {profile.resolved_at ? (
                <CheckCircleOutline
                  style={{ marginRight: 8 }}
                  className={classes.persianGreenColor}
                />
              ) : (
                <RadioButtonUnchecked
                  style={{ marginRight: 8 }}
                  className={classes.persianGreenColor}
                />
              )}
              {profile.resolved_at ? 'Activate' : 'Resolve'}
            </MenuItem>
          </Menu>
        </div>

        {severity && (
          <Typography
            style={{
              color: severity === 'YELLOW' ? COLORS.RAISIN_BLACK : COLORS.WHITE,
            }}
            className={classes.severityText}
            data-cy={`selected-priority`}
          >
            {INJURED_PROFILE_PROPERTIES[severity].toUpperCase()}
          </Typography>
        )}
      </div>
      <div className={`${classes.suspectPropertiesCtn} ${classes.padding}`}>
        {(Object.keys(INJURED_PROFILE_PROPERTIES) as Array<
          keyof typeof INJURED_PROFILE_PROPERTIES
        >).map((label) => (
          <ClickableSuspectCharsComp
            key={`${label}-${index}`}
            label={INJURED_PROFILE_PROPERTIES[label]}
            propertyKey={label}
            onClick={handleSelectSeverity}
            isActive={label === severity}
            properties={severity ? [severity] : []}
            color={getAppropriateColor(label)}
          />
        ))}
      </div>
      {/* <Collapse in={isExpanded}> */}
      <Typography className={`${classes.headerText} ${classes.padding}`}>
        Comments
      </Typography>

      <TextareaAutosize
        value={comment}
        className={`${classes.tunaBgColor} ${classes.comment}`}
        rowsMin={5}
        onChange={handleComment}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '8px',
          marginBottom: '16px',
        }}
      >
        <CustomButton
          onClick={handleSave}
          datacy="save"
          error={false}
          label="Save"
        />
      </div>
      {/* </Collapse> */}
    </div>
  );
}

export default InjuredProfile;
