import { all, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { push, replace } from 'connected-react-router';
import { loginActions } from './login.slice';
import { appActions, getAllCategory } from 'store/app';
import { AuthActionPayloadInterface } from './login.type';
import * as EventServices from 'services/event.services';
import { showErrorMsg } from 'utils/helper';
import {
  EMS_COMMANDER_UNIQUE_SCOPES,
  ORG_ADMIN_SCOPES,
  POLICE_COMMANDER_UNIQUE_SCOPES,
  SCOPES,
} from 'utils/constants/common';
import { AREventInterface, AuthResponseInterface } from 'utils/types';
import * as loginModel from 'models/login';
import * as authService from 'services/auth.service';
import * as authorisedService from 'services/authorised.service';
import * as localStorageService from 'services/localStorage.service';
import { getAuthenticatedUserOrgName, haveValidScope } from 'utils/hooks';
import {
  getAuthenticatedUserOrg,
  getAuthenticatedUserOrgType,
} from './login.selector';
import { getAppCategory } from '../app/app.saga';
import {
  handleOrganizationRowClick,
  OrganizationTableClickAP,
} from 'store/organization';
import { AUTH_SCOPES } from 'utils';
import { DEFAULT_LANDING_ROUTE, OPS_CENTER_ROUTES } from 'routes/path';
import { eventActions } from 'store/event/event.slice';
import { webSocketActions } from 'store/websocket';
import { basicEventDetailsFromJson } from 'models/event/event.model';
import { getStartToken } from 'services/firebase.service';
import { getIPAddress } from 'services/geoCoding.service';

const checkIsUserAuthorised = (scope: string): boolean => {
  const receivedScopesList = scope.split(' ');
  const scopes = AUTH_SCOPES.split(' ');
  if (_.intersection(receivedScopesList, scopes).length === 0) {
    return false;
  }
  return true;
};

function* saveAccessTokenToLocalStorage(action: any) {
  const response = action.payload;

  const parsedResponse = loginModel.authFromJSON(response);

  const lsData = { ...parsedResponse.data, permission: '' };
  const haveScopes = checkIsUserAuthorised(parsedResponse.data.scope);

  if (haveScopes) {
    localStorageService.saveAuthObject(lsData);
    const userData = yield authorisedService.getAuthUser();
    yield put(appActions.hideSpinner());
    yield put(loginActions.authAsyncSuccess(userData.data));
    yield getAppCategory();
    const allCategories = yield select(getAllCategory);
    const authOrgType = yield select(getAuthenticatedUserOrgType);
    const authOrg = yield select(getAuthenticatedUserOrg);
    const authOrgTypeName = getAuthenticatedUserOrgName(
      authOrgType,
      allCategories,
    );

    if (authOrgTypeName) {
      // Non cera admin cases
      if (haveValidScope(SCOPES.EVENT_READ)) {
        if (haveValidScope(ORG_ADMIN_SCOPES)) {
          // ONLY ADMIN
          const payload: OrganizationTableClickAP = {
            categoryType: authOrgTypeName,
            data: authOrg,
          };
          yield handleOrganizationRowClick({
            payload,
          });
        } else if (
          haveValidScope(
            `${EMS_COMMANDER_UNIQUE_SCOPES} ${POLICE_COMMANDER_UNIQUE_SCOPES}`,
          )
        ) {
          // ONLY POLICE COMMANDER, EMS_COMMANDER
          const response: AREventInterface = yield EventServices.getEventsService(
            0,
            0,
          );
          const { data, meta } = response;
          if (meta?.event?.has_joined) {
            const transformEvent = basicEventDetailsFromJson(data);
            yield put(
              eventActions.successActiveEvent({
                onGoingEvent: transformEvent[0],
                eventList: null,
              }),
            );
            toast.success('Login Success');
            yield put(replace(OPS_CENTER_ROUTES.OPS_CENTER.path));
            yield put(push(`/events/${data[0].id}`));
          } else {
            // Change to send to event list
            yield put(push('/events'));
          }
        }
      } else {
        toast.success('Login Success');
        const payload: OrganizationTableClickAP = {
          categoryType: authOrgTypeName,
          data: authOrg,
        };
        yield handleOrganizationRowClick({
          payload,
        });
      }
    } else {
      toast.success('Login Success');
      // Cera admin
      yield put(replace(DEFAULT_LANDING_ROUTE.path));
    }

    let token = yield getStartToken();
    if (_.isNull(token)) {
      token = yield getStartToken();
    }
    if (token) {
      yield authorisedService.sendAuthToken({
        device_id: token,
      });
    }
  } else {
    yield put(appActions.hideSpinner());
    toast.error('Permission not granted');
  }
}

function* fetchDeviceIpAddress() {
  try {
    // @ts-ignore
    const ipAddress = yield getIPAddress();
    yield put(loginActions.fetchedIpAddressAsyncSuccess(ipAddress));
  } catch (err) {
    yield put(loginActions.authAsyncFailure());
    console.log('fetchDeviceIpAddress error', err);
    showErrorMsg(err);
  }
}

function* authenticateUser(action: PayloadAction<AuthActionPayloadInterface>) {
  try {
    yield put(appActions.showSpinner());
    yield put(loginActions.fetchIpAddressAsyncRequest());
    const response: AuthResponseInterface = yield authService.authenticateUser(
      action.payload,
      'password',
    );
    yield saveAccessTokenToLocalStorage({ payload: response });

    yield put(webSocketActions.startConnecting());
  } catch (err) {
    const meta = {
      path: '',
    };
    if (_.get(err, 'isError', true)) {
      meta.path = 'data.errorMessage';
    }
    showErrorMsg(err, meta);
    yield put(appActions.hideSpinner());
  }
}
export function* isAuth() {
  try {
    const isUserAuthenticated = localStorageService.isAuthenticated();
    if (isUserAuthenticated) {
      const userData = yield authorisedService.getAuthUser();
      yield put(loginActions.setAuth(userData.data));
    }
  } catch (e) {
    if (e?.statusCode === 401) {
      try {
        const response: AuthResponseInterface = yield authService.authenticateUser(
          null,
          'refresh_token',
        );
        yield saveAccessTokenToLocalStorage({ payload: response });
      } catch (err) {
        if (!err?.isSuccess) {
          localStorageService.logout();
          yield put(loginActions.logoutUser());
        }
      }
    }
  }
}

export function* loginSaga(): Generator {
  yield all([
    takeLatest(loginActions.authAsyncRequest, authenticateUser),
    takeLatest(loginActions.isAuthenticated, isAuth),
    takeLatest(loginActions.fetchIpAddressAsyncRequest, fetchDeviceIpAddress),
  ]);
}
