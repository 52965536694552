import { Typography } from '@material-ui/core';
import {
  CarouselComponent,
  SuspectCharsComp,
  SuspectColoredCharsComp,
} from 'components/General/SuspectProperties';
import React from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { eventActions } from 'store/event/event.slice';
import { PersonDescription, ReportInterface } from 'utils';
import { useMapContainerStyle } from './style';
interface ColoredProperty {
  label: string;
  value: string;
}

export function SuspectReportDetails(props: {
  personDescription: PersonDescription;
  index: number;
  report: ReportInterface;
}) {
  const { personDescription, index, report } = props;
  const classes = useMapContainerStyle();
  const {
    build,
    gender,
    hair,
    height,
    skin,
    shirt,
    weapon,
  } = personDescription;
  const coloredProperties: ColoredProperty[] = [
    {
      label: 'skin',
      value: skin,
    },
    {
      label: 'hair',
      value: hair,
    },
    {
      label: 'shirt',
      value: shirt,
    },
  ];

  const dispatch = useDispatch();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'report',
    item: { name: personDescription.id },
    canDrag: () => {
      return !Boolean(report.processed_at);
    },
    end: (item, monitor) => {
      const dropResult: any = monitor.getDropResult();
      if (item && dropResult) {
        dispatch(
          eventActions.addPersonDescriptionRequest({
            profileId: dropResult?.name,
            personDescriptionId: personDescription.id,
          }),
        );
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: !report.processed_at ? 'move' : 'default',
      }}
      className={`${
        report.processed_at
          ? classes.disabledReportBgColor
          : classes.raisinBgColor
      } ${classes.tunaBgColor} ${classes.suspectReportCtn}`}
    >
      <Typography className={classes.headerText}>
        Reported Threat {index + 1}
      </Typography>
      {personDescription.files.length > 0 && (
        <CarouselComponent imgList={personDescription.files} />
      )}

      <div className={classes.suspectPropertiesCtn}>
        {[build, gender, height, weapon].map((property) => {
          if (property) {
            return <SuspectCharsComp label={property} />;
          }
        })}
        {coloredProperties.map((property, index) => {
          if (property.value) {
            return (
              <SuspectColoredCharsComp
                key={`${personDescription.id}-${index}-${property.value}`}
                label={property.label}
                value={property.value}
                id={personDescription.id}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

export default SuspectReportDetails;
