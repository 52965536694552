import PageBody from 'container/PageBody';
import React, { Fragment } from 'react';
import { useRouteMatch } from 'react-router';
import { EventDetailScreen } from './EventDetail';
import { OPS_CENTER_ROUTES } from 'routes/path';
import { isRouteMatch } from 'utils';
import EventListScreen from './EventList';
export const Event: React.FC = () => {
  const currentRoute = useRouteMatch();

  return (
    <Fragment>
      <PageBody>
        {isRouteMatch(OPS_CENTER_ROUTES.OPS_CENTER, currentRoute.url) && (
          <EventDetailScreen />
        )}
        {isRouteMatch(OPS_CENTER_ROUTES.EVENT_LIST, currentRoute.url) && (
          <EventListScreen />
        )}
      </PageBody>
    </Fragment>
  );
};
