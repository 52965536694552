import React from 'react';
import { OverlayView } from '@react-google-maps/api';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
export const useDeleteBtnStyle = makeStyles((theme: Theme) => {
  return createStyles({
    deleteMenu: {
      // position: 'absolute',
      background: 'white',
      padding: '3px',
      color: '#666',
      fontWeight: 'bold',
      borderBottom: '1px solid #999',
      fontSize: '12px',
      boxShadow: '1px 3px 3px rgb(0 0 0 / 30%)',
      // marginTop: '-10px',
      // marginLeft: '10px',
      cursor: 'pointer',
    },
  });
});

interface DeleteBtnProps {
  position: any;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  onClickDeleteAll: (e: React.MouseEvent<HTMLElement>) => void;
}

export const DeleteButton = (props: DeleteBtnProps) => {
  const classes = useDeleteBtnStyle();
  const { onClick, position, onClickDeleteAll } = props;
  console.log('%c position ', 'background: black; color: yellow', { position });
  return (
    <OverlayView mapPaneName="overlayMouseTarget" position={position}>
      <div>
        <div
          onClick={onClick}
          aria-hidden="true"
          className={classes.deleteMenu}
        >
          {' '}
          Delete{' '}
        </div>
        <div
          onClick={onClickDeleteAll}
          aria-hidden="true"
          className={classes.deleteMenu}
        >
          {' '}
          Delete All{' '}
        </div>
      </div>
    </OverlayView>
  );
};
