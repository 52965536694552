/* eslint-disable prettier/prettier */
import { CategoryInterface } from 'store/app';
import { ASRAuthUserInterface } from 'utils';
import { API_SERVICE_TYPES } from 'utils/constants';

import { apiService } from './module/api.service';

export const getUsers = async (): Promise<any> => {
  return <Promise<CategoryInterface>>apiService('/user', API_SERVICE_TYPES.GET);
};

export const getAuthUser = async (): Promise<ASRAuthUserInterface> => {
  return <Promise<ASRAuthUserInterface>>(
    apiService(`/me`, API_SERVICE_TYPES.GET)
  );
};

export const sendAuthToken = async (body: {
  device_id: string;
}): Promise<any> => {
  return <Promise<any>>apiService(`/user_device`, API_SERVICE_TYPES.POST, body);
};
