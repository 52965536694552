import { Collapse, IconButton, Typography } from '@material-ui/core';
import { FiberManualRecordRounded } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { eventActions } from 'store/event/event.slice';
import {
  ReportInterface,
  REPORT_TYPE,
  PersonDescription,
  getTimeFormatted,
} from 'utils';
import { videoCallActions } from 'store/videoCall';
import VideoCallIcon from '../../assets/video.svg';
import SuspectReportDetails from './ReportSuspect';
import { useMapContainerStyle } from './style';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export function Reports(props: { report: ReportInterface; index: number }) {
  const { report, index } = props;

  const classes = useMapContainerStyle();
  const dispatch = useDispatch();
  const isInjured = report.report_type.logical_name === REPORT_TYPE.INJURED;
  const [showPersonDesc, setPersonDesc] = useState(false);

  const reportCreatedAt = dayjs(report.created_at);
  const now = dayjs();

  const [isCreatedRecently, setIsCreatedRecently] = useState(
    now.diff(reportCreatedAt, 'minute') < 1,
  );

  if (isCreatedRecently) {
    console.log('Setting setTimeout for report id', report.id);
    setTimeout(() => {
      console.log('Setting report time string');
      setIsCreatedRecently(false);
    }, 60000);
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'report',
    item: { name: report.id },
    canDrag: () => {
      return isInjured && !report.processed_at;
    },
    end: (item, monitor) => {
      const dropResult: any = monitor.getDropResult();
      if (item && dropResult) {
        dispatch(
          eventActions.addPersonDescriptionRequest({
            profileId: dropResult?.name,
            personDescriptionId: report.person_descriptions[0].id,
          }),
        );
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const handleClick = () => {
    dispatch(
      videoCallActions.startOutgoingCall({
        receiverId: report.reporting_user.id,
        reportId: report.id,
      }),
    );
  };
  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging && isInjured ? 0.5 : 1,
        cursor: isInjured && !report.processed_at ? 'move' : 'default',
      }}
      className={`${
        report.processed_at
          ? classes.disabledReportBgColor
          : classes.raisinBgColor
      } ${classes.reportCtn}`}
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => setPersonDesc((prev) => !prev)}
        aria-label="Show more"
        onClick={() => setPersonDesc((prev) => !prev)}
        className={classes.outlineNone}
      >
        {isCreatedRecently ? (
          <Typography className={classes.justNow}>
            <FiberManualRecordRounded fontSize="inherit" />
            <span className={classes.justNowText}>Just Now</span>
          </Typography>
        ) : (
          <Typography className={classes.date}>
            {getTimeFormatted(report.created_at)}
          </Typography>
        )}
        <Typography className={classes.reportType}>
          {report.report_type.name === 'Suspect'
            ? 'Threat'
            : report.report_type.name}{' '}
          Report #{index + 1}
        </Typography>
        <Typography className={classes.reportUser}>
          {report.reporting_user.first_name} {report.reporting_user.last_name} -{' '}
          {report.reporting_user.role.name}
        </Typography>
      </div>

      <div className={classes.vcCtn}>
        {
          <Typography className={classes.vcText}>
            Last call at{' '}
            {report.last_video_call_ended_at
              ? getTimeFormatted(report.last_video_call_ended_at)
              : `--:--`}
          </Typography>
        }

        <IconButton
          className={classes.vc}
          color="primary"
          aria-label="Make vc"
          onClick={handleClick}
        >
          <img src={VideoCallIcon} className={classes.vc} alt="Video icon" />
        </IconButton>
      </div>
      <Collapse in={showPersonDesc} unmountOnExit>
        {!isInjured &&
          report.person_descriptions.length > 0 &&
          // @ts-ignore
          report.person_descriptions?.map(
            (personDescObj: PersonDescription, descIndex: number) => (
              <SuspectReportDetails
                key={personDescObj.id}
                personDescription={personDescObj}
                index={descIndex}
                report={report}
              />
            ),
          )}
      </Collapse>
    </div>
  );
}

export default Reports;
