import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { WrappedFieldProps } from 'redux-form';
import { COLORS } from 'styles/colorConstants';
interface TextProps {
  type: string;
  id?: string;
  label: string;
  placeHolder?: string;
  value: any;
  required?: boolean;
  disable?: boolean;
  name?: string;
}

const useTextStyle = makeStyles((theme: Theme) =>
  createStyles({
    textFieldContainer: {
      width: '100%',
      marginBottom: theme.spacing(6),
    },
    textField: {
      width: '100%',
      'input:-internal-autofill-selected': {
        backgroundColor: 'red !important',
      },
      '& .MuiInputBase-root.Mui-disabled': {
        color: COLORS.DISABLE_COLOR,
      },
      '& .MuiFormLabel-root.Mui-disabled': {
        color: COLORS.DISABLE_COLOR,
      },
    },
  }),
);

export const Text = (field: WrappedFieldProps & TextProps) => {
  const classes = useTextStyle();

  return (
    <Box className={classes.textFieldContainer}>
      <TextField
        className={classes.textField}
        type={field.type}
        {...field.input}
        placeholder={field.placeHolder}
        label={field.label}
        required={field.required}
        id={field.id || ''}
        disabled={field.disable}
      />
      {field.meta.touched && (
        <FormHelperText data-cy={`error-${field.label}`} error={true}>
          {field.meta.error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default Text;
