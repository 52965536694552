import _ from 'lodash';
import { RootState } from '../index';
import { REDUX_FORM_CONSTANT } from 'utils/constants';

export const getPoliceDeptData = (state: RootState) => state.policeDept.list;

export const getPoliceDeptFormValue = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.POLICE_DEPT}.values`, {});

export const isPoliceDeptFormTouched = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.POLICE_DEPT}.anyTouched`, false);

export const getPoliceDeptFormErrors = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.POLICE_DEPT}.syncErrors`, undefined);

// export const getPoliceDeptFormValue = (state: RootState) =>
//   _.get(state, `form.${REDUX_FORM_CONSTANT.ORGANIZATION}.values`, {});

// export const getSelectedOrganization = (state: RootState) =>
//   _.get(state, `organization.currentOrganization`, null);

// export const getPoliceDeptUsers = (state: RootState) =>
//   _.get(state, `organization.users`, []);

// export const getSelectedUsers = (state: RootState) =>
//   _.get(state, `organization.selectedUser`, {});

// export const getPoliceDeptRoles = (state: RootState) =>
//   _.get(state, `organization.currentOrganizationRoles`, []);

// export const getOrgUserFormValue = (state: RootState) =>
//   _.get(state, `form.${REDUX_FORM_CONSTANT.ORG_USER}.values`, {});
