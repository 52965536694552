import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Typography,
  TextField,
  withStyles,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import appLogo from '../../assets/primary-logo-white.png';
import checkIcon from '../../assets/circle-check.svg';
import { useResetPasswordStyles } from './styles';
import { COLORS } from 'styles/colorConstants';
import { CustomButton } from 'components/General/CustomButton';
import { getIsPasswordResetSuccess, usersActions } from 'store';
import { verticalScale } from 'utils/dimensions';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

const CustomTextfield = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: COLORS.WHITE,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderBottomColor: COLORS.WHITE,
      },
      '&.Mui-focused fieldset': {
        borderBottomColor: COLORS.WHITE,
      },
    },
    marginBottom: verticalScale(4),
  },
})(TextField);

export const ResetPassword: React.FC = () => {
  const classes = useResetPasswordStyles();
  const dispatch = useDispatch();
  const isPasswordReset = useSelector(getIsPasswordResetSuccess);
  const [text, setText] = useState('');
  const [password, setPassword] = useState({
    new: '',
    confirm: '',
  });
  const [error, setError] = useState(false);
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('reset_token');

  const showErrorMessage = (msg: string) => {
    toast.error(msg, {
      position: 'top-center',
      style: { marginTop: '60px' },
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password.new && password.confirm) {
      if (text) {
        setError(true);
        showErrorMessage('Password is not valid.');
      } else if (password.new !== password.confirm) {
        setError(true);
        showErrorMessage('Passwords do not match. Please try again.');
      } else if (token) {
        dispatch(
          usersActions.resetPasswordRequest({
            token,
            password: password.new,
          }),
        );
      }
    } else {
      setError(true);
      showErrorMessage('Please fill both the fields.');
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setPassword({
      ...password,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const validatePassword = (password: string): boolean => {
    return Boolean(
      password.match(/^((?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,})$/),
    );
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const isValidated = validatePassword(password.new);
    if (!isValidated) {
      setError(true);
      setText(
        'Password must contain at least 8 characters, a special character, and a numeric character',
      );
    } else {
      setError(false);
      setText('');
    }
  };
  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoCtn}>
            <img src={appLogo} alt="Cera Logo" className={classes.logo} />
          </div>
        </Toolbar>
      </AppBar>

      {isPasswordReset ? (
        <div className={classes.successCtn}>
          <img src={checkIcon} alt="done" className={classes.resetIcon} />
          <Typography className={classes.successText} variant="body1">
            Your password has been reset successfully
          </Typography>
        </div>
      ) : (
        <Fragment>
          <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.formContainer}
              >
                <div className={classes.titleText} id="body">
                  <Typography variant="body1">
                    Please enter your new password
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <CustomTextfield
                    name="new"
                    type="password"
                    id="new_password"
                    placeholder="New password"
                    onChange={onChange}
                    fullWidth
                    onBlur={onBlur}
                    error={error}
                  />
                  <span className={classes.text}>{text}</span>
                  <CustomTextfield
                    name="confirm"
                    type="password"
                    id="confirm_password"
                    placeholder="Confirm password"
                    fullWidth
                    onChange={onChange}
                    error={error}
                    className={classes.btmMargin}
                  />
                </div>
                <div data-cy="btn" className={classes.btn}>
                  <CustomButton label="Confirm" />
                </div>
              </Grid>
            </form>
          </Container>
        </Fragment>
      )}
    </div>
  );
};
