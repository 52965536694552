import _ from 'lodash';
import { isNilOrEmpty } from 'utils/helper';
import { OrgInterface } from 'utils/types/org';

export const getFullAddress = (org: OrgInterface) => {
  if (isNilOrEmpty(org)) {
    return '';
  }

  const addressArr = [
    // org.name,
    org.street_1,
    org.street_2,
    org.city,
    org.country?.name,
    org.postal_code,
  ];

  console.log('%c addressArr -->  ', 'background: black; color: yellow', {
    addressArr,
  });

  const computeAddress = (address: any, field: any) => {
    if (isNilOrEmpty(field)) {
      return address;
    }
    address = `${address} ${field}`;
    return address;
  };

  const fullAddress = _.reduce(addressArr, computeAddress, '');

  console.log('%c fullAddress -->  ', 'background: black; color: yellow', {
    fullAddress,
  });

  return fullAddress;
};
