import React, { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _, { isNull } from 'lodash';
import { PageBody } from 'container/PageBody';
import OrganizationTable from 'components/OrganizationTable';
import OrganizationFormController from './OrganizationFormController';
import OrganizationUsers from './UserList';
import UserForm from './UserForm';
import NavigationSection from '../../components/SubHeader/NavigationSection';

import { RenderMap } from './Map';
import { ORGANIZATION_ROUTES } from '../path';
import { organizationActions } from '../../store/organization';
import {
  getAppRoute,
  checkSubHeaderSectionVisibility,
  updateActionButtonVisibility,
} from './helper';

import {
  isRouteMatch,
  findActiveRoute,
  compareFormObjects,
  getUpdatedFields,
} from 'utils/helper';

import {
  getSelectedOrganization,
  getOrganizationFormValue,
  getSelectedUsers,
  getOrgUserFormValue,
  getOrgUserFormErrors,
  isOrganizationDeptFormTouched,
  getCommandPostPoints,
  getGeoFencePoints,
  getPerimeterPoints,
  getOrgPerimeterPoints,
} from 'store/organization/organization.selectors';
import {
  ActionButtonInterface,
  CombinedActionButtonInterface,
  AppCategory,
} from 'utils/commonTypes';
import {
  ORG_NESTED_LINKS,
  ORG_ACTION_BUTTON,
} from 'routes/Organization/utils/constants';
import { getUserForms, useBreadCrumb } from 'utils/hooks/common.hook';
import {
  ORG_FORM_KEYS,
  REDUX_FORM_CONSTANT,
  STATUS,
  USER_FORM_KEYS,
  WARNING_MESSAGE,
} from 'utils';
import { toast } from 'react-toastify';
import {
  getOrganizationDeptFormErrors,
  getPoliceDeptFormValue,
  isPoliceDeptFormTouched,
} from 'store';
import { destroy, submit } from 'redux-form';
import { QrCodePreview } from './QrCodePreview';
// import { useFlags } from 'launchdarkly-react-client-sdk';
// import { LAUNCH_DARKLY_KEYS } from 'utils/constants/ldKeys';

export const Organization: React.FC = () => {
  const defaultSubHeaderSection = {
    leftSection: true,
    middleSection: false,
    rightSection: false,
  };
  const [, appRouteObj] = getAppRoute(ORGANIZATION_ROUTES);

  const selectedUser = useSelector(getSelectedUsers);
  const selectedOrganization = useSelector(getSelectedOrganization);
  const isOrgArchieved = !isNull(selectedOrganization?.archived_at);

  const currentRoute = useRouteMatch();
  console.log('%c history ', 'background: black; color: yellow', {
    currentRoute,
  });
  const dispatch = useDispatch();
  const [activeSubHeaderSection, setSubHeaderSection] = useState(
    defaultSubHeaderSection,
  );

  // const flags = useFlags();
  // const qrCodeFeatureFlag: boolean =
  //   flags[LAUNCH_DARKLY_KEYS.REGISTER_USING_QR_CODE];
  const nestedLinks: any = { ...ORG_NESTED_LINKS };
  // if (!qrCodeFeatureFlag || isOrgArchieved) {
  if (isOrgArchieved) {
    delete nestedLinks['qrCode'];
  }

  // const [breadcrumb, setBreadCrumb] = useState<BreadcrumbDataProps[] | []>([]);
  const breadcrumb = useBreadCrumb(selectedOrganization, selectedUser);
  const [
    actionButton,
    setActionButton,
  ] = useState<CombinedActionButtonInterface>(ORG_ACTION_BUTTON);

  const addOrEditOrganizationFormValue = useSelector(getOrganizationFormValue);
  const addOrEditUserFormValue = useSelector(getOrgUserFormValue);
  const addOrEditUserFormErrors = useSelector(getOrgUserFormErrors);
  // const currentOrgDataForMap = useSelector(getSelectedOrganization);
  const addOrEditOrgDeptFormErrors = useSelector(getOrganizationDeptFormErrors);
  const isPoliceFormTouched = useSelector(isPoliceDeptFormTouched);
  const isOrgFormTouched = useSelector(isOrganizationDeptFormTouched);
  const commandPosts = useSelector(getCommandPostPoints);
  const geoFence = useSelector(getGeoFencePoints);
  const perimeterPoints = useSelector(getPerimeterPoints);
  const orgPerimeterPoints = useSelector(getOrgPerimeterPoints);
  // const isUserTouched = isUserFormTouched();

  useEffect(() => {
    // @ts-ignore
    if (_.isNull(selectedOrganization) && currentRoute.params?.id) {
      dispatch(
        organizationActions.fetchParticularOrganizationDetailsRequest({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    const appActiveRoute = findActiveRoute(
      ORGANIZATION_ROUTES,
      currentRoute.url,
    );
    dispatch(organizationActions.setCurrentAppRoute(appActiveRoute));

    //  set the updateActionButtonVisibility
    // const newBreadCrumb: BreadcrumbDataProps[] = generateBreadCrumb(
    //   currentRoute.url,
    //   history,
    // );
    // setBreadCrumb(newBreadCrumb);

    // set the visiblity and navigation Icons

    const subHeaderSectionVisibility = checkSubHeaderSectionVisibility(
      appActiveRoute,
      ORGANIZATION_ROUTES,
    );
    setSubHeaderSection(subHeaderSectionVisibility);

    const updatedActionButton = updateActionButtonVisibility(
      ORG_ACTION_BUTTON,
      appActiveRoute,
      selectedUser,
      selectedOrganization,
    );
    setActionButton(updatedActionButton);
  }, [currentRoute.url, selectedUser, selectedOrganization]);

  const handleActionButtonClick = (actionButtonObj: ActionButtonInterface) => {
    if (actionButtonObj.id === ORG_ACTION_BUTTON.addOrganization.id) {
      dispatch(organizationActions.createNewOrganizationButtonClick());
      dispatch(destroy(REDUX_FORM_CONSTANT.ORGANIZATION));
    }

    if (actionButtonObj.id === ORG_ACTION_BUTTON.updateOrganizationDetails.id) {
      dispatch(submit(REDUX_FORM_CONSTANT.ORGANIZATION));
      if (!_.isEmpty(addOrEditOrgDeptFormErrors)) {
        toast.error('Please fill the required fields.');
      } else {
        const updatedFieldValue = getUpdatedFields(
          addOrEditOrganizationFormValue,
          selectedOrganization,
        );

        dispatch(
          organizationActions.addOrEditOrganizationsAsyncRequest({
            formValue: addOrEditOrganizationFormValue,
            dirtyFields: updatedFieldValue,
            // @ts-ignore
            id: currentRoute.params.id,
          }),
        );
      }
    }

    if (actionButtonObj.id === ORG_ACTION_BUTTON.addUserToOrganization.id) {
      dispatch(
        organizationActions.createNewOrgUserButtonClick({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    if (actionButtonObj.id === ORG_ACTION_BUTTON.updateUserDetails.id) {
      dispatch(submit(REDUX_FORM_CONSTANT.ORG_USER));
      if (!_.isEmpty(addOrEditUserFormErrors)) {
        toast.error('Please fill the required fields.');
      } else
        dispatch(
          organizationActions.addOrEditOrgUserAsyncRequest({
            formValue: addOrEditUserFormValue,

            // @ts-ignore
            orgId: currentRoute.params.id,
            // @ts-ignore
            userId: currentRoute.params.userId,
          }),
        );
    }
    if (actionButtonObj.id === ORG_ACTION_BUTTON.updateMapDetails.id) {
      if (
        !commandPosts.length ||
        !perimeterPoints.length ||
        !geoFence.length ||
        !orgPerimeterPoints.length
      ) {
        toast.error(
          'Command Points, Perimeter points and Geofence cannot be deleted. Please add a new one.',
        );
      } else {
        const updatedFieldValue = getUpdatedFields(
          addOrEditOrganizationFormValue,
          selectedOrganization,
        );
        dispatch(
          organizationActions.addOrEditOrganizationsAsyncRequest({
            formValue: selectedOrganization,
            dirtyFields: updatedFieldValue,
            // @ts-ignore
            id: currentRoute.params.id,
            isGeoJSONRequest: true,
          }),
        );
      }
    }

    if (actionButtonObj.id === ORG_ACTION_BUTTON.activateOrgDept.id) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'organization',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.ACTIVATE,
        }),
      );
    }

    if (actionButtonObj.id === ORG_ACTION_BUTTON.deActivateOrgDept.id) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'organization',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.DEACTIVATE,
        }),
      );
    }

    if (actionButtonObj.id === ORG_ACTION_BUTTON.activateOrgDeptUser.id) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.userId,
          status: STATUS.UNBLOCK_USER,
        }),
      );
    }

    if (actionButtonObj.id === ORG_ACTION_BUTTON.deActivateOrgDeptUser.id) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.userId,
          status: STATUS.BLOCK_USER,
        }),
      );
    }
  };
  const getPoliceForm = useSelector(getPoliceDeptFormValue);
  const [userForms, isUserTouched] = getUserForms();
  window.onbeforeunload = function (event: any) {
    const e = event || window.event;
    const msg = WARNING_MESSAGE;

    if (
      (isPoliceFormTouched &&
        compareFormObjects(
          selectedOrganization,
          getPoliceForm,
          ORG_FORM_KEYS,
        )) ||
      (isOrgFormTouched &&
        compareFormObjects(
          selectedOrganization,
          addOrEditOrganizationFormValue,
          ORG_FORM_KEYS,
        )) ||
      (isUserTouched &&
        compareFormObjects(selectedUser, userForms, USER_FORM_KEYS))
    ) {
      e.preventDefault();
      if (e) {
        return (e.returnValue = msg);
      }
      return msg;
    }
  };

  console.log('%c actionButton ', 'background: lime; color: black', {
    actionButton,
  });

  return (
    <Fragment>
      <NavigationSection
        breadcrumbData={breadcrumb}
        activeRouteSlug={appRouteObj.path}
        subNavigation={nestedLinks}
        sectionVisibility={activeSubHeaderSection}
        actionButtons={actionButton}
        actionButtonClick={handleActionButtonClick}
      />

      <PageBody>
        {isRouteMatch(ORGANIZATION_ROUTES.ORGANIZATION, currentRoute.url) && (
          <OrganizationTable categoryType={AppCategory.ORGANIZATION} />
        )}

        {isRouteMatch(
          ORGANIZATION_ROUTES.ORGANIZATION_DETAILS,
          currentRoute.url,
        ) && <OrganizationFormController />}

        {isRouteMatch(
          ORGANIZATION_ROUTES.ORGANIZATION_USERS,
          currentRoute.url,
        ) && <OrganizationUsers />}

        {isRouteMatch(
          ORGANIZATION_ROUTES.ORGANIZATION_USERS_DETAILS,
          currentRoute.url,
        ) && <UserForm id={REDUX_FORM_CONSTANT.ORG_USER} />}

        {isRouteMatch(
          ORGANIZATION_ROUTES.ORGANIZATION_MAP,
          currentRoute.url,
        ) && <RenderMap />}

        {isRouteMatch(
          ORGANIZATION_ROUTES.ORGANIZATION_QR_CODE,
          currentRoute.url,
        ) &&
          !isOrgArchieved && (
            // qrCodeFeatureFlag &&
            <QrCodePreview />
          )}
      </PageBody>
    </Fragment>
  );
};
