import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(4),
      font: 'normal normal bold 20px/30px Poppins',
      backgroundColor: '#222429',
      // Remove below 2 properties to scroll
      // secondary header with tha page body
      flex: 1,
      overflow: 'auto',
    },
  }),
);

interface PageBodyProps {
  children: React.ReactNode;
}

export const PageBody: React.FC<PageBodyProps> = (props: PageBodyProps) => {
  const { children } = props;
  const classes = useStyles();

  return <Box className={classes.container}>{children}</Box>;
};

export default PageBody;
