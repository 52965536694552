import {
  EventUserLocationResponseInterface,
  EventUserResponseInterface,
} from 'utils';
import _ from 'lodash';
import { isNilOrEmpty } from 'utils';
import {
  EventUser,
  EventUserLocationPayload,
} from 'store/eventUser/eventUser.slice';
import { getMapsCoords } from './event.model';

export const basicEventUserDetailsFromJson = (
  apiResponse: EventUserResponseInterface[],
): EventUser[] => {
  if (isNilOrEmpty(apiResponse)) {
    return [];
  }
  if (!_.isArray(apiResponse)) {
    return [];
  }

  return apiResponse.map((res) => {
    return eventUserModel(res);
  });
};

export function eventUserModel(dataObj: EventUserResponseInterface): EventUser {
  const eventUserObj = {} as EventUser;

  eventUserObj.id = dataObj.id;
  eventUserObj.archivedAt = dataObj.archived_at;
  eventUserObj.event = dataObj.event;
  eventUserObj.user = {
    id: dataObj.user.id,
    batchNumber: dataObj.user.batch_number,
    firstName: dataObj.user.first_name,
    lastName: dataObj.user.last_name,
    organization: {
      id: dataObj.user.organization.id,
      identifier: dataObj.user.organization.identifier,
      name: dataObj.user.organization.name,
    },
    personType: {
      id: dataObj.user.person_type.id,
      logicalName: dataObj.user.person_type.logical_name,
      name: dataObj.user.person_type.name,
    },
    username: dataObj.user.username,
    lastKnownLocation: dataObj.user.last_known_location
      ? {
          type: _.get(dataObj, 'user.last_known_location.type'),
          coordinates: getMapsCoords(
            dataObj.user.last_known_location.coordinates,
          ),
        }
      : null,
    role: {
      id: dataObj.user.role.id,
      // isSpecialEventRole: dataObj.user.role.is_special_event_role,
      logicalName: dataObj.user.role.logical_name,
      name: dataObj.user.role.name,
    },
    phone: dataObj.user.phone,
  };

  return eventUserObj;
}

export function eventUserLocationModel(
  dataObj: EventUserLocationResponseInterface,
): EventUserLocationPayload {
  const eventUserLocationObj = {} as EventUserLocationPayload;

  eventUserLocationObj.id = dataObj.id;
  eventUserLocationObj.event = {
    id: dataObj.event.id,
  };
  eventUserLocationObj.user = {
    id: dataObj.user.id,
    lastKnownLocation: dataObj.user.last_known_location && {
      type: dataObj.user.last_known_location.type,
      coordinates: getMapsCoords(dataObj.user.last_known_location.coordinates),
    },
  };

  return eventUserLocationObj;
}
