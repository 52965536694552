import React, { useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { EventUser } from 'store/eventUser/eventUser.slice';
import EmsIcon from 'assets/ems-icon.svg';
import EmsCommanderIcon from 'assets/ems-commander-icon.svg';
import SwatIcon from 'assets/swat-icon.svg';
import K9Icon from 'assets/k9-icon.svg';
import CommanderIcon from 'assets/commander-icon.svg';
import PoliceIcon from 'assets/police-icon.svg';
import StaffIcon from 'assets/staff-icon.svg';
import TeacherIcon from 'assets/teacher-icon.svg';
import StudentIcon from 'assets/student-icon.svg';
import SecurityGuardIcon from 'assets/security-guard-icon.svg';

import { AppPersonTypesEnum, AppRolesEnum } from 'utils';
import { includes } from 'lodash';
import { COLORS } from 'styles/colorConstants';

interface PersonTypeMapIconProps {
  user: EventUser;
  size: number;
}

const policeRoles = [
  AppRolesEnum.police_manager,
  AppRolesEnum.police_default,
  AppRolesEnum.police_commander,
  AppRolesEnum.police_commander_se,
  AppRolesEnum.police_officer_se,
  // AppRolesEnum.security_guard_se,
];

const emsRoles = [
  AppRolesEnum.ems_manager,
  AppRolesEnum.ems_default,
  AppRolesEnum.ems_commander,
  AppRolesEnum.ems_se,
  AppRolesEnum.ems_commander_se,
];

const generalRoles = [
  AppRolesEnum.org_manager,
  AppRolesEnum.org_default,
  AppRolesEnum.ems_commander,
  AppRolesEnum.ems_se,
  AppRolesEnum.ems_commander_se,
];

const PersonTypeMapIcon = (props: PersonTypeMapIconProps) => {
  const roleLogicalName = props.user.user.role.logicalName;
  const personTypeLogicalName = props.user.user.personType.logicalName;

  // Some icons having text like k9, commander etc are rendered small by
  // scaledSize icon property of <Marker />. This is required to make them
  // the same size as other icons.
  let scaleSizeOffset = 0;

  const [showInfo, setShowInfo] = useState(false);

  let icon;
  if (includes(policeRoles, roleLogicalName)) {
    switch (personTypeLogicalName) {
      case AppPersonTypesEnum.k9:
      case AppPersonTypesEnum.k9Se:
        icon = K9Icon;
        scaleSizeOffset = 15;
        break;
      case AppPersonTypesEnum.swat:
      case AppPersonTypesEnum.swatSe:
        icon = SwatIcon;
        scaleSizeOffset = 15;
        break;
      case AppPersonTypesEnum.commander:
      case AppPersonTypesEnum.commanderSe:
        icon = CommanderIcon;
        scaleSizeOffset = 15;
        break;
      default:
        icon = PoliceIcon;
    }
  } else if (includes(emsRoles, roleLogicalName)) {
    if (
      roleLogicalName === AppRolesEnum.ems_commander ||
      roleLogicalName === AppRolesEnum.ems_commander_se
    ) {
      scaleSizeOffset = 15;
      icon = EmsCommanderIcon;
    } else {
      icon = EmsIcon;
    }
  } else if (roleLogicalName == AppRolesEnum.security_guard_se) {
    icon = SecurityGuardIcon;
  } else {
    switch (personTypeLogicalName) {
      case AppPersonTypesEnum.teacher:
        icon = TeacherIcon;
        break;
      case AppPersonTypesEnum.student:
        icon = StudentIcon;
        break;
      case AppPersonTypesEnum.staff:
        icon = StaffIcon;
        break;
    }
  }

  if (!icon) {
    icon = StaffIcon;
  }

  return (
    <>
      {props.user.user.lastKnownLocation && (
        <Marker
          key={props.user.id}
          position={props.user.user.lastKnownLocation?.coordinates}
          icon={{
            url: icon,
            scaledSize: new google.maps.Size(
              props.size + scaleSizeOffset,
              props.size + scaleSizeOffset,
            ),
          }}
          onClick={() => setShowInfo(!showInfo)}
          clickable={true}
          draggable={false}
        >
          {showInfo && (
            <InfoWindow
              onCloseClick={() => setShowInfo(false)}
              options={{
                minWidth: 100,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  color: COLORS.RAISIN_BLACK,
                  fontSize: '12px',
                }}
              >
                <span style={{ marginBottom: 5, fontWeight: 'bold' }}>
                  {props.user.user.firstName}
                </span>
                <span style={{ marginBottom: 5, fontSize: '10px' }}>
                  {`${
                    props.user.user.organization.identifier
                      ? props.user.user.organization.identifier
                      : '-'
                  } ${
                    props.user.user.batchNumber
                      ? props.user.user.batchNumber
                      : '-'
                  }`}
                </span>
                {props.user.user.phone && (
                  <span style={{ fontSize: '10px' }}>
                    {props.user.user.phone}
                  </span>
                )}
              </div>
            </InfoWindow>
          )}
        </Marker>
      )}
    </>
  );
};

const InfoWindowContent = (props: PersonTypeMapIconProps) => (
  <div style={{ backgroundColor: 'black' }}>{props.user.user.firstName}</div>
);

export default PersonTypeMapIcon;
