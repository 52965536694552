import { all, put, takeLatest } from 'redux-saga/effects';
import { EventUser, eventUserActions } from './eventUser.slice';
import {
  getAllEventUsers,
  GetEventUserResponse,
} from 'services/eventUser.service';
import { PayloadAction } from '@reduxjs/toolkit';
import { showErrorMsg } from 'utils';
import { camelCaseForObject } from 'services/module/helper';
import { basicEventUserDetailsFromJson } from 'models/event/eventUser.model';

function* fetchAllEventUsers(action: PayloadAction<string>) {
  try {
    const response: GetEventUserResponse = yield getAllEventUsers(
      action.payload,
    );

    yield put(
      eventUserActions.setAllEventUsers(
        basicEventUserDetailsFromJson(response.data),
      ),
    );
    return response;
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', err);
    showErrorMsg(err);
  }
}

export function* eventUserSaga(): Generator {
  yield all([takeLatest(eventUserActions.fetchEventUsers, fetchAllEventUsers)]);
}
