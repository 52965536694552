import { POLICE_DEPT_ROUTES } from 'routes/path';
import { APP_ASSETS } from 'utils/asset';
import { COUNTRY_DATA } from 'utils/constants';
import {
  formatVal,
  isRequired,
  isValidIdentifier,
  isValidPhoneNumber,
  isValidPostalCode,
  isDomainPresent,
  isValidDomain,
} from 'utils/helper';
import Text from 'components/General/Form/Text';
import ComboBox from 'components/General/Form/ComboBox';
import { CheckboxComp } from 'components/General/Form/Checkbox';
import InputChip from 'components/General/Form/InputChip';

export const POLICE_ORG_NESTED_LINKS = {
  details: {
    id: 'details',
    name: 'Police Dept',
    routeSlug: [POLICE_DEPT_ROUTES.POLICE_DEPT_DETAILS.path],
    // routePost: '/',
    icon: {
      src: APP_ASSETS.POLICE_DEPARTMENT_DETAILS,
      name: 'Police Department',
    },
  },
  users: {
    id: 'users',
    name: 'Police Department Users',
    routeSlug: [
      POLICE_DEPT_ROUTES.POLICE_DEPT_USERS.path,
      POLICE_DEPT_ROUTES.POLICE_DEPT_USERS_DETAILS.path,
    ],
    routePost: 'users',
    icon: {
      src: APP_ASSETS.ORG_USERS,
      name: 'Police Department Users',
    },
  },
  org: {
    id: 'org',
    name: 'Police Dept Org',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_ORG.path,
    routePost: 'org',
    icon: {
      src: APP_ASSETS.ORG_DETAILS,
      name: 'Police Department Org',
    },
  },
  // explore: {
  //   id: 'explore',
  //   name: 'Map',
  //   routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_MAP.path,
  //   routePost: 'maps',
  //   icon: {
  //     src: APP_ASSETS.MAP,
  //     name: 'Maps',
  //   },
  // },
  qrCode: {
    id: 'qrCode',
    name: 'Police Department QR Code',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_QR_CODE.path,
    routePost: 'qr',
    icon: {
      src: APP_ASSETS.QR_CODE_ICON,
      name: 'Police Department QR Code',
    },
  },
};

export const POLICE_DEPT_ACTION_BUTTON = {
  addPoliceDept: {
    id: 'addPoliceDept',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_ACTION,
      name: 'Add Police Dept',
    },
  },
  startEvent: {
    id: 'startEvent',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_ACTION,
      name: 'Add Organization',
    },
  },
  addOrEditPoliceDept: {
    id: 'addOrEditPoliceDept',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'Save Police Dept',
    },
    type: 'submit',
  },
  // updateMapDetails: {
  //   id: 'updateMapDetails',
  //   routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_MAP.path,
  //   isVisible: false,
  //   icon: {
  //     src: APP_ASSETS.SAVE_ACTION,
  //     name: 'save Org',
  //   },
  // },
  activatePoliceDept: {
    id: 'activatePoliceDept',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.INACTIVE_ICON,
      name: 'Activate Org',
    },
  },
  deActivatePoliceOrg: {
    id: 'deActivatePoliceOrg',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ACTIVE_ICON,
      name: 'Deactivate Org',
    },
  },
  addUserToPoliceDept: {
    id: 'addUserToPoliceDept',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_USERS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_USER_ACTION,
      name: 'Add Users',
    },
  },
  updateUserDetails: {
    id: 'updateUserDetails',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'save User',
    },
    type: 'submit',
  },
  addEditSupervisee: {
    id: 'addEditSupervisee',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_ORG.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_ACTION,
      name: 'Add Organization',
    },
  },
  activatePoliceDeptUsers: {
    id: 'activatePoliceDeptUsers',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.INACTIVE_ICON,
      name: 'Activate User',
    },
  },
  deActivatePoliceDeptUsers: {
    id: 'deActivatePoliceDeptUsers',
    routeSlug: POLICE_DEPT_ROUTES.POLICE_DEPT_USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ACTIVE_ICON,
      name: 'Deactivate User',
    },
  },
};

export const POLICE_DEPT_ACTIVE_SUB_HEADER_SECTION = {
  leftSection: true,
  middleSection: false,
  rightSection: false,
};

export const POLICE_DEPT_FORM_FIELDS = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    component: Text,
    placeHolder: 'Enter Organization Name',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'country',
    label: 'Country',
    type: 'text',
    component: ComboBox,
    id: 'country',
    placeHolder: 'Select Country',
    validate: [isRequired],
    required: true,
    options: COUNTRY_DATA,
    displayValuePath: 'name',
  },
  {
    name: 'phone',
    type: 'tel',
    component: Text,
    label: 'Phone',
    placeHolder: 'Enter Phone',
    validate: [isValidPhoneNumber],
    required: true,
  },
  {
    name: 'street_1',
    type: 'text',
    component: Text,
    label: 'Street',
    placeHolder: 'Enter Street',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'street_2',
    type: 'text',
    component: Text,
    label: 'Street 2',
    placeHolder: 'Enter Street',
    validate: [],
  },
  {
    name: 'city',
    type: 'text',
    component: Text,
    label: 'City',
    placeHolder: 'Enter City',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'state',
    type: 'text',
    component: ComboBox,
    id: 'state',
    label: 'State',
    placeHolder: 'Enter State',
    displayValuePath: 'name',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'postal_code',
    type: 'text',
    component: Text,
    label: 'Postal Code',
    placeHolder: 'Enter Postal Code',
    validate: [isValidPostalCode],
    required: true,
    format: formatVal('postal_code'),
  },
  {
    name: 'identifier',
    type: 'text',
    label: 'Department Identifier',
    component: Text,
    placeHolder: 'Enter Department Identifier',
    validate: [isValidIdentifier],
    required: true,
  },
  {
    name: 'domains',
    type: 'text',
    component: InputChip,
    label: 'domain',
    placeHolder: 'Enter domain',
    validate: [isDomainPresent, isValidDomain],
    required: true,
    disable: false,
  },
];
