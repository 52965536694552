import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getIncomingVideoCalls,
  getIsOngoingVideoCall,
  getOutgoingVideoCallId,
  getVideoCallId,
} from './videoCall.selector';
import {
  InitiateVideoCallData,
  videoCallActions,
  VideoCallData,
} from './videoCall.slice';
import * as videoCallService from 'services/videoCall.service';
import { showErrorMsg } from 'utils/';
import { videoCallStatus } from 'config/videoCall';
import { PayloadAction } from '@reduxjs/toolkit';
import { findIndex, isNil } from 'lodash';
import { getActiveEvent } from 'store/event';
import { EventInterface } from 'utils/types';
import { push } from 'connected-react-router';
import { getAuthenticatedUserId } from 'store/login';
import { toast } from 'react-toastify';
import { camelCaseForObject } from 'services/module/helper';
import { getErrorStatusCode } from 'utils/helper';
import { OverRideEventInterface } from 'models/event/event.model';

function* setStatusVideoCallSaga(
  status: videoCallStatus,
  id: string | null = null,
) {
  try {
    let videoCallId: string = id || (yield select(getVideoCallId));

    if (isNil(videoCallId)) {
      videoCallId = yield select(getOutgoingVideoCallId);
    }

    if (!isNil(videoCallId)) {
      const response: VideoCallData = yield videoCallService.setStatusVideoCall(
        videoCallId,
        status,
      );

      return response;
    }
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', err);
    showErrorMsg(err);
  }
}

function* acceptVideoCallSaga(action: PayloadAction<string>) {
  // After accept we are not setting ongoing video call data here.
  // Instead, another pubsub messsage would be fired by backend called
  // video_call_join which would add the ongoing video call data

  try {
    const response: VideoCallData = yield call(
      setStatusVideoCallSaga,
      videoCallStatus.accept,
      action.payload,
    );

    return response;
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', err);
    showErrorMsg(err);
  }
}

function* rejectVideoCallSaga(action: PayloadAction<string>) {
  try {
    yield call(setStatusVideoCallSaga, videoCallStatus.reject, action.payload);
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', err);
    showErrorMsg(err);
  }
}

function* endVideoCallSaga() {
  try {
    const isOngoingVideoCall: boolean = yield select(getIsOngoingVideoCall);
    // const videoCallId: string = yield select(getVideoCallId);

    yield call(setStatusVideoCallSaga, videoCallStatus.end);
    yield put(videoCallActions.setEndVideoCall());

    yield put(videoCallActions.setOutgoingCall(null));

    const event: OverRideEventInterface | null = yield select(getActiveEvent);
    if (!isNil(event)) {
      yield put(push(`/events/${event.id}`));
    }
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', err);
    showErrorMsg(err);
  }
}

function* setOngoingVideoCallSaga(action: PayloadAction<VideoCallData>) {
  // This saga just reroutes to the VideoCall component
  // when a new ongoing video call pubsub message is received from
  // backend

  const event: OverRideEventInterface | null = yield select(getActiveEvent);
  if (!isNil(action.payload.endedAt) && event) {
    yield put(push(`/events/${event.id}`));
  }
}

function* initiateVideoCallSaga(action: PayloadAction<InitiateVideoCallData>) {
  try {
    const authUserId: string = yield select(getAuthenticatedUserId);

    if (authUserId == action.payload.receiverId) {
      toast.error('You cannot call yourself');
      return;
    }
    const event: OverRideEventInterface | null = yield select(getActiveEvent);

    const response: videoCallService.InitiateVideoCallResponseInterface = yield videoCallService.initiateVideoCall(
      action.payload,
    );

    yield put(
      videoCallActions.setOutgoingCall(
        camelCaseForObject(response.data) as VideoCallData,
      ),
    );

    if (!isNil(event)) {
      yield put(push(`/events/${event.id}/outgoing_video_call`));
    }
  } catch (err) {
    console.log('%c err ', 'background: salmon; color: black', err);
    showErrorMsg(err);
  }
}

function* fetchActiveVideoCall() {
  const response: videoCallService.GetVideoCallResponseInterface = yield videoCallService.getActiveVideoCall();

  if (response.data.length > 0) {
    yield put(
      videoCallActions.setOngoingVideoCall(
        camelCaseForObject(response.data[0]) as VideoCallData,
      ),
    );
  }
}

function* pingVideoCallSaga(action: PayloadAction<string>) {
  try {
    yield videoCallService.pingVideoCall(action.payload);
  } catch (err) {
    const statusCode = getErrorStatusCode(err);

    if (statusCode == 'VIDEO_CALL_NOT_FOUND') {
      yield put(videoCallActions.setEndVideoCall());
    } else {
      showErrorMsg(err);
    }
  }
}

export function* videoCallSaga(): Generator {
  yield all([
    takeLatest(videoCallActions.acceptVideoCall, acceptVideoCallSaga),
    takeLatest(videoCallActions.rejectVideoCall, rejectVideoCallSaga),
    takeLatest(videoCallActions.endVideoCall, endVideoCallSaga),
    takeLatest(videoCallActions.setOngoingVideoCall, setOngoingVideoCallSaga),
    takeLatest(videoCallActions.startOutgoingCall, initiateVideoCallSaga),
    takeLatest(videoCallActions.fetchActiveVideoCall, fetchActiveVideoCall),
    takeLatest(videoCallActions.pingVideoCall, pingVideoCallSaga),
  ]);
}
