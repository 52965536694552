import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrgTypeInterface } from 'utils/types';
import { APP_CATEGORY } from 'utils/constants';
import { AppCategoryEnum } from 'utils/commonTypes';
import { Config } from 'store/organization';

interface AppStateInterface {
  isLoading: boolean;
  isCategoriesFetched: boolean;
  allCategories: OrgTypeInterface[] | [];
  organizationCategory: OrgTypeInterface | undefined;
  policeCategory: OrgTypeInterface | undefined;
  emsCategory: OrgTypeInterface | undefined;
  specialEventCategory: OrgTypeInterface | undefined;
  activeCategoryType: AppCategoryEnum | null;
  allConfigDetails: Config | undefined;
}

const initialState: AppStateInterface = {
  isLoading: false,
  isCategoriesFetched: false,
  allCategories: [],
  organizationCategory: undefined,
  policeCategory: undefined,
  emsCategory: undefined,
  specialEventCategory: undefined,
  activeCategoryType: null,
  allConfigDetails: undefined,
};

const separateCategories = (data: OrgTypeInterface[] | []) => {
  const res = {
    organizationCategory: undefined,
    policeCategory: undefined,
    emsCategory: undefined,
    specialEventCategory: undefined,
  };

  // @ts-ignore
  res.organizationCategory = _.find(data, { name: APP_CATEGORY.ORGANIZATION });
  // @ts-ignore
  res.policeCategory = _.find(data, { name: APP_CATEGORY.POLICE_DEPARTMENT });
  // @ts-ignore
  res.emsCategory = _.find(data, { name: APP_CATEGORY.EMS });
  // @ts-ignore
  res.specialEventCategory = _.find(data, { name: APP_CATEGORY.SPECIAL_EVENT });

  return res;
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showSpinner: (state) => {
      state.isLoading = true;
    },
    hideSpinner: (state) => {
      state.isLoading = false;
    },
    categoryAsyncRequest: (state) => {
      state.isLoading = true;
      state.isCategoriesFetched = false;
    },
    categoryAsyncSuccess: (
      state,
      action: PayloadAction<OrgTypeInterface[] | []>,
    ) => {
      state.isLoading = false;
      state.allCategories = action.payload;
      const computedCategory = separateCategories(state.allCategories);
      state.organizationCategory = computedCategory.organizationCategory;
      state.policeCategory = computedCategory.policeCategory;
      state.emsCategory = computedCategory.emsCategory;
      state.specialEventCategory = computedCategory.specialEventCategory;

      state.isCategoriesFetched = true;
    },
    categoryAsyncFailure: (state) => {
      state.isLoading = false;
      state.isCategoriesFetched = false;
      // todo
      // handle the failure of the category Type api
      console.log(
        '%c error inside the categoryAsyncFailure ',
        'background: salmon; color: black',
      );
    },
    setActiveCategoryType: (
      state,
      action: PayloadAction<AppCategoryEnum | null>,
    ) => {
      state.activeCategoryType = action.payload;
    },
    configAsyncRequest: (state) => {
      state.isLoading = true;
    },
    configAsyncSuccess: (state, action) => {
      state.isLoading = false;
      state.allConfigDetails = action.payload;
    },
    configAsyncFailure: (state) => {
      state.isLoading = false;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
