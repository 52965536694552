import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';

interface WebSocketConnState {
  isEstablishingConnection: boolean;
  isConnected: boolean;
  isSocketUrlReady: boolean;
  socketUrl: string | null;
}

interface SocketUrlPayload {
  token: string;
}

const initialState: WebSocketConnState = {
  isEstablishingConnection: false,
  isSocketUrlReady: false,
  isConnected: false,
  socketUrl: null,
};

export const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState,
  reducers: {
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.isEstablishingConnection = false;
    },
    connectionBroken: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = true;
    },
    closeConnection: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = false;
    },
    setSocketUrl: (state, action: PayloadAction<SocketUrlPayload>) => {
      if (!isNil(action.payload.token)) {
        state.socketUrl = action.payload.token;
      }
    },
    setSocketUrlReady: (state) => {
      state.isSocketUrlReady = true;
    },
  },
});

export const webSocketActions = webSocketSlice.actions;

export default webSocketSlice.reducer;
