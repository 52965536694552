import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersDataAP } from './users.type';
import {
  UserInterface,
  UserDetailsAP,
  fetchUserOrgAP,
  UserRolesType,
} from 'utils/types';
import { OrganizationTypeInterface } from 'models/organization';
import { PaginationInterface } from 'utils/commonTypes';
import { DEFAULT_PAGINATION_OBJECT } from 'utils/constants';
import { isCreateAction } from 'utils';
import {
  AddEditOrgUserSuccessActionPayload,
  AddEditOrgUserActionPayload,
  UpdateActiveStatusPayload,
  ResetPasswordPayload,
  OrganizationRolesInterface,
} from 'store/organization';

interface UserState {
  list: {
    data: UserInterface[] | [];
    pagination: PaginationInterface;
  };
  isLoading: boolean;
  isPasswordResetSuccess: boolean;

  combinedUserRoles: UserRolesType;
  combinedOrgList: OrganizationTypeInterface[];

  activeUser: UserInterface | null;
  allRoles: OrganizationRolesInterface[] | [];
}

const initialState: UserState = {
  list: {
    data: [],
    pagination: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
  },
  isLoading: false,

  combinedUserRoles: {
    entities: {},
    result: [],
  },
  combinedOrgList: [],

  activeUser: null,
  isPasswordResetSuccess: false,
  allRoles: [],
};

export const usersSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    //  list all the organization
    fetchUserDataRequest: (state) => {
      state.isLoading = true;
    },
    fetchUserDataSuccess: (state, action: PayloadAction<UsersDataAP>) => {
      state.isLoading = false;
      state.list.data = action.payload.data;
      state.list.pagination = action.payload.meta;
    },
    fetchUserDataFailure: (state) => {
      state.isLoading = false;
    },

    //  particular Usr details
    fetchUserDetailsDataRequest: (
      state,
      action: PayloadAction<UserDetailsAP>,
    ) => {
      state.isLoading = true;
    },
    fetchUserDetailsSuccess: (
      state,
      action: PayloadAction<{ data: UserInterface }>,
    ) => {
      console.log(
        '%c action.payloadaction.payloadaction.payload ',
        'background: aqua; color: black',
        { fetchUserDetailsSuccess: action.payload },
      );
      state.isLoading = false;
      state.activeUser = action.payload?.data;
    },
    fetchUserDetailsFailure: (state) => {
      state.isLoading = false;
    },

    //  list all the possible user orgs
    fetchUserOrgRequest: (state, action: PayloadAction<fetchUserOrgAP>) => {
      state.isLoading = true;
    },
    fetchUserOrgSuccess: (
      state,
      action: PayloadAction<{ data: OrganizationTypeInterface[] }>,
    ) => {
      state.isLoading = false;
      state.combinedOrgList = action.payload.data;
    },
    fetchUserOrgFailure: (state) => {
      state.isLoading = false;
    },

    //  list all the possible user roles
    fetchUserRolesRequest: (state) => {
      state.isLoading = true;
    },
    fetchUserRolesSuccess: (
      state,
      action: PayloadAction<{ data: UserRolesType }>,
    ) => {
      state.isLoading = false;
      state.combinedUserRoles = action.payload.data;
    },
    fetchUserRolesFailure: (state) => {
      state.isLoading = false;
    },

    fetchAllRolesSuccess: (
      state,
      action: PayloadAction<OrganizationRolesInterface[]>,
    ) => {
      state.allRoles = action.payload;
    },

    //  Add or Edit a user
    addOrEditUserRequest: (
      state,
      action: PayloadAction<AddEditOrgUserActionPayload>,
    ) => {
      state.isLoading = true;
    },
    addOrEditUserSuccess: (
      state,
      action: PayloadAction<AddEditOrgUserSuccessActionPayload>,
    ) => {
      state.isLoading = false;

      const { actionType, updatedValue } = action.payload;

      if (isCreateAction(actionType || '')) {
        // @ts-ignore
        state.activeUser = updatedValue;
        // @ts-ignore
        state.list.data.push(updatedValue);
      } else {
        // @ts-ignore
        state.activeUser = updatedValue;
        const findUserIndex = _.findIndex(
          state.list?.data || [],
          (user: any) => {
            if (user && user.id === updatedValue.id) {
              return true;
            }
            return false;
          },
        );

        if (findUserIndex !== -1) {
          // @ts-ignore
          state.list.data[findUserIndex] = updatedValue;
        }
      }
    },
    addOrEditUserFailure: (state) => {
      state.isLoading = false;
    },

    updateActiveUser: (
      state,
      action: PayloadAction<{ data: UserInterface | null }>,
    ) => {
      const { data } = action.payload;
      state.activeUser = data;
    },
    updateUserActiveStatusRequest: (
      state,
      action: PayloadAction<UpdateActiveStatusPayload>,
    ) => {
      state.isLoading = true;
    },
    updateUserActiveStatusSuccess: (
      state,
      action: PayloadAction<UserInterface>,
    ) => {
      state.isLoading = false;
      state.activeUser = action.payload;
    },
    updateUserActiveStatusFailure: (state) => {
      state.isLoading = false;
    },
    resetPasswordRequest: (
      state,
      action: PayloadAction<ResetPasswordPayload>,
    ) => {
      state.isLoading = true;
      state.isPasswordResetSuccess = false;
    },
    resetPasswordSuccess: (state) => {
      state.isLoading = false;
      state.isPasswordResetSuccess = true;
    },
    resetPasswordFailure: (state) => {
      state.isLoading = false;
      state.isPasswordResetSuccess = false;
    },
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
