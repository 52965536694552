import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { getLoginUser, loginActions } from 'store/login';
import * as localStorageService from 'services/localStorage.service';
import { ROUTES } from 'routes/path';
import { FormSaveConfirmationModal } from 'components/Form/FormModal';
import {
  getCurrentUser,
  getUserForms,
  isEmsCommander,
  isOpsCenter,
  isSEManager,
  useModal,
} from 'utils/hooks';
import { destroy } from 'redux-form';
import {
  compareFormObjects,
  EventInterface,
  ORG_FORM_KEYS,
  REDUX_FORM_CONSTANT,
  USER_FORM_KEYS,
} from 'utils';
import {
  getActiveEvent,
  getModalOpenStatus,
  getOrganizationFormValue,
  getPoliceDeptFormValue,
  getSelectedOrganization,
  isOrganizationDeptFormTouched,
  isPoliceDeptFormTouched,
  modalActions,
} from 'store';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { COLORS } from 'styles/colorConstants';
import { CustomButton } from 'components/General/CustomButton';
import { eventActions } from 'store/event/event.slice';
import { deleteToken } from 'firebase/messaging';
import { messaging } from 'config/firebaseConfig';
import { Menu } from '@material-ui/icons';
import { LeaceActiveThreatDialogComponent } from './leaveActiveThreat';

const useLogOutStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // width: '5%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    username: {
      fontFamily: 'Poppins',
      color: COLORS.WHITE_GREY,
      fontWeight: 600,
      marginLeft: '8px',
    },
    title: {
      fontSize: '20px',
    },
    color: {
      color: COLORS.RAISIN_BLACK,
    },
    margin: {
      margin: '16px',
    },
    padding: {
      padding: '16px',
    },
    lightColor: {
      color: COLORS.DATE_COLOR,
    },
    ctn: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkbox: {
      color: COLORS.OPS_CENTER_GREY,
      '&.Mui-checked': {
        color: COLORS.OPS_CENTER_GREY,
      },
      '&.Mui-unchecked': {
        color: COLORS.OPS_CENTER_GREY,
      },
    },
    actionBtnCtn: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '16px',
    },
    comment: {
      marginTop: '8px',
    },
    drawerStyles: {
      background: COLORS.GREY,
    },
    list: {
      width: 250,
    },
    redText: {
      color: COLORS.RED,
    },
    reportCEDialogCtn: {
      textAlign: 'center',
      color: COLORS.BLACK,
      textTransform: 'uppercase',
    },
    // logo: {
    //   width: ' 70px',
    // },
  }),
);

const DialogComponent = (props: {
  open: boolean;
  onClose: any;
  id: string;
}) => {
  const { open, onClose, id } = props;
  const classes = useLogOutStyles();
  const loggedUsername = useSelector(getLoginUser);
  const [isConfirmed, setConfirmed] = useState(false);
  const [reason, setReason] = useState<any>('');
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();

  const title = 'Are you certain you want to end this event?';

  const getValue = (label: string): string => {
    return label.replace(' ', '_');
  };

  const handleEndEvent = () => {
    const payload: any = { id, reason };
    if (comment) {
      payload['comment'] = comment;
    }
    dispatch(eventActions.endEventRequest(payload));
    onClose();
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setReason(event.target.value);
  };

  const handleComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };
  return (
    <Dialog
      data-cy="end-event"
      open={open}
      onClose={onClose}
      keepMounted={false}
    >
      <DialogContent className={`${classes.padding} ${classes.ctn}`}>
        <DialogTitle
          className={`${classes.title} ${classes.username} ${classes.color}`}
        >
          {title.toUpperCase()}
        </DialogTitle>
        <Typography className={`${classes.lightColor}`} variant="caption">
          Reason for Ending Event*
        </Typography>
        <Select
          value={reason}
          onChange={handleChange}
          label="Select reason"
          className={`${classes.color}`}
          variant="standard"
          name="reason"
        >
          {['Event Completed', 'Training Exercise', 'False Alarm', 'Other'].map(
            (label, index) => (
              <MenuItem
                className={classes.color}
                key={index}
                value={getValue(label.toUpperCase())}
              >
                {label}
              </MenuItem>
            ),
          )}
        </Select>
        {(reason === 'FALSE_ALARM' || reason === 'OTHER') && (
          <>
            <Typography
              className={`${classes.lightColor} ${classes.comment}`}
              variant="caption"
            >
              Additional Details*
            </Typography>
            <TextareaAutosize
              rowsMin={4}
              value={comment}
              onChange={handleComment}
              className={classes.comment}
            />
          </>
        )}
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              checked={isConfirmed}
              onChange={() => setConfirmed((prev) => !prev)}
            />
          }
          className={classes.color}
          label={`I, ${loggedUsername},  confirm that I want to end this Critical Event`}
        />
      </DialogContent>
      <DialogActions className={`${classes.actionBtnCtn}`}>
        <CustomButton
          disabled={
            !isConfirmed ||
            !reason ||
            ((reason === 'FALSE_ALARM' || reason === 'OTHER') && !comment)
          }
          onClick={handleEndEvent}
          error={true}
          label="End Event"
        />
      </DialogActions>
    </Dialog>
  );
};

const ReportCEModal = (props: { open: boolean; onClose: any }) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const user = useSelector(getLoginUser);
  const classes = useLogOutStyles();
  const handleReportCE = () => {
    dispatch(
      eventActions.reportCERequest({
        name: user,
      }),
    );
    onClose();
  };
  return (
    <Dialog
      data-cy="report-ce"
      open={open}
      onClose={onClose}
      keepMounted={false}
    >
      <DialogTitle
        className={`${classes.title} ${classes.username} ${classes.color}`}
      >
        You are about to report a critical event
      </DialogTitle>
      <DialogContent className={classes.reportCEDialogCtn}>
        Are you sure?
      </DialogContent>
      <DialogActions className={`${classes.actionBtnCtn}`}>
        <CustomButton onClick={onClose} error={true} label="Cancel" />
        <CustomButton onClick={handleReportCE} error={false} label="Report" />
      </DialogActions>
    </Dialog>
  );
};

export const Logout = () => {
  const classes = useLogOutStyles();
  const dispatch = useDispatch();
  const [open, openModal, closeModal] = useModal();
  const getOrgForm = useSelector(getOrganizationFormValue);
  const getPoliceForm = useSelector(getPoliceDeptFormValue);
  const currentOrg = useSelector(getSelectedOrganization);
  const currentUser = getCurrentUser();
  const [userForm, isUserTouched] = getUserForms();
  const isPoliceFormTouched = useSelector(isPoliceDeptFormTouched);
  const isOrgFormTouched = useSelector(isOrganizationDeptFormTouched);
  const opsCenter = isOpsCenter();
  const loggedUsername = useSelector(getLoginUser);
  const activeEvent = useSelector(getActiveEvent);
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);
  const [openLeaveActiveThreatDialog, setLeaveActiveThreatDialog] = useState(
    false,
  );
  const menuStatus: boolean = useSelector(getModalOpenStatus);
  const [openReportCEdialog, setCEDialog] = useState(false);
  const isEms = isEmsCommander();
  const seManager = isSEManager();
  const isSpecialEvent = activeEvent?.is_special_event;

  const handleLogout = () => {
    localStorageService.logout();
    if (messaging) {
      deleteToken(messaging);
    }

    dispatch(loginActions.logoutUser());
    dispatch(replace(ROUTES.LOGIN.path));
  };
  const destroyForms = () => {
    dispatch(destroy(REDUX_FORM_CONSTANT.ORGANIZATION));
    dispatch(destroy(REDUX_FORM_CONSTANT.POLICE_DEPT));
  };
  const handleLeave = () => {
    closeModal();
    destroyForms();
    handleLogout();
  };
  const handleStay = () => {
    closeModal();
  };

  const handleNavLink = () => {
    if (
      (isPoliceFormTouched &&
        compareFormObjects(currentOrg, getPoliceForm, ORG_FORM_KEYS)) ||
      (isOrgFormTouched &&
        compareFormObjects(currentOrg, getOrgForm, ORG_FORM_KEYS)) ||
      (isUserTouched &&
        compareFormObjects(currentUser, userForm, USER_FORM_KEYS))
    ) {
      openModal();
    } else {
      destroyForms();
      handleLogout();
    }
  };

  const handleMenuBtn = () => {
    dispatch(modalActions.openModal());
  };

  const endEvent = () => {
    setConfirmationDialog(true);
  };
  const handleDialogClose = () => {
    setConfirmationDialog(false);
  };
  const openleaveActiveThreatDialog = () => {
    setLeaveActiveThreatDialog(true);
  };
  const closeLeaveActiveThreatDialog = () => {
    setLeaveActiveThreatDialog(false);
  };
  const reportCriticalEvent = () => {
    setCEDialog(true);
  };
  const handleReportCEClose = () => {
    setCEDialog(false);
    dispatch(modalActions.closeModal());
  };
  return (
    <div className={classes.container}>
      {' '}
      {opsCenter && (
        <Typography data-cy="username" className={classes.username}>
          {loggedUsername}
        </Typography>
      )}
      {!opsCenter ? (
        <IconButton data-cy="logout" color="primary" onClick={handleNavLink}>
          <ExitToAppIcon />{' '}
        </IconButton>
      ) : (
        <IconButton data-cy="menu-btn" color="primary" onClick={handleMenuBtn}>
          <Menu />{' '}
        </IconButton>
      )}
      <Drawer
        anchor="right"
        open={menuStatus}
        onClose={() => dispatch(modalActions.closeModal())}
        classes={{ paper: classes.drawerStyles }}
      >
        <div className={classes.list}>
          <List>
            <ListItem button onClick={handleNavLink}>
              <ListItemText>Logout</ListItemText>
            </ListItem>
            {opsCenter && activeEvent && !activeEvent?.ended_at && (
              <ListItem onClick={openleaveActiveThreatDialog} button>
                <ListItemText>Leave Active Threat</ListItemText>
              </ListItem>
            )}
            {opsCenter && activeEvent && !activeEvent?.ended_at && !isEms && (
              <ListItem onClick={endEvent} button>
                <ListItemText>End Event</ListItemText>
              </ListItem>
            )}
            {opsCenter &&
              activeEvent &&
              !activeEvent?.ended_at &&
              !isEms &&
              isSpecialEvent && (
                <ListItem
                  onClick={reportCriticalEvent}
                  className={classes.redText}
                  button
                >
                  <ListItemText>Report Critical Event</ListItemText>{' '}
                </ListItem>
              )}
          </List>
        </div>
      </Drawer>
      <FormSaveConfirmationModal
        handleLeave={handleLeave}
        handleStay={handleStay}
        open={open}
      />
      <DialogComponent
        open={openConfirmationDialog}
        onClose={handleDialogClose}
        id={activeEvent?.id}
      />
      <LeaceActiveThreatDialogComponent
        open={openLeaveActiveThreatDialog}
        onClose={closeLeaveActiveThreatDialog}
        id={activeEvent?.id}
      />
      <ReportCEModal open={openReportCEdialog} onClose={handleReportCEClose} />
    </div>
  );
};

export default Logout;
