import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { WrappedFieldProps } from 'redux-form';
import { COLORS } from 'styles/colorConstants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useRouteMatch } from 'react-router';
import Table from 'components/General/Table';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDomainUsers,
  getSelectedOrganization,
  isLoadingDomainUsers,
  organizationActions,
} from 'store';
import { ORGANIZATION_DOMAIN_USER_LIST_TABLE_COLUMN } from 'utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& .MuiInputBase-root.Mui-disabled': {
      color: COLORS.DISABLE_COLOR,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: COLORS.DISABLE_COLOR,
    },
  },
  dialogBody: {
    maxHeight: '400px',
    marginBottom: '16px',
  },
  textColor: {
    color: COLORS.BLACK,
  },
  textfield: {
    textTransform: 'lowercase',
  },
}));

type ComboBoxProps = {
  options: any;
  displayValuePath: string;
  label: string;
  id: string;
  disable?: boolean;
  required?: boolean;
};

export const InputChip: React.FC<WrappedFieldProps & ComboBoxProps> = (
  props: WrappedFieldProps & ComboBoxProps,
) => {
  const formInputChange = props.input.onChange;
  const formInputBlur = props.input.onBlur;
  const currentOrg = useSelector(getSelectedOrganization);
  const domains = _.get(currentOrg, 'domains', []);
  const [domain, setDomain] = useState<Array<string>>(domains);
  const [openDialog, setDialog] = useState(false);
  const classes = useStyles();
  const isLoading = useSelector(isLoadingDomainUsers);
  const domainUsers = useSelector(getDomainUsers);
  const [deletedDomain, setDeletedDomain] = useState('');
  const dispatch = useDispatch();
  const currentRoute = useRouteMatch();
  // @ts-ignore
  const { id } = currentRoute.params;

  const handleRemoveDomain = (newValue: Array<string>) => {
    const prevDomains = _.cloneDeep(props.input.value);
    const deletedDomain = prevDomains.filter(
      (domain: string) => newValue.indexOf(domain) === -1,
    )[0];
    setDeletedDomain(deletedDomain);
    if (_.indexOf(domains, deletedDomain) !== -1) {
      dispatch(
        organizationActions.getDomainUsersRequest({
          id: id,
          domain: deletedDomain,
        }),
      );
      setDialog(true);
      setDomain((state) => prevDomains);
    } else {
      setDomain((state) => newValue);
      formInputChange(newValue);
    }
  };

  const handleChange = (event: any, value: Array<string>, reason: string) => {
    if (reason !== 'remove-option') {
      setDomain((state) => value);
      formInputChange(value);
    } else {
      handleRemoveDomain(value);
    }
  };
  const handleBlur = (e: any) => {
    if (e.target.value) {
      setDomain((prevVal) => [...prevVal, e.target.value]);
      formInputBlur([...domain, e.target.value]);
    } else formInputBlur(domain);
  };

  useEffect(() => {
    if (!isLoading && domainUsers.length === 0 && openDialog) {
      const newValue = domain.filter((d) => deletedDomain !== d);
      setDialog(false);
      setDomain((state) => newValue);
      formInputChange(newValue);
    }
  }, [isLoading, domainUsers]);

  return (
    <>
      <Autocomplete
        multiple
        disabled={props.disable}
        id={props.id || ''}
        classes={classes}
        freeSolo
        options={[]}
        defaultValue={[]}
        {...props.input}
        onChange={handleChange}
        style={{
          flex: 1,
          wordBreak: 'break-word',
          overflowX: 'scroll',
          textOverflow: 'ellipsis',
        }}
        renderTags={(
          value: any[],
          getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes,
        ) =>
          value.map((option: any, index: any) => {
            return (
              <Chip
                key={index}
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                style={{
                  display: 'flex',
                  borderColor: COLORS.WHITE,
                  color: COLORS.WHITE,
                }}
                onDelete={() => {
                  const updatedDomains = value.filter(
                    (domain) => domain !== option,
                  );

                  handleRemoveDomain(updatedDomains);
                }}
              />
            );
          })
        }
        onBlur={handleBlur}
        renderInput={(params) => (
          <Box>
            <TextField
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.preventDefault();
                } else if (e.key === 'Backspace') {
                  e.stopPropagation();
                }
              }}
              required={props.required}
              {...params}
              className={classes.textfield}
              label={props.label}
              InputProps={{ ...params.InputProps, endAdornment: null }}
            />
            {props.meta.touched && (
              <FormHelperText data-cy={`error-${props.label}`} error={true}>
                {props.meta.error}
              </FormHelperText>
            )}
          </Box>
        )}
      />
      <Dialog open={openDialog} onClose={() => setDialog(false)}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          domainUsers?.length !== 0 && (
            <>
              <DialogTitle className={classes.textColor}>
                Cannot remove domain name
              </DialogTitle>
              <DialogContent
                className={`${classes.dialogBody} ${classes.textColor}`}
              >
                You cannot delete this domain name because there are active
                users in your organization with this domain name. You must
                archive them first or change their domain name.
                <Table
                  columnData={ORGANIZATION_DOMAIN_USER_LIST_TABLE_COLUMN}
                  rowData={domainUsers}
                  onRowClick={(rowObj) => {
                    window.open(`${window.location.href}/users/${rowObj.id}`);
                  }}
                />
              </DialogContent>
            </>
          )
        )}
      </Dialog>
    </>
  );
};

export default InputChip;
