import _ from 'lodash';
import { RoutesInterface } from './../../routes/path';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OrganizationTypeInterface,
  PPMarkerInterface,
} from 'models/organization';
import {
  OrganizationDataActionPayload,
  AddEditOrganizationActionPayload,
  AddEditOrganizationSuccessActionPayload,
  OrganizationRolesInterface,
  OrganizationSpecificActionPayload,
  OrganizationUserSuccessActionPayload,
  OrganizationUserTypeInterface,
  AddEditOrgUserActionPayload,
  AddEditOrgUserSuccessActionPayload,
  CreateNewOrgUserButtonClickActionPayload,
  ParticularUserDetailsActionPayload,
  OrganizationTableClickAP,
  FetchOrganizationAP,
  AddOrgSuperviseeAP,
  UpdateGeoFenceAP,
  UpdatePerimeterPointsAP,
  addPerimeterPointsAP,
  UpdateCommandPostAP,
  AddCommandPostAP,
  UpdateGeoJSONDataAP,
  UpdateActiveStatusPayload,
  StartEvenPayload,
  UpdateCPsPayload,
  DomainUsersPayload,
  // FormModal,
} from './organization.type';
import {
  PaginationInterface,
  PositionInterface,
  MarkerObj,
} from 'utils/commonTypes';
import { DEFAULT_PAGINATION_OBJECT } from 'utils/constants';
import { isCreateAction, findAndUpdate } from 'utils';

interface OrganizationState {
  organizationList: {
    data: OrganizationTypeInterface[] | [];
    pagination: PaginationInterface;
  };
  isLoading: boolean;

  currentAppRoute: RoutesInterface | null;
  currentOrganization: OrganizationTypeInterface | null | undefined;
  currentOrganizationRoles: OrganizationRolesInterface[] | [];
  users: OrganizationUserSuccessActionPayload;
  selectedUser: OrganizationUserTypeInterface | null;
  superviseeList: {
    data: OrganizationTypeInterface[] | [];
    isLoading: boolean;
    associatedOrg: OrganizationTypeInterface[] | [] | undefined;
    pagination: PaginationInterface;
  };

  geoFence: PositionInterface[] | [];
  perimeterPoints: MarkerObj[] | [];
  commandPost: MarkerObj[] | [];
  currentOrgCords: PositionInterface | null;
  emsCommandPost: MarkerObj[] | null;
  isDomainUsersLoading?: boolean;
  domainUsers?: OrganizationUserSuccessActionPayload;
  // formModal: boolean;
  // formDisposeUrl: string;
  eventMapCenter?: PositionInterface | null;
  eventMapZoom?: number | null;
  orgPps: PPMarkerInterface[];
}

const initialState: OrganizationState = {
  organizationList: {
    data: [],
    pagination: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
  },
  currentOrganization: null,
  isLoading: false,
  currentAppRoute: null,
  currentOrganizationRoles: [],
  users: {
    data: [],
    meta: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
  },
  selectedUser: null,
  superviseeList: {
    data: [],
    isLoading: false,
    associatedOrg: [],
    pagination: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
  },

  // geoFence: [
  //   { lat: 52.52549080781086, lng: 13.398118538856465 },
  //   { lat: 52.48578559055679, lng: 13.36653284549709 },
  //   { lat: 52.48871246221608, lng: 13.44618372440334 },
  // ],
  geoFence: [],
  perimeterPoints: [],
  commandPost: [],
  emsCommandPost: null,
  currentOrgCords: null,
  orgPps: [],
  // formModal: false,
  // formDisposeUrl: '',
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    //  list all the organization
    fetchOrganizationDataSuccess: (
      state,
      action: PayloadAction<OrganizationDataActionPayload>,
    ) => {
      state.isLoading = false;
      state.organizationList.data = action.payload.data;
      state.organizationList.pagination = action.payload.meta;
    },
    fetchOrganizationDataFailure: (state) => {
      state.isLoading = false;
    },
    fetchOrganizationDataRequest: (
      state,
      action?: PayloadAction<FetchOrganizationAP>,
    ) => {
      state.organizationList.data = [];
      state.isLoading = true;
    },

    //  fetch particular Organization details
    fetchParticularOrganizationDetailsSuccess: (
      state,
      action: PayloadAction<OrganizationTypeInterface | undefined>,
    ) => {
      state.isLoading = false;
      state.currentOrganization = action.payload;
    },
    fetchParticularOrganizationDetailsFailure: (state) => {
      state.isLoading = false;
    },
    fetchParticularOrganizationDetailsRequest: (
      state,
      action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
    ) => {
      state.isLoading = true;
    },

    // create or update organization
    addOrEditOrganizationsAsyncRequest: (
      state,
      action: PayloadAction<AddEditOrganizationActionPayload>,
    ) => {
      state.isLoading = true;
    },
    addOrEditOrganizationsAsyncSuccess: (
      state,
      action: PayloadAction<AddEditOrganizationSuccessActionPayload>,
    ) => {
      state.isLoading = false;
      state.currentOrganization = action.payload.updatedValue;

      if (action.payload.actionType === 'new') {
        // @ts-ignore
        state.organizationList.data.push(action.payload.updatedValue);
      } else {
        findAndUpdate<OrganizationTypeInterface>(
          state.organizationList.data,
          action.payload.updatedValue,
        );
        const objIndex = state.organizationList.data.findIndex((obj) => {
          return obj.id === _.get(action.payload.updatedValue, 'id', '');
        });

        if (objIndex !== -1) {
          state.organizationList.data[objIndex] = action.payload.updatedValue;
        }
      }
    },
    addOrEditOrganizationsAsyncFailure: (state) => {
      state.isLoading = false;
    },

    // save the organization specific roles
    organizationRolesAsyncRequest: (
      state,
      action: PayloadAction<OrganizationSpecificActionPayload>,
    ) => {
      state.isLoading = action.payload.showLoader
        ? action.payload.showLoader
        : false;
    },
    organizationRolesAsyncSuccess: (
      state,
      action: PayloadAction<OrganizationRolesInterface[]>,
    ) => {
      state.isLoading = false;
      state.currentOrganizationRoles = action.payload;
    },
    organizationRolesAsyncFailure: (state) => {
      state.isLoading = false;
      state.currentOrganizationRoles = [];
    },

    // save the organization specific users
    organizationUsersAsyncRequest: (
      state,
      action: PayloadAction<OrganizationSpecificActionPayload>,
    ) => {
      state.isLoading = action.payload.showLoader
        ? action.payload.showLoader
        : false;
    },
    organizationUsersAsyncSuccess: (
      state,
      action: PayloadAction<OrganizationUserSuccessActionPayload>,
    ) => {
      state.isLoading = false;
      state.users = action.payload;
    },
    organizationUsersAsyncFailure: (state) => {
      state.isLoading = false;
      state.users = {
        data: [],
        meta: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
      };
    },

    // save the user specific details
    fetchUserDetailsAsyncRequest: (
      state,
      action: PayloadAction<ParticularUserDetailsActionPayload>,
    ) => {
      state.isLoading = true;
    },
    fetchUserDetailsAsyncSuccess: (
      state,
      action: PayloadAction<OrganizationUserTypeInterface>,
    ) => {
      state.isLoading = false;
      state.selectedUser = action.payload;
    },
    fetchUserDetailsAsyncFailure: (state) => {
      state.isLoading = false;
      state.selectedUser = null;
    },

    addOrEditOrgUserAsyncRequest: (
      state,
      action: PayloadAction<AddEditOrgUserActionPayload>,
    ) => {
      state.isLoading = true;
    },

    addOrEditOrgUserAsyncSuccess: (
      state,
      action: PayloadAction<AddEditOrgUserSuccessActionPayload>,
    ) => {
      state.isLoading = false;
      const { actionType, updatedValue } = action.payload;
      if (isCreateAction(actionType || '')) {
        state.selectedUser = updatedValue;
        // @ts-ignore
        state.users.data.push(updatedValue);
      } else {
        state.selectedUser = updatedValue;
        findAndUpdate<OrganizationUserTypeInterface>(
          state.users.data,
          updatedValue,
        );
      }
    },

    addOrEditOrgUserAsyncFailure: (state) => {
      state.isLoading = false;
    },

    //  list all the organization Supervisee
    fetchOrgSuperviseeDataSuccess: (
      state,
      action: PayloadAction<OrganizationDataActionPayload>,
    ) => {
      state.isLoading = false;
      state.superviseeList.data = action.payload.data;
      state.superviseeList.associatedOrg = action.payload.associatedOrg;
      state.superviseeList.pagination = action.payload.meta;
    },
    fetchOrgSuperviseeDataFailure: (state) => {
      state.isLoading = false;
    },
    fetchOrgSuperviseeDataRequest: (
      state,
      action?: PayloadAction<FetchOrganizationAP>,
    ) => {
      state.isLoading = true;
      state.superviseeList.data = [];
      state.superviseeList.associatedOrg = [];
      state.superviseeList.pagination = _.cloneDeep(DEFAULT_PAGINATION_OBJECT);
    },

    //  create the org supervisee
    addOrgSuperviseeDataSuccess: (state) => {
      state.superviseeList.isLoading = false;
    },
    addOrgSuperviseeDataFailure: (state) => {
      state.superviseeList.isLoading = false;
      // state.superviseeList.data = [];
      // state.superviseeList.associatedOrg = [];
      // state.superviseeList.pagination = _.cloneDeep(DEFAULT_PAGINATION_OBJECT);
    },
    addOrgSuperviseeDataRequest: (
      state,
      action?: PayloadAction<AddOrgSuperviseeAP>,
    ) => {
      state.superviseeList.isLoading = true;
    },

    // sync
    OrganizationTableClick: (
      state,
      action: PayloadAction<OrganizationTableClickAP>,
    ) => {
      state.currentOrganization = null;
    },
    userRowClick: (
      state,
      action: PayloadAction<OrganizationUserTypeInterface | null>,
    ) => {
      state.selectedUser = action.payload;
    },

    createNewOrganizationButtonClick: (state) => {
      state.currentOrganization = null;
      state.users.data = [];
      state.geoFence = [];
      state.commandPost = [];
      state.perimeterPoints = [];
      state.currentOrgCords = null;
    },
    createNewOrgUserButtonClick: (
      state,
      action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
    ) => {
      state.selectedUser = null;
    },

    fetchOrgSpecificData: (
      state,
      action: PayloadAction<OrganizationSpecificActionPayload>,
    ) => {
      // state.currentAppRoute = action.payload;
      console.log('%c action ', 'background: lime; color: black', action);
      state.users = {
        data: [],
        meta: _.cloneDeep(DEFAULT_PAGINATION_OBJECT),
      };
      state.currentOrganizationRoles = [];
    },
    setCurrentAppRoute: (
      state,
      action: PayloadAction<RoutesInterface | null>,
    ) => {
      state.currentAppRoute = action.payload;
    },

    updateGeoFence: (state, action: PayloadAction<UpdateGeoFenceAP>) => {
      state.geoFence = action.payload.points;
    },
    updatePerimeterPoints: (
      state,
      action: PayloadAction<UpdatePerimeterPointsAP>,
    ) => {
      const { index, point, type } = action.payload;
      if (type === 'update' && point) {
        if (_.isString(index))
          state.orgPps = state.orgPps.map((item: PPMarkerInterface) =>
            item.id === index
              ? {
                  ...item,
                  position: point.position,
                }
              : item,
          );
        else
          state.orgPps = state.orgPps.map(
            (item: PPMarkerInterface, seq: number) =>
              seq === index
                ? {
                    ...item,
                    position: point.position,
                  }
                : item,
          );
      }
      if (type === 'delete') {
        if (_.isString(index))
          state.orgPps = state.orgPps?.filter((item) => item.id !== index);
        else state.orgPps = state.orgPps?.filter((item, seq) => seq !== index);
      }
    },
    addPerimeterPoints: (
      state,
      action: PayloadAction<addPerimeterPointsAP>,
    ) => {
      state.orgPps[state.orgPps?.length] = {
        position: action.payload.points.position,
        icon: action.payload.points.icon,
        label: action.payload.points.label,
      };
    },
    updateCommandPost: (state, action: PayloadAction<UpdateCommandPostAP>) => {
      const { index, point, type } = action.payload;

      if (type === 'update' && point) {
        state.commandPost[index] = point;
      }
      if (type === 'delete') {
        state.commandPost = state.commandPost.filter(
          (item, seq) => seq !== index,
        );
      }
    },
    addCommandPost: (state, action: PayloadAction<AddCommandPostAP>) => {
      state.commandPost[state.commandPost.length] = action.payload.points;
    },

    updateEmsCP: (state, action: PayloadAction<UpdatePerimeterPointsAP>) => {
      const { index, point, type } = action.payload;
      if (!_.isNil(state.emsCommandPost) && _.isNumber(index)) {
        if (type === 'update' && point) {
          state.emsCommandPost[index] = point;
        }
        if (type === 'delete') {
          state.emsCommandPost = state.emsCommandPost.filter(
            (item, seq) => seq !== index,
          );
        }
      }
    },
    addEmsCP: (state, action: PayloadAction<addPerimeterPointsAP>) => {
      if (!_.isNil(state.emsCommandPost)) {
        state.emsCommandPost[state.emsCommandPost.length] =
          action.payload.points;
      } else {
        state.emsCommandPost = [action.payload.points];
      }
    },

    updateGeoJSONData: (state, action: PayloadAction<UpdateGeoJSONDataAP>) => {
      const { geoFence, ppPoints, cpPoints, emsCp, orgPps } = action.payload;

      state.geoFence = geoFence;
      state.perimeterPoints = ppPoints;
      state.commandPost = cpPoints;
      state.emsCommandPost = emsCp ? emsCp : null;
      state.orgPps = orgPps;
    },
    updateActiveStatusRequest: (
      state,
      action: PayloadAction<UpdateActiveStatusPayload>,
    ) => {
      state.isLoading = true;
    },
    updateActiveStatusSuccess: (state) => {
      state.isLoading = false;
    },
    updateActiveStatusFailure: (state) => {
      state.isLoading = false;
    },
    getCurrentOrgCoordinatesRequest: (
      state,
      action: PayloadAction<OrganizationTypeInterface>,
    ) => {
      state.isLoading = true;
    },
    getCurrentOrgCoordinatesSuccess: (
      state,
      action: PayloadAction<PositionInterface>,
    ) => {
      state.isLoading = false;
      state.currentOrgCords = action.payload;
    },
    getCurrentOrgCoordinatesFailure: (state) => {
      state.isLoading = false;
    },
    setCurrentOrgCoordinates: (
      state,
      action: PayloadAction<PositionInterface | null>,
    ) => {
      state.currentOrgCords = action.payload;
    },
    // setFormModalState: (state, action: PayloadAction<FormModal>) => {
    //   const { status, url } = action.payload;
    //   state.formModal = status;
    //   state.formDisposeUrl = url ? url : '';
    // },

    updateCpsRequest: (state, action: PayloadAction<UpdateCPsPayload>) => {
      state.isLoading = true;
    },
    updateCpsSuccess: (state) => {
      state.isLoading = false;
    },
    updateCpsFailure: (state) => {
      state.isLoading = false;
    },
    getDomainUsersRequest: (
      state,
      action: PayloadAction<DomainUsersPayload>,
    ) => {
      state.isDomainUsersLoading = true;
    },
    getDomainUsersSuccess: (
      state,
      action: PayloadAction<OrganizationUserSuccessActionPayload>,
    ) => {
      state.isDomainUsersLoading = false;
      state.domainUsers = action.payload;
    },
    getDomainUsersFailure: (state) => {
      state.isDomainUsersLoading = false;
    },
    updateFormDomainRequest: (
      state,
      action: PayloadAction<CreateNewOrgUserButtonClickActionPayload>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    ) => {},
    updateEventMapCenter: (
      state,
      action: PayloadAction<PositionInterface | null>,
    ) => {
      state.eventMapCenter = action.payload;
    },
    updateEventMapZoom: (state, action: PayloadAction<number | null>) => {
      state.eventMapZoom = action.payload;
    },
  },
});

export const organizationActions = organizationSlice.actions;

export default organizationSlice.reducer;
