// Use this site to name colors http://chir.ag/projects/name-that-color/#6195ED

export const COLORS = {
  CHARCOAL: '#364B57',
  PERSIAN_GREEN: '#009696',
  PERSIAN_GREEN_SHADOW: '#144E4E',
  MAX_BLUE_GREEN: '#009696',
  TEAL: '#41C7C7',
  GUN_METAL: '#2A2D33',
  SHARK: '#2A2D33',
  WHITE: '#FFFFFF',
  ABBEY: '#4D5153',
  TUNA: '#313439',
  LIGHT_GREY: '#E0E4E4',
  RAISIN_BLACK: '#222429',
  THEME_COLOR: '#121C21',
  BLACK: '#000000',
  CERA_GREY: '#FFFFFF99',
  GREEN: '#86EBA2',
  GREEN_ACCENT: '#53b873',
  WE_WAK: '#F2A09B',
  DISABLE_COLOR: '#767676',
  RED: '#FE6A6A',
  RED_SHADOW: '#c6373f',
  PURPLE: '#8591F7',
  WHITE_GREY: '#E7E8EBA',
  OPS_CENTER_GREY: '#292D33',
  BORDER_COLOR: '#707070',
  DATE_COLOR: '#B0B2B6',
  PP_COLOR: '#0C3DB2',
  ORANGE: '#EF8D33',
  DISABLED_REPORT: '#75777A',
  PRIORITY_RED: '#B74344',
  PRIORITY_YELLOW: '#FAF16B',
  GREY: '#3C424A',
  TEXT_BLACK: '#1E1E1E',
};
