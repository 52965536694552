import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Flex from 'components/General/Flex';
import { COLORS } from '../../styles/colorConstants';

const useSubHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      paddingTop: theme.spacing(0),
      borderBottom: `1px solid ${COLORS.ABBEY}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      font: 'normal normal bold 20px/30px Poppins',
      backgroundColor: COLORS.GUN_METAL,
      color: COLORS.WHITE,
    },
  }),
);

interface SubHeaderProps {
  children: React.ReactNode;
}

const SubHeader: React.FC<SubHeaderProps> = ({ children }: SubHeaderProps) => {
  const classes = useSubHeaderStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <Flex alignItems="center" style={{ flex: 1 }}>
        {children}
      </Flex>
    </Toolbar>
  );
};

interface LeftChildProps {
  children: React.ReactNode;
}

export const LeftChild = (props: LeftChildProps) => {
  const { children } = props;
  return (
    <Grid item xs={5}>
      <Flex>{children}</Flex>
    </Grid>
  );
};

interface MiddleChildProps {
  children: React.ReactNode;
}

export const MiddleChild = (props: MiddleChildProps) => {
  const { children } = props;
  return (
    <Grid item xs={4}>
      <Flex>{children}</Flex>
    </Grid>
  );
};

interface RightChildProps {
  children: React.ReactNode;
}

export const RightChild = (props: RightChildProps) => {
  const { children } = props;
  return (
    <Grid item xs={3}>
      <Flex justifyContent="flex-end">{children}</Flex>
    </Grid>
  );
};

export * from './NavigationBox';
export * from './NavigationLink';

export default SubHeader;
