import {
  Badge,
  Chip,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { COLORS } from 'styles/colorConstants';
import _ from 'lodash';
import { Carousel } from 'react-responsive-carousel';
import { FilesInterface, isDark } from 'utils';

const useStyles = makeStyles((theme: Theme) => ({
  unclickable: {
    color: COLORS.RAISIN_BLACK,
    fontFamily: 'Poppins',
    fontSize: '9px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  unclickableCtn: {
    marginTop: '8px',
    borderRadius: '20px',
    width: '54px',
    backgroundColor: COLORS.LIGHT_GREY,
    display: 'flex',
    justifyContent: 'center',
    marginRight: '8px',
    padding: '2px',
  },
  chipCtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginRight: '18px',
  },
  topMargin: {
    marginTop: '16px',
  },
  badge: {
    '& .MuiBadge-badge': {
      color: COLORS.WHITE_GREY,
      backgroundColor: COLORS.ORANGE,
      fontSize: '8px',
      height: '12px',
      maxWidth: '10px',
      width: '10px',
      padding: '0px',
    },
  },
  chip: {
    // marginTop: '8px',
    '&.MuiChip-clickable:hover': {
      backgroundColor: COLORS.PURPLE,
    },
    '&.MuiChip-clickable:active': {
      backgroundColor: COLORS.PURPLE,
    },
  },
  greyBgColor: {
    backgroundColor: COLORS.LIGHT_GREY,
  },
  purpleBgColor: {
    backgroundColor: COLORS.PURPLE,
  },
  carousel: {
    display: 'flex',
    flex: 1,
    maxHeight: '200px',
  },
  susImage: {
    width: '100%',
    maxHeight: '200px',
  },
  progresbarCtn: {
    display: 'flex',
    height: '200px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

// export function UnclickableSuspectCharsComp(props: { label: string }) {
//   const { label } = props;
//   const classes = useStyles();
//   return <Chip size="small" className={classes.unclickable} label={label} />;
// }

export function SuspectCharsComp(props: { label: string }) {
  const { label } = props;
  const classes = useStyles();
  return (
    <div className={classes.unclickableCtn}>
      <Typography className={classes.unclickable}>{label}</Typography>
    </div>
  );
}

export function SuspectColoredCharsComp(props: {
  label: string;
  value: string;
  id?: string;
}) {
  const { label, value } = props;
  const colorHex: string =
    value.charAt(0) !== '#' ? '#' + value.slice(2) : '#' + value.slice(3);

  const classes = useStyles();

  return (
    <div
      className={classes.unclickableCtn}
      style={{ backgroundColor: colorHex }}
    >
      <Typography
        className={classes.unclickable}
        style={{
          color: isDark(colorHex) ? COLORS.WHITE : COLORS.RAISIN_BLACK,
        }}
      >
        {label}
      </Typography>
    </div>
  );
}

export function ClickableSuspectCharsComp(props: {
  label: string; //value
  propertyKey?: string;
  onClick: any;
  properties: string[] | [];
  isActive: boolean;
  color?: string;
  isSelectedByCom?: boolean;
  isPresentInPD?: boolean;
  badge?: number;
  disable?: boolean;
}) {
  const {
    label,
    onClick,
    color,
    isSelectedByCom,
    isPresentInPD,
    badge,
    propertyKey,
    disable,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.chipCtn}>
      <Badge
        className={`${classes.topMargin} ${classes.badge}`}
        badgeContent={badge}
        overlap="circle"
        showZero={false}
        variant="standard"
      >
        <Chip
          onClick={() => {
            onClick(propertyKey, label);
          }}
          className={`${classes.chip} ${classes.unclickable}`}
          style={{
            backgroundColor: color
              ? color
              : isSelectedByCom
              ? COLORS.PURPLE
              : COLORS.LIGHT_GREY,
          }}
          size="small"
          label={label}
          data-cy={`${label.toLowerCase().replace(' ', '-')}`}
          clickable={true}
          variant={isSelectedByCom || isPresentInPD ? 'default' : 'outlined'}
          disabled={disable}
        />
      </Badge>
    </div>
  );
}

export const CarouselComponent = (props: { imgList: FilesInterface[] }) => {
  const { imgList } = props;
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  return (
    <Carousel
      showThumbs={false}
      axis="horizontal"
      className={classes.carousel}
      showIndicators={imgList.length === 1 ? false : true}
    >
      {imgList.map((imgObj: FilesInterface, index: number) => (
        <div key={imgObj.id}>
          {isLoading && (
            <div className={classes.progresbarCtn}>
              <CircularProgress />
            </div>
          )}
          <img
            onLoad={() => setLoading(false)}
            className={classes.susImage}
            alt=""
            src={imgObj.url}
          />
        </div>
      ))}
    </Carousel>
  );
};
