import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useVideoCallContainerStyle = makeStyles((theme: Theme) => {
  return createStyles({
    pictureInPictureContainer: {
      position: 'absolute',
      width: '200px',
      height: '200px',
      bottom: 100,
      left: 100,
      border: 'solid',
      borderWidth: '1px',
      zIndex: 10,
    },
    videoContainer: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    videoControls: {
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    videoClose: {
      position: 'absolute',
      top: 10,
      right: 10,
      zIndex: 10,
    },
    userVideoContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    userConnectingContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10%',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    incomingVideoCallCloseContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      marginRight: '20px',
    },
    incomingVideoCallNameContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '10px',
      marginRight: '20px',
    },
  });
});
