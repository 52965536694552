import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from 'config';
import { isAuthenticated, getAuthToken } from '../localStorage.service';
import { callAPIService } from './helper';
import { API_SERVICE_TYPES } from 'utils/constants';

export const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

export const apiService = (
  url: string,
  method: string,
  body?: any,
  headers?: any,
) => {
  let addAuthHeader = false;
  if (isAuthenticated()) {
    addAuthHeader = true;
  }

  const combinedHeaders = {
    ...headers,
  };

  if (addAuthHeader) {
    combinedHeaders['Authorization'] = `Bearer ${getAuthToken()}`;
  }

  let req: Promise<AxiosResponse<any>>;

  if (method === 'get') {
    req = http.get(url, { headers: combinedHeaders });
  }

  if (method === API_SERVICE_TYPES.POST) {
    req = http.post(url, body, { headers: combinedHeaders });
  }
  if (method === API_SERVICE_TYPES.PATCH) {
    req = http.patch(url, body, { headers: combinedHeaders });
  }

  if (method === API_SERVICE_TYPES.PUT) {
    req = http.put(url, body, { headers: combinedHeaders });
  }

  if (method === API_SERVICE_TYPES.DELETE) {
    req = http.delete(url, { headers: combinedHeaders });
  }

  // @ts-ignore
  return callAPIService(req);
};

export default http;
