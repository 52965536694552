import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Flex from 'components/General/Flex';
import _ from 'lodash';
import { FormField } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveCategory,
  getAllCategory,
  getUserDetailsFormValue,
  organizationActions,
} from 'store';
import {
  showBadgeFieldInOrganisation,
  showBadgeFieldUser,
} from 'routes/Users/utils/hook';
// import { useFlags } from 'launchdarkly-react-client-sdk';
// import { LAUNCH_DARKLY_KEYS } from 'utils/constants/ldKeys';
import { useLocation, useRouteMatch } from 'react-router';
import { isSpecialEventRoute } from 'utils';
// interface FormField {
//   name: string;
//   type: string;
//   component: any;
//   label: string;
//   placeHolder: string;
//   validate: any[];
// }

interface FormFieldContainerProps {
  fieldArr: FormField[];
}

const GridContainer = styled(Grid)({
  display: 'flex',
});
const GridWrapper = styled('div')({
  padding: '0rem 2rem',
  display: 'flex',
  flex: 1,
  '&::first-child': {
    paddingLeft: 0,
  },
});

export const FormFieldContainer = (props: FormFieldContainerProps) => {
  const formValue = useSelector(getUserDetailsFormValue);
  // const flags = useFlags();
  const dispatch = useDispatch();
  const currentRoute = useRouteMatch();
  // @ts-ignore
  const { id } = currentRoute.params;
  const allCategories = useSelector(getAllCategory);
  const activeCategory = useSelector(getActiveCategory);
  const currentLocation = useLocation();
  const pathName = currentLocation.pathname;
  const isSpecialEventOrg = isSpecialEventRoute(pathName);

  const totalElements = props.fieldArr?.length;
  const maxElementInARow = totalElements > 3 ? 3 : totalElements;
  let noOfRow = _.floor(totalElements / maxElementInARow);
  const showBadgeForDept = showBadgeFieldInOrganisation(
    activeCategory,
    allCategories,
  );
  if (totalElements % maxElementInARow != 0) {
    noOfRow += 1;
  }

  // Used to re-populate domain value if flag is turned on
  // useEffect(() => {
  //   // if (flags[LAUNCH_DARKLY_KEYS.ADD_DOMAIN_NAME]) {
  //   dispatch(
  //     organizationActions.updateFormDomainRequest({
  //       orgId: id,
  //     }),
  //   );
  //   // }
  //   // }, [flags[LAUNCH_DARKLY_KEYS.ADD_DOMAIN_NAME]]);
  // }, []);

  return (
    <Flex
      style={{
        flex: 1,
      }}
      column
    >
      {_.times(noOfRow, (rowIndex) => {
        return (
          <GridContainer key={`${rowIndex}-${maxElementInARow - rowIndex}`}>
            {_.times(maxElementInARow, (colIndex) => {
              const fieldObjIndex = rowIndex * maxElementInARow + colIndex;
              const showBadge = showBadgeFieldUser(allCategories, formValue);
              const fieldObj = props.fieldArr[fieldObjIndex];
              if (
                fieldObj &&
                (fieldObj.name !== 'batch_number' ||
                  showBadge ||
                  showBadgeForDept) &&
                (fieldObj.name !== 'domains' ||
                  // (flags[LAUNCH_DARKLY_KEYS.ADD_DOMAIN_NAME] &&
                  !isSpecialEventOrg)
                // )
              ) {
                return (
                  <GridWrapper
                    key={`${fieldObjIndex}-${colIndex}-${rowIndex}-grid`}
                  >
                    <Field
                      {...fieldObj}
                      key={`${fieldObjIndex}-${colIndex}-${rowIndex}`}
                    />
                  </GridWrapper>
                );
              }
              return <GridWrapper />;
            })}
          </GridContainer>
        );
      })}
    </Flex>
  );
};
