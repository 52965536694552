import React from 'react';
import {
  styled,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Flex from 'components/General/Flex';
import { COLORS } from 'styles/colorConstants';

interface NavigationBoxProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      borderStyle: 'solid',
      borderRadius: '6px 6px 6px 6px',
      borderWidth: '1px',
      borderColor: COLORS.ABBEY,
      padding: theme.spacing(0),
    },
  }),
);

export const NavigationBox: React.FC<NavigationBoxProps> = (
  props: NavigationBoxProps,
) => {
  const { children } = props;
  const classes = useStyles();
  return <Flex className={classes.container}> {children && children} </Flex>;
};

export default NavigationBox;
