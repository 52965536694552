import React, { useState } from 'react';
import _ from 'lodash';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { styled } from '@material-ui/core/styles';
import { COLORS } from 'styles/colorConstants';
import {
  CERA_ADMIN_SCOPES,
  compareFormObjects,
  ORG_FORM_KEYS,
  REDUX_FORM_CONSTANT,
  USER_FORM_KEYS,
} from 'utils';
import {
  EMS_ROUTES,
  ORGANIZATION_ROUTES,
  POLICE_DEPT_ROUTES,
} from 'routes/path';
import { useHistory } from 'react-router';
import {
  getOrganizationFormValue,
  getPoliceDeptFormValue,
  getSelectedOrganization,
  isOrganizationDeptFormTouched,
  isPoliceDeptFormTouched,
} from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { destroy } from 'redux-form';
import { FormSaveConfirmationModal } from 'components/Form/FormModal';
import {
  getCurrentUser,
  getUserForms,
  haveValidScope,
  useModal,
} from 'utils/hooks';

const BreadCrumbContainer = styled(Breadcrumbs)({
  color: COLORS.WHITE,
  textTransform: 'capitalize',
});

const CustomLink = styled(Link)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '150px',
  cursor: 'pointer',
});
export interface BreadcrumbDataProps {
  label: string;
  value: string;
  onClick?: any;
}

interface CustomBreadcrumbsProps {
  breadcrumbDataArray: BreadcrumbDataProps[];
}

interface BreadcrumbLinkProps {
  label: string;
  isLastBreadcrumb: boolean;
  onClick?: any;
  isFirstBreadcrumb: boolean;
  data_cy: number;
}

const BreadcrumbLink = ({
  label,
  isLastBreadcrumb,
  onClick,
  isFirstBreadcrumb,
  data_cy,
}: BreadcrumbLinkProps) => {
  const updatedLabel = label === 'qr-code' ? 'QR Code' : label;
  if (isLastBreadcrumb || isFirstBreadcrumb) {
    return <Typography data-cy={data_cy}>{updatedLabel}</Typography>;
  }

  return (
    <CustomLink data-cy={data_cy} color="inherit" onClick={onClick}>
      {updatedLabel}
    </CustomLink>
  );
};

const CustomBreadcrumbs: React.FC<CustomBreadcrumbsProps> = ({
  breadcrumbDataArray,
}: CustomBreadcrumbsProps) => {
  const history = useHistory();
  const [open, openModal, closeModal] = useModal();
  const currentOrg = useSelector(getSelectedOrganization);
  const currentUser = getCurrentUser();
  const [link, setLink] = useState<any>('');
  const getOrgForm = useSelector(getOrganizationFormValue);
  const getPoliceForm = useSelector(getPoliceDeptFormValue);
  const [userForm, isUserTouched] = getUserForms();
  const dispatch = useDispatch();
  const isPoliceFormTouched = useSelector(isPoliceDeptFormTouched);
  const isOrgFormTouched = useSelector(isOrganizationDeptFormTouched);

  const handleStay = () => {
    closeModal();
  };
  const destroyForms = (link: any) => {
    dispatch(destroy(REDUX_FORM_CONSTANT.ORGANIZATION));
    dispatch(destroy(REDUX_FORM_CONSTANT.POLICE_DEPT));
    history.push(link);
  };

  const handleLeave = () => {
    destroyForms(link);
    closeModal();
  };

  const callback = (value: string) => {
    if (
      haveValidScope(CERA_ADMIN_SCOPES) ||
      (value !== POLICE_DEPT_ROUTES.POLICE_DEPT.path &&
        value !== ORGANIZATION_ROUTES.ORGANIZATION.path &&
        value !== EMS_ROUTES.EMS_ORG_LIST.path)
    ) {
      setLink(value);
      if (
        !_.isEmpty(userForm) &&
        isUserTouched &&
        compareFormObjects(currentUser, userForm, USER_FORM_KEYS)
      ) {
        openModal();
      } else if (
        haveValidScope(CERA_ADMIN_SCOPES) &&
        (value === POLICE_DEPT_ROUTES.POLICE_DEPT.path ||
          value === ORGANIZATION_ROUTES.ORGANIZATION.path ||
          value === EMS_ROUTES.EMS_ORG_LIST.path)
      ) {
        if (
          (isPoliceFormTouched &&
            compareFormObjects(currentOrg, getPoliceForm, ORG_FORM_KEYS)) ||
          (isOrgFormTouched &&
            compareFormObjects(currentOrg, getOrgForm, ORG_FORM_KEYS))
        ) {
          openModal();
        } else {
          destroyForms(value);
        }
      } else history.push(value);
    }
  };

  return (
    <div>
      <BreadCrumbContainer
        separator={<NavigateNextIcon fontSize="large" />}
        aria-label="breadcrumb"
      >
        {breadcrumbDataArray.map(
          (breadcrumbData: BreadcrumbDataProps, index: number) => {
            const { label, value } = breadcrumbData;
            const isLastBreadcrumb = index === breadcrumbDataArray.length - 1;
            const isFirstBreadcrumb = haveValidScope(CERA_ADMIN_SCOPES)
              ? false
              : index === 0;

            return (
              <BreadcrumbLink
                key={index}
                data_cy={index}
                label={label}
                isLastBreadcrumb={isLastBreadcrumb}
                isFirstBreadcrumb={isFirstBreadcrumb}
                onClick={() => callback(value)}
              />
            );
          },
        )}
      </BreadCrumbContainer>
      <FormSaveConfirmationModal
        handleLeave={handleLeave}
        handleStay={handleStay}
        open={open}
      />
    </div>
  );
};

export default CustomBreadcrumbs;
