import _ from 'lodash';
import { RootState } from 'store';

export const getIsOngoingVideoCall = (state: RootState) =>
  _.get(state, 'videoCall.isVideoCallOngoing');

export const getIncomingVideoCalls = (state: RootState) => {
  return _.get(state, 'videoCall.incomingCalls');
};

export const getOutgoingVideoCall = (state: RootState) => {
  return _.get(state, 'videoCall.outgoingCall');
};

export const getOutgoingVideoCallId = (state: RootState) => {
  return _.get(state, 'videoCall.outgoingCall.id');
};

export const getVideoCallReceiverName = (state: RootState) => {
  return _.get(state, 'videoCall.data.receiverName');
};

export const getVideoCallCallerName = (state: RootState) => {
  return _.get(state, 'videoCall.data.callerName');
};

export const getVideoCallCallerId = (state: RootState) => {
  return _.get(state, 'videoCall.data.callerId');
};

export const getVideoCallUserId = (state: RootState) => {
  return _.get(state, 'videoCall.data.callUserId');
};

export const getVideoCallId = (state: RootState) =>
  _.get(state, 'videoCall.data.id');

export const getVideoCallChannelId = (state: RootState) =>
  _.get(state, 'videoCall.data.channelId');

export const getVideoCallToken = (state: RootState) =>
  _.get(state, 'videoCall.data.token');
