import {
  ARReportTypesInterface,
  ARAddProfileInterface,
  AREventInterface,
  ARReportListInterface,
  ARProfilesInterface,
  ARAddPersonDescriptionInterface,
  AREventEndInterface,
  EventEndPayloadInterface,
  SendUserLocationPayload,
  ARJoinEventRequest,
  ReportCEServicePayload,
  ARLeaveActiveEventInterface,
  AREventJoinRequestsInterface,
  ARDenyEventJoinRequestInterface,
  ARAcceptEventJoinRequestInterface,
} from 'utils/types';
import { API_SERVICE_TYPES } from 'utils/constants';

import { apiService } from './module/api.service';

export const getEventsService = async (
  latitude: number,
  longitude: number,
): Promise<AREventInterface> => {
  return <Promise<AREventInterface>>(
    apiService(
      `/event?latitude=${latitude}&longitude=${longitude}`,
      API_SERVICE_TYPES.GET,
    )
  );
};

export const getReportListService = async (
  id: string,
  report_type?: string,
): Promise<ARReportListInterface> => {
  return <Promise<ARReportListInterface>>(
    apiService(
      report_type
        ? `/event/${id}/report?report_type=${report_type}`
        : `/event/${id}/report`,
      API_SERVICE_TYPES.GET,
    )
  );
};

export const addNewProfileService = async (props: {
  id: string;
  body: any;
}): Promise<ARAddProfileInterface> => {
  const { id, body } = props;
  return <Promise<ARAddProfileInterface>>(
    apiService(`/event/${id}/profile`, API_SERVICE_TYPES.POST, body)
  );
};

export const fetchReportTypeService = async (): Promise<ARReportTypesInterface> => {
  return <Promise<ARReportTypesInterface>>(
    apiService(`/report_type`, API_SERVICE_TYPES.GET)
  );
};

export const fetchProfilesService = async (
  id: string,
  report_type?: string,
  resolved?: boolean | null,
): Promise<ARProfilesInterface> => {
  return <Promise<ARProfilesInterface>>(
    apiService(
      report_type
        ? `/event/${id}/profile?report_type=${report_type}&resolved_at=${resolved}`
        : `/event/${id}/profile?resolved_at=${resolved}`,
      API_SERVICE_TYPES.GET,
    )
  );
};

export const updateProfilesService = async (
  id: string,
  body: any,
): Promise<ARAddProfileInterface> => {
  return <Promise<ARAddProfileInterface>>(
    apiService(`/profile/${id}`, API_SERVICE_TYPES.PATCH, body, {
      'Content-Type':
        'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    })
  );
};

export const addPersonDescriptionService = async (
  id: string,
  personDescriptionIdObj: { id: string },
): Promise<ARAddPersonDescriptionInterface> => {
  return <Promise<ARAddPersonDescriptionInterface>>(
    apiService(
      `/profile/${id}/person_description`,
      API_SERVICE_TYPES.POST,
      personDescriptionIdObj,
    )
  );
};

export const endEventService = (id: string, body: EventEndPayloadInterface) => {
  return <Promise<AREventEndInterface>>(
    apiService(`/event/${id}/resolve`, API_SERVICE_TYPES.POST, body)
  );
};

export const leaveActiveEventService = (id: string) => {
  return <Promise<ARLeaveActiveEventInterface>>(
    apiService(`/event/${id}/leave`, API_SERVICE_TYPES.POST)
  );
};

export const sendUserLocationService = (body: SendUserLocationPayload) => {
  return apiService('/location_update', API_SERVICE_TYPES.POST, body);
};

export const sendEventJoinRequest = (id: string) => {
  return <Promise<ARJoinEventRequest>>(
    apiService(`/event/${id}/join`, API_SERVICE_TYPES.POST)
  );
};

export const deleteEventJoinRequest = (id: string) => {
  return <Promise<ARJoinEventRequest>>(
    apiService(`/event_join_request/${id}`, API_SERVICE_TYPES.DELETE)
  );
};

export const reportCriticalEvent = (body: ReportCEServicePayload) => {
  return <Promise<ARJoinEventRequest>>(
    apiService(`/event?is_special_event=true`, API_SERVICE_TYPES.POST, body)
  );
};

export const fetchEventJoinRequestsService = async (
  eventId: string,
  last_fetched_time?: string,
): Promise<AREventJoinRequestsInterface> => {
  let extendedQuery = '';
  if (last_fetched_time && last_fetched_time !== '') {
    extendedQuery = '?last_updated_at=$lastFetchedTime';
  }
  return <Promise<AREventJoinRequestsInterface>>(
    apiService(
      `event/${eventId}/event_join_request${extendedQuery}`,
      API_SERVICE_TYPES.GET,
    )
  );
};
export const acceptEventJoinRequestService = async (payload: {
  joinRequestId: string;
  requestBody: any;
}): Promise<ARAcceptEventJoinRequestInterface> => {
  const { joinRequestId, requestBody } = payload;
  return <Promise<ARAcceptEventJoinRequestInterface>>(
    apiService(
      `event_join_request/${joinRequestId}`,
      API_SERVICE_TYPES.PATCH,
      requestBody,
    )
  );
};
export const denyEventJoinRequestService = async (payload: {
  joinRequestId: string;
  requestBody: any;
}): Promise<ARDenyEventJoinRequestInterface> => {
  const { joinRequestId, requestBody } = payload;
  return <Promise<ARDenyEventJoinRequestInterface>>(
    apiService(
      `event_join_request/${joinRequestId}`,
      API_SERVICE_TYPES.PATCH,
      requestBody,
    )
  );
};
