import React from 'react';
import PropTypes from 'prop-types';

interface FlexProps {
  children?: React.ReactNode;
  className?: any;
  direction?: string;
  justifyContent?: string;
  alignItems?: string;
  wrap?: boolean;
  fullWidth?: string;
  inline?: string;
  center?: string;
  column?: boolean;
  style?: any;
  onClick?: any;
}

const Flex = (props: FlexProps) => {
  const {
    className,
    children,
    direction,
    justifyContent,
    alignItems,
    wrap,
    fullWidth,
    inline,
    center,
    column,
    style,
    ...args
  } = props;
  return (
    <div
      {...args}
      className={className}
      style={{
        display: inline ? 'inline-flex' : 'flex',
        flexWrap: wrap ? 'wrap' : 'nowrap',
        flexDirection: column ? 'column' : direction,
        justifyContent: center ? 'center' : justifyContent,
        alignItems: center ? 'center' : alignItems,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

Flex.propTypes = {
  /** Determine which css flex `flex-direction` should use */
  direction: PropTypes.string,
  /** Determine which css flex `justify-content` should use */
  justifyContent: PropTypes.string,
  /** Determine which css flex `align-items` should use */
  alignItems: PropTypes.string,
  /** Use for set `flex-wrap` as `'wrap'` */
  wrap: PropTypes.bool,
  /** Use for set css`display` as `'inline'` */
  inline: PropTypes.bool,
  /** Use for set `justify-content` and `align-items` as `'center'` */
  center: PropTypes.bool,
  /** Use for set `flex-direction` as `'column'` */
  column: PropTypes.bool,
};

Flex.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'normal',
  direction: 'row',
  wrap: false,
  inline: false,
  center: false,
  column: false,
};

export default Flex;
