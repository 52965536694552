import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'styles/colorConstants';

interface CustomButtonProps {
  label: string;
  error?: boolean;
  onClick?: any;
  disabled?: boolean;
  datacy?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  greenBtnColor: {
    backgroundColor: COLORS.GREEN,
    boxShadow: `0px 4px 0px ${COLORS.GREEN_ACCENT}`,
    '&:hover': {
      backgroundColor: COLORS.GREEN,
      color: COLORS.BLACK,
    },
  },
  redBtnColor: {
    backgroundColor: COLORS.RED,
    boxShadow: `0px 4px 0px ${COLORS.RED_SHADOW}`,
    '&:hover': {
      backgroundColor: COLORS.RED,
      color: COLORS.BLACK,
    },
  },
  btnSize: {
    color: COLORS.BLACK,
    padding: '5px 30px',
    borderRadius: '4px',
    fontStyle: 'Noto Sans',
    fontSize: '22px',
  },
}));

export const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  error,
  onClick,
  disabled,
  datacy,
}: CustomButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      data-cy={datacy ? datacy : label.toLowerCase().replace(' ', '-')}
      onClick={onClick ? onClick : () => console.log('')}
      type="submit"
      className={`${classes.btnSize} ${
        error ? classes.redBtnColor : classes.greenBtnColor
      }`}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default Text;
