import AgoraRTC, {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteVideoTrack,
  UID,
} from 'agora-rtc-sdk-ng';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { AGORA_APP_ID } from 'config/api';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIncomingVideoCalls,
  getIsOngoingVideoCall,
  getVideoCallCallerId,
  getVideoCallCallerName,
  getVideoCallChannelId,
  getVideoCallId,
  getVideoCallReceiverName,
  getVideoCallToken,
  getVideoCallUserId,
} from 'store/videoCall/videoCall.selector';
import { getAuthenticatedUserId } from 'store';
import { videoCallActions, VideoCallData } from 'store/videoCall';
import { isArray } from 'lodash';

interface IAgoraRtc {
  localAudioTrack: IMicrophoneAudioTrack | null;
  localVideoTrack: ICameraVideoTrack | null;
  client: IAgoraRTCClient | null;
}

export interface IAgoraUser {
  uid: UID;
  audio: boolean;
  video: boolean;
  client: boolean;
  videoTrack: IRemoteVideoTrack | ICameraVideoTrack | undefined | null;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export interface OngoingVideoCallPropType {
  users: IAgoraUser[];
  isVideoCallOngoing: boolean;
  setAudioEnabled: (a: boolean) => void;
  audioEnabled: boolean;
  leaveChannel: (a: boolean) => void;
  otherUserName: string;
  setPnPMode: (a: boolean) => void;
}

export interface PnPOngoingVideoCallPropType extends OngoingVideoCallPropType {
  isPnPMode: boolean;
}

const useVideoCall = (): PnPOngoingVideoCallPropType => {
  const rtc = useRef<IAgoraRtc>({
    localAudioTrack: null,
    localVideoTrack: null,
    client: null,
  });

  const dispatch = useDispatch();
  const [users, setUsers] = useState<IAgoraUser[]>([]);
  const [audioEnabled, setAudioEnabled] = useState<boolean>(true);
  const [videoEnabled, setVideoEnabled] = useState<boolean>(true);

  const isVideoCallOngoing = useSelector(getIsOngoingVideoCall);
  const videoCallToken = useSelector(getVideoCallToken);
  const videoCallChannelId = useSelector(getVideoCallChannelId);
  const videoCallId = useSelector(getVideoCallId);
  const authUserId: string = useSelector(getAuthenticatedUserId);
  const callerId = useSelector(getVideoCallCallerId);
  const callerName = useSelector(getVideoCallCallerName);
  const receiverName = useSelector(getVideoCallReceiverName);

  const [isPnPMode, setIsPnPMode] = useState(false);

  let otherUserName;

  if (authUserId === callerId) {
    otherUserName = receiverName;
  } else {
    otherUserName = callerName;
  }

  const userId = useSelector(getVideoCallUserId);

  rtc.current;
  const initClientEvents = () => {
    rtc.current?.client?.on('user-published', async (user, mediaType) => {
      // New User Enters
      if (rtc.current?.client?.connectionState === 'CONNECTED') {
        await rtc.current.client?.subscribe(user, mediaType);
      }
      console.log('data?.visible', 'user-published', user.uid);

      if (mediaType === 'video') {
        const remoteVideoTrack = user.videoTrack;
        setUsers((prevUsers) => {
          return [
            ...prevUsers,
            {
              uid: user.uid,
              audio: user.hasAudio,
              video: user.hasVideo,
              client: false,
              videoTrack: remoteVideoTrack,
            },
          ];
        });
      }

      if (mediaType === 'audio') {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack?.play();
        setUsers((prevUsers) => {
          return prevUsers.map((User) => {
            if (User.uid === user.uid) {
              return { ...User, audio: user.hasAudio };
            }
            return User;
          });
        });
      }
    });

    rtc.current.client?.on('user-unpublished', (user, type) => {
      //User Leaves
      if (type === 'audio') {
        setUsers((prevUsers) => {
          return prevUsers.map((User) => {
            if (User.uid === user.uid) {
              return { ...User, audio: !User.audio };
            }
            return User;
          });
        });
      }
      if (type === 'video') {
        setUsers((prevUsers) => {
          const newUsers = prevUsers.filter((User) => User.uid !== user.uid);

          if (newUsers.length == 0) {
            leaveChannel(true);
          }
          return newUsers;
        });
      }
    });
  };

  const init = async (name: string, token: string, userId: string) => {
    rtc.current.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

    initClientEvents();

    const uid = await rtc.current.client.join(
      AGORA_APP_ID,
      name,
      token,
      userId,
    );

    // Create an audio track from the audio sampled by a microphone.
    rtc.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a video track from the video captured by a camera.
    rtc.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

    // Adding the caller to the Users State
    // setUsers((prevUsers) => {
    //   return [
    //     ...prevUsers,
    //     {
    //       uid: uid,
    //       audio: true,
    //       video: true,
    //       client: true,
    //       videoTrack: rtc.current.localVideoTrack,
    //     },
    //   ];
    // });

    if (rtc.current?.client?.connectionState === 'CONNECTED') {
      // Publishing the Streams
      await rtc.current.client.publish([
        rtc.current.localAudioTrack,
        rtc.current.localVideoTrack,
      ]);
    }
  };

  const leaveChannel = async (shouldEndVideoCall: boolean) => {
    dispatch(videoCallActions.endVideoCall());

    const remoteUsers = rtc.current.client?.remoteUsers;

    if (isArray(remoteUsers) && remoteUsers.length > 0) {
      // Destroy the local audio and video tracks.
      await rtc.current.localAudioTrack?.close();
      await rtc.current.localVideoTrack?.close();
      await rtc.current.client?.leave();
      setUsers([]);
    }
  };

  useEffect(() => {
    console.log('audioEnabled', audioEnabled);
    rtc.current.localAudioTrack?.setEnabled(audioEnabled);
  }, [audioEnabled]);

  useEffect(() => {
    rtc.current.localVideoTrack?.setEnabled(videoEnabled);
  }, [videoEnabled]);

  useEffect(() => {
    let intervalId: any;
    if (isVideoCallOngoing) {
      intervalId = setInterval(() => {
        dispatch(videoCallActions.pingVideoCall(videoCallId));
      }, 3000);
      init(videoCallChannelId, videoCallToken, userId);
    }
    return () => {
      // leaveChannel(isVideoCallOngoing);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isVideoCallOngoing, videoCallChannelId, videoCallToken, userId]);

  return {
    users,
    isVideoCallOngoing,
    setAudioEnabled,
    audioEnabled,
    leaveChannel,
    otherUserName,
    isPnPMode,
    setPnPMode: (a: boolean) => setIsPnPMode(a),
  };
};

export default useVideoCall;
