import React, { useEffect } from 'react';
import _ from 'lodash';
import { push } from 'connected-react-router';
import Table from 'components/General/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersData } from 'store/users/users.selectors';
import { USERS_TABLE_COLUMN } from 'utils/constants';
import { usersActions } from 'store/users';
import { UserInterface } from 'utils/types';
import { USERS_ROUTES } from 'routes/path';
import { replaceKeyWithValues } from 'routes/Organization/helper';

interface UsersTableProps {}

export const UsersList: React.FC<UsersTableProps> = (
  props: UsersTableProps,
) => {
  const userList = useSelector(getUsersData);
  const dispatch = useDispatch();

  const handleRowClick = (rowObj: UserInterface) => {
    const slug = USERS_ROUTES.USERS_DETAILS.path;
    const replaceWith = {
      id: rowObj.id,
    };
    let url = replaceKeyWithValues(slug, replaceWith);
    dispatch(usersActions.updateActiveUser({ data: null }));

    if (_.isArray(url)) {
      url = url[1];
    }

    dispatch(push(url));
  };

  useEffect(() => {
    dispatch(usersActions.fetchUserDataRequest());
  }, []);

  return (
    <Table
      columnData={USERS_TABLE_COLUMN}
      rowData={userList || []}
      onRowClick={handleRowClick}
    />
  );
};

export default UsersList;
