import _ from 'lodash';
import { RootState } from '../index';
import { REDUX_FORM_CONSTANT } from 'utils/constants';

export const getOrganizationData = (state: RootState) =>
  state.organization.organizationList;

export const getAppRouteObject = (state: RootState) => {
  state.organization.currentAppRoute;
};

export const getOrganizationFormValue = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.ORGANIZATION}.values`, {});

export const isOrganizationDeptFormTouched = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.ORGANIZATION}.anyTouched`, false);

export const getSelectedOrganization = (state: RootState) =>
  _.get(state, `organization.currentOrganization`, null);

export const getOrganizationUsers = (state: RootState) =>
  _.get(state, `organization.users`, []);

export const getSelectedUsers = (state: RootState) =>
  _.get(state, `organization.selectedUser`, {});

export const getOrganizationRoles = (state: RootState) =>
  _.get(state, `organization.currentOrganizationRoles`, []);

export const getOrgUserFormValue = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.ORG_USER}.values`, {});

export const isOrgUserFormTouched = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.ORG_USER}.anyTouched`, false);

export const getOrgUserFormErrors = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.ORG_USER}.syncErrors`, {});

export const getPoliceUserFormValue = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.POLICE_USER}.values`, {});

export const isPoliceUserFormTouched = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.POLICE_USER}.anyTouched`, false);

export const getPoliceUserFormErrors = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.POLICE_USER}.syncErrors`, {});

export const getEMSUserFormValue = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.EMS_USER}.values`, {});

export const isEMSUserFormTouched = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.EMS_USER}.anyTouched`, false);

export const getEMSUserFormErrors = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.EMS_USER}.syncErrors`, {});

export const getSpecialEventUserFormValue = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.SPECIAL_EVENT_USER}.values`, {});

export const isSpecialEventUserFormTouched = (state: RootState) =>
  _.get(
    state,
    `form.${REDUX_FORM_CONSTANT.SPECIAL_EVENT_USER}.anyTouched`,
    false,
  );

export const getSpecialEventUserFormErrors = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.SPECIAL_EVENT_USER}.syncErrors`, {});

export const getOrgSupervisee = (state: RootState) =>
  _.get(state, `organization.superviseeList`, {});

export const getAssOrgList = (state: RootState) =>
  _.get(state, `organization.superviseeList.associatedOrg`, []);

export const getAssociateOrgFormValue = (state: RootState) =>
  _.get(state, `form.${REDUX_FORM_CONSTANT.ASSOCIATED_ORG}.values`, {});

export const getAssociatedOrgLoadingStatus = (state: RootState) =>
  _.get(state, `organization.superviseeList.isLoading`, false);

export const getGeoFencePoints = (state: RootState) =>
  _.get(state, `organization.geoFence`, []);

export const getPerimeterPoints = (state: RootState) =>
  _.get(state, `organization.perimeterPoints`, []);

export const getCommandPostPoints = (state: RootState) =>
  _.get(state, `organization.commandPost`, []);

export const getEMSCommandPostPoints = (state: RootState) =>
  _.get(state, `organization.emsCommandPost`, []);

export const getCurrentOrgCoordinates = (state: RootState) =>
  _.get(state, `organization.currentOrgCords`, null);

export const getEventMapCenter = (state: RootState) =>
  _.get(state, `organization.eventMapCenter`, null);

export const getEventMapZoom = (state: RootState) =>
  _.get(state, `organization.eventMapZoom`, null);

export const getOrganizationDeptFormErrors = (state: RootState) =>
  _.get(
    state,
    `form.${REDUX_FORM_CONSTANT.ORGANIZATION}.syncErrors`,
    undefined,
  );

export const isLoadingDomainUsers = (state: RootState): boolean =>
  _.get(state, `organization.isDomainUsersLoading`, false);

export const getDomainUsers = (state: RootState) =>
  _.get(state, `organization.domainUsers`, []);

// export const getFormModalStatus = (state: RootState) =>
//   _.get(state, 'organization.formModal', undefined);

// export const getFormModalUrl = (state: RootState) =>
//   _.get(state, 'organization.formDisposeUrl', '');

export const getOrgPerimeterPoints = (state: RootState) =>
  _.get(state, `organization.orgPps`, []);
