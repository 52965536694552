import React from 'react';
import { ReactComponent as VideoCallEndIcon } from 'assets/video-call-end.svg';
import { useSelector } from 'react-redux';
import { videoCallActions, VideoCallData } from 'store/videoCall';
import { getOutgoingVideoCall } from 'store/videoCall/videoCall.selector';
import { useVideoCallContainerStyle } from './styles';

interface Props {
  onClick: () => void;
}
export const OutgoingVideoCall = (props: Props) => {
  const classes = useVideoCallContainerStyle();
  const { onClick } = props;

  const outgoingVideoCall: VideoCallData = useSelector(getOutgoingVideoCall);

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div id="videos" className={classes.videoContainer}>
        <div className={classes.videoControls}>
          <div>
            <VideoCallEndIcon onClick={() => onClick()} />
          </div>
        </div>
        <div className={classes.userConnectingContainer}>
          <h2>{outgoingVideoCall && outgoingVideoCall.receiverName}</h2>
          <small>Calling...</small>
        </div>
      </div>
    </div>
  );
};
