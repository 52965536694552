import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import OrganizationTable from 'components/OrganizationTable';
import { AppCategory } from 'utils/commonTypes';
import { AppModal } from 'components/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { getModalOpenStatus, modalActions } from 'store/modal';
import Flex from 'components/General/Flex';
import ComboBox from 'components/General/Form/ComboBox';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { isRequired } from 'utils/helper';
import { FormFieldContainer } from 'components/Form';
import { REDUX_FORM_CONSTANT } from 'utils/constants';
import _ from 'lodash';
import { reduxForm, InjectedFormProps } from 'redux-form';
import {
  getAssOrgList,
  getAssociateOrgFormValue,
  organizationActions,
  getAssociatedOrgLoadingStatus,
  getSelectedOrganization,
} from 'store/organization';
import { COLORS } from 'styles/colorConstants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useRouteMatch } from 'react-router';

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      width: '100%',
      // margin: theme.spacing(-2),
    },
    bodyContainer: {
      width: '100%',
      justifyContent: 'center',
    },
    title: {
      fontSize: theme.typography.fontSize,
      textAlign: 'center',
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    actionButton: {
      maxWidth: '200px',
      width: '100%',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      borderRadius: '8px',
      backgroundColor: COLORS.GREEN,
      color: COLORS.BLACK,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
      boxShadow: `0px 6px 0px ${COLORS.GREEN_ACCENT}`,
      cursor: 'pointer',
    },
  }),
);

const FORM_FIELDS_ARRAY = [
  {
    name: 'associatedOrg',
    type: 'text',
    component: ComboBox,
    id: 'associated-org',
    label: 'Organization Name',
    displayValuePath: 'name',
    placeHolder: 'Enter Organization Name',
    validate: [isRequired],
    required: true,
    options: [],
  },
];

const AssOrgController = (props: InjectedFormProps) => {
  const orgList = useSelector(getAssOrgList);
  const [formFields, setFormFields] = useState(_.cloneDeep(FORM_FIELDS_ARRAY));
  useEffect(() => {
    formFields[0].options = _.cloneDeep(orgList);
    setFormFields(formFields);
  }, [orgList]);

  return (
    <div>
      <form>
        <Box p={1} display="flex">
          <Grid container>
            <FormFieldContainer fieldArr={formFields} />
          </Grid>
        </Box>
      </form>
    </div>
  );
};

const AssOrgRFWrapper = reduxForm({
  form: REDUX_FORM_CONSTANT.ASSOCIATED_ORG,
})(AssOrgController);

export const SuperviseeList = () => {
  const classes = useHeaderStyles();
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrganization);
  const isOpen = useSelector(getModalOpenStatus);
  const selectedAssociatedOrgForm = useSelector(getAssociateOrgFormValue);
  const isLoading = useSelector(getAssociatedOrgLoadingStatus);
  const currentRoute = useRouteMatch();
  // @ts-ignore
  const orgId = currentRoute.params?.id;
  const handleClose = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSaveButton = () => {
    dispatch(
      organizationActions.addOrgSuperviseeDataRequest({
        asOrgObj: selectedAssociatedOrgForm.associatedOrg,
        categoryType: AppCategory.ORGANIZATION,
      }),
    );
  };

  useEffect(() => {
    if (_.isEmpty(selectedOrg)) {
      dispatch(
        organizationActions.fetchParticularOrganizationDetailsRequest({
          orgId: orgId,
        }),
      );
    }
  }, [selectedOrg]);

  return (
    <div>
      <OrganizationTable categoryType={AppCategory.ORGANIZATION} isSupervisee />
      <AppModal open={isOpen} onClose={handleClose}>
        <Flex className={classes.container}>
          <div className={classes.bodyContainer}>
            <div className={classes.title}> Add Associated Organization </div>
            <div className={classes.formContainer}>
              <AssOrgRFWrapper />
            </div>
            <Flex justifyContent="center" onClick={handleSaveButton}>
              <div className={classes.actionButton}>
                Save &nbsp;
                {isLoading && <CircularProgress size={20} />}
              </div>
            </Flex>
          </div>
        </Flex>
      </AppModal>
    </div>
  );
};
