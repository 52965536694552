import axios from 'axios';
import GeoCode from 'react-geocode';
import { GOOGLE_MAP_API } from '../config/index';

// const GOOGLE_MAP_API = 'AIzaSyA9Z9qAGXcv_WU7kNIwUX2viOrui8g5UMM';

GeoCode.setApiKey(GOOGLE_MAP_API);

// set response language. Defaults to english.
GeoCode.setLanguage('en');

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result

// @ts-ignore
GeoCode.setLocationType('ROOFTOP');

export const getGeoCoordinates = async (address: string) => {
  try {
    const response = await GeoCode.fromAddress(address);

    const { lat, lng } = response.results[0].geometry.location;

    console.log('%c defaultGeoCode ', 'background: black; color: yellow', {
      lat,
      lng,
    });

    return { lat, lng };
  } catch (err) {
    console.log(
      '%c error inside the  getGeoCoordinates  ---> ',
      'background: salmon; color: black',
      err,
    );
    throw err;
  }
};

export const getIPAddress = async (): Promise<any> => {
  try {
    const response = await axios.get('https://geolocation-db.com/json/');

    const data = response.data;

    return data.IPv4;
  } catch (err) {
    console.log(
      '%c error inside the  getIPAddress  ---> ',
      'background: salmon; color: black',
      err,
    );
    throw err;
  }
};
