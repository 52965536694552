import { API_SERVICE_TYPES } from 'utils/constants';
import { apiService } from './module/api.service';
import {
  MarkerObj,
  PaginationInterface,
  PositionInterface,
} from 'utils/commonTypes';

interface SocketTokenData {
  token: string;
}

export interface SocketTokenPayload {
  data: SocketTokenData;
  meta: PaginationInterface;
}

export const getSocketToken = async (): Promise<SocketTokenPayload> => {
  const url = '/socket_token';

  return <Promise<SocketTokenPayload>>apiService(url, API_SERVICE_TYPES.GET);
};
