import { ColorMode } from './../../store/theme/slice';
import { ThemeOptions } from '@material-ui/core';
import { unset } from 'lodash';
import { COLORS } from '../colorConstants';

// Customize using options listed here:
// https://material-ui.com/customization/default-theme/#default-theme

export const appTheme: ThemeOptions = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          boxSizing: 'border-box',
          scrollbarWidth: 'thin',
        },
      },
    },
    MuiTableCell: {
      root: {
        color: COLORS.WHITE,
        borderBottom: 'unset',
      },
      body: {
        color: COLORS.WHITE,
        borderBottom: 'unset',
      },
      head: {
        color: COLORS.WHITE,
        borderBottom: 'unset',
      },
      footer: {
        color: COLORS.WHITE,
        borderBottom: 'unset',
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: COLORS.LIGHT_GREY,
      },
    },
    MuiFormLabel: {
      root: {
        color: COLORS.CERA_GREY,
      },
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: COLORS.PERSIAN_GREEN,
      contrastText: COLORS.WHITE,
    },
    secondary: {
      main: COLORS.WHITE,
      contrastText: COLORS.BLACK,
    },
    text: {
      primary: COLORS.WHITE,
    },
  },
  shape: {
    borderRadius: 10,
  },
};
