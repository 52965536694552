import React from 'react';
import Flex from 'components/General/Flex';
import { useControllerStyle } from 'routes/Organization/Map/style';
import EMSIcon from 'assets/customized/house-medical-hollow.svg';
import CommandPostIcon from 'assets/customized/command-post-outline.svg';
import GeoFenceIcon from 'assets/customized/geo-fence.svg';
import PerimeterPointIcon from 'assets/customized/perimeter-point.svg';
import { getAllCategory, getSelectedOrganization } from 'store';
import { useSelector } from 'react-redux';
import { OrgInterface } from 'utils';
import { getAuthenticatedUserOrgName } from 'utils/hooks';

const ActionButton = (props: any) => {
  const { className, label, imgSrc, imgStyle, onClick } = props;
  return (
    <div className={className} onClick={onClick} aria-hidden="true">
      <img src={imgSrc} alt={label} />
      <div className="menuLabel">{label}</div>
    </div>
  );
};

export const Controllers = (props: any) => {
  const classes = useControllerStyle();
  const currentOrgObj: OrgInterface = useSelector(getSelectedOrganization);
  const allCategories = useSelector(getAllCategory);
  const { ppBtnClick, gfBtnClick, cpBtnClick, emsCpBtnClick } = props;

  const orgName = getAuthenticatedUserOrgName(
    currentOrgObj?.organization_type,
    allCategories,
  );

  return (
    <Flex column className={classes.actionContainer}>
      <ActionButton
        label="Geo Fence"
        className={classes.actionMenuItem}
        imgSrc={GeoFenceIcon}
        onClick={gfBtnClick}
      />
      <ActionButton
        label="Perimeter Point"
        className={classes.actionMenuItem}
        imgSrc={PerimeterPointIcon}
        onClick={ppBtnClick}
      />
      <ActionButton
        label="Command Post"
        className={classes.actionMenuItem}
        imgSrc={CommandPostIcon}
        onClick={cpBtnClick}
      />
      {orgName === 'Special Event' && (
        <ActionButton
          label="EMS CP"
          className={classes.actionMenuItem}
          imgSrc={EMSIcon}
          onClick={emsCpBtnClick}
        />
      )}
    </Flex>
  );
};

export default Controllers;
