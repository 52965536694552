import { AuthResponseInterface } from 'utils/types';

export const authFromJSON = (
  responsePayload: AuthResponseInterface,
): AuthResponseInterface => {
  return {
    ...responsePayload,
    ...responsePayload.data,
  };
};
