import {
  changeToLowerCase,
  isEmailValid,
  isRequired,
  isValidBadgeId,
  isValidPhoneNumber,
} from 'utils/helper';
import Text from 'components/General/Form/Text';
import ComboBox from 'components/General/Form/ComboBox';
import { USERS_ROUTES } from 'routes/path';
import { APP_ASSETS } from 'utils/asset';

export const USER_DETAILS_FORM_FIELDS = [
  {
    name: 'first_name',
    label: 'First Name',
    type: 'text',
    component: Text,
    placeHolder: 'Enter First Name',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'last_name',
    label: 'Last Name',
    type: 'text',
    component: Text,
    placeHolder: 'Enter Last Name',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'organization',
    label: 'Associated',
    type: 'text',
    component: ComboBox,
    id: 'organization',
    displayValuePath: 'name',
    placeHolder: 'Enter Associated',
    validate: [isRequired],
    required: true,
    options: [],
  },
  {
    name: 'username',
    type: 'text',
    component: Text,
    label: 'Email',
    placeHolder: 'Enter Role',
    validate: [isRequired, isEmailValid],
    required: true,
    normalize: changeToLowerCase,
  },
  {
    name: 'role',
    label: 'Permission Roles',
    type: 'text',
    id: 'role',
    displayValuePath: 'name',
    component: ComboBox,
    placeHolder: 'Enter Role',
    validate: [isRequired],
    required: true,
    options: [],
  },
  {
    name: 'phone',
    label: 'Phone',
    type: 'tel',
    component: Text,
    placeHolder: 'Enter Phone',
    validate: [isValidPhoneNumber],
    required: true,
  },
  {
    name: 'person_type',
    type: 'text',
    component: ComboBox,
    label: 'Person Type',
    id: 'person-type',
    displayValuePath: 'name',
    placeHolder: 'Enter Person Type',
    validate: [isRequired],
    required: true,
    options: [],
  },
  {
    name: 'batch_number',
    type: 'number',
    component: Text,
    label: 'Badge Number',
    placeHolder: 'Enter Badge Id',
    validate: [isValidBadgeId],
    required: true,
  },
];

export const USER_DETAILS_ACTIVE_SUB_HEADER_SECTION = {
  leftSection: true,
  middleSection: false,
  rightSection: false,
};

export const USER_DETAILS_ACTION_BUTTON = {
  addUser: {
    id: 'addUser',
    routeSlug: USERS_ROUTES.USERS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ADD_ACTION,
      name: 'Add Users',
    },
  },
  addOrEditUserDetails: {
    id: 'addOrEditUserDetails',
    routeSlug: USERS_ROUTES.USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.SAVE_ACTION,
      name: 'save User',
    },
    type: 'submit',
  },
  activateUser: {
    id: 'activateUser',
    routeSlug: USERS_ROUTES.USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.INACTIVE_ICON,
      name: 'Activate User',
    },
  },
  deActivateUser: {
    id: 'deActivateUser',
    routeSlug: USERS_ROUTES.USERS_DETAILS.path,
    isVisible: false,
    icon: {
      src: APP_ASSETS.ACTIVE_ICON,
      name: 'Deactivate User',
    },
  },
};
