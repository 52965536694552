import { ROUTES } from './../path';
import _, { isNull } from 'lodash';
import { RoutesInterface, AppRouteInterface } from 'routes/path';
import { isNilOrEmpty, isCurrentRoute, isRequired } from 'utils/helper';
import { CombinedActionButtonInterface } from 'utils/commonTypes';
import { OrganizationUserTypeInterface } from 'store/organization';

import {
  CERA_ADMIN_SCOPES,
  CREATE_ORG_SCOPES,
  CREATE_USER_SCOPES,
  DEACTIVATE_ORG_SCOPES,
  DEACTIVATE_USER_SCOPES,
  EDIT_ORG_SCOPES,
  EDIT_USER_SCOPES,
} from 'utils/constants';
import { haveValidScope } from 'utils/hooks';
import { OrganizationTypeInterface } from 'models/organization';

export const replaceKeyWithValues = (sugValue: any, replaceWithObj: any) => {
  let newValue = sugValue;

  Object.keys(replaceWithObj).forEach((paramKey: string) => {
    if (_.isArray(newValue)) {
      newValue = _.map(newValue, (path) => {
        return _.replace(path, `:${paramKey}`, replaceWithObj[paramKey]);
      });
      return newValue;
    }
    newValue = newValue.replace(
      `:${paramKey}`,
      // todo
      // @ts-ignore
      replaceWithObj[paramKey],
    );
  });

  return newValue;
};

export const generateBreadCrumb = (currentUrl: string, history: any) => {
  const breadCrumbData = currentUrl.split('/').filter((path: string) => path);
  let routeToNavigate = '';

  return breadCrumbData.map((path: string) => {
    const label = path;
    routeToNavigate = `${routeToNavigate}/${path}`;
    return {
      label,
      value: routeToNavigate,
      onClick: (value: string) => history.push(value),
    };
  });
};

//

export const getAppRoute = (appRoutes: any) => {
  const screenKeys = Object.keys(appRoutes);
  let appRoute = null;
  const appRouteKey: any = screenKeys.find((routeKey: string) => {
    // @ts-ignore
    const routeObj = appRoutes[routeKey];
    if (isCurrentRoute(routeObj)) {
      appRoute = routeObj;
    }
  });

  return [appRouteKey, appRoute];
};

export const checkSubHeaderSectionVisibility = (
  currentAppRoute: RoutesInterface | null,
  appRoutes: AppRouteInterface,
) => {
  if (isNilOrEmpty(currentAppRoute)) {
    return {
      leftSection: true,
      middleSection: true,
      rightSection: true,
    };
  }

  //  when on organization data list page
  if (currentAppRoute && currentAppRoute.id === appRoutes.ORGANIZATION.id) {
    return {
      leftSection: true,
      middleSection: false,
      rightSection: true,
    };
  }

  //  when on map page
  if (currentAppRoute && currentAppRoute.id === appRoutes.ORGANIZATION_MAP.id) {
    return {
      leftSection: true,
      middleSection: true,
      rightSection: true,
    };
  }

  return {
    leftSection: true,
    middleSection: true,
    rightSection: true,
  };
};

export const updateActionButtonVisibility = (
  actionButtonObj: CombinedActionButtonInterface,
  currentAppRoute: RoutesInterface | null,
  selectedUser: OrganizationUserTypeInterface | null,
  selectedOrganization: OrganizationTypeInterface | null,
) => {
  if (!currentAppRoute) {
    return actionButtonObj;
  }

  const isOrgEditable = haveValidScope(EDIT_ORG_SCOPES);
  const areUsersEditable = haveValidScope(EDIT_USER_SCOPES);
  const canCreateUsers = haveValidScope(CREATE_USER_SCOPES);
  const canAddOrg = haveValidScope(CREATE_ORG_SCOPES);
  const isUserNotBlocked = isNull(_.get(selectedUser, 'blocked_at', ''));
  const isUserNotArchived = isNull(_.get(selectedUser, 'archived_at', ''));
  const canUsersBeActivated = haveValidScope(DEACTIVATE_USER_SCOPES);
  const isOrgActive = isNull(_.get(selectedOrganization, 'archived_at', ''));
  const canOrgBeActivated = haveValidScope(DEACTIVATE_ORG_SCOPES);
  const isCeraAdmin = haveValidScope(CERA_ADMIN_SCOPES);

  console.log('%c currentAppRoute ', 'background: black; color: yellow', {
    currentAppRoute,
  });

  if (currentAppRoute.id === ROUTES.ORGANIZATION.id) {
    _.set(actionButtonObj, 'addOrganization.isVisible', canAddOrg);
    _.set(actionButtonObj, 'startEvent.isVisible', false);
    _.set(actionButtonObj, 'updateOrganizationDetails.isVisible', false);
    _.set(actionButtonObj, 'activateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'addUserToOrganization.isVisible', false);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activateOrgDeptUser.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDeptUser.isVisible', false);
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
  }

  if (currentAppRoute.id === ROUTES.ORGANIZATION_DETAILS.id) {
    _.set(actionButtonObj, 'addOrganization.isVisible', false);
    _.set(
      actionButtonObj,
      'updateOrganizationDetails.isVisible',
      isNull(selectedOrganization) || (isOrgActive && isOrgEditable),
    );
    _.set(
      actionButtonObj,
      'activateOrgDept.isVisible',
      !isNull(selectedOrganization) && !isOrgActive && canOrgBeActivated,
    );
    _.set(
      actionButtonObj,
      'deActivateOrgDept.isVisible',
      !isNull(selectedOrganization) && isOrgActive && canOrgBeActivated,
    );
    _.set(actionButtonObj, 'addUserToOrganization.isVisible', false);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activateOrgDeptUser.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDeptUser.isVisible', false);
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
  }

  if (currentAppRoute.id === ROUTES.ORGANIZATION_USERS.id) {
    _.set(actionButtonObj, 'addOrganization.isVisible', false);
    _.set(actionButtonObj, 'updateOrganizationDetails.isVisible', false);
    _.set(actionButtonObj, 'activateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'addUserToOrganization.isVisible', canCreateUsers);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activateOrgDeptUser.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDeptUser.isVisible', false);
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
  }

  if (currentAppRoute.id === ROUTES.ORGANIZATION_USERS_DETAILS.id) {
    _.set(actionButtonObj, 'addOrganization.isVisible', false);
    _.set(actionButtonObj, 'updateOrganizationDetails.isVisible', false);
    _.set(actionButtonObj, 'activateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'addUserToOrganization.isVisible', false);
    _.set(
      actionButtonObj,
      'updateUserDetails.isVisible',
      isNull(selectedUser) ||
        (isUserNotBlocked && isUserNotArchived && areUsersEditable),
    );
    _.set(
      actionButtonObj,
      'activateOrgDeptUser.isVisible',
      !isNull(selectedUser) && !isUserNotBlocked && canUsersBeActivated,
    );
    _.set(
      actionButtonObj,
      'deActivateOrgDeptUser.isVisible',
      !isNull(selectedUser) && isUserNotBlocked && canUsersBeActivated,
    );
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
  }

  if (currentAppRoute.id === ROUTES.ORGANIZATION_MAP.id) {
    _.set(actionButtonObj, 'addOrganization.isVisible', false);
    _.set(actionButtonObj, 'updateOrganizationDetails.isVisible', false);
    _.set(actionButtonObj, 'activateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'addUserToOrganization.isVisible', false);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activateOrgDeptUser.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDeptUser.isVisible', false);
    _.set(
      actionButtonObj,
      'updateMapDetails.isVisible',
      isCeraAdmin && isOrgActive,
    );
    //todo add scope cond here
  }

  if (currentAppRoute.id === ROUTES.ORGANIZATION_QR_CODE.id) {
    _.set(actionButtonObj, 'addOrganization.isVisible', false);
    _.set(
      actionButtonObj,
      'updateOrganizationDetails.isVisible',
      isNull(selectedOrganization) || (isOrgActive && isOrgEditable),
    );
    _.set(actionButtonObj, 'activateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDept.isVisible', false);
    _.set(actionButtonObj, 'addUserToOrganization.isVisible', false);
    _.set(actionButtonObj, 'updateUserDetails.isVisible', false);
    _.set(actionButtonObj, 'activateOrgDeptUser.isVisible', false);
    _.set(actionButtonObj, 'deActivateOrgDeptUser.isVisible', false);
    _.set(actionButtonObj, 'updateMapDetails.isVisible', false);
  }
  return actionButtonObj;
};

export const validateOrgForm = (values: any) => {
  console.log(
    'Validation conditions are already added in the individual fields.',
  );
};
