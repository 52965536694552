import React, { Fragment, useState, useEffect } from 'react';
import { PageBody } from 'container/PageBody';
import NavigationSection from 'components/SubHeader/NavigationSection';
import _, { isNull } from 'lodash';
import {
  EMS_NESTED_LINKS,
  EMS_ACTION_BUTTON,
  EMS_ACTIVE_SUB_HEADER_SECTION,
} from 'routes/Ems/utils/constants';
import { useRouteMatch } from 'react-router';
import { EMS_ROUTES } from 'routes/path';
import {
  getUserForms,
  useActiveAppRouteMatch,
  useBreadCrumb,
} from 'utils/hooks';
import OrganizationTable from 'components/OrganizationTable';
import {
  ActionButtonInterface,
  AppCategory,
  CombinedActionButtonInterface,
} from 'utils/commonTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdatePoliceDeptHeaderVisibility } from 'routes/Ems/utils/hook';
import {
  compareFormObjects,
  isRouteMatch,
  getUpdatedFields,
} from 'utils/helper';
import { CreatePoliceDeptForm } from './EmsForm';
import { OrganizationUsers } from 'routes/Ems/OrganizationUsers';
import { UserForm } from 'routes/Organization/UserForm';
import { updateActionButtonVisibility } from 'routes/Ems/utils/helper';
import {
  organizationActions,
  getEMSUserFormValue,
  getEMSUserFormErrors,
  getOrganizationFormValue,
  isOrganizationDeptFormTouched,
} from 'store/organization';
import {
  getPoliceDeptFormValue,
  getPoliceDeptFormErrors,
  isPoliceDeptFormTouched,
} from 'store/policeDept';
import { SuperviseeList } from 'routes/Ems/SuperviseeList';
import { modalActions } from 'store/modal';
import { getSelectedOrganization, getSelectedUsers } from 'store/organization/';
import {
  ORG_FORM_KEYS,
  REDUX_FORM_CONSTANT,
  STATUS,
  USER_FORM_KEYS,
  WARNING_MESSAGE,
} from 'utils';
import { toast } from 'react-toastify';
import { destroy, submit } from 'redux-form';
import { QrCodePreview } from 'routes/Organization/QrCodePreview';
// import { useFlags } from 'launchdarkly-react-client-sdk';
// import { LAUNCH_DARKLY_KEYS } from 'utils/constants/ldKeys';

export const Ems = () => {
  const currentRoute = useRouteMatch();
  const dispatch = useDispatch();

  const selectedUser = useSelector(getSelectedUsers);
  const selectedOrganization = useSelector(getSelectedOrganization);
  const isOrgArchieved = !isNull(selectedOrganization?.archived_at);
  const currentRouteBreadCrumb = useBreadCrumb(
    selectedOrganization,
    selectedUser,
  );

  // const flags = useFlags();
  // const qrCodeFeatureFlag: boolean =
  //   flags[LAUNCH_DARKLY_KEYS.REGISTER_USING_QR_CODE];
  const nestedLinks: any = { ...EMS_NESTED_LINKS };
  // if (!qrCodeFeatureFlag || isOrgArchieved) {
  if (isOrgArchieved) {
    delete nestedLinks['qrCode'];
  }

  const appActiveRoute = useActiveAppRouteMatch(EMS_ROUTES);
  const activeSubHeaderSection = useUpdatePoliceDeptHeaderVisibility(
    EMS_ROUTES,
    EMS_ACTIVE_SUB_HEADER_SECTION,
  );
  const addOrEditPoliceDeptFormValue = useSelector(getPoliceDeptFormValue);
  const addOrEditPoliceDeptFormErrors = useSelector(getPoliceDeptFormErrors);
  const addOrEditUserFormValue = useSelector(getEMSUserFormValue);
  const addOrEditUserFormError = useSelector(getEMSUserFormErrors);
  const [
    actionButton,
    setActionButton,
  ] = useState<CombinedActionButtonInterface>(_.cloneDeep(EMS_ACTION_BUTTON));
  const isPoliceFormTouched = useSelector(isPoliceDeptFormTouched);
  const isOrgFormTouched = useSelector(isOrganizationDeptFormTouched);

  useEffect(() => {
    // @ts-ignore
    if (_.isNull(selectedOrganization) && currentRoute.params?.id) {
      dispatch(
        organizationActions.fetchParticularOrganizationDetailsRequest({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    const updatedActionButton = updateActionButtonVisibility(
      actionButton,
      appActiveRoute,
      selectedUser,
      selectedOrganization,
    );
    setActionButton(updatedActionButton);
  }, [appActiveRoute?.path, selectedUser, selectedOrganization]);

  const handleActionButtonClick = (actionButtonObj: ActionButtonInterface) => {
    if (actionButtonObj.id === EMS_ACTION_BUTTON.emsAddOrg.id) {
      dispatch(organizationActions.createNewOrganizationButtonClick());
      dispatch(destroy(REDUX_FORM_CONSTANT.POLICE_DEPT));
    }

    if (actionButtonObj.id === EMS_ACTION_BUTTON.emsAddOrEditOrg.id) {
      dispatch(submit(REDUX_FORM_CONSTANT.POLICE_DEPT));
      if (!_.isEmpty(addOrEditPoliceDeptFormErrors)) {
        toast.error('Please fill the required fields.');
      } else {
        const updatedFieldValue = getUpdatedFields(
          addOrEditPoliceDeptFormValue,
          selectedOrganization,
        );

        dispatch(
          organizationActions.addOrEditOrganizationsAsyncRequest({
            formValue: addOrEditPoliceDeptFormValue,
            dirtyFields: updatedFieldValue,
            // @ts-ignore
            id: currentRoute.params.id,
          }),
        );
      }
    }

    if (actionButtonObj.id === EMS_ACTION_BUTTON.emsAddUserToOrg.id) {
      dispatch(
        organizationActions.createNewOrgUserButtonClick({
          // @ts-ignore
          orgId: currentRoute.params.id,
        }),
      );
    }
    if (actionButtonObj.id === EMS_ACTION_BUTTON.emsUpdateUserDetails.id) {
      dispatch(submit(REDUX_FORM_CONSTANT.EMS_USER));
      if (!_.isEmpty(addOrEditUserFormError)) {
        toast.error('Please fill the required fields.');
      } else
        dispatch(
          organizationActions.addOrEditOrgUserAsyncRequest({
            formValue: addOrEditUserFormValue,
            // @ts-ignore
            orgId: currentRoute.params.id,
            // @ts-ignore
            userId: currentRoute.params.userId,
          }),
        );
    }
    if (actionButtonObj.id === EMS_ACTION_BUTTON.addEditSupervisee.id) {
      dispatch(modalActions.openModal());
    }

    if (actionButtonObj.id === EMS_ACTION_BUTTON.activateEmsDept.id) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'organization',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.ACTIVATE,
        }),
      );
    }

    if (actionButtonObj.id === EMS_ACTION_BUTTON.deActivateEmsDept.id) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'organization',
          // @ts-ignore
          id: currentRoute.params.id,
          status: STATUS.DEACTIVATE,
        }),
      );
    }

    if (actionButtonObj.id === EMS_ACTION_BUTTON.emsActivateUser.id) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.userId,
          status: STATUS.UNBLOCK_USER,
        }),
      );
    }

    if (actionButtonObj.id === EMS_ACTION_BUTTON.emsDeActivateUser.id) {
      dispatch(
        organizationActions.updateActiveStatusRequest({
          type: 'user',
          // @ts-ignore
          id: currentRoute.params.userId,
          status: STATUS.BLOCK_USER,
        }),
      );
    }
  };
  const getOrgForm = useSelector(getOrganizationFormValue);
  const [userForms, isUserTouched] = getUserForms();

  window.onbeforeunload = function (event: any) {
    const e = event || window.event;
    const msg = WARNING_MESSAGE;

    if (
      (isPoliceFormTouched &&
        compareFormObjects(
          selectedOrganization,
          addOrEditPoliceDeptFormValue,
          ORG_FORM_KEYS,
        )) ||
      (isOrgFormTouched &&
        compareFormObjects(selectedOrganization, getOrgForm, ORG_FORM_KEYS)) ||
      (isUserTouched &&
        compareFormObjects(selectedUser, userForms, USER_FORM_KEYS))
    ) {
      e.preventDefault();
      if (e) {
        return (e.returnValue = msg);
      }
      return msg;
    }
  };

  return (
    <Fragment>
      <NavigationSection
        breadcrumbData={currentRouteBreadCrumb}
        activeRouteSlug={appActiveRoute?.path}
        subNavigation={nestedLinks}
        // TODO
        // merge section and action button visibility together
        sectionVisibility={activeSubHeaderSection}
        actionButtons={actionButton}
        actionButtonClick={handleActionButtonClick}
      />

      <PageBody>
        {isRouteMatch(EMS_ROUTES.EMS_ORG_LIST, currentRoute.url) && (
          <OrganizationTable categoryType={AppCategory.EMS} />
        )}

        {isRouteMatch(EMS_ROUTES.EMS_ORG_DETAILS, currentRoute.url) && (
          <CreatePoliceDeptForm />
        )}

        {isRouteMatch(EMS_ROUTES.EMS_ORG_USERS, currentRoute.url) && (
          <OrganizationUsers />
        )}

        {isRouteMatch(EMS_ROUTES.EMS_ORG_USERS_DETAILS, currentRoute.url) && (
          <UserForm id={REDUX_FORM_CONSTANT.EMS_USER} />
        )}

        {isRouteMatch(EMS_ROUTES.EMS_ORG_ASSOCIATION, currentRoute.url) && (
          <SuperviseeList />
        )}

        {isRouteMatch(EMS_ROUTES.EMS_ORG_QR_CODE, currentRoute.url) &&
          !isOrgArchieved && (
            // qrCodeFeatureFlag &&
            <QrCodePreview />
          )}
      </PageBody>
    </Fragment>
  );
};

export default Ems;
