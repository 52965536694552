// @ts-nocheck

import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import PageSubHeader, {
  NavigationBox,
  NavigationLink,
  RightChild,
  MiddleChild,
  LeftChild,
} from '.';
import { useHistory, useRouteMatch } from 'react-router';
import CustomBreadcrumbs, {
  BreadcrumbDataProps,
} from 'components/CustomBreadcrumbs';
import {
  CombinedActionButtonInterface,
  ActionButtonInterface,
} from 'utils/commonTypes';
import { replaceKeyWithValues } from '../../routes/Organization/helper';
import IconButton from '@material-ui/core/IconButton';
import { getUserForms, haveValidScope, useModal } from 'utils/hooks';
import {
  compareFormObjects,
  READ_USER_SCOPES,
  SCOPES,
  USER_FORM_KEYS,
} from 'utils';
import { toast } from 'react-toastify';
import { FormSaveConfirmationModal } from 'components/Form/FormModal';
import { getSelectedOrganization, getSelectedUsers } from 'store';
import { useSelector } from 'react-redux';
import { CustomButton } from 'components/General/CustomButton';

interface ShowSectionInterface {
  leftSection: boolean;
  middleSection: boolean;
  rightSection: boolean;
}

interface NavigationSectionProps {
  children?: React.ReactNode;
  breadcrumbData: BreadcrumbDataProps[];
  activeRouteSlug?: any;
  subNavigation?: any;
  sectionVisibility: ShowSectionInterface;
  actionButtons: CombinedActionButtonInterface;
  actionButtonClick: (value: ActionButtonInterface) => void;
}

export const NavigationSection = (props: NavigationSectionProps) => {
  const currentRoute = useRouteMatch();
  const [open, openModal, closeModal] = useModal();
  const historyObj = useHistory();
  const isUsersListReadable = haveValidScope(READ_USER_SCOPES);
  const canViewEditMap = haveValidScope(SCOPES.EDIT_ORG); //only cera admin
  const [link, setLink] = useState('');
  const currentUser = useSelector(getSelectedUsers);
  const currentOrg = useSelector(getSelectedOrganization);
  const [userForm, isUserTouched] = getUserForms();
  const isOrgActive = _.isNull(_.get(currentOrg, 'archived_at', ''));

  const handleNavLink = (linkObj: any) => {
    const newRoute = replaceKeyWithValues(
      linkObj.routeSlug,
      currentRoute.params,
    );
    let url = newRoute;
    if (_.isArray(newRoute)) {
      url = newRoute[0];
    }
    setLink(url);

    if (
      linkObj.id !== 'users' ||
      (linkObj.id === 'users' && isUsersListReadable)
    ) {
      if (
        isUserTouched &&
        compareFormObjects(currentUser, userForm, USER_FORM_KEYS)
      ) {
        openModal();
      } else historyObj.push(url);
    } else {
      toast.error('Permission not granted');
    }
  };

  const isActiveLink = (linkObj: any): boolean => {
    if (_.isArray(linkObj.routeSlug)) {
      const slugMatched = _.find(
        linkObj.routeSlug,
        (slug) => slug === props.activeRouteSlug,
      );
      if (slugMatched) {
        return true;
      }
      return false;
    }

    return (
      _.isString(linkObj.routeSlug) &&
      linkObj.routeSlug === props.activeRouteSlug
    );
  };

  const isVisible = (sectionName: string) => {
    return _.get(props.sectionVisibility, sectionName, false);
  };

  const handleStay = () => {
    closeModal();
  };

  const handleLeave = () => {
    closeModal();
    historyObj.push(link);
  };

  return (
    <>
      <PageSubHeader>
        <LeftChild>
          {isVisible('leftSection') && (
            <CustomBreadcrumbs breadcrumbDataArray={props.breadcrumbData} />
          )}
        </LeftChild>

        <MiddleChild>
          {isVisible('middleSection') && (
            <NavigationBox>
              {Object.keys(props.subNavigation).map((linkId: any) => {
                // @todo
                // @ts-ignore
                const linkObj = props.subNavigation[linkId];
                if (
                  (linkId === 'explore' && canViewEditMap) ||
                  linkId !== 'explore'
                ) {
                  return (
                    <NavigationLink
                      key={linkId}
                      isActive={isActiveLink(linkObj)}
                      linkObj={linkObj}
                      onClick={(linkObj: any) => handleNavLink(linkObj)}
                    />
                  );
                }
              })}
            </NavigationBox>
          )}
        </MiddleChild>

        <RightChild>
          {isVisible('rightSection') &&
            Object.keys(props.actionButtons).map((actionKey) => {
              const actionButton = props.actionButtons[actionKey];
              if (actionButton.isVisible && actionButton.id !== 'startEvent') {
                return (
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    // component="span"
                    type={actionButton.type}
                    data-cy={actionButton.icon.name}
                    key={actionKey}
                    onClick={() => props.actionButtonClick(actionButton)}
                  >
                    <img
                      src={actionButton.icon.src}
                      alt="logo"
                      style={{
                        width: '28px',
                        height: '28px',
                        boxSize: 'auto',
                      }}
                    />
                  </IconButton>
                );
              } else if (
                actionButton.isVisible &&
                actionButton.id === 'startEvent'
              ) {
                return (
                  <CustomButton
                    onClick={() => props.actionButtonClick(actionButton)}
                    error={true}
                    label="Start"
                    key={actionButton.id}
                    disabled={
                      !isOrgActive ||
                      _.get(currentOrg, 'has_active_event', false)
                    }
                  />
                );
              }
              return <Fragment key={actionKey} />;
            })}
        </RightChild>
      </PageSubHeader>
      <FormSaveConfirmationModal
        handleLeave={handleLeave}
        handleStay={handleStay}
        open={open}
      />
    </>
  );
};

export default NavigationSection;
