import React, { useEffect, useState } from 'react';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'components/General/Form/Text';
import _, { isBoolean, isNil } from 'lodash';
import ComboBox from 'components/General/Form/ComboBox';
import { CERA_ADMIN_SCOPES, COUNTRY_DATA } from 'utils/constants';
import { getSelectedOrganization } from 'store/organization/organization.selectors';
import { getOrganizationFormValue } from 'store/organization';
import { checkAndUpdateProvinceOptions } from 'routes/PoliceDepartment/utils/helper';

import {
  formatVal,
  isRequired,
  isDomainPresent,
  isValidPhoneNumber,
  isValidPostalCode,
  isValidDomain,
} from 'utils/helper';
import { FormContainer } from '../../components/Form';
import { REDUX_FORM_CONSTANT } from 'utils/constants';
import { haveValidScope, useFetchOrgDetails } from 'utils/hooks';
import { FormField } from 'utils/types';
import { validateOrgForm } from './helper';
import { updateProvinceOptions } from 'routes/Ems/utils/helper';
import InputChip from 'components/General/Form/InputChip';

const FORM_FIELDS_ARRAY = [
  {
    name: 'name',
    type: 'text',
    component: Text,
    label: 'Name',
    placeHolder: 'Enter Organization Name',
    validate: [isRequired],
    required: true,
  },
  {
    name: 'country',
    label: 'Country',
    type: 'text',
    component: ComboBox,
    id: 'country',
    placeHolder: 'Select Country',
    validate: [isRequired],
    required: true,
    options: COUNTRY_DATA,
    displayValuePath: 'name',
    disable: false,
  },

  {
    name: 'phone',
    type: 'tel',
    component: Text,
    label: 'Phone',
    placeHolder: 'Enter Phone',
    validate: [isValidPhoneNumber],
    required: true,
  },
  {
    name: 'street_1',
    type: 'text',
    component: Text,
    label: 'Street',
    placeHolder: 'Enter Street',
    validate: [isRequired],
    required: true,
    disable: false,
  },
  {
    name: 'street_2',
    type: 'text',
    component: Text,
    label: 'Street 2',
    placeHolder: 'Enter Street',
    validate: [],
    disable: false,
  },
  {
    name: 'city',
    type: 'text',
    component: Text,
    label: 'City',
    placeHolder: 'Enter City',
    validate: [isRequired],
    required: true,
    disable: false,
  },
  // {
  //   name: 'state',
  //   type: 'text',
  //   component: Text,
  //   label: 'State',
  //   placeHolder: 'Enter State',
  //   validate: [isRequired],
  //   required: true,
  // },
  {
    name: 'state',
    type: 'text',
    component: ComboBox,
    label: 'State',
    id: 'state',
    placeHolder: 'Enter State',
    displayValuePath: 'name',
    validate: [isRequired],
    required: true,
    disable: false,
  },
  {
    name: 'postal_code',
    type: 'text',
    component: Text,
    label: 'Postal Code',
    placeHolder: 'Enter Postal Code',
    validate: [isValidPostalCode],
    format: formatVal('postal_code'),
    required: true,
    disable: false,
  },
  {
    name: 'domains',
    type: 'text',
    component: InputChip,
    label: 'domain',
    placeHolder: 'Enter domain',
    validate: [isDomainPresent, isValidDomain],
    required: true,
  },
];

type FormWrapperProps = { fieldArr: FormField[] };

const OrganizationFormWrapper = (
  props: FormWrapperProps & InjectedFormProps<unknown, FormWrapperProps>,
) => {
  return <FormContainer fieldArr={props.fieldArr} />;
};

const OrgRFWrapper = reduxForm<unknown, FormWrapperProps>({
  form: REDUX_FORM_CONSTANT.ORGANIZATION,
  onSubmit: validateOrgForm,
  destroyOnUnmount: false,
})(OrganizationFormWrapper);

const useInitializeForm = () => {
  const [formFieldsArr, setFormFieldArr] = useState<FormField[]>([]);
  const formValue = useSelector(getOrganizationFormValue);
  const initialValue = useSelector(getSelectedOrganization);
  const currentRoute = useRouteMatch();
  const orgId = _.get(currentRoute, 'params.id', '');
  const isAdmin = haveValidScope(CERA_ADMIN_SCOPES);
  useEffect(() => {
    const fieldArr = _.cloneDeep(FORM_FIELDS_ARRAY);

    if (orgId && !isAdmin) {
      fieldArr.map((obj) => {
        const isDisableFieldPresent = _.get(obj, 'disable', '');
        if (isBoolean(isDisableFieldPresent)) {
          obj.disable = true;
        }
      });
    }

    setFormFieldArr(fieldArr);
  }, []);

  console.log('%c fieldArr ', 'background: lime; color: black', {
    formFieldsArr,
  });
  return [formFieldsArr, initialValue, formValue];
};

export const CreateOrganizationForm: React.FC = () => {
  const [fieldArr] = useInitializeForm();
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(getSelectedOrganization);
  const currentRoute = useRouteMatch();
  const orgId = _.get(currentRoute, 'params.id', '');
  if (orgId) {
    useFetchOrgDetails(orgId);
  }
  const orgFormValue = useSelector(getOrganizationFormValue);
  useEffect(() => {
    const countryCode = _.get(orgFormValue, 'country.name', null);
    if (!isNil(countryCode) && fieldArr.length !== 0) {
      updateProvinceOptions(fieldArr, countryCode);
    }
  }, [fieldArr]);

  const handleChange = (formValue: any, ...arg: any) => {
    const previousFormValue = arg.pop();
    console.log('%c fieldArr  before', 'background: black; color: yellow', {
      formValue,
      previousFormValue,
      fieldArr,
      arg,
    });

    const isValueUpdated = checkAndUpdateProvinceOptions(
      formValue,
      previousFormValue,
      fieldArr,
    );
    const stateVal = _.get(formValue, 'state.name', '');

    const stateOptions = fieldArr.filter((obj: any) => obj.name === 'state')[0]
      .options;

    const options = stateOptions?.map((obj: any) => obj.name) || [];

    if (isValueUpdated && _.indexOf(options, stateVal) === -1) {
      dispatch(change(REDUX_FORM_CONSTANT.ORGANIZATION, 'state', null));
    }

    console.log(
      '%c fieldArr  after',
      'background: black; color: yellow',
      fieldArr,
    );
  };

  return (
    <OrgRFWrapper
      initialValues={selectedOrganization}
      onChange={handleChange}
      fieldArr={fieldArr}
    />
  );
};

export default CreateOrganizationForm;

// fetchParticularOrganizationDetailsRequest
